import React, { useState } from 'react';
import useStyles from './styles';
import { IconButton } from '@mui/material';

interface VideoThumbnailPreviewProps {
  videoSrc: string; // The source of the video
  thumbnailSrc: string; // The source of the thumbnail image
  handleRemoveVideo: (index: number) => void;
  displayOnly?: boolean; // Flag to not allow any action
  index: number;
  isDisabled: boolean;
  isLoading: boolean;
}

const VideoThumbnailPreview: React.FC<VideoThumbnailPreviewProps> = ({
  videoSrc,
  thumbnailSrc,
  displayOnly = false,
  handleRemoveVideo,
  index,
  isDisabled,
  isLoading,
}) => {
  const classes = useStyles();

  const [isPlaying, setIsPlaying] = useState(false);

  const handleTogglePlay = () => {
    setIsPlaying((prev) => !prev);
  };

  return (
    <div
      className={classes.videoPreview}
      onClick={handleTogglePlay} // Handle tap/click interaction
    >
      {/* Thumbnail Image */}
      <img
        alt="Video Thumbnail"
        src={thumbnailSrc}
        style={{
          display: isPlaying ? 'none' : 'block',
          opacity: displayOnly ? 0.3 : 1,
        }}
        className={classes.imagePreview}
      />

      {/* Video */}
      <video
        src={videoSrc}
        muted
        loop
        autoPlay={isPlaying} // Start playing only when toggled
        playsInline // Ensure mobile compatibility
        style={{
          position: 'relative',
          display: isPlaying ? 'block' : 'none',
          width: '5rem',
          height: '5rem',
          objectFit: 'cover',
          opacity: displayOnly ? 0.3 : 1,
        }}
      />
      {!displayOnly && (
        <IconButton
          className={classes.videoDeleteIcon}
          size="small"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering play toggle
            handleRemoveVideo(index);
          }}
          disabled={isDisabled || isLoading}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            {/* SVG for delete icon */}
            <rect
              x="0.00244141"
              y="0.447021"
              width="19.9976"
              height="19.9976"
              rx="9.99878"
              fill="#F2F2F2"
            />
            <path
              d="M14 14.4447L10.0005 10.4452L14 6.44568"
              stroke="#4B4B4B"
              strokeWidth="1.40922"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.00146 6.44572L10.001 10.4452L6.00146 14.4447"
              stroke="#4B4B4B"
              strokeWidth="1.40922"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
      )}
    </div>
  );
};

export default VideoThumbnailPreview;
