import { makeStyles } from '@mui/styles';
import MuiTheme from '~/styles/theme/MuiTheme';

export const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  calendarHeaderRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'center',
  },
  calendar: {
    '& .MuiPickersDay-root': {
      color: '#000',
      width: 40,
      height: 40,

      margin: 0,
      padding: '0px 2px',
    },
    '& .MuiPickersDay-root.Mui-disabled': {
      color: '#6B7791',
    },
    '& .MuiPickersDay-today': {
      outline: 'none',
      border: 'none',
    },
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 0px 4px rgba(255, 255,255, 0.8)',
  },
  currentMonthText: {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: '700',
  },
  day: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: '400',
  },
  highlightedDay: {
    backgroundColor: '#FE7138 !important',
    color: '#fff !important',
    borderRadius: '0%',
  },
  singleDay: {
    borderRadius: '50%',
  },
  firstDay: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  lastDay: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },

  weekdayLabel: {
    '& .MuiTypography-root.MuiDayCalendar-weekDayLabel': {
      color: '#6B7791', // Set the color for the week day labels
    },
  },

  selectionIcon: {
    color: '#EC642C',
  },

  [theme.breakpoints.between(0, 768)]: {
    calendar: {
      width: 'auto',
    },
  },
}));
