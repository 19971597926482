import React from 'react';
import { Grid, Typography, IconButton, CircularProgress } from '@mui/material';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import useStyles from './styles';
import { IMedia } from '~/utility/models';
import { validImageTypes } from './constants';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Represents the props for the ImageContainerSection component.
 * @interface ImageContainerProps
 * @property {FormData} formData - The form data for the current section.
 * @property {Function} onFileChange - A function to handle changes in file form fields.
 * @property {boolean} isDisabled - A flag indicating whether the file upload is disabled.
 * @property {boolean} isLoading - A flag indicating whether an image is being uploaded.
 * @property {boolean} displayOnly - A flag indicating whether the image container should only display images or allow for file upload.
 * @property {Function} handleRemoveImage - A function to remove the selected file.
 * @property {string} type - The type of the form section ('departure' or 'arrival').
 */

interface ImageContainerProps {
  images: IMedia[];
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveImage: (index: number) => void;
  isDisabled: boolean;
  isLoading: boolean;
  displayOnly?: boolean;
}

/**
 * Renders a form container for either departure or arrival point.
 * @param {Object} props - The props object.
 * @param {FormData} props.formData - The form data.
 * @param {Function} props.onFormChange - The function to handle form field changes.
 * @param {boolean} props.isDisabled - Flag to determine if the form is disabled.
 * @param {boolean} props.isLoading - A flag indicating whether an image is being uploaded.
 * @param {boolean} props.displayOnly - A flag indicating whether the image container should only display images or allow for file upload.
 * @param {Function} props.handleRemoveImage - A function to remove the selected file.
 * @param {string} props.type - The type of the form ('departure' or 'arrival').
 * @returns {JSX.Element} FormContainer component.
 */

export const NewImageContainer: React.FC<ImageContainerProps> = ({
  onFileChange,
  handleRemoveImage,
  isDisabled,
  images,
  isLoading,
  displayOnly,
}) => {
  const classes = useStyles();

  return (
    <div style={{ paddingTop: '10' }}>
      <Typography
        component="span"
        sx={{
          opacity: isDisabled ? 0.3 : 1,
          color: '#000000',
          fontSize: { xs: '14px', sm: '16px', md: '18px' }, // Responsive font size
          marginLeft: '.4rem',
          fontWeight: 700,
          lineHeight: '30px',
          fontFamily: 'Poppins, sans-serif',
          marginBottom: '1rem',
          display: 'inline', // Ensures it behaves like a span
        }}
      >
        Upload Pictures (max 2 MB)
      </Typography>
      <Grid container spacing={2} gap={2} sx={{ marginTop: '.2rem' }}>
        {images &&
          images &&
          images?.length > 0 &&
          images.map((image, index) => (
            <Grid item key={index} className={classes.imagePreview}>
              <img
                alt={`Preview ${index}`}
                className={classes.imagePreview}
                src={image.thumbnail}
                style={{
                  opacity: displayOnly ? 0.3 : 1,
                }}
              />
              {!displayOnly && (
                <IconButton
                  className={classes.deleteIcon}
                  size="small"
                  onClick={() => handleRemoveImage(index)}
                  disabled={isDisabled || isLoading}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <rect
                      x="0.00244141"
                      y="0.447021"
                      width="19.9976"
                      height="19.9976"
                      rx="9.99878"
                      fill="#F2F2F2"
                    />
                    <path
                      d="M14 14.4447L10.0005 10.4452L14 6.44568"
                      stroke="#4B4B4B"
                      strokeWidth="1.40922"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.00146 6.44572L10.001 10.4452L6.00146 14.4447"
                      stroke="#4B4B4B"
                      strokeWidth="1.40922"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </IconButton>
              )}
            </Grid>
          ))}

        {!displayOnly && (
          <Grid item className={classes.imageSelector}>
            <input
              accept={validImageTypes.join(', ')}
              style={{ display: 'none' }}
              id={`file-input-image`}
              type="file"
              multiple
              onChange={onFileChange}
              disabled={images.length >= 3 || isDisabled}
            />

            <label htmlFor={`file-input-image`}>
              <div
                className={
                  images.length >= 3
                    ? classes.fileInputBoxDisabled
                    : isDisabled || isLoading
                    ? classes.disableFileUpload
                    : classes.fileInputBox
                }
              >
                {isLoading ? (
                  <CircularProgress size={26} thickness={2} />
                ) : (
                  <img
                    src="/icons/add-media.svg"
                    alt="Click to add images"
                    aria-label="Click to add images"
                  />
                )}
              </div>
            </label>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
