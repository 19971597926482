import { makeStyles } from '@mui/styles';
import type MuiTheme from '~/styles/theme/MuiTheme';

const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  resend: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Futura Bk BT',
    color: '#1F5460',
  },

  resendButton: {
    textTransform: 'none',
    display: 'flex',
    color: '#1F5460',
    fontFamily: 'Futura Bk BT',
    position: 'relative',
  },
  resendButtonText: {
    textDecoration: 'underline',
    textDecorationColor: '#1F5460',
  },

  [theme.breakpoints.between(600, 992)]: {},
  [theme.breakpoints.between(350, 600)]: {},
  [theme.breakpoints.between(250, 385)]: {
    thanks: {
      fontSize: '15px',
    },
  },
  [theme.breakpoints.between(250, 350)]: {},
}));

export default useStyles;
