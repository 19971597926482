import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../reducers';
import { getTravelPointsArray } from '~/utility/utils';

// Selectors
const selectTravelPointsByDay = (state: RootState) =>
  state.P2PManualReducers.travelPointsByDayIndex;

const selectLastVisitedDayIndex = (state: RootState) =>
  state.P2PManualReducers.lastVisitedDayIndex;

export const selectIsSavingToHistory = (state: RootState) =>
  state.P2PManualReducers.isSavingToHistory;

export const selectTravelPoints = createSelector(
  selectTravelPointsByDay,
  (travelPointsByDayIndex) => getTravelPointsArray(travelPointsByDayIndex),
);

export const filteredPoints = createSelector(
  selectTravelPointsByDay,
  selectLastVisitedDayIndex, // Add this selector here
  (travelPointsByDayIndex, lastVisitedDayIndex) => {
    // Access `lastVisitedDayIndex` and use it
    return travelPointsByDayIndex[lastVisitedDayIndex] || [];
  },
);
