import React from 'react';
import { Dialog, Box, DialogTitle, DialogContent, Button } from '@mui/material';
import useStyles from './styles';

/**
 * Props for the ConfirmationDialog component.
 * 
 * @typedef {Object} ConfirmationDialogProps
 * @property {boolean} open - Determines if the dialog is open
 * @property {function} onClose - Function to close the dialog
 * @property {function} onConfirm - Function to confirm the action
 * @property {function} openItinerary - Function to open the itinerary
 */
interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  openItinerary: () => void;
}
/**
 * ConfirmationDialog component - Displays a dialog for confirming itinerary actions.
 *
 * @component
 * @param {ConfirmationDialogProps} props - Component props
 * @returns {React.ReactElement} - Rendered component
 */
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  openItinerary,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        Add a Travel Itinerary
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        Do you want to modify the current itinerary or do you want to remove the
        current one and start a new one?
      </DialogContent>
      <Box className={classes.dialogButtonContainer}>
        <Button
          className={`${classes.dialogButton} ${classes.dialogConfirmButton}`}
          onClick={openItinerary}
          color="primary"
        >
          View/Modify current Itinerary
        </Button>
        <Button
          className={`${classes.dialogButton} ${classes.dialogCancelButton}`}
          onClick={onConfirm}
        >
          Add a New Itinerary
        </Button>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
