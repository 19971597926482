import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  animationIsPlaying,
  isVizualizingSignal,
  showMainHeader,
  showPlayPauseButton,
} from '../../common';
import { getStartedBlinkSignal } from '~/components/signals/blinkSignals';
import { isMapLoadedSignal } from '~/map/helpers';
import ActionsCreator from '~/redux/actions';
import {
  VizualizeButtonData,
  vizualizeButtonSignal,
} from '../DaysHeader/DaysHeader';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { motion } from 'framer-motion';
import { isOverlayVisible } from '../../StatsOverlay';
import { shouldShowMakeOwnPopup } from '~/map/ViewTravel/MakeYourOwnPopup';
import { showOverviewMenu } from '~/components/OverviewMenu';
import { isSyncingToServerSignal } from '~/components/P2PManual';
import { RootState } from '~/redux/reducers';
import subscribeSignal from '~/hooks/subscribeSignal';

const MobileVizualizeButton = ({
  setStep,
  startTrip,
}: {
  setStep: (v: string) => void;
  startTrip: (v: number) => void;
}) => {
  const [selectedPlayData, setSelectedPlayData] =
    useState<VizualizeButtonData>();
  const dispatch = useDispatch();

  // useSignalEffect(() => {
  //   setSelectedPlayData(vizualizeButtonSignal.value);
  // });
  subscribeSignal(vizualizeButtonSignal, setSelectedPlayData);

  const [loading, setLoading] = useState(false);
  const isTravelPointsUpdated = useSelector(
    (state: RootState) => state.P2PManualReducers.isTravelPointsUpdated,
  );

  subscribeSignal(isSyncingToServerSignal, setLoading);

  const getStarted = () => {
    if (!isMapLoadedSignal.peek()) return;
    vizualizeButtonSignal.value = {
      day: selectedPlayData?.day,
      startIndex: selectedPlayData?.startIndex,
      endIndex: selectedPlayData?.endIndex,
    };
    isVizualizingSignal.value = true;
    // added for blinking state
    getStartedBlinkSignal.value = 2;
    showPlayPauseButton.value = true;
    showOverviewMenu.value = false;
    if (!selectedPlayData) {
      vizualizeButtonSignal.value = undefined;
    }
    // play(!isPlaying);
    // fadingUp.play(!fadingUp.isPlaying);
    animationIsPlaying.value = true;
    // dispatch(ActionsCreator.setPlayPauseState(false));
    showMainHeader.value = false;

    setTimeout(() => {
      setStep('load');
      if (selectedPlayData?.startIndex) {
        dispatch(ActionsCreator.setPlayPauseState(false));
        setTimeout(() => startTrip?.(selectedPlayData?.startIndex), 0);
      } else {
        dispatch(ActionsCreator.setPlayPauseState(false));
      }
      if (isOverlayVisible.peek()) isOverlayVisible.value = false;
    }, 300);
  };
  return (
    <motion.div
      className="bottom-sheet-button"
      animate={{
        scale: 1.05,
      }}
      exit={{ scale: 1 }}
      initial={{ scale: 1 }}
      transition={{
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 1,
        ease: 'easeInOut',
      }}
      onClick={() => !loading && getStarted()}
    >
      {loading || isTravelPointsUpdated ? (
        <Fragment>Syncing to server...</Fragment>
      ) : (
        <Fragment>
          {selectedPlayData === undefined
            ? 'Vizualize Whole Trip'
            : `Vizualize Day ${selectedPlayData.day}`}
        </Fragment>
      )}
    </motion.div>
  );
};

export default MobileVizualizeButton;
