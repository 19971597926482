import { signal } from '@preact/signals-core';
import { initialAudioState } from '../VideoPopup/constants';

//  Initialize signal
export const addMusicSignal = signal<{
  id: string;
  name: string;
  size: number;
  type: string;
  url: string;
  duration: number;
}>(initialAudioState);
