import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import './logomodal.css';
import FeedbackPopup from '~/components/FeedbackPopup';
import { useIsMobile } from '../counter/hooks/useMobile';

interface LogoModalProps {
  open: boolean;
  onClose: () => void;
  logo: string;
}

export const LogoModal: React.FC<LogoModalProps> = ({
  open,
  onClose,
  logo,
}) => {
  const isMobile = useIsMobile();

  const [logoBottom, setLogoBottom] = useState('min(30vh, 300px)'); // Default value

  // Function to calculate the bottom position based on the footer height
  const calculateBottomPosition = useCallback(() => {
    let footerElement: HTMLElement | null = null;
    let offset = 5; // Default offset for mobile

    if (isMobile) {
      footerElement = document.querySelector('.mobile-footer');
    } else {
      footerElement = document.querySelector('.desktop-footer');
      offset = 30; // Default offset for desktop
    }

    if (footerElement) {
      const footerHeight = footerElement.offsetHeight;
      const newBottom = `${offset + footerHeight}px`;
      setLogoBottom(newBottom);
    }
  }, [isMobile]);

  useEffect(() => {
    calculateBottomPosition();
    // Optionally, re-calculate on window resize to adjust dynamically
    window.addEventListener('resize', calculateBottomPosition);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', calculateBottomPosition);
    };
  }, [calculateBottomPosition]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        // Use `BackdropComponent` to create a semi-transparent backdrop
        components={{
          Backdrop: () => (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'transparent',
                pointerEvents: 'none', // Allow clicks through the backdrop
              }}
            />
          ),
        }}
        sx={{
          position: 'absolute', // Position the element as absolute
          right: 0, // Align it to the right side of the container
          top: '50%', // Center it vertically
          transform: 'translateY(-50%)', // Offset its position by half its height
          width: 'auto', // Adjust width as needed
          height: '100%', // Or adjust height as needed
          zIndex: 500, // Ensure it is above other content
          pointerEvents: 'none', // Allow clicks through the element itself
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: 'auto',
            left: isMobile ? '1.2rem' : '2rem',
            zIndex: 500,
            // bottom: isMobile ? '220px' : '130px', // Adjust based on view height
            bottom: logoBottom,
            marginBottom: '1rem',
            background: 'transparent',
            pointerEvents: 'auto', // Allow clicks on the modal content
            overflow: 'hidden',
          }}
        >
          {/* LOGO */}
          <img
            src={logo}
            alt="Logo"
            style={{
              // bottom: isMobile ? 'calc(100dvh - 210px)' : 'calc(100dvh - 20px)', // Adjust based on view height

              display: 'block', // Ensures that the image is centered in its container if needed
              maxWidth: isMobile ? '120px' : '120px', // Set maximum width for mobile and desktop
              height: '60px', // Maintain aspect ratio
              objectFit: 'contain',
              background: 'transparent', // Ensures background remains transparent
              borderRadius: '8px', // Optional: Add rounded corners for a softer look
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Add a subtle shadow to make it stand out
            }}
          />
        </Box>
      </Modal>
      <FeedbackPopup />
    </>
  );
};
