import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { commonButtonsSx } from '~/components/P2PAI/MapPreview/BottomSheetp2p';
import { GetCurrentCategoryType, TypeOfPointInterface } from '../helpers';
import { P2PTypeOfPointModal } from '../P2PTypeOfPointModal';

import { Options } from '~/utility/models';

// Initialize dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: '30px 15px 15px 15px',
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

const AddButton = styled(Button)({
  width: 313,
  height: 56,
  borderRadius: 18,
  fontFamily: 'Poppins',
  fontSize: '16px',
  backgroundColor:
    'radial-gradient(100% 100% at 0% 0%, #FF9345 0.01%, #FE7138 50%, #F25D21 100%)',
  boxShadow: `0px 2px 6px #b8c5d033, 0px 6px 22px #b8c5d04d, 
              0px 9px 30px #c6d8e933, inset 0px 1.5px 1.5px #ffffff40`,
  '&:hover': { backgroundColor: '#e6602b' },
});

const BackButton = styled(Button)({
  width: 150,
  height: 54,
  borderRadius: 18,
  fontFamily: 'Poppins',
  fontSize: '16px',
  margin: '0px',
  backgroundColor: '#fff',
  background: 'none',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#f5f5f5', // Lighter shade for subtle hover effect
  },
});

const TypeButton = styled(Button)({
  width: 150,
  borderRadius: 18,
  fontFamily: 'Poppins',
  fontSize: '16px',
  margin: '0px',
  background:
    ' linear-gradient(180deg, rgba(255, 255, 255, 0) -9.58%, rgba(255, 255, 255, 0.52) 100%)',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: `0px 2px 6px #b8c5d033, 0px 6px 22px #b8c5d04d, 
  0px 9px 30px #c6d8e933, inset 0px 1.5px 1.5px #ffffff40`,
  '&:hover': {
    backgroundColor: '#f5f5f5', // Lighter shade for subtle hover effect
  },
});

interface P2PSelectedLocationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (selectedType: TypeOfPointInterface | null) => void;
  selectedLocation?: Options;
  placeType: string | null;
  isSaving: boolean;
}

export interface SelectedTypeInterface {
  type: string | null;
  imagePath: any;
}
export const P2PSelectedLocationModal: FC<P2PSelectedLocationModalProps> = ({
  open,
  onClose,
  onConfirm,
  selectedLocation,
  placeType,
  isSaving,
}) => {
  const [selectedType, setSelectedType] = useState<TypeOfPointInterface | null>(
    null,
  );

  const [openSelectTypeModal, setOpenSelectTypeModal] =
    useState<boolean>(false);

  const handleSelectTypeOpen = () => {
    setOpenSelectTypeModal(true);
  };

  const handleSelectedType = (type: TypeOfPointInterface) => {
    setSelectedType(type);
  };

  const handleClose = () => {
    setSelectedType(null);
    onClose();
  };

  useEffect(() => {
    const selectedCategory = GetCurrentCategoryType(placeType);
    setSelectedType(selectedCategory);
  }, [placeType]);

  return (
    <StyledDialog
      open={open}
      onClose={!isSaving ? onClose : undefined}
      disableEscapeKeyDown={isSaving}
    >
      <DialogTitle>
        <Typography
          variant="h5"
          sx={{
            color: '#ff8447',
            fontFamily: 'Poppins, sans-serif',
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '36px',
          }}
        >
          {selectedLocation?.label}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="h5"
          sx={{
            color: '#000',
            fontFamily: 'Poppins, sans-serif',
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '36px',
            mb: 4,
          }}
        >
          Address : {selectedLocation?.street || 'Romeo Vachon, Dorval, QC'}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: '#000',
              fontFamily: 'Poppins, sans-serif',
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '36px',
            }}
          >
            Type of Point :
          </Typography>

          <TypeButton onClick={handleSelectTypeOpen}>
            <Typography
              variant="h5"
              sx={{
                color: '#84858e',
                fontFamily: 'Poppins, sans-serif',
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '36px',
                textTransform: 'none',
              }}
            >
              Select a type
            </Typography>
            {selectedType ? (
              <img
                src={selectedType?.icon}
                style={{ width: 50, height: 50 }}
                alt={selectedType?.label}
              />
            ) : (
              <img
                src="/emptyType.png"
                style={{ width: 50, height: 50 }}
                alt="No Type Selected"
              />
            )}
          </TypeButton>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <AddButton
          onClick={() => onConfirm(selectedType)}
          disabled={!selectedType || isSaving}
          variant="contained"
          sx={{ ...commonButtonsSx, height: 56, width: 313 }}
        >
          {isSaving ? 'Adding...' : 'Add Point'}
        </AddButton>

        <BackButton onClick={handleClose} disabled={isSaving}>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: '#FE7138', fontFamily: 'Poppins, sans-serif' }}
          >
            Go Back
          </Typography>
        </BackButton>
      </DialogActions>

      <P2PTypeOfPointModal
        open={openSelectTypeModal}
        onClose={() => {
          setOpenSelectTypeModal(false);
        }}
        onSave={handleSelectedType}
        selectedPlaceType={selectedType}
      />
    </StyledDialog>
  );
};
