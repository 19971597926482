import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import { fetchAndStoreUserID } from '~/utility/utils';

const useIsAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const dispatch = useDispatch();

  const userID: string = useSelector(
    (state: RootState) => state.MapReducers.userID,
  );

  const checkAuthentication = async () => {
    const userId = await fetchAndStoreUserID(dispatch);
    setIsAuthenticated(userId ? true : false);
  };

  useEffect(() => {
    if (userID === null || userID === '') {
      checkAuthentication();
    } else {
      setIsAuthenticated(userID ? true : false);
    }
    return () => {
      setIsAuthenticated(null);
    }
  }, [userID]);

  return isAuthenticated;
};

export default useIsAuth;
