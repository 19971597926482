import { signal } from '@preact/signals-core';
import { useEffect, useMemo, useState } from 'react';
import mapStyles from '~/map/utility/mapStyles';
import maplibregl, { LngLat, LngLatBounds, LngLatLike } from 'maplibre-gl';
import { URLConfig } from '~/utility/models';
import { DiscoveryMapHeader } from './DiscoveryMapHeader';
import rawData from '../data/tokyoData.json';
import { TiktokItem } from '../TiktokItem/TiktokItem';
import { useParams } from 'react-router-dom';

import './DiscoverMap.css';
import { getMarkerIcon } from '~/animationEngine/StaticTravelVisualizer/getCustomIcon';

export const markersSignal = signal<
  { id: string; marker: maplibregl.Marker }[]
>([]);

export const previewMapSignal = signal<maplibregl.Map | null>(null);

export type FilterType = 'activity' | 'food' | null;

export const DiscoverMap = () => {
  const params = useParams();

  const dataToShow = [...rawData.activities, ...rawData.restaurants].map(
    (item, index) => ({ ...item, storyIndex: index + 1 }),
  );
  const [filter, setFilter] = useState<FilterType>(
    () => (params.id as FilterType) || null,
  );
  const [currentStoryIndex, setCurentStoryIndex] = useState<number | undefined>(
    undefined,
  );
  const [isStoriesVisible, setIsStoriesVisible] = useState(false);

  const handleShowStories = (storyIndex: number) => {
    setCurentStoryIndex(storyIndex);
    setIsStoriesVisible(true);
  };

  const handleNext = (currentValue: number) => {
    setCurentStoryIndex(-1);
    setTimeout(() => {
      setCurentStoryIndex((_prev) => {
        if (currentValue === dataToShow.length) {
          return 1;
        }
        return currentValue! + 1;
      });
    }, 50);
  };

  const handlePrev = (currentValue: number) => {
    setCurentStoryIndex(-1);
    setTimeout(() => {
      setCurentStoryIndex((_prev) => {
        return currentValue! - 1;
      });
    }, 50);
  };

  // const dataToShow = filter
  //   ? data.filter((item) => item.category === filter)
  //   : data;

  useEffect(() => {
    // @ts-ignore
    if (!previewMapSignal.peek()) {
      // Check if device is mobile
      const isMobile = window.innerWidth <= 768;
      const initialZoom = isMobile ? 12 : 13;

      const map = new maplibregl.Map({
        container: 'map',
        style: (mapStyles[1] as URLConfig).isURL
          ? ((mapStyles[1] as URLConfig).URL as string)
          : (mapStyles[1] as maplibregl.StyleSpecification),
        attributionControl: false,
        maplibreLogo: false,
        center: [
          139.7527995,
          35.685175
        ],
        zoom: initialZoom,
        minZoom: 10,
      });

      const markersArrayIds = Array.from(dataToShow, (place) =>
        place.coords.toString(),
      );

      map.on('dragstart', () => {
        // console.log('123 drag start');
      });
      map.on('dragend', () => {
        // console.log('123 drag end');
      });

      map.on('load', () => {
        dataToShow.forEach((marker, index) => {
          const customIcon = `
              <div 
                style="display: flex; flex-direction: column; align-items: center; position: relative"
                class='activity-marker ${marker.category}'
              >
                <div class="marker-wrapper"> 
                  ${getMarkerIcon({
            category: marker.category,
            indexOfMarker: index,
            travelArrayIds: markersArrayIds,
            imageLink: marker.media[0].thumbnail,
            isNoNumberBadge: true,
          })}
                </div>
                <div class="marker-label-wrapper ${marker.category === 'food'
              ? 'food-marker-wrapper'
              : 'activity-marker-wrapper'
            }">${`<span class="marker-label">${marker.name}</span>`}<span> - ${marker.review
            }⭐</span></div>
              </div>
              `;
          // create a DOM element for the marker
          const el = document.createElement('div');
          el.innerHTML = customIcon;
          el.className = 'marker';
          // el.style.backgroundImage = `url(https://picsum.photos/${marker.properties.iconSize.join(
          //   '/',
          // )}/)`;

          el.addEventListener('click', () => {
            // Open details of place
            // window.alert(marker.name);
            handleShowStories(marker.storyIndex);
          });

          new maplibregl.Marker({ element: el })
            // @ts-ignore
            .setLngLat(marker.coords)
            // @ts-ignore
            .addTo(map);

          if (filter) {
            manageMarkers(filter);
          }
        });
      });
    }
  }, []);

  const memoizedMap = useMemo(() => {
    return <div id="map" style={{ height: '100%' }} />;
  }, []);

  const handleFilter = (filterValue: FilterType) => {
    setFilter(filterValue);
  };

  const hideMarkers = (markerClass: string) => {
    let markers = document.getElementsByClassName(markerClass);
    for (let i = 0; i < markers.length; i++) {
      // @ts-ignore
      markers[i].style.visibility = 'hidden';
    }
  };
  const showMarkers = (markerClass: string) => {
    let markers = document.getElementsByClassName(markerClass);
    for (let i = 0; i < markers.length; i++) {
      // @ts-ignore
      markers[i].style.visibility = 'visible';
    }
  };

  function manageMarkers(filter: string) {
    const filteredMarkers = ['activity', 'food'].filter(
      (marker) => marker !== filter,
    );
    hideMarkers(filteredMarkers[0]);
    showMarkers(filter);
  }

  useEffect(() => {
    if (!filter) {
      showMarkers('activity-marker');
    } else {
      manageMarkers(filter);
    }
  }, [filter]);

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <DiscoveryMapHeader setFilter={handleFilter} activeFilter={filter} />
      <div
        style={{
          height: 'inherit',
          width: 'inherit',
        }}
      >
        {memoizedMap}
      </div>
      {currentStoryIndex && currentStoryIndex !== dataToShow.length + 1 && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            background: '#000',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 999999,
          }}
        >
          {currentStoryIndex !== -1 && (
            <TiktokItem
              content={
                isStoriesVisible
                  ? dataToShow.find(
                    (story) => story.storyIndex === currentStoryIndex,
                  )
                  : undefined
              }
              goNext={handleNext}
              goPrev={handlePrev}
              handleClose={() => {
                setIsStoriesVisible(false);
                setCurentStoryIndex(undefined);
              }}
              currentStoryIndex={currentStoryIndex}
            />
          )}
        </div>
      )}
    </div>
  );
};
