import React, { useState, useEffect, useRef, Fragment } from 'react';
import {
  AnimatePresence,
  motion,
  PanInfo,
  useAnimation,
  useMotionValue,
  Variant,
} from 'framer-motion';
import './style.css';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';

import { isOverlayVisible, TravelPoint } from '../StatsOverlay';
import { signal } from '@preact/signals-core';
import {
  mobile4thImagePostionSignal,
  PauseAnimation,
  PlayAnimation,
  showPlayPauseButton,
  animationIsPlaying,
  isVizualizingSignal,
} from '../common';
import PlayPauseButton from '~/components/PlayPauseButton';
import OverviewDataComponent, { OverviewData } from './OverviewDataComponent';
import {
  initialItineraryRender,
  mapSignal,
  restartDaySignal,
} from '~/map/ViewTravel';
import DaysHeader, { selectedTravelDaySignal } from './DaysHeader/DaysHeader';
import PointsHerader from './PointsHeader';
import { PointLike } from 'maplibre-gl';
import MobileVizualizeButton from './MobileVizualizeButton';
import HeaderDescription from './BottomSheetHeaderDescription';
import { useIsMobile } from '../counter/hooks/useMobile';
import { encodeTourID, getTravelPointsArray } from '~/utility/utils';
import MakeYourOwnModal from '../MakeYourOwnModal';
import {
  fireOpenBottomSheet,
  fireResetTrip,
  fireSetBottomSheetOverviewData,
  fireSetBottomSheetState,
  fireSetIsMarkerClicked,
  fireShowSignupDialog,
  selectBottomSheetOverviewData,
  selectBottomSheetState,
  selectIsBottomSheetFullyOpened,
  selectIsBottomSheetHalfOpened,
  selectIsBottomSheetHidden,
  selectIsMarkerClicked,
  selectShowSignupDialog,
} from '~/redux/reducers/ui.slice';
import {
  selectAllDaysData,
  selectOneDayForBottomSheet,
  selectTravelRawData,
} from '~/redux/selectors/tripInfoSelectors';
import { store, useDispatch } from '~/redux/store';
import { TravelOptions, TravelPointsByDayIndex } from '~/utility/models';
import { MANUAL_TRIP_HISTORY } from '~/components/P2PManual';
import ActionsCreator from '~/redux/actions';
import { Box } from '@mui/material';
import SignupDialog from '~/components/P2PAI/MapPreview/SignUpDialog';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '~/routes';
import { showOverviewMenu } from '~/components/OverviewMenu';
import subscribeSignal from '~/hooks/subscribeSignal';
import { selectUserID } from '~/redux/selectors/mapSelectors';
import { selectPlayPauseState } from '~/redux/selectors/animationSelectors';
import { selectIsSavingToHistory } from '~/redux/selectors/P2PManualSelectors';
import {
  EBottomSheetState,
  TBottomSheetState,
  BOTTOM_SHEET_STATES,
} from './types';

export const mapOffset = signal<PointLike>([0, 0]);

export const isAuthenticated = () => {
  return (
    store.getState().MapReducers.userID &&
    store.getState().MapReducers.userEmail
  );
};

const BOTTOM_SHEET_OPTIONS = {
  visible: { y: 100 },
  half: { y: window.innerHeight / 2 },
  hidden: { y: window.innerHeight - 224 },
} satisfies Record<TBottomSheetState, Variant>;

const Wrapper = ({
  draggable,
  setOpened,
  isAnimationIdle,
  travelData,
  setStep,
  startTrip,
}: React.PropsWithChildren<{
  draggable: boolean;
  setOpened: any;
  travelData: TravelPoint[];
  isAnimationIdle: boolean;
  setStep: (v: string) => void;
  startTrip?: (v?: number) => void;
}>) => {
  const bottomSheetRef = useRef<HTMLDivElement>();
  const [overviewData, setOverviewData] = useState<OverviewData | null>(null);
  const controls = useAnimation();
  const [isOverlay, setIsOverlay] = useState(isOverlayVisible.peek());

  const showSignupDialog = useSelector(selectShowSignupDialog);
  const isMarkerClicked = useSelector(selectIsMarkerClicked);
  const bottomSheetOverviewData = useSelector(selectBottomSheetOverviewData);
  const allDaysData = useSelector(selectAllDaysData);
  const oneDayData = useSelector(
    selectOneDayForBottomSheet(
      selectedTravelDaySignal.peek()?.format('DD-MM-YYYY'),
    ),
  );
  const isSavingToHistory = useSelector(selectIsSavingToHistory);
  const playPauseState = useSelector(selectPlayPauseState);
  const travelRawData = useSelector(selectTravelRawData);
  const userId = useSelector(selectUserID);
  const bottomSheetState = useSelector(selectBottomSheetState);
  const isBottomSheetFullyOpened = useSelector(selectIsBottomSheetFullyOpened);
  const isBottomSheetHalfOpened = useSelector(selectIsBottomSheetHalfOpened);
  const isBottomSheetHidden = useSelector(selectIsBottomSheetHidden);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tourID } = useParams();

  const isMobile = useIsMobile();

  subscribeSignal(isOverlayVisible, setIsOverlay);

  const prev = useRef(0);
  const animatedY = useMotionValue(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [overviewMenuVisible, setOverviewMenuVisible] = useState(false);

  useEffect(() => {
    controls.start(BOTTOM_SHEET_OPTIONS[BOTTOM_SHEET_STATES[bottomSheetState]]);
    if (isBottomSheetHidden) {
      onClose();
    } else if (
      isBottomSheetHalfOpened &&
      prev.current !== EBottomSheetState.visible
    ) {
      onOpen();
    }
  }, [bottomSheetState]);

  subscribeSignal(animationIsPlaying, setIsPlaying);
  subscribeSignal(showOverviewMenu, setOverviewMenuVisible);

  const contentRef = useRef<HTMLDivElement>(null);
  const navbarref = useRef<HTMLDivElement>(null);

  function onClose() {
    mapOffset.value = [0, 0];
    if (!isAnimationIdle) {
      mapSignal.value?.panTo(mapSignal.value?.getCenter(), {
        offset: [0, window.innerHeight * 0.2],
      });
    }
    setOpened(false);
    dispatch(fireSetIsMarkerClicked(false));

    dispatch(fireSetBottomSheetOverviewData(null));
    if (showPlayPauseButton.value && !isPlaying) {
      isOverlayVisible.value = false;
      PlayAnimation();
      setTimeout(() => {
        PlayAnimation();
      }, 0);
    }
  }

  function onOpen() {
    mapOffset.value = [0, -window.innerHeight * 0.2];
    if (!isAnimationIdle) {
      mapSignal.value?.panTo(mapSignal.value?.getCenter(), {
        offset: mapOffset.value,
      });
    }
    if (showPlayPauseButton.value && isPlaying) {
      PauseAnimation();
    }

    setOpened(true);
    if (!isMarkerClicked) {
      dispatch(
        fireOpenBottomSheet({
          data: selectedTravelDaySignal.value ? oneDayData[0] : allDaysData[0],
        }),
      );
    }
  }

  const onDragEnd = (_: PointerEvent, info: PanInfo) => {
    const y = bottomSheetRef.current?.getBoundingClientRect().top || 0;

    const velocity =
      Math.abs(info.velocity.y) > Math.abs(info.velocity.x)
        ? info.velocity.y
        : 0;

    let dragPosition = EBottomSheetState.hidden;
    if (y >= window.innerHeight * 0.75) {
      dragPosition = EBottomSheetState.hidden;
    } else if (y < window.innerHeight * 0.75 && y > window.innerHeight * 0.35) {
      dragPosition = EBottomSheetState.half;
    } else {
      dragPosition = EBottomSheetState.visible;
    }

    let velocityPosition: EBottomSheetState =
      velocity > 100
        ? Math.max(EBottomSheetState.hidden, bottomSheetState - 1)
        : velocity < -100
        ? Math.min(EBottomSheetState.visible, bottomSheetState + 1)
        : dragPosition;

    if (velocity === 0) {
      velocityPosition = dragPosition;
    }
    controls.start(BOTTOM_SHEET_OPTIONS[BOTTOM_SHEET_STATES[velocityPosition]]);
    prev.current = bottomSheetState;
    dispatch(fireSetBottomSheetState(velocityPosition));
  };

  subscribeSignal(showPlayPauseButton, () => {
    if (showPlayPauseButton.peek()) {
      BOTTOM_SHEET_OPTIONS.hidden = { y: window.innerHeight };
    } else {
      BOTTOM_SHEET_OPTIONS.hidden = { y: window.innerHeight - 224 };
    }
    dispatch(fireSetBottomSheetState(0));
    controls.start('hidden');
  });

  useEffect(() => {
    setOverviewData(bottomSheetOverviewData);
    prev.current = bottomSheetState;
    if (bottomSheetOverviewData) {
      !animationIsPlaying.peek() &&
        mapSignal.peek()?.flyTo({
          center: bottomSheetOverviewData?.location,
          offset: [0, -window.innerHeight * 0.2],
          zoom: 14,
        });
      dispatch(
        fireSetBottomSheetState(
          isBottomSheetHidden ? EBottomSheetState.half : bottomSheetState,
        ),
      );
    } else {
      dispatch(fireSetBottomSheetState(EBottomSheetState.hidden));
    }
  }, [bottomSheetOverviewData]);

  useEffect(() => {
    const updateTranslateValue = () => {
      if (contentRef.current && navbarref.current) {
        mobile4thImagePostionSignal.value =
          contentRef.current.clientHeight +
          navbarref.current.clientHeight +
          50 +
          'px';
      }
    };
    setTimeout(updateTranslateValue, 1000);
  }, []);

  const handleVisibilityChange = (e: any) => {
    if (document.hidden && showPlayPauseButton.value && isMobile) {
      dispatch(fireResetTrip(true));
      restartDaySignal.value = true;
      dispatch(fireSetBottomSheetState(EBottomSheetState.hidden));
      isOverlayVisible.value = true;
    }
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () =>
      window.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  const onComplete = async () => {
    const state = store.getState();
    const { travelPointsByDayIndex, isSavingToHistory } =
      state.P2PManualReducers;
    const { travelRawData } = state.TripInfoReducers;
    const { userID } = state.MapReducers;

    const travelPoints: TravelOptions[] = getTravelPointsArray(
      travelPointsByDayIndex,
    );

    // Update general states
    dispatch(ActionsCreator.setHeaderState(true));
    dispatch(ActionsCreator.setMapButtonState(true));
    dispatch(ActionsCreator.setIsTravelPointSelectedState(true));

    // Handle user-specific travel data
    if (travelRawData?.userId === userID) {
      updateExistingTravelData(travelRawData);
    } else {
      dispatch(ActionsCreator.setCurrentHistoryId(-1));
      await handleNewTravelData(
        travelRawData,
        travelPoints,
        travelPointsByDayIndex,
      );
    }

    // Update travel day indices
    const lastDayIndex = travelPoints[travelPoints.length - 1]?.dayIndex || 0;
    dispatch(ActionsCreator.setCurrentDayIndex(lastDayIndex));
    dispatch(ActionsCreator.setLastVisitedDayIndex(lastDayIndex));

    // Handle session storage and navigation
    if (!isSavingToHistory) {
      sessionStorage.setItem(
        MANUAL_TRIP_HISTORY,
        JSON.stringify(state.P2PManualReducers),
      );
      initialItineraryRender.value = false;

      if (tourID) {
        let tourIDtoNumber = Number(tourID);
        return navigate(
          `${ROUTES.VIEWTRAVEL.path}?tourID=${encodeTourID(tourIDtoNumber)}`,
        );
      }
    }
  };

  // Helper: Update existing travel data
  const updateExistingTravelData = (travelRawData: any) => {
    dispatch(
      ActionsCreator.setPulishedTravelId(travelRawData.publishedId || 0),
    );
    dispatch(
      ActionsCreator.setCurrentHistoryIdentifier(
        travelRawData.identifier || '',
      ),
    );
  };

  // Helper: Handle new travel data creation
  const handleNewTravelData = async (
    travelRawData: any,
    travelPoints: TravelOptions[],
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) => {
    resetTravelData();

    dispatch(
      ActionsCreator.setTravelRawData(
        JSON.stringify({ ...travelRawData, publishedId: 0 }),
      ),
    );

    await dispatch(ActionsCreator.saveP2PTravelPoint(travelPoints));
    await dispatch(
      ActionsCreator.createP2PTravelPointVisualization(travelPointsByDayIndex),
    );
  };

  const saveOrEditTrip = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) => {
    e.stopPropagation();

    const travelPointsArrays: TravelOptions[] = getTravelPointsArray(
      travelPointsByDayIndex,
    );

    // Set common states
    dispatch(ActionsCreator.setTravelTitle(travelRawData?.title));
    dispatch(
      ActionsCreator.setCurrentDayIndex(
        travelPointsArrays[travelPointsArrays.length - 1]?.dayIndex || 0,
      ),
    );
    dispatch(
      ActionsCreator.setLastVisitedDayIndex(
        travelPointsArrays[0]?.dayIndex || 0,
      ),
    );

    const historyId = store.getState().P2PManualReducers.currentHistoryId;

    // Update states based on authentication
    if (isAuthenticated()) {
      dispatch(ActionsCreator.setHeaderState(true));
      dispatch(ActionsCreator.setMapButtonState(true));
      dispatch(ActionsCreator.setIsTravelPointSelectedState(true));
      dispatch(ActionsCreator.setCurrentHistoryId(historyId));
      initialItineraryRender.value = false;
      if (travelRawData?.userId !== userId) {
        resetTravelData();
        // dispatch(ActionsCreator.setCurrentHistoryId(-1));
        await handleNewTravelData(
          travelRawData,
          travelPointsArrays,
          travelPointsByDayIndex,
        );
        // add delay to allow the trip to be added in db
        setTimeout(() => {
          navigate(ROUTES.HOME.path);
        }, 1000);
      } else {
        isOverlayVisible.value = true;
        showOverviewMenu.value = true;
      }
    } else {
      resetTravelData();
      dispatch(ActionsCreator.setCurrentHistoryId(historyId));
      dispatch(fireShowSignupDialog(true));
    }

    PauseAnimation();
  };

  // Helper function to reset travel data
  const resetTravelData = () => {
    dispatch(ActionsCreator.setPublishedTravelLink(''));
    dispatch(ActionsCreator.setCurrentHistoryId(-1));
    dispatch(ActionsCreator.setCurrentHistoryIdentifier(''));
    dispatch(ActionsCreator.setPulishedTravelId(0));
  };

  return (
    <>
      {/* SIGNUP DIALOG  */}
      <SignupDialog
        open={showSignupDialog}
        onClose={() => dispatch(fireShowSignupDialog(false))}
        title="To Save This Trip..."
        onComplete={onComplete}
      />

      {isBottomSheetFullyOpened && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0, 0.5)',
            cursor: 'pointer',
          }}
          onClick={() => {
            dispatch(fireSetBottomSheetOverviewData(null));
          }}
        />
      )}

      {overviewData &&
        !animationIsPlaying.peek() &&
        !isBottomSheetFullyOpened && (
          <div
            className="mobile-header-go-back-btn"
            style={{
              position: 'fixed',
              left: window.innerWidth < 768 ? '10px' : '30%',
              top: '20px',
            }}
            onClick={() => {
              dispatch(fireSetBottomSheetOverviewData(null));
              isVizualizingSignal.value = false;
            }}
          >
            <img src="./goBackNew.png" width={40} height={33} alt="go-back" />
          </div>
        )}

      <MakeYourOwnModal />

      <AnimatePresence>
        <motion.div
          //@ts-ignore
          ref={bottomSheetRef}
          dragDirectionLock
          drag="y"
          onDragEnd={onDragEnd}
          initial={BOTTOM_SHEET_OPTIONS.hidden}
          animate={controls}
          transition={{
            type: 'spring',
            damping: 40,
            stiffness: 400,
          }}
          onTouchStart={() => {
            if (!isOverlayVisible.peek() && !animationIsPlaying.peek())
              isOverlayVisible.value = true;
          }}
          variants={BOTTOM_SHEET_OPTIONS}
          dragConstraints={{ top: 0 }}
          dragElastic={0.2}
          style={{
            y: animatedY,
            position: 'fixed',
            opacity: isOverlay ? 1 : 0.4,
            display: isOverlay
              ? 'block'
              : animationIsPlaying.peek()
              ? 'block'
              : 'none',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 999,
            backgroundColor: 'white',
            width: isMobile ? '100%' : '40%',
            margin: 'auto',
            height: window.innerHeight,
            border: '1px solid #E0E0E0',
            boxShadow:
              '0px 2px 5px rgba(0, 0, 0, 0.06), 0px 2px 13px rgba(0, 0, 0, 0.12)',
            borderRadius: '15px 15px 0px 0px',
            paddingRight: '5px',
            paddingLeft: '5px',
            fontFamily: 'Poppins',
          }}
        >
          {showPlayPauseButton.value && (
            <PlayPauseButton
              playPauseState={playPauseState}
              bottom={window.innerHeight + 20}
              left={'-100px'}
              type={'mobile'}
              animatedY={animatedY}
            />
          )}
          {travelData &&
            travelData[0]?.dayIndex &&
            travelRawData?.identifier &&
            !showPlayPauseButton.value &&
            !isBottomSheetFullyOpened && (
              <Fragment>
                {!overviewMenuVisible && (
                  <Box
                    className="makeYourOwnButton"
                    onClick={(e) =>
                      saveOrEditTrip(
                        e,
                        store.getState().P2PManualReducers
                          .travelPointsByDayIndex,
                      )
                    }
                    sx={{
                      pointerEvent: isSavingToHistory ? 'none' : 'auto',
                    }}
                  >
                    <span className="makeYourOwnText">
                      {travelRawData?.userId === userId ? (
                        <span className="flex justify-items-center align-items-center">
                          <svg
                            className="me-1"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.868 24L24 6.86802L17.1319 0L0 17.1321V24H6.868ZM17.1319 3.02854L20.9715 6.86802L18.2027 9.63682L14.3633 5.7973L17.1319 3.02854ZM12.8489 7.31156L16.6885 11.151L5.98097 21.8585H2.1415V18.0191L12.8489 7.31156Z"
                              fill="white"
                            />
                          </svg>
                          Edit{' '}
                        </span>
                      ) : isSavingToHistory ? (
                        'Saving'
                      ) : (
                        'Save'
                      )}
                    </span>
                  </Box>
                )}
              </Fragment>
            )}

          <motion.div
            className="DragHandleEdge"
            style={{
              touchAction: 'none',
              paddingTop: 5,
              paddingBottom: 5,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <motion.div
              className="DragHandle"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.15 }}
            />
          </motion.div>
          {!overviewData && <HeaderDescription travelData={travelData} />}
          <motion.div
            style={{
              height: 75,
              overflow: 'hidden',
            }}
            ref={navbarref}
          >
            <motion.div
              animate={{ y: 0 }}
              initial={{ y: 0 }}
              transition={{ type: 'tween' }}
            >
              <div
                style={{
                  height: 80,
                  overflow: 'hidden',
                }}
              >
                <AnimatePresence>
                  <motion.div
                    animate={!isBottomSheetHidden ? { y: -80 } : { y: 0 }}
                    transition={{ duration: 0.3 }}
                    initial={{ y: 0 }}
                  >
                    <DaysHeader travelData={travelData} />
                    <PointsHerader travelData={travelData} />
                  </motion.div>
                </AnimatePresence>
              </div>
              {/* <PlayHeader travelData={travelData} /> */}
            </motion.div>
          </motion.div>
          <div
            ref={contentRef}
            className="p-1"
            style={{
              overflow: draggable ? 'hidden' : 'visible',
            }}
          >
            {!overviewData ? (
              !showPlayPauseButton.peek() ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <MobileVizualizeButton
                    setStep={setStep}
                    startTrip={(v) => startTrip?.(v)}
                  />
                </div>
              ) : null
            ) : (
              <OverviewDataComponent />
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const TripTo = ({
  to,
  startDate,
  endDate,
}: {
  to: string;
  startDate: string;
  endDate: string;
}) => {
  const userName: string = useSelector(
    (state: RootState) => state.TripInfoReducers.travelRawData?.userName,
  );
  return (
    <>
      <div className="TripTOMobileBottomSheet flex-1">
        <div className="d-flex">
          <span>Trip to </span>&nbsp;<b> {to} </b>
        </div>
        <div className="d-flex">
          {userName && (
            <>
              <span>Made by&nbsp;</span>
              <b>{userName}</b>
            </>
          )}
        </div>
      </div>
    </>
  );
};

TripTo.displayName = 'TripTo';

export default {
  Wrapper,
  TripTo,
};
