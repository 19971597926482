import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import 'swiper/css';
import { signal } from '@preact/signals-core';
import { TravelPoint } from '../../StatsOverlay';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { Swiper as SwiperType } from 'swiper/types';
import { uniqBy } from 'lodash';
import ActionsCreator from '~/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectShouldShowFirstDay } from '~/redux/selectors/travelSelectors';

import './style.css';
import dayjs, { Dayjs } from 'dayjs';
import subscribeSignal from '~/hooks/subscribeSignal';

export interface VizualizeButtonData {
  day: number;
  startIndex: number;
  endIndex: number;
}

export const selectedTravelDaySignal = signal<Dayjs | undefined>();
export const selectedTravelDayIndexSignal = signal<number | undefined>();
export const showDayHeader = signal(0);
export const vizualizeButtonSignal = signal<VizualizeButtonData | any>();

const DaysHeader = ({
  travelData,
  onDayPress,
  initialDay,
}: {
  travelData: TravelPoint[];
  onDayPress?: (v: Dayjs) => void;
  initialDay?: Dayjs;
}) => {
  const [selectedDay, setSelectedDay] = useState<string | undefined>(
    undefined,
  );
  const swiperRef = useRef<SwiperType>();
  const dispatch = useDispatch();
  const shouldShowFirstDay = useSelector(selectShouldShowFirstDay);
  const lastTravelDayLengthRef = useRef<number | undefined>();
  const [travelDays, setTravelDays] = useState<{ date: Dayjs; index: number, dayIndex: string | undefined }[]>([]);

  const [selectedTravelDayIndex, setSelectedTravelDayIndex] = useState<number | undefined>();

  useEffect(() => {

    const uniqueTravelDays = uniqBy(
      travelData.map((i, idx) => ({
        date: dayjs(String(i.departure.dateTime)).tz(i.departure.timezone),
        dayIndex: i.dayIndex,
        index: idx,
        timezone: i.departure.timezone,
      })),
      (u) => u.dayIndex,
    ).map((i) => ({
      date: i.date,
      index: i.index,
      dayIndex: i.dayIndex
    }))

    if (lastTravelDayLengthRef.current && lastTravelDayLengthRef.current !== uniqueTravelDays.length && selectedTravelDayIndexSignal.peek()) {
      console.log('setting up as last day', uniqueTravelDays.length - 1, '===>>>', lastTravelDayLengthRef.current);
      const shouldDecrement = uniqueTravelDays.length < lastTravelDayLengthRef.current
      selectedTravelDayIndexSignal.value = shouldDecrement ? selectedTravelDayIndexSignal.peek() as number - 1 : lastTravelDayLengthRef.current + 1
    }
    lastTravelDayLengthRef.current = uniqueTravelDays.length;
    setTravelDays(uniqueTravelDays)

  }, [travelData]);




  // useSignalEffect(() => {
  //   setSelectedDay(selectedTravelDaySignal.value);
  // });

  // subscribeSignal(selectedTravelDaySignal, (day) => {

  //   console.log('selectedTravelDayIndexSignal', day);
  //   if (!day) return;
  //   const i = travelDays[day];
  //   if (typeof onDayPress === 'function') {
  //     onDayPress(i.date);
  //     setSelectedDay(i.date);
  //   } else {
  //     handleDay(i, day);
  //   }
  //   dispatch(ActionsCreator.setCurrentTravelDay(day + 1));


  // });

  subscribeSignal(selectedTravelDayIndexSignal, setSelectedTravelDayIndex)

  // subscribeSignal(selectedTravelDayIndexSignal, (day) => {
  //   if (!day) return;
  //   const i = travelDays[day - 1];
  //   console.log('selectedTravelDayIndexSignal', travelDays);
  //   if (!i) return;
  //   if (typeof onDayPress === 'function') {
  //     onDayPress(i.date);
  //     setSelectedDay(i.date);
  //   } else {
  //     handleDay(i, day - 1);
  //   }
  //   dispatch(ActionsCreator.setCurrentTravelDay(day));
  // }, [travelDays]);

  useEffect(() => {
    if (selectedTravelDayIndex) {
      const i = travelDays[selectedTravelDayIndex - 1];
      console.log('selectedTravelDayIndex', selectedTravelDayIndex);
      if (!i) return;
      if (typeof onDayPress === 'function') {
        onDayPress(i.date);
        setSelectedDay(i.dayIndex);
      } else {
        handleDay(i, selectedTravelDayIndex - 1);
      }
      dispatch(ActionsCreator.setCurrentTravelDay(selectedTravelDayIndex));
    }
  }, [selectedTravelDayIndex, travelDays])


  const handleDay = useCallback((i: (typeof travelDays)[number], idx: number) => {

    if (
      !selectedTravelDaySignal.peek() &&
      i.date.format('DD-MM-YYYY') ===
      selectedTravelDaySignal.peek()?.format('DD-MM-YYYY')
    ) {
      setSelectedDay(undefined);
      selectedTravelDaySignal.value = undefined;

      vizualizeButtonSignal.value = undefined;
    } else {

      selectedTravelDaySignal.value = i.date;

      vizualizeButtonSignal.value = {
        day: idx + 1,
        startIndex: i.index,
        endIndex: travelDays[idx + 1]
          ? travelDays[idx + 1].index - 1
          : travelData.length - 1,
      };

      setSelectedDay(i.dayIndex);
      swiperRef.current?.slideTo(idx);
    }
  }, [travelDays]);



  useEffect(() => {
    if (shouldShowFirstDay) handleDay(travelDays[0], 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowFirstDay]);

  return (
    <Swiper
      //@ts-ignore
      onSwiper={(ref) => (swiperRef.current = ref)}
      slidesPerView={'auto'}
      spaceBetween={10}
      style={{
        paddingTop: 5,
        paddingBottom: 10,
      }}
      longSwipesRatio={1}
      centeredSlidesBounds
      centerInsufficientSlides
      centeredSlides
      onTouchStart={() => {
        showDayHeader.value += 1;
      }}
    >
      {travelDays.map((i, idx) => {
        const selected =
          // i.date.format('DD-MM-YYYY') === selectedDay?.format('DD-MM-YYYY');
          i.dayIndex === selectedDay
        return (
          <SwiperSlide style={{ width: 51 }} key={i.toString() + idx}>
            <div
              onClick={() => {
                if (selected) {
                  setSelectedDay(undefined);
                  selectedTravelDaySignal.value = undefined;
                  vizualizeButtonSignal.value = undefined;
                  selectedTravelDayIndexSignal.value = undefined;
                } else {
                  selectedTravelDayIndexSignal.value = idx + 1;
                }
                // if (typeof onDayPress === 'function') {
                //   onDayPress(i.date);
                //   setSelectedDay(i.date);
                // } else {
                //   handleDay(i, idx);
                // }
                // dispatch(ActionsCreator.setCurrentTravelDay(idx + 1));
              }}
              style={{
                backgroundColor: selected ? '#FE7138' : '#FF91471A',
              }}
              className={`days-header-button ${selectedDay ? '' : 'animate'}`}
            >
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: selected ? 'white' : '#FE7138',
                }}
              >
                {'Day'}
              </div>
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  color: selected ? 'white' : '#FE7138',
                }}
              >
                {idx + 1}
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default DaysHeader;
