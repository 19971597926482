import React, { useEffect, useRef, useState } from 'react';
import {
  List,
  ListItemText,
  ListItemButton,
  Typography,
  InputAdornment,
  Box,
} from '@mui/material';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import tzlookup from 'tz-lookup';
import { generateUUID } from 'three/src/math/MathUtils';

import { P2PSelectedLocationModal } from './P2PSelectedLocationModal';
import { StyledTextField } from '../P2PManualTitleModal';

import { geocode, setupGoogle } from '~/containers/FormContainer/helpers';
import {
  Options,
  TravelOptions,
  TravelPointsByDayIndex,
} from '~/utility/models';
import {
  generateEncodedPathAndDuration,
  getInitialDateTime,
  getTravelMode,
  updateNextDays,
  updateNextPointByDay,
} from '~/utility/utils';
import {
  CategoryTypeIcons,
  GetCurrentCategoryType,
  typeCategories,
  TypeOfPointInterface,
  typesOfPoints,
} from './helpers';

import { findPlaceById, findRowByValue } from '~/supabase/php2EditablePlaces';

import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';
import { store } from '~/redux/store';
import { DEFAULT_DURATION_HOUR } from './constant';
import { handlePlaceInfo } from '../helpers';

interface Option {
  value: string;
  label: string;
  text: string;
  street: string;
  code: string;
  city: string;
  country: string;
  placeId: string;
  types?: string[];
}

interface SearchResultRowProps<T> {
  option: T;
  category: string;
  handleSelect: (value: T) => void;
  setPlaceType: React.Dispatch<React.SetStateAction<string | null>>;
}
const SearchResultRow = <T extends { street: string; label: string }>({
  option,
  category,
  handleSelect,
  setPlaceType,
}: SearchResultRowProps<T>): JSX.Element => {
  return (
    <ListItemButton
      sx={{ paddingLeft: '5px' }}
      onClick={() => {
        handleSelect(option);
        setPlaceType(category);
      }}
    >
      <Box
        sx={{
          mr: 1,
        }}
      >
        <CategoryTypeIcons category={category} />
      </Box>
      <ListItemText
        primary={
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontWeight: 700,
            }}
          >
            {option.label}
          </Typography>
        }
        secondary={
          <>
            <Typography variant="body2">{option.street}</Typography>
          </>
        }
      />
    </ListItemButton>
  );
};

interface PointInterfaceFromDB {
  city: string;
  code: string;
  country: string;
  id: number;
  label: string;
  street: string;
  text: string;
  typeofpoint: string; // combined_search returnes typeofpoint instead of typeOfPoint
  useruuid: string; // combined_search returnes useruuid instead of userUUID
  uuid: string;
}

export const P2PSearchAutocomplete = ({
  isFirstPoint,
}: {
  isFirstPoint: boolean;
}) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [options, setOptions] = useState<Option[]>([]);
  const [customOptions, setCustomOptions] = useState<any>([]);

  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<Options | null>(
    null,
  ); // Use Options | null type
  const [selectedLocationModalState, setSelectedLocationModalState] =
    useState(false);
  const [forceAddPoint, setForceAddPoint] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const currentDebounceValueRef = useRef<number>(500);
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const [placeType, setPlaceType] = useState<string | null>(null);

  const currentDayIndex: number = useSelector(
    (state: RootState) => state.P2PManualReducers.currentDayIndex,
  );

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );

  const lastVisitedDayIndex: number = useSelector(
    (state: RootState) => state.P2PManualReducers.lastVisitedDayIndex,
  );

  useEffect(() => {
    async function initializeGoogleOnLoad(): Promise<void> {
      try {
        await setupGoogle();
      } catch (error) {
        console.error('Error initializing Google Maps:', error);
      }
    }

    initializeGoogleOnLoad();
    dispatch(ActionsCreator.setMapButtonState(false));

    const handleClickOutside = (event: MouseEvent) => {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target as Node)
      ) {
        // dispatch(ActionsCreator.setHeaderState(true));
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dispatch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQuery(query);
    }, currentDebounceValueRef.current);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [query]);

  useEffect(() => {
    const handleSearch = async (query: string) => {
      try {
        const newOptions = (await geocode(query)) as Option[];
        const newOptionsFromEditableplaces = await findRowByValue(query);

        setCustomOptions(newOptionsFromEditableplaces);
        setOptions(newOptions);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (debouncedQuery) {
      handleSearch(debouncedQuery);
      setOpen(true);
    } else {
      setOptions([]);
      setOpen(false);
    }
  }, [debouncedQuery]);

  const handleSave = async (selectedType: TypeOfPointInterface | null) => {
    if (!selectedType) return;
    // setIsSaving(true);

    const lastVisitedDay: TravelOptions[] = [
      ...(travelPointsByDayIndex[lastVisitedDayIndex] || []),
    ];

    if (lastVisitedDay && lastVisitedDay.length > 0) {
      const lastPoint: TravelOptions = {
        ...lastVisitedDay[lastVisitedDay.length - 1],
      };

      if (lastPoint) {
        const firstPoint = lastVisitedDay[0];

        if (lastPoint?.id === firstPoint?.id) {
          const newEndOfDayDate = dayjs.utc(lastPoint.startDate);

          let travelPoint = {
            ...selectedLocation,
            typeOfPoint: selectedType.placeType,
            startDate: newEndOfDayDate.format(),
            endDate: newEndOfDayDate.add(1, 'hour').format(),
            dayIndex: lastVisitedDayIndex,
            selectedTransport: getTravelMode('Drive'),
            id: generateUUID(),
            duration: 0,
          };

          if (selectedLocation?.id) {
            travelPoint = {
              ...travelPoint,
              editableplaceId: selectedLocation.id,
            };
          }

          // update last point duration and encodedPath
          let updatedLastPoint = { ...lastPoint };

          // Determine the correct day index and position
          const updatedDayIndex = updatedLastPoint.dayIndex;
          const updatedTravelPointsByDay = [
            ...(travelPointsByDayIndex[updatedDayIndex] || []),
          ];

          // Find the position of the last point
          const updatedLastPointIndex = updatedTravelPointsByDay.findIndex(
            (point) => point.id === updatedLastPoint.id,
          );

          if (updatedLastPointIndex !== -1) {
            const result = await generateEncodedPathAndDuration(
              updatedTravelPointsByDay[updatedLastPointIndex],
              travelPoint as TravelOptions,
            );

            const newStartDatePlusDuration = dayjs
              .utc(travelPoint.startDate)
              .add(result.duration, 'm')
              .format();
            const newEndDatePlusDuration = dayjs
              .utc(travelPoint.endDate)
              .add(result.duration, 'm')
              .format();

            travelPoint = {
              ...travelPoint,
              startDate: newStartDatePlusDuration,
              endDate: newEndDatePlusDuration,
            };

            // Update the last point in the array
            updatedTravelPointsByDay[updatedLastPointIndex] = {
              ...updatedLastPoint,
              duration: result.duration,
              encodedPath: result.encodedPath,
            };
          }

          const travelPointsByDayIndexFromStore =
            store.getState().P2PManualReducers.travelPointsByDayIndex;

          // Get the current day index
          const dayIndex = travelPoint.dayIndex;

          //  Create a new updated object for travelPointsByDayIndex
          const newTravelPointsByDayIndexFromStore = {
            ...travelPointsByDayIndexFromStore,
            // Update the day where the last point exists
            [updatedDayIndex]: updatedTravelPointsByDay,
          };

          let newUpdatedTravelPointsByDayIndex: TravelPointsByDayIndex = {
            ...newTravelPointsByDayIndexFromStore,
            // Add the new travel point to its day
            [dayIndex]: [
              ...(newTravelPointsByDayIndexFromStore[dayIndex] || []),
              travelPoint as TravelOptions,
            ],
          };

          // check if the last place matches the first of the next point
          newUpdatedTravelPointsByDayIndex = updateNextDays(
            newUpdatedTravelPointsByDayIndex,
            dayIndex,
            updateNextPointByDay,
          );

          if (!selectedLocation?.id) {
            handlePlaceInfo(travelPoint as TravelOptions);
          }
          dispatch(
            ActionsCreator.setTravelPointsByDayIndex(
              newUpdatedTravelPointsByDayIndex,
            ),
          );

          dispatch(ActionsCreator.setLastVisitedDayIndex(dayIndex));
        } else {
          let travelPoint = {
            ...selectedLocation,
            typeOfPoint: selectedType.placeType,
            startDate: lastPoint.endDate,
            endDate: dayjs(lastPoint.endDate).utc().add(1, 'hour').format(),
            dayIndex: lastVisitedDayIndex,
            selectedTransport: getTravelMode('Drive'),
            id: generateUUID(),
            duration: 0,
          };

          if (selectedLocation?.id) {
            travelPoint = {
              ...travelPoint,
              editableplaceId: selectedLocation.id,
            };
          }

          // update last point duration and encodedPath
          let updatedLastPoint = { ...lastPoint };

          // Determine the correct day index and position
          const updatedDayIndex = updatedLastPoint.dayIndex;
          const updatedTravelPointsByDay = [
            ...(travelPointsByDayIndex[updatedDayIndex] || []),
          ];

          // Find the position of the last point
          const updatedLastPointIndex = updatedTravelPointsByDay.findIndex(
            (point) => point.id === updatedLastPoint.id,
          );

          if (updatedLastPointIndex !== -1) {
            const result = await generateEncodedPathAndDuration(
              updatedTravelPointsByDay[updatedLastPointIndex],
              travelPoint as TravelOptions,
            );

            const newStartDatePlusDuration = dayjs
              .utc(travelPoint.startDate)
              .add(result.duration, 'm')
              .format();

            const newEndDatePlusDuration = dayjs
              .utc(travelPoint.endDate)
              .add(result.duration, 'm')
              .format();

            travelPoint = {
              ...travelPoint,
              startDate: newStartDatePlusDuration,
              endDate: newEndDatePlusDuration,
            };

            // Update the last point in the array
            updatedTravelPointsByDay[updatedLastPointIndex] = {
              ...updatedLastPoint,
              duration: result.duration,
              encodedPath: result.encodedPath,
            };
          }

          const travelPointsByDayIndexFromStore =
            store.getState().P2PManualReducers.travelPointsByDayIndex;

          // Get the current day index
          const dayIndex = travelPoint.dayIndex;

          //  Create a new updated object for travelPointsByDayIndex
          const newTravelPointsByDayIndexFromStore = {
            ...travelPointsByDayIndexFromStore,
            // Update the day where the last point exists
            [updatedDayIndex]: updatedTravelPointsByDay,
          };

          let updatedTravelPointsByDayIndex: TravelPointsByDayIndex = {
            ...newTravelPointsByDayIndexFromStore,
            // Add the new travel point to its day
            [dayIndex]: [
              ...(newTravelPointsByDayIndexFromStore[dayIndex] || []),
              travelPoint as TravelOptions,
            ],
          };

          updatedTravelPointsByDayIndex = updateNextDays(
            updatedTravelPointsByDayIndex,
            dayIndex,
            updateNextPointByDay,
          );

          if (!selectedLocation?.id) {
            handlePlaceInfo(travelPoint as TravelOptions);
          }

          dispatch(
            ActionsCreator.setTravelPointsByDayIndex(
              updatedTravelPointsByDayIndex,
            ),
          );
        }
      }
    } else {
      let currentDate = dayjs().tz(selectedLocation?.timezone);

      if (currentDayIndex > -1) {
        // Adjust dates based on the currentDayIndex
        currentDate = currentDate.add(currentDayIndex - 1, 'day');
      }

      const startDate = getInitialDateTime(currentDate);

      let travelPoint = {
        review: '',
        longDescription: '',
        description: '',
        cost: 0,
        ...selectedLocation,
        typeOfPoint: selectedType.placeType,
        startDate: startDate,
        endDate: dayjs(startDate)
          .add(DEFAULT_DURATION_HOUR, 'hour')
          .utc()
          .format(),
        dayIndex: store.getState().P2PManualReducers.lastVisitedDayIndex,
        selectedTransport: getTravelMode('Drive'),
        id: generateUUID(),
        duration: 0,
        encodedPath: {
          data: [],
          path: '',
        },
      };

      if (selectedLocation?.id) {
        travelPoint = {
          ...travelPoint,
          editableplaceId: selectedLocation.id,
        };
      } else {
        handlePlaceInfo(travelPoint as TravelOptions);
      }

      // Get the current day index
      const dayIndex = travelPoint.dayIndex;

      // Create a new updated object for travelPointsByDayIndex
      const updatedTravelPointsByDayIndex = {
        ...travelPointsByDayIndex,
        [dayIndex]: [
          ...(travelPointsByDayIndex[dayIndex] || []), // existing points for the day, or empty array if none
          travelPoint as TravelOptions,
        ],
      };

      dispatch(
        ActionsCreator.setTravelPointsByDayIndex(updatedTravelPointsByDayIndex),
      );
    }

    dispatch(ActionsCreator.setIsTravelPointSelectedState(true));
    dispatch(ActionsCreator.setMapButtonState(true));
    dispatch(ActionsCreator.setHeaderState(true));

    handleSelectedLocationType();
    setIsSaving(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setQuery(input);
  };

  const handleSelectPreviousPoint = async (value: PointInterfaceFromDB) => {
    const selectedPointFromPreviousList = await findPlaceById(value.id);
    await setSelectedLocation(selectedPointFromPreviousList);
    setForceAddPoint(true);
  };

  useEffect(() => {
    if (forceAddPoint && selectedLocation && selectedLocation.typeOfPoint) {
      const typeOfPoint = GetCurrentCategoryType(selectedLocation.typeOfPoint);
      handleSave(typeOfPoint);
    }
  }, [forceAddPoint, selectedLocation]);

  const handleSelect = async (value: Option) => {
    const geocoder = new google.maps.Geocoder();
    const { results } = await geocoder.geocode({ placeId: value.placeId });

    const countryComponent = results[0].address_components.find((component) =>
      component.types.includes('country'),
    );
    const cityComponent = results[0].address_components.find(
      (component) =>
        component.types.includes('locality') ||
        component.types.includes('administrative_area_level_1'),
    );

    const coordinates = [
      results[0].geometry.location.lng(),
      results[0].geometry.location.lat(),
    ];
    const timezone = tzlookup(coordinates[1], coordinates[0]);

    const result: Options = {
      value: value.value,
      label: value.label,
      text: value.text,
      code: countryComponent ? countryComponent.short_name : '',
      city: cityComponent ? cityComponent.long_name : '',
      country: countryComponent ? countryComponent.long_name : '',
      coordinates: coordinates,
      street: value.street,
      timezone: timezone,
      placeId: results[0].place_id,
    };

    setSelectedLocation(result);
    setSelectedLocationModalState(true);
  };

  const handleSelectedLocationType = () => {
    setSelectedLocationModalState(false);
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <StyledTextField
        sx={{
          width: '98%',
          margin: '0 auto',
        }}
        autoFocus
        placeholder="Search for a Point"
        value={query}
        onChange={handleChange}
        onFocusCapture={() => {
          if (!isFirstPoint) {
            dispatch(ActionsCreator.setHeaderState(false));
          }
        }}
        fullWidth
        inputRef={textFieldRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon
                icon="gg:search"
                width="30"
                height="30"
                style={{ color: '#979797' }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Box sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
        {open && customOptions.length > 0 && (
          <h3
            style={{
              marginTop: '20px',
              marginLeft: '10px',
            }}
          >
            Previous points
          </h3>
        )}
        {open && customOptions.length > 0 && (
          <List>
            {customOptions.slice(0, 3).map((option: PointInterfaceFromDB, index: number) => {
              return (
                <SearchResultRow
                  key={option.id + index}
                  option={option}
                  category={option.typeofpoint}
                  handleSelect={handleSelectPreviousPoint}
                  setPlaceType={setPlaceType}
                />
              );
            })}
          </List>
        )}

        {open && options.length > 0 && (
          <h3
            style={{
              marginTop: '20px',
              marginLeft: '10px',
            }}
          >
            Search points
          </h3>
        )}

        {open && options.length > 0 && (
          <List>
            {options.map((option, index) => {
              const category =
                option.types
                  ?.includes('restaurant')
                  ? typesOfPoints.RESTAURANT.placeType
                  : option.types
                    ?.includes('food')
                    ? typesOfPoints.RESTAURANT.placeType
                    : option.types
                      ?.includes('airport')
                      ? typesOfPoints.AIRPORT.placeType
                      : option.types
                        ?.includes('point_of_interest')
                        ? typesOfPoints.POINT_OF_ATTRACTION.placeType
                        : option.types
                          ?.map((type: string) => typeCategories[type])
                          .find((cat: string | undefined) => cat) ||
                        typesOfPoints.other.placeType;

              return (
                <SearchResultRow
                  key={option.placeId + index}
                  option={option}
                  category={category}
                  handleSelect={handleSelect}
                  setPlaceType={setPlaceType}
                />
              );
            })}
          </List>
        )}
      </Box>

      <P2PSelectedLocationModal
        selectedLocation={selectedLocation as Options}
        open={selectedLocationModalState}
        onClose={() => {
          setSelectedLocationModalState(false);
        }}
        onConfirm={handleSave}
        placeType={placeType}
        isSaving={isSaving}
      />
    </div>
  );
};
