import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import ActionsCreator from '~/redux/actions';
import { AppDispatch } from '~/redux/store';
import { TravelFormData, FormData } from '~/utility/models';
import { addRouteData } from '~/utility/utils';

/**
 * Modifies the travel points array.
 * @param {FormData} departureFormData - The departure form data.
 * @param {FormData} arrivalFormData - The arrival form data.
 * @param {React.MutableRefObject<TravelMode | null>} selectedTransport - Ref for selected transport.
 * @param {React.MutableRefObject<TravelFormData[]>} travelPoints - Ref for travel points array.
 * @param {number} index - The index of the travel point being modified.
 * @param {AppDispatch} dispatch - Dispatch function for Redux actions.
 * @param {React.Dispatch<React.SetStateAction<string>>} setErrorMessage - Function to set the error message state.
 * @returns {Promise<boolean>} A promise indicating the success of the modification.
 */
export async function modifyTravelPointsArray(
  departureFormData: FormData,
  arrivalFormData: FormData,
  selectedTransport: React.MutableRefObject<TravelMode | null>,
  travelPoints: React.MutableRefObject<TravelFormData[]>,
  index: number,
  dispatch: AppDispatch,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  selectedTransportImages: string[] | [],
): Promise<boolean> {
  const isAnyFieldNull =
    Object.values(departureFormData).some(
      (value) => value === null || value === '',
    ) ||
    Object.values(arrivalFormData).some(
      (value) => value === null || value === '',
    ) ||
    !selectedTransport.current;

  if (isAnyFieldNull) {
    // Set error message
    setErrorMessage('Please fill all the fields before proceeding further');
    // Dispatch action to show the Snackbar
    dispatch(ActionsCreator.setSnackbarOpen(true));
    // Exit the function if any field is null
    return false;
  }

  const isDepartureAndArrivalSame =
    departureFormData.location?.placeId === arrivalFormData.location?.placeId;
  if (isDepartureAndArrivalSame) {
    setErrorMessage('Departure and arrival locations should not be the same');
    dispatch(ActionsCreator.setSnackbarOpen(true)); // Show the Snackbar
    return false; // Exit the function if any field is null
  }

  let updatedTravelPoints = [...travelPoints.current];

  let updatedPoints = {
    arrival: arrivalFormData,
    departure: departureFormData,
    selectedTransport: selectedTransport.current as TravelMode,
    selectedTransportImages,
    encodedPath: {
      data: [],
      path: '',
    },
  } as unknown as TravelFormData;

  updatedPoints = await addRouteData(updatedPoints);

  if (index < 0) {
    // Add new travel point at the beginning
    updatedTravelPoints.unshift(updatedPoints);
  } else {
    // Update existing travel point
    updatedTravelPoints[index] = updatedPoints;
  }

  // Dispatch action to save updated travel points
  dispatch(
    ActionsCreator.saveTravelPoint(updatedTravelPoints as TravelFormData[]),
  );

  return true; // Return true to indicate that the update was successful
}

/**
 * Updates the travel points array with a new travel point.
 * @param {FormData} departureFormData - The departure form data.
 * @param {FormData} arrivalFormData - The arrival form data.
 * @param {React.MutableRefObject<TravelMode | null>} selectedTransport - Ref for selected transport.
 * @param {React.MutableRefObject<TravelFormData[]>} travelPoints - Ref for travel points array.
 * @param {AppDispatch} dispatch - Dispatch function for Redux actions.
 * @param {React.Dispatch<React.SetStateAction<string>>} setErrorMessage - Function to set the error message state.
 * @param {boolean} isSave - Indicates whether to save the travel point or just add it.
 * @returns {Promise<boolean>} A promise indicating the success of the operation.
 */
export async function updateTravelPointsArray(
  departureFormData: FormData,
  arrivalFormData: FormData,
  selectedTransport: React.MutableRefObject<TravelMode | null>,
  travelPoints: React.MutableRefObject<TravelFormData[]>,
  dispatch: AppDispatch,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  isSave: boolean,
  selectedTransportImages: string[],
): Promise<boolean> {
  const isAnyFieldNull =
    Object.values(departureFormData).some(
      (value) => value === null || value === '',
    ) ||
    Object.values(arrivalFormData).some(
      (value) => value === null || value === '',
    ) ||
    !selectedTransport.current;

  if (isAnyFieldNull) {
    setErrorMessage('Please fill all the fields before proceeding further');
    dispatch(ActionsCreator.setSnackbarOpen(true)); // Show the Snackbar
    return false; // Exit the function if any field is null
  }

  const isDepartureAndArrivalSame =
    departureFormData.location?.placeId === arrivalFormData.location?.placeId;
  if (isDepartureAndArrivalSame) {
    setErrorMessage('Departure and arrival locations should not be the same');
    dispatch(ActionsCreator.setSnackbarOpen(true)); // Show the Snackbar
    return false; // Exit the function if any field is null
  }

  let newTravelObj = {
    arrival: arrivalFormData,
    departure: departureFormData,
    selectedTransport: selectedTransport.current as TravelMode,
    selectedTransportImages,
    encodedPath: {
      data: [],
      path: '',
    },
  } as unknown as TravelFormData;

  newTravelObj = await addRouteData(newTravelObj);

  const updatedTravelPoints = [...travelPoints.current, newTravelObj];

  dispatch(
    isSave
      ? ActionsCreator.saveTravelPoint(updatedTravelPoints)
      : ActionsCreator.addTravelPoint(updatedTravelPoints),
  );

  travelPoints.current = updatedTravelPoints;

  return true;
}
