import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';

import { TravelOptions } from '~/utility/models';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import NumberInput from './NumberInput';
import { TransportationDataInterface } from '..';
import { getDifferenceInMinutes } from '~/utility/utils';

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 261,
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

interface P2PTimeDurationModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (duration: number) => void;
  selectedLocation: TravelOptions;
  selectedIndex: number;
  transportationData: TransportationDataInterface;
}

export const P2PTimeDurationModal: FC<P2PTimeDurationModalProps> = ({
  open,
  onClose,
  onSave,
  selectedLocation,
  selectedIndex,
  transportationData,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    transportationData.duration.toString(),
  ); // Local state for typing

  const [durationRanges, setDurationRanges] = useState({
    min: 0,
    max: 0,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valueAsInt = Number(value);
    if (valueAsInt < durationRanges.min || valueAsInt > durationRanges.max)
      return;
    setInputValue(value); // Allow incremental typing
  };

  useEffect(() => {
    setInputValue(transportationData.duration.toString());
  }, [transportationData]);

  useEffect(() => {
    const selectedLocationTimezone = selectedLocation?.timezone;
    const dateAtMidnight = dayjs(selectedLocation.endDate)
      .tz(selectedLocationTimezone)
      .endOf('day'); // Set time to 00:00:00.000

    let max = getDifferenceInMinutes(
      dateAtMidnight.tz(selectedLocationTimezone).utc().format(),
      selectedLocation.endDate,
    );

    setDurationRanges({ min: 0, max });
  }, [
    selectedLocation.timezone,
    selectedLocation.id,
    selectedLocation.endDate,
    selectedLocation.startDate,
    selectedIndex,
  ]);

  const onSubmit = (duration: number) => {
    onSave(duration);
    setInputValue('0');
    onClose(); // Close the modal
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h5"
            sx={{
              color: '#ff8447',
              fontFamily: 'Poppins, sans-serif',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '25.2px',
              margin: '10px',
            }}
          >
            How long does it take?
          </Typography>

          {transportationData.icon && (
            <img
              src={transportationData.icon}
              alt=""
              style={{
                height: '40px',
                width: '40px',
                marginRight: '5px',
              }}
            />
          )}
        </Box>
      </DialogTitle>

      <DialogContent>
        <NumberInput
          value={inputValue}
          max={durationRanges.max}
          min={durationRanges.min}
          onSubmit={onSubmit}
          onChange={handleChange}
        />
      </DialogContent>
    </StyledDialog>
  );
};
