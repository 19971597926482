import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { signal } from '@preact/signals-core';
import { useLocation } from 'react-router-dom';

import { P2PManualHeader } from './P2PManualHeader';
import { P2PTravelBuilder } from './P2PTravelBuilder';
import {
  P2PManualTitleModal,
  lastTripTitleSignal,
} from './P2PManualTitleModal';
import { P2PSearchAutocomplete } from './P2PSearchAutocomplete';
import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';
import { TravelOptions, TravelPointsByDayIndex } from '~/utility/models';
import { getTravelPointsArray } from '~/utility/utils';
import ROUTES from '~/routes';
import {
  initialItineraryRender,
  isDiscoveryOverlayVisible,
} from '~/map/ViewTravel';
import subscribeSignal from '~/hooks/subscribeSignal';
import { store } from '~/redux/store';
import useIsAuth from '../ViewTravel/useIsAuth';
import { useDebouncedSave } from '~/hooks/use_debounced_save';

export const MANUAL_TRIP_HISTORY = 'MANUAL_TRIP_HISTORY';
export const TOUR_ID_STORAGE_KEY = 'tourId';
export const isSyncingToServerSignal = signal(false);
export const previousTravelPoints = signal<TravelPointsByDayIndex>({});
export const previousTitle = signal('');
export const isSavingToServerSignal = signal(false);

export const saveTravelPoints = async (
  travelPointsByDayIndex: TravelPointsByDayIndex,
) => {
  try {
    const travelPointsArrays: TravelOptions[] = getTravelPointsArray(
      travelPointsByDayIndex,
    );

    if (travelPointsArrays.length > 0) {
      isSyncingToServerSignal.value = true;

      await store.dispatch(
        ActionsCreator.saveP2PTravelPoint(travelPointsArrays),
      );
      if (Object.keys(travelPointsByDayIndex).length > 0) {
        await store.dispatch(
          ActionsCreator.createP2PTravelPointVisualization(
            travelPointsByDayIndex,
          ),
        );
      } else {
        console.log('No travel points to save');
      }
    } else {
      console.log('No travel points to save');
    }
  } catch (error) {
    console.error('Error saving travel points:', error);
  } finally {
    isSyncingToServerSignal.value = false;
    isSavingToServerSignal.value = false;
  }
};

const P2PManual: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useIsAuth();

  const isPointSelected = useSelector(
    (state: RootState) => state.P2PManualReducers.isPointSelected,
  );
  const travelData = useSelector((state: RootState) => state.P2PManualReducers);
  const tripTitle = useSelector(
    (state: RootState) => state.P2PManualReducers.travelTitle,
  );

  const travelPointsByDayIndex =
    store.getState().P2PManualReducers.travelPointsByDayIndex;

  const [lastTripTitle, setLastTripTitle] = useState('');
  subscribeSignal(lastTripTitleSignal, setLastTripTitle);

  const [shouldShowTitleModal, setShouldShowTitleModal] = useState(false);

  const saveTravelPoints = async (
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) => {
    try {
      const travelPointsArrays: TravelOptions[] = getTravelPointsArray(
        travelPointsByDayIndex,
      );

      isSyncingToServerSignal.value = true;

      await store.dispatch(
        ActionsCreator.saveP2PTravelPoint(travelPointsArrays),
      );

      await store.dispatch(
        ActionsCreator.createP2PTravelPointVisualization(
          travelPointsByDayIndex,
        ),
      );
    } catch (error) {
      console.error('Error saving travel points:', error);
    } finally {
      isSyncingToServerSignal.value = false;
      isSavingToServerSignal.value = false;
    }
  };

  useLayoutEffect(() => {
    const shouldShow =
      (location.pathname === ROUTES.P2PManual.path ||
        (location.pathname === ROUTES.VIEWTRAVEL.path)) &&
      !tripTitle;
    setShouldShowTitleModal(shouldShow);
  }, [location.pathname, tripTitle]);

  useEffect(() => {
    loadPreviousTrip();
  }, []);

  const handleSave = useDebouncedSave({
    tripTitle,
    isSavingToServerSignal,
    previousTravelPoints,
    previousTitle,
    saveTravelPoints,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        isAuthenticated &&
        location.pathname === ROUTES.VIEWTRAVEL.path
        // &&
        // !initialItineraryRender.peek()
      ) {
        console.log('Saving travel points from view travel');
        handleSave(travelPointsByDayIndex);
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, [
    isAuthenticated,
    tripTitle,
    travelPointsByDayIndex,
    location.pathname,
    handleSave,
  ]);

  const loadPreviousTrip = () => {
    const manualTripString = sessionStorage.getItem(MANUAL_TRIP_HISTORY);
    if (manualTripString) {
      const manualTripJson = JSON.parse(manualTripString);

      if (location.pathname !== ROUTES.VIEWTRAVEL.path) {
        dispatch(ActionsCreator.restoreStateFromPrevious(manualTripJson));
      }
    }
  };

  useEffect(() => {
    sessionStorage.setItem(MANUAL_TRIP_HISTORY, JSON.stringify(travelData));
  }, [travelData]);

  useLayoutEffect(() => {
    if (Object.keys(travelData.travelPointsByDayIndex).length === 0) {
      dispatch(ActionsCreator.setIsTravelPointSelectedState(false));
    }
  }, [dispatch, travelData.travelPointsByDayIndex]);

  const handleTitleSave = (title: string) => {
    // if (lastTripTitle === '') {
    //   resetTravelState();
    // }
    dispatch(ActionsCreator.setTravelTitle(title));
  };

  const resetTravelState = () => {
    dispatch(ActionsCreator.setDefaultP2pManualState());
    dispatch(ActionsCreator.setPublishedTravelLink(''));
    dispatch(ActionsCreator.setPulishedTravelId(0));
  };

  return (
    <Box
      sx={{
        p: 0,
        maxWidth: 'min(100vw, 375px)',
        minWidth: 'min(100vw,375px)',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <P2PManualHeader onCancel={() => { }} />

      {!isPointSelected && (
        <P2PSearchAutocomplete
          isFirstPoint={
            Object.keys(travelData.travelPointsByDayIndex).length === 0
          }
        />
      )}

      {isPointSelected && <P2PTravelBuilder />}

      {shouldShowTitleModal && (
        <P2PManualTitleModal
          open={!tripTitle}
          onClose={() => {
            if (lastTripTitle !== '') {
              dispatch(ActionsCreator.setTravelTitle(lastTripTitle));
            }
          }}
          onSave={handleTitleSave}
        />
      )}
    </Box>
  );
};

export default P2PManual;
