export interface TypeOfPointInterface {
  label: string;
  placeType: string;
  icon: string;
  aliases?: string[];
}
export interface TypesOfPointInterface {
  [key: string]: TypeOfPointInterface;
}

export interface IPointInfo {
  city?: string;
  street?: string;
  country?: string;
  name: string;
}

export interface IPointInfoResponse {
  cost: number;
  review: string;
  longDescription: string;
  description: string;
}

export const typesOfPoints: TypesOfPointInterface = {
  POINT_OF_ATTRACTION: {
    label: 'Point of Attraction',
    placeType: 'poa',
    icon: '/poa.svg',
    aliases: ['poa', 'point of attraction', '🎡 point of attraction'], // Optional aliases if needed
  },
  RESTAURANT: {
    label: 'Restaurant',
    placeType: 'restaurant',
    icon: '/restaurant.svg',
  },

  ACCOMODATION: {
    label: 'Accommodation',
    placeType: 'hotel',
    icon: '/hotel.svg',
  },
  AIRPORT: {
    label: 'Airport',
    placeType: 'airport',
    icon: '/airport.svg',
  },

  HOME: {
    label: 'Home',
    placeType: 'home',
    icon: '/home.svg',
  },
  OTHER: {
    label: 'Other',
    placeType: 'other',
    icon: '/other.svg',
  },
};

export const typeCategories: Record<string, string> = {
  accounting: typesOfPoints.OTHER.placeType,
  airport: typesOfPoints.AIRPORT.placeType,
  amusement_park: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  aquarium: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  art_gallery: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  atm: typesOfPoints.OTHER.placeType,
  bakery: typesOfPoints.RESTAURANT.placeType,
  bank: typesOfPoints.OTHER.placeType,
  bar: typesOfPoints.OTHER.placeType,
  beauty_salon: typesOfPoints.OTHER.placeType,
  bicycle_store: typesOfPoints.OTHER.placeType,
  book_store: typesOfPoints.OTHER.placeType,
  bowling_alley: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  bus_station: typesOfPoints.OTHER.placeType,
  cafe: typesOfPoints.RESTAURANT.placeType,
  campground: typesOfPoints.ACCOMODATION.placeType,
  car_dealer: typesOfPoints.OTHER.placeType,
  car_rental: typesOfPoints.OTHER.placeType,
  car_repair: typesOfPoints.OTHER.placeType,
  car_wash: typesOfPoints.OTHER.placeType,
  casino: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  cemetery: typesOfPoints.OTHER.placeType,
  church: typesOfPoints.OTHER.placeType,
  city_hall: typesOfPoints.OTHER.placeType,
  clothing_store: typesOfPoints.OTHER.placeType,
  convenience_store: typesOfPoints.OTHER.placeType,
  courthouse: typesOfPoints.OTHER.placeType,
  dentist: typesOfPoints.OTHER.placeType,
  department_store: typesOfPoints.OTHER.placeType,
  doctor: typesOfPoints.OTHER.placeType,
  drugstore: typesOfPoints.OTHER.placeType,
  electrician: typesOfPoints.OTHER.placeType,
  electronics_store: typesOfPoints.OTHER.placeType,
  embassy: typesOfPoints.OTHER.placeType,
  fire_station: typesOfPoints.OTHER.placeType,
  florist: typesOfPoints.OTHER.placeType,
  funeral_home: typesOfPoints.OTHER.placeType,
  furniture_store: typesOfPoints.OTHER.placeType,
  gas_station: typesOfPoints.OTHER.placeType,
  gym: typesOfPoints.OTHER.placeType,
  hair_care: typesOfPoints.OTHER.placeType,
  hardware_store: typesOfPoints.OTHER.placeType,
  hindu_temple: typesOfPoints.OTHER.placeType,
  home_goods_store: typesOfPoints.OTHER.placeType,
  hospital: typesOfPoints.OTHER.placeType,
  insurance_agency: typesOfPoints.OTHER.placeType,
  jewelry_store: typesOfPoints.OTHER.placeType,
  laundry: typesOfPoints.OTHER.placeType,
  lawyer: typesOfPoints.OTHER.placeType,
  library: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  light_rail_station: typesOfPoints.OTHER.placeType,
  liquor_store: typesOfPoints.OTHER.placeType,
  local_government_office: typesOfPoints.OTHER.placeType,
  locksmith: typesOfPoints.OTHER.placeType,
  lodging: typesOfPoints.ACCOMODATION.placeType,
  meal_delivery: typesOfPoints.RESTAURANT.placeType,
  meal_takeaway: typesOfPoints.RESTAURANT.placeType,
  mosque: typesOfPoints.OTHER.placeType,
  movie_rental: typesOfPoints.OTHER.placeType,
  movie_theater: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  moving_company: typesOfPoints.OTHER.placeType,
  museum: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  night_club: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  painter: typesOfPoints.OTHER.placeType,
  park: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  parking: typesOfPoints.OTHER.placeType,
  pet_store: typesOfPoints.OTHER.placeType,
  pharmacy: typesOfPoints.OTHER.placeType,
  physiotherapist: typesOfPoints.OTHER.placeType,
  plumber: typesOfPoints.OTHER.placeType,
  police: typesOfPoints.OTHER.placeType,
  post_office: typesOfPoints.OTHER.placeType,
  primary_school: typesOfPoints.OTHER.placeType,
  real_estate_agency: typesOfPoints.OTHER.placeType,
  restaurant: typesOfPoints.RESTAURANT.placeType,
  roofing_contractor: typesOfPoints.OTHER.placeType,
  rv_park: typesOfPoints.ACCOMODATION.placeType,
  school: typesOfPoints.OTHER.placeType,
  secondary_school: typesOfPoints.OTHER.placeType,
  shoe_store: typesOfPoints.OTHER.placeType,
  shopping_mall: typesOfPoints.OTHER.placeType,
  spa: typesOfPoints.OTHER.placeType,
  stadium: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  storage: typesOfPoints.OTHER.placeType,
  store: typesOfPoints.OTHER.placeType,
  subway_station: typesOfPoints.OTHER.placeType,
  supermarket: typesOfPoints.OTHER.placeType,
  synagogue: typesOfPoints.OTHER.placeType,
  taxi_stand: typesOfPoints.OTHER.placeType,
  tourist_attraction: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  train_station: typesOfPoints.OTHER.placeType,
  transit_station: typesOfPoints.OTHER.placeType,
  travel_agency: typesOfPoints.OTHER.placeType,
  university: typesOfPoints.OTHER.placeType,
  veterinary_care: typesOfPoints.OTHER.placeType,
  zoo: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  administrative_area_level_1: typesOfPoints.OTHER.placeType,
  administrative_area_level_2: typesOfPoints.OTHER.placeType,
  administrative_area_level_3: typesOfPoints.OTHER.placeType,
  administrative_area_level_4: typesOfPoints.OTHER.placeType,
  administrative_area_level_5: typesOfPoints.OTHER.placeType,
  administrative_area_level_6: typesOfPoints.OTHER.placeType,
  administrative_area_level_7: typesOfPoints.OTHER.placeType,
  archipelago: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  colloquial_area: typesOfPoints.OTHER.placeType,
  continent: typesOfPoints.OTHER.placeType,
  country: typesOfPoints.OTHER.placeType,
  establishment: typesOfPoints.OTHER.placeType,
  finance: typesOfPoints.OTHER.placeType,
  floor: typesOfPoints.OTHER.placeType,
  food: typesOfPoints.RESTAURANT.placeType,
  general_contractor: typesOfPoints.OTHER.placeType,
  geocode: typesOfPoints.OTHER.placeType,
  health: typesOfPoints.OTHER.placeType,
  intersection: typesOfPoints.OTHER.placeType,
  landmark: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  locality: typesOfPoints.OTHER.placeType,
  natural_feature: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  neighborhood: typesOfPoints.OTHER.placeType,
  place_of_worship: typesOfPoints.OTHER.placeType,
  plus_code: typesOfPoints.OTHER.placeType,
  point_of_interest: typesOfPoints.POINT_OF_ATTRACTION.placeType,
  political: typesOfPoints.OTHER.placeType,
  post_box: typesOfPoints.OTHER.placeType,
  postal_code: typesOfPoints.OTHER.placeType,
  postal_code_prefix: typesOfPoints.OTHER.placeType,
  postal_code_suffix: typesOfPoints.OTHER.placeType,
  postal_town: typesOfPoints.OTHER.placeType,
  premise: typesOfPoints.OTHER.placeType,
  room: typesOfPoints.OTHER.placeType,
  route: typesOfPoints.OTHER.placeType,
  street_address: typesOfPoints.OTHER.placeType,
  street_number: typesOfPoints.OTHER.placeType,
  sublocality: typesOfPoints.OTHER.placeType,
  sublocality_level_1: typesOfPoints.OTHER.placeType,
  sublocality_level_2: typesOfPoints.OTHER.placeType,
  sublocality_level_3: typesOfPoints.OTHER.placeType,
  sublocality_level_4: typesOfPoints.OTHER.placeType,
  sublocality_level_5: typesOfPoints.OTHER.placeType,
  subpremise: typesOfPoints.OTHER.placeType,
  town_square: typesOfPoints.POINT_OF_ATTRACTION.placeType,
};

export const GetCurrentCategoryType = (category: string | null) => {
  let currentCategory = Object.values(typesOfPoints).find(
    (categoryObj) =>
      categoryObj.placeType.toLowerCase() === category?.toLowerCase() ||
      categoryObj.aliases?.some(
        (alias) => alias.toLowerCase() === category?.toLowerCase(),
      ), // Check aliases
  );

  // For safety, return Other as the default category
  if (!currentCategory) currentCategory = typesOfPoints.OTHER;
  return currentCategory;
};

export const CategoryTypeIcons = ({
  category,
}: {
  category: string | null;
}) => {
  const selected = GetCurrentCategoryType(category);

  return selected ? <img src={selected.icon} alt={selected.label} /> : null;
};
