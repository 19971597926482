import React, { useState } from 'react';
import { signal } from '@preact/signals-core';
import { Drawer } from '@mui/material';
import P2PManual from '../P2PManual';
import subscribeSignal from '~/hooks/subscribeSignal';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';

import './style.css';

export const showOverviewMenu = signal(false);

export enum ETabIndexes {
  TRIP = 'Trip',
  ITINERARY = 'Itinerary',
}

const OverviewMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useIsMobile();

  subscribeSignal(showOverviewMenu, setIsVisible);

  // const initialAnimation = isMobile
  //   ? { y: -window.innerHeight }
  //   : { y: -window.innerHeight, x: '-50%' };
  // const animation = isMobile ? { y: 0 } : { y: 0, x: '-50%' };
  // const exit = isMobile
  //   ? { y: -window.innerHeight }
  //   : { y: -window.innerHeight, x: '-50%' };

  return (
    <div className="overview-modal-menu">
      <Drawer
        anchor="left"
        variant="persistent"
        open={isVisible}
        onClose={() => {
          showOverviewMenu.value = false;
        }}
        style={
          isMobile
            ? {}
            : {
                width: '25vw',
              }
        }
        PaperProps={{
          ...(!isMobile && {
            style: { width: '25vw' },
          }),
        }}
      >
        {isVisible && <P2PManual />}
      </Drawer>
    </div>
  );
};

export default OverviewMenu;
