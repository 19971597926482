import { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { signal } from '@preact/signals-core';
import ActionsCreator from '~/redux/actions';
import { useDispatch } from 'react-redux';
import { initialItineraryRender } from '~/map/ViewTravel';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
    marginLeft: '15px',
    marginRight: '15px',
    padding: '25px 1px 25px 1px',
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

export const StyledTextField = styled(TextField)({
  width: 'min(345px,70vw)',
  marginTop: '5px',
  boxShadow: '0px 1px 4px 0px #00000040',
  outline: 'none',
  borderRadius: '10px',
  backgroundColor: 'white',
  border: '1px solid #D6D6D6',
  '& .MuiInputBase-input': {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '20px',
    borderRadius: '10px',
    outline: 'none',
    border: 'none',
  },
  '& .MuiInputBase-input::placeholder': {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '20px',
  },
  '& fieldset': { border: 'none' },
});

const SaveButton = styled(Button)({
  width: 180,
  height: 64,
  borderRadius: 18,
  fontFamily: 'Poppins',
  fontSize: '16px',
  backgroundColor: '#fe7138',
  boxShadow: `0px 2px 6px #b8c5d033, 0px 6px 22px #b8c5d04d, 
              0px 9px 30px #c6d8e933, inset 0px 1.5px 1.5px #ffffff40`,
  '&:hover': { backgroundColor: '#e6602b' },
  textTransform: 'none',
});

interface P2PManualTitleModalProps {
  open: boolean;
  isSaving?: boolean;
  onClose: () => void;
  onSave: (title: string) => void;
}

export const lastTripTitleSignal = signal<string>('');

export const P2PManualTitleModal: FC<P2PManualTitleModalProps> = ({
  open,
  isSaving = false,
  onClose,
  onSave,
}) => {
  const [title, setTitle] = useState('');
  const [error, setError] = useState(false);

  const handleSave = () => {
    if (title.trim()) {
      initialItineraryRender.value = true;
      onSave(title);

      // onClose();
      return;
    }

    setError(true);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    if (error && e.target.value.trim()) {
      setError(false);
    }
  };

  return (
    <StyledDialog
      sx={{ width: '100%' }}
      open={open}
      onClose={() => {
        onClose();
        setTitle('');
      }}
    >
      <DialogTitle
        sx={{
          paddingBottom: '10px',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: '#000',
            fontFamily: 'Poppins, sans-serif',
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '36px',
          }}
        >
          Title your Trip
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{ paddingBottom: 0, paddingLeft: '5px', paddingRight: '5px' }}
      >
        <StyledTextField
          sx={{ width: '100%' }}
          variant="outlined"
          placeholder={lastTripTitleSignal.peek() || 'My best New York Trip'}
          fullWidth
          defaultValue={lastTripTitleSignal.peek()}
          value={title}
          onChange={handleTitleChange}
          required
          error={error}
          helperText={error ? 'Please enter a title for your trip' : ''}
        />
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center' }}>
        <SaveButton
          onClick={handleSave}
          variant="contained"
          disabled={!title.trim()}
        >
          {isSaving
            ? 'Creating your Trip..'
            : lastTripTitleSignal.peek()
            ? 'Save'
            : 'Start Planning'}
        </SaveButton>
      </DialogActions>
    </StyledDialog>
  );
};
