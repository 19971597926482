import React, { forwardRef, LegacyRef } from 'react';
import { isReactNativeView } from '~/utility/utils';

export const IFrameItem = forwardRef(
  ({ videoId }: { videoId: string }, ref: LegacyRef<HTMLIFrameElement>) => {
    const isReactNative = isReactNativeView()
    return (
      <iframe
        ref={ref}
        src={`https://www.tiktok.com/player/v1/${videoId}?controls=0&autoplay=0&native_context_menu=0&rel=0&description=0&closed_caption=0&loop=1`}
        className="tiktok_iframe"
        style={{
          pointerEvents: isReactNative ? 'auto' : 'none',
        }}
        allow="encrypted-media; autoplay"
        title="tiktok"
        onLoad={(e) => console.log('123 on load e', e)}
      ></iframe>
    );
  },
);
