import { useEffect, useState } from 'react';
import useIsAuth from '~/components/ViewTravel/useIsAuth';
import { generateUUID } from '~/utility/utils';

export const SESSION_KEY = 'sessionId';

export const useSessionId = () => {
  const [sessionId, setSessionId] = useState<string>('');

  const isAuthenticated = useIsAuth();

  useEffect(() => {
    // This is created if the user was generating a
    //  trip before being told to login else nothing will be present
    const prevSessionId = sessionStorage.getItem(SESSION_KEY);
    if (prevSessionId) {
      setSessionId(prevSessionId);
      sessionStorage.removeItem(SESSION_KEY);
    } else {
      const newSessionId = generateUUID();
      setSessionId(newSessionId);
      if (!isAuthenticated) {
        sessionStorage.setItem(SESSION_KEY, newSessionId);
      }
    }
  }, []);
  return sessionId;
};
