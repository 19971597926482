import { AnimationAction, AnimationMixer, Clock } from 'three';
import { Map } from 'maplibre-gl';
import { Model } from '~/utility/models';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { CharacterStateMachine } from './characterStateMachine/CharacterStateMachine';
import { AnimationStates } from '../utility/enums/AnimationStates';
import { LandTransportController } from '../LandTransportController';
import {
  currentTravelTypeSignal,
  handleAnimationState,
} from '~/components/ViewTravel/common';
import { zoomResolver } from '../utility/utils';
import { LandTransportAnimationConfig } from '../AnimationController';

interface Character {
  model: Model;
  mixer: AnimationMixer;
  walkClipAction: AnimationAction;
  appearClipAction: AnimationAction;
  disappearClipAction: AnimationAction;
  idleClipAction: AnimationAction;
}

/**
 * This class controls the animation of a walk along a travel path on a map.
 * It inherits from the `LandTransportController` class and provides walk-specific functionalities.
 */
class WalkAnimationController extends LandTransportController {
  lastPathIndex: number = 0;

  isIdleStateInitiated: boolean = false;

  character!: Character;

  stateMachine!: CharacterStateMachine;

  clock!: Clock;

  /**
   * Constructor for a base animation controller class. This class provides common functionalities
   * for animating objects along a path on a map. Specific implementations for WalkAnimationController
   * and PlaneAnimationController will extend this class.
   *
   * @param map A reference to the Maplibre map instance
   * @param index Index of the current travel segment
   * @param model A reference to the GLTF object to be animated
   * @param tb A reference to the Three.js wrapper class
   */
  constructor(map: Map, index: number, tb: CustomThreeJSWrapper) {
    super(map, index, tb);
    this.clock = new Clock();
    this.streetLevelZoom = 15; // override for walk controller
  }

  /**
   * Starts the walk animation along the travel path.
   *
   * @param animationConfig
   * Configuration object for the walk animation
   * (specific to WalkAnimationController)
   *
   * @remarks
   * This function overrides the base class's `startAnimation` method.
   * It performs walk-specific setup tasks in addition to the common
   * animation setup.
   */
  startAnimation() {
    currentTravelTypeSignal.value = 'walk';
    this.clock.start();
    this.mapPitch = 30;

    if (
      (this.animationConfig as LandTransportAnimationConfig).streetLevelZoom
    ) {
      this.streetLevelZoom = (
        this.animationConfig as LandTransportAnimationConfig
      ).streetLevelZoom as number;
    }
    this.cameraZoom = zoomResolver(this.cameraZoom, this.streetLevelZoom);

    this.stateMachine = new CharacterStateMachine(this.gltf as Model, this);

    this.isIdleStateInitiated = false;

    ((this.animationConfig as LandTransportAnimationConfig)
      .duration as number) -= this.stateMachine.getModelAnimationTime();

    this.stateMachine.setState(AnimationStates.Appear);
  }

  onAnimationEnded() {
    if (!this.isIdleStateInitiated) {
      this.isIdleStateInitiated = true;
      this.stateMachine.setState(AnimationStates.Disappear);
    }
    this.streetLevelZoom = 15; // Reset to default value
  }

  update(delta: number) {
    const deltaSeconds = this.clock.getDelta();
    if (this.stateMachine) this.stateMachine.update(deltaSeconds);

    super.update(delta);
  }

  setAnimationExpired = () => {
    this.isAnimationExpired = true;
    this.onAnimationCompleteCallback();
  };
}

export { WalkAnimationController };
