import { ModelConfigProps } from '~/utility/models';

/**
 * An enumeration of various car models.
 * Each member represents a specific car type with a corresponding numeric value for potential use in calculations or comparisons.
 */
export enum CarModels {
  /** Represents a standard car type. */
  Car = 'Car',
}

export const carModelsConfiguration = new Map<CarModels, ModelConfigProps>([
  [
    CarModels.Car,
    {
      name: 'car.glb',
      path: './models/car.glb',
      UIname: 'Default Car',
    },
  ],
]);
