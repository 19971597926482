import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase } from '~/supabase/supabaseClient';
import {
  Content3DLayer,
  PublishableTravelData,
  PublishableTravelDataWithDecodedPath,
  PublishableTravelObject,
  TravelOptions,
} from '~/utility/models';
import useStyles from './styles';
import { RootState, useSelector } from '~/redux/reducers';
import maplibregl, { Map } from 'maplibre-gl';
import { TravelAnimation } from '~/animationEngine/Travel/TravelAnimation';
import StaticTravelVisualizer from '~/animationEngine/StaticTravelVisualizer';
import {
  encodeTourID,
  groupAndSortTravelPointsByDay,
  setupPublishableTravelObjectWithDecodedPath,
} from '~/utility/utils';
import ActionsCreator from '~/redux/actions';
import { useDispatch } from '~/redux/store';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import {
  initializeMapLibreMap,
  initializeTravelAnimation,
  onConfigUpdateOfPublishableTravelData,
  setStaticTravelVisualizer,
  visualizeTodos,
} from '../helpers';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import {
  animationIsPlaying,
  clickedIndexDirection,
  clickedIndexSignal,
  destinationStateSignal,
  durationSignal,
  emptyStateSignal,
  getDestinationPointData,
  PauseAnimation,
  pauseAnimationSignal,
  PlayAnimation,
  playAnimationSignal,
  provokeAutoOpenImages,
  provokeEmptyState,
  provokeEndState,
  resetAllSignals,
  setAnimationStateSignal,
  setImagesBySignal,
  showMainHeader,
  showPlayPauseButton,
} from '~/components/ViewTravel/common';
import { SplashScreen } from './SplashScreen';
import { LogoModal } from '~/components/ViewTravel/LogoModal';
import {
  selectedTravelDaySignal,
  showDayHeader,
  vizualizeButtonSignal,
} from '~/components/ViewTravel/MobileFooter/DaysHeader/DaysHeader';
import { useSignalEffect } from '@preact/signals-react/runtime';
import StatsOverlay, {
  isDiscoverModalVisible,
  isOverlayVisible,
} from '~/components/ViewTravel/StatsOverlay';
import { signal } from '@preact/signals-core';
import { useMarkersAnimationController } from '~/animationEngine/StaticTravelVisualizer/useMarkersAnimationController';
import { getManualPointsByIdentifier } from '~/supabase/p2pHistory';
import { CityInterface } from '~/supabase/php2EditablePlaces';
import { getTravelItineraryByTourID } from '~/supabase/publishTravel';
import { ITodoTravelPoint } from '~/components/AddToTrip';
import { AddThingsToDo } from './AddThingsToDo';
import {
  fireOpenBottomSheet,
  fireResetTrip,
  selectBottomSheetOverviewData,
  selectResetTrip,
} from '~/redux/reducers/ui.slice';
import {
  thingsToDoSignal,
  todosSignal,
} from '~/components/signals/thingsToDoSignal';
import { showOverviewMenu } from '~/components/OverviewMenu';
import subscribeSignal from '~/hooks/subscribeSignal';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';

export const mapSignal = signal<Map | null>();
export const restartDaySignal = signal(false);
export const isDiscoveryOverlayVisible = signal(true);
export const initialItineraryRender = signal(true);

export const DAYJS = dayjs;
let timeout: NodeJS.Timeout;
DAYJS.extend(duration);
DAYJS.extend(isSameOrBefore);
DAYJS.extend(customParseFormat);
let lastClickedIndex = 0;

export async function getTravelItinerary(tourID: number) {
  console.log('tourID', tourID);
  const { data } = await supabase
    .from('Publish Travel')
    .select(
      'id,publishable_data,UUID,published_id,manual_history_identifier,city',
    )
    .eq('id', tourID);

  return data;
}

export default function ViewTravel() {
  const location = useLocation();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const map = useRef<Map | null>();

  const isViewTravel = true;
  const [travelPoints, setTravelPoints] = useState<PublishableTravelData[]>([]);
  const [mapStyleIndex, setMapStyleIndex] = useState<number | null>(null);
  const [fullscreenState, setFullScreenState] = useState<boolean>();
  const [audio, setAudio] = useState<string | undefined>(undefined);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [logo, setLogo] = useState<string>('');
  const [open, setOpen] = useState(true);
  const [isDataFetching, setIsDataFetching] = useState<boolean>(true);
  const [content, setContent] = useState<ITodoTravelPoint | null>(null);
  const [isNotFullWidth, setIsNotFullWidth] = useState(false);
  subscribeSignal(showOverviewMenu, setIsNotFullWidth);

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const queryParams = new URLSearchParams(location.search);
  const travelVisualizer = useRef<StaticTravelVisualizer | null>();

  const isMounted = useRef<boolean | null>(true);
  const dispatch = useDispatch();
  const resetTrip = useSelector(selectResetTrip);
  const bottomSheetOverviewData = useSelector(selectBottomSheetOverviewData);

  const [
    publishableAnimationTravelDataWithDecodedPath,
    setPublishableAnimationTravelDataWithDecodedPath,
  ] = useState<PublishableTravelDataWithDecodedPath[]>([]);
  const wrapper = useRef<CustomThreeJSWrapper | null>(null);
  const [loading, setLoading] = useState(true);
  const [showAddToTripDialog, setShowAddtoTripDialog] =
    useState<boolean>(false);

  const travelAnimation = useRef<TravelAnimation | null>();
  const playPauseState = useSelector(
    (state: RootState) => state.AnimationReducers.playPauseState,
  );

  const isTravelPointsUpdated = useSelector(
    (state: RootState) => state.P2PManualReducers.isTravelPointsUpdated,
  );
  const selectedCity: CityInterface | null = useSelector(
    (state: RootState) => state.P2PManualReducers.selectedCity,
  );

  const encodedTourID = queryParams.get('tourID');

  // TODO - remove half
  const tourID = decodeTourID(Number(encodedTourID));

  const handleClose = () => {
    setOpen(false);
  };

  function decodeTourID(encodedTourID: number) {
    return (encodedTourID - 100010) / 9;
  }

  const handleAnimationEnd = () => {
    // Set playPauseState to true when animation ends
    provokeEndState(true);
    showPlayPauseButton.value = false;
    isOverlayVisible.value = true;
    resetAllSignals();
    dispatch(ActionsCreator.setPlayPauseState(true));
  };

  const handleTodoClick = (todo: ITodoTravelPoint) => {
    setShowAddtoTripDialog(true);
    setContent(todo);
  };

  useEffect(() => {
    getTravelItineraryByTourID(tourID)
      .then(
        (
          data:
            | {
                id: number;
                UUID: string;
                publishable_data: PublishableTravelObject;
                published_id: number;
                manual_history_identifier: string;
                city: CityInterface;
              }[]
            | null,
        ) => {
          if (isMounted.current) {
            if (data) {
              const response = data[0];

              if (response) {
                setTravelPoints(response.publishable_data.travelPoints);
                setMapStyleIndex(
                  response.publishable_data.mapStyleIndex as number,
                );
                setAudio(response?.publishable_data?.audio ?? undefined);
                setLogo(response?.publishable_data.logo ?? '');
                dispatch(
                  ActionsCreator.setTravelData(
                    response.publishable_data.travelPoints,
                  ),
                );
                dispatch(
                  ActionsCreator.setTravelTitle(
                    response?.publishable_data?.title ?? '',
                  ),
                );
                dispatch(
                  ActionsCreator.setTravelRawData({
                    ...response.publishable_data,
                    userId: response?.UUID,
                    // publishedId: response?.published_id,
                    publishedId: response?.id ?? 0,
                    identifier: response?.manual_history_identifier,
                    city: response?.city,
                  }),
                );

                const hashedTourID = encodeTourID(tourID);
                const baseUrl = window.location.origin;

                const link = `${baseUrl}/viewtravel?tourID=${hashedTourID}`;

                dispatch(ActionsCreator.setPublishedTravelLink(link));
                dispatch(ActionsCreator.setPulishedTravelId(tourID));
                dispatch(ActionsCreator.setSelectedCity(data[0]?.city));

                if (response?.manual_history_identifier) {
                  dispatch(
                    ActionsCreator.setCurrentHistoryIdentifier(
                      response?.manual_history_identifier,
                    ),
                  );
                  getManualPointsByIdentifier(
                    response?.manual_history_identifier,
                  )
                    .then(({ data }) => {
                      if (data) {
                        const travelPoints =
                          data?.travelPoints! as TravelOptions[];

                        const travelPointsByDayIndex =
                          groupAndSortTravelPointsByDay(travelPoints);

                        dispatch(
                          ActionsCreator.setCurrentHistoryCreatedAt(
                            data.created_at,
                          ),
                        );
                        dispatch(ActionsCreator.setCurrentHistoryId(data.id));
                        dispatch(
                          ActionsCreator.setTravelPointsByDayIndex(
                            travelPointsByDayIndex,
                          ),
                        );

                        thingsToDoSignal.value = data?.todos?.length
                          ? data.todos
                          : [];
                      }
                    })
                    .catch((error) => {
                      if (error) {
                        console.log(
                          'ERROR OCCURED WHILE GETTING MANUAL HISTORY VIewtravel',

                          {
                            error,
                          },
                        );
                      }
                    });
                }
              }
            }
          }
        },
      )
      .catch((error) => {
        if (error) {
          console.log('ERROR OCCURED WHILE GETTING Trave data tourId', {
            error,
          });
        }
      })
      .finally(() => {
        setIsDataFetching(false);
      });

    if (document.documentElement.requestFullscreen!) setFullScreenState(true);
    else setFullScreenState(false);
  }, [tourID]);

  useEffect(() => {
    if (isTravelPointsUpdated)
      getTravelItineraryByTourID(tourID)
        .then(
          (
            data:
              | {
                  id: number;
                  UUID: string;
                  publishable_data: PublishableTravelObject;
                  published_id: number;
                  manual_history_identifier: string;
                  city: CityInterface;
                }[]
              | null,
          ) => {
            if (isMounted.current) {
              if (data) {
                setTravelPoints(data[0].publishable_data.travelPoints);
                setMapStyleIndex(
                  data[0].publishable_data.mapStyleIndex as number,
                );
                setAudio(data[0].publishable_data?.audio ?? undefined);

                setLogo(data[0].publishable_data.logo ?? '');
                dispatch(
                  ActionsCreator.setTravelData(
                    data[0].publishable_data.travelPoints,
                  ),
                );
                dispatch(
                  ActionsCreator.setTravelTitle(
                    data[0]?.publishable_data?.title ?? '',
                  ),
                );
                dispatch(ActionsCreator.setIsTravelPointsUpdated(false));

                dispatch(
                  ActionsCreator.setTravelRawData({
                    ...data[0].publishable_data,
                    userId: data[0]?.UUID,
                    publishedId: data[0]?.id ?? 0,
                    identifier: data[0]?.manual_history_identifier,
                    city: data[0]?.city,
                  }),
                );

                const hashedTourID = encodeTourID(tourID);
                const baseUrl = window.location.origin;

                const link = `${baseUrl}/viewtravel?tourID=${hashedTourID}`;

                dispatch(ActionsCreator.setPublishedTravelLink(link));
                dispatch(ActionsCreator.setPulishedTravelId(tourID));
                dispatch(ActionsCreator.setSelectedCity(data[0]?.city));
              }
            }
          },
        )
        .catch((error) => {
          if (error) {
            console.log(
              'ERROR OCCURED WHILE GETTING Trave data from is travel updated',
              {
                error,
              },
            );
          }
        });
  }, [isTravelPointsUpdated]);

  const content3DLayer: Content3DLayer = {
    id: 'custom-threejs-layer',
    type: 'custom',
    renderingMode: '3d',
    render: () => {
      if (wrapper.current) wrapper.current.update();

      travelAnimation.current?.update();
    },
  };

  const onResourcesLoadingStateUpdate = (newLoadingState: boolean) => {
    setLoading(newLoadingState);
  };

  useEffect(() => {
    const setupMap = async () => {
      if (!map.current && mapStyleIndex !== null) {
        try {
          await initializeMapLibreMap(
            map as React.MutableRefObject<maplibregl.Map>,
            mapContainer as React.MutableRefObject<HTMLDivElement>,
            mapStyleIndex,
            wrapper as React.MutableRefObject<CustomThreeJSWrapper>,
            content3DLayer,
            isViewTravel,
            true,
            false,
          );
        } finally {
          // setLoading(false); // Set loading to false after initialization is complete
        }
      }
    };

    setupMap();
  }, [mapStyleIndex, selectedCity]);

  useEffect(() => {
    if (travelPoints.length > 0) {
      const travelData = Promise.all(
        travelPoints.map((data) =>
          setupPublishableTravelObjectWithDecodedPath(
            data,
            data.travelSegmentConfig.modelEnum,
            data.travelSegmentConfig.modelScale,
            data.travelSegmentConfig.animationSpeed,
          ),
        ),
      );
      travelData.then((data) => {
        setPublishableAnimationTravelDataWithDecodedPath(data);
      });
    } else {
      setPublishableAnimationTravelDataWithDecodedPath([]);
    }
  }, [travelPoints]);

  useEffect(() => {
    if (!playPauseState)
      if (!travelAnimation.current)
        initializeTravelAnimation(
          travelAnimation as React.MutableRefObject<TravelAnimation>,
          publishableAnimationTravelDataWithDecodedPath,
          map as React.MutableRefObject<maplibregl.Map>,
          wrapper as React.MutableRefObject<CustomThreeJSWrapper>,
          onResourcesLoadingStateUpdate,
          isViewTravel,
          handleAnimationEnd,
        );
      else
        onConfigUpdateOfPublishableTravelData(
          false,
          travelAnimation as React.MutableRefObject<TravelAnimation>,
          publishableAnimationTravelDataWithDecodedPath,
        );

    return () => {
      travelAnimation.current?.clean();
      travelAnimation.current?.destroy();
      travelAnimation.current?.setPlayPause(true);
      travelAnimation.current = null;
      // set play pause
    };
  }, [playPauseState, publishableAnimationTravelDataWithDecodedPath]);

  useEffect(() => {
    const playAnimationSub = playAnimationSignal.subscribe((val) => {
      if (val > 1) {
        travelAnimation.current?.setPlayPause(false);
      }
    });

    const pauseAnimationSub = pauseAnimationSignal.subscribe((val) => {
      if (val) {
        travelAnimation.current?.setPlayPause(true);
      }
    });
    // Function to handle visibility changes
    const handleVisibilityChange = async () => {
      PauseAnimation();
      isOverlayVisible.value = true;
      showMainHeader.value = true;
    };

    // Add an event listener to detect visibility changes
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to be called when the component unmounts or when the dependencies change
    return () => {
      playAnimationSub();
      pauseAnimationSub();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleMarkerClick: Parameters<typeof setStaticTravelVisualizer>[5] = (
    data,
    index,
    currentMarker,
    travelArray,
    isDefaultMarker,
  ) => {
    const key = currentMarker === 'origin' ? 'departure' : 'arrival';
    let changedIndex = key === 'arrival' ? index + 1 : index;

    if (isDefaultMarker) {
      const originLocation = data[data.length - 1][key].location
        ?.coordinates as any;

      //@ts-ignore
      changedIndex = travelArray?.findIndex(
        (item) =>
          item.departure.location?.coordinates.toString() ===
          originLocation.toString(),
      );
    }

    if (!isDiscoveryOverlayVisible.peek()) {
      dispatch(
        fireOpenBottomSheet({
          data: {
            index: changedIndex,
            location: data[data.length - 1][key].location?.coordinates as any,
            name: data[data.length - 1][key]?.location?.label || '',
            street: data[data.length - 1][key].location?.street,
            id: data[data.length - 1][key].location?.placeId,
            description:
              'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Assumenda velit voluptatibus accusantium sunt suscipit eos commodi ullam omnis adipisci.',
            images:
              data[data.length - 1][key].images.length === 0
                ? data[data.length - 1][key].images
                : [],
            point: data[data.length - 1][key] as any,
          },
          isMarker: true,
        }),
      );
    }
  };

  const debounceTime = useRef(250);

  const debouncedSetStaticTravelVisualizer = (
    date: any,
    publishableAnimationTravelDataWithDecodedPath: any,
  ) => {
    const startTime = performance.now();
    playPauseState &&
      setStaticTravelVisualizer(
        publishableAnimationTravelDataWithDecodedPath,
        true,
        travelVisualizer as React.MutableRefObject<StaticTravelVisualizer>,
        map as React.MutableRefObject<maplibregl.Map>,
        wrapper as React.MutableRefObject<CustomThreeJSWrapper>,
        handleMarkerClick,
        date,
        thingsToDoSignal.value,
        handleTodoClick,
      );
    debounceTime.current = Math.max(
      debounceTime.current,
      (performance.now() - startTime) * 1,
    );
  };

  // TODO: Elimitate when "animationIsPlaying" and "showPlayPauseButton" are moved to redux
  useSignalEffect(() => {
    if (
      !bottomSheetOverviewData &&
      !animationIsPlaying.value &&
      !showPlayPauseButton.value
    ) {
      debouncedSetStaticTravelVisualizer(
        selectedTravelDaySignal.value,
        publishableAnimationTravelDataWithDecodedPath,
      );
    }
  });
  // ...and used in the effect below
  useEffect(() => {
    if (
      !bottomSheetOverviewData &&
      !animationIsPlaying.value &&
      !showPlayPauseButton.value
    ) {
      debouncedSetStaticTravelVisualizer(
        selectedTravelDaySignal.value,
        publishableAnimationTravelDataWithDecodedPath,
      );
    }
  }, [bottomSheetOverviewData]);

  useEffect(() => {
    const setUpStaticTravelVisualizer = async () => {
      try {
        await setStaticTravelVisualizer(
          publishableAnimationTravelDataWithDecodedPath,
          true,
          travelVisualizer as React.MutableRefObject<StaticTravelVisualizer>,
          map as React.MutableRefObject<maplibregl.Map>,
          wrapper as React.MutableRefObject<CustomThreeJSWrapper>,
          handleMarkerClick,
          selectedTravelDaySignal.value,
          thingsToDoSignal.value,
          handleTodoClick,
        );
      } finally {
        setLoading(false);
      }
    };

    setUpStaticTravelVisualizer();
  }, [publishableAnimationTravelDataWithDecodedPath, playPauseState]);

  useEffect(() => {
    if (travelVisualizer.current && !playPauseState) {
      showDayHeader.value = 0;
    }
  }, [playPauseState]);

  useEffect(() => {
    const mapInstance = map.current;

    return () => {
      if (travelAnimation.current) {
        travelAnimation.current?.clean?.();
        travelAnimation.current?.destroy?.();
        travelAnimation.current = null;
      }
      if (mapInstance) {
        mapInstance?.remove?.();
      }
    };
  }, []);

  useEffect(() => {
    if (resetTrip && travelAnimation.current) {
      vizualizeButtonSignal.value = undefined;
      PauseAnimation();
      travelAnimation.current.clean();
      travelAnimation.current.destroy();
      travelAnimation.current = null;
      resetAllSignals(false);
      dispatch(ActionsCreator.setPlayPauseState(true));
      selectedTravelDaySignal.value = undefined;
      dispatch(fireResetTrip(false));
    }
  }, [resetTrip]);

  useEffect(() => {
    mapSignal.value = map.current;
  }, [map.current]);

  useEffect(() => {
    // Effect runs on initial render and whenever location changes
    return () => {
      // Cleanup logic runs when the component unmounts or location changes
      isDiscoveryOverlayVisible.value = true;
    };
  }, [location.pathname]); // Dependency on pathname ensures it triggers when route changes

  useSignalEffect(() => {
    if (todosSignal.value) {
      visualizeTodos(
        publishableAnimationTravelDataWithDecodedPath,
        map as React.MutableRefObject<maplibregl.Map>,
        todosSignal.value,
        handleTodoClick,
      );
    }
  });

  const startTravelAtIndex = async (index: number) => {
    clearTimeout(timeout);
    if (lastClickedIndex < index) {
      clickedIndexDirection.value = 'fwd';
    } else {
      clickedIndexDirection.value = 'bwd';
    }
    lastClickedIndex = index;
    durationSignal.value = 0;
    provokeEndState(false);
    clickedIndexSignal.value = index;
    provokeEmptyState();
    destinationStateSignal.value = getDestinationPointData(index);

    timeout = setTimeout(() => {
      provokeAutoOpenImages();
    }, 2500);

    setImagesBySignal([]);
    if (index === 0) {
      emptyStateSignal.value = 0;
    }
    setAnimationStateSignal((prev: any) => ({
      ...prev,
      calendarStep: index > 0 ? index : undefined,
    }));
    await travelAnimation.current?.reset(
      publishableAnimationTravelDataWithDecodedPath,
      false,
    );
    PlayAnimation();
    setTimeout(() => PlayAnimation(), 0);
  };

  useMarkersAnimationController(travelVisualizer);

  if (tourID === undefined) {
    return <div>No tour ID provided</div>;
  }

  const mapStyles = isDiscoverModalVisible.peek()
    ? { pointerEvents: 'all' }
    : { pointerEvents: 'all' };

  return (
    <>
      <div
        className={
          isNotFullWidth && !isMobile
            ? classes.mapsMainContainerThreeQuarter
            : classes.mapsMainContainer
        }
        ref={mapContainer}
        style={mapStyles as React.CSSProperties}
      />

      <StatsOverlay
        isDataFetching={isDataFetching}
        travelPoints={travelPoints}
        handleDateSelect={startTravelAtIndex}
        mapStyleIndex={mapStyleIndex || 0}
        audio={audio}
      />

      {!showSplashScreen && logo && (
        <LogoModal open={open} onClose={handleClose} logo={logo} />
      )}

      {showSplashScreen && (
        <SplashScreen
          loading={loading}
          closeSplashScreen={() => setShowSplashScreen(false)}
        />
      )}

      {showAddToTripDialog && content && (
        <AddThingsToDo
          todo={content}
          close={() => setShowAddtoTripDialog(false)}
          isViewTravel={true}
          childTitle="Add this Point to a Day"
          open={true}
        />
      )}
    </>
  );
}
