import { makeStyles } from '@mui/styles';
import type MuiTheme from '~/styles/theme/MuiTheme';

const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  heading: {
    marginTop: '4px',
    fontFamily: 'Futura Bk BT',
    fontWeight: 400,
    color: '#0E131F',
    lineHeight: '26.37px',
    marginBottom: '8px',
  },
  hideDropdownArrow: {
    color: 'black',
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },

  customOutlineInput: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        width: '98.5%',
      },
    },
  },

  subHeading: {
    fontFamily: 'Futura Bk BT',
    fontWeight: 400,
    color: '#0E131F',
    lineHeight: '26.37px',
    marginBottom: '4px',
  },

  '@media (max-width: 420px)': {
    subHeading: {
      fontSize: '14px',
    },

    categoryButtons: {
      fontSize: '14px',
    },
  },
  [theme.breakpoints.between(420, 768)]: {
    subHeading: {
      fontSize: '16px',
    },

    // Add styles for other classes at this breakpoint
  },
  [theme.breakpoints.between(768, 1024)]: {
    subHeading: {
      fontSize: '14px',
    },
  },
  [theme.breakpoints.between(1024, 1440)]: {
    subHeading: {
      fontSize: '16px',
    },
  },
  [theme.breakpoints.between(1440, 1700)]: {
    subHeading: {
      fontSize: '18px',
    },
  },
  [theme.breakpoints.between(1700, 1920)]: {
    subHeading: {
      fontSize: '20px',
    },
  },
  [theme.breakpoints.between(1920, 2560)]: {
    subHeading: {
      fontSize: '22px',
    },
  },
  [theme.breakpoints.between(2560, 3500)]: {
    subHeading: {
      fontSize: '24px',
    },
    dropDownOutlineInput: {
      '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
        {
          height: '20px',
          fontSize: '24px',
          paddingTop: '15px',
        },
    },

    customOutlineInput: {
      '& .MuiOutlinedInput-root': {
        fontSize: '24px',
        padding: '4px auto',

        '& fieldset': {
          height: '78px',
        },
      },
    },
  },
  '@media (min-width: 3500px)': {
    subHeading: {
      fontSize: '30px',
    },
    dropDownOutlineInput: {
      '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
        {
          height: '3rem',
          fontSize: '30px',
          paddingTop: '20px',
        },
    },

    customOutlineInput: {
      '& .MuiOutlinedInput-root': {
        fontSize: '30px',
        padding: '6px auto',

        '& fieldset': {
          height: '70px',
        },
      },
    },

    clockImg: {
      width: '40px',
      height: '40px',
    },
  },
}));
export default useStyles;
