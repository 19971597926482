import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ActionsCreator from '~redux/actions';
import { useDispatch } from '~redux/store';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useSelector } from '~redux/reducers';
import { submitFeedback } from '~/supabase/feedback';
import useStyles from './styles';
import { RootState } from '~/redux/reducers';
import CustomSnackbar from '../CustomSnackbar';
import {
  feedbackBlinkSignal,
  getStartedBlinkSignal,
} from '../signals/blinkSignals';

/**
 * FeedbackPopup component for sending feedback.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setOpenSnackbar - Function to set snackbar visibility.
 * @param {Object} props.feedbackResult - Result of the feedback submission.
 * @param {Function} props.setFeedbackResult - Function to set feedback result.
 * @returns {JSX.Element} The rendered FeedbackPopup component.
 */
const FeedbackPopup = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // State to manage button disabled state
  const [isLoading, setIsLoading] = useState(false); // State to manage loading indicator

  const userID = useSelector((state: RootState) => state.MapReducers.userID);
  const openFeedbackPopup = useSelector(
    (state: RootState) => state.MapReducers.feedbackPopupState,
  );

  /**
   * Handles text change in the feedback text field.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedbackText(event.target.value);
  };

  useEffect(() => {
    setIsSubmitDisabled(feedbackText.trim() === '');
  }, [feedbackText]);

  /**
   * Handles feedback type change.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  // const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFeedbackType(event.target.value);
  // };

  /**
   * Closes the feedback popup.
   */
  const handleClose = () => {
    dispatch(ActionsCreator.setFeedbackPopupState(false));
    setFeedbackText('');
    setIsLoading(false);
  };

  /**
   * Submits the feedback.
   */
  const handleSubmitFeedback = async () => {
    setIsLoading(true); // Set loading state to true when submit is clicked
    const result = await submitFeedback(feedbackText, userID);

    dispatch(ActionsCreator.setSnackbarMessage(result.message));
    dispatch(ActionsCreator.setSnackbarSeverity(result.success));
    dispatch(ActionsCreator.setSnackbarOpen(true));
  };

  return (
    <Dialog
      open={openFeedbackPopup}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>Give Feedback</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          id="feedback-text"
          placeholder="Write any Suggestions or Problems"
          multiline
          rows={4}
          onChange={handleTextChange}
          className={classes.fieldBox}
          InputProps={{
            disableUnderline: true,
            sx: {
              borderRadius: '10px',
              border: '1px solid #d4d4d4',
              padding: '10px',
              fontSize: '20px',
              fontWeight: '600',
              fontFamily: 'Poppins',
            },
          }}
        />
      </DialogContent>

      <Box className={classes.dialogButtonContainer}>
        <Button
          className={`${classes.dialogButton} ${classes.dialogConfirmButton}`} // Confirm button styling
          onClick={handleSubmitFeedback}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitDisabled || isLoading} // Disable the button based on isSubmitDisabled state
        >
          Send Feedback
        </Button>
      </Box>

      <CustomSnackbar handleClosePopup={handleClose} />
    </Dialog>
  );
};

export default FeedbackPopup;
