import { useEffect, useRef, useState } from 'react';
import { Story } from 'react-insta-stories/dist/interfaces';
import { signal } from '@preact/signals-core';
import {
  animationStateSignal,
  autoOpenImagesSignal,
  PauseAnimation,
  PlayAnimation,
  showPlayPauseButton,
} from '../common';
import { getIconByCategory } from '../MobileFooter/helpers';
import { PublishableTravelData } from '~/utility/models';
import {
  getStoryType,
  selectCurrentOverviewData,
} from '~/redux/selectors/tripInfoSelectors';
import { useSelector } from 'react-redux';
import subscribeSignal from '~/hooks/subscribeSignal';
import { InstaStories } from './InstaStories/InstaStories';
import { TiktokStories } from './TiktoktStories/TiktokStories';
import { selectBottomSheetOverviewData } from '~/redux/reducers/ui.slice';
import { OverviewData } from '../MobileFooter/OverviewDataComponent';
import { useSignals } from '@preact/signals-react/runtime';

export const showStoriesSignal = signal<string[]>();
export const bottomSheetOverviewDataSignal = signal<OverviewData | null>(null);

const StoriesOverlay = ({
  travelPoints,
}: {
  travelPoints: PublishableTravelData[];
}) => {
  useSignals();
  const [stories, setStories] = useState<Story[]>([]);
  const [tiktokUrl, setTiktokUrl] = useState<string[]>([]);
  const [isStoriesVisible, setIsStoriesVisible] = useState(false);
  const [isTiktokVisible, setIsTiktokVisible] = useState(false);
  const currentOverviewData = useSelector(selectCurrentOverviewData);
  const isFirstTime = useRef(true);

  const bottomSheetOverviewData = useSelector(selectBottomSheetOverviewData);

  subscribeSignal(showPlayPauseButton, () => {
    if (showPlayPauseButton.peek()) {
      isFirstTime.current = true;
    }
  });

  const convertImagesToStories = (
    images: string[],
    label?: string,
    category?: string,
  ) => {
    return (
      images?.map((imageSrc) => {
        const storyType = getStoryType(imageSrc);

        return {
          url: imageSrc,
          type: storyType,
          header: {
            heading:
              label ||
              bottomSheetOverviewData?.name ||
              currentOverviewData?.name ||
              bottomSheetOverviewDataSignal.peek()?.name ||
              '',
            subheading: '',
            profileImage:
              bottomSheetOverviewDataSignal.peek()?.icon ??
              getIconByCategory(
                category ||
                  bottomSheetOverviewData?.point?.category ||
                  currentOverviewData?.point?.category ||
                  '',
              ),
          },
        };
      }) || []
    );
  };

  subscribeSignal(showStoriesSignal, (value) => {
    if (value && value.length > 0 && value[0].includes('tiktok')) {
      setTiktokUrl(value);
      setIsTiktokVisible(true);
    } else if (value && value.length > 0) {
      setStories(convertImagesToStories(value));
      setIsStoriesVisible(true);
    }
  });

  useEffect(() => {
    if (isStoriesVisible) {
      if (showPlayPauseButton.peek()) {
        stories.length > 0 && PauseAnimation();
        if (stories.length === 0) {
          setIsStoriesVisible(false);
        }
      }
    }
  }, [isStoriesVisible, stories]);

  useEffect(() => {
    if (isTiktokVisible) {
      if (showPlayPauseButton.peek()) {
        tiktokUrl.length > 0 && PauseAnimation();
        if (tiktokUrl.length === 0) {
          setIsTiktokVisible(false);
        }
      }
    }
  }, [isTiktokVisible, tiktokUrl]);

  subscribeSignal(autoOpenImagesSignal, (value) => {
    if (value > 0) {
      if (isFirstTime.current) {
        isFirstTime.current = false;
        if (
          travelPoints[animationStateSignal.peek().calendarStep].departure
            .tiktokData &&
          travelPoints[animationStateSignal.peek().calendarStep].departure
            .tiktokData?.length! > 0
        ) {
          setTiktokUrl(
            travelPoints[
              animationStateSignal.peek().calendarStep
            ]?.departure.tiktokData?.map((media) => media.url) || [],
          );
          setIsTiktokVisible(true);
        } else {
          setStories(
            convertImagesToStories(
              travelPoints[animationStateSignal.peek().calendarStep]?.departure
                .media?.length! > 0
                ? travelPoints[
                    animationStateSignal.peek().calendarStep
                  ]?.departure?.media!.map((m) => m.url)
                : travelPoints[animationStateSignal.peek()?.calendarStep]
                    ?.departure.images?.length! > 0
                ? travelPoints[animationStateSignal.peek()?.calendarStep]
                    ?.departure?.images
                : [],
              travelPoints[animationStateSignal.peek()?.calendarStep]?.departure
                ?.location?.label,
              travelPoints[animationStateSignal.peek()?.calendarStep]?.departure
                ?.category,
            ),
          );
          setIsStoriesVisible(true);
        }
      } else {
        if (
          travelPoints[animationStateSignal.peek().calendarStep].arrival
            .tiktokData &&
          travelPoints[animationStateSignal.peek().calendarStep].arrival
            .tiktokData?.length! > 0
        ) {
          setTiktokUrl(
            travelPoints[
              animationStateSignal.peek().calendarStep
            ]?.arrival.tiktokData?.map((media) => media.url) || [],
          );
          setIsTiktokVisible(true);
        } else {
          setStories(
            convertImagesToStories(
              travelPoints[animationStateSignal.peek().calendarStep]?.arrival
                ?.media?.length! > 0
                ? travelPoints[
                    animationStateSignal.peek().calendarStep
                  ]?.arrival?.media!.map((m) => m.url)
                : travelPoints[animationStateSignal.peek().calendarStep]
                    ?.arrival?.images?.length! > 0
                ? travelPoints[animationStateSignal.peek().calendarStep]
                    ?.arrival?.images
                : [],
              travelPoints[animationStateSignal.peek().calendarStep]?.arrival
                .location?.label,
              travelPoints[animationStateSignal.peek().calendarStep]?.arrival
                ?.category,
            ),
          );
          setIsStoriesVisible(true);
        }
      }
    }
  });

  const handleTiktokClose = () => {
    showStoriesSignal.value = undefined;
    setIsTiktokVisible(false);
    setTiktokUrl([]);
    if (showPlayPauseButton.peek()) {
      PlayAnimation();
      PlayAnimation();
      setTimeout(() => {
        PauseAnimation();
        PlayAnimation();
        PlayAnimation();
      }, 1000);
    }
  };

  if (isTiktokVisible) {
    return <TiktokStories tiktok={tiktokUrl} close={handleTiktokClose} />;
  }

  if (!isStoriesVisible || stories.length === 0) {
    return null;
  }

  return (
    <InstaStories
      setIsVisible={setIsStoriesVisible}
      setStories={setStories}
      stories={stories}
    />
  );
};

export default StoriesOverlay;
