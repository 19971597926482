import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from './routes/AppRoutes';

/**
 * The main application component that sets up the routing and theming for the app.
 *
 * @component
 * @example
 * return (
 *   <App />
 * )
 *
 * @returns {JSX.Element} The rendered component
 *
 * This component wraps the application with the `Router` for handling routing,
 * and the `ThemeProvider` to apply the Material-UI theme. It renders the `AppRoutes`
 * component which should contain the specific routes for the application.
 *
 * @category Component
 * @author Muhammad Ahmad <ahmadbutt4260@gmail.com>
 */

export default function App() {
  return (
    <>
      <Router
        // ⚠️ React Router Future Flag Warning
        future={{
          v7_startTransition: true, // Enabling the flag for early use of React Router v7's transition feature
          v7_relativeSplatPath: true,
        }}
      >
        <AppRoutes />
      </Router>
    </>
  );
}
