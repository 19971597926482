import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  List,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { animate, motion, MotionValue, useMotionValue } from 'framer-motion';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';

import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import {
  generateEncodedPathAndDuration,
  getDifferenceInMinutes,
  getTravelMode,
  getTypeFromTravelMode,
  updateNextDays,
  updateNextPointByDay,
} from '~/utility/utils';
import {
  EncodedPathDataObject,
  ITravelFormHistoryData,
  TravelOptions,
  TravelPointsByDayIndex,
} from '~/utility/models';

import { RootState } from '~/redux/reducers';
import ActionsCreator from '~/redux/actions';

import DayNumber from './components/DayNumber';
import DateSelector from './components/DateSelector';
import { P2PTransportSelector } from './P2PTransportSelector';
import { P2PConfirmDayDeletion } from './P2PConfirmDayDeletion';
import P2PTravelPoint from './P2PTravelPoint';
import { transportMap, transportMapInterface } from '../../helpers';
import { DurationWithEncoding } from './DurationWithEncoding';
import { Dispatch } from 'redux';
import { selectTravelPoints } from '~/redux/selectors/P2PManualSelectors';
import { CarModels } from '~/animationEngine/utility/enums/CarModels';
import { ThingsToDoDialog } from '~/components/ThingsToDo';
import { getManualPointsById } from '~/supabase/p2pHistory';
import { ITodoTravelPoint } from '~/components/AddToTrip';

// Initialize dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

type P2PPointsViewerProps = {
  dayTravelPoints: TravelOptions[];
  travelPoints: TravelOptions[];
};

const TransportButton = styled('div')({
  // width: 'auto',
  padding: '1px 2px',
  borderRadius: 18,
  backgroundColor: '#fff',
  boxShadow: '0px 2px 3px 0px #00000040',
  '&:hover': { backgroundColor: '#f5f5f5' },
});

interface BadgeWithContentProps {
  count: number;
}

interface ITimeOverlap {
  point: TravelOptions;
  index: number;
  dayTravelPoints: TravelOptions[];
}
export interface ConfirmationDayInterface {
  open: boolean;
  content: any;
}
export interface TransportationDataInterface {
  label: string;
  icon: string;
  duration: number;
}

const P2PPointsViewer: React.FC<P2PPointsViewerProps> = ({
  dayTravelPoints,
  travelPoints,
}) => {
  const dispatch = useDispatch();

  const historyId = useSelector(
    (state: RootState) => state.P2PManualReducers.currentHistoryId,
  );

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );

  const [todos, setTodos] = useState<ITodoTravelPoint[] | null>(null);

  const [openThingsTodo, setOpenThingsTodo] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openTimeDialog, setOpenTimeDialog] = useState(false);
  const [openDurationWithEncodingDialog, setOpenDurationWithEncodingDialog] =
    useState(false);
  const [isFetchingFromGoogle, setIsFetchingFromGoogle] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [openConfirmDayDeletionDialog, setOpenConfirmDayDeletionDialog] =
    useState<ConfirmationDayInterface>({
      open: false,
      content: '',
    });

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const transportationDefaultData = {
    label: 'Walk',
    icon: '',
    duration: 0,
  };

  const [transportationData, setTransportationData] =
    useState<TransportationDataInterface>(transportationDefaultData);

  const isDisabled = (currentPoint: TravelOptions, index: number) => {
    // If there's only one day's worth of travel points, it should not be disabled

    if (
      Object.keys(travelPointsByDayIndex).length === 1 && // Only one day
      travelPointsByDayIndex[Number(Object.keys(travelPointsByDayIndex)[0])]
        .length === 1 // Only one travel point on that day
    ) {
      return false;
    }

    const prevPointDays =
      travelPointsByDayIndex[
        index === 0 && currentPoint.dayIndex === 1
          ? currentPoint.dayIndex
          : currentPoint.dayIndex - 1
      ];

    if (!prevPointDays || prevPointDays?.length === 0) {
      return false;
    }

    // if (prevPointDays.length === 1 && currentPoint.dayIndex === 1) {
    //   return false;
    // }

    const prevPoint = prevPointDays[prevPointDays.length - 1];

    // Disable if either current or prev point has the same coordinates
    return prevPoint.id === currentPoint.id;
  };

  const [loadingAfterDrag, setLoadingAfterDrag] = useState(false);

  const handleOnDragEnd = async (result: any) => {
    const { source, destination } = result;
    setIsDragging(false);
    setLoadingAfterDrag(true);
    // Exit early if there's no destination or if it's a click without a position change
    if (!destination || source.index === destination.index) {
      setLoadingAfterDrag(false);
      return;
    }

    const items = [...dayTravelPoints];

    // Check if dragging over the locked item
    const isDraggingOverLockedItem = isDisabled(
      items[destination.index],
      destination.index,
    );
    const draggedIndex = source.index;
    const destinationIndex = destination.index;
    const oldprevious = items[source.index - 1];

    if (isDraggingOverLockedItem) {
      // If dragged over a locked item, revert to original position
      // items.splice(draggedIndex, 1);
      // items.splice(draggedIndex, 0, dayTravelPoints[draggedIndex]);
      setLoadingAfterDrag(false);
      return;
    }

    const [reorderedItem] = items.splice(draggedIndex, 1);

    items.splice(destinationIndex, 0, reorderedItem);

    // Update timings and reset transports based on the provided logic
    const previousItem = items[destinationIndex - 1] || null;
    const nextItem = items[destinationIndex + 1] || null;

    const updatedReOrderedItem = { ...reorderedItem };

    const activityDuration = getDifferenceInMinutes(
      updatedReOrderedItem.endDate,
      updatedReOrderedItem.startDate,
    );

    if (nextItem) {
      updatedReOrderedItem.startDate = nextItem.startDate; // New start time based on next point
    } else if (!nextItem && previousItem) {
      updatedReOrderedItem.startDate = previousItem.endDate; // New start time based on previous point's end time
    }

    updatedReOrderedItem.endDate = dayjs(updatedReOrderedItem.startDate)
      .utc()
      .add(activityDuration, 'm')
      .format(); // Update end time

    const updatedItems = items.map((point) =>
      point.id === updatedReOrderedItem.id ? updatedReOrderedItem : point,
    );

    // Get the dayIndex of the reordered item
    const dayIndex = updatedReOrderedItem.dayIndex;

    // Clone travelPointsByDayIndex to avoid direct mutation
    let updatedTravelPointsByDayIndex: TravelPointsByDayIndex = {
      ...travelPointsByDayIndex,
    };

    updatedTravelPointsByDayIndex[dayIndex] = updatedItems;

    let pointsByDayIndex = updatedTravelPointsByDayIndex[dayIndex];

    const currentIndex = pointsByDayIndex.findIndex(
      (point) => point.id === reorderedItem.id,
    );

    if (selectedIndex !== currentIndex) {
      let duration: number = 0;
      let encodedPath: EncodedPathDataObject | undefined = {
        data: [],
        path: '',
      };

      let currentPointAfterSorting = pointsByDayIndex[currentIndex];

      const pointAfterCurrentIndex = pointsByDayIndex[currentIndex + 1];

      if (pointAfterCurrentIndex) {
        const result = await generateEncodedPathAndDuration(
          {
            ...currentPointAfterSorting,
            selectedTransport: getTravelMode('Drive'),
          },
          pointAfterCurrentIndex,
        );

        duration = result.duration;
        encodedPath = result.encodedPath;
      }

      pointsByDayIndex[currentIndex] = {
        ...currentPointAfterSorting,
        selectedTransport: getTravelMode('Drive'),
        encodedPath,
        duration,
        travelSegmentConfig: {
          modelScale: 1,
          modelEnum: [CarModels.Car],
          animationSpeed: 1,
        },
      };

      if (oldprevious) {
        const oldPreviousPointCurrentIndex = pointsByDayIndex.findIndex(
          (point) => point.id === oldprevious.id,
        );

        if (oldPreviousPointCurrentIndex !== -1) {
          const isLastPointOfDay =
            oldPreviousPointCurrentIndex === pointsByDayIndex.length - 1;
          if (isLastPointOfDay) {
            const { encodedPath, ...rest } = oldprevious;
            pointsByDayIndex[oldPreviousPointCurrentIndex] = {
              ...rest,
              duration: 0,
              selectedTransport: getTravelMode('Drive'),
            };
          } else {
            const nextPointAfterOldPrevious =
              pointsByDayIndex[oldPreviousPointCurrentIndex + 1];

            if (nextPointAfterOldPrevious) {
              const result = await generateEncodedPathAndDuration(
                { ...oldprevious, selectedTransport: getTravelMode('Drive') },
                nextPointAfterOldPrevious,
              );

              duration = result.duration;
              encodedPath = result.encodedPath;
            }
          }

          pointsByDayIndex[oldPreviousPointCurrentIndex] = {
            ...pointsByDayIndex[oldPreviousPointCurrentIndex],
            selectedTransport: getTravelMode('Drive'),
            encodedPath,
            duration,
            travelSegmentConfig: {
              modelScale: 1,
              modelEnum: [CarModels.Car],
              animationSpeed: 1,
            },
          };
        }
      }

      if (currentIndex > 0) {
        const newPrevious = pointsByDayIndex[currentIndex - 1];

        const result = await generateEncodedPathAndDuration(
          { ...newPrevious, selectedTransport: getTravelMode('Drive') },
          currentPointAfterSorting,
        );

        duration = result.duration;
        encodedPath = result.encodedPath;

        pointsByDayIndex[currentIndex - 1] = {
          ...pointsByDayIndex[currentIndex - 1],
          selectedTransport: getTravelMode('Drive'),
          encodedPath,
          duration,
          travelSegmentConfig: {
            modelScale: 1,
            modelEnum: [CarModels.Car],
            animationSpeed: 1,
          },
        };
      }
    }

    // check if the last place matches the first of the next point
    updatedTravelPointsByDayIndex = updateNextDays(
      updatedTravelPointsByDayIndex,
      reorderedItem.dayIndex,
      updateNextPointByDay,
    );

    // Dispatch the updated state
    dispatch(
      ActionsCreator.setTravelPointsByDayIndex(updatedTravelPointsByDayIndex),
    );
    setLoadingAfterDrag(false);
  };

  useLayoutEffect(() => {
    dispatch(ActionsCreator.setHeaderState(true));
  }, []);

  const handleThingsToDo = () => {
    setOpenThingsTodo(true);
  };

  const handleOpenTransportTypeModal = (
    point: TravelOptions,
    index: number,
  ) => {
    const currentTransportation: transportMapInterface =
      transportMap.find((el) => el.label === point.selectedTransport) ||
      transportMap[1];

    setTransportationData({
      ...currentTransportation,
      duration: point.duration,
    });
    setSelectedIndex(index); // Set the index of the selected item
    setOpenDialog(true); // Open the dialog
  };

  const updateTravelPoints = (
    selectedIndex: number,
    updatedLocation: TravelOptions,
    travelPointsByDayIndex: Record<number, TravelOptions[]>,
    dayTravelPoints: TravelOptions[],
    dispatch: Dispatch,
  ) => {
    const selectedPointId = dayTravelPoints[selectedIndex].id;
    const dayIndex = dayTravelPoints[selectedIndex].dayIndex;

    // Update travel points by day index immutably
    const updatedDayPoints = travelPointsByDayIndex[dayIndex].map((point) =>
      point.id === selectedPointId ? updatedLocation : point,
    );

    const updatedTravelPointsByDayIndex = {
      ...travelPointsByDayIndex,
      [dayIndex]: updatedDayPoints,
    };

    // Dispatch updated data
    dispatch(
      ActionsCreator.setTravelPointsByDayIndex(updatedTravelPointsByDayIndex),
    );
  };

  const handleSelectTransportType = async (
    transport: transportMapInterface,
  ) => {
    await setTransportationData((prev) => {
      return {
        ...prev,
        ...transport,
      };
    });
    setOpenDialog(false);
    if (getTravelMode(transport.label) === TravelMode.Plane) {
      setOpenTimeDialog(true);
    }
    setOpenDurationWithEncodingDialog(true);
  };

  const handleSelectTravelDetails = (
    transport: transportMapInterface,
    duration: number,
  ) => {
    if (selectedIndex !== null) {
      const updatedTransport = getTravelMode(transport.label);
      const updatedLocation = {
        ...dayTravelPoints[selectedIndex],
        duration,
        selectedTransport: updatedTransport,
        ...(dayTravelPoints[selectedIndex].selectedTransport !==
          updatedTransport && {
          encodedPath: { data: [], path: '' },
        }),
      };

      updateTravelPoints(
        selectedIndex,
        updatedLocation,
        travelPointsByDayIndex,
        dayTravelPoints,
        dispatch,
      );
    }
    
    setOpenDialog(false);
    setOpenDurationWithEncodingDialog(false);
  };
  
  const getSelectedTransportLabel = (point: TravelOptions) => {
    return getTypeFromTravelMode(point.selectedTransport as TravelMode);
  };

  const printDuration = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
      return `${hours}h ${
        remainingMinutes > 0 ? `${remainingMinutes} min` : ''
      }`.trim();
    }

    return `${minutes} min`;
  };

  const handleConfirmDayDeletion = (
    event: React.MouseEvent<HTMLElement>,
    dayIndex: number,
  ) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();

    setOpenConfirmDayDeletionDialog({
      open: true,
      content: dayIndex,
    });
  };

  const handleDeleteDay = (
    dayIndex: number,
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) => {
    const newTravelPointsByDayIndex = removeKey(
      dayIndex,
      travelPointsByDayIndex,
    );

    dispatch(
      ActionsCreator.setTravelPointsByDayIndex(newTravelPointsByDayIndex),
    );

    // Previous day
    dispatch(ActionsCreator.setCurrentDayIndex(dayIndex - 1));
    dispatch(ActionsCreator.setLastVisitedDayIndex(dayIndex - 1));
  };

  function removeKey(
    dayIndex: number,
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) {
    const { [dayIndex]: _, ...rest } = travelPointsByDayIndex; //  Destructuring to exclude the key
    return rest;
  }

  const handleFetchFromGoogle = async (
    selectedLocation: TravelOptions,
    nextLocation: TravelOptions,
    selectedIndex: number,
    transport: transportMapInterface,
  ) => {
    setIsFetchingFromGoogle(true);
    const updatedTransport = getTravelMode(transport.label);

    let updatedLocation = {
      ...selectedLocation,
      selectedTransport: updatedTransport,
      duration: selectedLocation.duration ?? 0,
    };

    const result = await generateEncodedPathAndDuration(
      updatedLocation,
      nextLocation,
    );

    updatedLocation = {
      ...updatedLocation,
      duration: result.duration,
      encodedPath: result.encodedPath,
    };

    updateTravelPoints(
      selectedIndex,
      updatedLocation,
      travelPointsByDayIndex,
      dayTravelPoints,
      dispatch,
    );

    setIsFetchingFromGoogle(false);
    setOpenDialog(false);
    setOpenDurationWithEncodingDialog(false);
  };

  const [isDragging, setIsDragging] = useState(false);
  const scrollContainerRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (historyId !== 0) {
      setLoading(true);
      getManualPointsById(historyId)
        .then((res) => {
          console.log({ res });
          const data = res.data as unknown as ITravelFormHistoryData;
          setTodos(data?.todos && data?.todos?.length ? data.todos : []);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [historyId]);

  const handleScroll = useCallback(() => {
    if (!isDragging || !scrollContainerRef.current) return;

    const container = scrollContainerRef.current as HTMLElement;
    const containerRect = container.getBoundingClientRect();
    const scrollSpeed = 10;

    // Auto-scroll when dragging near edges
    if (container.scrollTop < 20) {
      container.scrollBy(0, -scrollSpeed);
    } else if (
      container.scrollHeight - container.scrollTop - containerRect.height <
      20
    ) {
      container.scrollBy(0, scrollSpeed);
    }
  }, [isDragging]);

  return (
    <Fragment>
      <Box
        sx={{
          flex: '1 1 auto',
          overflowY: 'auto',
          p: 2,
          minHeight: 'calc(100vh - 64px)',
          paddingBottom: '150px',
          WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '4px',
          },
        }}
        ref={(el: HTMLDivElement) => {
          scrollContainerRef.current = el;
        }}
      >
        <DateSelector travelPoints={travelPoints} />
        <DayNumber
          disabled={false}
          onDeleteDay={(event, dayIndex) =>
            handleConfirmDayDeletion(event, dayIndex)
          }
        />
        {!travelPoints?.length && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#979797',
              paddingTop: '10px',
              fontSize: '24px',
              fontWeight: '600',
            }}
          >
            No Points Yet
          </Box>
        )}
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onBeforeDragStart={(e) => {
            setIsDragging(true);
          }}
        >
          <Droppable droppableId="travelPointsList">
            {(provided) => (
              <List
                ref={(el) => {
                  provided.innerRef(el);
                }}
                {...provided.droppableProps}
                sx={{
                  padding: 0,
                }}
              >
                {dayTravelPoints.length > 0 &&
                  dayTravelPoints?.map((point, index) => {
                    const shouldDisable = isDisabled(point, index);
                    const isStartPoint = index === 0;
                    const isEndPoint = index === dayTravelPoints.length - 1;
                    return (
                      <React.Fragment key={index}>
                        <Draggable
                          draggableId={index.toString()}
                          index={index}
                          isDragDisabled={shouldDisable}
                          // isDragDisabled={true}
                        >
                          {(provided, snapshot) => (
                            <Box
                              ref={provided.innerRef}
                              {...(shouldDisable
                                ? {}
                                : provided.draggableProps)} // Apply only if dragging is allowed
                              // Apply only if dragging is allowed
                              // {...(shouldDisable ? {} : provided.dragHandleProps)}
                              sx={{
                                opacity: snapshot.isDragging ? 0.5 : 1,
                                transition: 'opacity 0.2s ease',
                                position: shouldDisable ? 'relative' : 'static', // Lock position if shouldDisable
                              }}
                            >


                            <P2PTravelPoint
                              point={point}
                              dayTravel={dayTravelPoints}
                              index={index}
                              isStartPoint={isStartPoint}
                              isEndPoint={isEndPoint}
                              isDisabled={shouldDisable}
                              isDragging={isDragging}
                              provided={provided}
                              loadingAfterDrag={loadingAfterDrag}
                            />

                              {dayTravelPoints.length > 1 &&
                                index < dayTravelPoints.length - 1 && (
                                  <motion.div
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={
                                      isDragging || loadingAfterDrag
                                        ? { opacity: 0 }
                                        : { opacity: 1, scale: 1 }
                                    }
                                    transition={{ duration: 0.25, delay: 0.1 }}
                                    exit={{ opacity: 0 }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        gap: '1.2rem',
                                        mt: 1.4,
                                        mb: 1.4,
                                      }}
                                    >
                                      <TransportButton
                                        // variant="contained"
                                        onClick={() =>
                                          handleOpenTransportTypeModal(
                                            point,
                                            index,
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            padding: 0,
                                            display: 'flex',
                                            // justifyContent: 'space-between',
                                            alignItems: 'center',
                                            // width: '100%',
                                          }}
                                        >
                                          <img
                                            src={
                                              getSelectedTransportLabel(
                                                dayTravelPoints[index],
                                              ).icon
                                            }
                                            alt=""
                                            style={{
                                              height: '20px',
                                              width: '20px',
                                              marginRight: '5px',
                                            }}
                                          />
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              color: '#000',
                                              fontFamily: 'Poppins, sans-serif',
                                              fontSize: '10px',
                                              fontWeight: 600,
                                              textTransform: 'capitalize',
                                              marginRight: '8px',
                                            }}
                                          >
                                            {point?.duration === 0
                                              ? getSelectedTransportLabel(
                                                  dayTravelPoints[index],
                                                ).label
                                              : printDuration(point.duration)}
                                          </Typography>
                                        </div>
                                      </TransportButton>

                                      {handleTimeOverlap(
                                        point,
                                        index,
                                        dayTravelPoints,
                                      )}
                                    </Box>
                                  </motion.div>
                                )}
                            </Box>
                          )}
                        </Draggable>
                      </React.Fragment>
                    );
                  })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>

        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          sx={{
            mt: 2,
            cursor: 'pointer',
            width: 'fit-content',
            height: '50px',
            p: 2,
            borderRadius: '10px',
            // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
          }}
          onClick={() => {
            dispatch(ActionsCreator.setPointToAddIndex(-1));
            dispatch(ActionsCreator.setIsTravelPointSelectedState(false));
            dispatch(ActionsCreator.setHeaderState(false));
          }}
        >
          <Icon
            icon="icomoon-free:plus"
            width="24"
            height="24"
            style={{ color: '#fe7138' }}
          />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '22.4px',
              textAlign: 'center',
              ml: 2,
            }}
          >
            Add Point
          </Typography>
        </Box>

        {!loading && todos && todos?.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
            onClick={handleThingsToDo}
          >
            <BadgeWithContent count={todos.length <= 9 ? todos.length : 9} />
          </div>
        )}

        {/* Render dialog component and pass props */}

        <P2PTransportSelector
          open={openDialog}
          transportationData={transportationData}
          onClose={() => {
            setOpenDialog(false);
          }}
          onSave={handleSelectTransportType}
        />

        <P2PConfirmDayDeletion
          dialogData={openConfirmDayDeletionDialog}
          // This need for not hiding content inside modal until modal is fully closed
          onExited={() => {
            setOpenConfirmDayDeletionDialog((prev) => {
              return {
                ...prev,
                content: '',
              };
            });
          }}
          onClose={() => {
            setOpenConfirmDayDeletionDialog((prev) => {
              return {
                ...prev,
                open: false,
              };
            });
          }}
          onSave={(dayIndex: number) =>
            handleDeleteDay(dayIndex, travelPointsByDayIndex)
          }
        />

        {selectedIndex !== null &&
          dayTravelPoints.length > 1 &&
          selectedIndex < dayTravelPoints.length - 1 && (
            <DurationWithEncoding
              open={openDurationWithEncodingDialog}
              onClose={() => setOpenDurationWithEncodingDialog(false)}
              onSave={handleSelectTravelDetails}
              selectedLocation={dayTravelPoints[selectedIndex!]}
              nextLocation={dayTravelPoints[selectedIndex + 1!]}
              selectedIndex={selectedIndex!}
              transportationData={transportationData}
              handleFetchFromGoogle={handleFetchFromGoogle}
              openTimeDialog={openTimeDialog}
              setOpenTimeDialog={setOpenTimeDialog}
              isFetchingFromGoogle={isFetchingFromGoogle}
            />
          )}

        {!loading && todos && todos?.length > 0 && (
          <ThingsToDoDialog
            open={openThingsTodo}
            onClose={() => setOpenThingsTodo(false)}
            todos={todos}
          />
        )}
      </Box>
    </Fragment>
  );
};

export default P2PPointsViewer;

const handleTimeOverlap = (
  point: TravelOptions,
  index: number,
  travelPoints: TravelOptions[],
) => {
  return (
    <TimeOverlap point={point} index={index} dayTravelPoints={travelPoints} />
  );
};

const TimeOverlap: React.FC<ITimeOverlap> = ({
  point,
  index,
  dayTravelPoints,
}) => {
  const dispatch = useDispatch();
  const travelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );

  const [text, setText] = useState<string | null>(null);
  const [duration, setDuration] = useState<number>(0);

  const nextPoint = dayTravelPoints[index + 1];

  useEffect(() => {
    const calculateDuration = () => {
      if (!nextPoint) return;

      const pointDuration = point.duration;

      const durationFromCurrentToNext =
        index === 0
          ? getDifferenceInMinutes(point.startDate, nextPoint.startDate)
          : getDifferenceInMinutes(point.endDate, nextPoint.startDate);

      const totalDuration = durationFromCurrentToNext + pointDuration;

      setDuration(Math.abs(totalDuration));

      const newText =
        totalDuration > 0
          ? `${totalDuration} min time overlap`
          : totalDuration < 0
          ? `${Math.abs(totalDuration)} min empty space`
          : '';
      setText(newText);
    };

    calculateDuration();
  }, [nextPoint, index, point.startDate, point.duration, point.endDate]);

  const updateTravelPoints = (adjustmentFn: (date: string) => string) => {
    const updatedDayTravelPoints = dayTravelPoints.map((travelPoint, i) =>
      i > index
        ? {
            ...travelPoint,
            startDate: adjustmentFn(travelPoint.startDate),
            endDate: adjustmentFn(travelPoint.endDate),
          }
        : travelPoint,
    );

    const updatedTravelPointsByDayIndex = {
      ...travelPointsByDayIndex,
      [point.dayIndex]: updatedDayTravelPoints,
    };

    dispatch(
      ActionsCreator.setTravelPointsByDayIndex(updatedTravelPointsByDayIndex),
    );
    setText(null);
  };

  const handleDelayNextActivities = () => {
    updateTravelPoints((date) =>
      dayjs(date).add(duration, 'minute').utc().format(),
    );
  };

  const handleMoveNextActivitiesEarlier = () => {
    updateTravelPoints((date) =>
      dayjs(date).subtract(duration, 'minute').utc().format(),
    );
  };

  if (!text) return null;

  return (
    <Box
      sx={{
        padding: '6px',
        borderRadius: '20px',
        border: '2px dashed #ccc',
        minWidth: '250px',
        maxWidth: '250px',
        minHeight: '70px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '700',
          fontFamily: 'Poppins, sans-serif',
          lineHeight: '22.4px',
          color: '#959EA7',
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        {text.includes('overlap') && (
          <Button
            onClick={handleDelayNextActivities}
            variant="contained"
            color="primary"
            sx={{
              textTransform: 'capitalize',
              borderRadius: '50px',
              padding: '4px 8px',
              fontSize: '14px',
              fontWeight: '700',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            Delay next activities
          </Button>
        )}
        {text.includes('empty space') && (
          <Button
            onClick={handleMoveNextActivitiesEarlier}
            variant="contained"
            color="primary"
            sx={{
              textTransform: 'capitalize',
              borderRadius: '50px',
              padding: '4px 8px',
              fontSize: '14px',
              fontWeight: '700',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            Move Next Activities Earlier
          </Button>
        )}
      </Box>
    </Box>
  );
};

const inactiveShadow = '0px 0px 0px rgba(0,0,0,0.8)';

export function useRaisedShadow(value: MotionValue<number>) {
  const boxShadow = useMotionValue(inactiveShadow);

  useEffect(() => {
    let isActive = false;
    value.onChange((latest) => {
      const wasActive = isActive;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(boxShadow, '5px 5px 10px rgba(0,0,0,0.3)');
        }
      } else {
        isActive = false;
        if (isActive !== wasActive) {
          animate(boxShadow, inactiveShadow);
        }
      }
    });
  }, [value, boxShadow]);

  return boxShadow;
}

const CustomBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    fontFamily: 'Poppins, sans-serif',
    backgroundColor: '#FF9345',
    color: '#fff',
    fontSize: '18px', // Font size of the badge content
    height: '25px', // Height of the badge
    minWidth: '25px', // Minimum width of the badge
    borderRadius: '50%', // Make it a circle
    transform: 'translate(50%, -50%)', // Position the badge correctly
    top: '0', // Align to the top
    right: '0', // Align to the right
    position: 'absolute', // Position relative to the parent container
    lineHeight: '29.9px',
  },
}));

const BadgeWithContent: React.FC<BadgeWithContentProps> = ({ count }) => {
  return (
    <CustomBadge badgeContent={count} overlap="circular">
      <Typography
        sx={{
          backgroundColor: '#e0e0e0',
          padding: '8px 16px',
          borderRadius: '8px',
          cursor: 'pointer',
          display: 'inline-block',
          position: 'relative', // Necessary for the badge to position correctly
        }}
        className="custom-badge info-footer-hide-show"
      >
        Things to Do
      </Typography>
    </CustomBadge>
  );
};
