import React, { ChangeEvent } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';

interface LeaveReviewDialogProps {
  open: boolean;
  onClose: (review: string) => void;
  state: number | null;
  setState: React.Dispatch<React.SetStateAction<number | null>>;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 300,
    padding: '10px 10px',
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

const DoneButton = styled(Button)({
  width: 120,
  height: 64,
  borderRadius: 18,
  marginTop: '10px',
  fontFamily: 'Poppins',
  fontSize: '16px',
  background:
    ' radial-gradient(100% 100% at 0% 0%, #FF9345 0.01%, #FE7138 50%, #F25D21 100%) ',
  boxShadow: `0px 2px 6px #b8c5d033, 0px 6px 22px #b8c5d04d, 
              0px 9px 30px #c6d8e933, inset 0px 1.5px 1.5px #ffffff40`,
  '&:hover': { backgroundColor: '#e6602b' },
});

export const LeaveReviewDialog: React.FC<LeaveReviewDialogProps> = ({
  open,
  onClose,
  state,
  setState,
}) => {
  const handleSubmit = () => {
    if (state !== null) {
      onClose(state.toString());
    }
  };

  const handleRatingChanges = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === '') {
      setState(null);
    } else {
      const regex = /^\d+(\.\d{0,1})?$/;

      if (regex.test(value)) {
        const parsedValue = parseFloat(value);
        if (parsedValue >= 0.1 && parsedValue <= 5.0) {
          setState(parsedValue);
        }
      }
    }
  };

  return (
    <StyledDialog open={open} onClose={() => onClose('')}>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Add Review (out of 5)
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextField
          type="number"
          value={state || ''}
          onChange={handleRatingChanges}
          fullWidth
          sx={{
            width: '100px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px', // Apply border radius to the input container
            },
          }}
          InputProps={{
            inputProps: { min: 0.1, max: 5.0, step: 0.1 },
          }}
        />

        <DoneButton variant="contained" onClick={handleSubmit}>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: '#fff', fontFamily: 'Poppins, sans-serif' }}
          >
            Done
          </Typography>
        </DoneButton>
      </DialogContent>
    </StyledDialog>
  );
};
