import { motion } from 'framer-motion';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import CloseIcon from '@mui/icons-material/Close';
import './DetailsItem.css';
import parse from 'html-react-parser';
import { getDefaultMarkerSvgString } from '~/utility/utils';

export const DetailsItem = ({
  content,
  close,
}: {
  content: any;
  close: () => void;
}) => {
  const isMobile = useIsMobile();

  console.log('123 content', content);

  const initialAnimation = isMobile
    ? { x: -window.innerWidth }
    : { y: -window.innerHeight, x: '-50%' };
  const animation = isMobile ? { x: 0 } : { y: 0, x: '-50%' };
  const exit = isMobile
    ? { x: -window.innerWidth }
    : { y: -window.innerHeight, x: '-50%' };

  return (
    <motion.div
      transition={{
        ease: 'easeInOut',
        duration: 0.4,
        type: 'spring',
        bounce: false,
      }}
      initial={initialAnimation}
      animate={animation}
      exit={exit}
      className={`details-item ${isMobile ? '' : 'desktop'}`}
    >
      <CloseIcon fontSize="medium" onClick={close} />
      <div className="details-item-content">
        <div className="details-item-header">
          {parse(getDefaultMarkerSvgString(content.category))}
          <div className="details-item-name">{content.name}</div>
        </div>
        <div className="details-item-summary">“{content.summary}”</div>
        <a
          className="custom-badge active details-item-badge"
          href={content.url_book_place}
          target="_blank"
          rel="noreferrer"
        >
          View Official Website
        </a>
        <div className="details-item-estimate">
          <div className="custom-badge estimate-badge">{content.review} ⭐</div>
          {!!content.price && (
            <div className="custom-badge estimate-badge">
              ~US${content.price}
            </div>
          )}
        </div>
        <div className="details-item-image" onClick={close}>
          <img src={content.media[0].thumbnail} alt="" />
          <img
            src="./icons/playIcon.png"
            alt=""
            className="details-play-button"
          />
        </div>
        <div className="details-item-desc">{content.notes}</div>
        <a
          className="custom-badge badge-site"
          target="_blank"
          href={`https://google.com/maps/search/${content?.name}`}
          style={{ textDecoration: 'none' }}
          rel="noreferrer"
        >
          Search on Google
        </a>
      </div>
    </motion.div>
  );
};
