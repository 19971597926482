import styled from '@emotion/styled';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FC } from 'react';
import { TravelOptions, TravelPointsByDayIndex } from '~/utility/models';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import {
  generateEncodedPathAndDuration,
  getInitialDateTime,
  getTravelMode,
  updateNextDays,
  updateNextPointByDay,
} from '~/utility/utils';
import ActionsCreator from '~/redux/actions';
import { DEFAULT_DURATION_HOUR } from '../P2PManual/P2PSearchAutocomplete/constant';
import { store } from '~/redux/store';
import { generateUUID } from 'three/src/math/MathUtils';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';
import { ITodoTravelPoint } from '../AddToTrip';

interface ThingsToDoDialogProps {
  open: boolean;
  onClose: () => void;
  todos: ITodoTravelPoint[];
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

export const ThingsToDoDialog: React.FC<ThingsToDoDialogProps> = ({
  open,
  onClose,
  todos,
}) => {
  const isMobile = useIsMobile();
  const close = (e: {
    stopPropagation?: () => void;
    nativeEvent?: { stopImmediatePropagation: () => void };
  }) => {
    e?.stopPropagation?.();
    e?.nativeEvent?.stopImmediatePropagation?.();
    onClose();
  };

  return (
    <StyledDialog
      open={open}
      onClose={(e) => {
        close(e);
      }}
      fullScreen={isMobile}
    >
      {/* Close Button */}
      <IconButton
        onClick={close}
        style={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: '#000',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          my: '1rem',
        }}
      >
        Things to Do{' '}
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {todos?.map((todo, index) => (
          <Item key={index} todo={todo} onClose={onClose} />
        ))}
      </DialogContent>
    </StyledDialog>
  );
};

const Item: FC<{
  todo: ITodoTravelPoint;
  onClose: () => void;
}> = ({ todo, onClose }) => {
  const dispatch = useDispatch();

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );
  const currentDayIndex: number = useSelector(
    (state: RootState) => state.P2PManualReducers.currentDayIndex,
  );

  const lastVisitedDayIndex: number = useSelector(
    (state: RootState) => state.P2PManualReducers.lastVisitedDayIndex,
  );

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const lastVisitedDay: TravelOptions[] = [
      ...(travelPointsByDayIndex[lastVisitedDayIndex] || []),
    ];

    if (lastVisitedDay && lastVisitedDay.length > 0) {
      const lastPoint: TravelOptions = {
        ...lastVisitedDay[lastVisitedDay.length - 1],
      };

      if (lastPoint) {
        const firstPoint = lastVisitedDay[0];

        if (lastPoint?.id === firstPoint?.id) {
          const newEndOfDayDate = dayjs.utc(lastPoint.startDate);

          let travelPoint = {
            ...todo,
            startDate: newEndOfDayDate.format(),
            endDate: newEndOfDayDate.add(1, 'hour').format(),
            dayIndex: lastVisitedDayIndex,
            selectedTransport: getTravelMode('Drive'),
            id: generateUUID(),
            duration: 0,
            placeId: '',
          };

          // update last point duration and encodedPath
          let updatedLastPoint = { ...lastPoint };

          // Determine the correct day index and position
          const updatedDayIndex = updatedLastPoint.dayIndex;
          const updatedTravelPointsByDay = [
            ...(travelPointsByDayIndex[updatedDayIndex] || []),
          ];

          // Find the position of the last point
          const updatedLastPointIndex = updatedTravelPointsByDay.findIndex(
            (point) => point.id === updatedLastPoint.id,
          );

          if (updatedLastPointIndex !== -1) {
            const result = await generateEncodedPathAndDuration(
              updatedTravelPointsByDay[updatedLastPointIndex],
              travelPoint as TravelOptions,
            );

            const newStartDatePlusDuration = dayjs
              .utc(travelPoint.startDate)
              .add(result.duration, 'm')
              .format();
            const newEndDatePlusDuration = dayjs
              .utc(travelPoint.endDate)
              .add(result.duration, 'm')
              .format();

            travelPoint = {
              ...travelPoint,
              startDate: newStartDatePlusDuration,
              endDate: newEndDatePlusDuration,
            };

            // Update the last point in the array
            updatedTravelPointsByDay[updatedLastPointIndex] = {
              ...updatedLastPoint,
              duration: result.duration,
              encodedPath: result.encodedPath,
            };
          }

          const travelPointsByDayIndexFromStore =
            store.getState().P2PManualReducers.travelPointsByDayIndex;

          // Get the current day index
          const dayIndex = travelPoint.dayIndex;

          //  Create a new updated object for travelPointsByDayIndex
          const newTravelPointsByDayIndexFromStore = {
            ...travelPointsByDayIndexFromStore,
            // Update the day where the last point exists
            [updatedDayIndex]: updatedTravelPointsByDay,
          };

          let newUpdatedTravelPointsByDayIndex: TravelPointsByDayIndex = {
            ...newTravelPointsByDayIndexFromStore,
            // Add the new travel point to its day
            [dayIndex]: [
              ...(newTravelPointsByDayIndexFromStore[dayIndex] || []),
              travelPoint as TravelOptions,
            ],
          };

          // check if the last place matches the first of the next point
          newUpdatedTravelPointsByDayIndex = updateNextDays(
            newUpdatedTravelPointsByDayIndex,
            dayIndex,
            updateNextPointByDay,
          );

          dispatch(
            ActionsCreator.setTravelPointsByDayIndex(
              newUpdatedTravelPointsByDayIndex,
            ),
          );

          dispatch(ActionsCreator.setLastVisitedDayIndex(dayIndex));
        } else {
          let travelPoint = {
            ...todo,
            startDate: lastPoint.endDate,
            endDate: dayjs(lastPoint.endDate).utc().add(1, 'hour').format(),
            dayIndex: lastVisitedDayIndex,
            selectedTransport: getTravelMode('Drive'),
            id: generateUUID(),
            duration: 0,
            placeId: '',
          };

          // update last point duration and encodedPath
          let updatedLastPoint = { ...lastPoint };

          // Determine the correct day index and position
          const updatedDayIndex = updatedLastPoint.dayIndex;
          const updatedTravelPointsByDay = [
            ...(travelPointsByDayIndex[updatedDayIndex] || []),
          ];

          // Find the position of the last point
          const updatedLastPointIndex = updatedTravelPointsByDay.findIndex(
            (point) => point.id === updatedLastPoint.id,
          );

          if (updatedLastPointIndex !== -1) {
            const result = await generateEncodedPathAndDuration(
              updatedTravelPointsByDay[updatedLastPointIndex],
              travelPoint as TravelOptions,
            );

            const newStartDatePlusDuration = dayjs
              .utc(travelPoint.startDate)
              .add(result.duration, 'm')
              .format();

            const newEndDatePlusDuration = dayjs
              .utc(travelPoint.endDate)
              .add(result.duration, 'm')
              .format();

            travelPoint = {
              ...travelPoint,
              startDate: newStartDatePlusDuration,
              endDate: newEndDatePlusDuration,
            };

            // Update the last point in the array
            updatedTravelPointsByDay[updatedLastPointIndex] = {
              ...updatedLastPoint,
              duration: result.duration,
              encodedPath: result.encodedPath,
            };
          }

          const travelPointsByDayIndexFromStore =
            store.getState().P2PManualReducers.travelPointsByDayIndex;

          // Get the current day index
          const dayIndex = travelPoint.dayIndex;

          //  Create a new updated object for travelPointsByDayIndex
          const newTravelPointsByDayIndexFromStore = {
            ...travelPointsByDayIndexFromStore,
            // Update the day where the last point exists
            [updatedDayIndex]: updatedTravelPointsByDay,
          };

          let updatedTravelPointsByDayIndex: TravelPointsByDayIndex = {
            ...newTravelPointsByDayIndexFromStore,
            // Add the new travel point to its day
            [dayIndex]: [
              ...(newTravelPointsByDayIndexFromStore[dayIndex] || []),
              travelPoint as TravelOptions,
            ],
          };

          updatedTravelPointsByDayIndex = updateNextDays(
            updatedTravelPointsByDayIndex,
            dayIndex,
            updateNextPointByDay,
          );

          dispatch(
            ActionsCreator.setTravelPointsByDayIndex(
              updatedTravelPointsByDayIndex,
            ),
          );
        }
      }
    } else {
      let currentDate = dayjs().tz(todo?.timezone);
      if (currentDayIndex > -1) {
        // Adjust dates based on the currentDayIndex
        currentDate = currentDate.add(currentDayIndex - 1, 'day');
      }
      const startDate = getInitialDateTime(currentDate);

      let travelPoint = {
        ...todo,
        startDate: startDate,
        endDate: dayjs(startDate)
          .add(DEFAULT_DURATION_HOUR, 'hour')
          .utc()
          .format(),
        dayIndex: store.getState().P2PManualReducers.lastVisitedDayIndex,
        selectedTransport: getTravelMode('Drive'),
        id: generateUUID(),
        duration: 0,
        encodedPath: {
          data: [],
          path: '',
        },
        placeId: '',
      };

      console.log({ travelPoint });

      // Get the current day index
      const dayIndex = travelPoint.dayIndex;

      // Create a new updated object for travelPointsByDayIndex
      const updatedTravelPointsByDayIndex = {
        ...travelPointsByDayIndex,
        [dayIndex]: [
          ...(travelPointsByDayIndex[dayIndex] || []), // existing points for the day, or empty array if none
          travelPoint as TravelOptions,
        ],
      };

      dispatch(
        ActionsCreator.setTravelPointsByDayIndex(updatedTravelPointsByDayIndex),
      );
    }

    dispatch(ActionsCreator.setIsTravelPointSelectedState(true));
    dispatch(ActionsCreator.setMapButtonState(true));
    dispatch(ActionsCreator.setHeaderState(true));

    onClose();
  };

  return (
    <Box
      m="1rem"
      sx={{
        cursor: 'pointer', // Disable cursor ifloading
        opacity: 1, // Dim the component whileloading
        pointerEvents: 'auto', // Disable pointer events whileloading
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
      }}
    >
      <img
        src={'/' + todo.typeOfPoint + '.svg'}
        height="48"
        loading="lazy"
        alt="Point of Attraction"
        style={{ marginRight: '10px' }}
      ></img>

      <Box>
        <Typography
          sx={{
            fontFamily: 'Poppins, sans-serif',
            color: '#0E2132',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '36px',
          }}
        >
          {todo.value ?? todo.label}
        </Typography>
        {todo?.city && todo?.country && (
          <Typography
            sx={{
              fontFamily: 'Poppins, sans-serif',
              color: '#0E2132',
              fontSize: '12px',
              fontWeight: '600',
              lineHeight: '18px',
              opacity: 0.85,
            }}
          >
            {todo?.city}, {todo?.country}
          </Typography>
        )}
      </Box>

      <IconButton onClick={handleClick}>
        <img
          src="/icons/+.svg"
          height="30"
          loading="lazy"
          alt="Add to trip"
          style={{ marginRight: '10px' }}
        />
      </IconButton>
    </Box>
  );
};
