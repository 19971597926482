import { BufferGeometry, CatmullRomCurve3, Mesh, Object3D } from 'three';
import { Map } from 'maplibre-gl';
import { generate3DArc, getScaleFromZoom } from './utility/utils';
import {
    AnimationController,
    PlaneAnimationConfig,
} from './AnimationController';
import { EasingFunctions } from './utility/easing';
import { unprojectFromWorld } from '~/CustomThreeJsWrapper/utility/utils';
import { AnimationTravelData } from '~/utility/models';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { Config } from './MultiTransportAnimationController';
import {
    canMultiTransportDeclareArrival,
    currentTravelTypeSignal,
    destinationStateSignal,
    loadStateProvokeSignal,
    setAnimationState,
} from '~/components/ViewTravel/common';
import { handleAnimationState } from '~/components/ViewTravel/common';
import { mapOffset } from '~/components/ViewTravel/MobileFooter/BottomSheet';
import { isCalendarAnimating } from '~/components/ViewTravel/DesktopFooter/CalendarLine';

/**
 * Class that controls the animation of a plane along a path on a map.
 * Inherits from the base AnimationController class.
 */
class StayPointAnimationController extends AnimationController {
    /**
     * @public
     * @defaultValue 10
     *
     * This variable, `planeGrowPercentage`, defines the percentage by which the plane (presumably a 3D plane object) will grow in size. The value is a number representing a percentage and should be between 0 and 100. A value of 10, as set by the default value, indicates a 10% increase in size.
     *
     * This value is typically used in conjunction with a function or logic that modifies the scale of the plane object. For example, a function might take the current scale of the plane, add this percentage to it, and then apply the new scale to the plane.
     *
     * You can adjust this value to control the growth rate of the plane. A higher value will result in a larger size increase, while a lower value will result in a smaller size increase.
     */
    planeGrowPercentage: number = 10;

    /**
     * @public
     *
     * This variable, `modelMaxScale`, represents the maximum scale that the plane object can reach. It's a number value that defines the upper limit for the plane's size.
     *
     * This variable is crucial for preventing the plane from growing infinitely or exceeding a desired size limit. It's often used in the same logic that utilizes `planeGrowPercentage`. When modifying the plane's scale, a check can be performed against `modelMaxScale` to ensure the new scale doesn't surpass this limit.
     *
     * The value of `modelMaxScale` should be chosen based on your specific needs and the intended size range of the plane object. You can set it to a large value to allow for significant growth, or a smaller value to restrict the plane's size.
     *
     * It's important to note that this variable is marked as non-null (`!`) using the TypeScript exclamation mark syntax. This indicates that the variable is guaranteed to have a value assigned to it before it's used. This helps prevent potential runtime errors that might occur if the variable is accessed before initialization.
     */
    modelMaxScale!: number;

    /**
     * Constructor for the base animation controller class. This class provides common functionalities
     * for animating vehicles or planes along a travel path.
     *
     * @param map A reference to the Maplibre map instance
     * @param index The index of the current travel segment
     * @param model A Three.js object representing the GLTF to be animated
     * @param tb A reference to the Three.js wrapper class
     */
    constructor(map: Map, index: number, tb: CustomThreeJSWrapper) {
        super();
        if (!map) return;
        this.map = map;
        this.tb = tb;
        this.index = index;

        this.pathGeometry = new BufferGeometry();
    }

    /**
     * Sets up the path geometry and mesh for the travel segment
     *
     * @param curveHeight The height of the curved path
     */
    setupPath(curveHeight: number): void {
        /**
         * Generates a 3D arc geometry based on the travel segment's decoded path
         *
         * @param path An array of coordinates representing the decoded path
         * @param curveHeight The height of the curved path
         * @param tb A reference to the Three.js wrapper class
         * @returns An object containing the generated path mesh, path curve, and material
         */
        const pathMesh = generate3DArc(
            this.travelSegment.decodedPath.path,
            curveHeight,
            this.tb,
        );

        /**
         * Stores a reference to the Catmull-Rom curve representing the 3D path
         */
        // this.pathCurve = pathMesh.pathCurve as CatmullRomCurve3;

        /**
         * Stores a reference to the material used for rendering the path mesh
         */
        this.material = pathMesh?.material;

        /**
         * Stores a reference to the underlying buffer geometry of the path mesh
         */
        this.pathGeometry = pathMesh?.pathGeometry as BufferGeometry;

        /**
         * Creates a new Three.js mesh object using the generated path geometry and material
         */
        this.pathMesh = new Mesh(this.pathGeometry, this.material);

        /**
         * Adds the path mesh to the Three.js scene using the provided wrapper
         */
        this.tb.add(this.pathMesh);
    }

    /**
     * Starts the plane animation along the travel path.
     *
     * @param animationConfig
     * Configuration object for the plane animation
     * (specific to StayPointAnimationController)
     *
     * @remarks
     * This function overrides the base class's `startAnimation` method.
     * It performs plane-specific setup tasks in addition to the common
     * animation setup.
     */
    setupAnimation(animationConfig: PlaneAnimationConfig) {
        this.animationConfig = animationConfig;
        this.setupModelForAnimation();
        handleAnimationState({
            state: 'depart',
        });
        // Pause for 3 seconds and then call the completion callback
        setTimeout(() => {
            this.isAnimationExpired = true;
            this.onAnimationCompleteCallback();
            handleAnimationState({
                state: 'arrival',
            });
        }, 1500);



    }

    /**
     * Gets the zoom level at which the animation started
     *
     * @returns The zoom level of the map camera when the animation began
     */
    getAnimationStartZoom(): number {
        return this.cameraZoom;
    }

    clean() {
        // No path to clean
    }

    setupAnimationTime() {
        // No animation timing to set up
    }

    update(delta?: number): void {
        // No update logic needed
    }

    setupModelForAnimation(): void {
        if (!this.model) return;

        this.tb.add(this.model);
        if (!(this.model as Object3D).visible) {
            (this.model as Object3D).visible = true;
        }

        // Set the initial position of the model
        const position = this.travelSegment.decodedPath.path[0];
        (this.model as Object3D).position.set(position[0], position[1], position[2]);
        (this.model as Object3D).scale.set(1, 1, 1);
    }

}

export { StayPointAnimationController };
