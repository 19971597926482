import React, { useMemo, useEffect, useState } from 'react';
import { signal } from '@preact/signals-core';
import { useDispatch, useSelector } from 'react-redux';

import MobileFooter from './MobileFooter';
import MobileHeader from './MobileHeaderNew';
import { DAYJS, isDiscoveryOverlayVisible } from '~/map/ViewTravel';
import { AttatchedMedia } from './MobileFooter/OverviewDataComponent';
import ShareModal from './ShareModal';
import FeedbackPopup from '../FeedbackPopup';
import MobileDiscoverModal from './MobileDiscoverModal';
import StoriesOverlay from './StoriesOverlay';
import { MANUAL_TRIP_HISTORY } from '../P2PManual';

import {
  activitiesSignal,
  animationStateSignal,
  departureDatesSignal,
  formatCategory,
  PauseAnimation,
  TravelDataSignal,
  useStateWithChangeCheck,
} from './common';

import { TravelFormData } from '~/utility/models';
import useHandleMapInteraction from './useHandleMapInteraction';

import subscribeSignal from '~/hooks/subscribeSignal';
import { RootState } from '~/redux/reducers';
import AddNewPointSheet from './AddNewPointSheet/AddNewPointSheet';

export const isOverlayVisible = signal(true);
export const isShareModalVisible = signal(false);
export const isDiscoverModalVisible = signal(true);

export default ({
  isDataFetching,
  travelPoints,
  mapStyleIndex,
  handleDateSelect,
  audio,
}: {
  isDataFetching: boolean;
  travelPoints: any;
  mapStyleIndex: number;
  handleDateSelect: (index: number) => void;
  audio?: string;
}) => {
  const dispatch = useDispatch();

  const [animationState, setAnimationState] = useStateWithChangeCheck({
    state: 'idle',
  });

  const { selectedCity, travelPointsByDayIndex } = useSelector(
    (state: RootState) => state.P2PManualReducers,
  );
  const travelRawData = useSelector(
    (state: RootState) => state.TripInfoReducers.travelRawData,
  );

  const [showDiscoverModal, setShowDiscoverModal] = useState(true);

  const [showShareModal, setShowShareModal] = useState(
    isShareModalVisible.peek(),
  );
  useHandleMapInteraction();

  subscribeSignal(isShareModalVisible, setShowShareModal);

  const lightBG = mapStyleIndex === 1 || mapStyleIndex === 3;

  useEffect(() => {
    if (!isDataFetching && !travelPoints?.length) {
      setShowDiscoverModal(false);
    }
  }, [isDataFetching, travelPoints]);

  useEffect(() => {
    TravelDataSignal.value = travelPoints;
    // preLoadAllImages(travelPoints);
    const subscription = animationStateSignal.subscribe((state: any) => {
      setAnimationState(state);
    });
    return () => {
      subscription();
      sessionStorage.removeItem(MANUAL_TRIP_HISTORY);
    };
  }, []);

  const tripTo = useMemo(
    () => getIntelligentTripToName(travelPoints),
    [travelPoints],
  );

  const tripToObj = useMemo(
    () => getIntelligentTripToObject(travelPoints),
    [travelPoints],
  );

  const startDate = useMemo(
    () => getIntelligentStartDate(travelPoints),
    [travelPoints],
  );
  const endDate = useMemo(
    () => getIntelligentEndDate(travelPoints),
    [travelPoints],
  );
  const duration = useMemo(
    () => getIntelligentTripDurationArray(travelPoints),
    [travelPoints],
  );
  const from = useMemo(
    () => getIntelligentFromLocations(travelPoints),
    [travelPoints],
  );
  const fromTime = useMemo(
    () => getIntelligentfromTimeArray(travelPoints),
    [travelPoints],
  );
  const destination = useMemo(
    () => getIntelligentDestinationLocations(travelPoints),
    [travelPoints],
  );
  const destinationTime = useMemo(
    () => getIntelligentDestinationTimeArray(travelPoints),
    [travelPoints],
  );

  const selectedTransports = useMemo(
    () => travelPoints.map((point: TravelPoint) => point.selectedTransport),
    [travelPoints],
  );
  const departureDates = useMemo(() => {
    const dd = travelPoints.map((point: TravelPoint) =>
      DAYJS(point.departure.dateTime).tz(point.departure.timezone),
    );
    departureDatesSignal.value = dd;
    return dd;
  }, [travelPoints]);

  useMemo(() => {
    const activities = travelPoints.map((point: TravelPoint) => {
      return {
        arrival: point.arrival,
        departure: point.departure,
      };
    });
    activitiesSignal.value = activities;
  }, [travelPoints]);

  // const isMobile = window.innerWidth <= 1025;

  if (!animationState) return null;

  return (
    <>
      <>
        {travelPoints?.length && showDiscoverModal && (
          <MobileDiscoverModal
            travelPoints={travelPoints}
            hideModal={() => {
              setTimeout(() => {
                isDiscoveryOverlayVisible.value = false;
              }, 250);
              isDiscoverModalVisible.value = false;
              setShowDiscoverModal(false);
            }}
          />
        )}
        {!showDiscoverModal && (
          <>
            {showShareModal && <ShareModal travelPoints={travelPoints} />}

            {/* For New Trips */}
            {((selectedCity &&
              Object.keys(travelPointsByDayIndex)?.length > 0) ||
              //  FOR Old Trips, where there is no selected city
              (!selectedCity &&
                Object.keys(travelPointsByDayIndex)?.length > 0) ||
              // For Tokyo
              (!Object.keys(travelPointsByDayIndex)?.length &&
                !selectedCity &&
                !travelRawData.identifier)) && (
              <MobileFooter
                step={animationState}
                to={tripTo}
                toObj={tripToObj}
                data={travelPoints}
                currentDate={departureDates[animationState?.calendarStep || 0]}
                startDate={startDate}
                endDate={endDate}
                duration={duration}
                from={from}
                fromTime={fromTime}
                destination={destination}
                destinationTime={destinationTime}
                selectedTransports={selectedTransports}
                lightBG={lightBG}
                departureDates={departureDates}
                handleCardSelect={handleDateSelect}
              />
            )}

            {selectedCity && !Object.keys(travelPointsByDayIndex)?.length && (
              <AddNewPointSheet />
            )}

            <MobileHeader travelPoints={travelPoints} />
            <FeedbackPopup />
            <StoriesOverlay travelPoints={travelPoints} />
          </>
        )}
      </>
      {/* ) : (
          <>
            <DesktopFooter
              step={animationState}
              to={tripTo}
              toObj={tripToObj}
              data={travelPoints}
              currentDate={departureDates[animationState?.calendarStep || 0]}
              startDate={startDate}
              endDate={endDate}
              duration={duration}
              from={from}
              fromTime={fromTime}
              destination={destination}
              destinationTime={destinationTime}
              selectedTransports={selectedTransports}
              lightBG={lightBG}
              departureDates={departureDates}
              audio={audio}
              handleCardSelect={handleDateSelect}
            />
            <DesktopPicturesOverlay />
            <DesktopHeader
              step={animationState}
              data={travelPoints}
              startDate={startDate}
              endDate={endDate}
              currentDate={departureDates[animationState?.calendarStep || 0]}
              lightBG={lightBG}
              handleCardSelect={handleDateSelect}
              departureDates={departureDates}
            />
          </>
        )} */}
    </>
  );
};

export interface TravelPoint {
  arrival: Arrival;
  departure: Arrival;
  selectedTransport: string;
  encodedPath: string;
  dayIndex?: string;
  duration?: number;
}

export interface Arrival {
  id?: string;
  location: Location;
  dateTime: Date;
  category: string;
  timezone: string;
  images?: string[];
  media?: AttatchedMedia[];
  tiktokData?: Pick<AttatchedMedia, 'thumbnail' | 'url'>[];
  cost?: number;
  review?: number;
  longDescription?: string;
  description?: string;
}

export interface Location {
  value: string;
  label: string;
  text: string;
  code: string;
  city: string;
  country: string;
  coordinates: number[];
  placeId: string;
  street?: string;
}

export enum DateFormatType {
  DayAndDate = 'DayAndDate',
  DayDateYear = 'DayDateYear',
  MonthDateCommaYear = 'MonthDateCommaYear',
  MonthDateOnly = 'MonthDateOnly',
  FullDayAndDate = 'FullDayAndDate',
  FullWithTime = 'FullWithTime',
  ISOFormat = 'ISOFormat',
  MonthDayYear = 'MonthDayYear',
  DayMonthYear = 'DayMonthYear',
  YearMonthDay = 'YearMonthDay',
  HourMinuteSecond = 'HourMinuteSecond',
  FormatAMPM = 'FormatAMPM',
  FullDayMonthDateCommaYear = 'FullDayMonthDateCommaYear',
}

export function formatDate(dateString: Date, formatType: DateFormatType) {
  const date = dateString;

  // with DAYJS
  const formatters: { [key in DateFormatType]: () => string } = {
    [DateFormatType.DayAndDate]: () => DAYJS(date).format('ddd, MMM D'),
    [DateFormatType.DayDateYear]: () => DAYJS(date).format('ddd, MMM D, YYYY'),
    [DateFormatType.FullDayAndDate]: () => DAYJS(date).format('dddd, MMMM D'),
    [DateFormatType.FullWithTime]: () =>
      DAYJS(date).format('dddd, MMMM D, h:mm:ss a'),
    [DateFormatType.ISOFormat]: () => DAYJS(date).toISOString(),
    [DateFormatType.MonthDateCommaYear]: () =>
      DAYJS(date).format('MMMM D, YYYY'),
    [DateFormatType.MonthDayYear]: () => DAYJS(date).format('MM/DD/YYYY'),
    [DateFormatType.DayMonthYear]: () => DAYJS(date).format('DD/MM/YYYY'),
    [DateFormatType.YearMonthDay]: () => DAYJS(date).format('YYYY/MM/DD'),
    [DateFormatType.HourMinuteSecond]: () => DAYJS(date).format('HH:mm:ss'),
    [DateFormatType.FormatAMPM]: () => DAYJS(date).format('h:mm a'),
    [DateFormatType.FullDayMonthDateCommaYear]: () =>
      DAYJS(date).format('dddd, MMMM D, YYYY'),
    [DateFormatType.MonthDateOnly]: () => DAYJS(date).format('MMMM D'),
  };

  return formatters[formatType]();
}

export function getIntelligentTripToName(travelPoints: TravelPoint[]): string {
  if (!travelPoints || travelPoints?.length === 0) {
    return 'No travel points provided';
  }

  const travelPointssorted = travelPoints.sort(
    (a, b) =>
      DAYJS(a.arrival.dateTime).tz(a.arrival.timezone).toDate().getTime() -
      DAYJS(b.arrival.dateTime).tz(b.arrival.timezone).toDate().getTime(),
  );

  // Assuming travelPoints are sorted by dateTime, or you could sort them here
  const finalDestination =
    travelPointssorted[travelPointssorted.length - 1].arrival.location;

  return finalDestination.city;
}

export function getIntelligentTripToObject(travelPoints: TravelPoint[]) {
  if (!travelPoints || travelPoints.length === 0) {
    return [];
  }
  return travelPoints.map((point) => ({
    ...point.arrival.location,
    srccategory: point.arrival.category,
    depcategory: point.departure.category,
  }));
}

export const getIntelligentStartDate = (
  travelPoints: TravelPoint[],
): Date | null => {
  if (!travelPoints || travelPoints.length === 0) {
    return null;
  }

  const travelPointssorted = travelPoints.sort(
    (a, b) =>
      DAYJS(a.departure.dateTime).tz(a.departure.timezone).toDate().getTime() -
      DAYJS(b.departure.dateTime).tz(b.departure.timezone).toDate().getTime(),
  );

  const startDate = travelPointssorted[0].departure.dateTime;

  return startDate;
};

export const getIntelligentEndDate = (
  travelPoints: TravelPoint[],
): Date | null => {
  if (!travelPoints || travelPoints.length === 0) {
    return null;
  }

  const travelPointssorted = travelPoints.sort(
    (a, b) =>
      DAYJS(a.arrival.dateTime).tz(a.arrival.timezone).toDate().getTime() -
      DAYJS(b.arrival.dateTime).tz(b.arrival.timezone).toDate().getTime(),
  );

  const endDate =
    travelPointssorted[travelPointssorted.length - 1].arrival.dateTime;

  return endDate;
};

export const getIntelligentTripDurationArray = (
  travelPoints: TravelPoint[],
): string[] => {
  if (!travelPoints || travelPoints.length === 0) {
    return [];
  }

  // get trip duration of each leg
  const tripDurationArray = travelPoints.map((point, index) => {
    let duration: number;
    const currentTransportation = travelPoints[index];

    if (currentTransportation?.duration) {
      duration = currentTransportation.duration * 60 * 1000;
    } else {
      duration =
        DAYJS(point.arrival.dateTime)
          .tz(point.arrival.timezone)
          .toDate()
          .getTime() -
        DAYJS(point.departure.dateTime)
          .tz(point.departure.timezone)
          .toDate()
          .getTime();
    }

    // format duration in days, hours, minutes
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

    return `${
      days
        ? days +
          `
        ${days > 1 ? ' days' : ' day'}
      `
        : ''
    } ${
      hours
        ? hours +
          `
        ${hours > 1 ? ' hours' : ' hour'}
      `
        : ''
    } ${
      minutes
        ? minutes +
          `
        ${minutes > 1 ? ' minutes' : ' minute'}
      `
        : '0 minute'
    }`;
  });
  return tripDurationArray;
};

export const getIntelligentFromLocations = (
  travelPoints: TravelPoint[],
): string[] => {
  if (!travelPoints || travelPoints.length === 0) {
    return [];
  }
  return travelPoints.map((point) => formatCategory(point.departure.category));
};

export const getIntelligentfromTimeArray = (
  travelPoints: TravelPoint[],
): string[] => {
  if (!travelPoints || travelPoints.length === 0) {
    return [];
  }

  return travelPoints.map((point) =>
    DAYJS(point.departure.dateTime)
      .tz(point.departure.timezone)
      .format('h:mm a'),
  );
};

export const getIntelligentDestinationLocations = (
  travelPoints: TravelPoint[],
): string[] => {
  if (!travelPoints || travelPoints.length === 0) {
    return [];
  }
  return travelPoints.map((point) => formatCategory(point.arrival.category));
};

export const getIntelligentDestinationTimeArray = (
  travelPoints: TravelPoint[],
): string[] => {
  if (!travelPoints || travelPoints.length === 0) {
    return [];
  }
  //     DAYJS(point.arrival.dateTime).tz(point.arrival.timezone).format('h:mm a'),

  return travelPoints.map((point) =>
    DAYJS(point.arrival.dateTime).tz(point.arrival.timezone).format('h:mm a'),
  );
};

export const getIntelligentDepartureDates = (
  travelPoints: TravelPoint[],
): Date[] => {
  if (!travelPoints || travelPoints.length === 0) {
    return [];
  }
  return travelPoints.map((point) => point.departure.dateTime);
};

export const preLoadAllImages = (travelPoints: TravelFormData[]) => {
  const allDepartImages = travelPoints.map((point) => point.departure.images);
  const allArrivalImages = travelPoints.map((point) => point.arrival.images);
  const allSelectedTransportImages = travelPoints.map(
    (point) => point.selectedTransportImages,
  );
  const allImages = allDepartImages
    .concat(allArrivalImages)
    .concat(allSelectedTransportImages);
  const images = allImages.flat();
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
};
