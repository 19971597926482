import { FC } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import { TransportationDataInterface } from '..';
import {
  transportMap,
  transportMapInterface,
} from '~/components/P2PManual/helpers';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 300,
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

interface P2PTransportSelectorProps {
  open: boolean;
  onClose: () => void;
  onSave: (transport: transportMapInterface) => void;
  transportationData: TransportationDataInterface;
}

export const P2PTransportSelector: FC<P2PTransportSelectorProps> = ({
  transportationData,
  open,
  onClose,
  onSave,
}) => {
  const handleSelect = (transport: transportMapInterface) => {
    onSave(transport);
    onClose(); // Optionally close the dialog after selecting
  };

  return (
    <StyledDialog style={{ width: '100%' }} open={open} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: '#ff8447',
              fontFamily: 'Poppins, sans-serif',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '36px',
              margin: '10px',
            }}
          >
            Transportation:{' '}
          </Typography>
          {transportationData.icon && (
            <img
              src={transportationData.icon}
              style={{ width: 40, height: 40 }}
              alt=""
            />
          )}
        </Box>
      </DialogTitle>

      <DialogContent>
        <List>
          {transportMap.map((transport) => (
            <ListItemButton
              key={transport.label}
              sx={{ py: 0.2 }}
              onClick={() => handleSelect(transport)}
            >
              <ListItemIcon>
                <img
                  src={transport.icon}
                  style={{ width: 35, height: 35, margin: '10px' }}
                  alt=""
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '16px', margin: '10px' }}
                  >
                    {transport.label}
                  </Typography>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
    </StyledDialog>
  );
};
