import { motion } from 'framer-motion';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import CloseIcon from '@mui/icons-material/Close';
import './DetailsItem.css';
import { useState } from 'react';
import { AddToTripDialog, ITodoTravelPoint } from '~/components/AddToTrip';
import SignupDialog from '~/components/P2PAI/MapPreview/SignUpDialog';
import { isAuthenticated } from '~/components/ViewTravel/MobileFooter/BottomSheet';
import playIconNoOpacity from '~assets/playIcon-no-opacity.png';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import parse from 'html-react-parser';
import { getDefaultMarkerSvgString } from '~/utility/utils';

export const DetailsItem = ({
  content,
  close,
  isViewTravel,
  childTitle,
  point,
}: {
  content: any;
  close: () => void;
  isViewTravel?: boolean;
  childTitle?: string;
  point?: ITodoTravelPoint;
}) => {
  const isMobile = useIsMobile();
  const userId = useSelector((state: RootState) => state.MapReducers.userID);
  const tripUserId = useSelector(
    (state: RootState) => state.TripInfoReducers.travelRawData?.userId,
  );

  const [open, setOpen] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState<boolean>(false); // Tracks if the item is added
  const [showSignupDialog, setShowSignupDialog] = useState(false);

  const onClose = () => {
    setShowSignupDialog(false);
    if (isAuthenticated()) {
      onToggleOpen();
    }
  };

  const onToggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const initialAnimation = isMobile
    ? { x: -window.innerWidth }
    : { y: -window.innerHeight, x: '-50%' };
  const animation = isMobile ? { x: 0 } : { y: 0, x: '-50%' };
  const exit = isMobile
    ? { x: -window.innerWidth }
    : { y: -window.innerHeight, x: '-50%' };

  const handleAddToTrip = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (isAuthenticated()) {
      onToggleOpen();
    } else {
      setShowSignupDialog(true);
      return;
    }
  };

  return (
    <motion.div
      transition={{
        ease: 'easeInOut',
        duration: 0.4,
        type: 'spring',
        bounce: false,
      }}
      initial={initialAnimation}
      animate={animation}
      exit={exit}
      className={`details-item ${isMobile ? '' : 'desktop'}`}
      onClick={(e) => e.stopPropagation()}
    >
      <CloseIcon fontSize="medium" onClick={close} />
      <div className="details-item-content">
        <div className="details-item-header">
          {parse(getDefaultMarkerSvgString(isViewTravel ? point?.typeOfPoint : content.category))}
          <div className="details-item-name">
            {isViewTravel ? point?.value : content.name}
          </div>
        </div>
        <div
          className={`info-footer-add-trip-wrapper ${!isMobile && 'desktop'}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isViewTravel ? (
            <>
              {userId === tripUserId && isAuthenticated() && (
                <span
                  className="custom-badge info-footer-hide-show"
                  onClick={handleAddToTrip}
                  style={
                    isAdded
                      ? {
                          backgroundColor: '#fe7138',
                          color: '#fff',
                        }
                      : {}
                  }
                >
                  {isAdded ? 'Added' : 'Add to Trip'}
                </span>
              )}
            </>
          ) : (
            <span
              className="custom-badge info-footer-hide-show"
              onClick={handleAddToTrip}
              style={
                isAdded
                  ? {
                      backgroundColor: '#fe7138',
                      color: '#fff',
                    }
                  : {}
              }
            >
              {isAdded ? 'Added' : 'Add to Trip'}
            </span>
          )}
        </div>
        <div className="details-item-summary">
          “{isViewTravel ? point?.description : content.summary}”
        </div>
        <a
          className="custom-badge active details-item-badge"
          href={content.url_book_place}
          target="_blank"
          rel="noreferrer"
        >
          View Official Website
        </a>
        <div className="details-item-estimate">
          <div className="custom-badge estimate-badge">
            {isViewTravel ? point?.review : content.review} ⭐
          </div>
          {(!!content.price || !!point?.cost) && (
            <div className="custom-badge estimate-badge">
              ~US${isViewTravel ? point?.cost : content.price}
            </div>
          )}
        </div>
        <div className="details-item-image" onClick={close}>
          <img
            src={
              isViewTravel
                ? point?.tiktokData[0].thumbnail
                : content.media[0].thumbnail
            }
            alt=""
          />
          <img src={playIconNoOpacity} alt="" className="details-play-button" />
        </div>
        <div className="details-item-desc">
          {isViewTravel ? point?.longDescription : content.notes}
        </div>
        <a
          className="custom-badge badge-site"
          target="_blank"
          href={`https://google.com/maps/search/${
            isViewTravel ? point?.value : content.name
          }`}
          style={{ textDecoration: 'none' }}
          rel="noreferrer"
        >
          Search on Google
        </a>
      </div>

      <AddToTripDialog
        open={open}
        onClose={() => {
          setIsAdded(true);
          close();
          setOpen(false);
        }}
        content={content}
        point={point}
        isViewTravel={isViewTravel}
        title={childTitle}
      />

      <SignupDialog
        open={showSignupDialog}
        onClose={onClose}
        title="To Add To Trip..."
      />
    </motion.div>
  );
};
