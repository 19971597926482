import React from 'react';
import { Box, Button, TextField } from '@mui/material';

interface NumberInputProps {
  min: number; // Minimum allowed value
  max: number; // Maximum allowed value
  value: string;
  onSubmit: (duration: number) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({
  onSubmit,
  min,
  max,
  value,
  onChange,
}) => {
  const handleOkClick = () => {
    const numericValue = Number(value);

    // Validate the value when "OK" is clicked
    if (!isNaN(numericValue) && numericValue >= min && numericValue <= max) {
      onSubmit(numericValue); // Trigger the callback
    } else {
      alert(`Please enter a value between ${min} and ${max}.`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <TextField
        type="number"
        value={value}
        onChange={onChange}
        InputProps={{
          inputProps: {
            min: min,
            max: max,
          },
        }}
        helperText={`Allowed range: ${min} - ${max}`}
      />

      {/* Confirm Button */}
      <Button variant="contained" onClick={handleOkClick}>
        OK
      </Button>
    </Box>
  );
};

export default NumberInput;
