import { Signal } from "@preact/signals-core"
import React, { useEffect } from "react"

export default (signal: Signal, setState: React.Dispatch<any>, deps = [] as any[]) => {
    return useEffect(() => {
        const unsub = signal.subscribe(setState)
        return () => {
            unsub()
        }
    }, [])
}

