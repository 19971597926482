import { AnimationState } from '~/utility/models';
import * as THREE from 'three';
import { CharacterStateMachine } from '../CharacterStateMachine';
import { AnimationStates } from '~/animationEngine/utility/enums/AnimationStates';

export class AppearAnimation implements AnimationState {
  private action: THREE.AnimationAction;

  constructor(
    private mixer: THREE.AnimationMixer,
    clip: THREE.AnimationClip,
    private stateMachine: CharacterStateMachine,
  ) {
    this.action = this.mixer.clipAction(clip);
    this.onFinished = this.onFinished.bind(this);
  }

  onEnter(): void {
    console.log('Entering appear state');
    this.action.setLoop(THREE.LoopOnce, 1);
    this.action.clampWhenFinished = true;
    this.action.play();
    this.action.getMixer().addEventListener('finished', this.onFinished);
  }

  onExit(): void {
    console.log('Exiting appear state');
    this.action.setEffectiveWeight(1).fadeOut(0.5);
    this.action.getMixer().removeEventListener('finished', this.onFinished);
  }

  onUpdate(delta: number): void {
    this.mixer.update(delta);
  }

  private onFinished(event: any): void {
    if (event.action === this.action) {
      this.stateMachine.setState(AnimationStates.Walk);
      this.stateMachine.controller.initializeModelAnimation();
    }
  }
}
