import React, { useState } from 'react';
import './style.css';

import { motion, AnimatePresence } from 'framer-motion';
import { signal } from '@preact/signals-core';
import { useSignalEffect } from '@preact/signals-react/runtime';
import subscribeSignal from '~/hooks/subscribeSignal';

export const showMakeYourOwn = signal<'make' | 'generate' | false>(false);

const MakeYourOwnModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  // useSignalEffect(() => {
  //   setIsVisible(!!showMakeYourOwn.value);
  // });

  subscribeSignal(showMakeYourOwn, () => {
    setIsVisible(!!showMakeYourOwn.peek());
  });

  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="make-own-modal-container"
            onClick={() => (showMakeYourOwn.value = false)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ y: 300 }}
            animate={{ y: 0 }}
            exit={{ y: 300 }}
            transition={{ duration: 0.25, type: 'spring', bounce: 0.15 }}
            className="make-own-modal-view"
          >
            <span className="make-own-modal-header-text">
              {showMakeYourOwn.peek() === 'make'
                ? `To Make your Own...`
                : `To Generate a new Trip...`}
            </span>

            <motion.div
              className="make-own-modal-button"
              onClick={() => {
                window.location.assign('/signup');
              }}
              animate={{
                scale: 1.05,
              }}
              exit={{ scale: 1 }}
              initial={{ scale: 1 }}
              transition={{
                repeat: Infinity,
                repeatType: 'reverse',
                duration: 1,
                ease: 'easeInOut',
              }}
            >
              Sign Up for Free
            </motion.div>
            <div style={{ paddingTop: 16 }}>
              <span className="make-own-modal-bottomText">{`Already member?`}</span>
              <a className="make-own-modal-bottomText" href="/login">
                {' '}
                Log in
              </a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MakeYourOwnModal;
