import { ModelConfigProps } from '~/utility/models';

/**
 * An enumeration of various plane models.
 * Each member represents a specific plane type with a corresponding numeric value for potential use in calculations or comparisons.
 * The `EgyptPlane` value starts at 0 for potential use in specific scenarios.
 */
export enum PlaneModels {
  /** Represents a plane associated with Egypt, possibly for airline identification or regional filtering. */
  EgyptPlane = 'EgyptPlane',
  /** Represents a plane named Milad, potentially for a specific aircraft or fleet. */
  MiladPlane = 'MiladPlane',
  /** Represents a private jet, indicating a luxurious or exclusive aircraft. */
  PrivateJet = 'PrivateJet',
}

export const planeModelsConfiguration = new Map<PlaneModels, ModelConfigProps>([
  [
    PlaneModels.EgyptPlane,
    {
      name: 'egypt_plane.glb',
      path: './airbus318_colors-mono/egypt plane.glb',
      UIname: 'Default Plane',
    },
  ],
  [
    PlaneModels.MiladPlane,
    {
      name: 'milad_plane.glb',
      path: './airbus318_colors-mono/milad plane.glb',
      UIname: 'Classic Plane',
    },
  ],
  [
    PlaneModels.PrivateJet,
    {
      name: 'private_jet.glb',
      path: './airbus318_colors-mono/private_jet.glb',
      UIname: 'Private Jet',
    },
  ],
]);
