import { makeStyles } from '@mui/styles';
import type MuiTheme from '~/styles/theme/MuiTheme';

const useStyles = makeStyles((theme: typeof MuiTheme) => ({
    paper: {
        width: '100%',
        maxWidth: '600px',
      },
      container: {
        display: 'flex',
        overflow: 'auto',
        borderRadius: '8px',
        flexDirection: 'column',
        backgroundColor: 'white',
      },
      headingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },

}));

export default useStyles;
