import dayjs from 'dayjs';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';

export const getTransportIcon = (selectedTransport: TravelMode) => {
  let middleImgSrc = '';
  switch (selectedTransport) {
    case TravelMode.Plane:
    case 'Flight' as TravelMode:
      middleImgSrc = './icons/planeTransport-new.png';
      break;

    case TravelMode.Car:
    case 'Drive' as TravelMode:
      middleImgSrc = './icons/carTransport-new.png';
      break;

    case TravelMode.Transit:
      // Assuming there's an icon for Transit transport
      middleImgSrc = './icons/bestTransitTrain-new.png';
      break;

    case TravelMode.Walk:
      // Assuming there's an icon for walk transport
      middleImgSrc = './icons/walkTransport-new.png';
      break;

    case TravelMode.Ferry:
      middleImgSrc = './icons/ferryTransport-new.png';
      break;
  }
  return middleImgSrc;
};

export const getDurationString = ({
  departure,
  arrival,
}: {
  departure: string;
  arrival: string;
}) => {
  const duration = dayjs.duration(
    dayjs(String(arrival))?.diff(dayjs(String(departure))),
  );

  const durationString = `${duration.asDays() > 1 ? Math.floor(duration.asDays()) + 'd' : ''
    } ${duration.hours() > 0 ? duration.hours() + 'h' : ''} ${duration.minutes() > 0 ? duration.minutes() + 'm' : '0m'
    }`;

  return durationString;
};
