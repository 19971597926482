// React Imports
import React from 'react';

// MUI Imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

// Utility Imports
import { TravelFormHistoryData } from '~/utility/models';

// Icons Imports
import { Icon } from '@iconify/react';

// Style Imports
import useStyles from './styles';
import dayjs from 'dayjs';

interface HistoryCardProps {
  travelHistoryItem: TravelFormHistoryData;
  handleOpenTravel: (
    travelHistoryItem: TravelFormHistoryData,
    index: number,
  ) => void;
  handleDeleteTravel: (uuid: string, id: number) => void;
  index: number;
}

// Travel History Card Component on History Page
const HistoryCard = ({
  travelHistoryItem,
  handleOpenTravel,
  handleDeleteTravel,
  index,
}: HistoryCardProps) => {
  // Extracting Data from travelHistory array for History Card UI
  const departureDate: Date | null = travelHistoryItem.travelPoints?.[0]?.departure
    .dateTime
    ? new Date(travelHistoryItem.travelPoints?.[0]?.departure?.dateTime as Date)
    : null;
  const arrivalDate: Date | null = travelHistoryItem?.travelPoints[
    travelHistoryItem?.travelPoints?.length - 1
  ]?.arrival?.dateTime
    ? new Date(
      travelHistoryItem?.travelPoints[
        travelHistoryItem?.travelPoints?.length - 1
      ]?.arrival?.dateTime as Date,
    )
    : null;

  const departureCity: string | undefined = travelHistoryItem.travelPoints[0]
    .departure.location?.city
    ? travelHistoryItem?.travelPoints?.[0]?.departure?.location?.city
    : travelHistoryItem?.travelPoints?.[0]?.departure?.location?.country;

  const arrivalCity: string | undefined = travelHistoryItem.travelPoints[
    travelHistoryItem.travelPoints.length - 1
  ].arrival.location?.city
    ? travelHistoryItem.travelPoints[travelHistoryItem.travelPoints.length - 1]
      .arrival.location?.city
    : travelHistoryItem.travelPoints[travelHistoryItem.travelPoints.length - 1]
      .arrival.location?.country;

  const classes = useStyles();

  return (
    <Box className={classes.maincard} sx={{ boxShadow: 1 }}>
      <Grid sx={{ display: 'flex' }}>
        <Grid item xs={11}>
          <Typography className={classes.cardTitle}>
            {departureCity} - {arrivalCity}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button
            sx={{ width: '40px', minWidth: '40px', marginBottom: '5px' }}
            onClick={() =>
              handleDeleteTravel(travelHistoryItem.UUID, travelHistoryItem.id)
            }
          >
            <Icon
              icon="ic:round-delete"
              color="#fe7138"
              width={30}
              height={30}
            />
          </Button>
        </Grid>
      </Grid>
      <Grid sx={{ display: 'flex' }}>
        <Grid item xs={4.5}>
          <Typography variant="h6" className={classes.text}>
            {dayjs(departureDate)
              .tz(travelHistoryItem.travelPoints?.[0]?.departure.timezone)
              .format('MMMM D, YYYY')}
            <br />
            {dayjs(departureDate)
              .tz(travelHistoryItem.travelPoints?.[0]?.departure.timezone)
              .format('hh:mm A')}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'center', margin: 'auto' }}>
          <img
            src="/icons/clock2.svg"
            alt="clock"
            className={classes.clock2}
          ></img>
        </Grid>
        <Grid item xs={4.5} sx={{ textAlign: 'right' }}>
          <Typography variant="h6" className={classes.text}>
            {dayjs(arrivalDate)
              .tz(
                travelHistoryItem.travelPoints[
                  travelHistoryItem.travelPoints.length - 1
                ].arrival.timezone,
              )
              .format('MMMM D, YYYY')}
            <br />
            {dayjs(arrivalDate)
              .tz(
                travelHistoryItem.travelPoints[
                  travelHistoryItem.travelPoints.length - 1
                ].arrival.timezone,
              )
              .format('hh:mm A')}
          </Typography>
        </Grid>
      </Grid>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end', // Spread children evenly
          marginTop: '9px',
        }}
      >
        <Button
          className={classes.openButton}
          onClick={() => handleOpenTravel(travelHistoryItem, index)}
        >
          Open
        </Button>
      </div>
    </Box>
  );
};
export default HistoryCard;
