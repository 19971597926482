import { makeStyles } from '@mui/styles';
import type MuiTheme from '~/styles/theme/MuiTheme';

const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  mapsMainContainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    right: 0,
  },
  mapsMainContainerThreeQuarter: {
    position: 'fixed',
    width: '75%',
    height: '100%',
    overflow: 'hidden',
    right: 0,
  },
  mapsMainContainerHalf: {
    position: 'fixed',
    width: '50%',
    height: '100%',
    overflow: 'hidden',
    right: 0,
  },
  topRightFab: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    top: '10px', // Adjust the vertical position as needed
    right: '10px', // Adjust the horizontal position as needed
    width: '60px',
    height: '60px',

    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  watermark: {
    width: '153px',
    position: 'absolute',
    bottom: '4px',
    left: '8px',
    zIndex: '1',
  },
  watermarkFullscreen: {
    width: '153px',
    position: 'absolute',
    bottom: '10px',
    left: '8px',
    zIndex: '1',
  },
  loadingScreen: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)', // Adjust the background color and opacity
    zIndex: 9998, // Adjust the z-index as needed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
