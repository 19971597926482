import { BufferGeometry, CatmullRomCurve3, Mesh, Object3D } from 'three';
import { Map } from 'maplibre-gl';
import { generate3DArc, getScaleFromZoom } from './utility/utils';
import {
  AnimationController,
  PlaneAnimationConfig,
} from './AnimationController';
import { EasingFunctions } from './utility/easing';
import { unprojectFromWorld } from '~/CustomThreeJsWrapper/utility/utils';
import { AnimationTravelData } from '~/utility/models';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { Config } from './MultiTransportAnimationController';
import {
  canMultiTransportDeclareArrival,
  currentTravelTypeSignal,
  setAnimationState,
} from '~/components/ViewTravel/common';
import { handleAnimationState } from '~/components/ViewTravel/common';
import { mapOffset } from '~/components/ViewTravel/MobileFooter/BottomSheet';

/**
 * Class that controls the animation of a plane along a path on a map.
 * Inherits from the base AnimationController class.
 */
class PlaneAnimationController extends AnimationController {
  /**
   * @public
   * @defaultValue 10
   *
   * This variable, `planeGrowPercentage`, defines the percentage by which the plane (presumably a 3D plane object) will grow in size. The value is a number representing a percentage and should be between 0 and 100. A value of 10, as set by the default value, indicates a 10% increase in size.
   *
   * This value is typically used in conjunction with a function or logic that modifies the scale of the plane object. For example, a function might take the current scale of the plane, add this percentage to it, and then apply the new scale to the plane.
   *
   * You can adjust this value to control the growth rate of the plane. A higher value will result in a larger size increase, while a lower value will result in a smaller size increase.
   */
  planeGrowPercentage: number = 10;

  /**
   * @public
   *
   * This variable, `modelMaxScale`, represents the maximum scale that the plane object can reach. It's a number value that defines the upper limit for the plane's size.
   *
   * This variable is crucial for preventing the plane from growing infinitely or exceeding a desired size limit. It's often used in the same logic that utilizes `planeGrowPercentage`. When modifying the plane's scale, a check can be performed against `modelMaxScale` to ensure the new scale doesn't surpass this limit.
   *
   * The value of `modelMaxScale` should be chosen based on your specific needs and the intended size range of the plane object. You can set it to a large value to allow for significant growth, or a smaller value to restrict the plane's size.
   *
   * It's important to note that this variable is marked as non-null (`!`) using the TypeScript exclamation mark syntax. This indicates that the variable is guaranteed to have a value assigned to it before it's used. This helps prevent potential runtime errors that might occur if the variable is accessed before initialization.
   */
  modelMaxScale!: number;

  /**
   * Constructor for the base animation controller class. This class provides common functionalities
   * for animating vehicles or planes along a travel path.
   *
   * @param map A reference to the Maplibre map instance
   * @param index The index of the current travel segment
   * @param model A Three.js object representing the GLTF to be animated
   * @param tb A reference to the Three.js wrapper class
   */
  constructor(map: Map, index: number, tb: CustomThreeJSWrapper) {
    super();
    if (!map) return;
    this.map = map;
    this.tb = tb;
    this.index = index;

    this.pathGeometry = new BufferGeometry();
  }

  /**
   * Sets up the path geometry and mesh for the travel segment
   *
   * @param curveHeight The height of the curved path
   */
  setupPath(curveHeight: number): void {
    /**
     * Generates a 3D arc geometry based on the travel segment's decoded path
     *
     * @param path An array of coordinates representing the decoded path
     * @param curveHeight The height of the curved path
     * @param tb A reference to the Three.js wrapper class
     * @returns An object containing the generated path mesh, path curve, and material
     */
    const pathMesh = generate3DArc(
      this.travelSegment.decodedPath.path,
      curveHeight,
      this.tb,
    );

    /**
     * Stores a reference to the Catmull-Rom curve representing the 3D path
     */
    this.pathCurve = pathMesh.pathCurve as CatmullRomCurve3;

    /**
     * Stores a reference to the material used for rendering the path mesh
     */
    this.material = pathMesh?.material;

    /**
     * Stores a reference to the underlying buffer geometry of the path mesh
     */
    this.pathGeometry = pathMesh?.pathGeometry as BufferGeometry;

    /**
     * Creates a new Three.js mesh object using the generated path geometry and material
     */
    this.pathMesh = new Mesh(this.pathGeometry, this.material);

    /**
     * Adds the path mesh to the Three.js scene using the provided wrapper
     */
    this.tb.add(this.pathMesh);
  }

  /**
   * Starts the plane animation along the travel path.
   *
   * @param animationConfig
   * Configuration object for the plane animation
   * (specific to PlaneAnimationController)
   *
   * @remarks
   * This function overrides the base class's `startAnimation` method.
   * It performs plane-specific setup tasks in addition to the common
   * animation setup.
   */
  setupAnimation(animationConfig: PlaneAnimationConfig) {
    this.animationConfig = animationConfig;
    /**
     * Sets up the path for the plane animation based on the provided curve height.
     * This involves calculating the flight path considering the curvature of the earth.
     */
    this.setupPath(animationConfig.curveHeight);

    this.setupModelForAnimation();

    /**
     * Sets up the animation timing based on the travel segment data and animation configuration.
     * This involves calculating the animation duration and speed based on factors
     * like travel distance and desired animation speed.
     */
    this.setupAnimationTime(this.travelSegment, animationConfig);

    /**
     * Calculates the maximum scale for the plane model based on the current camera zoom
     * and the model scale specified in the travel segment configuration.
     */
    this.modelMaxScale =
      getScaleFromZoom(this.cameraZoom) *
      this.travelSegment.travelSegmentConfig.modelScale;

    this.initializeModelAnimation();
  }

  /**
   * Gets the zoom level at which the animation started
   *
   * @returns The zoom level of the map camera when the animation began
   */
  getAnimationStartZoom(): number {
    return this.cameraZoom;
  }

  /**
   * Clears any references to the path curve, potentially helping with memory management.
   * This function is called when the animation is no longer needed to avoid holding
   * onto unnecessary data.
   */
  clean() {
    this.pathCurve = undefined;
  }

  /**
   * Adjusts the animation duration based on the travel segment's animation speed configuration.
   *
   * This function modifies the provided `animationConfig` object by scaling its `duration` property.
   * The scaling factor is derived from the `animationSpeed` property within the `travelSegment.travelSegmentConfig` object.
   * Essentially, a higher `animationSpeed` value results in a shorter animation duration.
   *
   * @param travelSegment An object containing travel data for a specific segment
   * @param animationConfig An animation configuration object to be modified
   */
  setupAnimationTime(
    travelSegment: Config | AnimationTravelData,
    animationConfig: PlaneAnimationConfig,
  ): void {
    animationConfig.duration /=
      travelSegment.travelSegmentConfig.animationSpeed * 2;
  }

  /**
   * Animates the travel path along the map over a specified duration.
   *
   * This function continuously updates the position of the travel model (e.g., car or plane)
   * based on the animation progress. It also adjusts the map view to follow the animation
   * and updates the model's scale dynamically. The animation loop continues until the animation
   * duration is complete, at which point the `onAnimationCompleteCallback` (if provided) is invoked.
   *
   * @param delta The time elapsed since the last animation frame (in milliseconds)
   */
  update(delta?: number): void {
    if (!this.isAnimationExpired) {
      // Get current time and elapsed time
      const now = performance.now();

      this.timeElapsed = now - this.animationStartTime;

      // Check if animation is still ongoing
      // Calculate animation progress (0 to 1)
      let timeProgress =
        this.timeElapsed /
        (this.animationConfig as PlaneAnimationConfig).duration;

      if (timeProgress < 1) {
        // Apply easing function to time progress
        timeProgress = EasingFunctions.easeInOutCubic(timeProgress);

        // Get the model's position on the path curve based on time progress
        let position = (this.pathCurve as CatmullRomCurve3).getPointAt(
          timeProgress,
        );
        (this.model as Object3D).position.set(
          position.x,
          position.y,
          position.z,
        );

        // Calculate position for the next frame to update map view
        let timeProgressForNextPoint =
          timeProgress + 0.01 > 1 ? timeProgress : timeProgress + 0.01;
        const modelLngLatPos = unprojectFromWorld(position);

        let nextPosition = (this.pathCurve as CatmullRomCurve3).getPointAt(
          timeProgressForNextPoint,
        );

        setAnimationState(timeProgress);
        currentTravelTypeSignal.value = 'plane';
        canMultiTransportDeclareArrival.value = true;
        // Animate the map view to follow the model's movement
        this.map.flyTo({
          center: modelLngLatPos,
          essential: true,
          bearing: this.mapBearing,
          pitch: this.mapPitch,
          zoom: this.cameraZoom,
          easing: EasingFunctions.linear,
          duration: 100,
          offset: mapOffset.value,
        });

        // Update model's rotation to look at the next point on the path
        const worldPos = (this.pathMesh as Mesh).localToWorld(nextPosition);
        (this.model as Object3D).lookAt(worldPos);

        // Update the visible portion of the path geometry based on animation progress
        this.pathGeometry.setDrawRange(
          0,
          timeProgress * this.pathGeometry.index!.count,
        );

        // Calculate dynamic model scale based on animation progress
        const minScale = 0;
        const maxScale = this.modelMaxScale;

        const planeGrowPrecentage = this.planeGrowPercentage / 100;
        const planeShrinkPrecentage = (100 - this.planeGrowPercentage) / 100;

        let currentScale: number;
        if (timeProgress <= planeGrowPrecentage) {
          // Smoothly grow to maxScale during the first 30%
          currentScale =
            minScale +
            (maxScale - minScale) * (timeProgress / planeGrowPrecentage);
        } else if (timeProgress <= planeShrinkPrecentage) {
          // Maintain maxScale between 30% and 70%
          currentScale = maxScale;
        } else {
          // Smoothly shrink back to minScale from 70% to the end
          currentScale =
            maxScale -
            (maxScale - minScale) *
            ((timeProgress - planeShrinkPrecentage) / planeGrowPrecentage);
        }

        currentScale *= this.travelSegment.travelSegmentConfig.modelScale;

        // Apply the calculated scale to the model
        (this.model as Object3D).scale.set(
          currentScale,
          currentScale,
          currentScale,
        );
      } else {
        // Mark animation as expired and call completion callback
        this.isAnimationExpired = true;
        this.onAnimationCompleteCallback();
      }
    }
  }
}

export { PlaneAnimationController };
