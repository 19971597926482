// React Imports
import React from 'react';

// Style Imports
import useStyles from '../styles';

// Redux Imports
import { useSelector } from '~/redux/reducers';

// MUI Imports
import { Box, Fab, Modal } from '@mui/material';

// Icon Imports
import { Icon } from '@iconify/react';

// Child Component Imports
import MapView from '~/map/MapView';
import { RootState } from '~/redux/store';

interface FullScreenModalProps {
  onClose: () => void;
}

/**
 * This is a FullScreenModal Component and a Child Component of VideoPopup
 * @returns {JSX.Element} Returns CFullScreenModal component
 */
const FullScreenModal: React.FC<FullScreenModalProps> = ({ onClose }) => {
  const classes = useStyles();
  const fullscreenMode: boolean = useSelector(
    (state: RootState) => state.MapReducers.fullscreenMode,
  );

  return (
    <Modal open={fullscreenMode} onClose={onClose}>
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
        }}
      >
        <MapView isVideoPopupMap={false} />
        <Fab
          className={classes.topRightFab}
          color="primary"
          aria-label="Add"
          onClick={() => onClose()}
        >
          <Icon
            icon="tdesign:fullscreen-exit"
            color="black"
            width="40"
            height="40"
          />
        </Fab>
      </Box>
    </Modal>
  );
};

export default FullScreenModal;
