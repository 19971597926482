import React, { useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import useStyles from './styles';
import { useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';

interface CustomizeMapProps {
  handleClose: () => void;
}

/**
 * CustomizeMap component - Allows users to select a map style.
 *
 * @component
 * @param {CustomizeMapProps} props - Props for the component
 * @returns {React.ReactElement} - Rendered component
 */
const CustomizeMap: React.FC<CustomizeMapProps> = ({
  handleClose,
}: CustomizeMapProps): React.ReactElement => {
  const [selectedImage, setSelectedImage] = useState<string>('');
  const classes = useStyles();
  const dispatch = useDispatch();

  const imageList = [
    { src: 'icons/basic.png', alt: 'mapnew1' },
    { src: 'icons/osmBright.png', alt: 'mapnew2' },
    { src: 'icons/whiteMap.png', alt: 'map2' },
    { src: 'icons/darkMap.png', alt: 'map3' },
    { src: 'icons/raster.png', alt: 'map4' },
    { src: 'icons/satellite.png', alt: 'map1' },
  ];

  /**
   * Handles image click event to select a map style.
   *
   * @param {string} imageName - The name of the selected image
   * @param {number} index - The index of the selected image
   */
  const handleImageClick = (imageName: string, index: number) => {
    setSelectedImage(imageName);
    dispatch(ActionsCreator.setMapStyleIndex(index));
  };

  return (
    <Box className={classes.boxStyle}>
      <Grid className={classes.mainGrid}>
        <img
          className={classes.img}
          src="icons/cross.svg"
          alt="cancel"
          onClick={handleClose}
        />
        <Typography variant="h4" className={classes.heading}>
          Customize Map
        </Typography>
        <div className={classes.imageGrid}>
          {imageList.map((image, index) => (
            <img
              key={index}
              src={image.src}
              alt={image.alt}
              onClick={() => handleImageClick(image.alt, index)}
              className={`${
                selectedImage === image.alt ? classes.selected : ''
              } ${classes.mapType} ${classes.imageGap}`}
              style={{ cursor: 'pointer' }}
            />
          ))}
        </div>
      </Grid>
    </Box>
  );
};

export default CustomizeMap;
