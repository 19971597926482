import { useEffect, useRef, useState } from 'react';
import { Swiper as SwiperType } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { pointsSignal } from '../PointsHeader';
import { Arrival } from '../../StatsOverlay';
import OverviewContainer from './OverviewContainer';
import subscribeSignal from '~/hooks/subscribeSignal';
import { useDispatch } from '~/redux/store';
import { useSelector } from '~/redux/reducers';
import {
  fireSetBottomSheetOverviewData,
  selectBottomSheetOverviewData,
} from '~/redux/reducers/ui.slice';

export interface OverviewData {
  name: string;
  images: string[];
  media?: AttatchedMedia[];
  tiktokData?: Pick<AttatchedMedia, 'thumbnail' | 'url'>[];
  description: string;
  location?: any;
  index: number;
  street?: string;
  id?: string;
  icon?: string;
  duration?: string;
  staypointDuration?: string;
  time?: string | Date;
  price?: string | number;
  point?: Arrival;
  nextPoint?: Arrival;
  prevPoint?: Arrival;
  type?: string;
  travelIndex?: number;
  cost?: number;
  review?: number;
  longDescription?: string;
  arrivalTimeZone?: string;
  departureTimeZone?: string;
  arrivalDateTime?: Date;
  departureDateTime?: Date;
  prevArrivalDateTime?: Date;
  prevArrivalTimeZone?: string;
}

export interface AttatchedMedia {
  url: string;
  type: 'image' | 'video';
  credits: string;
  thumbnail: string;
}

const OverviewDataComponent = () => {
  const [points, setPoints] = useState<OverviewData[]>([]);
  const swiperRef = useRef<SwiperType>();

  const dispatch = useDispatch();
  const bottomSheetOverviewData = useSelector(selectBottomSheetOverviewData);

  subscribeSignal(pointsSignal, setPoints);

  useEffect(() => {
    if (bottomSheetOverviewData?.index !== undefined) {
      swiperRef.current?.slideTo(bottomSheetOverviewData.index);
    }
  }, [bottomSheetOverviewData]);

  return (
    <>
      <Swiper
        //@ts-ignore
        onSwiper={(ref) => (swiperRef.current = ref)}
        slidesPerView="auto"
        spaceBetween={10}
        slidesPerGroup={1}
        centeredSlides
        longSwipesRatio={1}
        onSlideChangeTransitionEnd={(swiper: SwiperType) => {
          if (bottomSheetOverviewData?.index !== swiper.activeIndex) {
            dispatch(
              fireSetBottomSheetOverviewData(points[swiper.activeIndex]),
            );
          }
        }}
      >
        {points.map((i, idx) => {
          const extraPoints = {
            nextPoint: points[idx + 1]?.point || i?.nextPoint,
            prevPoint: points[idx - 1]?.point,
          };
          return (
            <SwiperSlide key={i.id + idx.toString()}>
              {(swiperRef.current?.activeIndex || 0) + 3 > idx &&
                (swiperRef.current?.activeIndex || 0) - 3 < idx && (
                  <OverviewContainer data={{ ...i, ...extraPoints }} />
                )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default OverviewDataComponent;
