import React from 'react';

export const LocationCity = ({ city }: { city: string }) => {
  return (
    <div className="city-item">
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8.5"
          cy="7.33333"
          r="2"
          stroke="#211818"
          strokeWidth="1.5"
        />
        <path
          d="M14.5 7.25925C14.5 10.5321 10.75 14.6667 8.5 14.6667C6.25 14.6667 2.5 10.5321 2.5 7.25925C2.5 3.98646 5.18629 1.33333 8.5 1.33333C11.8137 1.33333 14.5 3.98646 14.5 7.25925Z"
          stroke="#211818"
          strokeWidth="1.5"
        />
      </svg>
      <span className="city-text">{city}</span>
    </div>
  );
};
