import React, { useEffect } from 'react';

declare global {
  interface Window {
    LiveAgent: any; // or you can specify the exact type if you know it
  }
}

export const LiveAgentChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.id = 'la_x2s6df8d';
    script.src = 'https://vizualtravel.ladesk.com/scripts/track.js';

    script.onload = function () {
      // Ensure the LiveAgent object is available before using it
      if (window.LiveAgent) {
        window.LiveAgent.createButton('pgg2edgv', script);
      }
    };

    // Append the script to the document
    document.body.appendChild(script);

    // Clean up the script if the component is unmounted
    return () => {
      // document.body.removeChild(script);
    };
  }, []); // Empty dependency array to run only once when the component mounts

  return null; // This component does not render anything visually
};
