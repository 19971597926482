import React, { useEffect, useRef, useState } from 'react';
import {
  List,
  ListItemText,
  ListItemButton,
  Typography,
  InputAdornment,
  Box,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useDispatch } from 'react-redux';

import { StyledTextField } from '../P2PManualTitleModal';

import { CityInterface, findCities } from '~/supabase/php2EditablePlaces';

import ActionsCreator from '~/redux/actions';

interface SearchResultRowProps {
  city: CityInterface;
  handleSelect: (city: CityInterface) => void;
}
const SearchResultRow = ({
  city,
  handleSelect,
}: SearchResultRowProps): JSX.Element => {
  return (
    <ListItemButton
      sx={{ paddingLeft: '5px' }}
      onClick={() => {
        handleSelect(city);
      }}
    >
      <ListItemText
        primary={
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontWeight: 700,
            }}
          >
            {city.city}
          </Typography>
        }
        secondary={
          <>
            <Typography variant="body2">{city.country}</Typography>
          </>
        }
      />
    </ListItemButton>
  );
};

export const P2PCityAutocomplete = ({
  onClose,
  onSelect,
}: {
  onClose: () => void;
  onSelect: (city: CityInterface) => void;
}) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [cities, setCities] = useState<CityInterface[]>([]);

  const [open, setOpen] = useState(false);

  const currentDebounceValueRef = useRef<number>(500);
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    dispatch(ActionsCreator.setMapButtonState(false));
  }, [dispatch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQuery(query);
    }, currentDebounceValueRef.current);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [query]);

  useEffect(() => {
    const fetchCities = async (query: string) => {
      const cities = await findCities(query);
      setCities(cities);
    };

    if (debouncedQuery) {
      fetchCities(debouncedQuery);
      setOpen(true);
    } else {
      setCities([]);
      setOpen(false);
    }
  }, [debouncedQuery]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setQuery(input);
  };

  return (
    <Box
      className="p2p-city-autocomplete"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 99999999,
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: '500px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '0 20px',
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '20px 0',
          }}
        >
          <div onClick={onClose}>
            <img
              src="/closeSquare.svg"
              alt="Close Icon"
              style={{ width: 40, height: 40, cursor: 'pointer' }}
            />
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            Enter a City
          </Typography>
          <StyledTextField
            sx={{
              width: '100%',
              margin: '0 auto',
            }}
            autoFocus
            placeholder="Search for a Point"
            value={query}
            onChange={handleChange}
            // onFocusCapture={() => {
            //   if (!isFirstPoint) {
            //     dispatch(ActionsCreator.setHeaderState(false));
            //   }
            // }}
            fullWidth
            inputRef={textFieldRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    icon="gg:search"
                    width="30"
                    height="30"
                    style={{ color: '#979797' }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {open && cities.length > 0 && (
          <List>
            {cities.map((city, index) => {
              return (
                <SearchResultRow
                  key={city.id}
                  city={city}
                  handleSelect={onSelect}
                />
              );
            })}
          </List>
        )}
      </Box>
    </Box>
  );
};
