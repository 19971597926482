import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import { TravelFormData, FormData } from '~/utility/models';
import { addRouteData } from '~/utility/utils';
import ActionsCreator from '~/redux/actions';
import { AppDispatch } from '~/redux/store';

/**
 * Handles the mouse enter event for a given transport type.
 * @param {TravelMode} transportType - The type of transport.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsPlaneHovered - Setter function for the plane hover state.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsCarHovered - Setter function for the car hover state.
 */
export const handleMouseEnter = (
  transportType: TravelMode,
  setIsPlaneHovered: React.Dispatch<React.SetStateAction<boolean>>,
  setIsCarHovered: React.Dispatch<React.SetStateAction<boolean>>,
  setIsTransitHovered: React.Dispatch<React.SetStateAction<boolean>>,
  setIsWalkHovered: React.Dispatch<React.SetStateAction<boolean>>,
  setIsFerryHovered: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  switch (transportType) {
    case TravelMode.Plane:
      setIsPlaneHovered(true);
      setIsCarHovered(false);
      setIsTransitHovered(false);
      setIsFerryHovered(false);
      setIsWalkHovered(false);
      break;

    case TravelMode.Car:
      setIsCarHovered(true);
      setIsPlaneHovered(false);
      setIsTransitHovered(false);
      setIsFerryHovered(false);
      setIsWalkHovered(false);
      break;

    case TravelMode.Transit:
      setIsTransitHovered(true);
      setIsPlaneHovered(false);
      setIsCarHovered(false);
      setIsFerryHovered(false);
      setIsWalkHovered(false);
      break;

    case TravelMode.Walk:
      setIsWalkHovered(true);
      setIsTransitHovered(false);
      setIsPlaneHovered(false);
      setIsCarHovered(false);
      setIsFerryHovered(false);
      break;

    case TravelMode.Ferry:
      setIsTransitHovered(false);
      setIsPlaneHovered(false);
      setIsCarHovered(false);
      setIsFerryHovered(true);
      setIsWalkHovered(false);
      break;

    default:
      setIsPlaneHovered(false);
      setIsCarHovered(false);
      setIsTransitHovered(false);
      setIsWalkHovered(false);
      setIsFerryHovered(false);
      break;
  }
};

/**
 * Handles the mouse leave event for a given transport type.
 * @param {TravelMode} transportType - The type of transport.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsPlaneHovered - Setter function for the plane hover state.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsCarHovered - Setter function for the car hover state.
 */
export const handleMouseLeave = (
  transportType: TravelMode,
  setIsPlaneHovered: React.Dispatch<React.SetStateAction<boolean>>,
  setIsCarHovered: React.Dispatch<React.SetStateAction<boolean>>,
  setIsTransitHovered: React.Dispatch<React.SetStateAction<boolean>>,

  setIsWalkHovered: React.Dispatch<React.SetStateAction<boolean>>,
  setIsFerryHovered: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  switch (transportType) {
    case TravelMode.Plane:
      setIsPlaneHovered(false);
      break;

    case TravelMode.Car:
      setIsCarHovered(false);
      break;

    case TravelMode.Transit:
      setIsTransitHovered(false);
      break;

    case TravelMode.Walk:
      setIsWalkHovered(false);
      break;

    case TravelMode.Ferry:
      setIsFerryHovered(false);
      break;
  }
};

/**
 * Asynchronously updates the travel points array with new data.
 * @param {FormData} departureFormData - The data for departure form.
 * @param {FormData} arrivalFormData - The data for arrival form.
 * @param {string} selectedTransport - The selected mode of transport.
 * @param {TravelFormData[]} travelPoints - The current array of travel points.
 * @param {React.Dispatch<React.SetStateAction<TravelFormData[]>>} setTravelPoints - The function to update the travel points state.
 * @param {React.MutableRefObject<TravelFormData[]>} travelObj - The mutable ref object for travel points.
 * @param {AppDispatch} dispatch - The dispatch function from Redux.
 * @param {boolean} isSave - A boolean indicating whether to save the data.
 * @param {SetStateAction<string>} setErrorMessage - The function to update the error message state.
 * @returns {Promise<boolean>} A promise that resolves to true if the operation is successful, false otherwise.
 */
export async function updateTravelPointsArray(
  departureFormData: FormData,
  arrivalFormData: FormData,
  selectedTransport: string | null,
  travelPoints: TravelFormData[],
  setTravelPoints: React.Dispatch<React.SetStateAction<TravelFormData[]>>,
  travelObj: React.MutableRefObject<TravelFormData[]>,
  dispatch: AppDispatch,
  isSave: boolean,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  selectedTransportImages: string[] | [],
) {
  const isAnyFieldNull =
    Object.values(departureFormData).some(
      (value) => value === null || value === '',
    ) ||
    Object.values(arrivalFormData).some(
      (value) => value === null || value === '',
    ) ||
    !selectedTransport;

  if (isAnyFieldNull) {
    setErrorMessage('Please fill all the fields before proceeding further');
    dispatch(ActionsCreator.setSnackbarOpen(true)); // Show the Snackbar
    return false; // Exit the function if any field is null
  }

  const isDepartureAndArrivalSame =
    departureFormData.location?.placeId === arrivalFormData.location?.placeId;
  if (isDepartureAndArrivalSame) {
    setErrorMessage('Departure and arrival locations should not be the same');
    dispatch(ActionsCreator.setSnackbarOpen(true)); // Show the Snackbar
    return false; // Exit the function if any field is null
  }

  let newTravelObj = {
    arrival: arrivalFormData,
    departure: departureFormData,
    selectedTransport: selectedTransport,
    encodedPath: {
      data: [],
      path: '',
    },
    selectedTransportImages,
  } as TravelFormData;

  newTravelObj = await addRouteData(newTravelObj);
  const updatedTravelPoints = [...travelPoints, newTravelObj];

  travelObj.current = updatedTravelPoints;
  setTravelPoints(updatedTravelPoints);

  dispatch(
    isSave
      ? ActionsCreator.saveTravelPoint(updatedTravelPoints as TravelFormData[])
      : ActionsCreator.addTravelPoint(updatedTravelPoints as TravelFormData[]),
  );

  return true;
}

/**
 * Asynchronously updates the travel points array with new data for multiple departures and arrivals.
 * @param {FormData[]} departureFormDataArray - The array of departure form data.
 * @param {FormData[]} arrivalFormDataArray - The array of arrival form data.
 * @param {string[]} selectedTransportArray - The array of selected modes of transport.
 * @param {TravelFormData[]} travelPoints - The current array of travel points.
 * @param {React.Dispatch<React.SetStateAction<TravelFormData[]>>} setTravelPoints - The function to update the travel points state.
 * @param {React.MutableRefObject<TravelFormData[]>} travelObj - The mutable ref object for travel points.
 * @param {AppDispatch} dispatch - The dispatch function from Redux.
 * @param {boolean} isSave - A boolean indicating whether to save the data.
 * @param {React.Dispatch<React.SetStateAction<string>>} setErrorMessage - The function to update the error message state.
 * @param {string[][]} selectedTransportImagesArray - The array of transport images for each transport.
 * @returns {Promise<boolean>} A promise that resolves to true if the operation is successful, false otherwise.
 */
export async function saveAndPublishTravelPointsArrayInMultiple(
  departureFormDataArray: FormData[],
  arrivalFormDataArray: FormData[],
  selectedTransportArray: string[],
  travelPoints: TravelFormData[],
  setTravelPoints: React.Dispatch<React.SetStateAction<TravelFormData[]>>,
  travelObj: React.MutableRefObject<TravelFormData[]>,
  dispatch: AppDispatch,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  selectedTransportImagesArray: string[][],
) {
  for (let i = 0; i < departureFormDataArray.length; i++) {
    const departureFormData = departureFormDataArray[i];
    const arrivalFormData = arrivalFormDataArray[i];
    const selectedTransport = selectedTransportArray[i];
    const selectedTransportImages = selectedTransportImagesArray[i];

    const isAnyFieldNull =
      Object.values(departureFormData).some(
        (value) => value === null || value === '',
      ) ||
      Object.values(arrivalFormData).some(
        (value) => value === null || value === '',
      ) ||
      !selectedTransport;

    if (isAnyFieldNull) {
      setErrorMessage('Please fill all the fields before proceeding further');
      dispatch(ActionsCreator.setSnackbarOpen(true)); // Show the Snackbar
      return false; // Exit the function if any field is null
    }

    const isDepartureAndArrivalSame =
      departureFormData.location?.placeId === arrivalFormData.location?.placeId;
    if (isDepartureAndArrivalSame) {
      setErrorMessage('Departure and arrival locations should not be the same');
      dispatch(ActionsCreator.setSnackbarOpen(true)); // Show the Snackbar
      return false; // Exit the function if any field is null
    }

    let newTravelObj = {
      arrival: arrivalFormData,
      departure: departureFormData,
      selectedTransport: selectedTransport,
      encodedPath: {
        data: [],
        path: '',
      },
      selectedTransportImages,
    } as TravelFormData;

    console.log('Travel Object:', newTravelObj);

    newTravelObj = await addRouteData(newTravelObj);
    travelPoints.push(newTravelObj);
  }

  travelObj.current = travelPoints;
  setTravelPoints(travelPoints);

  dispatch(
    ActionsCreator.saveTravelPointAndPublish(travelPoints as TravelFormData[]),
  );

  return true;
}
