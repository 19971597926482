import React from 'react';

import SignupForm from './SignupForm';
/**
 * Component for user signup.
 * @component
 * @returns {JSX.Element} Signup Component
 */

function Signup(): JSX.Element {
  return <SignupForm open={true} onClose={() => {}} isSignupPage />;
}

export default Signup;
