import svg64 from 'svg64';
import {
  getIconColor,
  isUniqueById,
  determineMarkerType,
  getDefaultMarkerSvgString,
} from '~/utility/utils';

interface CustomIconProps {
  index: number;
  category: string;
}

// old fn , used at some pages
export const getCustomIcon = ({ index, category }: CustomIconProps) => {
  const iconColor = getIconColor(category);
  const icon = `<svg width="55px" height="57px" viewBox="27 20 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <style>
        .text-number {
          font-family: Poppins, sans-serif;
          font-size: 20px;
          fill: white;
          font-weight: bold;
          text-align:center;
        }
    </style>

<g filter="url(#shadowFilterIcon)">

<path d="M40 23C35.5817 23 32 26.5817 32 31V49C32 53.4183 35.5817 57 40 57H43.7245C44.5616 57 45.3916 57.2529 45.9366 57.8882C46.2191 58.2171 46.7348 58.8652 47.0753 59.293C47.1323 59.3648 47.1845 59.4303 47.2298 59.4871C47.26 59.525 47.2935 59.5673 47.3297 59.6129C47.603 59.9569 48.0256 60.4888 48.2693 60.7627C48.6167 61.1529 49.0461 61.5676 49.6424 61.5676C50.2388 61.5676 50.668 61.1529 51.0152 60.7631C51.2542 60.4946 51.6653 59.9774 51.939 59.6332C51.9813 59.58 52.0203 59.5309 52.0549 59.4875C52.0948 59.4374 52.14 59.3805 52.1893 59.3186C52.529 58.8914 53.0596 58.224 53.3478 57.8886C53.8938 57.2534 54.7239 57 55.5615 57H58C62.4183 57 66 53.4183 66 49V31C66 26.5817 62.4183 23 58 23H40Z" fill="${iconColor}"/>

<path d="M45.1777 58.5394L45.178 58.5397C45.4451 58.8507 45.9462 59.4801 46.2928 59.9157L46.4474 60.1099L46.4479 60.1106L46.5468 60.2349L47.3087 59.6296L46.5468 60.2349L46.5605 60.2522C46.8264 60.5869 47.2598 61.1325 47.5222 61.4274L47.5224 61.4276C47.8597 61.8065 48.5576 62.5676 49.6424 62.5676C50.7273 62.5676 51.4251 61.8064 51.7619 61.4282L51.7621 61.428C52.0199 61.1384 52.4423 60.607 52.7082 60.2726L52.7218 60.2555L52.8369 60.1109L52.8372 60.1105L52.972 59.9409L52.1893 59.3186L52.972 59.9409C53.318 59.5059 53.8337 58.8576 54.1062 58.5404C54.3992 58.1995 54.9025 58 55.5615 58H58C62.9706 58 67 53.9706 67 49V31C67 26.0294 62.9706 22 58 22H40C35.0294 22 31 26.0294 31 31V49C31 53.9706 35.0294 58 40 58H43.7245C44.3836 58 44.886 58.1993 45.1777 58.5394Z" stroke="white" stroke-width="2"/>

</g>

<text class="text-number" x="50" y="43" text-anchor="middle" alignment-baseline="middle">${index}</text>

<defs>
<filter id="shadowFilterIcon" x="-20%" y="-20%" width="140%" height="140%">
  <feDropShadow dx="0" dy="3" stdDeviation="3" flood-color="rgba(0, 0, 0, 0.25)" />
</filter>
</defs>
</svg>`;
  return svg64(icon);
};

interface CustomMarkerIcon {
  category: string;
  indexOfMarker: number;
  imageLink: string | undefined;
  travelArrayIds: string[];
  isP2PTravelPoint?: boolean;
  isNoNumberBadge?: boolean;
}

export function getMarkerIcon({
  category,
  indexOfMarker,
  travelArrayIds,
  imageLink,
  isP2PTravelPoint = false,
  isNoNumberBadge = false,
}: CustomMarkerIcon): string {
  /* Utils variables:  */
  const markerType = determineMarkerType(category);
  const currentID = travelArrayIds[indexOfMarker];
  const characterValue = isUniqueById(travelArrayIds, currentID)
    ? indexOfMarker + 1
    : '★';
  const hasFutureDuplicates = travelArrayIds
    .slice(indexOfMarker + 1)
    .includes(currentID);
  if (!currentID) {
    return '';
  }

  // if we have duplicate same place in future function calls - we render last
  if (hasFutureDuplicates) {
    return '';
  }

  /* Custom badge:  */
  const svgBadge = svg64(`
    <svg
      width="30"
      height="30"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="FF9345" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="0%" stop-color="#FF9345" stop-opacity="1" />
            <stop offset="50%" stop-color="#FE7138" stop-opacity="1" />
            <stop offset="100%" stop-color="#F25D21" stop-opacity="1" />
        </linearGradient>
      </defs>

      <circle
        cx="50"
        cy="50"
        r="45"
        fill="url(#FF9345)"
      />

      <text
        x="50%"
        y="55%"
        text-anchor="middle"
        alignment-baseline="middle"
        font-size="50"
        font-weight="bold"
        style="
        font-family: Poppins, sans-serif;
        line-height: 15.4px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;"
        fill="white"
      >
        ${characterValue}
      </text>
    </svg>
  `);
  /* Custom thumbnail:  */
  const image = imageLink;

  /* Default icon:  */
  const defaultIcon = getDefaultMarkerSvgString(markerType);

  /* Callback values */
  const svgMask = (image: string) =>
    `<svg width="55px" height="57px" viewBox="27 20 45 45" id="svgThumbnail${indexOfMarker}" fill="none" overflow="visible"; xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
  <defs>
    <filter id="shadowFilter${indexOfMarker}" x="-20%" y="-20%" width="140%" height="140%">
      <feDropShadow dx="0" dy="3" stdDeviation="3" flood-color="rgba(0, 0, 0, 0.25)" />
    </filter>
    <pattern id="pattern0_2_4066${indexOfMarker}" patternContentUnits="objectBoundingBox" width="1" height="1">
      <use xlink:href="#imageThumbnail${indexOfMarker}" transform="translate(-0.7, -0.35) scale(0.0015)"></use>
    </pattern>
    <image 
      id="imageThumbnail${indexOfMarker}" 
      href="${image}" 
      alt=""
      data-category="${determineMarkerType(category)}" 
      width="1600" 
      height="1200" 
      onError="this.setAttribute('href', '/icons/if_error/' + this.getAttribute('data-category') + '.svg')">
      style="image-rendering: pixelated;">
    </image>
  </defs>
  <g filter="url(#shadowFilter${indexOfMarker})">
    <path d="M40.5 23C36.0817 23 32.5 26.5817 32.5 31V49C32.5 53.4183 36.0817 57 40.5 57H44.2245C45.0616 57 45.8916 57.2529 46.4366 57.8882C46.7191 58.2171 47.2348 58.8652 47.5753 59.293C47.6323 59.3648 47.6845 59.4303 47.7298 59.4871C47.76 59.525 47.7935 59.5673 47.8297 59.6129C48.103 59.9569 48.5256 60.4888 48.7693 60.7627C49.1167 61.1529 49.5461 61.5676 50.1424 61.5676C50.7388 61.5676 51.168 61.1529 51.5152 60.7631C51.7542 60.4946 52.1653 59.9774 52.439 59.6332C52.4813 59.58 52.5203 59.5309 52.5549 59.4875C52.5948 59.4374 52.64 59.3805 52.6893 59.3186C53.029 58.8914 53.5596 58.224 53.8478 57.8886C54.3938 57.2534 55.2239 57 56.0615 57H58.5C62.9183 57 66.5 53.4183 66.5 49V31C66.5 26.5817 62.9183 23 58.5 23H40.5Z" fill="url(#pattern0_2_4066${indexOfMarker})"></path>
    <path d="M45.6777 58.5394L45.678 58.5397C45.9451 58.8507 46.4462 59.4801 46.7928 59.9157L46.9474 60.1099L46.9479 60.1106L47.0468 60.2349L47.8087 59.6296L47.0468 60.2349L47.0605 60.2522C47.3264 60.5869 47.7598 61.1325 48.0222 61.4274L48.0224 61.4276C48.3597 61.8065 49.0576 62.5676 50.1424 62.5676C51.2273 62.5676 51.9251 61.8064 52.2619 61.4282L52.2621 61.428C52.5199 61.1384 52.9423 60.607 53.2082 60.2726L53.2218 60.2555L53.3369 60.1109L53.3372 60.1105L53.472 59.9409L52.6893 59.3186L53.472 59.9409C53.818 59.5059 54.3337 58.8576 54.6062 58.5404C54.8992 58.1995 55.4025 58 56.0615 58H58.5C63.4706 58 67.5 53.9706 67.5 49V31C67.5 26.0294 63.4706 22 58.5 22H40.5C35.5294 22 31.5 26.0294 31.5 31V49C31.5 53.9706 35.5294 58 40.5 58H44.2245C44.8836 58 45.386 58.1993 45.6777 58.5394Z" stroke="white" stroke-width="2"></path>
  </g>
</svg>
`;

  const badgeValue = `<img style="
    height: 28px; 
    width: 28px;
    position: absolute;
    top: -8px;
    right: -5px;
  " src=${svgBadge}
    id=${'badge' + indexOfMarker}
    >
  </img>`;

  if (isP2PTravelPoint) {
    if (image) {
      return (
        svgMask(image) +
        `<img style="
          height: 26px; 
          width: 26px;
          position: absolute;
          top: -9px;
          right: -5px;
        " src=${svgBadge}
          id=${'badge' + indexOfMarker}
          >
        </img>`
      );
    }
    return `<div style='height: 53px; width: 51px; position: relative;'>
                ${defaultIcon}
              </div>  
              <img style="
                height: 24px; 
                width: 24px;
                position: absolute;
                top: -10px;
                right: -5px;
                " 
                src=${svgBadge}
                id=${'badge' + indexOfMarker}
                >
              </img>`;
  }
  if (isNoNumberBadge) {
    if (image) {
      return svgMask(image);
    } else {
      return defaultIcon;
    }
  }

  const iconValue = image
    ? svgMask(image) + badgeValue
    : defaultIcon + badgeValue;

  return iconValue;
}
