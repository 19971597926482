import { toggleMapInteractivity } from '~/map/helpers';
import { TravelAnimation } from '../TravelAnimation';
import { State } from '../models';
import { Marker } from '~/animationEngine/Marker';

/**
 * Class representing the AnimateDestinationMarker state within a travel animation.
 * This state is responsible for animating the destination marker and setting up its text.
 */
export class AnimateDestinationMarker implements State {
  /**
   * Reference to the parent TravelAnimation state machine.
   */
  private stateMachine: TravelAnimation;
  private marker: Marker | undefined;

  isPaused = false;
  /**
   * Constructor for the AnimateDestinationMarker state.
   * @param stateMachine - Reference to the parent TravelAnimation state machine.
   */
  constructor(stateMachine: TravelAnimation) {
    this.stateMachine = stateMachine;
  }

  /**
   * Function called upon entering the AnimateDestinationMarker state.
   * - Sets up the text for the destination marker using the appropriate animation controller.
   * - Starts the animation for the destination marker based on the selected transport type.
   * - Transitions the state machine to the hideTextPopups state upon animation completion.
   */
  async onEnter() {
    if (this.stateMachine.devMode)
      console.log('Entering AnimateDestinationMarker state');

    const currentController = this.stateMachine.getCurrentController();
    this.marker = currentController?.markers?.destination;
    toggleMapInteractivity(this.stateMachine.map, true);

    await this.marker?.setupText(true);

    this.marker?.startAnimation(
      this.stateMachine.getCurrentSegment().selectedTransport,
      () => {
        this.stateMachine.setState(
          this.stateMachine.states.destinationDelayState,
        );
      },
    );
  }

  onUpdate() {
    this.stateMachine.map.repaint = true;
  }

  /**
   * Function called upon exiting the AnimateDestinationMarker state.
   */
  onExit() {
    if (this.stateMachine.devMode)
      console.log('Exiting AnimateDestinationMarker state');
  }

  onPause() {
    this.isPaused = true;
    this.marker?.pause();
  }

  onPlay() {
    this.isPaused = false;
    this.stateMachine.map.repaint = true;
    this.marker?.play();
  }
}
