import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { Analytics } from '@vercel/analytics/react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import MuiTheme from '~styles/theme/MuiTheme';
import '~/index.css';
import App from '~/App';
import { store } from '~redux/store';

import { SpeedInsights } from '@vercel/speed-insights/react';
import { initializeOpenReplayTracker } from './utility/openreplay';
import { disableConsoleLogs } from './utility/utils';

const container: HTMLElement | null = document.getElementById('root');
const root = createRoot(container as HTMLElement);

// Initialize OpenReplay tracker
export const tracker = initializeOpenReplayTracker();

// Function to update tracker with user ID if available
const updateTrackerUserID = () => {
  const userID = store.getState().MapReducers.userID;
  const userEmail = store.getState().MapReducers.userEmail;
  const userName = store.getState().MapReducers.userName;

  if (tracker) {
    tracker.setUserID(userID);
    tracker.setMetadata('userEmail', userEmail);
    tracker.setMetadata('userName', userName);
  }
};

// Subscribe to the Redux store to watch for changes to the userID
store.subscribe(updateTrackerUserID);

// Initial check if the user ID is already set (useful for returning users)
updateTrackerUserID();

// disabled logs
disableConsoleLogs();

root.render(
  <Provider store={store}>
    <ThemeProvider theme={MuiTheme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <App />
        <Analytics />
        <SpeedInsights />
      </StyledEngineProvider>
    </ThemeProvider>
  </Provider>,
);
