import React, { forwardRef, LegacyRef } from 'react';
import { isReactNativeView } from '~/utility/utils';

export const IFrameItem = forwardRef(
  (
    {
      videoId,
      shouldCover = false,
    }: { videoId: string; shouldCover?: boolean },
    ref: LegacyRef<HTMLIFrameElement>,
  ) => {
    const isReactNative = isReactNativeView()
    return (
      <iframe
        ref={ref}
        src={`https://www.tiktok.com/player/v1/${videoId}?controls=${isReactNative ? 1 : 0}&autoplay=0&native_context_menu=0&rel=0&description=0&closed_caption=0&loop=1`}
        className={shouldCover ? '' : 'tiktok_iframe'}
        style={
          shouldCover
            ? {
              // position: 'absolute',
              // top: 0,
              // left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              border: 'none',
              cursor: 'pointer',
              pointerEvents: isReactNative ? 'auto' : 'none',
            }
            : {}
        }
        allow="encrypted-media; autoplay"
        title="tiktok"
      ></iframe>
    );
  },
);
