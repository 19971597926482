// React Imports
import React from 'react';

// MUI Imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Redux Imports
import { useSelector } from '~/redux/reducers';
import { CombinedState } from 'redux';
import { MapProps } from '~/redux/reducers/MapReducers';
import { TravelProps } from '~/redux/reducers/TravelReducers';
import { AnimationProps } from '~/redux/reducers/AnimationReducers';

// Utility Imports
import { TravelFormData } from '~/utility/models';
import { calculateTravelTime, formatTime } from '~/utility/utils';

// Style Imports
import useStyles from './styles';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import dayjs from 'dayjs';

/**
 * Represents the props for the ScheduleSection
 * @interface FormSectionProps
 * @property {TravelFormData} formData - Travel Form Data to show on ScheduleSection
 */
interface ScheduleSectionProps {
  travelPointItem: TravelFormData;
}

/**
 * Renders the Travel Points Schedule in Travel Itinery rightside Popup
 * @param {Object} props - The props object.
 * @param {ScheduleSectionProps} props.formData - The form data.
 * @returns {JSX.Element} Returns ScheduleSection component.
 */
const ScheduleSection = ({ travelPointItem }: ScheduleSectionProps) => {
  const scheduleSectionState = useSelector(
    (
      state: CombinedState<{
        MapReducers: MapProps;
        TravelReducers: TravelProps;
        AnimationReducers: AnimationProps;
      }>,
    ) => state.MapReducers.scheduleSectionState,
  );

  const classes = useStyles({ scheduleSectionState });

  // Extracting Data from Props to use in UI
  let departureDate = travelPointItem.departure.dateTime
    ? new Date(travelPointItem.departure.dateTime as Date)
    : null;

  let arrivalDate = travelPointItem.arrival.dateTime
    ? new Date(travelPointItem.arrival.dateTime as Date)
    : null;

  let travelTime = calculateTravelTime(departureDate, arrivalDate);

  return (
    <div>
      <Grid container className={classes.containerComp}>
        <Grid item xs={4}>
          <Typography variant="h6" className={classes.text}>
            {travelPointItem.departure.location?.city}
            <br />
            {travelPointItem.departure.location?.country}
            <br />
            {travelPointItem.departure.location?.label}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center', margin: 'auto' }}>
          <img
            src={
              travelPointItem.selectedTransport === TravelMode.Car
                ? '/icons/carTravel.svg'
                : travelPointItem.selectedTransport === TravelMode.Plane
                ? '/icons/flight.svg'
                : travelPointItem.selectedTransport === TravelMode.Transit
                ? '/icons/blacktransit.svg'
                : travelPointItem.selectedTransport === TravelMode.Walk
                ? '/icons/blackwalk.svg'
                : travelPointItem.selectedTransport === TravelMode.Ferry
                ? '/icons/ferry.png'
                : ''
            }
            alt="flight"
            className={classes.flight}
          ></img>
          <br />
          <Typography variant="h6" className={classes.traveltime}>
            {travelTime}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <Typography variant="h6" className={classes.text}>
            {travelPointItem.arrival.location?.city}
            <br />
            {travelPointItem.arrival.location?.country}
            <br />
            {travelPointItem.arrival.location?.label}
          </Typography>
        </Grid>
        <hr className={classes.line}></hr>

        <Grid item xs={4.5}>
          <Typography
            variant="h6"
            sx={{ fontSize: '10px' }}
            className={classes.text}
          >
            {dayjs(travelPointItem.departure.dateTime)
              .tz(travelPointItem.departure.timezone)
              .format('MMMM D, YYYY')}
            <br />
            {dayjs(travelPointItem.departure.dateTime)
              .tz(travelPointItem.departure.timezone)
              .format('hh:mm A')}{' '}
            (local time)
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'center', margin: 'auto' }}>
          <img
            src="/icons/clock2.svg"
            alt="clock"
            className={classes.clock2}
          ></img>
        </Grid>
        <Grid item xs={4.5} sx={{ textAlign: 'right' }}>
          <Typography variant="h6" className={classes.text}>
            {dayjs(travelPointItem.arrival.dateTime)
              .tz(travelPointItem.arrival.timezone)
              .format('MMMM D, YYYY')}
            <br />
            (local time){' '}
            {dayjs(travelPointItem.arrival.dateTime)
              .tz(travelPointItem.arrival.timezone)
              .format('hh:mm A')}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ScheduleSection;
