import styled from '@emotion/styled';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  IMedia,
  ITravelFormHistoryData,
  TravelOptions,
  TravelPointsByDayIndex,
} from '~/utility/models';
import { RootState } from '~/redux/reducers';
import {
  addP2PToHistory,
  getP2PHistory,
  updateTravelHistoryTodoById,
} from '~/supabase/p2pHistory';
import ActionsCreator from '~/redux/actions';
import tz_lookup from 'tz-lookup';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import { useNavigate } from 'react-router-dom';
import ROUTES from '~/routes';
import { MANUAL_TRIP_HISTORY, saveTravelPoints } from '../P2PManual';
import { getCountryCode } from 'countries-list';
import { SwiperSlide } from 'swiper/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { generateUUID } from 'three/src/math/MathUtils';
import {
  encodeTourID,
  generateEncodedPathAndDuration,
  getInitialDateTime,
  getTravelPointsArray,
  showError,
  updateNextDays,
  updateNextPointByDay,
} from '~/utility/utils';
import { store } from '~/redux/store';
import { DEFAULT_DURATION_HOUR } from '../P2PManual/P2PSearchAutocomplete/constant';
import { todosSignal } from '../signals/thingsToDoSignal';
import { P2PCityAutocomplete } from '../P2PManual/P2PCityAutocomplete';
import { publishManualTravelPoints } from '~/redux/actions/MapActions';
import { CityInterface } from '~/supabase/php2EditablePlaces';

dayjs.extend(duration);

interface AddToTripDialogProps {
  open: boolean;
  onClose: (isAdded: boolean) => void;
  content?: IContentDetails;
  point?: ITodoTravelPoint;
  isViewTravel?: boolean;
  title?: string;
}

export interface ITodoTravelPoint {
  typeOfPoint: string;
  duration: number;
  selectedTransport: TravelMode;
  dayIndex: number;
  value: string;
  label: string;
  text: string;
  code: string;
  city: string;
  street: string;
  country: string;
  coordinates: [number, number]; // [longitude, latitude]
  timezone: string;
  description: string;
  longDescription: string;
  review: string;
  cost: number;
  tiktokData: IMedia[];
  urlBookPlace: string;
  todoCategory: string;
}

export interface IContentDetails {
  category: string;
  coords: [number, number];
  media: IMedia[];
  name: string;
  country: string;
  city: string;
  notes: string;
  price: number;
  review: number;
  storyIndex: number;
  summary: string;
  url_book_place: string;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 361,
    padding: '10px 10px',
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

const DoneButton = styled(Button)({
  width: '100%',
  minWidth: '100%',
  padding: '.4rem',
  borderRadius: 18,
  fontFamily: 'Poppins',
  fontSize: '16px',
  background:
    ' radial-gradient(100% 100% at 0% 0%, #FF9345 0.01%, #FE7138 50%, #F25D21 100%) ',
  boxShadow: `0px 2px 6px #b8c5d033, 0px 6px 22px #b8c5d04d, 
              0px 9px 30px #c6d8e933, inset 0px 1.5px 1.5px #ffffff40`,
  '&:hover': { backgroundColor: '#e6602b' },
});

export const AddToTripDialog: React.FC<AddToTripDialogProps> = ({
  open,
  onClose,
  content,
  isViewTravel = false,
  title,
  point,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(isViewTravel ? false : true);
  const [citySearchPopup, setCitySearchPopup] = useState<boolean>(false);

  const [travelHistory, setTravelHistory] = useState<ITravelFormHistoryData[]>(
    [],
  );

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );

  const currentDayIndex: number = useSelector(
    (state: RootState) => state.P2PManualReducers.currentDayIndex,
  );

  const { userID, userEmail }: { userID: string; userEmail: string } =
    useSelector((state: RootState) => state.MapReducers);

  const [creationInProgress, setCreatingInProgress] = useState<boolean>(false);

  const dayIndex = useMemo(() => {
    const points =
      (getTravelPointsArray(travelPointsByDayIndex) as TravelOptions[]) || []; // Ensure points is at least an empty array

    if (points.length === 0) {
      return 0; // Fallback if no points exist
    }

    const lastPoint = points[points.length - 1];
    return lastPoint?.dayIndex || 0; // Safely access dayIndex or fallback to 0
  }, [travelPointsByDayIndex]);

  // State to keep track of each number button and the active button
  const [numbers] = useState<number[]>(
    Array.from(
      { length: currentDayIndex > dayIndex ? currentDayIndex : dayIndex },
      (_, i) => i + 1,
    ) || [1],
  );
  const [selectedDay, setSelectedDay] = useState<number>(0);

  const setActiveNumber = (activeNumber: number) => {
    setSelectedDay(activeNumber);
  };

  const close = (e: {
    stopPropagation?: () => void;
    nativeEvent?: { stopImmediatePropagation: () => void };
  }) => {
    e?.stopPropagation?.();
    e?.nativeEvent?.stopImmediatePropagation?.();
    onClose(false);
  };

  const handleAddToTrip = async (point: ITodoTravelPoint) => {
    const lastVisitedDay: TravelOptions[] = [
      ...(travelPointsByDayIndex[selectedDay] || []),
    ];

    setLoading(true);

    let pointsByDayIndex: TravelPointsByDayIndex = {};

    if (lastVisitedDay && lastVisitedDay.length > 0) {
      const lastPoint: TravelOptions = {
        ...lastVisitedDay[lastVisitedDay.length - 1],
      };

      if (lastPoint) {
        const firstPoint = lastVisitedDay[0];

        if (lastPoint?.id === firstPoint?.id) {
          const newEndOfDayDate = dayjs.utc(lastPoint.startDate);

          let travelPoint = {
            ...point,
            startDate: newEndOfDayDate.format(),
            endDate: newEndOfDayDate.add(1, 'hour').format(),
            dayIndex: selectedDay,
            id: generateUUID(),
            duration: 0,
            placeId: '',
          };

          // update last point duration and encodedPath
          let updatedLastPoint = { ...lastPoint };

          // Determine the correct day index and position
          const updatedDayIndex = updatedLastPoint.dayIndex;
          const updatedTravelPointsByDay = [
            ...(travelPointsByDayIndex[updatedDayIndex] || []),
          ];

          // Find the position of the last point
          const updatedLastPointIndex = updatedTravelPointsByDay.findIndex(
            (point) => point.id === updatedLastPoint.id,
          );

          if (updatedLastPointIndex !== -1) {
            const result = await generateEncodedPathAndDuration(
              updatedTravelPointsByDay[updatedLastPointIndex],
              travelPoint as TravelOptions,
            );

            const newStartDatePlusDuration = dayjs
              .utc(travelPoint.startDate)
              .add(result.duration, 'm')
              .format();
            const newEndDatePlusDuration = dayjs
              .utc(travelPoint.endDate)
              .add(result.duration, 'm')
              .format();

            travelPoint = {
              ...travelPoint,
              startDate: newStartDatePlusDuration,
              endDate: newEndDatePlusDuration,
            };

            // Update the last point in the array
            updatedTravelPointsByDay[updatedLastPointIndex] = {
              ...updatedLastPoint,
              duration: result.duration,
              encodedPath: result.encodedPath,
            };
          }

          const travelPointsByDayIndexFromStore =
            store.getState().P2PManualReducers.travelPointsByDayIndex;

          // Get the current day index
          const dayIndex = travelPoint.dayIndex;

          //  Create a new updated object for travelPointsByDayIndex
          const newTravelPointsByDayIndexFromStore = {
            ...travelPointsByDayIndexFromStore,
            // Update the day where the last point exists
            [updatedDayIndex]: updatedTravelPointsByDay,
          };

          let newUpdatedTravelPointsByDayIndex: TravelPointsByDayIndex = {
            ...newTravelPointsByDayIndexFromStore,
            // Add the new travel point to its day
            [dayIndex]: [
              ...(newTravelPointsByDayIndexFromStore[dayIndex] || []),
              travelPoint as TravelOptions,
            ],
          };

          // check if the last place matches the first of the next point
          newUpdatedTravelPointsByDayIndex = updateNextDays(
            newUpdatedTravelPointsByDayIndex,
            dayIndex,
            updateNextPointByDay,
          );

          dispatch(
            ActionsCreator.setTravelPointsByDayIndex(
              newUpdatedTravelPointsByDayIndex,
            ),
          );

          dispatch(ActionsCreator.setLastVisitedDayIndex(dayIndex));
          pointsByDayIndex = newUpdatedTravelPointsByDayIndex;
        } else {
          let travelPoint = {
            ...point,
            startDate: lastPoint.endDate,
            endDate: dayjs(lastPoint.endDate).utc().add(1, 'hour').format(),
            dayIndex: selectedDay,
            id: generateUUID(),
            duration: 0,
            placeId: '',
          };

          // update last point duration and encodedPath
          let updatedLastPoint = { ...lastPoint };

          // Determine the correct day index and position
          const updatedDayIndex = updatedLastPoint.dayIndex;
          const updatedTravelPointsByDay = [
            ...(travelPointsByDayIndex[updatedDayIndex] || []),
          ];

          // Find the position of the last point
          const updatedLastPointIndex = updatedTravelPointsByDay.findIndex(
            (point) => point.id === updatedLastPoint.id,
          );

          if (updatedLastPointIndex !== -1) {
            const result = await generateEncodedPathAndDuration(
              updatedTravelPointsByDay[updatedLastPointIndex],
              travelPoint as TravelOptions,
            );

            const newStartDatePlusDuration = dayjs
              .utc(travelPoint.startDate)
              .add(result.duration, 'm')
              .format();

            const newEndDatePlusDuration = dayjs
              .utc(travelPoint.endDate)
              .add(result.duration, 'm')
              .format();

            travelPoint = {
              ...travelPoint,
              startDate: newStartDatePlusDuration,
              endDate: newEndDatePlusDuration,
            };

            // Update the last point in the array
            updatedTravelPointsByDay[updatedLastPointIndex] = {
              ...updatedLastPoint,
              duration: result.duration,
              encodedPath: result.encodedPath,
            };
          }

          const travelPointsByDayIndexFromStore =
            store.getState().P2PManualReducers.travelPointsByDayIndex;

          // Get the current day index
          const dayIndex = travelPoint.dayIndex;

          //  Create a new updated object for travelPointsByDayIndex
          const newTravelPointsByDayIndexFromStore = {
            ...travelPointsByDayIndexFromStore,
            // Update the day where the last point exists
            [updatedDayIndex]: updatedTravelPointsByDay,
          };

          let updatedTravelPointsByDayIndex: TravelPointsByDayIndex = {
            ...newTravelPointsByDayIndexFromStore,
            // Add the new travel point to its day
            [dayIndex]: [
              ...(newTravelPointsByDayIndexFromStore[dayIndex] || []),
              travelPoint as TravelOptions,
            ],
          };

          updatedTravelPointsByDayIndex = updateNextDays(
            updatedTravelPointsByDayIndex,
            dayIndex,
            updateNextPointByDay,
          );

          dispatch(
            ActionsCreator.setTravelPointsByDayIndex(
              updatedTravelPointsByDayIndex,
            ),
          );

          pointsByDayIndex = updatedTravelPointsByDayIndex;
        }
      }
    } else {
      let currentDate = dayjs().tz(point?.timezone);
      if (currentDayIndex > -1) {
        // Adjust dates based on the currentDayIndex
        currentDate = currentDate.add(currentDayIndex - 1, 'day');
      }
      const startDate = getInitialDateTime(currentDate);

      let travelPoint = {
        ...point,
        startDate: startDate,
        endDate: dayjs(startDate)
          .add(DEFAULT_DURATION_HOUR, 'hour')
          .utc()
          .format(),
        dayIndex: selectedDay,
        id: generateUUID(),
        duration: 0,
        encodedPath: {
          data: [],
          path: '',
        },
        placeId: '',
      };

      // Get the current day index
      const dayIndex = travelPoint.dayIndex;

      // Create a new updated object for travelPointsByDayIndex
      const updatedTravelPointsByDayIndex = {
        ...travelPointsByDayIndex,
        [dayIndex]: [
          ...(travelPointsByDayIndex[dayIndex] || []), // existing points for the day, or empty array if none
          travelPoint as TravelOptions,
        ],
      };

      dispatch(
        ActionsCreator.setTravelPointsByDayIndex(updatedTravelPointsByDayIndex),
      );
      pointsByDayIndex = updatedTravelPointsByDayIndex;
    }

    // dispatch(ActionsCreator.setIsTravelPointSelectedState(true));
    // dispatch(ActionsCreator.setMapButtonState(true));
    // dispatch(ActionsCreator.setHeaderState(true));
    if (Object.keys(pointsByDayIndex).length > 0) {
      await saveTravelPoints(pointsByDayIndex);
    }

    const currentTodos = todosSignal.peek();

    todosSignal.value = currentTodos.filter(
      (todo) =>
        JSON.stringify(todo.coordinates) !== JSON.stringify(point.coordinates),
    );

    setLoading(false);

    onClose(true);
  };

  const handleTitleSave = async (city: CityInterface) => {
    const { city: title } = city;

    setCreatingInProgress(true);

    try {
      addP2PToHistory([], city, userEmail, userID, title)
        .then((res) => {
          if (res) {
            publishManualTravelPoints(
              {
                travelPoints: [],
                mapStyleIndex: 1,
                title,
              },
              userID,
              res.identifier,
              0,
              city,
            ).then((publishId: number) => {
              if (publishId)
                dispatch(
                  ActionsCreator.setCurrentHistoryIdentifier(res.identifier),
                );

              dispatch(ActionsCreator.setTravelTitle(title));
              dispatch(ActionsCreator.setSelectedCity(city));
              return navigate(
                `${ROUTES.VIEWTRAVEL.path}?tourID=${encodeTourID(publishId)}`,
              );
            });
          }
        })
        .catch((error) => {
          showError('Something went wrong. Please try again...');
          console.log(error, ' error here');
        })
        .finally(() => {
          setCreatingInProgress(false);
        });
    } catch (err) {
      showError('Something went wrong. Please try again...');
      setCreatingInProgress(false);
      console.log(err, 'err');
    }
  };

  useEffect(() => {
    if (!isViewTravel) {
      getP2PHistory(userID).then((result) => {
        if (result.error) {
          console.error('Error fetching travel history:', result.error);
        } else {
          setTravelHistory(result.data as ITravelFormHistoryData[]);
          setLoading(false);
          dispatch(
            ActionsCreator.saveTravelToHistory(
              result.data as ITravelFormHistoryData[],
            ),
          );
        }
      });
    }
  }, [dispatch, isViewTravel, userID]);

  // Renderer for react-window list items
  const renderRow = ({
    index,
    style,
  }: ListChildComponentProps<ITravelFormHistoryData[]>) => {
    const point = travelHistory[index];
    if (!content) return null;
    return (
      <div style={style} key={point.id}>
        <Item
          point={point}
          content={content}
          loading={loading}
          setLoading={setLoading}
          onClose={onClose}
        />
      </div>
    );
  };

  return (
    <StyledDialog
      open={open}
      onClose={(e) => {
        if (!loading) {
          close(e);
        }
      }}
      disableEscapeKeyDown={loading}
      PaperProps={{
        style: {
          maxHeight: '100dvh',
          overflow: 'auto',
        },
      }}
    >
      <IconButton
        onClick={close}
        disabled={loading}
        style={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: '#000',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          mt: '1rem',
        }}
      >
        {title ?? 'Add this Point to a Trip'}
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflowY: 'auto',
          maxHeight: '100dvh',
        }}
      >
        {isViewTravel ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              gap: '1rem',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingTop: '10px',
                gap: '1rem',
              }}
            >
              {/* Render each number button based on numbers array */}
              {numbers.map((num, i) => (
                <SwiperSlide style={{ width: 51 }} key={i.toString()}>
                  <Button
                    key={num}
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setActiveNumber(num);
                    }}
                    disabled={loading}
                    sx={{
                      width: 12,
                      height: '60px',
                      padding: 3,
                      boxShadow: 'none',
                      minWidth: 0,
                      fontSize: '8px',
                      borderRadius: '10px',
                      textTransform: 'none',
                      backgroundColor:
                        selectedDay === num
                          ? '#fe7138'
                          : 'var(--alpha-orange-alpha-10, #FF91471A)',
                      color: selectedDay === num ? '#fff' : '#fe7138',
                    }}
                  >
                    <span
                      className="d-flex flex-column"
                      style={{ lineHeight: '0.3' }}
                    >
                      <h6 style={{ fontWeight: 400, fontSize: '14px' }}>
                        Day{' '}
                      </h6>
                      <b style={{ fontSize: '18px', fontWeight: 700 }}>{num}</b>
                    </span>
                  </Button>
                </SwiperSlide>
              ))}
            </Box>
            {!!selectedDay && (
              <Button
                disabled={loading}
                sx={{
                  cursor: 'pointer',
                  width: '95%',
                  height: '56px',
                  padding: '4px 6px',
                  gap: '6px',
                  borderRadius: '18px',
                  background:
                    'radial-gradient(100% 100% at 0% 0%, #FF9345 0.01%, #FE7138 50%, #F25D21 100%)',
                  boxShadow:
                    '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
                  fontFamily: 'Poppins',
                  fontSize: '21px',
                  fontWeight: 700,
                  lineHeight: '31.5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  color: '#FFFFFF',
                  marginTop: '10px',
                  textTransform: 'none',
                  '&:hover': {
                    background:
                      'radial-gradient(100% 100% at 0% 0%, #FE7138 0.01%, #FF9345 50%, #F25D21 100%)',
                  },
                }}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (point) {
                    await handleAddToTrip(point);
                  }
                }}
              >
                {loading ? 'Adding...' : 'Add'}
              </Button>
            )}
          </Box>
        ) : (
          <>
            {loading ? (
              <Typography>Loading...</Typography>
            ) : travelHistory.length === 0 ? (
              <Box my="1rem">
                <Typography
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    color: '#0E2132',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '22.4px',
                    opacity: 0.75,
                  }}
                >
                  No trips available.
                  <br />
                  Create a trip to start adding places.
                </Typography>
              </Box>
            ) : (
              <FixedSizeList
                height={300} // Set the height of the list container
                itemCount={travelHistory.length}
                itemSize={80} // Set the height of each item
                width="100%" // Make it responsive
              >
                {renderRow}
              </FixedSizeList>
            )}
            {!loading && (
              <DoneButton
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  dispatch(ActionsCreator.setDefaultP2pManualState());
                  sessionStorage.removeItem(MANUAL_TRIP_HISTORY);
                  setCitySearchPopup(true);
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    color: '#fff',
                    fontFamily: 'Poppins, sans-serif',
                    textTransform: 'math-auto',
                  }}
                >
                  Create a New Trip
                </Typography>
              </DoneButton>
            )}
          </>
        )}

        {citySearchPopup && (
          <P2PCityAutocomplete
            onClose={() => setCitySearchPopup(false)}
            onSelect={handleTitleSave}
          />
        )}
      </DialogContent>
    </StyledDialog>
  );
};

const Item: FC<{
  point: ITravelFormHistoryData;
  content: IContentDetails;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: (isAdded: boolean) => void;
}> = ({ point, content, loading, setLoading, onClose }) => {
  const handleClick = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (!point) {
      return;
    }

    // Indicate loading state and disable other interactions
    setLoading(true);

    try {
      const currentToDo = convertContentDetailsToPointTodo(content);

      if (currentToDo && currentToDo?.length > 0) {
        const todos =
          point?.todos && point.todos.length > 0 ? [...point.todos] : [];

        // Perform the update operation
        await updateTravelHistoryTodoById([...todos, ...currentToDo], point.id);
      }
    } catch (error) {
      console.error('Error saving travel data:', error);
    } finally {
      // Ensureloading state is reset after the operation finishes
      setLoading(false);
    }
    onClose(true);
  };

  return (
    <Box
      m="1rem"
      sx={{
        cursor: loading ? 'not-allowed' : 'pointer', // Disable cursor ifloading
        opacity: loading ? 0.5 : 1, // Dim the component whileloading
        pointerEvents: loading ? 'none' : 'auto', // Disable pointer events whileloading
      }}
      onClick={loading ? () => {} : handleClick}
    >
      <Typography
        sx={{
          fontFamily: 'Poppins, sans-serif',
          color: '#0E2132',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '22.4px',
          opacity: 0.75,
        }}
      >
        {point.title}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Poppins, sans-serif',
          color: '#0E2132',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '16.8px',
          opacity: 0.5,
        }}
      >
        {point?.todos?.length ?? 0} points
      </Typography>
    </Box>
  );
};

export const convertContentDetailsToPointTodo = (content: IContentDetails) => {
  // Ensure content is defined and has the necessary properties
  if (!content || !content.coords || content.coords.length < 2) {
    console.error('Content or coordinates are missing');
    return;
  }

  const [lng, lat] = content.coords;

  // Assuming tz_lookup is properly defined
  const timezone = tz_lookup(lat, lng);

  // Handle the todo list correctly if it exists
  const currentToDo: ITodoTravelPoint[] = [];

  let countryCode = '';

  if (content?.country) {
    const _code = getCountryCode(content.country);
    if (_code) {
      countryCode = _code;
    }
  }

  const travelPoint = {
    typeOfPoint: content?.category === 'food' ? 'restaurant' : 'poa',
    duration: 0,
    selectedTransport: TravelMode.Car,
    dayIndex: 0,
    value: content.name,
    label: content.name,
    text: content.name,
    code: countryCode,
    city: content?.city ?? '',
    street: '',
    country: content?.country ?? '',
    coordinates: content.coords,
    timezone: timezone,
    description: content.summary,
    longDescription: content.notes,
    review: content.review.toString(),
    cost: content.price,
    tiktokData: content.media,
    urlBookPlace: content.url_book_place,
    todoCategory: content.category,
  };

  currentToDo.push(travelPoint);

  return currentToDo;
};
