import React from 'react';
import { useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Paper,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useStyles from './styles';
import dayjs, { Dayjs } from 'dayjs';
import { Options } from '~/utility/models';

/**
 * Represents the props for a disabled form section component.
 * @interface DisabledFormSectionProps
 * @property {string} type - The type of the form section ('departure' or 'arrival').
 * @property {string} alertText - The text to display as an alert.
 * @property {Object} data - The data object containing information about the location, date and time, category, and timezone.
 * @property {Options | null} data.location - The location option.
 * @property {Date | null | undefined | Dayjs | string} data.dateTime - The date and time of the form section, represented as a Date, Dayjs, or string.
 * @property {string} data.category - The category of the form section.
 * @property {string} data.timezone - The timezone associated with the form section.
 * @property {Date | null} date - The date associated with the form section.
 * @property {Function} handleChangeDateTime - A function to handle changes in the date and time.
 */

interface DisabledFormSectionProps {
  type: string;
  alertText: string;
  data: {
    location: Options | null;
    dateTime: Date | null | undefined | Dayjs | string;
    category: string;
    timezone: string;
  };
  date: Date | Dayjs | null | string;
  handleChangeDateTime: (date: Dayjs | null) => void;
  selectedDateTime?: Dayjs;
  setSelectedDateTime?: React.Dispatch<React.SetStateAction<Dayjs | undefined>>;
}

/**
 * Renders a disabled form container for either departure or arrival point.
 * @param {Object} props - The props object.
 * @param {string} props.type - The type of the form section ('departure' or 'arrival').
 * @param {string} props.alertText - The text to display as an alert.
 * @param {Object} props.data - The data object containing location, dateTime, category, and timezone.
 * @param {Date|null} props.date - The date value.
 * @param {Function} props.handleChangeDateTime - The function to handle changes in date and time.
 * @returns {JSX.Element} DisabledFormContainer component.
 */

const DisabledFormContainer = ({
  type,
  data,
  alertText,
  date,
  handleChangeDateTime,
  selectedDateTime,
  setSelectedDateTime,
}: DisabledFormSectionProps) => {
  const classes = useStyles();
  const desktopScreen = useMediaQuery('(min-width:1440px)');
  const inputFieldSize = desktopScreen ? 'medium' : 'small';
  const titletext = type === 'departure' ? 'Departure' : 'Arrival';
  const dayjsDateTime = dayjs(date);
  /**
   * Effect hook to set selected date and time when component mounts or data changes.
   */

  useEffect(() => {
    if (!selectedDateTime && setSelectedDateTime) {
      const dayjsDateTime = dayjs(data.dateTime);
      setSelectedDateTime(dayjsDateTime as Dayjs);
    }
  }, [data.dateTime, selectedDateTime]);

  /**
   * Renders the disabled form container JSX.
   * @returns {JSX.Element} JSX for the disabled form container.
   */

  return (
    <Grid container spacing={1} padding={0.5} paddingTop={0} marginTop={0}>
      <Grid
        item
        xs={12}
        md={12}
        marginTop={1}
        marginBottom={2}
        className={classes.formContainer}
      >
        <Typography className={`${classes.subHeading} ${classes.disabled}`}>
          {titletext} Point
          <span>
            <img src="/icons/disabled.svg" alt="disabled"></img>
          </span>
        </Typography>
        <Paper elevation={3} className={classes.customPaper}>
          <Typography variant="body1" className={classes.alertText}>
            {alertText}
          </Typography>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        marginTop={1}
        marginBottom={1}
        className={classes.formContainer}
      >
        <TextField
          variant="outlined"
          size={inputFieldSize}
          fullWidth
          value={data.location?.value}
          disabled
          className={classes.customInput}
          InputProps={{
            className: classes.subHeading,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        marginTop={1}
        marginBottom={1}
        className={classes.formContainer}
      >
        <Typography className={classes.subHeading} color={'grey'}>
          Type of Point
        </Typography>
        <Button
          variant="contained"
          className={classes.categoryButtons}
          disabled
        >
          {data.category}
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        marginTop={1}
        marginBottom={1}
        className={classes.formContainer}
      >
        <Typography className={classes.subHeading}>
          {titletext} Time and Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            slotProps={{
              textField: {
                fullWidth: true,
                size: inputFieldSize,
                placeholder: 'Full date format',
                className: classes.selectBackground,
                InputProps: {
                  className: classes.subHeading,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src="/icons/clock.svg"
                        alt="calendar"
                        className={classes.clockImg}
                      />
                    </InputAdornment>
                  ),
                },
              },
            }}
            value={
              data.dateTime
                ? (dayjs(data.dateTime).tz(data.timezone) as Dayjs)
                : null
            }
            onChange={handleChangeDateTime}
            minDateTime={type === "departure" ? selectedDateTime as Dayjs: dayjsDateTime as Dayjs}
            timezone={data.timezone}
            maxDateTime={type === "departure" ? dayjsDateTime as Dayjs: selectedDateTime  as Dayjs}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default DisabledFormContainer;
