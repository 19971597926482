import { Modal } from '@mui/material';
import React from 'react';
import CustomizeMap from '~/components/CustomizeMap';
import { useSelector } from '~/redux/reducers';
import ActionsCreator from '~redux/actions';
import { useDispatch } from '~redux/store';

/**
 * CustomizeMapContainer component - wraps the CustomizeMap component in a Modal.
 *
 * @component
 * @returns {React.ReactElement} - Rendered component.
 */
const CustomizeMapContainer = (): React.ReactElement => {
  const dispatch = useDispatch();

  // Retrieve the state of the map customization popup
  const openCustomizeMap = useSelector(
    (state) => state.MapReducers.mapCustomizationPopupState,
  );

  /**
   * Handle closing the map customization popup.
   */
  const handleClose = () => {
    dispatch(ActionsCreator.openMapCustomizationPopup(false));
  };

  return (
    <Modal
      open={openCustomizeMap}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <CustomizeMap handleClose={handleClose} />
      </div>
    </Modal>
  );
};

export default CustomizeMapContainer;
