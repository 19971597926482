import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { previewMapSignal } from './DiscoverMap';
import goBackNew from '~assets/goBackNew.png';

import './DiscoveryMapHeader.css';

export const DiscoveryMapHeader = ({
  setFilter,
  activeFilter,
}: {
  setFilter: (filter: 'activity' | 'food' | null) => void;
  activeFilter: 'activity' | 'food' | null;
}) => {
  const navigate = useNavigate();

  return (
    <div className="discovery-map-header">
      <div
        className="mobile-header-go-back-btn"
        onClick={(e) => {
          e.stopPropagation();
          previewMapSignal.value = null;
          navigate('/discover-tokyo');
        }}
      >
        <img src={goBackNew} width={40} height={33} alt="go-back" />
      </div>
      <div className="activities-btns">
        <div
          className={`custom-badge btn ${
            activeFilter === 'food' ? 'not-active' : ''
          }`}
          style={{ color: '#4169E1' }}
          onClick={() => {
            setFilter(activeFilter === 'activity' ? null : 'activity');
          }}
        >
          <span>
            <img className="discovery-map-img" src="/poa.svg" alt="" />
            Activity
          </span>
        </div>
        <div
          style={{ color: '#ff901f' }}
          className={`custom-badge btn ${
            activeFilter === 'activity' ? 'not-active' : ''
          }`}
          onClick={() => {
            setFilter(activeFilter === 'food' ? null : 'food');
          }}
        >
          <span>
            <img className="discovery-map-img" src="/restaurant.svg" alt="" />
            Restaurant
          </span>
        </div>
      </div>
    </div>
  );
};
