import React from 'react';
import { AnimationProps, motion } from 'framer-motion';

const HeaderText = ({
  children,
  color = 'black',
  id,
  ...rest
}: React.PropsWithChildren & {
  color?: string;
  id?: string;
} & AnimationProps) => {
  return (
    <motion.span
      id={id}
      exit={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: -100 }}
      className="mobileHeader-title"
      style={{
        color: color,
      }}
      {...rest}
    >
      {children}
    </motion.span>
  );
};

export default HeaderText;
