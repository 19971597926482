import styled from '@emotion/styled';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ITiktokData, TravelOptions } from '~/utility/models';
import ClearIcon from '@mui/icons-material/Clear';
import { convertBlobUrlToFile } from '../helpers/convertBlobUrlToFile';
import {
  uploadFileToStorage,
  deleteFileByUrlFromStorage,
} from '~/utility/utils';
import { store } from '~/redux/store';

interface AddTiktokDialogProps {
  open: boolean;
  onSave: (tiktokData: ITiktokData[]) => void;
  point: TravelOptions;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 361,
    padding: '10px 10px',
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

const DoneButton = styled(Button)({
  width: 120,
  height: 64,
  borderRadius: 18,
  fontFamily: 'Poppins',
  fontSize: '16px',
  background:
    ' radial-gradient(100% 100% at 0% 0%, #FF9345 0.01%, #FE7138 50%, #F25D21 100%) ',
  boxShadow: `0px 2px 6px #b8c5d033, 0px 6px 22px #b8c5d04d, 
              0px 9px 30px #c6d8e933, inset 0px 1.5px 1.5px #ffffff40`,
  '&:hover': { backgroundColor: '#e6602b' },
});

let timer: ReturnType<typeof setTimeout>;

export const AddTiktokDialog: React.FC<AddTiktokDialogProps> = ({
  open,
  onSave,
  point,
}) => {
  const [tiktokUrl, setTiktokUrl] = useState('');
  const [tiktokData, setTiktokData] = useState<ITiktokData[]>([]);
  const [isValidUrl, setIsValidUrl] = useState<'true' | 'false' | null>(null);
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [thumbUrl, setThumbUrl] = useState('');

  useEffect(() => {
    if (point?.tiktokData && point?.tiktokData?.length > 0) {
      setTiktokData(point.tiktokData);
      setThumbUrl(point.tiktokData[0]?.thumbnail || '');
      setTiktokUrl(point.tiktokData[0]?.url || '');
    }
  }, [point]);

  const handleValidateAndSaveTiktok = () => {
    const tiktokArr = tiktokUrl?.split('/') || [];
    const videoId = tiktokArr[tiktokArr?.length - 1];
    setIframeSrc(
      videoId ? `https://www.tiktok.com/player/v1/${videoId}` : null,
    );
  };

  useEffect(() => {
    const handleReadMessage = (msg: any) => {
      if (msg.data.type === 'onPlayerReady') {
        setIsValidUrl('true');
      }
    };

    window.addEventListener('message', handleReadMessage);

    return () => {
      window.removeEventListener('message', handleReadMessage);
    };
  }, []);

  useEffect(() => {
    clearInterval(timer);
    setIsLoading(true);

    timer = setTimeout(() => {
      if (!isValidUrl && iframeSrc) {
        setIsValidUrl('false');
      }
      setIsLoading(false);
    }, 2000);
  }, [isValidUrl, iframeSrc]);

  useEffect(() => {
    if (isValidUrl === 'true') {
      const fetchTiktokData = async () => {
        try {
          const res = await axios.get(
            `https://www.tiktok.com/oembed?url=${tiktokUrl}`,
          );

          if (res.data.thumbnail_url) {
            const imageFile = await convertBlobUrlToFile(
              res.data.thumbnail_url,
            );
            const { fileUrl, error } = await uploadFileToStorage({
              bucketName: 'travel-thumbnails',
              userId: store.getState().MapReducers.userID,
              file: imageFile,
            });

            if (error) {
              console.error(error);
              return;
            }

            if (fileUrl?.data.publicUrl) {
              const newTiktokData = {
                url: tiktokUrl,
                thumbnail: fileUrl?.data.publicUrl ?? '',
              };
              setThumbUrl(res.data.thumbnail_url);
              setTiktokData([newTiktokData]);
            }
          }
        } catch (error) {
          setIsValidUrl('false');
        }
      };
      fetchTiktokData();
    }
  }, [isValidUrl]);

  const handleClose = () => {
    onSave(tiktokData);
    // setIframeSrc(null);
    // setIsValidUrl(null);
    // setTiktokUrl('');
    // setThumbUrl('');
  };

  const handleRemoveThumbnail = () => {
    setIframeSrc(null);
    setIsValidUrl(null);
    setTiktokUrl('');
    setThumbUrl('');
    deleteFileByUrlFromStorage({
      bucketName: 'travel-thumbnails',
      userId: store.getState().MapReducers.userID,
      fileUrl: tiktokData[0]?.thumbnail,
    });
    setTiktokData([]);
  };

  return (
    <>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Paste a TikTok Video Link
        </DialogTitle>
        {thumbUrl ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                position: 'relative',
                width: '80px',
                height: '80px',
                margin: '0 auto',
              }}
            >
              <img
                width="80px"
                height="80px"
                src={thumbUrl}
                alt="thumbnail"
                loading="lazy"
                style={{ borderRadius: 16 }}
              />

              <IconButton
                style={{
                  position: 'absolute',
                  top: '1px',
                  right: '-2px',
                  backgroundColor: 'transparent',
                  borderRadius: '50%',
                  zIndex: '99',
                }}
                size="small"
                onClick={handleRemoveThumbnail}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <rect
                    x="0.00244141"
                    y="0.447021"
                    width="19.9976"
                    height="19.9976"
                    rx="9.99878"
                    fill="#F2F2F2"
                  />
                  <path
                    d="M14 14.4447L10.0005 10.4452L14 6.44568"
                    stroke="#4B4B4B"
                    strokeWidth="1.40922"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.00146 6.44572L10.001 10.4452L6.00146 14.4447"
                    stroke="#4B4B4B"
                    strokeWidth="1.40922"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </div>
            <p
              style={{
                textAlign: 'center',
                fontSize: '15px',
                fontWeight: 700,
                padding: '10px',
              }}
            >
              One TikTok Link Max
            </p>
            <DoneButton onClick={handleClose}>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ color: '#fff', fontFamily: 'Poppins, sans-serif' }}
              >
                Done
              </Typography>
            </DoneButton>
          </div>
        ) : (
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 0,
            }}
          >
            <TextField
              fullWidth
              multiline
              rows={4}
              value={tiktokUrl}
              onChange={(e) => setTiktokUrl(e.target.value)}
              variant="outlined"
              label="Enter your tiktok url here"
              sx={{ marginBottom: 2 }}
            />
            <DoneButton
              onClick={handleValidateAndSaveTiktok}
              disabled={!tiktokUrl || isLoading}
              style={
                tiktokUrl && !isLoading ? { opacity: 1 } : { opacity: 0.3 }
              }
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ color: '#fff', fontFamily: 'Poppins, sans-serif' }}
              >
                Add
              </Typography>
            </DoneButton>

            <div style={{ marginTop: '12px', height: '90px' }}>
              {isLoading ? (
                <CircularProgress sx={{ marginTop: '10px' }} />
              ) : isValidUrl === 'false' ? (
                <p
                  style={{
                    fontSize: '20px',
                    color: 'rgba(255, 0, 0, 1)',
                    textAlign: 'center',
                    fontWeight: 700,
                    padding: '10px',
                  }}
                >
                  Failed, please try again
                </p>
              ) : (
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '15px',
                    fontWeight: 700,
                    padding: '10px',
                  }}
                >
                  Please note that adding a TikTok Link will replace the medias
                  for this point.
                </p>
              )}
            </div>
          </DialogContent>
        )}
      </StyledDialog>
      {iframeSrc && (
        <iframe
          title="tiktok"
          src={iframeSrc}
          style={{ display: 'none' }}
        ></iframe>
      )}
    </>
  );
};
