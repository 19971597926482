import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverviewData } from '~/components/ViewTravel/MobileFooter/OverviewDataComponent';
import { EBottomSheetState } from '~/components/ViewTravel/MobileFooter/types';

export interface IUiState {
  bottomSheetOverviewData: OverviewData | null;
  isMarkerClicked: boolean;
  resetTrip: boolean;
  bottomSheetState: EBottomSheetState;
  showSignupDialog: boolean;
}

// NOTE: This is the slice for signals migration.
export interface IUiSlice {
  UiReducer: IUiState;
}

const initialState: IUiState = {
  bottomSheetOverviewData: null,
  isMarkerClicked: false,
  resetTrip: false,
  bottomSheetState: EBottomSheetState.hidden,
  showSignupDialog: false,
};

export const UiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    /**
     * @method fireSetIsMarkerClicked
     * @category Actions
     */
    fireSetIsMarkerClicked: (state, action: PayloadAction<boolean>) => {
      state.isMarkerClicked = action.payload;
    },
    /**
     * @method fireSetBottomSheetOverviewData
     * @category Actions
     */
    fireSetBottomSheetOverviewData: (
      state,
      action: PayloadAction<OverviewData | null>,
    ) => {
      state.bottomSheetOverviewData = action.payload;
    },

    /**
     * @method fireResetTrip
     * @category Actions
     */
    fireResetTrip: (state, action: PayloadAction<boolean>) => {
      state.resetTrip = action.payload;
    },

    /**
     * @method fireSetBottomSheetState
     * @category Actions
     */
    fireSetBottomSheetState: (
      state,
      action: PayloadAction<EBottomSheetState>,
    ) => {
      state.bottomSheetState = action.payload;
    },

    /**
     * @method fireShowSignupDialog
     * @category Actions
     */
    fireShowSignupDialog: (state, action: PayloadAction<boolean>) => {
      state.showSignupDialog = action.payload;
    },

    /**
     * @method fireCollapseBottomSheet
     * @category Actions
     */
    fireCollapseBottomSheet(state) {
      state.bottomSheetState = EBottomSheetState.hidden;
      state.bottomSheetOverviewData = null;
    },

    /**
     * @method fireOpenBottomSheet
     * @category Actions
     */
    fireOpenBottomSheet(
      state,
      action: PayloadAction<{ data: OverviewData; isMarker?: boolean }>,
    ) {
      state.bottomSheetOverviewData = action.payload.data;
      state.isMarkerClicked = action.payload.isMarker || false;
    },
  },
});

export const {
  fireSetIsMarkerClicked,
  fireSetBottomSheetOverviewData,
  fireResetTrip,
  fireSetBottomSheetState,
  fireShowSignupDialog,
  fireCollapseBottomSheet,
  fireOpenBottomSheet,
} = UiSlice.actions;

export const UiReducer = UiSlice.reducer;

export const selectIsMarkerClicked = (state: IUiSlice) =>
  state.UiReducer.isMarkerClicked;
export const selectBottomSheetOverviewData = (state: IUiSlice) =>
  state.UiReducer.bottomSheetOverviewData;
export const selectResetTrip = (state: IUiSlice) => state.UiReducer.resetTrip;
export const selectBottomSheetState = (state: IUiSlice) =>
  state.UiReducer.bottomSheetState;
export const selectShowSignupDialog = (state: IUiSlice) =>
  state.UiReducer.showSignupDialog;

export const selectIsBottomSheetFullyOpened = (state: IUiSlice) =>
  selectBottomSheetState(state) === EBottomSheetState.visible;
export const selectIsBottomSheetHalfOpened = (state: IUiSlice) =>
  selectBottomSheetState(state) === EBottomSheetState.half;
export const selectIsBottomSheetHidden = (state: IUiSlice) =>
  selectBottomSheetState(state) === EBottomSheetState.hidden;
