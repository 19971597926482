import timezone from 'dayjs/plugin/timezone';
import { store } from '../store';
import * as Types from '../actionTypes';
import { TravelOptions, TravelPointsByDayIndex } from '~/utility/models';
import { supabase } from '~/supabase/supabaseClient';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import {
  generateUUID,
  saveAndPublishTravelPointsFromP2P,
  transformedTravelPointsByDayIndex,
} from '~/utility/utils';
import { ICompletedPlaceP2P, IFormDataP2P } from '~/types/websocket';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { P2PManualProps } from '../reducers/P2PManualReducers';
import { MANUAL_HISTORY_TABLE_NAME } from '~/supabase/p2pHistory';
import { CityInterface } from '~/supabase/php2EditablePlaces';

dayjs.extend(utc);
dayjs.extend(timezone);

export const setTitleModalState = (value: boolean) =>
  store.dispatch({
    type: Types.TITLE_MODAL_STATE,
    isTitleModalOpened: value,
  });

export const setMapButtonState = (value: boolean) =>
  store.dispatch({
    type: Types.MAP_BUTTON_STATE,
    isMapButtonState: value,
  });

export const setHeaderState = (value: boolean) =>
  store.dispatch({
    type: Types.HEADER_STATE,
    isHeaderState: value,
  });

export const setMapState = (value: boolean) =>
  store.dispatch({
    type: Types.MAP_SELECTED,
    isMapSelected: value,
  });

export const setTravelTitle = (value: string) => {
  return store.dispatch({
    type: Types.TRAVEL_TITLE,
    travelTitle: value,
  });
};

export const setTravelPointsByDayIndex = (value: TravelPointsByDayIndex) =>
  store.dispatch({
    type: Types.TRAVEL_POINTS_BY_DAY_INDEX,
    travelPointsByDayIndex: value,
  });

export const setIsTravelPointSelectedState = (value: boolean) =>
  store.dispatch({
    type: Types.TRAVEL_POINT_SELECTED,
    isPointSelected: value,
  });

export const setIsSavingToHistory = (value: boolean) =>
  store.dispatch({
    type: Types.SAVING_TO_HISTORY_STATE,
    isSavingToHistory: value,
  });

export const setIsMapPointClicked = (value: boolean) =>
  store.dispatch({
    type: Types.MAP_POINT_SELECTED,
    isPointClicked: value,
  });

export const setIsMapPointSelectedIndex = (value: number) =>
  store.dispatch({
    type: Types.MAP_POINT_SELECTED_INDEX,
    mapPointSelectedIndex: value,
  });

export const setIsCreatingVisualization = (value: boolean) =>
  store.dispatch({
    type: Types.CREATING_VISUALIZATION_STATE,
    isCreatingVisualization: value,
  });

export const setIsTravelPointsUpdated = (value: boolean) =>
  store.dispatch({
    type: Types.TRAVEL_POINTS_UPDATED,
    isTravelPointsUpdated: value,
  });

export const setPointToAddIndex = (value: number) =>
  store.dispatch({
    type: Types.POINT_TO_ADD_INDEX,
    addAPointAtIndex: value,
  });

export const saveP2PTravelPoint = (value: TravelOptions[]) => {
  const travelPoints = [...value];
  // if travel points and existing points are equal return
  return async () => {
    const { P2PManualReducers, MapReducers } = store.getState();

    // Indicate saving process
    setIsSavingToHistory(true);

    const currentHistoryId = P2PManualReducers.currentHistoryId;
    const currentHistoryIdentifier = P2PManualReducers.currentHistoryIdentifier;
    const selectedCity: CityInterface =
      P2PManualReducers.selectedCity as CityInterface;
    const title = P2PManualReducers.travelTitle;

    try {
      if (currentHistoryId !== -1 || currentHistoryIdentifier) {
        // Update existing history
        await updateP2PHistory(
          currentHistoryId,
          travelPoints,
          title,
          currentHistoryIdentifier,
        );
      } else {
        // Add new history
        await addP2PToHistory(
          travelPoints,
          MapReducers.userEmail,
          MapReducers.userID,
          title,
          selectedCity,
        );
      }
    } catch (error) {
      console.error('Error saving travel points:', error);
    } finally {
      // Ensure state is reset
      setIsSavingToHistory(false);
    }
  };
};

export const updateP2PTravelPoint = (value: TravelOptions[]) => {
  const travelPoints = [...value];
  // if travel points and existing points are equal return
  return async () => {
    const { P2PManualReducers } = store.getState();

    // Indicate saving process
    setIsSavingToHistory(true);

    const currentHistoryId = P2PManualReducers.currentHistoryId;
    const title = P2PManualReducers.travelTitle;
    const currentHistoryIdentifier = P2PManualReducers.currentHistoryIdentifier;

    try {
      if (currentHistoryId !== -1) {
        // Update existing history
        await updateP2PHistory(
          currentHistoryId,
          travelPoints,
          title,
          currentHistoryIdentifier,
        );
      }
    } catch (error) {
      console.error('Error updating travel points:', error);
    } finally {
      // Ensure state is reset
      setIsSavingToHistory(false);
    }
  };
};

export const createP2PTravelPointVisualization = (
  value: TravelPointsByDayIndex,
) => {
  return async () => {
    const points = transformedTravelPointsByDayIndex(value);

    // Initialize completedPlaces with properties to store the arrays.
    const completedPlaces: ICompletedPlaceP2P = {
      arrivals: [] as IFormDataP2P[],
      departures: [] as IFormDataP2P[],
      selectedTransports: [] as TravelMode[],
      selectedTransportImages: [] as string[],
      dayIndexes: [] as number[],
      transportationCosts: [] as number[],
      title: '' as string,
      description: '' as string,
    };

    for (const point of points) {
      const {
        arrival,
        departure,
        selectedTransport,
        selectedTransportImages: images,
        dayIndex,
        transportationCost,
      } = point;

      // Push each data item into the appropriate array within completedPlaces.
      completedPlaces.departures.push(departure!);
      completedPlaces.arrivals.push(arrival!);
      completedPlaces.selectedTransports.push(selectedTransport);
      completedPlaces.selectedTransportImages.push(...images);
      completedPlaces.dayIndexes.push(dayIndex);
      completedPlaces.transportationCosts.push(transportationCost);
    }

    // setIsCreatingVisualization(true);
    setIsTravelPointsUpdated(false);

    saveAndPublishTravelPointsFromP2P({
      ...completedPlaces,
      selectedTransportImages: [],
      title: store.getState().P2PManualReducers.travelTitle ?? '',
      description: '',
    }).finally(() => {
      setIsCreatingVisualization(false);
      setIsTravelPointsUpdated(true);
    });
  };
};

// Action creator to update P2P to History and dispatch a proper action
export const addP2PToHistory = (
  travelPoints: TravelOptions[],
  email: string,
  id: string,
  title: string,
  city?: CityInterface,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // save to publish travel

      const { data, error } = await supabase
        .from(MANUAL_HISTORY_TABLE_NAME)
        .insert({
          travelPoints,
          city,
          email,
          UUID: id,
          title,
          identifier: generateUUID(),
        })
        .select();

      console.log({ data, error });
      const historyId = (data && data[0]?.id) ?? -1;
      const historyIdentifier = (data && data[0]?.identifier) ?? '';
      if (historyId !== -1) setCurrentHistoryId(historyId);
      if (historyIdentifier) setCurrentHistoryIdentifier(historyIdentifier);

      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

/**
 * Updates an existing entry in the "Manual History" table in Supabase.
 *
 * @param {string} id - The unique identifier of the entry to update.
 * @param {TravelOptions[]} travelPoints - An array of travel points to update in the record.
 * @returns {Promise<any>} - Resolves with the updated data if successful, or rejects with an error.
 */
export const updateP2PHistory = (
  id: number,
  travelPoints: TravelOptions[],
  title: string,
  currentHistoryIdentifier: string,
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await supabase
        .from(MANUAL_HISTORY_TABLE_NAME)
        .update({ travelPoints, title })
        .or(`id.eq.${id},identifier.eq.${currentHistoryIdentifier}`) // Match either `id` or `manual_history_identifier`
        .select();

      if (error) {
        console.log({ from: 'supabase update', error });
      }

      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const setCurrentHistoryId = (value: number) =>
  store.dispatch({
    type: Types.ADD_P2P_HISTORY_ID,
    currentHistoryId: value,
  });

export const setCurrentHistoryCreatedAt = (value: string) => {
  return store.dispatch({
    type: Types.HISTORY_CREATED_AT,
    created_at: value,
  });
};

export const setCurrentHistoryIdentifier = (value: string) => {
  return store.dispatch({
    type: Types.ADD_MANUAL_HISTORY_IDENTIFIER,
    currentHistoryIdentifier: value,
  });
};

export const setCurrentDayIndex = (value: number) => {
  return store.dispatch({
    type: Types.SET_CURRENT_DAY_INDEX,
    currentDayIndex: value,
    previousDayIndex: value > 1 ? value - 1 : value,
  });
};

export const setLastVisitedDayIndex = (value: number) => {
  return store.dispatch({
    type: Types.SET_LAST_VISITED_DAY_INDEX,
    lastVisitedDayIndex: value,
  });
};

export const setPreviousDayIndex = (value: number) => {
  return store.dispatch({
    type: Types.SET_PREVIOUS_DAY_INDEX,
    previousDayIndex: value,
  });
};

export const setSelectedCity = (value: CityInterface) => {
  return store.dispatch({
    type: Types.SET_SELECTED_CITY,
    selectedCity: value,
  });
};

export const restoreStateFromPrevious = (value: P2PManualProps) => {
  return store.dispatch({
    type: Types.RESTORE_FROM_PREVIOUS_STATE,
    ...value,
  });
};

export const setDefaultP2pManualState = () => {
  return store.dispatch({
    state: null,
    type: Types.SET_DEFAULT_P2P_MANUAL_STATE,
  });
};

export const saveToHistory = (value: TravelOptions[]) =>
  store.dispatch({
    type: Types.SAVE_TO_HISTORY,
    historyState: value,
  });

const P2PManualActions = {
  setTitleModalState,
  setTravelTitle,
  setTravelPointsByDayIndex,
  setMapButtonState,
  setHeaderState,
  setIsTravelPointSelectedState,
  setPointToAddIndex,
  saveP2PTravelPoint,
  addP2PToHistory,
  setIsSavingToHistory,
  createP2PTravelPointVisualization,
  setCurrentHistoryId,
  setCurrentDayIndex,
  setPreviousDayIndex,
  setLastVisitedDayIndex,
  restoreStateFromPrevious,
  setMapState,
  setIsMapPointClicked,
  setIsMapPointSelectedIndex,
  setDefaultP2pManualState,
  saveToHistory,
  setCurrentHistoryIdentifier,
  setCurrentHistoryCreatedAt,
  setIsTravelPointsUpdated,
  setIsCreatingVisualization,
  setSelectedCity,
  updateP2PTravelPoint,
};

export default P2PManualActions;
