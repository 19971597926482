// helper.ts

import { supabase } from '~/supabase/supabaseClient';
import { PublishableTravelObject } from '~/utility/models';

const TABLE_NAME = 'Publish Travel';

export async function getTravelItinerary(history_identifier: string) {
  try {
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .select(
        'id, publishable_data, UUID, published_id,manual_history_identifier, city',
      )
      .eq('manual_history_identifier', history_identifier)
      .single();

    if (data) {
      return data;
    }

    if (error) {
      return error;
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

export const getPublishedId = async (tourID: number) => {
  const { data } = await supabase
    .from(TABLE_NAME)
    .select('id')
    .eq('published_id', tourID);

  return data;
};

export const getTravelItineraryByTourID = async (tourID: number) => {
  const { data } = await supabase
    .from(TABLE_NAME)
    .select(
      'id,publishable_data, UUID, published_id, manual_history_identifier, city',
    )
    .eq('id', tourID);

  return data;
};

export const insertItinerary = async (
  travelObj: PublishableTravelObject,
  currentHistoryID: number,
  userId: string,
) => {
  const { data } = await supabase //cannot specify type for PostgrestSingleResponse from supabase
    .from(TABLE_NAME)
    .upsert({
      publishable_data: travelObj,
      published_id: currentHistoryID,
      UUID: userId,
    })
    .select()
    .single();

  return data;
};
