import './style.css';
import { memo } from 'react';
import CalendarLine, { } from '../../DesktopFooter/CalendarLine';
import { DAYJS } from '~/map/ViewTravel';

const Step2Mobile = ({ startDate, endDate, currentDate }: { startDate: Date, endDate: Date, currentDate: Date }) => {
  return (
    <CalendarLine
      startDate={startDate}
      endDate={endDate}
      currentDate={currentDate}
      mobile
    />
  )
}

export default memo(Step2Mobile);


export const formatCurrentDate = (date: string) => {
  let newDate = DAYJS(date);
  return newDate.format('YYYY-MM-DD');
};

