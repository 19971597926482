import { Button, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '~/components/NavbarNew';
import { showOverviewMenu } from '~/components/OverviewMenu';

import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';
import { encodeTourID } from '~/utility/utils';
import { lastTripTitleSignal } from '../P2PManualTitleModal';
import { isSyncingToServerSignal } from '..';
import { initialItineraryRender } from '~/map/ViewTravel';
import ROUTES from '~/routes';

interface P2PManualHeaderProps {
  onCancel: () => void;
}

export const P2PManualHeader: FC<P2PManualHeaderProps> = ({ onCancel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const travelTitle: string = useSelector(
    (state: RootState) => state.P2PManualReducers.travelTitle,
  );

  const isHeaderState: boolean = useSelector(
    (state: RootState) => state.P2PManualReducers.isHeaderState,
  );

  const publishId: number = useSelector(
    (state: RootState) => state.MapReducers.publishID,
  );

  const handleMapClicked = () => {
    // This prevents initially create visualization for a user who just opened the page.
    if (initialItineraryRender.peek()) {
      initialItineraryRender.value = false;
    }

    if (location.pathname === ROUTES.VIEWTRAVEL.path) {
      showOverviewMenu.value = false;
      return;
    }

    if (showOverviewMenu.value) {
      showOverviewMenu.value = false;
    } else {
      if (publishId > 0) {
        navigate(`${ROUTES.VIEWTRAVEL.path}?tourID=${encodeTourID(publishId)}`);
      }
    }
  };

  return (
    <>
      {isHeaderState ? (
        <div
          style={{
            display: 'flex',
            flex: '0 0 auto',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '12px 20px',
            height: 80,
            background: '#ffffff',
            width: '100%',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              pointerEvents: 'all',
            }}
          >
            <Navbar onlyBurger />
          </div>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              lastTripTitleSignal.value = travelTitle;
              dispatch(ActionsCreator.setTravelTitle(''));
            }}
          >
            {
              <Typography
                sx={{
                  fontSize: '20px',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  textAlign: 'center',
                  textTransform: 'capitalize',
                  cursor: 'pointer',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2, // Adjust the number of lines to truncate
                  overflow: 'hidden',
                  whiteSpace:
                    lastTripTitleSignal.peek().length || travelTitle.length > 20
                      ? 'normal'
                      : 'nowrap',
                  textOverflow:
                    lastTripTitleSignal.peek().length || travelTitle.length > 20
                      ? 'ellipsis'
                      : 'clip',
                }}
              >
                {travelTitle || lastTripTitleSignal.peek()}
              </Typography>
            }
          </div>
          <div>
            <SaveButton handleMapClicked={handleMapClicked} />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            background: '#ffffff',
            width: '100%',
            height: 80,
          }}
        >
          <img
            src="/closeSquare.svg"
            alt="Map Icon"
            style={{ width: 40, height: 40, cursor: 'pointer' }}
            onClick={() => {
              dispatch(ActionsCreator.setIsMapPointClicked(true));
              dispatch(ActionsCreator.setIsMapPointSelectedIndex(0));
              dispatch(ActionsCreator.setIsTravelPointSelectedState(true));
              dispatch(ActionsCreator.setHeaderState(true));
              dispatch(ActionsCreator.setMapButtonState(true));
              dispatch(ActionsCreator.setCurrentDayIndex(0));
            }}
          />
        </div>
      )}
    </>
  );
};

const SaveButton = ({ handleMapClicked }: { handleMapClicked: () => void }) => {
  const [loading, setLoading] = useState(false);
  const publishID = useSelector(
    (state: RootState) => state.MapReducers.publishID,
  );
  useEffect(() => {
    const unsub = isSyncingToServerSignal.subscribe((value) => {
      setLoading(value);
    });
    return () => {
      unsub();
    };
  }, []);
  return (
    <Button
      sx={{
        fontSize: '18px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        textTransform: 'capitalize',
        borderRadius: '14px',
        boxShadow:
          '0px 2px 6px 0px #b8c5d033, 0px 6px 22px 0px #b8c5d04d, 0px 9px 30px 0px #c6d8e933, inset 0px 1.5px 1.5px 0px #ffffff40',
        cursor: loading || publishID === 0 ? 'not-allowed' : 'pointer',
      }}
      onClick={handleMapClicked}
      disabled={loading || publishID === 0}
    >
      <img
        src="/mapIcon.svg"
        alt="Map Icon"
        style={{ width: 24, height: 24, marginRight: 6 }}
      />
      {loading ? 'Syncing...' : 'Map'}
    </Button>
  );
};
