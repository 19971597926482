import React from 'react';
import { Box, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import P2PModalTimePicker from '../../P2PModalTimePicker';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Initialize dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);

interface P2PTimeDisplayProps {
  title: string;
  selectedTime: Dayjs;
  type: 'start' | 'end';
  minTime: Dayjs;
  maxTime: Dayjs;
  handleSaveTime: (time: Dayjs, type: 'start' | 'end') => void;
  handleClick: () => void;
  handleShowFullEndTime?: () => void;
  text?: string;
}

export const P2PTimeDisplay: React.FC<P2PTimeDisplayProps> = ({
  title,
  selectedTime,
  minTime,
  maxTime,
  handleSaveTime,
  type,
  handleClick,
  text,
  handleShowFullEndTime,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      mb={2}
    >
      <Typography
        variant="h5"
        sx={{
          color: '#000',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '36px',
          mr: 2,
          mb: 2,
        }}
      >
        {title}:
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        mb={2}
      >
        <P2PModalTimePicker
          onSave={(time: Dayjs) => {
            handleSaveTime(time, type);
          }}
          handleClick={handleClick}
          time={selectedTime}
          minTime={minTime}
          maxTime={maxTime}
        />
      </Box>

      <Typography
        variant="h5"
        sx={{
          color: '#000',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '27px',
          mr: 2,
          mb: 2,
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => handleShowFullEndTime?.()}
      >
        {text}
      </Typography>
    </Box>
  );
};
