import { TravelPointsByDayIndex } from '~/utility/models';
import * as Types from '../actionTypes';
import { CityInterface } from '~/supabase/php2EditablePlaces';

export type P2PManualProps = {
  isTitleModalOpened: boolean;
  isMapButtonState: boolean;
  isHeaderState: boolean;
  travelTitle: string;
  travelPointsByDayIndex: TravelPointsByDayIndex;
  isPointSelected: boolean;
  isMapSelected: boolean;
  addAPointAtIndex: number;
  isSavingToHistory: boolean;
  isCreatingVisualization: boolean;
  isTravelPointsUpdated: boolean;
  currentHistoryId: number;
  previousDayIndex: number;
  currentDayIndex: number;
  lastVisitedDayIndex: number;
  isPointClicked: boolean;
  mapPointSelectedIndex: number;
  currentHistoryIdentifier: string;
  selectedCity: CityInterface | null;
  created_at: string;
};

type ActionProps = P2PManualProps & {
  type: string;
};

const initialState: P2PManualProps = {
  isTitleModalOpened: false,
  isMapButtonState: true,
  isHeaderState: true,
  travelTitle: '',
  travelPointsByDayIndex: {},
  isPointSelected: false,
  isMapSelected: false,
  addAPointAtIndex: -1,
  isSavingToHistory: false,
  isCreatingVisualization: false,
  isTravelPointsUpdated: false,
  isPointClicked: false,
  currentHistoryId: -1,
  previousDayIndex: 1,
  currentDayIndex: 1,
  lastVisitedDayIndex: 1,
  mapPointSelectedIndex: 0,
  currentHistoryIdentifier: '',
  selectedCity: null,
  created_at: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case Types.TITLE_MODAL_STATE:
      return {
        ...state,
        isTitleModalOpened: action.isTitleModalOpened,
      };
    case Types.MAP_BUTTON_STATE:
      return {
        ...state,
        isMapButtonState: action.isMapButtonState,
      };
    case Types.HEADER_STATE:
      return {
        ...state,
        isHeaderState: action.isHeaderState,
      };
    case Types.TRAVEL_TITLE:
      return {
        ...state,
        travelTitle: action.travelTitle,
      };

    case Types.TRAVEL_POINTS_BY_DAY_INDEX:
      return {
        ...state,
        travelPointsByDayIndex: action.travelPointsByDayIndex,
      };

    case Types.TRAVEL_POINT_SELECTED:
      return {
        ...state,
        isPointSelected: action.isPointSelected,
      };

    case Types.MAP_POINT_SELECTED:
      return {
        ...state,
        isPointClicked: action.isPointClicked,
      };

    case Types.MAP_POINT_SELECTED_INDEX:
      return {
        ...state,
        mapPointSelectedIndex: action.mapPointSelectedIndex,
      };

    case Types.MAP_SELECTED:
      return {
        ...state,
        isMapSelected: action.isMapSelected,
      };

    case Types.POINT_TO_ADD_INDEX:
      return {
        ...state,
        addAPointAtIndex: action.addAPointAtIndex,
      };
    case Types.SAVING_TO_HISTORY_STATE:
      return {
        ...state,
        isSavingToHistory: action.isSavingToHistory,
      };

    case Types.CREATING_VISUALIZATION_STATE: {
      return {
        ...state,
        isCreatingVisualization: action.isCreatingVisualization,
      };
    }

    case Types.TRAVEL_POINTS_UPDATED: {
      return {
        ...state,
        isTravelPointsUpdated: action.isTravelPointsUpdated,
      };
    }

    case Types.ADD_P2P_HISTORY_ID: {
      return {
        ...state,
        currentHistoryId: action.currentHistoryId,
      };
    }

    case Types.SET_CURRENT_DAY_INDEX: {
      return {
        ...state,
        previousDayIndex: action.previousDayIndex,
        currentDayIndex: action.currentDayIndex,
      };
    }

    case Types.SET_PREVIOUS_DAY_INDEX: {
      return {
        ...state,
        previousDayIndex: action.previousDayIndex,
      };
    }

    case Types.SET_LAST_VISITED_DAY_INDEX: {
      return {
        ...state,
        lastVisitedDayIndex: action.lastVisitedDayIndex,
      };
    }

    case Types.RESTORE_FROM_PREVIOUS_STATE: {
      const { type, ...rest } = action;
      return {
        ...state,
        ...rest,
      };
    }

    case Types.ADD_MANUAL_HISTORY_IDENTIFIER: {
      return {
        ...state,
        currentHistoryIdentifier: action.currentHistoryIdentifier,
      };
    }
    case Types.SET_SELECTED_CITY: {
      return {
        ...state,
        selectedCity: action.selectedCity,
      };
    }
    case Types.HISTORY_CREATED_AT: {
      return {
        ...state,
        created_at: action.created_at,
      };
    }
    case Types.SET_DEFAULT_P2P_MANUAL_STATE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
