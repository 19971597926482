import { GeoJSONSource, LngLatLike } from 'maplibre-gl';
import {
  AnimationConfig,
  LandTransportAnimationConfig,
} from './AnimationController';
import { LandTransportController } from './LandTransportController';
import { lineString, Position, length, nearestPointOnLine } from '@turf/turf';
import { Path, PathSegment } from '~/utility/models';
import { EasingFunctions } from './utility/easing';
import { handleAnimationState } from '~/components/ViewTravel/common';
import { mapOffset } from '~/components/ViewTravel/MobileFooter/BottomSheet';

/**
 * This class controls the animation of a car along a travel path on a map.
 * It inherits from the `LandTransportController` class and provides car-specific functionalities.
 */
class StraightLineController extends LandTransportController {
  animationConfig: AnimationConfig | undefined;
  isNextStateDestination: boolean = false;
  pointId: string | undefined;

  timeAtPause = 0;

  /**
   * Draw dotted straight line for a path segment of route
   *
   * @param animationConfig
   * Configuration object for the car animation
   * (specific to StraightLineController)
   *
   * @remarks
   * This function overrides the base class's `startAnimation` method.
   * It performs straightline-specific setup tasks in addition to the common
   * animation setup.
   */
  startAnimation() {
    this.initializeModelAnimation();
    handleAnimationState({
      type: 'line',
    });
    this.map.flyTo({
      center: this.currentSegment.lineString[
        this.currentSegment.lineString.length - 1
      ] as LngLatLike,
      duration: this.currentSegment.screenTime * 1000,
      easing: EasingFunctions.easeInOutCubic,
      essential: true,
      offset: mapOffset.value,
    });
  }

  setupAnimationTime() {}

  /**
   * Draw dotted straight line by calling update function of parent class
   *
   * @param delta The elapsed time since the last animation frame (in seconds)
   */
  update(delta: number) {
    // Check if animation has expired
    if (!this.isAnimationExpired) {
      // Get current time and elapsed time
      const now = performance.now();
      this.timeElapsed = now - this.animationStartTime;
      let timeProgress =
        this.timeElapsed /
        (this.animationConfig as LandTransportAnimationConfig).duration;

      if (timeProgress <= 1) {
        const line = lineString([
          this.currentSegment.lineString[0],
          this.currentSegment.lineString[
            this.currentSegment.lineString.length - 1
          ],
        ]);

        // nearestPointOnLine wont be a performance hit here as line has only two points
        const pointOnLine = nearestPointOnLine(
          line,
          this.map.getCenter().toArray(),
        );
        this.dynamicPath[1] = pointOnLine.geometry.coordinates;

        // Update the dynamic path line string
        this.updateLineString(this.dynamicPath);
      } else {
        this.onAnimationEnded();
      }
    } else {
      this.onAnimationCompleteCallback();
    }
  }

  /**
   * This function is called when animation of straight line ended
   */
  onAnimationEnded() {
    this.setAnimationExpired();
  }

  getIsNextStateDestination(isNextStateDestination: boolean) {
    this.isNextStateDestination = isNextStateDestination;
    this.pointId = this.isNextStateDestination ? 'destination' : 'origin';
  }

  /**
   * Set isAnimationExpired flag to true and call onAnimationCompleteCallback
   */
  setAnimationExpired = () => {
    this.isAnimationExpired = true;
    this.onAnimationCompleteCallback();
  };

  resetGeoJSONSources(path: Position[]): void {
    if (this.currentSegment)
      this.addTravelLayer(this.currentSegment.sourceID, []);
  }

  setupPath(): void {
    const travelSegment = this.travelSegment;
    if (travelSegment.decodedPath.path.length > 0) {
      this.path = this.generatePath(travelSegment.decodedPath.path) as any;

      this.currentSegment = this.path.end;
      this.dynamicPath = new Array(2).fill(this.currentSegment.lineString[0]);
    }
  }

  generatePath(path: Position[]) {
    const line = lineString(path);
    const totalDistance = length(line);

    const segment = {
      ...this.getSanitizedPathSegments(line.geometry.coordinates),
      lineString: line.geometry.coordinates,
      screenTime: (this.animationConfig?.duration as number) / 1000,
      realWorldDistance: totalDistance,
      sourceID: `${this.pointId ?? ''}end${this.index}`,
    } as PathSegment;

    return {
      start: undefined as any,
      middle: undefined as any,
      end: segment,
    } as Path;
  }

  addAnimationLineLayers() {
    if (this.path) {
      this.addTravelLayer(this.path.end.sourceID, []);
    }

    if (this.map.getLayer('custom-threejs-layer'))
      this.map.moveLayer('custom-threejs-layer');
  }

  /**
   * Add straight dotted line source and layer on the map
   * @param id id of the layer and source to be added
   * @param obj coordinates array
   */
  addTravelLayer(id: any, obj: Position[]) {
    if (!id) return;
    if (!this.map.getSource(id)) {
      this.map.addSource(id, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: obj,
          },
        },
      });

      this.map.addLayer({
        id: id,
        type: 'line',
        source: id,
        paint: {
          'line-color': '#FE7138',
          'line-width': 7,
          'line-dasharray': [0, 0.5, 1],
        },
      });
    } else {
      const source = this.map.getSource(id) as GeoJSONSource;
      source.setData({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: obj,
        },
      });
    }
  }

  onPause() {
    const now = performance.now();
    this.timeAtPause = now;
  }

  onPlay() {
    if (this.animationConfig) {
      const now = performance.now();
      const elapsedFromPauseTime = now - this.timeAtPause;
      this.animationStartTime += elapsedFromPauseTime;

      const remainingTime = this.animationConfig?.duration - this.timeElapsed;
      this.map.flyTo({
        center: this.currentSegment.lineString[
          this.currentSegment.lineString.length - 1
        ] as LngLatLike,
        duration: remainingTime,
        easing: EasingFunctions.easeInOutCubic,
        essential: true,
        offset: mapOffset.value,
      });
    }
  }
}

export { StraightLineController };
