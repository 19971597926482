// React imports
import React, { useState } from 'react';

// Redux Imports
import { RootState, useSelector } from '~/redux/reducers';

// Style Imports
import useStyles from '../styles';

// Redux Imports
import { useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';

// Animation Engine Imports
import {
  PlaneModels,
  planeModelsConfiguration,
} from '~/animationEngine/utility/enums/PlaneModels';

// MUI Imports
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ModelKeys } from '~/utility/models';

/**
 * This is PlaneModelSelector component and a child component of VideoPopup
 * @returns {JSX.Element} Returns PlaneModelSelector component
 */
const PlaneModelSelector = () => {
  const planeModelEnum: ModelKeys[] = useSelector(
    (state: RootState) => state.MapReducers.planeModelEnum,
  );
  const [selectedModel, setSelectedModel] = useState<ModelKeys>(
    planeModelEnum[0],
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  /**
   * This function handles model change and set selected model
   */
  const handleModelChange = (event: SelectChangeEvent<ModelKeys>) => {
    dispatch(
      ActionsCreator.selectPlaneModel([event.target.value as ModelKeys]),
    );
    setSelectedModel(event.target.value as ModelKeys);
  };

  const menuItemsConfig = Object.values(PlaneModels).map((value) => {
    const modelEnum = value as PlaneModels; // Assuming models is your enum
    const config = planeModelsConfiguration.get(modelEnum);

    if (config && config.UIname && config.name) {
      return { label: config.UIname, value: modelEnum };
    }

    return null;
  });

  return (
    <div>
      <Select
        value={selectedModel}
        onChange={handleModelChange}
        displayEmpty
        className={`${classes.selectEmpty} ${classes.planeSelector}`}
        MenuProps={{
          classes: {
            paper: classes.paper,
          },
        }}
        inputProps={{
          classes: {
            root: classes.input,
          },
        }}
      >
        {menuItemsConfig.map((item) => (
          <MenuItem
            className={classes.selectItem}
            key={item?.value}
            value={item?.value}
          >
            {item?.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default PlaneModelSelector;
