import { Modal } from '@mui/material';
import React from 'react';
import { useSelector } from '~/redux/reducers';
import ActionsCreator from '~redux/actions';
import { useDispatch } from '~redux/store';
import VideoPopup from '~/components/VideoPopup';
import { RootState } from '~/redux/reducers';

/**
 * VideoPopupContainer component - Container for the Video Popup.
 *
 * @component
 * @returns {React.ReactElement} - Rendered component
 */
const VideoPopupContainer = (): React.ReactElement => {
  const dispatch = useDispatch();

  // Get the state of the video popup and recording status from the store
  const openVideoPopup = useSelector(
    (state: RootState) => state.MapReducers.videoPopupState,
  );
  const isRecording = useSelector(
    (state: RootState) => state.MapReducers.isRecording,
  );

  /**
   * handleClose - Closes the video popup if not recording.
   */
  const handleClose = () => {
    if (isRecording) return;
    dispatch(ActionsCreator.openVideoPopup(false));
    dispatch(ActionsCreator.setIsAnimationCapture(false));
    dispatch(ActionsCreator.setFullscreenAnimationMode(false));
  };

  return (
    <Modal
      open={openVideoPopup}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <VideoPopup handleClose={handleClose} />
      </div>
    </Modal>
  );
};

export default VideoPopupContainer;
