interface HadleShareProps {
  text?: string;
  url?: string;
  title?: string;
}

export const handleShare = async (props: HadleShareProps = {}) => {
  const defaultTitle = `Trip Itinerary: `;
  const {
    text = defaultTitle,
    title = defaultTitle,
    url = `${window.location.href}`,
  } = props;
  try {
    await navigator.share({
      text,
      url,
      title,
    });
  } catch (error) {}
};
