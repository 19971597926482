import { TravelOptions, TravelPointsByDayIndex } from '~/utility/models';
import {
  IPointInfo,
  IPointInfoResponse,
} from '../P2PSearchAutocomplete/helpers';
import { requestInstance } from '~/config';
import { store } from '~/redux/store';
import ActionsCreator from '~/redux/actions';
import {
  addNewEditablePoint,
  updateMultipleEditablePlaces,
} from '~/supabase/php2EditablePlaces';
import { EditablePlacesKnownKeys } from '../P2PTravelBuilder/P2PPointsViewer/P2PTravelPoint/P2PTravelPointEditDialog';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import { fetchPlaceInfoSignal } from '~/components/signals/placeInfoSignal';

export const EDITABLE_PLACE_ID_KEY = 'editableplaceId';

export interface transportMapInterface {
  label: string;
  icon: string;
}
export const transportMap: transportMapInterface[] = [
  {
    label: 'Walk',
    icon: '/walk.svg',
  },
  {
    label: 'Drive',
    icon: '/drive.svg',
  },
  {
    label: 'Transit',
    icon: '/transit.svg',
  },
  {
    label: 'Plane',
    icon: '/flight.svg',
  },
  {
    label: 'Ferry',
    icon: '/ferry.svg',
  },
];

export const handlePlaceInfo = async (travelPoint: TravelOptions) => {
  // Get the current day index

  const { id: selectedPointId, dayIndex, editableplaceId } = travelPoint;

  try {
    const { value, label, city, street, country } = travelPoint;

    const payload: IPointInfo = {
      city: city!,
      street: street!,
      country: country!,
      name: value! ?? label!,
    };

    fetchPlaceInfoSignal.value = true;

    const response = await requestInstance.post('place-info', payload);

    const result = response?.data?.resource as IPointInfoResponse;

    const travelPointsByDayIndex =
      store.getState().P2PManualReducers.travelPointsByDayIndex;
    const pointsByDay = travelPointsByDayIndex[dayIndex];

    const currentPointIndex = pointsByDay.findIndex(
      (point) => point.id === travelPoint.id,
    );

    if (currentPointIndex !== -1) {
      if (result) {
        const updatedProperties = {
          review: result?.review || '',
          longDescription: result?.longDescription || '',
          description: result?.description || '',
          cost: result?.cost || 0,
        };

        let updatedTravelPoint = {
          ...travelPoint,
          ...pointsByDay[currentPointIndex],
          ...updatedProperties,
        } as TravelOptions;

        updatedTravelPoint = await updateOrCreateEditablePoint(
          editableplaceId,
          updatedProperties,
          travelPointsByDayIndex,
          dayIndex,
          selectedPointId,
        );

        let updatedDayPoints = [...(travelPointsByDayIndex[dayIndex] || [])];

        updatedDayPoints = updatedDayPoints.map((p) =>
          p.id === updatedTravelPoint.id ? updatedTravelPoint : p,
        );

        let updatedTravelPointsByDayIndex = { ...travelPointsByDayIndex };

        updatedTravelPointsByDayIndex = {
          ...updatedTravelPointsByDayIndex,
          [dayIndex]: updatedDayPoints,
        };

        // Dispatch updated travel point
        store.dispatch(
          ActionsCreator.setTravelPointsByDayIndex(
            updatedTravelPointsByDayIndex,
          ),
        );
      }
    }
  } catch (error) {
    console.error('Error fetching place info:', error);
  } finally {
    fetchPlaceInfoSignal.value = false;
  }
};

export async function updateOrCreateEditablePoint(
  editableplaceId: string | number | null | undefined,
  updatedProperties: Record<string, any>,
  travelPointsByDayIndex: TravelPointsByDayIndex,
  dayIndex: number,
  selectedPointId: string | number,
): Promise<TravelOptions> {
  let newProperties = {
    ...updatedProperties,
  } as TravelOptions;

  if (editableplaceId) {
    const hasAdded = await updateMultipleEditablePlaces(
      editableplaceId,
      updatedProperties,
    );

    // Check if the editable place was deleted or does not exist anymore
    if (hasAdded && hasAdded.length === 0) {
      // Find the existing point and update it
      const existingPoint: Partial<TravelOptions> =
        travelPointsByDayIndex[dayIndex]?.find(
          (p) => p.id === selectedPointId,
        ) || {};

      const updatedProps = Object.keys(existingPoint).reduce((acc, key) => {
        acc[key as EditablePlacesKnownKeys] =
          updatedProperties[key as EditablePlacesKnownKeys] ||
          existingPoint[key as EditablePlacesKnownKeys] ||
          '';
        return acc;
      }, {} as Record<string, any>);

      const newPoint = {
        ...existingPoint,
        ...updatedProps,
        ...updatedProperties,
      } as TravelOptions;

      // Remove editableplaceId from the point and add it again
      delete newPoint.editableplaceId;

      const newEditablePoint = await addNewEditablePoint(newPoint);

      if (newEditablePoint.id) {
        newProperties = {
          ...newPoint,
          [EDITABLE_PLACE_ID_KEY]: newEditablePoint.id,
        };
      }
    }
  } else {
    // If no editableplaceId, create a new editable point
    const existingPoint: Partial<TravelOptions> =
      travelPointsByDayIndex[dayIndex]?.find((p) => p.id === selectedPointId) ||
      {};

    const updatedProps = Object.keys(existingPoint).reduce((acc, key) => {
      acc[key as EditablePlacesKnownKeys] =
        updatedProperties[key as EditablePlacesKnownKeys] ||
        existingPoint[key as EditablePlacesKnownKeys] ||
        '';
      return acc;
    }, {} as Record<string, any>);

    const newPoint = {
      ...existingPoint,
      ...updatedProps,
      ...updatedProperties,
    } as TravelOptions;

    const newEditablePoint = await addNewEditablePoint(newPoint);

    if (newEditablePoint.id) {
      newProperties = {
        ...newPoint,
        [EDITABLE_PLACE_ID_KEY]: newEditablePoint.id,
      };
    }
  }

  return newProperties;
}
