import React from 'react';
import Settings from '~/components/Settings';
import Navbar from '~/components/NavbarNew';
import FeedbackPopup from '~/components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';

/**
 * SettingsPageContainer component - Container for the Settings page.
 *
 * @component
 * @returns {React.ReactElement} - Rendered component
 */
const SettingsPageContainer = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          background: '#ffffff',
        }}
      >
        <Navbar
          onBackButtonClick={() => {
            navigate('/home');
          }}
        />
      </div>
      <FeedbackPopup />
      <Settings />
    </>
  );
};

export default SettingsPageContainer;
