import { Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { CSSProperties, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MANUAL_TRIP_HISTORY } from '~/components/P2PManual';
import ActionsCreator from '~/redux/actions';
import { store } from '~/redux/store';
import ROUTES from '~/routes';
import {
  ITravelFormHistoryData,
  TravelOptions,
  TravelPointsByDayIndex,
} from '~/utility/models';
import {
  calculateDaysDifference,
  encodeTourID,
  groupAndSortTravelPointsByDay,
} from '~/utility/utils';

const getFirstMediaThumbnail = (points: TravelOptions[]) => {
  for (const point of points) {
    if (point.tiktokData && point.tiktokData.length > 0) {
      return point.tiktokData[0].thumbnail;
    }
    if (point.media && point.media.length > 0) {
      return point.media[0].thumbnail;
    }
  }
  return null;
};

const HistoryItem = ({ item }: { item: ITravelFormHistoryData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const travelPoints = useMemo(
    () => item?.travelPoints || [],
    [item?.travelPoints],
  );
  const firstPoint = travelPoints[0] || null;
  const lastPoint = travelPoints[travelPoints.length - 1] || null;

  // Extract Timezone from the first travel point and the last travel point
  const firstPointTimezone = firstPoint?.timezone;
  const lastPointTimezone = lastPoint?.timezone;

  const from = firstPoint?.startDate;
  const to = lastPoint?.endDate;

  const numberOfDays = useMemo(
    () =>
      calculateDaysDifference(from, to, firstPointTimezone, lastPointTimezone),
    [from, to, firstPointTimezone, lastPointTimezone],
  );

  const thumbnail = useMemo(
    () => getFirstMediaThumbnail(travelPoints) || '/mapIcon.svg',
    [travelPoints],
  );

  // Extract the city name from the item (if it exists)
  const cityName = item?.city?.city; // Assuming city is of type CityInterface and has a name property
  const countryName = item?.city?.country;

  const editTrip = async (item: ITravelFormHistoryData) => {
    const { travelPoints: items, publish_travel_table } = item as {
      travelPoints: TravelOptions[];
      publish_travel_table: { id: number };
    };

    if (item?.identifier) {
      const travelPointsByDayIndex: TravelPointsByDayIndex =
        groupAndSortTravelPointsByDay(items);

      dispatch(ActionsCreator.setDefaultP2pManualState());
      sessionStorage.removeItem(MANUAL_TRIP_HISTORY);
      dispatch(ActionsCreator.setPulishedTravelId(0));
      dispatch(ActionsCreator.setTravelData([]));
      dispatch(ActionsCreator.setTravelTitle(''));
      dispatch(ActionsCreator.setTravelRawData({}));
      dispatch(ActionsCreator.setPublishedTravelLink(''));
      dispatch(ActionsCreator.setIsTravelPointSelectedState(true));
      dispatch(
        ActionsCreator.setCurrentDayIndex(
          items[items.length - 1]?.dayIndex || 1,
        ),
      );
      dispatch(ActionsCreator.setSelectedCity(item.city));
      dispatch(
        ActionsCreator.setLastVisitedDayIndex(
          items[items.length - 1]?.dayIndex || 1,
        ),
      );

      dispatch(ActionsCreator.setCurrentHistoryId(item.id));
      dispatch(ActionsCreator.setCurrentHistoryIdentifier(item?.identifier!));

      dispatch(ActionsCreator.setTravelTitle(item.title));
      dispatch(
        ActionsCreator.setTravelPointsByDayIndex(travelPointsByDayIndex),
      );

      // Save state to session storage
      sessionStorage.setItem(
        MANUAL_TRIP_HISTORY,
        JSON.stringify(store.getState().P2PManualReducers),
      );

      // Navigate to manual trip page
      navigate(
        `${ROUTES.VIEWTRAVEL.path}?tourID=${encodeTourID(
          publish_travel_table.id,
        )}`,
      );
    }
  };

  const thumbnailStyles = useMemo(() => {
    let currentStyles: CSSProperties = {
      borderRadius: '16px',
      maxWidth: '82px',
      width: '100vw',
      height: '100px',
    };
    if (thumbnail) {
      currentStyles = {
        ...currentStyles,
        backgroundImage: `url(${thumbnail})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      };
    }

    if (!thumbnail) {
      currentStyles = {
        ...currentStyles,
        backgroundColor: '#fff',
        border: '2px solid #eee',
      };
    }

    return currentStyles;
  }, [thumbnail]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1.2rem',
      }}
    >
      <Box sx={thumbnailStyles}></Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        <Typography
          sx={{
            color: '#2C3624',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          {item?.title ? item?.title : 'Trip'}
        </Typography>
        <Typography
          sx={{
            color: '#676C5E',
            fontWeight: 200,
            fontSize: '12px',
            lineHeight: '18px',
          }}
        >
          {cityName || firstPoint?.city
            ? `${cityName || firstPoint?.city}, `
            : ''}
          {countryName || firstPoint?.country}
        </Typography>
        <Typography
          sx={{
            color: '#676C5E',
            fontWeight: 200,
            fontSize: '12px',
            lineHeight: '18px',
          }}
        >
          {`${numberOfDays} Day${numberOfDays > 1 ? 's' : ''} · ${dayjs
            .utc(from)
            .tz(firstPointTimezone)
            .format('D MMM')} - ${dayjs
            .utc(to)
            .tz(lastPointTimezone)
            .format('D MMM, YYYY')}`}
        </Typography>
        <Button
          sx={{
            background:
              'radial-gradient(100% 100% at 0% 0%, #FF9345 0.01%, #FE7138 50%, #F25D21 100%)',
            borderRadius: '18px',
            fontSize: '16px',
            fontWeight: 700,
            textTransform: 'none',
            boxShadow:
              '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px 4px',
            maxWidth: 'max-content',
          }}
          onClick={() => editTrip(item)}
          disabled={loading}
        >
          Edit
        </Button>
      </Box>
    </Box>
  );
};

export default HistoryItem;
