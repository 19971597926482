import { AnimationState } from '~/utility/models';
import * as THREE from 'three';
import { CharacterStateMachine } from '../CharacterStateMachine';

export class DisappearAnimation implements AnimationState {
  private action: THREE.AnimationAction;
  private stateMachine: CharacterStateMachine;

  constructor(
    private mixer: THREE.AnimationMixer,
    clip: THREE.AnimationClip,
    stateMachine: CharacterStateMachine,
  ) {
    this.action = this.mixer.clipAction(clip);
    this.onFinished = this.onFinished.bind(this);
    this.stateMachine = stateMachine;
  }

  onEnter(): void {
    console.log('Entering disappear state');
    this.action.reset().setLoop(THREE.LoopOnce, 1).setEffectiveWeight(1).play();

    this.action.clampWhenFinished = true;
    this.action.getMixer().addEventListener('finished', this.onFinished);
  }

  onExit(): void {
    console.log('Exiting disappear state');
    this.action.setEffectiveWeight(1).stop();
    this.action.getMixer().removeEventListener('finished', this.onFinished);
  }

  onUpdate(delta: number): void {
    this.mixer.update(delta);
  }

  private onFinished(event: any): void {
    if (event.action === this.action) {
      this.onExit();
      this.stateMachine.controller.setAnimationExpired();
    }
  }
}
