import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import './InfoFooter.css';
import { Box } from '@mui/material';
import { AddToTripDialog, ITodoTravelPoint } from '~/components/AddToTrip';
import { useEffect, useState } from 'react';
import { isAuthenticated } from '~/components/ViewTravel/MobileFooter/BottomSheet';
import SignupDialog from '~/components/P2PAI/MapPreview/SignUpDialog';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import parse from 'html-react-parser';
import { getDefaultMarkerSvgString } from '~/utility/utils';


export const InfoFooter = ({
  content,
  showDetails,
  playVideo,
  togglePlay,
  isHideVisible,
  isInfoFooterVisible,
  setIsInfoFooterVisible,
  isViewTravel,
  childTitle,
  point,
}: {
  content?: any;
  showDetails: () => void;
  playVideo: () => void;
  togglePlay: () => void;
  isHideVisible: boolean;
  isInfoFooterVisible: boolean;
  setIsInfoFooterVisible: (isVisible: boolean) => void;
  isViewTravel?: boolean;
  childTitle?: string;
  point?: ITodoTravelPoint;
}) => {
  const isMobile = useIsMobile();

  const userId = useSelector((state: RootState) => state.MapReducers.userID);
  const tripUserId = useSelector(
    (state: RootState) => state.TripInfoReducers.travelRawData?.userId,
  );

  const scaleValue =
    window.innerWidth > 500 ? 1 : window.innerWidth > 400 ? 0.9 : 0.8;

  const [open, setOpen] = useState<boolean>(false);
  const [showSignupDialog, setShowSignupDialog] = useState(false);
  const [isAdded, setIsAdded] = useState<boolean>(false); // Tracks if the item is added

  const onClose = () => {
    setShowSignupDialog(false);
    if (isAuthenticated()) {
      onToggleOpen();
    }
  };

  const onToggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleAddToTrip = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (isAuthenticated()) {
      onToggleOpen();
    } else {
      setShowSignupDialog(true);
      return;
    }
  };

  useEffect(() => {
    setIsAdded(false);
  }, [point]);

  return (
    <div
      style={
        isViewTravel && !isMobile
          ? {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'column',
          }
          : {
            position: 'fixed',
            bottom: 0,
            left: '50%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'column',
            zIndex: 99,
          }
      }
    // onClick={(e) => e.stopPropagation()}
    >
      {isHideVisible && (
        <div
          className="wrapper-info-footer"
          style={{ transform: `scale(${scaleValue})` }}
          onClick={
            !isHideVisible
              ? () => { }
              : (e) => {
                e.stopPropagation();
                togglePlay();
              }
          }
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              padding: '.6rem',
            }}
          >
            <div
              className={`info-footer-add-trip-wrapper ${!isMobile && 'desktop'
                }`}
            >
              {isViewTravel ? (
                <>
                  {userId === tripUserId && isAuthenticated() && (
                    <span
                      className="custom-badge info-footer-hide-show"
                      onClick={handleAddToTrip}
                      style={
                        isAdded
                          ? {
                            backgroundColor: '#fe7138',
                            color: '#fff',
                          }
                          : {}
                      }
                    >
                      {isAdded ? 'Added' : 'Add to Trip'}
                    </span>
                  )}
                </>
              ) : (
                <span
                  className="custom-badge info-footer-hide-show"
                  onClick={handleAddToTrip}
                  style={
                    isAdded
                      ? {
                        backgroundColor: '#fe7138',
                        color: '#fff',
                      }
                      : {}
                  }
                >
                  {isAdded ? 'Added' : 'Add to Trip'}
                </span>
              )}
            </div>
            <div
              className={`info-footer-hide-show-wrapper ${!isMobile && 'desktop'
                }`}
            >
              <span
                className="custom-badge info-footer-hide-show"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsInfoFooterVisible(!isInfoFooterVisible);
                  isInfoFooterVisible && playVideo();
                }}
              >
                {isInfoFooterVisible ? 'Hide' : 'Show'}
              </span>
            </div>
          </Box>
          {isHideVisible && (
            <div
              onClick={(e) => {
                if (isInfoFooterVisible) {
                  e.stopPropagation();
                }
              }}
              style={{
                height: '200px',
                width: isInfoFooterVisible ? '100%' : '1px',
              }}
            >
              {isInfoFooterVisible && (
                <div
                  className={`info-footer-bottom-wrapper ${!isMobile && 'desktop'
                    }`}
                >
                  <>
                    <div className="info-footer">
                      <div className="info-footer-content">
                        <div className="info-footer-image">
                          {parse(getDefaultMarkerSvgString(isViewTravel ? point?.todoCategory ?? point?.typeOfPoint : content.category))}
                        </div>
                        <div className="info-footer-item">
                          <div className="info-footer-place">in Tokyo</div>
                          <div className="info-footer-name">
                            {isViewTravel ? point?.value : content.name}
                          </div>
                        </div>
                        <div className="info-footer-estimate">
                          <div className="info-footer-review">
                            {isViewTravel ? point?.review : content.review} ⭐
                          </div>
                          {(!!content.price || !!point?.cost) && (
                            <div className="info-footer-price">
                              <div className="info-footer-price-value">
                                ~US$
                                {isViewTravel ? point?.cost : content.price}
                              </div>
                              <div>(estimated)</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="footer-info-buttons">
                        <div
                          className="custom-badge info-footer-details"
                          onClick={showDetails}
                        >
                          More Details
                        </div>
                        <a
                          className="custom-badge active info-footer-site"
                          href={point?.urlBookPlace ?? content.url_book_place}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Website
                        </a>
                      </div>
                      <div className="info-footer-desc">{`“${isViewTravel ? point?.description : content.summary
                        }”`}</div>
                    </div>
                  </>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <a
        style={{
          width: isMobile ? '50px' : '100px',
          background: 'white',
          height: isMobile ? '210px' : '240px',
          position: 'fixed',
          zIndex: 7,
          bottom: 0,
          right: 0,
          opacity: 0,
        }}
        target="_blank"
        rel="noreferrer"
        href={
          isViewTravel ? point?.tiktokData[0].thumbnail : content.media[0].url
        }
      ></a>

      {open && (
        <AddToTripDialog
          open={open}
          onClose={(updateIsAdded) => {
            if (updateIsAdded) {
              setIsAdded(true);
            }
            setOpen(false);
          }}
          content={content}
          point={point}
          isViewTravel={isViewTravel}
          title={childTitle}
        />
      )}

      {showSignupDialog && (
        <SignupDialog
          open={showSignupDialog}
          onClose={onClose}
          title="To Add To Trip..."
        />
      )}
    </div>
  );
};
