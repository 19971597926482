import React from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useStyles from './styles';
import VideoThumbnailPreview from './VideoThumbnailPreview';
import { IMedia } from '~/utility/models';
import { validVideoTypes } from './constants';

dayjs.extend(utc);
dayjs.extend(timezone);

interface VideoContainerProps {
  videos: IMedia[];
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveVideo: (index: number) => void;
  isDisabled: boolean;
  isLoading: boolean;
  displayOnly?: boolean;
}

const VideoContainer: React.FC<VideoContainerProps> = ({
  onFileChange,
  handleRemoveVideo,
  isDisabled,
  videos,
  isLoading,
  displayOnly,
}) => {
  const classes = useStyles();

  return (
    <div style={{ paddingTop: '10px' }}>
      <Typography
        component="span"
        sx={{
          opacity: isDisabled ? 0.3 : 1,
          color: '#000000',
          fontSize: { xs: '14px', sm: '14px', md: '16px' }, // Responsive font size
          marginLeft: '.4rem',
          fontWeight: 700,
          lineHeight: '30px',
          fontFamily: 'Poppins, sans-serif',
          marginBottom: '1rem',
          display: 'inline', // Ensures it behaves like a span
        }}
      >
        Upload Short Videos (max 5 MB){' '}
      </Typography>
      <Grid container spacing={2} gap={2} sx={{ marginTop: '.2rem' }}>
        {videos?.length > 0 &&
          videos.map((video, index) => (
            <Grid item key={index} className={classes.videoPreview}>
              <VideoThumbnailPreview
                videoSrc={video.url}
                thumbnailSrc={video.thumbnail}
                handleRemoveVideo={handleRemoveVideo}
                displayOnly={displayOnly}
                index={index}
                isDisabled={isDisabled}
                isLoading={isLoading}
              />
            </Grid>
          ))}

        {!displayOnly && (
          <Grid item>
            <input
              accept={validVideoTypes.join(', ')}
              style={{ display: 'none' }}
              id={`file-input-video`}
              type="file"
              multiple
              onChange={onFileChange}
              disabled={videos.length >= 3 || isDisabled}
            />
            <label htmlFor={`file-input-video`}>
              <div
                className={
                  videos.length >= 3
                    ? classes.fileInputBoxDisabled
                    : isDisabled || isLoading
                    ? classes.disableFileUpload
                    : classes.fileInputBox
                }
              >
                {isLoading ? (
                  <CircularProgress size={26} thickness={2} />
                ) : (
                  <img
                    src="/icons/add-media.svg"
                    alt="Click to add images"
                    aria-label="Click to add images"
                  />
                )}
              </div>
            </label>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default VideoContainer;
