import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Navbar from '../NavbarNew';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';
import FeedbackPopup from '../FeedbackPopup';

import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';
import ROUTES from '~/routes';
import { ICompletedPlace, IProcessedPlace } from '~/types/websocket';

import {
  NAVIGATE_TO,
  PREVIOUS_ITINERARIES_KEY,
} from './ActionConfirmationDialog';
import { itinerarySignal } from '../signals/itinerary/itinerarySignal';
import { messagesSignal } from '../signals/itinerary/messagesSignal';
import { dayCounterSignal } from '../P2PAI';
import ActionConfirmationDialog from './ActionConfirmationDialog';
import { MANUAL_TRIP_HISTORY } from '../P2PManual';
import P2PHistory from './P2PHistory';
import { addP2PToHistory } from '~/supabase/p2pHistory';
import { P2PCityAutocomplete } from '../P2PManual/P2PCityAutocomplete';
import { CityInterface } from '~/supabase/php2EditablePlaces';
import { encodeTourID, showError } from '~/utility/utils';
import { publishManualTravelPoints } from '~/redux/actions/MapActions';

import './style.css';

export const SELECTED_CITY = 'SELECTED_CITY';

const Home = () => {
  const dispatch = useDispatch();

  const userName: string = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  const [creationInProgress, setCreatingInProgress] = useState<boolean>(false);
  const [citySearchPopup, setCitySearchPopup] = useState<boolean>(false);

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleModalClose = () => {
    dispatch(ActionsCreator.setTitleModalState(false));
  };

  const { userID, userEmail }: { userID: string; userEmail: string } =
    useSelector((state: RootState) => state.MapReducers);

  const handleTitleSave = async (city: CityInterface) => {
    const { city: title } = city;

    setCreatingInProgress(true);

    try {
      addP2PToHistory([], city, userEmail, userID, title)
        .then((res) => {
          if (res) {
            publishManualTravelPoints(
              {
                travelPoints: [],
                mapStyleIndex: 1,
                title,
              },
              userID,
              res.identifier,
              0,
              city,
            ).then((publishId: number) => {
              if (publishId)
                dispatch(
                  ActionsCreator.setCurrentHistoryIdentifier(res.identifier),
                );

              dispatch(ActionsCreator.setTravelTitle(title));
              dispatch(ActionsCreator.setSelectedCity(city));
              return navigate(
                `${ROUTES.VIEWTRAVEL.path}?tourID=${encodeTourID(publishId)}`,
              );
            });
          }

          // dispatch(ActionsCreator.setPublishedTravelLink(''));
          // dispatch(ActionsCreator.setPulishedTravelId(0));
          // dispatch(ActionsCreator.setTravelPointsByDayIndex({}));
          // dispatch(ActionsCreator.setCurrentHistoryId(res.id));
        })
        .catch((error) => {
          showError('Something went wrong. Please try again...');
          console.log(error, ' error here');
        })
        .finally(() => {
          setCreatingInProgress(false);
        });
    } catch (err) {
      showError('Something went wrong. Please try again...');
      setCreatingInProgress(false);
      console.log(err, 'err');
    }
  };

  const [actionConfirmationDialog, setActionConfirmationDialog] =
    useState(false);

  const updateActionConfirmationDialog = useCallback(() => {
    setActionConfirmationDialog((prev) => !prev);
  }, []);

  useLayoutEffect(() => {
    const previousItineraries = sessionStorage.getItem(
      PREVIOUS_ITINERARIES_KEY,
    );
    if (
      previousItineraries &&
      sessionStorage.getItem(NAVIGATE_TO) === ROUTES.P2PAI.path
    ) {
      const previousItinerariesJSON: {
        currentDay: number;
        messages: IProcessedPlace[];
        itineraries: ICompletedPlace[];
        startArea: string;
        startPoint: string;
        startDate: string;
      } = JSON.parse(previousItineraries);
      const { currentDay, messages, itineraries } = previousItinerariesJSON;
      itinerarySignal.value = itineraries;
      messagesSignal.value = messages;
      dayCounterSignal.value = currentDay;
      updateActionConfirmationDialog();
      return;
    }

    if (sessionStorage.getItem(NAVIGATE_TO) === ROUTES.P2PManual.path) {
      updateActionConfirmationDialog();
    }
  }, []);

  const props = useMemo(
    () => ({
      open: actionConfirmationDialog,
      onClose: updateActionConfirmationDialog,
      onConfirmNavigateTo:
        sessionStorage.getItem(NAVIGATE_TO) ?? ROUTES.P2PAI.path,
    }),
    [actionConfirmationDialog, updateActionConfirmationDialog],
  );

  const handleGenerateNewTrip = async () => {
    // Remove previous trip history
    sessionStorage.removeItem(MANUAL_TRIP_HISTORY);
    dispatch(ActionsCreator.setIsTravelPointSelectedState(false));
    dispatch(ActionsCreator.setTitleModalState(true));
  };

  return (
    <div
      style={{
        background: 'white',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flex: 1,
      }}
    >
      <ActionConfirmationDialog {...props} />
      <FeedbackPopup />
      <Navbar shouldShowMap={false} />
      <div
        style={{
          display: 'flex',
          marginTop: '20px',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span className="home-title">Hi {userName} !</span>
          <div
            className={isMobile ? 'mobile-home-button' : 'home-button'}
            onClick={() => setCitySearchPopup(true)}
          >
            Create a New Trip
          </div>
          <div
            className="home-button home-button-secondary"
            style={{
              background: 'transparent',
            }}
            onClick={() => {
              navigate(ROUTES.DISCOVER_TOKYO.path);
            }}
          >
            Discover Tokyo, Japan
          </div>

          <div className={isMobile ? 'mobile-trip-header' : 'trip-header'}>
            <span className="home-title">My Trips</span>
          </div>
          <P2PHistory />
        </div>

        {citySearchPopup && (
          <P2PCityAutocomplete
            onClose={() => setCitySearchPopup(false)}
            onSelect={handleTitleSave}
          />
        )}
      </div>
    </div>
  );
};

export default Home;
