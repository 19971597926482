import { makeStyles } from '@mui/styles';
import MuiTheme from '~/styles/theme/MuiTheme';

export const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  splashScreenWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100dvw',
    height: '100dvh',
    background: '#FE7138',
    zIndex: 1000,
    overflow: 'hidden', // Ensure no overflow during transition
    WebkitOverflowScrolling: 'touch', // Enable momentum scrolling on WebKit
  },

  splashScreenWrapperBackgroundChange: {
    background: '#FFFFFF',
  },

  splashScreen: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100dvw',
    height: '100dvh',
    display: 'flex', // Use only the modern flexbox
    WebkitDisplay: 'flex', // WebKit
    MsDisplay: 'flex', // IE 10
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  splashImage: {
    width: '200px',
    height: '200px',
  },

  splashScreenContinue: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100dvw',
    height: '100dvh',
  },

  splashScreenContinueLoading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100dvw',
    height: '100dvh',
    display: 'none',
  },

  splashScreenContinueWrapper: {
    width: '100dvw',
    height: '100dvh',
    display: 'flex', // Use only the modern flexbox
    WebkitDisplay: 'flex', // WebKit
    MsDisplay: 'flex', // IE 10
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '2.5rem',
  },

  lottieGlobeContainer: {
    width: '80px',
    height: '80px',
  },

  lottieGlobe: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
  },

  headingWrapper: {
    padding: '1rem',
  },

  continueButton: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex', // Use only the modern flexbox
    WebkitDisplay: 'flex', // WebKit
    MsDisplay: 'flex', // IE 10
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px 30px',
    verticalAlign: 'middle',
    background: 'linear-gradient(125.34deg, #E45116 43.06%, #EDA334 103.42%)',
    boxShadow:
      '0px 1px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 10px 10px rgba(0, 0, 0, 0.1), -2px -1px 5px 0px rgba(255, 230, 0, 0.75) inset',
    backdropFilter: 'blur(15px) brightness(110%) saturate(120%)',
    WebkitBackdropFilter: 'blur(15px) brightness(110%) saturate(120%)', // WebKit support
    MozBackdropFilter: 'blur(15px) brightness(110%) saturate(120%)', // Firefox support
    borderRadius: '8px',
    height: 'min(7.5vh, 60px)',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease', // Smooth transitions
    WebkitTransition: 'background-color 0.3s ease, box-shadow 0.3s ease', // WebKit
    MozTransition: 'background-color 0.3s ease, box-shadow 0.3s ease', // Firefox
    MsTransition: 'background-color 0.3s ease, box-shadow 0.3s ease', // IE
  },

  continueButtonText: {
    textTransform: 'none',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 'bolder',
    fontSize: '4.44vw',
    transition: 'color 0.3s ease', // Smooth transitions
    WebkitTransition: 'color 0.3s ease', // WebKit
    MozTransition: 'color 0.3s ease', // Firefox
    MsTransition: 'color 0.3s ease', // IE
  },

  splashScreenContinueContainer: {
    margin: '0',
  },

  '@media (min-width: 769px)': {
    splashScreenWrapperBackgroundChange: {
      background: 'rgba(0,0,0, 0.5)',
    },

    splashScreenContinue: {
      position: 'absolute',
      display: 'flex', // Use only the modern flexbox
      WebkitDisplay: 'flex', // WebKit
      MsDisplay: 'flex', // IE 10
      justifyContent: 'center',
      alignItems: 'center',
      width: '100dvw',
      height: '100dvh',
    },

    splashScreenContinueWrapper: {
      borderRadius: '1rem',
      width: '38.6%',
      height: 'auto',
      margin: '0 auto',
      background: '#FFF',
      padding: '1rem',
      // padding: '42px, 32px, 42px, 32px',
      display: 'flex', // Use only the modern flexbox
      WebkitDisplay: 'flex', // WebKit
      MsDisplay: 'flex', // IE 10
    },

    lottieGlobeContainer: {
      width: '100px',
      height: '100px',
    },

    continueButton: {
      marginTop: '2rem',
      boxSizing: 'border-box',
      width: '100%',
      padding: '10px 8px',
      verticalAlign: 'middle',
      background: 'linear-gradient(125.34deg, #e45116 43.06%, #eda334 103.42%)',
      boxShadow:
        '0px 1px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 10px 10px rgba(0, 0, 0, 0.1), -2px -1px 5px 0px rgba(255, 230, 0, 0.75) inset',
      borderRadius: '8px',
      transition: 'background-color 0.3s ease, box-shadow 0.3s ease', // Smooth transitions
      WebkitTransition: 'background-color 0.3s ease, box-shadow 0.3s ease', // WebKit
      MozTransition: 'background-color 0.3s ease, box-shadow 0.3s ease', // Firefox
      MsTransition: 'background-color 0.3s ease, box-shadow 0.3s ease', // IE
    },

    continueButtonText: {
      fontWeight: '700',
      fontSize: '16px',
      transition: 'color 0.3s ease', // Smooth transitions
      WebkitTransition: 'color 0.3s ease', // WebKit
      MozTransition: 'color 0.3s ease', // Firefox
      MsTransition: 'color 0.3s ease', // IE
    },
  },
}));
