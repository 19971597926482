import React, { useEffect, useState, useMemo, Fragment } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Typography, ListItemText, Box, List, Skeleton } from '@mui/material';
import { PickerValue } from 'react-mobile-picker';
import { DragIndicator, Lock } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { TravelOptions, TravelPointsByDayIndex } from '~/utility/models';
import {
  convertDateToTimezoneFromUTC,
  truncateText,
  determineMarkerType,
  getDefaultMarkerSvgString,
} from '~/utility/utils';
import { P2PSelectTimeModal } from '../P2PSelectTimeModal';
import { P2PTravelPointEditDialog } from './P2PTravelPointEditDialog';
import { GetCurrentCategoryType } from '~/components/P2PManual/P2PSearchAutocomplete/helpers';
import { filteredPoints } from '~/redux/selectors/P2PManualSelectors';
import { RootState } from '~/redux/reducers';
import ActionsCreator from '~/redux/actions';
import { getMarkerIcon } from '~/animationEngine/StaticTravelVisualizer/getCustomIcon';

dayjs.extend(utc);
dayjs.extend(timezone);

interface P2PTravelPointProps {
  point: TravelOptions;
  dayTravel: TravelOptions[];
  index: number;
  isDisabled: boolean;
  isStartPoint: boolean;
  isEndPoint: boolean;
  isDragging: boolean;
  provided: any;
  loadingAfterDrag: boolean;
}

export interface TimeProps {
  startTime: Dayjs | null;
  endTime: Dayjs | null;
}
export interface PickerProps {
  startTime?: PickerValue;
  endTime?: PickerValue;
}

const P2PTravelPoint: React.FC<P2PTravelPointProps> = ({
  point,
  dayTravel,
  index,
  isDisabled,
  isStartPoint,
  isEndPoint,
  isDragging,
  provided,
  loadingAfterDrag,
}) => {
  const dispatch = useDispatch();
  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );
  const [selectedTime, setSelectedTime] = useState<TimeProps | null>(() => {
    // Function to compute initial value for selectedTime
    const { startDate, endDate, timezone } = point;

    const inTimezoneStartTime = startDate
      ? convertDateToTimezoneFromUTC({
          date: dayjs.utc(startDate),
          timezone: timezone,
        })
      : null;

    const inTimezoneEndTime = endDate
      ? convertDateToTimezoneFromUTC({
          date: dayjs.utc(endDate),
          timezone: timezone,
        })
      : null;

    return { startTime: inTimezoneStartTime, endTime: inTimezoneEndTime };
  });

  const [displayTime, setDisplayTime] = useState<PickerProps | null>(null);

  const [openEditTimeModal, setOpenEditTimeModal] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);

  const filteredTravelPoints = useSelector(filteredPoints);
// Marker point icon
  const travelPointIcon = useMemo(() => {
    const selectedPointType = GetCurrentCategoryType(point.typeOfPoint);
    const category: string = selectedPointType.label;
    const imageLink =
      point.tiktokData?.[0]?.thumbnail || point.media?.[0]?.thumbnail;
    const travelArrayIds = dayTravel.map(
      (value) => value.coordinates.toString()
    );

    const markerType = determineMarkerType(category);
    const defaultIcon = getDefaultMarkerSvgString(markerType);

    let markerIcon = getMarkerIcon({
      category,
      indexOfMarker: index,
      travelArrayIds,
      imageLink,
      isP2PTravelPoint: true,
    });
    if (markerIcon.includes('undefined')) {
      markerIcon = markerIcon.replace('undefined', defaultIcon);
    }
    if (!markerIcon) {
      markerIcon = defaultIcon;
    }
    return markerIcon;
  }, [point, dayTravel]);

  useEffect(() => {
    const { startDate, endDate, timezone } = point;

    // Ensure startDate and endDate are valid
    const validStartDate = startDate ? dayjs.utc(startDate) : null;
    const validEndDate = endDate ? dayjs.utc(endDate) : null;

    const startTime = validStartDate
      ? convertDateToTimezoneFromUTC({
          date: validStartDate,
          timezone: timezone,
        })
      : '';

    const endTime = validEndDate
      ? convertDateToTimezoneFromUTC({
          date: validEndDate,
          timezone: timezone,
        })
      : '';

    setSelectedTime({
      startTime: !!startTime ? startTime : null,
      endTime: !!endTime ? endTime : null,
    });

    let start: Dayjs | null | '' = startTime;
    let end: Dayjs | null | '' = endTime;

    // Helper function to extract time details
    const formatTime = (time: Dayjs) => {
      return {
        hour: time.format('hh'),
        minute: time.format('mm'),
        second: time.format('ss'), // Optional, include if needed
        ampm: time.format('A') as 'AM' | 'PM',
      };
    };

    // Update display times, ensuring null safety
    setDisplayTime({
      ...(start && { startTime: formatTime(start) }),
      ...(end && { endTime: formatTime(end) }),
    });
  }, [filteredTravelPoints.length, index, point]);

  const handleOpenSelectTimeModal = () => {
    setOpenEditTimeModal(true);
  };

  const handleSaveTime = () => {
    setOpenEditTimeModal(false);
  };

  const editPoint = () => {
    if (isDragging) return;
    handleEditDialogClick();
  };

  const handleEditDialogClick = () => setEditDialogOpen((prev) => !prev);

  const editDialogProps = useMemo(() => {
    return {
      open: editDialogOpen,
      onClose: handleEditDialogClick,
      point,
    };
  }, [editDialogOpen, point]);

  const handleTripDurationText = (point: TravelOptions) => {
    return <CalculateDuration point={point} />;
  };

  const handleDurationUpdate = (
    type: 'add' | 'subtract',
    point: TravelOptions,
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) => {
    const { id: selectedPointId, dayIndex, endDate } = point;
    const currentEndDate = dayjs(endDate).utc();
    const startDate = dayjs(point.startDate).utc();

    let updatedEndDate = '';

    if (type === 'subtract') {
      // Perform the substitution
      const newEndDate = currentEndDate.subtract(10, 'm');
      const durationInMinutes = currentEndDate.diff(startDate, 'm');

      if (durationInMinutes > 0) {
        updatedEndDate = newEndDate.format();
      } else {
        return;
      }
    } else {
      // Perform the addition
      updatedEndDate = currentEndDate.add(10, 'm').format();
    }

    // Update travel points by day index immutably
    const updatedTravelPointsByDayIndex = {
      ...travelPointsByDayIndex,
      [dayIndex]: travelPointsByDayIndex[dayIndex].map((p) =>
        p.id === selectedPointId ? { ...p, endDate: updatedEndDate } : p,
      ),
    };

    // Dispatch updated data
    dispatch(
      ActionsCreator.setTravelPointsByDayIndex(updatedTravelPointsByDayIndex),
    );
  };
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <P2PTravelPointEditDialog {...editDialogProps} />
      <Box
        sx={{
          margin: 0,
          display: 'flex',

          alignItems: 'center',
          background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%)',
          boxShadow:
            '0px 1.5px 1.5px 0px #FFFFFF40 inset , 0px 6.65px 4.72px 0px #ADBFD205 , 0px 12.52px 8.89px 0px #ADBFD206 , 0px 41.78px 29.66px 0px #ADBFD209 , 0px 10px 20px 0px #3E638A0D',
          borderRadius: '18px',
          padding: '0px 10px',
          pointerEvents: isDisabled ? 'none' : 'auto', // Disable interaction
        }}
        onClick={(e) => {
          if (!isDisabled) {
            editPoint();
          }
        }}
      >
        <List
          sx={{
            display: 'flex',
            p: 'min(4vw, 8px)',
            pl: 0,
          }}
        >
          <div
            className="me-1 d-flex align-items-center"
            {...(isDisabled ? {} : provided.dragHandleProps)}
          >
            {isDisabled ? (
              <Lock color="disabled" />
            ) : (
              <DragIndicator color="disabled" />
            )}
            {/* Here we put icons */}
            {loadingAfterDrag ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : (
              <div
                style={{ position: 'relative', overflow: 'visible' }}
                onClick={editPoint}
                key={index + 'image'}
              >
                {parse(travelPointIcon)}
              </div>
            )}
          </div>

          <ListItemText
            primary={
              <Box
                sx={{
                  display: 'flex',
                  gap: '1.2rem',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      opacity: isDisabled ? 0.35 : 1,
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                    }}
                    onClick={editPoint}
                  >
                    {loadingAfterDrag ? (
                      <Skeleton
                        variant="rounded"
                        width={'100px'}
                        height={'20px'}
                      />
                    ) : (
                      <Fragment>{truncateText(point?.label ?? '')}</Fragment>
                    )}
                  </Typography>
                  {index === 0 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                        pointerEvents: 'auto', // Make the time clickable even when the rest is disabled
                      }}
                    >
                      {loadingAfterDrag ? (
                        <Skeleton variant="text" width={100} height={20} />
                      ) : (
                        <Typography
                          sx={{
                            fontSize: 'min(4vw, 14px)',
                            fontWeight: 700,
                            color: '#fe7138',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent parent Box onClick
                            if (isDragging) return;
                            handleOpenSelectTimeModal();
                          }}
                        >
                          {displayTime &&
                            `${displayTime?.startTime?.hour}:${displayTime?.startTime?.minute} ${displayTime?.startTime?.ampm}`}
                        </Typography>
                      )}
                    </Box>
                  ) : index === filteredTravelPoints.length - 1 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                      }}
                    >
                      <Box>
                        {loadingAfterDrag ? (
                          <Skeleton variant="text" width={100} height={20} />
                        ) : (
                          <Typography
                            sx={{
                              fontSize: 'min(4vw, 14px)',
                              fontWeight: 700,
                              color: '#fe7138',
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent parent Box onClick
                              if (isDragging) return;
                              handleOpenSelectTimeModal();
                            }}
                          >
                            {displayTime &&
                              `${displayTime?.startTime?.hour}:${displayTime?.startTime?.minute} ${displayTime?.startTime?.ampm}`}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent parent Box onClick
                        if (isDragging) return;
                        handleOpenSelectTimeModal();
                      }}
                    >
                      <div>
                        {loadingAfterDrag ? (
                          <Skeleton variant="text" width={100} height={20} />
                        ) : (
                          <Typography
                            sx={{
                              fontSize: 'min(4vw, 14px)',
                              fontWeight: 700,
                              color: '#fe7138',
                              cursor: 'pointer',
                            }}
                          >
                            {displayTime &&
                              `${displayTime?.startTime?.hour}:${displayTime?.startTime?.minute} ${displayTime?.startTime?.ampm}`}
                            {' - '}
                          </Typography>
                        )}
                        {loadingAfterDrag ? (
                          <Skeleton variant="text" width={100} height={20} />
                        ) : (
                          <Typography
                            sx={{
                              fontSize: 'min(4vw, 14px)',
                              fontWeight: 700,
                              color: '#fe7138',
                              cursor: 'pointer',
                            }}
                          >
                            {displayTime &&
                              `${displayTime?.endTime?.hour}:${displayTime?.endTime?.minute} ${displayTime?.endTime?.ampm}`}
                          </Typography>
                        )}
                      </div>
                    </Box>
                  )}
                </Box>
              </Box>
            }
          />

          <div style={{ flex: 2 }} />
        </List>
        <div style={{ flex: 1 }} />
        {isStartPoint || isEndPoint ? (
          <Box
            sx={{
              width: '50%',
            }}
          >
            {loadingAfterDrag ? (
              <Skeleton variant="text" width={100} height={20} />
            ) : (
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 700,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  borderRadius: '16px', // Make it pill-shaped
                  backgroundColor: '#fff',
                  color: '#000',
                  padding: '8px 8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add subtle shadow
                  textAlign: 'center',
                  width: '80%',
                  m: '0 auto',
                }}
              >
                {isStartPoint ? 'Start Point' : 'End Point'}
              </Typography>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              // gap: '.2rem',
              width: '40%',
              // overflow: 'hidden', // Prevent content from spilling outside the container
              maxWidth: '100%', // Ensure the container respects the parent width
              boxSizing: 'border-box', // Include padding and borders in width calculation
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '30px',
                fontWeight: 700,
                color: '#000',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                display: 'inline-block',
                lineHeight: 1,
                padding: 0,
                margin: 0,
                userSelect: 'none', // Prevent text selection
                minWidth: '20px', // Set a minimum width to prevent layout breaking
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent any parent handlers
                if (isDragging) return;
                handleDurationUpdate('subtract', point, travelPointsByDayIndex);
              }}
            >
              -
            </Typography>
            {loadingAfterDrag ? (
              <Skeleton variant="text" width={50} height={35} />
            ) : (
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 700,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  borderRadius: '16px', // Make it pill-shaped
                  backgroundColor: '#fff',
                  color: '#000',
                  padding: '8px 8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add subtle shadow
                  minWidth: '60px',
                  maxWidth: '90px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                {handleTripDurationText(point)}
              </Typography>
            )}
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '30px',
                fontWeight: 700,
                color: '#000',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                display: 'inline-block',
                lineHeight: 1,
                padding: 0,
                margin: 0,
                userSelect: 'none', // Prevent text selection
                minWidth: '20px', // Set a minimum width to prevent layout breaking
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent any parent handlers
                if (isDragging) return;
                handleDurationUpdate('add', point, travelPointsByDayIndex);
              }}
            >
              +
            </Typography>
          </Box>
        )}
      </Box>

      <P2PSelectTimeModal
        open={openEditTimeModal}
        onClose={() => {
          setOpenEditTimeModal(false);
        }}
        onSave={handleSaveTime}
        selectedLocation={point}
        selectedIndex={index}
        selectedTime={selectedTime as TimeProps}
        setSelectedTime={setSelectedTime}
      />
    </div>
  );
};

export default P2PTravelPoint;

const CalculateDuration: React.FC<{
  point: TravelOptions;
}> = ({ point }) => {
  const currentDuration = useMemo(() => {
    const { startDate, endDate } = point;

    // Ensure they are valid Date objects
    const start = new Date(startDate || 0); // Defaults to epoch if startDate is invalid
    const end = new Date(endDate || 0);

    // Calculate the difference in seconds
    const diffInSeconds = Math.max(0, (end.getTime() - start.getTime()) / 1000); // Ensure no negative values

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = Math.floor(diffInSeconds % 60);

    // Format the duration
    if (diffInSeconds === 0) {
      return '0'; // Explicitly return 0s for zero duration
    }

    const parts: string[] = [];
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0) parts.push(`${seconds}s`);

    return parts.join(' ');
  }, [point]);

  return <>{currentDuration}</>;
};
