import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Modal } from '@mui/material';
import { TravelPointsByDayIndex } from '~/utility/models';
import { RootState } from '~/redux/reducers';
import P2PPointsViewer from './P2PPointsViewer';
import P2PManualMap from '../P2PManualMap';

import {
  filteredPoints,
  selectTravelPoints,
} from '~/redux/selectors/P2PManualSelectors';

export const P2PTravelBuilder: React.FC = () => {
  const [creattionError, setCreationError] = React.useState<boolean>(false);

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );
  const travelPoints = useSelector(selectTravelPoints);

  const filteredTravelPoints = useSelector(filteredPoints);

  const isMapSelected = useSelector(
    (state: RootState) => state.P2PManualReducers.isMapSelected,
  );

  return (
    <Fragment>
      {isMapSelected ? (
        <P2PManualMap
          travelPoints={travelPoints}
          travelPointsByDayIndex={travelPointsByDayIndex}
        />
      ) : (
        <P2PPointsViewer
          dayTravelPoints={filteredTravelPoints}
          travelPoints={travelPoints}
        />
      )}

      <Modal open={creattionError} onClose={() => setCreationError(false)}>
        {/* error modal */}
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%);',
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            padding: 5,
            textAlign: 'center',
            width: '90%',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h3>
            <b>Fix Trip to Visualize</b>
          </h3>

          <h5 className="my-4">
            <b>
              Each day needs to have at least 2 points to create a
              visualization.
            </b>
          </h5>

          <Button
            variant="contained"
            sx={{
              background: '#FE7138',
              boxShadow:
                '0px 2px 6px 0px #B8C5D033,0px 6px 22px 0px #B8C5D04D,0px 9px 30px 0px #C6D8E933,0px 1.5px 1.5px 0px #FFFFFF40 inset;',
              height: 64,
              borderRadius: '18px',
              width: 161,
            }}
            onClick={() => setCreationError(false)}
          >
            Back
          </Button>
        </Box>
      </Modal>
    </Fragment>
  );
};
