// helper.ts
import { generateUUID } from 'three/src/math/MathUtils';
import { ITodoTravelPoint } from '~/components/AddToTrip';
import { supabase } from '~/supabase/supabaseClient';
import { TravelFormData, TravelOptions } from '~/utility/models';
import { CityInterface } from './php2EditablePlaces';

export const MANUAL_HISTORY_TABLE_NAME = 'Manual History';

/**
 * This function creates a trip in Manual History Table in Database
 * @param {TravelOptions[]} travelPoints - Travel Points of the trip
 * @param {string} email - Email of the user
 * @param {string} id - ID of the user
 * @param {string} title - Title of the trip
 * @returns Returns created trip from the Database
 */

export async function addP2PToHistory(
  travelPoints: TravelOptions[],
  city: CityInterface,
  email: string,
  id: string,
  title: string,
) {
  try {
    // save to publish travel

    const { data, error } = await supabase
      .from(MANUAL_HISTORY_TABLE_NAME)
      .insert({
        travelPoints,
        city,
        email,
        UUID: id,
        title,
        identifier: generateUUID(),
      })
      .select()
      .single();

    return data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * This function adds a travel to Travel History of a User in DB based on email provided
 * @param {TravelFormData[]} travelArray - Array of TravelFormData objects
 * @param {string} email - Email of the user adding Travel (Logged In User)
 * @returns Returns response from Database query
 */
export async function addtoTravelHistory(
  travelArray: TravelFormData[],
  email: string,
) {
  try {
    const { data, error } = await supabase
      .from(MANUAL_HISTORY_TABLE_NAME)
      .upsert({
        travelPoints: travelArray,
        email: email,
      })
      .select();

    if (error) {
      console.error('Error upserting data:', error.message);
      return { error };
    } else {
      return { data };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

/**
 * This function fetchs data of Travel History of a user based on provided userId
 * @param {string} userId - ID of the user logged in
 * @returns Returns array of TravelPoints
 */
export async function getP2PHistory(userId: string) {
  try {
    const { data, error } = await supabase
      .from(MANUAL_HISTORY_TABLE_NAME)
      .select(
        `
        *,
       "Publish Travel_manual_history_identifier_fkey" (id)
      `,
      )
      .eq('UUID', userId)
      .order('id', { ascending: false });

    if (error) {
      console.error('Error fetching data:', error.message);
      return { error };
    } else {
      const updatedData = data.map((item) => {
        if (item['Publish Travel_manual_history_identifier_fkey']) {
          // Rename the key to a shorter name
          item['publish_travel_table'] =
            item['Publish Travel_manual_history_identifier_fkey'];
          delete item['Publish Travel_manual_history_identifier_fkey']; // Remove the old key
        }
        return item;
      });

      return { data: updatedData };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

/**
 * This function deletes a travel of a user from Travel History Table in Database
 * @param {string} userId - ID of the user
 * @param {string} id - ID of travel to be deleted
 * @returns Returns updated array of TravelPoints after deletion
 */
export async function deleteTravelHistory(userId: string, id: number) {
  try {
    const { error } = await supabase
      .from(MANUAL_HISTORY_TABLE_NAME)
      .delete()
      .eq('UUID', userId)
      .eq('id', id);

    if (error) {
      console.error('Error deleting data:', error.message);
      return { error };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

/**
 * This function updates a travel of a user in Travel History Table in Database
 * @param {TravelFormData[]} travelArray - Array of Updated Travels
 * @param {number} recordId - ID of travel to be updated
 * @returns Returns updated array of TravelPoints
 */
export async function updateTravelHistoryById(
  travelArray: TravelFormData[],
  recordId: number,
) {
  try {
    const { data, error } = await supabase
      .from(MANUAL_HISTORY_TABLE_NAME)
      .update({ travelPoints: travelArray })
      .eq('id', recordId)
      .select();

    if (error) {
      console.error('Error updating data:', error.message);
      return { error };
    } else {
      return { data };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

/**
 * This function fetchs data of Manual History of a user based on provided identifier
 * @param {string}identifier - identifier of the manual history
 * @returns Returns array of TravelPoints
 */
export async function getManualPointsByIdentifier(identifier: string) {
  try {
    const { data, error } = await supabase
      .from(MANUAL_HISTORY_TABLE_NAME)
      .select()
      .eq('identifier', identifier);

    if (error) {
      console.error('Error fetching data:', error.message);
      return { error };
    } else {
      if (data && data?.length > 0) {
        const result: {
          UUID: string;
          created_at: string;
          email: string;
          id: number;
          identifier: string;
          title: string;
          travelPoints: TravelOptions[];
          todos: ITodoTravelPoint[];
          city: {
            city: string;
            country: string;
            id: number;
            lat: number;
            lng: number;
            population: number;
          };
        } = {
          UUID: '',
          created_at: '',
          email: '',
          id: 0,
          identifier: '',
          title: '',
          travelPoints: [],
          ...data[0],
        };
        return { data: result };
      }

      return { data: null };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

/**
 * This function updates the todo field of a user's travel record in the Travel History Table in Database
 * @param {ITravelPoint[]} todoArray - The updated todo array for the travel record
 * @param {number} recordId - The ID of the travel record to be updated
 * @returns Returns the updated travel record or an error
 */
export async function updateTravelHistoryTodoById(
  todoArray: ITodoTravelPoint[],
  recordId: number,
) {
  try {
    const { data, error } = await supabase
      .from(MANUAL_HISTORY_TABLE_NAME)
      .update({ todos: todoArray }) // Update the 'todo' field
      .eq('id', recordId) // Specify which record to update based on the ID
      .select(); // Retrieve the updated record

    if (error) {
      console.error('Error updating todo field:', error.message);
      return { error };
    } else {
      console.log({ data });
      return { data };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

/**
 * This function creates a new travel record with a todo field in the Travel History Table in the Database
 * if the record does not already exist.
 * @param {ITodoTravelPoint[]} todoArray - The todo array to be added to the new travel record
 * @param {number} recordId - The ID of the travel record to be created
 * @returns Returns the created travel record or an error
 */
export async function createTravelHistoryTodoById(
  todoArray: ITodoTravelPoint[],
  recordId: number,
) {
  try {
    // Check if the record already exists
    const { data: existingRecord, error: fetchError } = await supabase
      .from(MANUAL_HISTORY_TABLE_NAME)
      .select()
      .eq('id', recordId)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') {
      // Ignore "No rows found" error
      console.error('Error fetching record:', fetchError.message);
      return { error: fetchError };
    }

    if (existingRecord) {
      console.log('Record already exists:', existingRecord);
      return { data: existingRecord };
    }

    // Insert a new record if it does not exist
    const { data, error } = await supabase
      .from(MANUAL_HISTORY_TABLE_NAME)
      .insert([{ id: recordId, todos: todoArray }]) // Insert with todo field
      .select();

    if (error) {
      console.error('Error creating travel record:', error.message);
      return { error };
    } else {
      console.log('Created record:', data);
      return { data };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}

/**
 * This function fetchs data of Manual History of a user based on provided id
 * @param {string}id - id of the manual history
 * @returns Returns array of TravelPoints
 */
export async function getManualPointsById(id: number) {
  try {
    const { data, error } = await supabase
      .from(MANUAL_HISTORY_TABLE_NAME)
      .select()
      .eq('id', id);

    if (error) {
      console.error('Error fetching data:', error.message);
      return { error };
    } else {
      if (data && data?.length > 0) {
        const result: {
          UUID: string;
          created_at: string;
          email: string;
          id: number;
          identifier: string;
          title: string;
          travelPoints: TravelOptions[];
        } = {
          UUID: '',
          created_at: '',
          email: '',
          id: 0,
          identifier: '',
          title: '',
          travelPoints: [],
          ...data[0],
        };
        return { data: result };
      }

      return { data: null };
    }
  } catch (error) {
    console.error('Error:', error);
    return { error };
  }
}
