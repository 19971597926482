import { store } from '../store';
import * as Types from '../actionTypes';
import {
  FormData,
  ITravelFormHistoryData,
  ModelKeys,
  PublishableTravelData,
  PublishableTravelObject,
  TravelFormData,
} from '~utility/models';
import { supabase } from '~/supabase/supabaseClient';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import {
  setupPublishableTravelObject,
  setupPublishableTravelObjectWithDecodedPath,
} from '~/utility/utils';
import ActionsCreator from '.';
import { CityInterface } from '~/supabase/php2EditablePlaces';
import { insertItinerary } from '~/supabase/publishTravel';

const removeTimezone = (obj: FormData): FormData => {
  // Make a copy of the original object
  const newObj: FormData = { ...obj };

  if (newObj.dateTime !== null) {
    if (typeof newObj.dateTime === 'string') {
      // Split the string at the first occurrence of '(' and keep only the first part (date)
      newObj.dateTime = newObj.dateTime.split('(')[0].trim();
    } else if (newObj.dateTime instanceof Date) {
      // Convert Date object to string without timezone info
      newObj.dateTime = new Date(
        Date.UTC(
          newObj.dateTime.getFullYear(),
          newObj.dateTime.getMonth(),
          newObj.dateTime.getDate(),
          newObj.dateTime.getHours(),
          newObj.dateTime.getMinutes(),
          newObj.dateTime.getSeconds(),
          newObj.dateTime.getMilliseconds(),
        ),
      ).toISOString();
    }
  }

  return newObj;
};

export const addTravelToHistoryAndDispatch = (
  travelArray: TravelFormData[],
  email: string,
  userId: string,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const filteredTravelArray = travelArray.map((travelData) => {
        const filteredArrival = { ...travelData.arrival };
        const filteredDeparture = { ...travelData.departure };

        return {
          ...travelData,
          arrival: removeTimezone(filteredArrival),
          departure: removeTimezone(filteredDeparture),
        };
      });

      console.log({ filteredTravelArray });

      const { data } = await supabase
        .from('Travel History')
        .upsert({
          travelPoints: filteredTravelArray,
          email: email,
          UUID: userId,
        })
        .select();

      setTravelHistoryCurrentID(data?.[0].id);
      // saveTravelToHistory(data as TravelFormHistoryData[]);

      resolve(data); // Resolve the Promise with the data
    } catch (error) {
      // You can also reject the Promise with the error if needed
      reject(error);
    }
  });
};

export const setPublishableTravel = (value: PublishableTravelData[]) =>
  store.dispatch({
    type: Types.SET_PUBLISHABLE_TRAVEL_DATA,
    publishableTravelData: value,
  });

export const publishTravelPoints = (
  travelObj: PublishableTravelObject,
  userId: string,
  currentHistoryID: number,
): Promise<number> => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await insertItinerary(travelObj, currentHistoryID, userId);

      if (data) {
        setPulishedTravelId(data.id);
        resolve(data.id); // Resolve the Promise with the data
      }
    } catch (error) {
      // You can also reject the Promise with the error if needed
      reject(error);
    }
  });
};

export const publishManualTravelPoints = async (
  travelObj: PublishableTravelObject,
  userId: string,
  currentHistoryIdentifier: string,
  publishId?: number,
  city?: CityInterface,
): Promise<number> => {
  try {
    let newPublishId = 0;

    // 1. Check if a record already exists based on `publishId` or `manual_history_identifier`
    const filterConditions =
      publishId && publishId !== 0
        ? `id.eq.${publishId},manual_history_identifier.eq.${currentHistoryIdentifier}`
        : `manual_history_identifier.eq.${currentHistoryIdentifier}`;

    const { data: existingData, error: fetchError } = await supabase
      .from('Publish Travel')
      .select('id, publishable_data, UUID, manual_history_identifier')
      .or(filterConditions);

    if (fetchError) {
      console.error('Error fetching existing record:', fetchError.message);
      throw fetchError;
    }

    if (existingData && existingData.length > 0) {
      // 2. Update the existing record if found
      const existingId = existingData[0].id;
      const { data: updatedData, error: updateError } = await supabase
        .from('Publish Travel')
        .update({
          publishable_data: travelObj,
          UUID: userId,
          // manual_history_identifier: currentHistoryIdentifier,
        })
        .eq('id', existingId)
        .select('id, publishable_data, UUID, manual_history_identifier');

      if (updateError) {
        console.error('Error updating record:', updateError.message);
        throw updateError;
      }

      console.log('Record updated:', updatedData);

      setPulishedTravelId(updatedData?.[0]?.id);
      newPublishId = updatedData?.[0]?.id;
    } else {
      // 3. Upsert a record to handle conflicts gracefully
      const { data: upsertedData, error: upsertError } = await supabase
        .from('Publish Travel')
        .upsert(
          {
            publishable_data: travelObj,
            UUID: userId,
            manual_history_identifier: currentHistoryIdentifier,
            city: city,
          },
          { onConflict: 'manual_history_identifier' }, // Ensure conflict resolution on this column
        )
        .select('id, publishable_data, UUID, manual_history_identifier');

      if (upsertError) {
        console.error('Error during upsert:', upsertError.message);
        throw upsertError;
      }

      console.log('Record upserted:', upsertedData);
      if (upsertedData && upsertedData.length > 0) {
        setPulishedTravelId(upsertedData[0].id);
        newPublishId = upsertedData[0].id;
      }
    }

    return newPublishId;
  } catch (error) {
    console.error('Unexpected error during upsert:', error);
    throw error;
  }
};

export const updateTravelToHistoryAndDispatch = (
  travelArray: TravelFormData[],
  id: number,
) => {
  return new Promise(async (resolve, reject) => {
    const filteredTravelArray = travelArray.map((travelData) => {
      const filteredArrival = { ...travelData.arrival };
      const filteredDeparture = { ...travelData.departure };

      return {
        ...travelData,
        arrival: removeTimezone(filteredArrival),
        departure: removeTimezone(filteredDeparture),
      };
    });

    console.log({ filteredTravelArray });

    try {
      const { data } = await supabase
        .from('Travel History')
        .update({ travelPoints: filteredTravelArray })
        .eq('id', id)
        .select();

      setTravelHistoryCurrentID(data?.[0].id);
      // saveTravelToHistory(data as TravelFormHistoryData[]);

      resolve(data); // Resolve the Promise with the data
    } catch (error) {
      // You can also reject the Promise with the error if needed
      reject(error);
    }
  });
};

export const deleteTravelToHistoryAndDispatch = (id: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await supabase
        .from('Travel History')
        .delete()
        .eq('id', id);

      resolve(data); // Resolve the Promise with the data
    } catch (error) {
      // You can also reject the Promise with the error if needed
      reject(error);
    }
  });
};
export const openTravelItinerary = (value: boolean) =>
  store.dispatch({
    type: Types.SET_TRAVEL_ITINERARY_STATE,
    travelItineraryState: value,
  });

export const openModifyTravelForm = (value: boolean) =>
  store.dispatch({
    type: Types.SET_MODIFY_TRAVEL_STATE,
    modifyTravelFormState: value,
  });

export const setIndexForModifyTravelForm = (value: number | null) =>
  store.dispatch({
    type: Types.SET_INDEX_FOR_MODIFY,
    index: value,
  });

export const openTravelForm = (value: boolean) =>
  store.dispatch({
    type: Types.TRAVEL_FORM_STATE,
    openTravelForm: value,
  });

export const setScheduleSectionState = (value: boolean) =>
  store.dispatch({
    type: Types.SET_SCHEDULE_SECTION_STATE,
    scheduleSectionState: value,
  });
export const sethistoryCardState = (value: boolean) =>
  store.dispatch({
    type: Types.SET_HISTORY_CARD_STATE,
    historyCardState: value,
  });

export const openPopUP = (value: boolean) =>
  store.dispatch({
    type: Types.POPUP_STATE,
    popupState: value,
  });

export const addTravelPoint = (value: TravelFormData[]) =>
  store.dispatch({
    type: Types.ADD_TRAVEL_POINT,
    pointsArray: value,
  });

export const saveTravelPointAndPublish = (value: TravelFormData[]) => {
  return () => {
    store.dispatch({
      type: Types.ADD_TRAVEL_POINT,
      pointsArray: value,
    });

    setLoading(true);

    const travelHistoryData = store.getState().MapReducers.travelHistoryState;
    const currentHistoryID =
      store.getState().MapReducers.travelHistoryTrackingID;

    const currentIndex = store.getState().MapReducers.travelHistoryIndex;

    console.log({ travelHistoryData, currentHistoryID, currentIndex });

    if (travelHistoryData.length > 0 && currentHistoryID !== -1) {
      if (travelHistoryData[currentIndex].id === currentHistoryID) {
        // Update existing travel history
        updateTravelToHistoryAndDispatch(value, currentHistoryID)
          .then((data) => {
            console.log('Operation successful:', data);

            // Publish
            const travelFormData = store.getState().MapReducers.pointsArray;

            if (travelFormData.length > 0) {
              const travelData = Promise.all(
                travelFormData.map((data) =>
                  setupPublishableTravelObjectWithDecodedPath(
                    data,
                    data.selectedTransport === TravelMode.Car
                      ? store.getState().MapReducers.carModelEnum
                      : data.selectedTransport === TravelMode.Plane
                      ? store.getState().MapReducers.planeModelEnum
                      : data.selectedTransport === TravelMode.Walk
                      ? store.getState().MapReducers.walkModelEnum
                      : data.selectedTransport === TravelMode.Ferry
                      ? store.getState().MapReducers.ferryModelEnum
                      : store.getState().MapReducers.transitModelEnum,
                    store.getState().MapReducers.modelSize,
                    store.getState().MapReducers.videoLength,
                  ),
                ),
              );

              travelData.then(async (data) => {
                console.log({ publishD: data });

                try {
                  const publishableTravelData = await Promise.all(
                    data.map(async (data) => {
                      return await setupPublishableTravelObject(data);
                    }),
                  );

                  if (publishableTravelData.length > 0) {
                    let clonedArray = JSON.parse(
                      JSON.stringify(publishableTravelData),
                    );
                    store.dispatch(
                      ActionsCreator.setPublishableTravel(clonedArray),
                    );
                  }
                } catch (error) {
                  console.error(
                    'Error setting publishable travel data:',
                    error,
                  );
                }
              });
            }

            // setSuccessMessage('Saved Successfully');
            // setSuccessSnackbarOpen(true);

            setTimeout(() => {
              setTravelFormSaveState(true);
              openModifyTravelForm(false);
              openTravelItinerary(true);
              setTravelHistoryIndex(0);
              setSuccessSnackbarOpen(false);
            }, 1000);
          })
          .catch((error) => {
            // Handle error with the 'error' returned
            console.error('Error:', error);
            // setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    } else {
      // Add new travel history
      addTravelToHistoryAndDispatch(
        value,
        store.getState().MapReducers.userEmail,
        store.getState().MapReducers.userID,
      )
        .then((data) => {
          // Handle successful operation
          console.log('Operation successful:', data);

          // Publish
          const travelFormData = store.getState().MapReducers.pointsArray;

          if (travelFormData.length > 0) {
            const travelData = Promise.all(
              travelFormData.map((data) =>
                setupPublishableTravelObjectWithDecodedPath(
                  data,
                  data.selectedTransport === TravelMode.Car
                    ? store.getState().MapReducers.carModelEnum
                    : data.selectedTransport === TravelMode.Plane
                    ? store.getState().MapReducers.planeModelEnum
                    : data.selectedTransport === TravelMode.Walk
                    ? store.getState().MapReducers.walkModelEnum
                    : data.selectedTransport === TravelMode.Ferry
                    ? store.getState().MapReducers.ferryModelEnum
                    : store.getState().MapReducers.transitModelEnum,
                  store.getState().MapReducers.modelSize,
                  store.getState().MapReducers.videoLength,
                ),
              ),
            );

            travelData.then(async (data) => {
              console.log({ publishD: data });

              try {
                const publishableTravelData = await Promise.all(
                  data.map(async (data) => {
                    return await setupPublishableTravelObject(data);
                  }),
                );

                if (publishableTravelData.length > 0) {
                  let clonedArray = JSON.parse(
                    JSON.stringify(publishableTravelData),
                  );
                  store.dispatch(
                    ActionsCreator.setPublishableTravel(clonedArray),
                  );
                }
              } catch (error) {
                console.error('Error setting publishable travel data:', error);
              }
            });
          }
          setLoading(false);
          // setSuccessMessage('Saved Successfully');
          // setSuccessSnackbarOpen(true);

          setTimeout(() => {
            setTravelFormSaveState(true);
            openTravelForm(false);
            setSuccessSnackbarOpen(false);
          }, 1000);
        })
        .catch((error) => {
          // Handle error with the 'error' returned
          console.error('Error:', error);
          setLoading(false);
        });
    }
  };
};
export const saveTravelPoint = (value: TravelFormData[]) => {
  return () => {
    store.dispatch({
      type: Types.ADD_TRAVEL_POINT,
      pointsArray: value,
    });

    setLoading(true);

    const travelHistoryData = store.getState().MapReducers.travelHistoryState;
    const currentHistoryID =
      store.getState().MapReducers.travelHistoryTrackingID;

    const currentIndex = store.getState().MapReducers.travelHistoryIndex;

    console.log({ travelHistoryData, currentHistoryID, currentIndex });

    if (travelHistoryData.length > 0 && currentHistoryID !== -1) {
      if (travelHistoryData[currentIndex].id === currentHistoryID) {
        // Update existing travel history
        updateTravelToHistoryAndDispatch(value, currentHistoryID)
          .then((data) => {
            setLoading(false);
            console.log('Operation successful:', data);
            setSuccessMessage('Saved Successfully');
            setSuccessSnackbarOpen(true);

            setTimeout(() => {
              setTravelFormSaveState(true);
              openModifyTravelForm(false);
              openTravelItinerary(true);
              setTravelHistoryIndex(0);
              setSuccessSnackbarOpen(false);
            }, 1000);
          })
          .catch((error) => {
            // Handle error with the 'error' returned
            console.error('Error:', error);
            setLoading(false);
          });
      }
    } else {
      // Add new travel history
      addTravelToHistoryAndDispatch(
        value,
        store.getState().MapReducers.userEmail,
        store.getState().MapReducers.userID,
      )
        .then((data) => {
          // Handle successful operation
          console.log('Operation successful:', data);
          setLoading(false);
          setSuccessMessage('Saved Successfully');
          setSuccessSnackbarOpen(true);

          setTimeout(() => {
            setTravelFormSaveState(true);
            openTravelForm(false);
            setSuccessSnackbarOpen(false);
          }, 1000);
        })
        .catch((error) => {
          // Handle error with the 'error' returned
          console.error('Error:', error);
          setLoading(false);
        });
    }
  };
};

export const updateTravelPoints = (value: TravelFormData[]) => {
  return async () => {
    // Dispatch the ADD_TRAVEL_POINT action first
    store.dispatch({
      type: Types.ADD_TRAVEL_POINT,
      pointsArray: value,
    });

    const travelHistoryData = store.getState().MapReducers.travelHistoryState;
    const currentHistoryID =
      store.getState().MapReducers.travelHistoryTrackingID;
    const currentIndex = store.getState().MapReducers.travelHistoryIndex;

    if (travelHistoryData.length > 0 && currentHistoryID !== -1) {
      if (travelHistoryData[currentIndex].id === currentHistoryID) {
        if (travelHistoryData[currentIndex].travelPoints.length > 1) {
          updateTravelToHistoryAndDispatch(value, currentHistoryID)
            .then((data) => {
              console.log('Update successful:', data);
              setTravelHistoryIndex(0);
            })
            .catch((error) => {
              // Handle error with the 'error' returned
              console.error('Error:', error);
            });
        } else {
          deleteTravelToHistoryAndDispatch(currentHistoryID)
            .then((data) => {
              console.log('Delete successful:', data);
            })
            .catch((error) => {
              // Handle error with the 'error' returned
              console.error('Error:', error);
            });
        }
      }
    }
  };
};

export const setSnackbarOpen = (value: boolean) =>
  store.dispatch({
    type: Types.SET_SNACKBAR_OPEN,
    snackbarOpen: value,
  });

export const setSuccessMessage = (value: string) =>
  store.dispatch({
    type: Types.SET_SUCCESS_MESSAGE,
    successMessage: value,
  });
export const setSnackbarMessage = (value: string) =>
  store.dispatch({
    type: Types.GET_SNACKBAR_MESSAGE,
    snackbarMessage: value,
  });

export const setSuccessSnackbarOpen = (value: boolean) =>
  store.dispatch({
    type: Types.SET_SUCCESS_SNACKBAR_OPEN,
    successSnackbarOpen: value,
  });

export const setSnackbarSeverity = (value: boolean) =>
  store.dispatch({
    type: Types.GET_SNACKBAR_SEVERITY,
    snackbarSeverity: value,
  });

export const setLoading = (value: boolean) =>
  store.dispatch({
    type: Types.SET_LOADING,
    loading: value,
  });

export const setTravelFormSaveState = (value: boolean) =>
  store.dispatch({
    type: Types.SET_TRAVEL_FORM_SAVE_STATE,
    isTravelFormSaved: value,
  });

export const discardAllTravelPoints = () =>
  store.dispatch({
    type: Types.DISCARD_TRAVEL_POINTS,
  });

export const openMapCustomizationPopup = (value: boolean) =>
  store.dispatch({
    type: Types.MAP_CUSTOMIZATION_POPUP_STATE,
    mapCustomizationPopupState: value,
  });
export const setMapStyleIndex = (value: number) =>
  store.dispatch({
    type: Types.SET_MAP_STYLE_INDEX,
    mapStyleIndex: value,
  });
export const openHistoryPageState = (value: boolean) =>
  store.dispatch({
    type: Types.SET_HISTORY_PAGE_STATE,
    historyPageState: value,
  });

export const triggerStateToClear = (value: boolean) =>
  store.dispatch({
    type: Types.CLEAR_MAP_STATE,
    mapClearState: value,
  });

export const openVideoPopup = (value: boolean) =>
  store.dispatch({
    type: Types.SET_VIDEO_POPUP_STATE,
    videoPopupState: value,
  });

export const setModelSize = (value: number) =>
  store.dispatch({
    type: Types.SET_MODEL_SIZE,
    modelSize: value,
  });

export const setVideoLength = (value: number) =>
  store.dispatch({
    type: Types.SET_VIDEO_LENGTH,
    videoLength: value,
  });

export const setIsAnimationCapture = (value: boolean) =>
  store.dispatch({
    type: Types.SET_IS_RECORDING,
    isRecording: value,
  });

export const startRecording = (value: boolean) =>
  store.dispatch({
    type: Types.START_RECORDING,
    startRecording: value,
  });

export const stopRecording = (value: boolean) =>
  store.dispatch({
    type: Types.STOP_RECORDING,
    stopRecording: value,
  });

export const saveTravelToHistory = (value: ITravelFormHistoryData[]) =>
  store.dispatch({
    type: Types.SAVE_TRAVEL_TO_HISTORY,
    travelHistoryState: value,
  });

export const setTravelHistoryIndex = (value: number) =>
  store.dispatch({
    type: Types.SET_TRAVEL_HISTORY_INDEX,
    travelHistoryIndex: value,
  });

export const setTravelHistoryCurrentID = (value: number) =>
  store.dispatch({
    type: Types.SET_HISTORY_TRACKING_ID,
    travelHistoryTrackingID: value,
  });
export const setPaymentStatsState = (value: boolean) =>
  store.dispatch({
    type: Types.SET_PAYMENT_STATS_STATE,
    paymentStatsState: value,
  });
export const setVerificationEmailState = (value: string | undefined) =>
  store.dispatch({
    type: Types.SET_VERIFICATION_EMAIL,
    verificationEmailState: value,
  });

export const setFullscreenAnimationMode = (value: boolean) =>
  store.dispatch({
    type: Types.SET_FULLSCREEN_MODE,
    fullscreenMode: value,
  });

export const selectPlaneModel = (value: ModelKeys[]) =>
  store.dispatch({
    type: Types.SELECT_PLANE_MODEL,
    planeModelEnum: value,
  });
export const selectCarModel = (value: ModelKeys[]) =>
  store.dispatch({
    type: Types.SELECT_CAR_MODEL,
    carModelEnum: value,
  });
export const selectTransitModel = (value: ModelKeys[]) =>
  store.dispatch({
    type: Types.SELECT_TRANSIT_MODEL,
    transitModelEnum: value,
  });
export const selectWalkModel = (value: ModelKeys[]) =>
  store.dispatch({
    type: Types.SELECT_WALK_MODEL,
    walkModelEnum: value,
  });

// Dispatch function for Ferry Model Select
export const selectFerryModel = (value: ModelKeys[]) =>
  store.dispatch({
    type: Types.SELECT_FERRY_MODEL,
    ferryModelEnum: value,
  });

export const setDownloadStartTime = (value: Date) =>
  store.dispatch({
    type: Types.SET_DOWNLOAD_START_TIME,
    downloadStartTime: value,
  });

export const openSettingsPageState = (value: boolean) =>
  store.dispatch({
    type: Types.SET_SETTINGS_PAGE_STATE,
    settingsPageState: value,
  });

export const setPulishedTravelId = (value: number) =>
  store.dispatch({
    type: Types.SET_PUBLISHED_TRAVEL_ID,
    publishID: value,
  });

export const getPulishedTravelId = (value: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await supabase
        .from('Publish Travel')
        .select('id')
        .eq('published_id', value);

      setPulishedTravelId(data!.length > 0 ? data![0].id : 0);

      resolve(data); // Resolve the Promise with the data
    } catch (error) {
      // You can also reject the Promise with the error if needed
      reject(error);
    }
  });
};
export const setFeedbackPopupState = (value: boolean) =>
  store.dispatch({
    type: Types.SET_FEEDBACK_POPUP_STATE,
    feedbackPopupState: value,
  });
export const setUserID = (value: string) =>
  store.dispatch({
    type: Types.GET_USER_ID,
    userID: value,
  });
export const setUserEmail = (value: string) =>
  store.dispatch({
    type: Types.GET_USER_EMAIL,
    userEmail: value,
  });
export const setUserName = (value: string) =>
  store.dispatch({
    type: Types.GET_USER_NAME,
    userName: value,
  });

export const setUserProfileImageURL = (value: string) =>
  store.dispatch({
    type: Types.GET_PROFILE_IMAGE_URL,
    profileImgURL: value,
  });

const MapActions = {
  addTravelPoint,
  saveTravelPoint,
  saveTravelPointAndPublish,
  selectPlaneModel,
  selectCarModel,
  selectFerryModel,
  selectTransitModel,
  selectWalkModel,
  setUserEmail,
  setUserName,
  openTravelForm,
  openPopUP,
  openTravelItinerary,
  openModifyTravelForm,
  discardAllTravelPoints,
  setTravelFormSaveState,
  setScheduleSectionState,
  setIndexForModifyTravelForm,
  updateTravelPoints,
  openMapCustomizationPopup,
  setMapStyleIndex,
  openHistoryPageState,
  triggerStateToClear,
  openVideoPopup,
  setModelSize,
  setVideoLength,
  setIsAnimationCapture,
  startRecording,
  stopRecording,
  saveTravelToHistory,
  setTravelHistoryIndex,
  setTravelHistoryCurrentID,
  setVerificationEmailState,
  setPaymentStatsState,
  addTravelToHistoryAndDispatch,
  updateTravelToHistoryAndDispatch,
  setFullscreenAnimationMode,
  deleteTravelToHistoryAndDispatch,
  setDownloadStartTime,
  openSettingsPageState,
  publishTravelPoints,
  publishManualTravelPoints,
  setPulishedTravelId,
  getPulishedTravelId,
  setFeedbackPopupState,
  setUserID,
  setUserProfileImageURL,
  setPublishableTravel,
  setSnackbarOpen,
  setSnackbarMessage,
  setSuccessMessage,
  setSuccessSnackbarOpen,
  setLoading,
  setSnackbarSeverity,
};

export default MapActions;
