import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { TypeOfPointInterface } from '~/components/P2PManual/P2PSearchAutocomplete/helpers';
import { TravelOptions } from '~/utility/models';

const SLIDE_SIZE = 200;

interface MediaWrapperProps {
  dialogHandlers: { imageAndVideo: () => void };
  point: TravelOptions;
  images: string[];
  videos: string[];
  isRemoving: boolean;
  typeOfPoint: TypeOfPointInterface;
}

export const MediaWrapper = (props: MediaWrapperProps) => {
  const { dialogHandlers, point, images, isRemoving, typeOfPoint } = props;

  return (
    <div
      style={{
        width: '95%',
        display: 'flex',
        marginBottom: 20,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.25 }}
      >
        <button
          style={{
            width: SLIDE_SIZE,
            height: SLIDE_SIZE,
            borderRadius: 32,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow:
              '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933,0px 1.5px 1.5px 0px #FFFFFF40 inset',
            padding: '10px',
            position: 'relative',
            border: 'none',
            background: '#fff',
          }}
          onClick={() => dialogHandlers.imageAndVideo()}
          disabled={isRemoving}
        >
          {images.length > 0 ? (
            <img
              width={SLIDE_SIZE}
              height={SLIDE_SIZE}
              src={images[0]}
              alt={String('img')}
              loading="lazy"
            />
          ) : (
            <Box>
              {!point?.media && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={typeOfPoint?.icon}
                    alt={typeOfPoint?.label}
                    width={SLIDE_SIZE * 0.25}
                    height={SLIDE_SIZE * 0.25}
                  />
                </Box>
              )}

              {point?.media && point?.media?.length > 0 ? (
                <img
                  src={point?.media[0]?.thumbnail}
                  alt={point.description}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Ensures the image covers the box proportionally
                  }}
                />
              ) : (
                <Typography
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '27px',
                    textAlign: 'center',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                    color: '#FE7138', // Add text color for better visibility
                  }}
                >
                  Add Image (+)
                  <br />
                  or Video (+)
                </Typography>
              )}
            </Box>
          )}
        </button>
      </motion.div>
    </div>
  );
};
