import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
    gap: '1.2rem',
    width: '100%',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: '#FE7138 #f1f1f1',
    // Custom scrollbar for Webkit browsers
    '&::-webkit-scrollbar': {
      width: '6px', // Width of the scrollbar
      height: '6px', // Height of horizontal scrollbar
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#FE7138', // Color of the scrollbar handle
      borderRadius: '4px', // Round the corners
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1', // Background of the track
    },
  },

  dialogTitle: {
    padding: 0,
    margin: 0,
    marginBottom: '2.5rem',
  },

  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1.5px solid #ececec',
    borderRadius: '10px',
    padding: '1rem',
    gap: '1rem',
    marginBotom: '2rem',
    [theme.breakpoints.down('sm')]: {
      gap: '.6rem',
      padding: '.3rem',
    },
  },

  dragAndDropParent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '.8rem',
    [theme.breakpoints.down('sm')]: {
      gap: '.6rem',
    },
  },

  fileSelectionText: {
    color: '#000000',
    fontSize: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },

  fileSelectionSubtext: {
    color: '#84858E',
    fontSize: '.8rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
    },
  },

  playPauseButton: {
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:blur': {
      backgroundColor: 'transparent',
    },
  },

  uploadButton: {
    border: '1.5px solid #ED6934',
    color: '#ED6934',
    fontWeight: 400,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    fontSize: '0.6rem',
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
      padding: '1px',
    },
  },

  fileInput: {
    display: 'none',
  },

  deleteIcon: {
    position: 'absolute',
    top: '-.5rem',
    right: '-.2rem',
    // backgroundColor: 'transparent',
    borderRadius: '50%',
    zIndex: '99',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  libaryContainer: {
    marginTop: '3rem',
    marginBottom: '1rem',
  },
  libraryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    overflow: 'hidden',
  },
  libraryList: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  heading: {
    fontSize: '.8rem',
  },

  [theme.breakpoints.between(300, 770)]: {
    dialogTitle: {
      marginBottom: '4rem',
    },
  },

  [theme.breakpoints.between(0, 768)]: {
    dialogContent: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },

    uploadButton: {
      padding: theme.spacing(1, 1),
    },
  },

  [theme.breakpoints.between(1024, 1440)]: {
    heading: {
      fontSize: '16px',
    },

    subHeading: {
      fontSize: '16px',
    },

    categoryButtons: {
      fontSize: '14px',
    },
  },
  [theme.breakpoints.between(1440, 1700)]: {
    dialogContent: {
      width: '90%',
      margin: '0 auto',
    },
    heading: {
      fontSize: '20px',
    },

    subHeading: {
      fontSize: '18px',
    },
  },
  [theme.breakpoints.between(1700, 1920)]: {
    heading: {
      fontSize: '22px',
    },

    subHeading: {
      fontSize: '20px',
    },
    dialogContent: {
      width: '85%',
      margin: '0 auto',
    },
  },
  [theme.breakpoints.between(1920, 2560)]: {
    heading: {
      fontSize: '24px',
    },

    subHeading: {
      fontSize: '22px',
    },
  },
  [theme.breakpoints.between(2560, 3500)]: {
    dialogContent: {
      width: '80%',
      margin: '0 auto',
    },
    heading: {
      fontSize: '32px',
    },
  },
  '@media (min-width: 3500px)': {
    dialogContent: {
      width: '75%',
      margin: '0 auto',
    },
    heading: {
      fontSize: '36px',
    },
  },
}));

export default useStyles;
