const ROUTES = {
  LANDINGPAGE: {
    path: '/',
    key: 'LANDINGPAGE',
  },
  HOMEPAGE: {
    path: '/homepage',
    key: 'HOMEPAGE',
  },
  LOGIN: {
    path: '/login',
    key: 'LOGIN',
  },
  SIGNUP: {
    path: '/signup',
    key: 'SIGNUP',
  },
  HISTORY: {
    path: '/history',
    key: 'HISTORY',
  },
  PAYMENT: {
    path: '/payment',
    key: 'PAYMENT',
  },
  PAYMENTPLANS: {
    path: '/paymentPlans',
    key: 'PAYMENTPLANS',
  },
  VERIFICATION: {
    path: '/verification',
    key: 'VERIFICATION',
  },
  PASSWORDRESET: {
    path: '/passwordreset',
    key: 'PASSWORDRESET',
  },
  NEWPASSWORD: {
    path: '/newpassword',
    key: 'NEWPASSWORD',
  },
  SETTINGS: {
    path: '/settings',
    key: 'SETTINGS',
  },
  VIEWTRAVEL: {
    path: '/viewtravel',
    key: 'VIEWTRAVEL',
  },
  SMART: {
    path: '/smart',
    key: 'SMART',
  },
  TRAVEL_ASSISTANT: {
    path: '/travel-assistant',
    key: 'TRAVEL_ASSISTANT',
  },
  AIBUILDER: {
    path: '/builder',
    key: 'AIBUILDER',
  },
  ITINERARY: {
    path: '/itinerary',
    key: 'ITINERARY',
  },
  P2PAI: {
    path: '/p2p-ai',
    key: 'P2PAI',
  },
  P2PManual: {
    path: '/p2p-manual',
    key: 'P2PManual',
  },
  HOME: {
    path: '/Home',
    key: 'HOME',
  },
  SERVE: {
    path: '/serve',
    key: 'SERVE',
  },
  DISCOVER_TOKYO: {
    path: '/discover-tokyo',
    key: 'DISCOVER_TOKYO',
  },
  DISCOVER_MAP: {
    path: '/discover-map/:id',
    key: 'DISCOVER_MAP',
  },
} as const;

export type RouteKeys = keyof typeof ROUTES;
export default ROUTES;
