import { ModelConfigProps } from '~/utility/models';
/**
 * An enumeration of various model types, potentially used for 3D objects or map markers.
 * Each member represents a specific model type with a corresponding numeric value for potential use in calculations or comparisons.
 */
export enum WalkModels {
  /** Represents a map pointer, potentially used for location identification on a map. */
  Man = 'Man',

  Char = 'Char',
}

export const walkModelsConfiguration = new Map<WalkModels, ModelConfigProps>([
  [
    WalkModels.Man,
    {
      name: 'Animation_Character_2.glb',
      path: './Animation_Character_2.glb',
      UIname: 'Man',
    },
  ],
  [
    WalkModels.Char,
    {
      name: 'Char.glb',
      path: './Char.glb',
      UIname: 'Char',
    },
  ],
]);
