import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import dayjs, { Dayjs } from 'dayjs';

import { CalendarPopup, State } from './CalendarPopup';
import MuiTheme from '~/styles/theme/MuiTheme';
import { TravelOptions, TravelPointsByDayIndex } from '~/utility/models';
import { RootState } from '~/redux/reducers';
import ActionsCreator from '~/redux/actions';
import { convertDateToTimezoneFromUTC } from '~/utility/utils';

export const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  container: {
    position: 'relative',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  dateBox: {
    width: 186,
    height: 35,
    boxShadow: '0px 2px 3px 0px #00000040',
    borderRadius: 20,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateText: {
    color: '#000',
    fontSize: 15,
    fontWeight: 'bold',
    fontFamily: `'Poppins', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif`,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DateSelector = ({ travelPoints }: { travelPoints: TravelOptions[] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // State to manage the popup open/close
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState('');

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );
  const created_at: string = useSelector(
    (state: RootState) => state.P2PManualReducers.created_at,
  );

  useEffect(() => {
    createDateRange(travelPointsByDayIndex, created_at);
  }, [travelPointsByDayIndex, created_at]);

  const createDateRange = (
    travelPointsByDayIndex: TravelPointsByDayIndex,
    created_at: string,
  ) => {
    const firstDayData: TravelOptions[] = travelPointsByDayIndex[1];
    const lastDayData: TravelOptions[] =
      travelPointsByDayIndex[Object.keys(travelPointsByDayIndex).length];
    let startDate;
    let endDate;
    let timeRange;

    // Format options
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
    };

    if (
      firstDayData &&
      firstDayData[0] &&
      lastDayData &&
      lastDayData[lastDayData.length - 1]
    ) {
      const firstPoint = firstDayData[0];
      const lastPoint = lastDayData[lastDayData.length - 1];

      startDate = dayjs(firstPoint.startDate).tz(firstPoint.timezone);
      endDate = dayjs(lastPoint.startDate).tz(lastPoint.timezone);
    } else {
      // Set trip created date when there are no points
      startDate = dayjs(new Date(created_at));
      endDate = dayjs(new Date(created_at));
    }

    // Generate time range string
    timeRange = `${startDate.format('MMM DD')} - ${endDate.format(
      'MMM DD',
    )}, ${endDate.year()}`;

    setSelectedDateRange(timeRange);
  };

  // Handle closing the calendar popup
  const handleClose = () => {
    setIsCalendarOpen(false);
  };

  // Handle saving dates from the calendar popup
  const handleSave = async (state: State) => {
    if (state?.startDate && state?.endDate) {
      await handleUpdateTravelPoints(state);
    }
    setIsCalendarOpen(false);
  };

  const handleUpdateTravelPoints = async (state: State) => {
    const newDateRange: Dayjs[] =
      await handleGenerateNewDateRangeByNumberOfDays(state);

    const newUpdatedTravelPointsByDayIndex: TravelPointsByDayIndex =
      await handleUpdateNewDateForPointsByDayIndex(
        newDateRange,
        travelPointsByDayIndex,
      );

    dispatch(
      ActionsCreator.setTravelPointsByDayIndex(
        newUpdatedTravelPointsByDayIndex as TravelPointsByDayIndex,
      ),
    );
  };

  const handleUpdateNewDateForPointsByDayIndex = (
    newDateRange: Dayjs[],
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) => {
    let updatedTrabelPointsByDayIndex: TravelPointsByDayIndex = {};

    Object.keys(travelPointsByDayIndex).forEach((k) => {
      updatedTrabelPointsByDayIndex[Number(k)] = handleUpdateNewDateForPoints(
        newDateRange,
        travelPointsByDayIndex[Number(k)],
      );
    });

    return updatedTrabelPointsByDayIndex as TravelPointsByDayIndex;
  };

  const handleUpdateNewDateForPoints = (
    newDateRange: Dayjs[],
    travelPoints: TravelOptions[],
  ) => {
    // Get the first travel point's start date
    const firstPointStartDate = dayjs(travelPoints[0].startDate);

    // Get the first date from the new date range
    const firstNewDate = dayjs(newDateRange[0]);

    // Calculate the difference in days
    const differenceInDays = firstNewDate.diff(firstPointStartDate, 'day');

    // Adjust each travel point's start and end dates
    const newUpdatedPoints: TravelOptions[] = travelPoints.map((point: TravelOptions) => {
      let updatedPoint = { ...point };

      // Adjust start date
      if (point.startDate) {
        updatedPoint.startDate = dayjs(point.startDate).add(differenceInDays, 'day').utc().format();
      }

      // Adjust end date
      if (point.endDate) {
        updatedPoint.endDate = dayjs(point.endDate).add(differenceInDays, 'day').utc().format();
      }

      return updatedPoint;
    });

    return newUpdatedPoints;
  };

  // Not used anymore

  // const mergeNewtimeToCurrentDate = (newDate: Dayjs, currentDate: Dayjs) => {
  //   const mergedDate = (newDate || dayjs())
  //     .utc()
  //     .set('hour', currentDate.utc().hour())
  //     .set('minute', currentDate.utc().minute())
  //     .set('second', currentDate.utc().second())
  //     .set('millisecond', currentDate.utc().millisecond());

  //   return mergedDate.format();
  // };

  const handleGenerateNewDateRangeByNumberOfDays = (state: State): Dayjs[] => {
    const { numberOfDays, startDate } = state;
    // Empty array by default
    let newDateRange: Dayjs[] = [];

    // One day
    if (startDate && numberOfDays === 0) {
      newDateRange = [startDate] as Dayjs[]; // Return an empty array for invalid input
    }

    // Range of days
    if (startDate && numberOfDays > 0) {
      for (let i = 0; i <= numberOfDays; i++) {
        const newDate = startDate.add(i, 'day');
        newDateRange.push(newDate);
      }
    }

    return newDateRange;
  };

  return (
    <>
      <Box className={classes.container}>
        <Box
          className={classes.dateBox}
          onClick={() => setIsCalendarOpen(true)}
        >
          <Typography className={classes.dateText}>
            {selectedDateRange}
          </Typography>
        </Box>
      </Box>
      <CalendarPopup
        open={isCalendarOpen}
        onClose={handleClose}
        onSave={handleSave}
      // selectedDateRange={selectedDateRange}
      />
    </>
  );
};
export default DateSelector;
