import React from 'react';
import { Modal, Drawer, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import TravelForm from '~/components/TravelForm';
import ActionsCreator from '~redux/actions';
import { useDispatch } from '~redux/store';
import useStyles from './styles';
import { RootState } from '~/redux/reducers';

/**
 * TravelFormContainer component - Container for the Travel Form.
 *
 * @component
 * @returns {React.ReactElement} - Rendered component
 */
const TravelFormContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const formName = 'Add a New Travel';
  const alertText =
    'Departure point locked in as previous arrival point, only departure';

  // Get the state of the travel form from the store
  const openTravelForm = useSelector(
    (state: RootState) => state.MapReducers.openTravelForm,
  );

  /**
   * handleClose - Closes the travel form.
   */
  const handleClose = () => {
    dispatch(ActionsCreator.openTravelForm(false));
    dispatch(ActionsCreator.setSnackbarOpen(false));
  };

  // Determine if the screen size is small
  const isSmScreen = useMediaQuery('(max-width:768px)');
  const PopupComponent = isSmScreen ? Drawer : Modal;

  return (
    <PopupComponent
      {...(isSmScreen
        ? {
            anchor: 'bottom',
            open: openTravelForm,
            onClose: handleClose,
            PaperProps: { style: { borderRadius: '25px 25px 0 0' } },
            ModalProps: { keepMounted: false },
          }
        : {
            open: openTravelForm,
            onClose: handleClose,
            'aria-labelledby': 'modal-modal-title',
            'aria-describedby': 'modal-modal-description',
          })}
    >
      <Box className={isSmScreen ? classes.smTravelForm : classes.travelForm}>
        <TravelForm
          handleClose={handleClose}
          formName={formName}
          alertText={alertText}
          state={false}
        />
      </Box>
    </PopupComponent>
  );
};

export default TravelFormContainer;
