import { FC } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from '@mui/material';
import { ConfirmationDayInterface } from '..';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
    borderRadius: 30,
    marginLeft: '14px',
    marginRight: '14px',
    paddingBottom: '30px',
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

const ButtonStyles = {
  display: 'flex',
  height: '64px',
  width: '100%',
  maxWidth: '161px',
  borderRadius: '18px',
  boxShadow: '0px 0px 11px 7px #C6D8E933',
};

interface P2PTransportSelectorProps {
  dialogData: ConfirmationDayInterface;
  onClose: () => void;
  onExited: () => void;
  onSave: (dayIndex: number) => void;
}

export const P2PConfirmDayDeletion: FC<P2PTransportSelectorProps> = ({
  dialogData,
  onClose,
  onExited,
  onSave,
}) => {
  const handleSelect = (dayIndex: number) => {
    if (dayIndex) {
      onClose(); // Optionally close the dialog after selecting
      onSave(dayIndex);
    }
  };

  return (
    <StyledDialog
      open={dialogData.open}
      onClose={onClose}
      TransitionProps={{
        onExited: onExited, // Triggered after the dialog's closing animation
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: '#000000',
              fontFamily: 'Poppins, sans-serif',
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '36px',
              margin: '10px',
            }}
          >
            Confirm you want to delete Day {dialogData.content}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
          width: '100%',
          margin: '0 auto',
          padding: '10px 0',
        }}
      >
        <Button
          sx={{ ...ButtonStyles, backgroundColor: '#FE7138', color: '#fff' }}
          onClick={() => handleSelect(dialogData.content)}
        >
          Yes
        </Button>
        <Button
          sx={{ ...ButtonStyles, backgroundColor: '#fff', color: '#FE7138' }}
          onClick={onClose}
        >
          No
        </Button>
      </DialogContent>
    </StyledDialog>
  );
};
