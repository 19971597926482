import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import maplibregl from 'maplibre-gl';
import mapStyles from '~/map/utility/mapStyles';
import { IGeneration, ItineraryState } from '~/types/itinerary';
import { useSignals } from '@preact/signals-react/runtime';
import { useDispatch } from 'react-redux';

import { itinerarySignal } from '~/components/signals/itinerary/itinerarySignal';
import ActionsCreator from '~/redux/actions';
import {
  convertForManualHistoryAndSaveAndPublishTravelPoints,
  mergeItineraryData,
  transformAITripForManualHistory,
} from '~/utility/utils';
import MapHeader from './MapHeader';
import BottomSheetp2p, {
  bottomSheetp2pStateSignal,
  isFirstScreenSignal,
  markersSignal,
  pauseAutoYesProgress,
  resetAutoYesProgress,
} from './BottomSheetp2p';
import { dayCounterSignal, generationStateSignal } from '..';
import { signal } from '@preact/signals-core';
import { Box, LinearProgress, Modal } from '@mui/material';
import NoResponseP2P from './NoResponseP2P';
import { addPauseSignal } from '~/components/signals/itinerary/pauseSignal';
import { motion } from 'framer-motion';
import { URLConfig } from '~/utility/models';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import './style.css';

export const previewMapSignal = signal<maplibregl.Map | null>(null);

export const linesSignal = signal<any>({
  id: 'lines',
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [],
  },
});

export const addNewLine = (
  startPoint: [number, number],
  endPoint: [number, number],
) => {
  const newLine = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [startPoint, endPoint],
    },
    properties: {
      opacity: 1,
      outlineOpacity: 0.6,
    },
  };

  linesSignal.value = {
    ...linesSignal.peek(),
    data: {
      ...linesSignal.peek().data,
      features: [...linesSignal.peek().data.features, newLine],
    },
  };
};

export const fadeAllpreviousLines = () => {
  linesSignal.value = {
    ...linesSignal.peek(),
    data: {
      ...linesSignal.peek().data,
      features: linesSignal.peek().data.features.map((item: any) => {
        return {
          ...item,
          properties: {
            ...item.properties,
            opacity: 0.35,
            outlineOpacity: 0,
          },
        };
      }),
    },
  };
};

export const removeLastLine = () => {
  linesSignal.value = {
    ...linesSignal.peek(),
    data: {
      ...linesSignal.peek().data,
      features: linesSignal.peek().data.features.slice(0, -1),
    },
  };
};

const initialState: ItineraryState = {
  input: '',
  clientError: '',
  errorMessage: '',
  generationComplete: false,
  showContinueButton: false,
  showResume: false,
  showPause: true,
  isPaused: false,
  isResumed: false,
  showCancel: false,
  showGenerate: true,
};

const MapPreview: FC<IGeneration> = ({
  pauseStream,
  resumeStream,
  handleCancel,
  messages,
  requestNextData,
  regenerateStream,
  loading,
  generateNextDay,
  wsError,
  deleteCurrentDay,
  restartFromScratch,
  startFromPreviousPoint,
  extra,
  getTitleAndDescription,
}) => {
  useSignals();
  const dispatch = useDispatch();

  const [state, setState] = useState<ItineraryState>(initialState);
  const [visualizationModalOpen, setVisualizationModalOpen] = useState(false);
  const [NoDrawerVisible, setNoDrawerVisible] = useState(false);
  const [hideItems, setHideItems] = useState(true);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!previewMapSignal.peek()) {
      const map = new maplibregl.Map({
        container: 'map',
        style: (mapStyles[1] as URLConfig).isURL
          ? ((mapStyles[1] as URLConfig).URL as string)
          : (mapStyles[1] as maplibregl.StyleSpecification),
        center: [0, 30],
        zoom: isMobile ? 0 : 2,
        attributionControl: false,
        maplibreLogo: false,
      });
      previewMapSignal.value = map;
      map.on('dragstart', () => {
        setHideItems(false);
        pauseAutoYesProgress();
      });
      map
        .on('dragend', () => {
          setHideItems(true);
          resetAutoYesProgress();
        })
        .once('load', () => {
          linesSignal.subscribe((source) => {
            if (map.getSource(source.id)) {
              const src = map.getSource(source.id) as any;
              src.setData(source.data);
              map.triggerRepaint();
            } else {
              map.addSource(source.id, {
                type: 'geojson',
                data: source.data,
              });
              map.addLayer({
                id: 'route-line-bg',
                type: 'line',
                source: source.id,
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-color': '#FEC9B3', // Orange color for the line
                  'line-width': 14,
                  'line-opacity': ['get', 'outlineOpacity'],
                },
              });
              map.addLayer({
                id: 'route-line',
                type: 'line',
                source: source.id,
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-color': '#FE7138', // Orange color for the line
                  'line-width': 8,
                  'line-opacity': ['get', 'opacity'],
                },
              });
              map.addLayer({
                id: 'route-symbols',
                type: 'symbol',
                source: source.id,
                layout: {
                  'symbol-placement': 'line',
                  'icon-offset': [-2, 0],
                  'text-offset': [0, -0.1],
                  // 'text-field': '▶',
                  // half filled chevron
                  'text-field': '›',
                  'text-size': 35,
                  'text-rotate': 0,
                  'text-keep-upright': false, // Allow rotation with line direction
                  'symbol-spacing': 50,
                },
                paint: {
                  'text-color': '#fff',
                  'text-opacity': ['get', 'opacity'],
                  // 'text-halo-blur': 1,
                  // 'text-halo-color': '#fff',
                },
              });
            }
          });
        });
    }
  }, []);

  const handleClose = (reason?: string) => {
    setNoDrawerVisible(false);

    if (reason) {
      setState((prevState) => ({
        ...prevState,
        showPause: true,
        showCancel: true,
        showResume: false,
        isPaused: false,
      }));
      bottomSheetp2pStateSignal.value =
        messages.length === 1 ? 'loading' : 'provoke-loading';

      resetAutoYesProgress();
      const place = messages[messages.length - 1];

      const id =
        place?.place?.location?.value + '-' + place?.transportationIndex;
      if (id) {
        const existingMarker = markersSignal
          .peek()
          .find((item) => item.id === id);
        if (existingMarker) {
          existingMarker.marker.remove();
          // markersSignal.value = markersSignal.peek().filter((item) => item.id !== id)
        }
      }

      removeLastLine();

      const placeIndex = +place.placeIndex;
      const transportationIndex = +place.transportationIndex;

      regenerateStream({
        placeIndex: placeIndex,
        transportationIndex: transportationIndex,
        reason,
      });
    }
  };

  const generateNextPoint = async (transportationIndex: number) => {
    setState((prev) => ({
      ...prev,
      showResume: false,
      showPause: true,
      isPaused: false,
      isResumed: true,
    }));

    requestNextData(transportationIndex);
  };

  const handleYes = (transportationIndex: number) => {
    transportationIndex = transportationIndex ? transportationIndex : 0;
    generateNextPoint(transportationIndex);
  };

  const handleNo = () => {
    setNoDrawerVisible(true);
  };

  const handlePause = (transportationIndex: number, placeIndex: number) => {
    setState((prev) => ({
      ...prev,
      showResume: true,
      showPause: false,
      isPaused: true,
      isResumed: false,
    }));
    pauseStream({ transportationIndex, placeIndex });
  };

  const handleResume = () => {
    setState((prev) => ({
      ...prev,
      showResume: false,
      showPause: true,
      isPaused: false,
      isResumed: true,
    }));
    addPauseSignal.value = false;
    resumeStream();
    ActionsCreator.setIsP2PButtonVisible(false);
  };

  const createVisualization = async () => {
    setState((prev) => ({
      ...prev,
      showResume: false,
      showPause: false,
      isPaused: false,
      isResumed: false,
    }));

    const itineraries = itinerarySignal.peek();

    if (itineraries && itineraries.length > 0) {
      setVisualizationModalOpen(true);
      getTitleAndDescription();
    } else {
      alert('Itinerary is empty');
    }
  };

  useEffect(() => {
    const fetchAndSaveData = async () => {
      const itineraries = itinerarySignal.peek();

      if (itineraries && itineraries.length > 0) {
        const mergedData = mergeItineraryData(itineraries);

        const pointsByDayIndex = transformAITripForManualHistory(mergedData);

        dispatch(ActionsCreator.setLoading(true));

        await convertForManualHistoryAndSaveAndPublishTravelPoints({
          pointsByDayIndex,
          title: extra.title,
          description: extra.description,
          quickTips: extra?.quickTips!,
        }).finally(() => {
          setVisualizationModalOpen(false);
          dispatch(ActionsCreator.setLoading(true));
        });
      }
    };

    if (extra?.title) {
      fetchAndSaveData();
    }
  }, [extra.description, extra?.quickTips, extra.title]); // Ensure extra is properly set in the dependency array

  const memoizedMap = useMemo(() => {
    return <div id="map" style={{ height: '100%' }} />;
  }, []);

  const shouldShowMapAnimation = isFirstScreenSignal.peek();

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <div
        className={
          shouldShowMapAnimation
            ? `map-animate ${isMobile ? 'isMobile' : ''}`
            : ''
        }
        style={{
          height: 'inherit',
          width: shouldShowMapAnimation ? '200vw' : 'inherit',
        }}
      >
        {memoizedMap}
      </div>

      <Fragment>
        <motion.div
          initial={{ opacity: 0, y: -200 }}
          animate={!hideItems ? { opacity: 0, y: -200 } : { opacity: 1, y: 0 }}
          transition={{ duration: 0.25 }}
          style={{
            left: 0,
            right: 0,
            top: 0,
            height: '8vh',
            position: 'absolute',
          }}
        >
          <MapHeader
            messages={messages}
            label={
              'Day ' +
                (dayCounterSignal.peek() || 1) +
                ' - ' +
                generationStateSignal.peek()?.startArea || 'Day 1 - Rome'
            }
            onStop={() => {
              handlePause(
                messages[messages.length - 1]?.transportationIndex,
                messages[messages.length - 1]?.placeIndex,
              );
            }}
            handleResume={handleResume}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={!hideItems ? { opacity: 0, y: 200 } : { opacity: 1, y: 0 }}
          transition={{ duration: 0.25 }}
          style={{
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
          }}
        >
          <BottomSheetp2p
            deleteDayX={(reason: string) => {
              if (deleteCurrentDay)
                deleteCurrentDay(
                  messages[messages.length - 1].dayIndex || 0,
                  reason,
                );
            }}
            messages={messages}
            handleNo={handleNo}
            handleYes={handleYes}
            generateNextDay={generateNextDay}
            handleCancel={handleCancel}
            handleResume={handleResume}
            createVisualization={createVisualization}
          />
        </motion.div>
        <Modal
          open={NoDrawerVisible}
          onClose={() => {
            resetAutoYesProgress();
            setNoDrawerVisible(false);
          }}
        >
          <Fragment>
            {NoDrawerVisible && <NoResponseP2P handleClose={handleClose} />}
          </Fragment>
        </Modal>

        <Modal
          open={visualizationModalOpen}
          onClose={() => {}}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '32px',
              left: '24px',
              right: '24px',
              padding: '24px',
              background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%)',
              borderRadius: '20px',
              boxShadow:
                '0px 1.3px 2.21px 0px #07081705 , 0px 3.13px 5.32px 0px #07081707 , 0px 5.89px 10.02px 0px #07081709 , 0px 10.5px 17.87px 0px #0708170B , 0px 19.64px 33.42px 0px #0708170D , 0px 47px 80px 0px #07081712',
              justifyContent: 'center',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              ...(!isMobile && { width: '40%', margin: '0 auto' }),
            }}
          >
            <LinearProgress />
            <h3 className="my-2">
              <b>Please wait while we generate the visualization</b>
            </h3>
          </Box>
        </Modal>
      </Fragment>
    </div>
  );
};

export default MapPreview;
