import { GeoJSONSource, Map } from 'maplibre-gl';
import {
  MultiTransportStates,
  MultiTransportAnimationController,
} from '../MultiTransportAnimationController';
import { Position } from '@turf/turf';

export class TransitionState implements MultiTransportStates {
  sourceIds: string[] = [];

  constructor(
    private stateMachine: MultiTransportAnimationController,
    private map: Map,
  ) {}

  onEnter(): void {
    let lastCoordinates = this.stateMachine.getPointOfStepTransition();
    this.addACircle(lastCoordinates);
    this.moveToNextStep();
  }

  addACircle(obj: Position) {
    let id = 'circle-point' + this.stateMachine.currentIndex;

    if (!this.map.getSource(id)) {
      // Add a GeoJSON source with a point feature
      this.map.addSource(id, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: obj, // Your circle's center coordinates
              },
            },
          ],
        },
      });
    } else {
      const source = this.map.getSource(id) as GeoJSONSource;

      // Reset the source data to an empty FeatureCollection
      source.setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: obj, // Your circle's center coordinates
            },
          },
        ],
      });
    }

    this.sourceIds.push(id);

    if (!this.map.getLayer(id))
      // Add a circle layer
      this.map.addLayer({
        id: id,
        type: 'circle',
        source: id,
        paint: {
          'circle-radius': 6, // circle radius in pixels
          'circle-color': '#FFFFFF', // circle color
          'circle-stroke-width': 2, // circle stroke width
          'circle-stroke-color': '#303030', // circle stroke color
        },
      });
  }

  moveToNextStep() {
    if (this.stateMachine.currentIndex < this.stateMachine.states.length) {
      this.stateMachine.currentState =
        this.stateMachine.states[this.stateMachine.currentIndex];
      this.stateMachine.currentState.onEnter();

      if (this.stateMachine.currentState.getTotalTime) {
        this.stateMachine.animationDuration +=
          this.stateMachine.currentState.getTotalTime();
      }

      this.stateMachine.currentIndex++;
    } else {
      this.stateMachine.currentIndex = 0;
      if (this.stateMachine.animationConfig.onCompleteCallback) {
        this.stateMachine.animationConfig.onCompleteCallback();
      }
    }
  }

  resetGeoJSONSources() {
    this.sourceIds.forEach((id) => {
      // Check if the source exists on the map
      if (this.map.getSource(id)) {
        const source = this.map.getSource(id) as GeoJSONSource;

        // Reset the source data to an empty FeatureCollection
        source.setData({
          type: 'FeatureCollection',
          features: [],
        });
      }
    });
  }

  onCleanup(): void {
    this.resetGeoJSONSources();
  }

  onExit(): void {
    console.log('Exiting Transition State');
  }

  onUpdate(): void {
    // No update logic required for the transition state
  }
}
