import { store } from '../store';
import * as Types from '../actionTypes';
import { PublishableTravelData } from '~/utility/models';

export const setCurrentTravelDay = (value: number) =>
  store.dispatch({
    type: Types.SET_CURRENT_TRAVEL_DAY,
    currentTravelDay:
      store.getState().TripInfoReducers.currentTravelDay === value
        ? undefined
        : value,
  });

export const setTravelData = (value: PublishableTravelData[]) =>
  store.dispatch({
    type: Types.SET_TRAVEL_DATA,
    travelData: value,
  });

export const setTravelRawData = (value: any) =>
  store.dispatch({
    type: Types.SET_TRAVEL_RAW_DATA,
    travelRawData: value,
  });

export const setCurrentOverviewData = (value: any) =>
  store.dispatch({
    type: Types.SET_CURRENT_OVERVIEW_DATA,
    currentOverviewData: value,
  });

export const setIsStoriesOpened = (value: boolean) =>
  store.dispatch({
    type: Types.SET_IS_STORIES_OPENED,
    isStoriesOpened: value,
  });

export const setIsP2POverviewVisible = (value: boolean) =>
  store.dispatch({
    type: Types.SET_IS_OVERVIEW_VISIBLE_IN_P2P,
    isP2POverviewVisible: value,
  });

export const setP2PItineraryData = (value: any) =>
  store.dispatch({
    type: Types.SET_P2P_ITINERARY_DATA,
    itineraryP2PData: value,
  });

export const setIsP2PButtonVisible = (value: boolean) =>
  store.dispatch({
    type: Types.SET_IS_OVERVIEW_BUTTON_VISIBLE_IN_P2P,
    isP2POverviewButtonVisible: value,
  });

const TripInfoActions = {
  setCurrentTravelDay,
  setTravelData,
  setCurrentOverviewData,
  setIsStoriesOpened,
  setTravelRawData,
  setIsP2POverviewVisible,
  setP2PItineraryData,
  setIsP2PButtonVisible,
};

export default TripInfoActions;
