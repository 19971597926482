import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import './style.css';

const SLIDE_SIZE = 150;

type P2PCardDetailsProps = {
  label: string;
  images: string;
  time: string;
  notes: string;
  price: number;
  currentDayIndex: number;
  rating: number;
};

export const P2PCardDetails = ({
  label,
  images,
  time,
  price,
  notes,
  currentDayIndex,
  rating,
}: P2PCardDetailsProps) => {
  return (
    <div
      style={{
        scrollbarWidth: 'none',
        paddingBottom: '30px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.15 }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 0,
          marginBottom: 10,
          maxWidth: '90%',
          justifyContent: 'center',
        }}
      >
        <img
          src={'/avatar.png'}
          height={48}
          style={{ marginRight: 10 }}
          loading="lazy"
          alt="icon"
        />
        <Typography
          fontSize={24}
          fontFamily={'Poppins'}
          fontWeight={'700'}
          style={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {label}
        </Typography>
      </motion.div>

      <Box className="mb-4 mt-2 px-2">
        <Typography
          sx={{
            color: '#bdbdbd',
            fontSize: '16px',
            lineHeight: '100.3%',
            fontFamily:
              'Poppins, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif',
            letterSpacing: '-0.711px',
            width: '350px',
            height: '40px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {notes || 'Not Added'}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '95%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {!!rating ? (
          <div
            className="custom-badge text-black"
            style={{ width: '90px', fontSize: '16px' }}
          >
            {`${rating || '4.8'} ⭐`}
          </div>
        ) : (
          <div
            className="custom-badge text-black"
            style={{ width: '100px', fontSize: '12px' }}
          >
            Not Added
          </div>
        )}

        {!!price ? (
          <div
            className="custom-badge text-black"
            style={{ width: '90px', fontSize: '14px' }}
          >
            {price || '~US$220'}
          </div>
        ) : (
          <div
            className="custom-badge text-black"
            style={{ width: '100px', fontSize: '12px' }}
          >
            Not Added
          </div>
        )}
      </Box>

      <div
        style={{
          width: '95%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.25 }}
        >
          <div
            style={{
              width: SLIDE_SIZE,
              height: SLIDE_SIZE,
              borderRadius: 32,
              overflow: 'hidden',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              boxShadow:
                '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933,0px 1.5px 1.5px 0px #FFFFFF40 inset',
            }}
            onClick={() => { }}
          >
            {images ? (
              <img
                width={SLIDE_SIZE}
                height={SLIDE_SIZE}
                src={images}
                alt={String('img')}
                loading="lazy"
              />
            ) : (
              <img
                src="/avatar.png"
                height={SLIDE_SIZE * 0.5}
                loading="lazy"
                alt={String('img')}
              />
            )}
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.35 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '95%',
            height: '100%',
            gap: 10,
          }}
        >
          <div
            className="custom-badge"
            style={{ maxWidth: '120px', width: '100%', flexWrap: 'wrap' }}
          >
            {`Day ${currentDayIndex}`}
          </div>
          <div
            className="custom-badge"
            style={{ maxWidth: '150px', width: '100%', flexWrap: 'wrap' }}
          >
            {time}
          </div>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.35 }}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          height: notes ? '75px' : '40px',
          width: '95%',
        }}
      >
        <Typography
          sx={{
            color: '#bdbdbd',
            fontSize: '14px',
            lineHeight: '100.3%',
            fontFamily:
              'Poppins, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif',
            letterSpacing: '-0.711px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {notes || 'Not Added'}
        </Typography>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.35 }}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '50%',
          paddingTop: 10,
          paddingBottom: 20,
        }}
      >
        <a
          className="custom-badge"
          target="_blank"
          href={`https://google.com/maps/search/${label.split(' ').join('+')}`}
          style={{ textDecoration: 'none', fontSize: '16px' }}
          rel="noreferrer"
        >
          Search on Google
        </a>
      </motion.div>
    </div>
  );
};
