import React, { Fragment } from 'react';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import { commonButtonsSx } from './BottomSheetp2p';
import { dayCounterSignal } from '../index';
import NoResponsePausedStateP2P from './NoResponseOfPausedStateP2P';
import { lastGeneratePlaceSignal } from '..';

import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';

const DayPaused = ({ handleResume, handleMarkAsCompleted, handleCancel, messages, deleteDayX }: { handleResume: () => void, handleMarkAsCompleted: () => void, handleCancel: () => void, messages: any, deleteDayX: (reason: string) => void }) => {

  const [showDontLikeItOptions, setShowDontLikeItOptions] = React.useState(false);
  const classes = useStyles();

  return <div className="text-center">
    <SwipeableDrawer
      anchor="bottom"
      open={showDontLikeItOptions}
      onClose={() => setShowDontLikeItOptions(false)}
      onOpen={() => setShowDontLikeItOptions(true)}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{ bgcolor: 'transparent' }}
      onClick={() => setShowDontLikeItOptions(false)}
    >
      <NoResponsePausedStateP2P handleResume={handleResume} setShowDontLikeItOptions={setShowDontLikeItOptions} handleClose={handleCancel} deleteDayX={deleteDayX} />
    </SwipeableDrawer>

    <Box>
      <h4 className='px-4' >
        <b> Day {dayCounterSignal.peek()}</b>{' '}
        <b style={{ color: '#FF0000' }}>stopped</b> <b>at</b>
        <b style={{ color: '#FE7138' }}>
          {' '}
          {messages?.[messages.length - 1]?.place?.location?.text || ''}{' '}
        </b>
        <b style={{ whiteSpace: 'nowrap' }}>
          (Point {messages.length + (lastGeneratePlaceSignal.peek() ? 1 : 0)})
        </b>{' '}
        <b>at</b>{' '}
        <b style={{ color: '#FE7138' }}>
          {dayjs(
            String(messages?.[messages.length - 1]?.place?.dateTime || ''),
          )
            .tz(messages?.[messages.length - 1]?.place?.timezone || '')
            .format('hh:mm A')}
        </b>
      </h4>

      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className='d-flex align-items-center w-full justify-content-center px-4'>
          <Button
            variant="contained"
            color="primary"
            onClick={handleMarkAsCompleted}
            sx={{ ...commonButtonsSx, background: 'white', color: '#FE7138', fontSize: '16px', fontWeight: 900, marginRight: '12px', lineHeight: 'normal', maxWidth: '200px' }}
            className={`d-flex flex-wrap align-items-center ${classes.button}`}

          >
            <span>
              Mark Day {dayCounterSignal.peek()}{' '}
            </span>
            <span>
              as Completed
            </span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleResume}
            sx={{ ...commonButtonsSx, width: '194px', fontSize: '16px', fontWeight: 900 }}

          >
            Continue
          </Button>
        </div>
        <Button
          variant="text"
          onClick={() => setShowDontLikeItOptions(true)}
          sx={{ color: '#FE7138', fontSize: '16px', fontWeight: 700, textTransform: 'none', height: '64px', maxWidth: '240px', marginTop: '10px' }}

        >
          I don’t like it. Delete it.
        </Button>
      </div>
    </Box>
  </div>
}

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      color: '#FE7138',
      background: 'white',
    }
  }
}))

export default DayPaused;