import React, { Dispatch, LegacyRef, useEffect, useRef, useState } from 'react';
import {
  AnimatePresence,
  motion,
  PanInfo,
  useAnimation,
  useMotionValue,
} from 'framer-motion';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import P2PHeaderDescription from './P2PHeaderDescription';
import { Box } from '@mui/material';
import { P2PCardDetails } from './P2PCardDetails';
import P2PGoBack from './P2PGoBack';
import DaysHeader from '~/components/ViewTravel/MobileFooter/DaysHeader/DaysHeader';
import { TravelPoint } from '~/components/ViewTravel/StatsOverlay';
import { RootState, useSelector } from '~/redux/reducers';
import { useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';
import { TravelOptions, TravelPointsByDayIndex } from '~/utility/models';
import dayjs, { Dayjs } from 'dayjs';
import { convertDateToTimezoneFromUTC } from '~/utility/utils';
import { selectTravelPoints } from '~/redux/selectors/P2PManualSelectors';

const BOTTOM_SHEET_OPTIONS = {
  generating: { y: window.innerHeight - 160 },
  point: { y: window.innerHeight - 260 },
  card: { y: window.innerHeight - 600 },
};

const BOTTOM_SHEET_STATES: (keyof typeof BOTTOM_SHEET_OPTIONS)[] = [
  'generating',
  'point',
  'card',
];

interface P2PFooterProgressCardProps {
  travelPoints: TravelPoint[];
  selectedDateRange: string;
  setFitBounds: Dispatch<boolean>;
}

export const P2PFooter: React.FC<P2PFooterProgressCardProps> = ({
  travelPoints,
  selectedDateRange,
  setFitBounds,
}) => {
  const dispatch = useDispatch();
  const bottomSheetRef = useRef<HTMLDivElement>();
  const controls = useAnimation();
  const prev = useRef(0);
  const [currentVariant, setCurrentVariant] = useState<number>(0);
  const animatedY = useMotionValue(0);
  const isMobile = useIsMobile();
  const [time, setTime] = useState('');
  const [label, setLabel] = useState('');
  const [images, setImages] = useState('');
  const [price, setPrice] = useState(0);
  const [isCardClicked, setIsCardClicked] = useState(false);

  const mapPointSelectedIndex: number = useSelector(
    (state: RootState) =>
      state.P2PManualReducers.mapPointSelectedIndex as number,
  );

  const currentTravelDay: number = useSelector(
    (state: RootState) => state.TripInfoReducers.currentTravelDay as number,
  );

  const isPointClicked: boolean = useSelector(
    (state: RootState) => state.P2PManualReducers.isPointClicked as boolean,
  );

  const travelOptions = useSelector(selectTravelPoints);

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );

  useEffect(() => {
    const formatTimeRange = (startTime: Dayjs, endTime: Dayjs) => {
      // Format start and end times using Dayjs's format method
      const startFormatted = startTime.format('hh:mm A'); // Format as "hh:mm AM/PM"
      const endFormatted = endTime.format('hh:mm A'); // Format as "hh:mm AM/PM"

      // Return the formatted time range
      return `${startFormatted} - ${endFormatted}`;
    };

    let point = travelOptions[mapPointSelectedIndex] as TravelOptions;

    if (currentTravelDay > 0) {
      point = travelPointsByDayIndex[currentTravelDay][mapPointSelectedIndex];
    }
    if (isPointClicked) {
      controls.start(BOTTOM_SHEET_OPTIONS[BOTTOM_SHEET_STATES[2]]);
      setLabel(point?.label || '');

      const startDate = convertDateToTimezoneFromUTC({
        date: dayjs.utc(point.startDate),
        timezone: point.timezone,
      });

      const endDate = convertDateToTimezoneFromUTC({
        date: dayjs.utc(point.startDate),
        timezone: point.timezone,
      });

      const time = formatTimeRange(startDate, endDate);
      setTime(time);
    } else {
      setFitBounds(true);
    }
  }, [isPointClicked]);

  const onDragEnd = (_: PointerEvent, info: PanInfo) => {
    const y = bottomSheetRef.current?.getBoundingClientRect().top || 0;

    const velocity =
      Math.abs(info.velocity.y) > Math.abs(info.velocity.x)
        ? info.velocity.y
        : 0;

    let dragPosition = 0;
    if (y >= window.innerHeight * 0.75) {
      dragPosition = 0;
    } else if (y < window.innerHeight * 0.75 && y > window.innerHeight * 0.35) {
      dragPosition = 1;
    } else {
      dragPosition = 2;
    }

    let velocityPosition =
      velocity > 100
        ? Math.max(0, currentVariant - 1)
        : velocity < -100
        ? Math.min(2, currentVariant + 1)
        : dragPosition;

    if (velocity === 0) {
      velocityPosition = dragPosition;
    }
    controls.start(BOTTOM_SHEET_OPTIONS[BOTTOM_SHEET_STATES[velocityPosition]]);
    prev.current = currentVariant;
    setCurrentVariant(velocityPosition);
  };

  const onBackClick = () => {
    setIsCardClicked(false);
    dispatch(ActionsCreator.setIsMapPointClicked(false));
    dispatch(ActionsCreator.setIsMapPointSelectedIndex(0));
    controls.start(BOTTOM_SHEET_OPTIONS[BOTTOM_SHEET_STATES[1]]);
  };

  return (
    <AnimatePresence>
      {isPointClicked && (
        <Box
          style={{
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '1100px',
            bottom: 100,
          }}
        >
          <P2PGoBack onBackClick={onBackClick} />
        </Box>
      )}

      <motion.div
        ref={bottomSheetRef as LegacyRef<HTMLDivElement>}
        dragDirectionLock
        drag="y"
        onDragEnd={onDragEnd}
        initial={'point'}
        animate={controls}
        transition={{
          type: 'spring',
          damping: 40,
          stiffness: 400,
        }}
        variants={BOTTOM_SHEET_OPTIONS}
        dragConstraints={{ top: 0 }}
        dragElastic={0.2}
        style={{
          y: animatedY,
          position: 'fixed',
          display: 'block',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 0,
          backgroundColor: 'white',
          width: isMobile ? '100%' : '40%',
          margin: 'auto',
          height: window.innerHeight,
          border: '1px solid #E0E0E0',
          boxShadow:
            '0px 2px 5px rgba(0, 0, 0, 0.06), 0px 2px 13px rgba(0, 0, 0, 0.12)',
          borderRadius: '15px 15px 0px 0px',
          paddingRight: '5px',
          paddingLeft: '5px',
          fontFamily: 'Poppins',
        }}
      >
        <motion.div
          className="DragHandleEdge"
          style={{
            touchAction: 'none',
            paddingTop: 5,
            paddingBottom: 5,
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <motion.div
            className="DragHandle"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.15 }}
          />
        </motion.div>
        {!isPointClicked && (
          <P2PHeaderDescription
            selectedDateRange={selectedDateRange}
            travelData={travelPoints}
          />
        )}

        {!isPointClicked && (
          <motion.div
            animate={{ y: 0 }}
            initial={{ y: 0 }}
            transition={{ type: 'tween' }}
          >
            <div
              style={{
                height: 180,
                overflow: 'hidden',
              }}
            >
              <DaysHeader travelData={travelPoints} />
            </div>
          </motion.div>
        )}

        {isPointClicked && (
          <P2PCardDetails
            label={label}
            images={'/hotelImgDef.png'}
            time={time}
            price={price}
            currentDayIndex={currentTravelDay}
            rating={0}
            notes={''}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
};
