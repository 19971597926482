import React, { useEffect, useRef, useState } from 'react';
import {
  LngLatBounds,
  LngLatLike,
  Map,
  Marker,
  PositionAnchor,
} from 'maplibre-gl';
import { Box } from '@mui/material';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import StaticTravelVisualizer from '~/animationEngine/StaticTravelVisualizer';
import {
  TravelOptions,
  TravelPointsByDayIndex,
  URLConfig,
} from '~/utility/models';
import mapStyles from '~/map/utility/mapStyles';
import { transformedTravelPointsByDayIndex } from '~/utility/utils';
import { getCustomIcon } from '~/animationEngine/StaticTravelVisualizer/getCustomIcon';
import { P2PFooter } from './P2PFooter';
import { Location, TravelPoint } from '~/components/ViewTravel/StatsOverlay';
import { RootState, useSelector } from '~/redux/reducers';
import { lineString } from '@turf/turf';
import { useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';

type P2PManualMapProps = {
  travelPoints: TravelOptions[];
  travelPointsByDayIndex: TravelPointsByDayIndex;
};

const P2PManualMap: React.FC<P2PManualMapProps> = ({
  travelPoints,
  travelPointsByDayIndex,
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const wrapper = useRef<CustomThreeJSWrapper>();
  const travelVisualizer = useRef<StaticTravelVisualizer>();

  const [selectedDateRange, setSelectedDateRange] = useState('');
  const [travelPointsForFooter, setTravelPointsForFooter] = useState<
    TravelPoint[]
  >([]);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const markersRef = useRef<{ id: string; marker: maplibregl.Marker }[]>([]);
  const map = useRef<Map>();
  const [pointsToShow, setPointsToShow] = useState<TravelOptions[]>([]);
  const [isFitBounds, setFitBounds] = useState<boolean>(false);
  const lineRefs = useRef<any[]>([]);

  const currentTravelDay: number = useSelector(
    (state: RootState) => state.TripInfoReducers.currentTravelDay as number,
  );

  useEffect(() => {
    if (!map.current) {
      const lat = 45.92;
      const lng = 6.87;

      map.current = new Map({
        container: mapContainer.current as HTMLElement,
        attributionControl: false,
        style: (mapStyles[0] as URLConfig).isURL
          ? ((mapStyles[0] as URLConfig).URL as string)
          : (mapStyles[0] as maplibregl.StyleSpecification),
        center: [lng, lat],
        maxPitch: 80,
        maxZoom: 18,
        minZoom: isMobile ? 0.25 : 1.25,
        maplibreLogo: false,
        zoom: 14,
      });

      wrapper.current = new CustomThreeJSWrapper(
        map.current,
        map.current.getCanvas().getContext('webgl') as WebGLRenderingContext,
      );

      travelVisualizer.current = new StaticTravelVisualizer(
        map.current!,
        wrapper.current!,
      );

      map.current?.on('load', async () => {
        const imagedata = await map.current?.loadImage('/next.png');
        const image = await convertImageBitmapToHTMLImageElement(
          imagedata?.data as ImageBitmap,
        );

        map.current?.addImage('arrow-icon', image);
      });

      dispatch(ActionsCreator.setIsMapPointClicked(false));
      dispatch(ActionsCreator.setIsMapPointSelectedIndex(0));
    }
  });

  const convertImageBitmapToHTMLImageElement = async (
    imageBitmap: ImageBitmap,
  ): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      // Create a new HTMLImageElement
      const img = new Image();

      // Once the Image is loaded, resolve the promise with the image element
      img.onload = () => {
        resolve(img);
      };

      // Handle any error loading the image
      img.onerror = (error) => {
        reject(new Error('Failed to load ImageBitmap into HTMLImageElement'));
      };

      // Create a canvas to draw the ImageBitmap onto
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (ctx) {
        // Set canvas dimensions to match the ImageBitmap
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;

        // Draw the ImageBitmap onto the canvas
        ctx.drawImage(imageBitmap, 0, 0);

        // Create a data URL from the canvas content
        const dataURL = canvas.toDataURL();

        // Set the src of the HTMLImageElement to the data URL
        img.src = dataURL;
      } else {
        reject(new Error('Unable to get canvas context'));
      }
    });
  };

  useEffect(() => {
    function transformResponse(data: any[]): TravelPoint[] {
      return data.map((item) => ({
        arrival: {
          location: transformLocation(item.arrival.location),
          dateTime: new Date(item.arrival.dateTime),
          category: item.arrival.category,
          timezone: item.arrival.timezone,
          images: item.arrival.images || [],
          cost: item.arrival.cost || 0,
          review: item.arrival.review || 0,
          longDescription: item.arrival.longDescription,
          description: item.arrival.description,
        },
        departure: {
          location: transformLocation(item.departure.location),
          dateTime: new Date(item.departure.dateTime),
          category: item.departure.category || '',
          timezone: item.departure.timezone || '',
          images: item.departure.images || [],
          cost: item.departure.cost || 0,
          review: item.departure.review || 0,
          longDescription: item.departure.longDescription,
          description: item.departure.description,
        },
        selectedTransport: item.selectedTransport,
        encodedPath: item.encodedPath?.path || '',
      }));
    }

    function transformLocation(location: any): Location {
      return {
        value: location.value,
        label: location.label,
        text: location.text,
        code: location.code,
        city: location.city,
        country: location.country,
        coordinates: location.coordinates,
        placeId: location.placeId,
        street: location.street,
      };
    }

    // If there are travel points, transform them to points for the footer
    if (travelPoints.length > 0) {
      // Get startDate from first element and endDate from last element
      const startDate = new Date(travelPoints[0].startDate);
      const endDate = new Date(travelPoints[travelPoints.length - 1].endDate);

      // Format options
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
      };

      // Generate time range string
      const timeRange = `${startDate.toLocaleDateString(
        'en-US',
        options,
      )} - ${endDate.toLocaleDateString(
        'en-US',
        options,
      )}, ${endDate.getFullYear()}`;

      setSelectedDateRange(timeRange);
    }
    if (travelPointsByDayIndex) {
      const points = transformedTravelPointsByDayIndex(travelPointsByDayIndex);

      const tp = transformResponse(points);
      setTravelPointsForFooter(tp);
      setPointsToShow(travelPoints);
    }
  }, [travelPoints, travelPointsByDayIndex]);

  const getUniqueTravelPoints = (travelPoints: TravelOptions[]) => {
    const uniquePlaceIds = new Set<string>(); // To track unique placeIds
    const uniqueTravelPoints: TravelOptions[] = []; // To store unique travel points

    travelPoints.forEach((point) => {
      const placeId = point.placeId;

      if (!placeId) {
        console.warn(`Missing placeId for a travel point`);
        return;
      }

      // Add point to uniqueTravelPoints if its placeId is not already in the Set
      if (!uniquePlaceIds.has(placeId)) {
        uniquePlaceIds.add(placeId); // Mark the placeId as added
        uniqueTravelPoints.push(point); // Add the unique point to the array
      }
    });

    return uniqueTravelPoints;
  };

  const addMarkersToMap = (travelPoints: TravelOptions[]) => {
    const pointsToShow = getUniqueTravelPoints(travelPoints);
    pointsToShow.forEach((point, index) => {
      showPoint(point, index);
    });
  };

  const fitBounds = (allPoints: TravelOptions[]) => {
    if (allPoints.length === 0) return; // No points to handle

    // Calculate dynamic padding values
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const padding = Math.max(viewportWidth * 0.1, 50); // At least 50px padding on sides
    const paddingTop = Math.max(viewportHeight * 0.15, 80); // Allow room for header/toolbars
    const paddingBottom = Math.max(viewportHeight * 0.2, 100); // Room for bottom UI (e.g., navigation)

    if (allPoints.length === 1) {
      // If there's only one point, flyTo it
      const singlePoint = allPoints[0].coordinates as LngLatLike;
      map.current?.flyTo({
        center: singlePoint,
        zoom: 12, // Adjust zoom level as needed
        pitch: 0,
      });
    } else {
      // If multiple points, fit bounds
      const firstPointLngLat = allPoints[0].coordinates as LngLatLike;
      const bounds = new LngLatBounds(
        firstPointLngLat as LngLatLike,
        firstPointLngLat as LngLatLike,
      );

      for (let index = 1; index < allPoints.length; index++) {
        const point = allPoints[index].coordinates;
        bounds.extend(point as LngLatLike);
      }

      map.current?.fitBounds(bounds as LngLatBounds, {
        pitch: 0,
        padding: {
          top: paddingTop,
          bottom: paddingBottom,
          left: padding,
          right: padding,
        },
      });
    }
  };

  useEffect(() => {
    if (pointsToShow.length < 0) return;
    addMarkersToMap(pointsToShow);
    addLinesBetweenPoints(pointsToShow);
    fitBounds(pointsToShow);
  }, [pointsToShow]);

  useEffect(() => {
    if (isFitBounds) {
      fitBounds(pointsToShow);
      setFitBounds(false);
    }
  }, [isFitBounds]);

  useEffect(() => {
    clearAllMarkers(markersRef);
    removeAllLines();
    if (currentTravelDay) {
      setPointsToShow(travelPointsByDayIndex[currentTravelDay]);
    } else {
      setPointsToShow(travelPoints);
    }
  }, [currentTravelDay]);

  const showPoint = (point: TravelOptions, index: number) => {
    const coordinates = point.coordinates as LngLatLike;
    const label = point.label || '';
    const category = point.typeOfPoint;

    const iconToRender = getCustomIcon({ index: index + 1, category });

    const customIcon = `
      <div style="display: flex; flex-direction: column; align-items: center; position: relative">
        <img style="height: 43px; width: 38px" src='${iconToRender}'></img>
        <span style="
          background-color: white; 
          font-size: 12px; 
          font-weight: 600; 
          height: 19px;
          padding: 2px 4px; 
          border-radius: 12px; 
          line-height: 15px;
          max-width: 150px; 
          text-align: center;   
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          position: absolute;
          bottom: -22px;
          font-family: 'Poppins', sans-serif;">
          ${label}
        </span>
      </div>
    `;

    const customMarker = document.createElement('div');
    customMarker.innerHTML = customIcon;

    addMarker(`marker-${index}`, coordinates, customMarker, 'bottom');
  };

  const addLinesBetweenPoints = (points: TravelOptions[]) => {
    if (points.length < 2) return; // No lines needed if fewer than 2 points

    const lineCoordinates = points.map((point) => point.coordinates as any);
    const line = lineString(lineCoordinates);

    const lineSource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [line],
      },
    };

    // Check if the source exists
    const existingSource = map.current?.getSource('line-source') as
      | maplibregl.GeoJSONSource
      | undefined;

    if (existingSource) {
      // Update data if source already exists
      existingSource.setData(lineSource.data as any);
    } else {
      // Add new source and layer
      map.current?.addSource('line-source', lineSource as any);

      map.current?.addLayer({
        id: 'line-layer',
        type: 'line',
        source: 'line-source',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#000', // Line color
          'line-width': 1, // Line thickness
          'line-opacity': 0.8, // Line opacity
        },
      });

      map.current?.addLayer({
        id: 'arrow-layer',
        type: 'symbol',
        source: 'line-source',
        layout: {
          'symbol-placement': 'line', // Place the symbols along the line
          'symbol-spacing': 50, // Space between arrows
          'icon-image': 'arrow-icon', // Custom arrow icon
          'icon-rotate': 0, // Rotate the arrows (MapLibre auto-aligns to line direction)
          'icon-size': 1, // Adjust size of the arrows
        },
      });
    }
  };

  const removeAllLines = () => {
    lineRefs.current.forEach(({ id, layerId }) => {
      if (map.current?.getLayer(layerId)) map.current?.removeLayer(layerId);
      if (map.current?.getSource(id)) map.current?.removeSource(id);
    });
    lineRefs.current = [];
  };

  const addMarker = (
    id: string,
    coordinates: LngLatLike,
    customMarker: HTMLDivElement,
    anchor: PositionAnchor,
  ) => {
    const marker = new Marker({ element: customMarker, anchor: anchor })
      .setLngLat(coordinates)
      .addTo(map.current!); // Assuming `map` is already initialized

    marker.getElement().addEventListener('click', () => {
      // Log or handle the marker data
      console.log(`Marker clicked: ${id}`, coordinates);
      handleMarkerClick({ id, coordinates });
    });

    // Add the new marker to the ref array
    markersRef.current.push({ id, marker });
  };

  // Function to remove a marker by id
  const removeMarker = (id: string) => {
    const markerIndex = markersRef.current.findIndex((m) => m.id === id);
    if (markerIndex !== -1) {
      // Remove marker from the map
      markersRef.current[markerIndex].marker.remove();

      // Remove marker from the array
      markersRef.current.splice(markerIndex, 1);
    }
  };

  function clearAllMarkers(
    markersRef: React.MutableRefObject<
      {
        id: string;
        marker: maplibregl.Marker;
      }[]
    >,
  ) {
    if (!markersRef.current) return;

    // Iterate over all markers and remove them
    markersRef.current.forEach(({ marker }) => {
      if (marker && typeof marker.remove === 'function') {
        marker.remove();
      }
    });

    // Clear the reference array
    markersRef.current = [];
  }

  const handleMarkerClick = (markerData: {
    id: string;
    coordinates: LngLatLike;
  }) => {
    // Extract the index from the marker id (assuming the format is "marker-<index>")
    const index = parseInt(markerData.id.split('-')[1], 10); // Splits by "-" and takes the second part as the index

    console.log('Marker Index:', index);

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const padding = Math.max(viewportWidth * 0.1, 50); // At least 50px padding on sides
    const paddingTop = Math.max(viewportHeight * 0.15, 80); // Allow room for header/toolbars
    const paddingBottom = Math.max(viewportHeight * 0.2, 500); // Room for bottom UI (e.g., navigation)

    // Example: Center map on marker
    map.current?.flyTo({
      center: markerData.coordinates,
      zoom: 14,
      padding: {
        top: paddingTop,
        bottom: paddingBottom,
        left: padding,
        right: padding,
      },
    });

    dispatch(ActionsCreator.setIsMapPointClicked(true));
    dispatch(ActionsCreator.setIsMapPointSelectedIndex(index));
  };

  const handleClose = (reason?: string) => {
    if (reason) {
      //   const data = messages[messages.length - 1];
      //   const placeIndex = +data.placeIndex;
      //   const transportationIndex = +data.transportationIndex;
      //   const id = data.place.location?.placeId;
      //   removeMarker(id!);
    }
  };

  const updateMarkers = (lastPlaceGenerated: any, messages: any[]) => {
    markersRef.current.forEach((marker) => {
      if (marker.id === lastPlaceGenerated.place.location?.placeId) {
        marker.marker.remove();
        // showPoint(messages);
        return;
      }

      marker.marker.setOpacity('0.2');
    });
  };

  return (
    <>
      <Box
        sx={{ height: isMobile ? '500px' : '500px', width: '100%' }}
        ref={mapContainer}
      />
      <P2PFooter
        travelPoints={travelPointsForFooter}
        selectedDateRange={selectedDateRange}
        setFitBounds={setFitBounds}
      />
    </>
  );
};

export default P2PManualMap;
