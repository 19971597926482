import { useMemo, useState } from 'react';
import { ITodoTravelPoint } from '~/components/AddToTrip';
import { TiktokItem } from '~/components/DiscoverMenu/TiktokItem/TiktokItem';

export const AddThingsToDo = ({
  todo,
  close,
  isViewTravel,
  childTitle,
  open,
}: {
  todo: ITodoTravelPoint;
  close: () => void;
  isViewTravel?: boolean;
  childTitle?: string;
  open: boolean;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(open);

  const handleNext = (currentValue: number) => {};

  const handlePrev = (currentValue: number) => {};

  const content = useMemo(() => {
    return {
      name: todo.value,
      price: todo.cost,
      review: todo.review,
      category: todo?.todoCategory ?? 'food',
      url_book_place: todo.urlBookPlace ?? '',
      summary: todo.description,
      notes: todo.longDescription,
      media: todo.tiktokData,
      coords: todo.coordinates,
      storyIndex: 1,
    };
  }, [todo]);

  const handleClose = () => {
    setIsModalOpen(false);
    close();
  };

  return (
    <>
      {isModalOpen && (
        <div
          style={
            isViewTravel
              ? {}
              : {
                  width: '100vw',
                  height: '100vh',
                  background: '#000',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 999999,
                }
          }
        >
          {content && content.storyIndex !== -1 && (
            <TiktokItem
              content={content}
              goNext={handleNext}
              goPrev={handlePrev}
              handleClose={handleClose}
              currentStoryIndex={content.storyIndex!}
              point={todo}
              isViewTravel={isViewTravel}
              childTitle={childTitle}
            />
          )}
        </div>
      )}
    </>
  );
};
