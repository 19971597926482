import { useDispatch as useReduxDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducerWithReset from './reducers';

// Creating the store
export const store = configureStore({
  reducer: rootReducerWithReset,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {
        ignoredPaths: [
          'TripInfoReducers.travelRawData.travelPoints',
          'TripInfoReducers.travelData',
          'MapReducers.publishableTravelData',
        ],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useDispatch: () => AppDispatch = useReduxDispatch;
