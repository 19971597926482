/**
 * A modal dialog for adding and managing music files.
 * This component allows users to upload new music files, view existing ones, and play or remove them.
 * It includes drag-and-drop support, file upload, and playback controls.
 *
 * @param open - A boolean indicating whether the modal is open or closed.
 * @param handleClose - A function to call when closing the modal.
 * @param loading - A boolean indicating if a file is being uploaded or processed.
 * @param handleFileChange - A function to handle file input changes when uploading a new music file.
 * @param handleRemoveAudio - A function to remove the currently selected audio file.
 * @param handleDrop - A function to handle file drop events for drag-and-drop functionality.
 * @param handleDragOver - A function to handle drag-over events for drag-and-drop functionality.
 * @param isErrorOpen - A boolean indicating if an error message should be displayed.
 * @param errorMessage - A string containing the error message to be displayed.
 * @param setIsErrorOpen - A function to update the visibility of the error message.
 *
 * @returns A React component that displays a modal dialog with music upload and playback features.
 *
 * @example
 * ```tsx
 * <AddMusicModal
 *   open={isModalOpen}
 *   handleClose={closeModal}
 *   loading={isUploading}
 *   handleFileChange={handleFileChange}
 *   handleRemoveAudio={handleRemoveAudio}
 *   handleDrop={handleDrop}
 *   handleDragOver={handleDragOver}
 *   isErrorOpen={isErrorOpen}
 *   errorMessage={errorMessage}
 *   setIsErrorOpen={setIsErrorOpen}
 * />
 * ```
 */
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import {
  Alert,
  Box,
  CircularProgress,
  Slide,
  Snackbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useStyles from './styles';
import { formatDuration, getAllFilesInFolder } from '~/utility/utils';
import { TransitionProps } from '@mui/material/transitions';
import { store } from '~/redux/store';
import MusicLibrary, { IAudioFile } from './MusicLibrary'; // Import MusicLibrary component
import { addMusicSignal } from '~/components/signals/musicSignals';
import { useSignals } from '@preact/signals-react/runtime';
import subscribeSignal from '~/hooks/subscribeSignal';

interface AddMusicModalProps {
  open: boolean;
  handleClose: () => void;
  loading: boolean;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveAudio: () => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  isErrorOpen: boolean;
  errorMessage: string;
  setIsErrorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddMusicModal: React.FC<AddMusicModalProps> = ({
  open,
  handleClose,
  loading,
  handleDragOver,
  handleDrop,
  handleFileChange,
  handleRemoveAudio,
  isErrorOpen,
  errorMessage,
  setIsErrorOpen,
}) => {
  const classes = useStyles();
  useSignals();

  const [audioPlaying, setAudioPlaying] = useState(false);
  const [currentAudioId, setCurrentAudioId] = useState<string | null>(null); // Track currently playing audio id

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [filesInFolder, setFilesInFolder] = useState<IAudioFile[] | undefined>(
    [],
  ); // State to hold files fetched from storage
  const [existingFileLoader, setExistingFileLoader] = useState<boolean>(false);

  const [displayedFiles, setDisplayedFiles] = useState<IAudioFile[]>([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    /**
     * Asynchronously fetches a list of audio files from a specified folder in storage.
     * This function is triggered when the modal is opened. It fetches the list of files, handles
     * potential errors, and updates the state based on the results. The function also manages the
     * loading state and error display.
     *
     * The function performs the following steps:
     * 1. Checks if the modal is open.
     * 2. Retrieves the user ID from the Redux store.
     * 3. Sets the loading state to `true` while fetching files.
     * 4. Calls `getAllFilesInFolder` with the folder name and user ID to fetch files.
     * 5. If the fetch is successful, updates the state with the retrieved files.
     * 6. If the fetch fails, sets an error state to show an alert and logs the error to the console.
     * 7. Ensures that the loading state is set to `false` after the fetch operation completes, regardless of success or failure.
     *
     * @async
     * @function fetchFiles
     *
     * @throws Will set the error state and log an error if fetching files fails.
     *
     * @returns {Promise<void>} A promise that resolves when the file fetching operation is complete.
     *
     * @example
     * // Example usage within a component
     * useEffect(() => {
     *   fetchFiles();
     * }, [open]);
     */
    const fetchFiles = async () => {
      if (open) {
        const userId = store.getState().MapReducers.userID;
        setExistingFileLoader(true);
        setShowAll(false);
        try {
          // Fetch files from storage when modal opens
          const { success, files, error } = await getAllFilesInFolder(
            'travel-musics',
            userId,
          );

          if (success) {
            setFilesInFolder(files);
          } else {
            setIsErrorOpen(true); // Show error alert if fetching files fails
            console.error('Error fetching files:', error);
          }
        } catch (error) {
          setIsErrorOpen(true); // Show error alert if fetching files fails
        } finally {
          setExistingFileLoader(false);
        }
      }
    };

    fetchFiles();
  }, [open, setIsErrorOpen]);

  useEffect(() => {
    if (filesInFolder && filesInFolder?.length > 0) {
      // Initially display only the first 4 items
      setDisplayedFiles(filesInFolder.slice(0, 4));
    }
  }, [filesInFolder]);

  /**
   * Handles the "See More" button click event to display all available files.
   *
   * When the "See More" button is clicked, this function updates the state to show
   * all items in the `filesInFolder` array and sets a flag to indicate that all items are being displayed.
   *
   * The function performs the following steps:
   * 1. Updates the state `displayedFiles` to include all files from `filesInFolder`.
   * 2. Sets the state `showAll` to `true`, indicating that all items should be shown.
   *
   * This function is typically used to reveal additional items in a list or gallery when a user clicks a "See More" button.
   *
   * @function handleSeeMore
   *
   * @returns {void}
   *
   * @example
   * // Example usage in a component
   * return (
   *   <div>
   *     {displayedFiles.map(file => (
   *       <FileItem key={file.id} file={file} />
   *     ))}
   *     {!showAll && filesInFolder.length > 4 && (
   *       <button onClick={handleSeeMore}>See More</button>
   *     )}
   *   </div>
   * );
   */
  const handleSeeMore = () => {
    // Show all items when "See More" button is clicked
    setDisplayedFiles(filesInFolder!);
    setShowAll(true);
  };

  /**
   * Toggles the play/pause state of an audio element based on the provided audio ID.
   *
   * This function handles the play/pause functionality for an audio element. It checks if the provided
   * `audioId` matches the currently playing audio, and either toggles its play/pause state or plays
   * the new audio file, pausing the currently playing one.
   *
   * The function performs the following steps:
   * 1. Retrieves the current audio element from the `audioRef`.
   * 2. Sets `showAudioElement` to `true` to ensure the audio element is displayed.
   * 3. Finds the file corresponding to the given `audioId` from the `filesInFolder` array or falls back to `audioState`.
   * 4. If the file is found, checks if it is the same as the currently playing file:
   *    - If it is the same file, toggles the play/pause state.
   *    - If it is a different file, updates the audio source, plays the new audio, and updates the current audio ID.
   * 5. If the file is not found, sets `showAudioElement` to `false`.
   *
   * @param {string} audioId - The ID of the audio file to play or pause.
   *
   * @returns {void}
   *
   * @example
   * // Example usage in a component
   * return (
   *   <button onClick={() => togglePlayPause('audio-file-id')}>
   *     {isPlaying ? 'Pause' : 'Play'}
   *   </button>
   * );
   */
  const togglePlayPause = (audioId: string) => {
    const audioElement = audioRef.current;

    if (audioElement) {
      const fileToPlay =
        filesInFolder?.find((file) => file.id === audioId) ||
        addMusicSignal.peek();

      if (!fileToPlay) return;

      if (currentAudioId === audioId) {
        // Toggle play/pause for the same audio
        audioElement.paused ? audioElement.play() : audioElement.pause();
        setAudioPlaying(!audioElement.paused);
      } else {
        // Play the clicked audio and pause the current one
        audioElement.src = fileToPlay.url;
        audioElement.play();
        setCurrentAudioId(audioId);
        setAudioPlaying(true);
      }
    }
  };

  const stopPlaying = () => {
    const audioElement = audioRef.current;

    if (audioElement) {
      audioElement.pause();
    }
  };

  const chooseFromLibrary = ({
    name,
    url,
    audioDuration,
  }: {
    name: string;
    url: string;
    audioDuration: number;
  }) => {
    setAudioPlaying(false);

    addMusicSignal.value = {
      id: 'audio-1',
      name: name,
      size: 0,
      duration: audioDuration,
      type: 'audio/mpeg',
      url: url,
    };
    stopPlaying();
  };

  /**
   * Handles the event when the audio playback ends.
   *
   * This function is called when the audio element's playback has ended. It updates the state to
   * indicate that the audio is no longer playing.
   *
   * It performs the following actions:
   * - Sets the `audioPlaying` state to `false` to reflect that no audio is currently playing.
   *
   * @returns {void}
   *
   * @example
   * // Example usage in an audio element
   * <audio
   *   src="audio-file-url.mp3"
   *   onEnded={handleAudioEnded}
   * />
   */
  const handleAudioEnded = () => {
    setAudioPlaying(false);
  };

  /**
   * Removes the currently selected audio and stops playback.
   *
   * This function invokes `handleRemoveAudio` to remove the audio and then updates the state
   * to stop any ongoing audio playback.
   *
   * It performs the following actions:
   * - Calls `handleRemoveAudio` to remove the audio file.
   * - Sets the `audioPlaying` state to `false` to stop playback.
   *
   * @returns {void}
   *
   * @example
   * // Example usage in a button click handler
   * <button onClick={removeAudio}>Remove Audio</button>
   */
  const removeAudio = () => {
    handleRemoveAudio();
    setAudioPlaying(false);
  };

  /**
   * Closes the modal, stops audio playback, and hides the audio element.
   *
   * This function is called when the modal is closed. It resets the audio element reference,
   * hides the audio element, stops playback, and then calls `handleClose` to close the modal.
   *
   * It performs the following actions:
   * - Sets the `audioRef.current` to `null` to clear the reference to the audio element.
   * - Sets `audioPlaying` to `false` to stop any audio playback.
   * - Calls `handleClose` to perform any additional cleanup required to close the modal.
   *
   * @returns {void}
   *
   * @example
   * // Example usage in a modal's onClose event
   * <Modal onClose={onClose}>
   *  Modal content here *
   * </Modal>
   */
  const onClose = () => {
    setAudioPlaying(false);
    audioRef.current = null;
    handleClose();
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  const [addMusicSignalValue, setAddMusicSignalValue] = useState(addMusicSignal.peek())
  subscribeSignal(addMusicSignal, setAddMusicSignalValue);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      // maxWidth={isMobile ? 'lg' : 'md'}
      fullWidth
      aria-labelledby="add-music-dialog"
    >
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={() => setIsErrorOpen(false)} // Close the Snackbar on action
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // Position the Snackbar at bottom left
        TransitionComponent={Slide} // Use the Slide component for the transition
        TransitionProps={{ direction: 'right' } as TransitionProps} // Slide from right to left
      >
        <Alert
          variant="filled"
          severity="error"
          onClose={() => setIsErrorOpen(false)}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <div style={{ padding: '1rem' }}>
        <DialogTitle id="add-music-title" className={classes.dialogTitle}>
          <img
            aria-label="close"
            src="/icons/delete.svg"
            alt="cancel"
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              padding: '18px',
              marginBottom: 10,
              cursor: 'pointer',
            }}
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent
          className={classes.dialogContent}
          style={{
            padding: 0,
            margin: 0,
            cursor: loading ? 'disabled' : 'auto',
          }}
        >
          <Box
            className={classes.fileContainer}
            onDragOver={loading ? undefined : handleDragOver}
            onDrop={loading ? undefined : handleDrop}
          >
            <Box className={classes.dragAndDropParent}>
              <div
                style={{
                  position: 'relative',
                }}
              >
                {loading ? (
                  <img
                    alt="Music icon"
                    src="/icons/music-disabled.svg"
                    style={{
                      position: 'relative',
                      height: '64px',
                      width: '64px',
                    }}
                  />
                ) : (
                  <img
                    alt="Music icon"
                    src="/icons/music-active.svg"
                    style={{
                      position: 'relative',
                      height: isMobile ? '46px' : 'auto',
                      width: isMobile ? '46px' : 'auto',
                    }}
                  />
                )}

                {addMusicSignalValue?.url && (
                  <img
                    alt="Delete icon"
                    src="/icons/delete.svg"
                    className={classes.deleteIcon}
                    onClick={removeAudio}
                  />
                )}
              </div>
              <div>
                {addMusicSignal.value && addMusicSignal.value?.url ? (
                  <>
                    <Typography className={classes.fileSelectionText}>
                      {addMusicSignalValue.name}
                    </Typography>
                    <Typography className={classes.fileSelectionSubtext}>
                      Duration: {formatDuration(addMusicSignalValue.duration)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography className={classes.fileSelectionText}>
                      Drag & drop an MP3 file here
                    </Typography>
                    <Typography className={classes.fileSelectionSubtext}>
                      Mp3 formats, up to 50MB
                    </Typography>
                  </>
                )}
              </div>
            </Box>
            <div>
              {addMusicSignalValue?.url ? (
                <button
                  className={classes.playPauseButton}
                  onClick={() => togglePlayPause(addMusicSignalValue.id)} // Pass the audio id
                  disabled={loading}
                  style={{
                    cursor: loading ? 'disabled' : 'pointer',
                  }}
                >
                  <audio
                    ref={audioRef}
                    src={addMusicSignalValue.url}
                    onEnded={handleAudioEnded}
                    autoPlay={audioPlaying}
                    style={{ display: 'none' }}
                  />

                  {addMusicSignalValue.id === currentAudioId &&
                    audioPlaying ? (
                    <>
                      <svg
                        fill="currentColor"
                        stroke-width="0"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        height="30px"
                        width="30px"
                        style={{ overflow: 'visible', color: '#ED6934' }}
                      >
                        <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48Zm-32 288h-32V176h32Zm96 0h-32V176h32Z"></path>
                      </svg>
                    </>
                  ) : (
                    <img src="/icons/play-button.svg" alt="Play button" />
                  )}
                </button>
              ) : (
                <label
                  htmlFor="music-file"
                  className={classes.uploadButton}
                  style={{
                    cursor: loading ? 'disabled' : 'auto',
                  }}
                >
                  <input
                    className={classes.fileInput}
                    disabled={loading}
                    style={{ display: 'none' }}
                    id="music-file"
                    type="file"
                    accept=".mp3"
                    onChange={handleFileChange}
                  />
                  {loading ? 'Uploading...' : ' Upload'}
                </label>
              )}
            </div>
          </Box>

          <Box className={classes.libraryContainer}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginTop: '2rem',
              }}
              className={classes.heading}
            >
              Add from library
            </Typography>
            <div className={classes.libraryWrapper}>
              {existingFileLoader ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <CircularProgress color="primary" size={20} />
                </Box>
              ) : (
                <div className={classes.libraryWrapper}>
                  {displayedFiles && displayedFiles?.length > 0 ? (
                    displayedFiles.map((file) => (
                      <MusicLibrary
                        key={file.id}
                        file={file}
                        loading={loading}
                        isPlaying={file.id === currentAudioId && audioPlaying}
                        togglePlayPause={togglePlayPause}
                        audioRef={audioRef} // Pass audioRef to MusicLibrary
                        setAudioPlaying={setAudioPlaying}
                        chooseFromLibrary={chooseFromLibrary}
                      />
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      <Typography
                        style={{
                          marginTop: '1rem',
                          color: '#000',
                          fontWeight: 400,
                          cursor: 'pointer',
                        }}
                      >
                        Musics you add will be shown here.
                      </Typography>
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    {!showAll && filesInFolder && filesInFolder.length > 4 && (
                      <Typography
                        onClick={handleSeeMore}
                        style={{
                          marginTop: '1rem',
                          color: '#ED6934',
                          fontWeight: 400,
                          cursor: 'pointer',
                        }}
                      >
                        See More
                      </Typography>
                    )}
                  </Box>
                </div>
              )}
            </div>
          </Box>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default AddMusicModal;
