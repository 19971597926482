import { useEffect, useState } from 'react';
import { fireCollapseBottomSheet } from '~/redux/reducers/ui.slice';
import { useDispatch } from '~/redux/store';
import {
  isCalendarAnimatingPromise,
  PauseAnimation,
  PlayAnimation,
} from '../ViewTravel/common';
import './animatedButton.css';
import useStyles from './styles';

let eventx: any;
let eventy: any;
let eventz: any;

const VideoControls = () => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();

  const handlePlay = () => {
    PlayAnimation();
    PlayAnimation();
    setIsVisible(false);
  };

  useEffect(() => {
    eventx = window.addEventListener('blur', () => {
      // PauseAnimation();
    });
    eventy = window.addEventListener('focus', () => {
      // setIsVisible(true);
    });
    eventz = window.addEventListener('visibilitychange', async () => {
      await isCalendarAnimatingPromise();
      // If the document is hidden, pause the animation
      if (document.visibilityState === 'hidden') {
        dispatch(fireCollapseBottomSheet());
        PauseAnimation();
        setIsVisible(true);
      }
    });
    return () => {
      window.removeEventListener('blur', eventx);
      window.removeEventListener('focus', eventy);
      window.removeEventListener('visibilitychange', eventz);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <div className={classes.overlay}>
      <div
        className={`${classes.videoControls} ${
          isVisible ? classes.visible : ''
        }`}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <button id="play-btn" className="play-btn" onClick={handlePlay} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoControls;
