import { useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { signal } from '@preact/signals-core';
import { TravelPoint } from '../StatsOverlay';
import { Swiper as SwiperType } from 'swiper/types';
import { selectedTravelDaySignal } from './DaysHeader/DaysHeader';
import { OverviewData } from './OverviewDataComponent';
import { durationString } from '../common';
import { getIconByCategory } from './helpers';
import dayjs from 'dayjs';
import subscribeSignal from '~/hooks/subscribeSignal';
import { useDispatch } from '~/redux/store';
import { useSelector } from '~/redux/reducers';
import {
  fireSetBottomSheetOverviewData,
  selectBottomSheetOverviewData,
} from '~/redux/reducers/ui.slice';

export const pointsSignal = signal<OverviewData[]>([]);

const PointsHerader = ({ travelData }: { travelData: TravelPoint[] }) => {
  const [dayTravel, setDayTravel] = useState<
    (TravelPoint & { travelIndex?: number })[]
  >([]);
  const [selectedTravelDaySignalValue, setSelectedTravelDaySignalValue] =
    useState<dayjs.Dayjs | undefined>(selectedTravelDaySignal.peek());
  const swiperRef = useRef<SwiperType>();

  const dispatch = useDispatch();
  const bottomSheetOverviewData = useSelector(selectBottomSheetOverviewData);

  const pointsOverview = useMemo(() => {
    if (
      dayTravel.length === 1 &&
      dayTravel[0].arrival?.id === dayTravel[0].departure?.id
    ) {
      const pp = dayTravel[0];
      return [
        {
          travelIndex: pp.travelIndex,
          index: 0,
          location: pp.arrival.location?.coordinates as any,
          name: pp.arrival.location?.label || '',
          street: pp.arrival.location.street,
          id: pp.arrival.location.placeId,
          icon: getIconByCategory(pp.arrival.category),
          description: pp.arrival.description || '',
          images: pp.arrival.images || [],
          media: pp.arrival.media,
          tiktokData: pp.arrival.tiktokData,
          duration: durationString(
            dayjs(pp.arrival.dateTime),
            dayjs(pp.departure.dateTime),
            true,
          ),
          staypointDuration: durationString(
            dayjs(pp.arrival.dateTime),
            dayjs(pp.departure.dateTime),
            true,
          ),
          price: 10,
          nextPoint: pp.arrival,
          point: pp.departure,
          type: pp.selectedTransport,
          departureDateTime: pp?.departure.dateTime,
          departureTimeZone: pp?.departure.timezone,
          arrivalDateTime: pp.arrival.dateTime,
          arrivalTimeZone: pp.arrival.timezone,
          cost: pp.arrival?.cost ?? 0,
          review: pp.arrival.review,
          longDescription: pp.arrival.longDescription,
          prevArrivalDateTime: pp.arrival.dateTime,
          prevArrivalTimeZone: pp.arrival.timezone,
        },
      ];
    }

    return [
      ...(dayTravel.length > 0
        ? [
            {
              travelIndex: dayTravel[0].travelIndex,
              index: 0,
              location: dayTravel[0]?.departure.location?.coordinates as any,
              name: dayTravel[0]?.departure.location?.label || '',
              street: dayTravel[0]?.departure.location.street,
              id: dayTravel[0]?.departure.location.placeId,
              icon: getIconByCategory(dayTravel[0]?.departure.category),
              description: dayTravel[0]?.departure.description || '',
              images: dayTravel[0]?.departure.images || [],
              price: 10,
              point: dayTravel[0]?.departure,
              type: dayTravel[0]?.selectedTransport,
              staypointDuration: 'END',
              departureDateTime: dayTravel[0]?.departure.dateTime,
              arrivalDateTime: dayTravel[0]?.arrival.dateTime,
              departureTimeZone: dayTravel[0]?.departure.timezone,
              arrivalTimeZone: dayTravel[0]?.arrival.timezone,
              nextPoint: dayTravel[0]?.arrival,
              prevPoint: undefined,
              cost: dayTravel[0]?.departure.cost,
              review: dayTravel[0]?.departure.review,
              longDescription: dayTravel[0]?.departure.longDescription,
              media: dayTravel[0]?.departure.media,
              tiktokData: dayTravel[0]?.departure.tiktokData,
            },
          ]
        : []),

      ...dayTravel.map((i, index, arr) => {
        return {
          travelIndex: i.travelIndex,
          index: index + 1,
          location: i.arrival.location?.coordinates as any,
          name: i.arrival.location?.label || '',
          street: i.arrival.location.street,
          id: i.arrival.location.placeId,
          icon: getIconByCategory(i.arrival.category),
          description: i.arrival.description || '',
          images: i.arrival.images || [],
          media: i.arrival.media,
          tiktokData: i.arrival.tiktokData,
          duration:
            index > 0
              ? durationString(
                  dayjs(arr[index - 1].arrival.dateTime),
                  dayjs(arr[index - 1].departure.dateTime),
                  true,
                )
              : undefined,
          staypointDuration:
            index === 0
              ? 'START'
              : index > 0 && index < arr.length
              ? durationString(
                  dayjs(i.departure.dateTime),
                  dayjs(arr[index - 1].arrival.dateTime),
                  true,
                )
              : undefined,
          price: 10,
          point: i.departure,
          type: i.selectedTransport,
          departureDateTime: arr[index + 1]?.departure.dateTime,
          departureTimeZone: arr[index + 1]?.departure.timezone,
          arrivalDateTime: i.arrival.dateTime,
          arrivalTimeZone: i.arrival.timezone,
          cost: i.arrival?.cost ?? 0,
          review: i.arrival.review,
          longDescription: i.arrival.longDescription,
          prevArrivalDateTime: i.arrival.dateTime,
          prevArrivalTimeZone: i.arrival.timezone,
        };
      }),
    ];
  }, [dayTravel]);

  useEffect(() => {
    pointsSignal.value = pointsOverview;
  }, [pointsOverview]);

  useEffect(() => {
    setDayTravel([]);
    setTimeout(() => {
      const filteredArray = travelData
        .map((i, idx) => ({ ...i, travelIndex: idx }))
        .filter((i) => {
          return (
            dayjs(String(i.departure.dateTime))
              .tz(i.departure.timezone)
              .format('DD-MM-YYYY') ===
            selectedTravelDaySignalValue?.format('DD-MM-YYYY')
          );
        });
      setDayTravel(
        selectedTravelDaySignalValue
          ? filteredArray
          : travelData.map((i, idx) => ({ ...i, travelIndex: idx })),
      );
    }, 50);
  }, [travelData, selectedTravelDaySignalValue]);

  subscribeSignal(
    selectedTravelDaySignal,
    (val) => {
      setSelectedTravelDaySignalValue(val);
      const filteredArray = travelData
        .map((i, idx) => ({ ...i, travelIndex: idx }))
        .filter((i) => {
          return (
            dayjs(String(i.departure.dateTime))
              .tz(i.departure.timezone)
              .format('DD-MM-YYYY') === val?.format('DD-MM-YYYY')
          );
        });
      setDayTravel(
        val
          ? filteredArray
          : travelData.map((i, idx) => ({ ...i, travelIndex: idx })),
      );
    },
    [travelData],
  );

  useEffect(() => {
    if (bottomSheetOverviewData) {
      swiperRef.current?.slideTo(bottomSheetOverviewData!.index);
    }
  }, [bottomSheetOverviewData]);

  return (
    <Swiper
      //@ts-ignore
      onSwiper={(ref) => (swiperRef.current = ref)}
      slidesPerView="auto"
      spaceBetween={10}
      slidesPerGroup={1}
      style={{ paddingTop: 5, paddingBottom: 10 }}
      centeredSlides
      longSwipesRatio={1}
    >
      {pointsOverview.map((i, idx) => {
        const selected = bottomSheetOverviewData?.index === i.index;
        return (
          <SwiperSlide style={{ width: 51 }} key={idx.toString()}>
            <div
              onClick={() => {
                dispatch(fireSetBottomSheetOverviewData(i));
                swiperRef.current?.slideTo(idx);
              }}
              style={{
                backgroundColor: selected ? '#FE7138' : '#FF91471A',

                height: 60,
                width: 51,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
                borderRadius: 12,
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: selected ? 'white' : '#FE7138',
                }}
              >
                {'Point'}
              </div>
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  color: selected ? 'white' : '#FE7138',
                }}
              >
                {idx + 1}
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default PointsHerader;
