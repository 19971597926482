import { TravelAnimation } from '../TravelAnimation';
import { State } from '../models';
import { Position, distance, point } from '@turf/turf';
import { getAnimationConfig } from '~/utility/utils';
import { MarkerInstances } from '~/utility/models';
import { TravelMode } from '../../utility/enums/TravelMode';
import {
  AnimationController,
  LandTransportAnimationConfig,
  PlaneAnimationConfig,
} from '../../AnimationController';
import { Clock } from 'three';
import { Marker } from '~/animationEngine/Marker';
import { MultiTransportAnimationController } from '~/animationEngine/MultiTransportAnimationController';
import { destinationStateSignal, originStateSignal } from '~/components/ViewTravel/common';

/**
 * This class represents the AnimateTravelSegment state within the TravelAnimation state machine.
 * It's responsible for animating the travel path between two locations along a travel segment.
 */
export class AnimateTravelSegment implements State {
  private stateMachine: TravelAnimation;
  private controller:
    | AnimationController
    | MultiTransportAnimationController
    | undefined;
  private clock = new Clock();
  private markers: MarkerInstances | undefined;
  private timeoutId: NodeJS.Timeout | null = null;
  isPaused = false;

  private timeElapsedTillPause = -1;

  /**
   * @constructor
   * @param stateMachine - A reference to the TravelAnimation state machine instance.
   */
  constructor(stateMachine: TravelAnimation) {
    this.stateMachine = stateMachine;

    // Bind `this.setState` to the current instance of the component to ensure
    // that it retains the correct context when it is called, especially when
    // passing it as a callback or using it in event handlers.
    this.setState = this.setState.bind(this);
  }

  /**
   * Called when the state machine enters the AnimateTravelSegment state.
   * - Retrieves the current travel segment data and calculates the distance between origin and destination.
   * - Fetches animation configuration based on distance and travel mode.
   * - Initializes the appropriate animation controller (PlaneAnimationController or CarAnimationController)
   *   and starts the travel path animation.
   */
  onEnter() {
    let animationConfig;
    console.log('Entered AnimateTravelSegment State');
    clearTimeout(this.timeoutId as NodeJS.Timeout);
    this.timeoutId = setTimeout(() => {
      destinationStateSignal.value = null;
    }, 500)
    if (this.stateMachine.devMode)
      console.log('Entered AnimateTravelSegment State');

    let mapCurveHeight: number | undefined;
    let travelSegment = this.stateMachine.getCurrentSegment();

    if (travelSegment.selectedTransport === TravelMode.Plane) {
      let from = point(
        travelSegment.departure.location?.coordinates as Position,
      );
      let to = point(travelSegment.arrival.location?.coordinates as Position);

      let dist: number = distance(from, to);

      mapCurveHeight = getAnimationConfig(
        dist,
        travelSegment.selectedTransport,
      )?.mapCurveHeight;
    }

    switch (travelSegment.selectedTransport) {
      case TravelMode.Plane:
        animationConfig = {
          duration: this.stateMachine.DEFAULT_ANIMATION_TOTAL_TIME * 1000,
          curveHeight: mapCurveHeight,
          onCompleteCallback: this.setState,
        } as PlaneAnimationConfig;
        break;

      case TravelMode.Car:
      case TravelMode.Transit:
      case TravelMode.Walk:
      case TravelMode.Ferry:
        animationConfig = {
          duration: 1000,
          onCompleteCallback: this.setState,
        } as LandTransportAnimationConfig;
        break;
    }

    const currentController = this.stateMachine.getCurrentController();
    currentController?.setupAnimation(animationConfig);

    this.controller = currentController;

    this.clock.start();
    // this.stateMachine.showNoTransportationParts =
    //   this.showNoTransportationPart();
    this.stateMachine.showStraightLine = this.showNoTransportationPart();
    this.stateMachine.isNextStateDestination = true;
  }

  setState() {
    this.stateMachine.setState(
      this.stateMachine.showStraightLine
        ? this.stateMachine.states.animateStraightLine
        : this.stateMachine.states.animateDestinationMarker,
    );
  }

  showNoTransportationPart() {
    let showNoTransportationParts = true;

    let currentPoint = this.stateMachine.getCurrentSegment();

    this.stateMachine.straightLineOrigin = currentPoint.departure.location
      ?.coordinates as Position;

    if (currentPoint.decodedPath.path.length > 0) {
      this.stateMachine.straightLineDestination =
        currentPoint.decodedPath.path.slice(-1)[0];
    } else if (currentPoint.decodedPath.data.length > 0) {
      this.stateMachine.straightLineDestination = currentPoint.decodedPath.data
        .slice(-1)[0]
        .path.slice(-1)[0];
    }

    const point1 = point(this.stateMachine.straightLineOrigin);
    const point2 = point(this.stateMachine.straightLineDestination);

    const distanceBetweenPoints = distance(point1, point2);

    if (distanceBetweenPoints > 0) {
      showNoTransportationParts = true;
    } else {
      showNoTransportationParts = false;
    }

    return showNoTransportationParts;
  }

  /**
   * Updates the animation based on the elapsed time since the last frame.
   *
   * This function retrieves the delta time (elapsed time since the last frame)
   * and calls the `update` method on the active animation controller
   * to advance the animation.
   */
  onUpdate(): void {
    const delta = this.clock.getDelta();
    if (this.controller) this.controller.update(delta);

    for (const controller of this.stateMachine.animationControllers) {
      this.markers = controller?.markers;
      for (const markerInstance of Object.values(
        this.markers as MarkerInstances,
      )) {
        (markerInstance as Marker).update(delta);
      }
    }
    this.stateMachine.map.repaint = true;
  }

  onPause() {
    this.isPaused = true;

    this.clock.stop();

    if (this.controller) {
      this.controller.onPause();

      // hackish way to cancel all camera animations
      this.stateMachine.map.stop();
    }
  }

  onPlay() {
    this.isPaused = false;

    this.clock.start();

    this.stateMachine.map.repaint = true;
    if (this.controller) {
      this.controller.onPlay();
    }
  }

  /**
   * Called when the state machine exits the AnimateTravelSegment state.
   */
  onExit() {
    if (this.stateMachine.devMode)
      console.log('Exiting AnimateTravelSegment state');
  }
}
