import React, { Suspense } from 'react';
import { Header, Story } from 'react-insta-stories/dist/interfaces';
import { Typography } from '@mui/material';
import { showStoriesSignal } from '..';
import {
  PauseAnimation,
  PlayAnimation,
  showPlayPauseButton,
} from '../../common';
const StoriesLazy = React.lazy(() => import('react-insta-stories'));

export const InstaStories = ({
  stories,
  setStories,
  setIsVisible,
}: {
  stories: Story[];
  setStories: (stories: Story[]) => void;
  setIsVisible: (isVisible: boolean) => void;
}) => {
  return (
    <div style={{ position: 'absolute', top: 0, zIndex: 99999 }}>
      <Suspense>
        <StoriesLazy
          header={(e: Header) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 8,
                  marginBottom: 24,
                  paddingLeft: 32,
                  paddingRight: 32,
                  left: -12,
                  justifyContent: 'space-between',
                  width: '100vw',
                  position: 'relative',
                }}
              >
                <img
                  src={e.profileImage}
                  height={48}
                  style={{ marginRight: 10 }}
                  alt="story"
                />
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <Typography
                    fontSize={24}
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    style={{
                      color: '#fff',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 1,
                    }}
                  >
                    {e.heading}
                  </Typography>
                </div>
              </div>
            );
          }}
          stories={stories}
          defaultInterval={1000}
          width={window.innerWidth}
          height={window.innerHeight}
          // isPaused={false}
          storyStyles={{
            width: undefined,
            height: undefined,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          onAllStoriesEnd={() => {
            showStoriesSignal.value = undefined;
            setStories([]);
            setIsVisible(false);
            if (showPlayPauseButton.peek()) {
              PlayAnimation();
              PlayAnimation();
              setTimeout(() => {
                PauseAnimation();
                PlayAnimation();
                PlayAnimation();
              }, 1000);
            }
          }}
        />
      </Suspense>
    </div>
  );
};
