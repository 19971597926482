export const TRAVEL_FORM_STATE = 'TRAVEL_FORM_STATE';
export const ADD_TRAVEL_POINT = 'ADD_TRAVEL_POINT';
export const HISTORY_CREATED_AT = 'HISTORY_CREATED_AT';
export const POPUP_STATE = 'POPUP_STATE';
export const SET_TRAVEL_ITINERARY_STATE = 'SET_TRAVEL_ITINERARY_STATE';
export const SET_MODIFY_TRAVEL_STATE = 'SET_MODIFY_TRAVEL_STATE';
export const DISCARD_TRAVEL_POINTS = 'DISCARD_TRAVEL_POINTS';
export const SET_TRAVEL_FORM_SAVE_STATE = 'SET_TRAVEL_FORM_SAVE_STATE';
export const SET_CONFIRMATION_DIALOG_STATE = 'SET_CONFIRMATION_DIALOG_STATE';
export const SET_SCHEDULE_SECTION_STATE = 'SET_SCHEDULE_SECTION_STATE';
export const SET_HISTORY_CARD_STATE = 'SET_HISTORY_CARD_STATE';
export const SET_SELECTED_DEPARTURE_CATEGORY =
  'SET_SELECTED_DEPARTURE_CATEGORY';
export const SET_SELECTED_ARRIVAL_CATEGORY = 'SET_SELECTED_ARRIVAL_CATEGORY';
export const SET_MODE_OF_TRANSPORT = 'SET_MODE_OF_TRANSPORT';
export const SET_INDEX_FOR_MODIFY = 'SET_INDEX_FOR_MODIFY';
export const SET_IS_EDITING_TRAVEL_STATE = 'SET_IS_EDITING_TRAVEL_STATE';
export const DELETE_TRAVEL_ITEM = 'DELETE_TRAVEL_ITEM';
export const MAP_CUSTOMIZATION_POPUP_STATE = 'MAP_CUSTOMIZATION_POPUP_STATE';
export const DISABLE_STATE = 'DISABLE_STATE';
export const SET_MAP_STYLE_INDEX = 'SET_MAP_STYLE_INDEX';
export const SET_HISTORY_PAGE_STATE = 'SET_HISTORY_PAGE_STATE';
export const CLEAR_MAP_STATE = 'CLEAR_MAP_STATE';
export const SET_VIDEO_POPUP_STATE = 'SET_VIDEO_POPUP_STATE';
export const SET_MODEL_SIZE = 'SET_MODEL_SIZE';
export const SET_VIDEO_LENGTH = 'SET_VIDEO_LENGTH';
export const SET_IS_RECORDING = 'SET_IS_RECORDING';
export const START_RECORDING = 'START_RECORDING';
export const STOP_RECORDING = 'STOP_RECORDING';
export const SAVE_TRAVEL_TO_HISTORY = 'SAVE_TRAVEL_TO_HISTORY';
export const LOAD_TRAVEL_FROM_HISTORY = 'LOAD_TRAVEL_FROM_HISTORY';
export const SET_TRAVEL_HISTORY_INDEX = 'SET_TRAVEL_HISTORY_INDEX';
export const SET_PAYMENT_STATS_STATE = 'SET_PAYMENT_STATS_STATE';
export const SET_HISTORY_TRACKING_ID = 'SET_HISTORY_TRACKING_ID';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_VERIFICATION_EMAIL = 'SET_VERIFICATION_EMAIL';
export const ADD_TRAVEL_RESPONSE = 'ADD_TRAVEL_RESPONSE';
export const SET_FULLSCREEN_MODE = 'SET_FULLSCREEN_MODE';
export const SELECT_PLANE_MODEL = 'SELECT_PLANE_MODEL';
export const SELECT_CAR_MODEL = 'SELECT_CAR_MODEL';
export const SET_DOWNLOAD_START_TIME = 'SET_DOWNLOAD_START_TIME';
export const SET_SETTINGS_PAGE_STATE = 'SET_SETTINGS_PAGE_STATE';
export const SET_PUBLISHED_TRAVEL_ID = 'SET_PUBLISHED_TRAVEL_ID';
export const SET_PUBLISHED_TRAVEL_INDEX = 'SET_PUBLISHED_TRAVEL_INDEX';
export const SET_PUBLISHED_TRAVEL_HISTORY = 'SET_PUBLISHED_TRAVEL_HISTORY';
export const SET_PLAY_PAUSE_STATE = 'SET_PLAY_PAUSE_STATE';
export const SET_CURRENT_TRAVEL_INDEX = 'SET_CURRENT_TRAVEL_INDEX';
export const SET_PUBLISHED_TRAVEL_LINK = 'SET_PUBLISHED_TRAVEL_LINK';
export const SHOW_SHARE_POPUP = 'SHOW_SHARE_POPUP';
export const SET_FEEDBACK_POPUP_STATE = 'SET_FEEDBACK_POPUP_STATE';
export const GET_USER_ID = 'GET_USER_ID';
export const GET_USER_EMAIL = 'GET_USER_EMAIL';
export const GET_USER_NAME = 'GET_USER_NAME';
export const GET_PROFILE_IMAGE_URL = 'GET_PROFILE_IMAGE_URL';
export const GET_SNACKBAR_SEVERITY = 'GET_SNACKBAR_SEVERITY';
export const GET_GENERIC_POPUP_MESSAGE = 'GET_GENERIC_POPUP_MESSAGE';
export const GET_SNACKBAR_MESSAGE = 'GET_SNACKBAR_MESSAGE';
export const SET_PUBLISHABLE_TRAVEL_DATA = 'SET_PUBLISHABLE_TRAVEL_DATA';
export const SET_SNACKBAR_OPEN = 'SET_SNACKBAR_OPEN';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_SUCCESS_SNACKBAR_OPEN = 'SET_SUCCESS_SNACKBAR_OPEN';
export const SET_LOADING = 'SET_LOADING';
export const SET_TRAVEL_PATH_DATA = 'SET_TRAVEL_PATH_DATA';
export const SELECT_TRANSIT_MODEL = 'SELECT_TRANSIT_MODEL';
export const SELECT_WALK_MODEL = 'SELECT_WALK_MODEL';
export const SELECT_FERRY_MODEL = 'SELECT_FERRY_MODEL';
// TripInfoActions
export const SET_CURRENT_TRAVEL_DAY = 'SET_CURRENT_TRAVEL_DAY';
export const SET_TRAVEL_DATA = 'SET_TRAVEL_DATA';
export const SET_CURRENT_OVERVIEW_DATA = 'SET_CURRENT_OVERVIEW_DATA';
export const SET_IS_STORIES_OPENED = 'SET_IS_STORIES_OPENED';
export const SET_TRAVEL_RAW_DATA = 'SET_TRAVEL_RAW_DATA';

// P2PManualActions
export const TITLE_MODAL_STATE = 'TITLE_MODAL_STATE';
export const TRAVEL_TITLE = 'TRAVEL_TITLE';
export const MAP_BUTTON_STATE = 'MAP_BUTTON_STATE';
export const HEADER_STATE = 'HEADER_STATE';
export const TRAVEL_POINT_SELECTED = 'TRAVEL_POINT_SELECTED';
export const POINT_TO_ADD_INDEX = 'POINT_TO_ADD_INDEX';
export const SAVING_TO_HISTORY_STATE = 'SAVING_TO_HISTORY_STATE';
export const CREATING_VISUALIZATION_STATE = 'CREATING_VISUALIZATION_STATE';
export const ADD_P2P_HISTORY_ID = 'ADD_P2P_HISTORY_ID';
export const SAVE_TO_HISTORY = 'SAVE_TO_HISTORY';
export const ADD_MANUAL_HISTORY_IDENTIFIER = 'ADD_MANUAL_HISTORY_IDENTIFIER';
export const SET_SELECTED_CITY = 'SET_SELECTED_CITY';

export const SET_CURRENT_DAY_INDEX = 'SET_CURRENT_DAY_INDEX';
export const SET_LAST_VISITED_DAY_INDEX = 'SET_LAST_VISITED_DAY_INDEX';
export const SET_PREVIOUS_DAY_INDEX = 'SET_PREVIOUS_DAY_INDEX';
export const RESTORE_FROM_PREVIOUS_STATE = 'RESTORE_FROM_PREVIOUS_STATE';
export const TRAVEL_POINTS_BY_DAY_INDEX = 'TRAVEL_POINTS_BY_DAY_INDEX';
export const MAP_SELECTED = 'MAP_SELECTED';
export const MAP_POINT_SELECTED = 'MAP_POINT_SELECTED';
export const MAP_POINT_SELECTED_INDEX = 'MAP_POINT_SELECTED_INDEX';
export const SET_IS_OVERVIEW_VISIBLE_IN_P2P = 'SET_IS_OVERVIEW_VISIBLE_IN_P2P';
export const SET_P2P_ITINERARY_DATA = 'SET_P2P_ITINERARY_DATA';
export const SET_IS_OVERVIEW_BUTTON_VISIBLE_IN_P2P =
  'SET_IS_OVERVIEW_BUTTON_VISIBLE_IN_P2P';
export const SET_DEFAULT_P2P_MANUAL_STATE = 'SET_DEFAULT_P2P_MANUAL_STATE';

// TravelActions
export const SET_SHOULD_SHOW_FIRST_DAY = 'SET_SHOULD_SHOW_FIRST_DAY';
export const TRAVEL_POINTS_UPDATED = 'TRAVEL_POINTS_UPDATED';
