import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';
import { store } from '~/redux/store';
import { RootState } from '~/redux/reducers';
import {
  fireShowSignupDialog,
  selectShowSignupDialog,
} from '~/redux/reducers/ui.slice';

import { PauseAnimation } from '../common';
import ROUTES from '~/routes';

import { isAuthenticated } from '../MobileFooter/BottomSheet';
import { isOverlayVisible } from '../StatsOverlay';
import { showOverviewMenu } from '~/components/OverviewMenu';

import { selectTravelRawData } from '~/redux/selectors/tripInfoSelectors';
import { selectUserID } from '~/redux/selectors/mapSelectors';
import { selectIsSavingToHistory } from '~/redux/selectors/P2PManualSelectors';

import { TravelOptions, TravelPointsByDayIndex } from '~/utility/models';
import { getTravelPointsArray } from '~/utility/utils';
import { initialItineraryRender } from '~/map/ViewTravel';
import SignupDialog from '~/components/P2PAI/MapPreview/SignUpDialog';

const AddNewPointSheet: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const travelRawData = useSelector(selectTravelRawData);
  const userId = useSelector(selectUserID);
  const isSavingToHistory = useSelector(selectIsSavingToHistory);

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );

  const hasPoints = useMemo(() => {
    return Object.keys(travelPointsByDayIndex).length > 0;
  }, [travelPointsByDayIndex]);

  const saveOrEditTrip = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) => {
    e.stopPropagation();

    const travelPointsArrays: TravelOptions[] = getTravelPointsArray(
      travelPointsByDayIndex,
    );

    // Set common states
    dispatch(ActionsCreator.setTravelTitle(travelRawData?.title));
    dispatch(
      ActionsCreator.setCurrentDayIndex(
        travelPointsArrays[travelPointsArrays.length - 1]?.dayIndex || 0,
      ),
    );
    dispatch(
      ActionsCreator.setLastVisitedDayIndex(
        travelPointsArrays[0]?.dayIndex || 0,
      ),
    );

    const historyId = store.getState().P2PManualReducers.currentHistoryId;

    // Update states based on authentication
    if (isAuthenticated()) {
      dispatch(ActionsCreator.setHeaderState(true));
      dispatch(ActionsCreator.setMapButtonState(true));
      dispatch(ActionsCreator.setIsTravelPointSelectedState(true));
      dispatch(ActionsCreator.setCurrentHistoryId(historyId));
      initialItineraryRender.value = false;
      if (travelRawData?.userId !== userId) {
        resetTravelData();
        // dispatch(ActionsCreator.setCurrentHistoryId(-1));
        await handleNewTravelData(
          travelRawData,
          travelPointsArrays,
          travelPointsByDayIndex,
        );
        // add delay to allow the trip to be added in db
        setTimeout(() => {
          navigate(ROUTES.HOME.path);
        }, 1000);
      } else {
        isOverlayVisible.value = true;
        showOverviewMenu.value = true;
      }
    } else {
      resetTravelData();
      dispatch(ActionsCreator.setCurrentHistoryId(historyId));
      dispatch(fireShowSignupDialog(true));
    }

    PauseAnimation();
  };

  // Helper: Handle new travel data creation
  const handleNewTravelData = async (
    travelRawData: any,
    travelPoints: TravelOptions[],
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) => {
    resetTravelData();

    dispatch(
      ActionsCreator.setTravelRawData(
        JSON.stringify({ ...travelRawData, publishedId: 0 }),
      ),
    );

    await dispatch(ActionsCreator.saveP2PTravelPoint(travelPoints));
    await dispatch(
      ActionsCreator.createP2PTravelPointVisualization(travelPointsByDayIndex),
    );
  };

  // Helper function to reset travel data
  const resetTravelData = () => {
    dispatch(ActionsCreator.setPublishedTravelLink(''));
    dispatch(ActionsCreator.setCurrentHistoryId(-1));
    dispatch(ActionsCreator.setCurrentHistoryIdentifier(''));
    dispatch(ActionsCreator.setPulishedTravelId(0));
  };

  const showSignupDialog = useSelector(selectShowSignupDialog);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '500px',
        height: '100px',
        background: '#fff',
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: '0px -2px 6px 0px #B8C5D033',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
      }}
    >
      <SignupDialog
        open={showSignupDialog}
        onClose={() => dispatch(fireShowSignupDialog(false))}
        title="To Save This Trip..."
      />

      {travelRawData?.userId !== userId && (
        <Box
          className="makeYourOwnButton"
          onClick={(e) => {
            saveOrEditTrip(
              e,
              store.getState().P2PManualReducers.travelPointsByDayIndex,
            );
          }}
          sx={{
            pointerEvent: isSavingToHistory ? 'none' : 'auto',
            left: 0,
          }}
        >
          <span className="makeYourOwnText">
            <span className="flex justify-items-center align-items-center">
              {isSavingToHistory ? 'Saving' : 'Save'}
            </span>
          </span>
        </Box>
      )}
      <Box
        onClick={
          !hasPoints && travelRawData?.userId !== userId
            ? () => {}
            : (e) => {
                e.stopPropagation();
                if (isAuthenticated()) {
                  isOverlayVisible.value = true;
                  showOverviewMenu.value = true;
                  dispatch(ActionsCreator.setHeaderState(true));
                  dispatch(ActionsCreator.setMapButtonState(true));
                  dispatch(ActionsCreator.setIsTravelPointSelectedState(true));
                } else {
                  store.dispatch(ActionsCreator.setPublishedTravelLink(''));
                  store.dispatch(ActionsCreator.setCurrentHistoryId(-1));
                  store.dispatch(
                    ActionsCreator.setCurrentHistoryIdentifier(''),
                  );
                  store.dispatch(ActionsCreator.setPulishedTravelId(0));
                  // showSignupDialog.value = true;
                  // save
                }
                PauseAnimation();
              }
        }
        className="mobile-home-button"
        sx={{
          cursor:
            !hasPoints && travelRawData?.userId !== userId
              ? 'default'
              : 'pointer',
          opacity: !hasPoints && travelRawData?.userId !== userId ? '0.4' : 1,
        }}
      >
        Enter a Start Point
      </Box>
    </Box>
  );
};

export default AddNewPointSheet;
