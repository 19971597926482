import { motion } from 'framer-motion';
import React from 'react';
// import ClearIcon from '@mui/icons-material/Clear';

const SLIDE_SIZE = 200;

export const TiktokThumbnail = ({
  imageSrc,
  removeThumbnail,
  dialogHandlers,
}: {
  imageSrc: string;
  removeThumbnail: () => void;
  dialogHandlers: { tiktok: () => void };
}) => {
  return (
    <div
      style={{
        width: '95%',
        display: 'flex',
        marginBottom: 20,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={() => dialogHandlers.tiktok()}
    >
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.25 }}
      >
        <div
          style={{
            width: SLIDE_SIZE,
            height: SLIDE_SIZE,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow:
              '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933,0px 1.5px 1.5px 0px #FFFFFF40 inset',
            position: 'relative',
            border: 'none',
            background: '#fff',
          }}
        >
          <img
            width="100%"
            height="100%"
            src={imageSrc}
            alt={String('img')}
            loading="lazy"
          />
        </div>
        {/* <ClearIcon
          fontSize="medium"
          onClick={removeThumbnail}
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            color: 'red',
            fontWeight: 700,
            cursor: 'pointer',
          }}
        /> */}
      </motion.div>
    </div>
  );
};
