import { IProcessedPlace } from '~/types/websocket';
import { AnimatePresence, motion } from 'framer-motion';
import dayjs from 'dayjs';
import { pauseAutoYesProgress } from './BottomSheetp2p';
import ActionsCreator from '~/redux/actions';
import { useDispatch } from 'react-redux';
import { getTransportIcon } from '~/components/OverviewMenu/ItineraryOverview/helper';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import { isEmpty } from 'lodash';
import { getIconByCategory } from '~/components/ViewTravel/MobileFooter/helpers';
import './style.css';

const DetailCardP2PAI = ({
  data,
  index,
}: {
  data: IProcessedPlace;
  index: number;
}) => {
  const dispatch = useDispatch();
  const scaleValue =
    window.innerWidth > 500 ? 1 : window.innerWidth > 400 ? 0.9 : 0.8;

  return (
    <AnimatePresence mode="wait">
      <motion.div
        className="m-4"
        key={'detail-card'}
        layoutId="detail-card"
        initial={{
          opacity: 0,
          y: 100,
          transform: 'translateX(-50%)',
          left: '44%',
        }}
        animate={{
          opacity: 1,
          y: 0,
          transform: 'translateX(-50%)',
          left: '44%',
        }}
        exit={{
          opacity: 0,
          y: 100,
          transform: 'translateX(-50%)',
          left: '44%',
        }}
        transition={{ duration: 0.2 }}
        onClick={() => {
          dispatch(
            ActionsCreator.setCurrentOverviewData({
              ...data.place,
              dayIndex: data.generatedPlace.dayIndex,
            }),
          );
          pauseAutoYesProgress();
        }}
        style={{
          boxShadow:
            '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
          borderRadius: '18px',
          backgroundColor: 'white',
          padding: '8px 8px 50px 8px',
          transformOrigin: 'bottom',
          cursor: 'pointer',
          position: 'absolute',
          bottom: '20px',
          left: '44%',
          width: '90%',
          transform: `translateX(-50%) scale(${scaleValue})`,
        }}
      >
        <div className="d-flex">
          <div className="me-1">
            <ImageViewerP2P
              src={data?.place?.images}
              category={data.place.category}
            />
          </div>
          <div
            className="flex-1 flex-column"
            style={{ marginLeft: '4px', marginTop: '4px' }}
          >
            <motion.h6
              style={{
                fontSize: 'min(14px, 4.5vw)',
                color: '#000',
                fontWeight: 400,
                whiteSpace: 'nowrap',
              }}
            >
              {index === 0 && data.generatedPlace.dayIndex === '1' ? (
                'Start Point'
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {calculateDuration(
                    data?.generatedPlace?.departureDate,
                    data?.generatedPlace?.arrivalDate,
                    data?.generatedPlace?.transportationType,
                  )}
                  <img
                    style={{
                      height: '20px',
                      width: '20px',
                      marginLeft: '2px',
                    }}
                    src={getTransportIcon(
                      data?.generatedPlace?.transportationType as TravelMode,
                    )}
                    alt=""
                  />
                </div>
              )}
            </motion.h6>
            <div className="flex-1" />
            <motion.h6
              transition={{
                duration: 0.25,
                delay: 0.2,
              }}
              style={{
                fontSize: 'min(22px, 7vw)',
                fontWeight: 700,
                lineClamp: 2,
                WebkitLineClamp: 2,
                boxOrient: 'vertical',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '2.5em',
                maxWidth: '149px',
              }}
            >
              {data?.place?.location?.label}
            </motion.h6>
            <div className="flex-1" />
          </div>
          <div
            className="flex-1 flex-column align-items-center"
            style={{ justifyContent: 'space-between' }}
          >
            <div>
              {data?.place?.review && (
                <div
                  style={{
                    fontSize: 'min(18px, 6vw)',
                    fontWeight: 700,
                    marginTop: '-2px',
                  }}
                >
                  {data.place.review}
                  <span>⭐</span>
                </div>
              )}
            </div>
            <div>
              {(
                index
                  ? data?.generatedPlace.arrivalPointCost
                  : data?.place?.cost
              ) ? (
                <motion.div
                  style={{
                    fontSize: 'min(14px, 4.3vw)',
                    fontWeight: 600,
                    padding: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ marginLeft: '-8px', display: 'inline-block' }}>
                    ~US$
                    {index
                      ? data?.generatedPlace.arrivalPointCost
                      : data?.place?.cost}
                  </span>
                  <span style={{ marginLeft: '-8px', display: 'inline-block' }}>
                    (Estimate)
                  </span>
                </motion.div>
              ) : (
                <span></span>
              )}
            </div>
          </div>
        </div>
        <motion.h6
          className="text-center"
          style={{
            fontSize: 'min(17px, 5.5vw)',
            color: 'black',
            padding: '2px 4px',
            fontWeight: 500,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2, // limits text to 2 lines
            textOverflow: 'ellipsis',
            lineHeight: '18.05px',
            maxHeight: '2.5em',
          }}
        >
          "{data?.place?.description}"
        </motion.h6>
      </motion.div>
    </AnimatePresence>
  );
};

export default DetailCardP2PAI;

const ImageViewerP2P = ({
  src,
  category,
  large,
}: {
  src: string[];
  category: any;
  large?: boolean;
}) => {
  return (
    <div
      style={{
        height: large ? '200px' : '76px',
        width: large ? '200px' : '68px',
      }}
      className="details-image"
    >
      {!src || isEmpty(src) ? (
        <>
          <img
            src={getIconByCategory(category)}
            style={{
              height: large ? '200px' : '76px',
              width: large ? '200px' : '68px',
              overflow: 'hidden',
            }}
            loading="lazy"
            alt="icon"
          />
        </>
      ) : (
        <>
          {src.map((image, index) => {
            return (
              <motion.img
                key={index}
                src={image}
                alt="place"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: large ? '32px' : '10px',
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export const calculateDuration = (
  departureDate: string,
  arrivalDate: string,
  transportationType: string,
) => {
  const departure = dayjs(departureDate);
  const arrival = dayjs(arrivalDate);
  const duration = arrival.diff(departure, 'minute');
  return `${duration} minutes by ${transportationType}`;
};
