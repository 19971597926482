
/*
 * This work is free. You can redistribute it and/or modify it under the
 * terms of the Do What The Fuck You Want To Public License, Version 2,
 * as published by Sam Hocevar. See the COPYING file for more details.
 */
/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 */

// Based on https://gist.github.com/gre/1650294

export function linear(t) {
  return t;
}

export function easeInOutSine(t) {
  return -0.5 * (Math.cos(Math.PI * t) - 1);
}

// Acceleration until halfway, then deceleration
export function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : - 1 + (4 - 2 * t) * t;
}

// Acceleration until halfway, then deceleration
export function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

// Acceleration until halfway, then deceleration
export function easeInOutQuart(t) {
  const t1 = t - 1;
  return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * t1 * t1 * t1 * t1;
}

// Acceleration until halfway, then deceleration
export function easeInOutQuint(t) {
  const t1 = t - 1;
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * t1 * t1 * t1 * t1 * t1;
}

export function customEaseInOut(t, D) {
  const t1 = t - 1;
  const D_floor = Math.floor(D);

  if (D <= 1) {
    return t;
  }

  if (t < 0.5) {
    return Math.pow(2, D_floor - 1) * Math.pow(t, D_floor);
  } else {
    return 1 + Math.pow(-2, D_floor - 1) * Math.pow(t1, D_floor)
  }
}

export function customEaseIn(t, D) {
  const D_floor = Math.floor(D);

  if (D_floor <= 1) {
    return t;
  }

  return Math.pow(2, D_floor - 1) * Math.pow(t, D_floor);
}

export function customEaseOut(t, D) {
  const t1 = t - 1;
  const D_floor = Math.floor(D);

  if (D_floor <= 1) {
    return t;
  }

  return 1 + Math.pow(-2, D_floor - 1) * Math.pow(t1, D_floor)
}

// Accelerate exponentially until finish
export function easeInExpo(t) {

  if (t === 0) {
    return 0;
  }

  return Math.pow(2, 10 * (t - 1));

}

// Initial exponential acceleration slowing to stop
export function easeOutExpo(t) {

  if (t === 1) {
    return 1;
  }

  return (-Math.pow(2, -10 * t) + 1);

}

// Exponential acceleration and deceleration
export function easeInOutExpo(t) {

  if (t === 0 || t === 1) {
    return t;
  }

  const scaledTime = t * 2;
  const scaledTime1 = scaledTime - 1;

  if (scaledTime < 1) {
    return 0.5 * Math.pow(2, 10 * (scaledTime1));
  }

  return 0.5 * (-Math.pow(2, -10 * scaledTime1) + 2);

}

// Increasing velocity until stop
export function easeInCirc(t) {

  const scaledTime = t / 1;
  return -1 * (Math.sqrt(1 - scaledTime * t) - 1);

}

// Start fast, decreasing velocity until stop
export function easeOutCirc(t) {

  const t1 = t - 1;
  return Math.sqrt(1 - t1 * t1);

}

// Fast increase in velocity, fast decrease in velocity
export function easeInOutCirc(t) {

  const scaledTime = t * 2;
  const scaledTime1 = scaledTime - 2;

  if (scaledTime < 1) {
    return -0.5 * (Math.sqrt(1 - scaledTime * scaledTime) - 1);
  }

  return 0.5 * (Math.sqrt(1 - scaledTime1 * scaledTime1) + 1);

}

// Slow movement backwards then fast snap to finish
export function easeInBack(t, magnitude = 1.70158) {

  return t * t * ((magnitude + 1) * t - magnitude);

}

// Fast snap to backwards point then slow resolve to finish
export function easeOutBack(t, magnitude = 1.70158) {

  const scaledTime = (t / 1) - 1;

  return (
    scaledTime * scaledTime * ((magnitude + 1) * scaledTime + magnitude)
  ) + 1;

}

// Slow movement backwards, fast snap to past finish, slow resolve to finish
export function easeInOutBack(t, magnitude = 0) {

  const scaledTime = t * 2;
  const scaledTime2 = scaledTime - 2;

  const s = magnitude * 1.525;

  if (scaledTime < 1) {

    return 0.5 * scaledTime * scaledTime * (
      ((s + 1) * scaledTime) - s
    );

  }

  return 0.5 * (
    scaledTime2 * scaledTime2 * ((s + 1) * scaledTime2 + s) + 2
  );

}
// Bounces slowly then quickly to finish
export function easeInElastic(t, magnitude = 0.7) {

  if (t === 0 || t === 1) {
    return t;
  }

  const scaledTime = t / 1;
  const scaledTime1 = scaledTime - 1;

  const p = 1 - magnitude;
  const s = p / (2 * Math.PI) * Math.asin(1);

  return -(
    Math.pow(2, 10 * scaledTime1) *
    Math.sin((scaledTime1 - s) * (2 * Math.PI) / p)
  );

}

// Fast acceleration, bounces to zero
export function easeOutElastic(t, magnitude = 0.7) {

  if (t === 0 || t === 1) {
    return t;
  }

  const p = 1 - magnitude;
  const scaledTime = t * 2;

  const s = p / (2 * Math.PI) * Math.asin(1);
  return (
    Math.pow(2, -10 * scaledTime) *
    Math.sin((scaledTime - s) * (2 * Math.PI) / p)
  ) + 1;

}

// Slow start and end, two bounces sandwich a fast motion
export function easeInOutElastic(t, magnitude = 0.65) {

  if (t === 0 || t === 1) {
    return t;
  }

  const p = 1 - magnitude;
  const scaledTime = t * 2;
  const scaledTime1 = scaledTime - 1;

  const s = p / (2 * Math.PI) * Math.asin(1);

  if (scaledTime < 1) {
    return -0.5 * (
      Math.pow(2, 10 * scaledTime1) *
      Math.sin((scaledTime1 - s) * (2 * Math.PI) / p)
    );
  }

  return (
    Math.pow(2, -10 * scaledTime1) *
    Math.sin((scaledTime1 - s) * (2 * Math.PI) / p) * 0.5
  ) + 1;

}

// Bounce to completion
export function easeOutBounce(t) {

  const scaledTime = t / 1;

  if (scaledTime < (1 / 2.75)) {

    return 7.5625 * scaledTime * scaledTime;

  } else if (scaledTime < (2 / 2.75)) {

    const scaledTime2 = scaledTime - (1.5 / 2.75);
    return (7.5625 * scaledTime2 * scaledTime2) + 0.75;

  } else if (scaledTime < (2.5 / 2.75)) {

    const scaledTime2 = scaledTime - (2.25 / 2.75);
    return (7.5625 * scaledTime2 * scaledTime2) + 0.9375;

  } else {

    const scaledTime2 = scaledTime - (2.625 / 2.75);
    return (7.5625 * scaledTime2 * scaledTime2) + 0.984375;

  }

}

// Bounce increasing in velocity until completion
export function easeInBounce(t) {
  return 1 - easeOutBounce(1 - t);
}

// Bounce in and bounce out
export function easeInOutBounce(t) {

  if (t < 0.5) {

    return easeInBounce(t * 2) * 0.5;

  }

  return (easeOutBounce((t * 2) - 1) * 0.5) + 0.5;

}

export function easeInSine(t) {
  return -1 * Math.cos(t * (Math.PI / 2)) + 1;
}

export function easeOutSine(t) {
  return Math.sin(t * (Math.PI / 2));
}

export function easeInCubic(t) {
  return t * t * t;
}

export function easeOutCubic(t) {
  const t1 = t - 1;
  return t1 * t1 * t1 + 1;
}

export function easeInQuad(t) {
  return t * t;
}

// Decelerating to zero velocity
export function easeOutQuad(t) {
  return t * (2 - t);
}

// Accelerating from zero velocity
export function easeInQuart(t) {
  return t * t * t * t;
}

// Decelerating to zero velocity
export function easeOutQuart(t) {
  const t1 = t - 1;
  return 1 - t1 * t1 * t1 * t1;
}


const EasingFunctions = {
  // no easing, no acceleration
  linear: (t) => t,
  // accelerating from zero velocity
  easeInQuad: (t) => t * t,
  // decelerating to zero velocity
  easeOutQuad: (t) => t * (2 - t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  // accelerating from zero velocity
  easeInCubic: (t) => t * t * t,
  // decelerating to zero velocity
  easeOutCubic: (t) => --t * t * t + 1,
  // acceleration until halfway, then deceleration
  easeInOutCubic: (t) =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
  // accelerating from zero velocity
  easeInQuart: (t) => t * t * t * t,
  // decelerating to zero velocity
  easeOutQuart: (t) => 1 - --t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: (t) =>
    t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t,
  // accelerating from zero velocity
  easeInQuint: (t) => t * t * t * t * t,
  // decelerating to zero velocity
  easeOutQuint: (t) => 1 + --t * t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuint: (t) =>
    t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t,
  easeOutExpo: (x) => (x === 1 ? 1 : 1 - Math.pow(2, -10 * x)),
  easeOutBack(x) {
    const c1 = 1.70158;
    const c3 = c1 + 1;
    return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
  },
  customCarEase: (t) => {
    if (t < 0.2) return 0;
    else if (0.2 <= t && t < 0.35) return (0.1 * (t - 0.2)) / 0.15;
    else if (0.35 <= t && t < 0.65) return 0.1 + 0.8 * ((t - 0.35) / 0.3);
    else if (0.65 <= t && t < 0.8) return 0.9 + 0.1 * ((t - 0.65) / 0.15);
    else return 1;
  },
};

const Easing = {
  linear(t, b, c, d) {
    return (c * t) / d + b;
  },
  easeInQuad(t, b, c, d) {
    return c * (t /= d) * t + b;
  },
  easeOutQuad(t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  },
  easeInOutQuad(t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return (c / 2) * t * t + b;
    } else {
      return (-c / 2) * (--t * (t - 2) - 1) + b;
    }
  },
  easeInCubic(t, b, c, d) {
    return c * (t /= d) * t * t + b;
  },
  easeOutCubic(t, b, c, d) {
    return c * ((t = t / d - 1) * t * t + 1) + b;
  },
  easeInOutCubic(t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return (c / 2) * t * t * t + b;
    } else {
      return (c / 2) * ((t -= 2) * t * t + 2) + b;
    }
  },
  easeInQuart(t, b, c, d) {
    return c * (t /= d) * t * t * t + b;
  },
  easeOutQuart(t, b, c, d) {
    return -c * ((t = t / d - 1) * t * t * t - 1) + b;
  },
  easeInOutQuart(t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return (c / 2) * t * t * t * t + b;
    } else {
      return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
    }
  },
  easeInQuint(t, b, c, d) {
    return c * (t /= d) * t * t * t * t + b;
  },
  easeOutQuint(t, b, c, d) {
    return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
  },
  easeInOutQuint(t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return (c / 2) * t * t * t * t * t + b;
    } else {
      return (c / 2) * ((t -= 2) * t * t * t * t + 2) + b;
    }
  },
  easeInSine(t, b, c, d) {
    return -c * Math.cos((t / d) * (Math.PI / 2)) + c + b;
  },
  easeOutSine(t, b, c, d) {
    return c * Math.sin((t / d) * (Math.PI / 2)) + b;
  },
  easeInOutSine(t, b, c, d) {
    return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
  },
  easeInExpo(t, b, c, d) {
    var _ref;
    return (_ref = t === 0) !== null
      ? _ref
      : {
        b: c * Math.pow(2, 10 * (t / d - 1)) + b,
      };
  },
  easeOutExpo(t, b, c, d) {
    var _ref;
    return (_ref = t === d) !== null
      ? _ref
      : b +
      {
        c: c * (-Math.pow(2, (-10 * t) / d) + 1) + b,
      };
  },
  easeInOutExpo(t, b, c, d) {
    if (t === 0) {
      return b;
    }
    if (t === d) {
      return b + c;
    }
    if ((t /= d / 2) < 1) {
      return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    } else {
      return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
    }
  },
  easeInCirc(t, b, c, d) {
    return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
  },
  easeOutCirc(t, b, c, d) {
    return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
  },
  easeInOutCirc(t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return (-c / 2) * (Math.sqrt(1 - t * t) - 1) + b;
    } else {
      return (c / 2) * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
    }
  },
  easeInElastic(t, b, c, d) {
    var a, p, s;
    s = 1.70158;
    p = 0;
    a = c;
    if (t === 0) {
      return b;
    } else if ((t /= d) === 1) {
      return b + c;
    }
    if (!p) {
      p = d * 0.3;
    }
    if (a < Math.abs(c)) {
      a = c;
      s = p / 4;
    } else {
      s = (p / (2 * Math.PI)) * Math.asin(c / a);
    }
    return (
      -(
        a *
        Math.pow(2, 10 * (t -= 1)) *
        Math.sin(((t * d - s) * (2 * Math.PI)) / p)
      ) + b
    );
  },
  easeOutElastic(t, b, c, d) {
    var a, p, s;
    s = 1.70158;
    p = 0;
    a = c;
    if (t === 0) {
      return b;
    } else if ((t /= d) === 1) {
      return b + c;
    }
    if (!p) {
      p = d * 0.3;
    }
    if (a < Math.abs(c)) {
      a = c;
      s = p / 4;
    } else {
      s = (p / (2 * Math.PI)) * Math.asin(c / a);
    }
    return (
      a * Math.pow(2, -10 * t) * Math.sin(((t * d - s) * (2 * Math.PI)) / p) +
      c +
      b
    );
  },
  easeInOutElastic(t, b, c, d) {
    var a, p, s;
    s = 1.70158;
    p = 0;
    a = c;
    if (t === 0) {
      return b;
    } else if ((t /= d / 2) === 2) {
      return b + c;
    }
    if (!p) {
      p = d * (0.3 * 1.5);
    }
    if (a < Math.abs(c)) {
      a = c;
      s = p / 4;
    } else {
      s = (p / (2 * Math.PI)) * Math.asin(c / a);
    }
    if (t < 1) {
      return (
        -0.5 *
        (a *
          Math.pow(2, 10 * (t -= 1)) *
          Math.sin(((t * d - s) * (2 * Math.PI)) / p)) +
        b
      );
    } else {
      return (
        a *
        Math.pow(2, -10 * (t -= 1)) *
        Math.sin(((t * d - s) * (2 * Math.PI)) / p) *
        0.5 +
        c +
        b
      );
    }
  },
  easeInBack(t, b, c, d, s) {
    if (s === void 0) {
      s = 1.70158;
    }
    return c * (t /= d) * t * ((s + 1) * t - s) + b;
  },
  easeOutBack(t, b, c, d, s) {
    if (s === void 0) {
      s = 1.70158;
    }
    return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
  },
  easeInOutBack(t, b, c, d, s) {
    if (s === void 0) {
      s = 1.70158;
    }
    if ((t /= d / 2) < 1) {
      return (c / 2) * (t * t * (((s *= 1.525) + 1) * t - s)) + b;
    } else {
      return (c / 2) * ((t -= 2) * t * (((s *= 1.525) + 1) * t + s) + 2) + b;
    }
  },
  easeInBounce(t, b, c, d) {
    var v;
    v = Easing.easeOutBounce(d - t, 0, c, d);
    return c - v + b;
  },
  easeOutBounce(t, b, c, d) {
    if ((t /= d) < 1 / 2.75) {
      return c * (7.5625 * t * t) + b;
    } else if (t < 2 / 2.75) {
      return c * (7.5625 * (t -= 1.5 / 2.75) * t + 0.75) + b;
    } else if (t < 2.5 / 2.75) {
      return c * (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375) + b;
    } else {
      return c * (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375) + b;
    }
  },
  easeInOutBounce(t, b, c, d) {
    var v;
    if (t < d / 2) {
      v = Easing.easeInBounce(t * 2, 0, c, d);
      return v * 0.5 + b;
    } else {
      v = Easing.easeOutBounce(t * 2 - d, 0, c, d);
      return v * 0.5 + c * 0.5 + b;
    }
  },
};

export { Easing, EasingFunctions };
