import React from 'react';
import { Box } from '@mui/material';
import TravelFormContainer from '~/containers/TravelFormContainer';
import TravelItineraryContainer from '~/containers/TravelItineraryContainer';
import ModifyTravelFormContainer from '~/containers/ModifyTravelFormContainer';
import CustomizeMapContainer from '~/containers/MapCustomizationPopupContainer';
import ConfirmationDialog from '~components/ConfirmationDialog';
import VideoPopupContainer from '~/containers/VideoPopupContainer';
import SharePopup from '../SharePopup';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';

const useStyles = makeStyles(() => ({
  controlsSpaceContainer: {
    position: 'absolute',
  },
}));

/**
 * ControlsSpace component
 *
 * This component renders various form and popup containers for controlling travel-related functionalities.
 * It also includes a confirmation dialog for user actions.
 *
 * @component
 * @returns {JSX.Element} The rendered ControlsSpace component.
 */
const ControlsSpace = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Selectors to get state values from the Redux store
  const isDialogOpen = useSelector(
    (state: RootState) => state.TravelReducers.isConfirmationDialogOpen,
  );
  const mapClearState = useSelector(
    (state: RootState) => state.MapReducers.mapClearState,
  );
  const link = useSelector(
    (state: RootState) => state.AnimationReducers.publishedLink,
  );

  /**
   * Handle cancel action
   *
   * Closes the confirmation dialog and sets the schedule section state.
   */
  const handleCancel = () => {
    dispatch(ActionsCreator.toggleConfirmationDialogState(false));
    dispatch(ActionsCreator.setScheduleSectionState(true));
  };

  /**
   * Open travel itinerary
   *
   * Closes the confirmation dialog and opens the travel itinerary.
   */
  const openItinerary = () => {
    dispatch(ActionsCreator.toggleConfirmationDialogState(false));
    dispatch(ActionsCreator.openTravelItinerary(true));
    dispatch(ActionsCreator.setScheduleSectionState(true));
  };

  /**
   * Handle confirm action
   *
   * Discards all travel points, resets the form state, and triggers map clearing state.
   */
  const handleConfirm = () => {
    dispatch(ActionsCreator.discardAllTravelPoints());
    dispatch(ActionsCreator.toggleConfirmationDialogState(false));
    dispatch(ActionsCreator.setTravelFormSaveState(false));
    dispatch(ActionsCreator.openTravelForm(true));
    dispatch(ActionsCreator.triggerStateToClear(!mapClearState));
    dispatch(ActionsCreator.setTravelHistoryCurrentID(-1));
    dispatch(ActionsCreator.setTravelHistoryIndex(0));
  };

  return (
    <Box component="div" className={classes.controlsSpaceContainer}>
      <TravelFormContainer />
      <ConfirmationDialog
        open={isDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        openItinerary={openItinerary}
      />
      <TravelItineraryContainer />
      <ModifyTravelFormContainer />
      <CustomizeMapContainer />
      <VideoPopupContainer />
      <SharePopup link={link} />
    </Box>
  );
};

export default ControlsSpace;
