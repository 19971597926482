import React from 'react';
import { TiktokItem } from './TiktokItem/TiktokItem';

export const TiktokStories = ({
  tiktok,
  close,
}: {
  tiktok: string[];
  close: () => void;
}) => {
  return <TiktokItem tiktokUrl={tiktok} handleClose={close} />;
};
