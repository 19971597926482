import { EasingFunctions } from '~/animationEngine/utility/easing';
import { GeoJSONSource, Map } from 'maplibre-gl';
import {
  Feature,
  LineString,
  Position,
  along,
  length,
  lineString,
  nearestPointOnLine,
  point,
} from '@turf/turf';
import {
  Config,
  MultiTransportAnimationController,
  MultiTransportStates,
} from '../MultiTransportAnimationController';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { FerryAnimationController } from '../FerryAnimationController';
import { StraightLineController } from '../StraightLineController';
import { LandTransportAnimationConfig } from '../AnimationController';
import { mapOffset } from '~/components/ViewTravel/MobileFooter/BottomSheet';

export class StraightLineState implements MultiTransportStates {
  private controller: FerryAnimationController;
  private dynamicFerryPath: Position[] = [];
  private pathLineString!: Feature<LineString>;
  private animationStartTime: number = 0;
  private lastPathIndex: number = 0;
  flyToDuration: number = 5000;
  animationStartZoom!: number;

  constructor(
    private map: Map,
    private index: number,
    private tb: CustomThreeJSWrapper,
    private stateMachine: MultiTransportAnimationController,
    private config: Config,
  ) {
    this.controller = new StraightLineController(this.map, this.index, this.tb);
    this.controller.setup(this.config, this.index, false);
  }

  getTotalTime(): number {
    return this.controller.totalTime * 1000;
  }

  onEnter() {
    this.startAnimation();
  }

  onReset() {
    if (this.controller) {
      this.controller.cleanup();
    }
  }

  onCleanup() {
    if (this.controller) {
      this.controller.cleanup();
    }
  }

  onExit() {}

  onUpdate(delta: number) {
    // this.updatePath();
    this.controller.update(delta);
  }

  /**
   * This function setup initial path for straight dotted line
   */
  setupNoTransportationPath() {
    // Generate Straight line between two points
    const straightLineCoords = this.config.decodedPath.path;

    // Fill whole dynamicPath array with first coordinate of actual path
    if (straightLineCoords.length > 0) {
      // Get the first value from the path array
      const initialValue = straightLineCoords[0];
      // Get the length of the path array
      const pathLength = straightLineCoords.length;
      // Create a new array of the desired length and fill it with the initial value
      this.dynamicFerryPath = new Array(pathLength).fill(initialValue);
    }

    this.pathLineString = lineString(straightLineCoords);
  }

  /**
   * This function takes position and updates the linestring feature and update
   * dynamicFerryPath array by adding given position point
   *
   * @param position It is the point generated along the line
   * @param pathLineString It is the linestring features array
   * @param dynamicFerryPath It is the array containing points generated for the line
   */
  updatePathLine(
    position: Position,
    pathLineString: Feature<LineString>,
    dynamicFerryPath: Position[],
  ) {
    const myPoint = point(position);

    // Calculate the closest point and the closest index
    const closestPoint = nearestPointOnLine(pathLineString, myPoint);
    const closestIndex = closestPoint.properties.index as number;
    const line = pathLineString.geometry.coordinates;

    if (line.length === 0) {
      return [];
    }

    // update path from the lastPathIndex to the closestIndex in the dynamicPath
    for (let i = this.lastPathIndex + 1; i <= closestIndex; i++) {
      dynamicFerryPath[i] = line[i];
    }
    // update lastPathIndex with the new closestIndex
    this.lastPathIndex = closestIndex as number;

    // Fill from closestIndex till the end with the last position
    dynamicFerryPath.fill(position, closestIndex + 1);
  }

  /**
   * Updates the line string geometry of a route on the map. This function is
   * used internally by the TravelAnimation class to update the visual representation
   * of the travel path as the animation progresses.
   *
   * @param updatedCoordinates An array of Position objects representing the new coordinates
   *                           for the line string
   *
   * @remarks
   *   - This function assumes a GeoJSON source named `'route' + this.index` exists
   *     on the map, where `this.index` is a property of the calling object.
   *   - It updates the source's data with a new GeoJSON LineString geometry created
   *     from the provided `updatedCoordinates`.
   *   - While the commented-out section suggests buffer functionality, it's not
   *     included in the provided code.
   */
  updateLineString(updatedCoordinates: Position[]) {
    let id = 'straightLine' + this.index;

    const source = this.map.getSource(id) as GeoJSONSource;
    const line = lineString(updatedCoordinates);

    if (source) source.setData(line);
  }

  calculatePositionAlongPath(easedTimeProgress: number): Position {
    const len = length(this.pathLineString, { units: 'meters' }); // Get the total length of the path

    const distanceFromTP = len * easedTimeProgress; // Calculate the distance along the path based on the time parameter

    const lngLatAtTime = along(this.pathLineString, distanceFromTP, {
      // Find the coordinates at the calculated distance
      units: 'meters', // Specify the units for distance calculation (kilometers in this case)
    });

    const coord = lngLatAtTime.geometry.coordinates; // Extract the coordinates from the GeoJSON

    return coord; // Return an object containing the calculated position and original coordinates
  }

  calculateTimeProgress() {
    let totalAnimationDuration = this.flyToDuration;
    let now = performance.now();
    let timeElapsed = now - this.animationStartTime;

    return timeElapsed / totalAnimationDuration;
  }

  setState() {
    this.stateMachine.setState(this.stateMachine.transitionState);
  }

  onPause() {
    if (this.controller) {
      this.controller.onPause();
    }
  }

  onPlay() {
    if (this.controller) {
      this.controller.onPlay();
    }
  }

  /**
   * This function updates the line on map by generating points and add those
   * points in the line. Furthermore, it changes camera position on map and
   * move camera with the line from start to end
   */
  updatePath() {
    const easedTimeProgress = this.calculateTimeProgress();
    // Calculate position along the path based on eased time progress
    const coord = this.calculatePositionAlongPath(easedTimeProgress);

    // Update the dynamic path line with the new coordinate
    this.updatePathLine(coord, this.pathLineString, this.dynamicFerryPath);

    let modelPosition = { lng: coord[0], lat: coord[1] };

    if (easedTimeProgress >= 1) this.setState();
    else {
      this.map.flyTo({
        center: modelPosition,
        bearing: this.stateMachine.mapBearing,
        pitch: this.stateMachine.mapPitch,
        zoom: this.animationStartZoom,
        duration: 100,
        easing: EasingFunctions.linear,
        essential: true,
        offset: mapOffset.value,
      });
    }

    // Update the dynamic path line string
    this.updateLineString(this.dynamicFerryPath);
  }

  /**
   * Resets all GeoJSON data sources used for route and buffered lines associated with animation segments.
   * This function iterates through the animation travel data and clears the GeoJSON data for each segment's route and buffered line source.
   * This is typically used to clear previous route or buffer visualizations before starting a new animation.
   */
  resetGeoJSONSources(path: Position[] = []): void {
    let id = 'straightLine' + this.index;

    if (this.map.getSource(id)) {
      const source = this.map.getSource(id) as GeoJSONSource;

      source.setData({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: path,
        },
        properties: {},
      });
    }
  }

  startAnimation(): void {
    const animationConfig: LandTransportAnimationConfig = {
      duration: this.stateMachine.durationForEachStep, // Replace with actual duration
      onCompleteCallback: () => {
        this.stateMachine.setState(this.stateMachine.transitionState); // Transition to the next state
      },
    };
    this.controller.setupAnimation(animationConfig);
  }
}
