import { Loader } from '@googlemaps/js-api-loader';

/**
 * @function setupGoogle
 * Initializes the Google Maps PlacesService and stores it in a ref.
 */
export async function setupGoogle() {
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    version: 'weekly',
    authReferrerPolicy: 'origin',
    language: 'en',
  });

  await loader.importLibrary('places');
}

/**
 * @function geocode
 * Geocodes the given request using Google Maps AutocompleteService.
 * @param {string} request - The input request to geocode.
 * @returns {Promise<Array<Object>>} A promise that resolves with an array of geocoded results.
 */
export async function geocode(
  request: string,
  types?: string[],
): Promise<Array<Object>> {
  const autocompleteService = new google.maps.places.AutocompleteService();

  return new Promise((resolve) => {
    autocompleteService.getPlacePredictions(
      {
        input: request,
        types: types,
      },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (predictions) {
            const detailsPromises = predictions.map((prediction) => {
              const terms = prediction.terms;
              const mainText =
                prediction.structured_formatting?.main_text || '';
              const secondaryText =
                prediction.structured_formatting?.secondary_text || '';

              const types = prediction.types || '';

              const placeId = prediction.place_id;

              let country = '';
              let city = '';

              if (terms.length === 1) {
                country = terms[0].value;
              } else if (terms.length > 1) {
                city = terms[terms.length - 2].value;
                country = terms[terms.length - 1].value;
              }

              const result = {
                value: mainText,
                label: mainText,
                text: mainText,
                street: secondaryText,
                code: country,
                city: city,
                country: country,
                placeId: placeId,
                types,
              };

              return result;
            });

            resolve(Promise.all(detailsPromises));
          }
        } else {
          resolve([]);
        }
      },
    );
  });
}
