import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import useStyles from './styles';
import { SOCIAL_MEDIA_LINKS, SUPPORT_EMAIL } from './constants';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';
const FooterLink = styled('a')({
  textDecoration: 'none',
  color: '#4F535B',
});

/**
 * Footer component represents the footer section of the application.
 *
 * @returns {JSX.Element} Footer component.
 */
const Footer = () => {
  const classes = useStyles();

  const isMobile = useIsMobile();

  return (
    <footer>
      <div className={classes.footerContainer}>
        <div className={classes.footerAboutColumn} style={{ flex: '1' }}>
          <img
            src="/logoVizualTravel.svg"
            alt="Logo"
            className={classes.footerLogo}
          />
          <p className={classes.footerAboutText}>
            Showcase travel in the most useful and interesting way.
          </p>
          <Box className={classes.footerIconBox}>
            <FooterLink href={SOCIAL_MEDIA_LINKS.facebook}>
              <img
                src="/icons/facebookIcon.svg"
                alt="Facebook Icon"
                style={{ cursor: 'pointer', height: '28px' }}
              />
            </FooterLink>
            <FooterLink href={SOCIAL_MEDIA_LINKS.twitter}>
              <img
                src="/icons/twitterIcon.svg"
                alt="Twitter Icon"
                style={{ cursor: 'pointer', height: '26px' }}
              />
            </FooterLink>
            <FooterLink href={SOCIAL_MEDIA_LINKS.instagram}>
              <img
                src="/icons/instagramIcon.svg"
                alt="Instagram Icon"
                style={{ cursor: 'pointer', height: '26px' }}
              />
            </FooterLink>
            <FooterLink href={SOCIAL_MEDIA_LINKS.youtube}>
              <img
                src="/icons/youtubeIcon.svg"
                alt="YouTube Icon"
                style={{ cursor: 'pointer', height: '24px' }}
              />
            </FooterLink>
          </Box>
        </div>
        <div className={classes.footerCenterColumn}>
          <div className={classes.quickAccessColumn}>
            <div className={classes.footerColumnTitle}>Quick Access</div>
            <div className={classes.footerColumnContainer}>
              <FooterLink className={classes.footerLink} href="#home">
                Home
              </FooterLink>
              <FooterLink className={classes.footerLink} href="#about">
                About
              </FooterLink>
              <FooterLink className={classes.footerLink} href="#features">
                Features
              </FooterLink>
            </div>
          </div>
          <div className={classes.featuresColumn}>
            <div className={classes.footerColumnTitle}>How-to</div>
            <div className={classes.footerColumnContainer}>
              <FooterLink className={classes.footerLink} href="#customItinery">
                Add Travel Itinerary
              </FooterLink>
              <FooterLink className={classes.footerLink} href="#generateVideo">
                Automatic Travel Animation
              </FooterLink>
              <FooterLink className={classes.footerLink} href="#changeMap">
                Publish and Share
              </FooterLink>
            </div>
          </div>
        </div>
        <div className={classes.footerContactColumn} style={{ flex: '1' }}>
          <div className={classes.footerColumnTitle}>Contact Us</div>
          <div className="d-flex align-items-start flex-column gap-3">
            <FooterLink
              className={classes.footerLink}
              href={`mailto:${SUPPORT_EMAIL}`}
            >
              <img
                src="/icons/emailIcon.svg"
                alt="Email Icon"
                style={{ marginRight: '12px' }}
              />
              {SUPPORT_EMAIL}
            </FooterLink>
          </div>
        </div>
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          alignItems: 'center',
          backgroundColor: '#FFF6F3',
          marginBottom: '1.2rem',
        }}
      >
        {!isMobile && <Box></Box>}
        <Box className={classes.footerCopyrightText}>
          Copyright &copy; VizualTravel 2024
        </Box>

        <Box
          sx={{
            marginBottom: '1.2rem',
          }}
        >
          <div
            className="liveagent-startups"
            style={{
              fontSize: '.8rem',
              fontWeight: '300',
            }}
          >
            <a
              href="https://www.liveagent.com/help-desk-software/?utm_campaign=liveagent-startups"
              title="LiveAgent"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
                color: 'initial',
                fontFamily: 'Futura Md BT',
                marginRight: '.5rem',
              }}
            >
              Chat powered by
            </a>
            <a
              href="https://www.liveagent.com/?utm_campaign=liveagent-startups"
              title="LiveAgent"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://www.liveagent.com/app/themes/liveagent/assets/images/logo_liveagent.svg"
                alt="LiveAgent"
                style={{ maxWidth: '60px', height: '60px', width: '60px' }}
              />
            </a>
          </div>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
