import styled from '@emotion/styled';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { ChangeEvent } from 'react';

interface AddPriceDialogProps {
  open: boolean;
  onClose: (price: number) => void;
  state: number | null;
  setState: React.Dispatch<React.SetStateAction<number | null>>;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 361,
    padding: '10px 10px',
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

const DoneButton = styled(Button)({
  width: 120,
  height: 64,
  borderRadius: 18,
  marginTop: '10px',
  fontFamily: 'Poppins',
  fontSize: '16px',
  background:
    ' radial-gradient(100% 100% at 0% 0%, #FF9345 0.01%, #FE7138 50%, #F25D21 100%) ',
  boxShadow: `0px 2px 6px #b8c5d033, 0px 6px 22px #b8c5d04d, 
              0px 9px 30px #c6d8e933, inset 0px 1.5px 1.5px #ffffff40`,
  '&:hover': { backgroundColor: '#e6602b' },
});

export const AddPriceDialog: React.FC<AddPriceDialogProps> = ({
  open,
  onClose,
  state,
  setState,
}) => {
  const handleDone = () => {
    if (state !== null) {
      onClose(state);
    }
  };

  const handlePriceChanges = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setState(null);
    } else {
      const parsedValue = parseInt(value);
      setState(Math.abs(parsedValue));
    }
  };

  return (
    <StyledDialog open={open} onClose={() => onClose(0)}>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Add Price
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Inter, sans-serif',
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '29.05px',
              textAlign: 'center',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
            }}
          >
            ~US$
          </Typography>

          <TextField
            type="number"
            value={state !== null ? state : ''}
            onChange={handlePriceChanges}
            fullWidth
            sx={{
              width: '100px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px', // Apply border radius to the input container
              },
            }}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        </Box>

        <DoneButton onClick={handleDone} disabled={state === null}>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: '#fff', fontFamily: 'Poppins, sans-serif' }}
          >
            Done
          </Typography>
        </DoneButton>
      </DialogContent>
    </StyledDialog>
  );
};
