import { Text } from 'troika-three-text';
import * as THREE from 'three';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';

class TextAnimation {
  text: string;
  tb: CustomThreeJSWrapper;

  constructor(text: string, tb: CustomThreeJSWrapper) {
    this.text = text;
    this.tb = tb;
  }

  loadText() {
    const geometry = new THREE.PlaneGeometry(1000000, 1000000);

    const planeMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
    });

    const planeMesh = new THREE.Mesh(geometry, planeMaterial);

    const myText = new Text();
    myText.text = this.text;
    myText.fontSize = 40000;

    myText.color = 0x000000;
    myText.anchorX = '50%';
    myText.anchorY = '50%';
    myText.rotation.x = Math.PI;
    myText.rotation.y = Math.PI;

    const subContainer = new THREE.Group();

    subContainer.add(myText);
    planeMesh.add(subContainer);

    return planeMesh;
  }
}

export { TextAnimation };
