import { DiscoverButtons } from '../DiscoverMenu';
import { GridContent } from '../GridContent/GridContent';

export const GridContainer = ({
  activeButton,
  setActiveButton,
  dataToShow,
  handleShowStories,
}: {
  activeButton: DiscoverButtons;
  setActiveButton: (button: DiscoverButtons) => void;
  dataToShow: any;
  handleShowStories: (idx: number) => void;
}) => {
  return (
    <div className="discover-menu-content">
      <GridContent
        activeSection={activeButton}
        dataToShow={dataToShow}
        handleShowStories={handleShowStories}
      />
    </div>
  );
};
