import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import { ICompletedPlace } from '~/types/websocket';
import ActionsCreator from '~/redux/actions';
import { saveAndPublishTravelPointsFromServer } from '~/utility/utils';

const Serve: React.FC = () => {
  const dispatch = useDispatch();

  const createVisualization = async () => {
    const savedData = sessionStorage.getItem('itinerary');
    if (savedData) {
      const itinerary = JSON.parse(savedData) as ICompletedPlace;

      if (itinerary && itinerary?.departures?.length > 0) {
        dispatch(ActionsCreator.setLoading(true));

        await saveAndPublishTravelPointsFromServer({
          departures: itinerary?.departures,
          arrivals: itinerary?.arrivals,
          selectedTransports: itinerary?.selectedTransports,
          selectedTransportImages: [],
          transportationCosts: itinerary?.transportationCosts,
          title: itinerary.title,
          dayIndexes: itinerary.dayIndexes,
          description: itinerary.description,
          quickTips: itinerary?.quickTips,
        });
      }
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button id="createVisualization" onClick={createVisualization}>
        Create Visualization
      </Button>
    </Box>
  );
};

export default Serve;
