import React, { useState } from 'react';
import { Dialog, DialogContent, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  bottomSheetp2pStateSignal,
  commonButtonsSx,
} from '../P2PAI/MapPreview/BottomSheetp2p';
import { dayCounterSignal } from '../P2PAI';
import { messagesSignal } from '../signals/itinerary/messagesSignal';
import { useSignals } from '@preact/signals-react/runtime';
import { previewMapSignal } from '../P2PAI/MapPreview';
import ROUTES from '~/routes';
import { addPauseSignal } from '../signals/itinerary/pauseSignal';
import { refreshAllPreviousMarkersSignal } from '../signals/refreshAllPreviousMarkersSignal';
import { showStopButtonSignal } from '../P2PAI/MapPreview/MapHeader';
import useSignal from '~/hooks/subscribeSignal';

export const PREVIOUS_ITINERARIES_KEY = 'PREVIOUS_ITINERARIES';
export const NAVIGATE_TO = ' NAVIGATE_TO';

interface ActionConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirmNavigateTo: string; // Dynamic navigation route
}

const ActionConfirmationDialog: React.FC<ActionConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirmNavigateTo,
}) => {
  useSignals();
  const navigate = useNavigate();

  const [messages, setMessages] = useState(messagesSignal.peek());

  useSignal(messagesSignal, setMessages);


  const handleYes = () => {
    if (onConfirmNavigateTo === ROUTES.P2PAI.path) {
      bottomSheetp2pStateSignal.value = 'dayready';
      addPauseSignal.value = false;
      refreshAllPreviousMarkersSignal.value = 1;
      showStopButtonSignal.value = -1;
      previewMapSignal.value = null;
    }

    navigate(onConfirmNavigateTo);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          borderRadius: '20px 20px 20px 20px',
          backgroundColor: '#fff',
        },
      }}
    >
      <DialogContent
        sx={{
          borderRadius: '16px 16px 16px 16px',
          background: '#FFFFFF',
          width: '100%',
          textAlign: 'center',
          mb: '2rem',
        }}
      >
        {onConfirmNavigateTo === ROUTES.P2PAI.path ? (
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontWeight: 700,
              fontSize: '24px',
              color: '#000000',
              lineHeight: '36px',
            }}
          >
            Continue with <br />
            <b style={{ color: '#FE7138' }}>
              {' '}
              Day {dayCounterSignal.value}
            </b> of{' '}
            <b style={{ color: '#FE7138' }}>
              {
                messages[messages.length - 1]?.place?.location?.value
              }
            </b>
          </Typography>
        ) : (
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontWeight: 700,
              fontSize: '24px',
              color: '#000000',
              lineHeight: '36px',
            }}
          >
            Continue with the Saved Trip
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          sx={{
            ...commonButtonsSx,
            margin: 0,
            width: '100%',
            padding: '4px 8px',
          }}
          onClick={handleYes}
        >
          Yes
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{
            ...commonButtonsSx,
            width: '100%',
            padding: '4px 8px',
            boxShadow:
              '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
            background:
              'radial-gradient(100% 100% at 0% 0%, #FFFFFF 0.01%, #FFFFFF 50%, #FFFFFF 100%)',
            color: '#FE7138',
          }}
          onClick={() => {
            onClose();
            sessionStorage.removeItem(PREVIOUS_ITINERARIES_KEY);
            sessionStorage.removeItem(NAVIGATE_TO);
          }}
        >
          No
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ActionConfirmationDialog;
