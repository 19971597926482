import { Typography } from '@mui/material';

import { useState } from 'react';
import { showStoriesSignal } from '~/components/ViewTravel/StoriesOverlay';
import { useDispatch, useSelector } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import { motion, AnimatePresence } from 'framer-motion';
import { resetAutoYesProgress } from '../BottomSheetp2p';
import CloseIcon from '@mui/icons-material/Close';
import { getIconByCategory } from '~/components/ViewTravel/MobileFooter/helpers';
import {
  selectCurrentOverviewData,
  selectIsStoriesOpened,
} from '~/redux/selectors/tripInfoSelectors';

import './style.css';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import { useLocation } from 'react-router-dom';
import ROUTES from '~/routes';
import dayjs from 'dayjs';

const SLIDE_SIZE = 200;

export const OverviewContainerWrapper = () => {
  const currentOverviewData = useSelector(selectCurrentOverviewData);
  const location = useLocation();
  const isp2pAiPath = location.pathname === ROUTES.P2PAI.path;

  const [imageError, setImageError] = useState(false);
  const dispatch = useDispatch();
  const isStoriesOpened = useSelector(selectIsStoriesOpened);
  const isMobile = useIsMobile();

  if (!isp2pAiPath) {
    return null;
  }

  const getThumbContent = (images: string[]) => {
    let thumbContent = <></>;
    const ext =
      images && images[0]
        ? images[0].split('.').pop()?.toLocaleLowerCase()
        : undefined;
    if (ext === 'mp4') {
      thumbContent = (
        <video>
          <source src={images[0]} />
        </video>
      );
    } else {
      thumbContent = (
        <img
          src={images[0]}
          alt={String('img')}
          loading="lazy"
          onError={() => setImageError(true)}
        />
      );
    }
    return thumbContent;
  };

  const initiaStyles = isMobile
    ? { opacity: 0, x: -500 }
    : { opacity: 0, y: -500, x: '-50%' };
  const animateStyles = isMobile
    ? { opacity: 1, x: 0 }
    : { opacity: 1, y: 0, x: '-50%' };
  const exitStyles = isMobile
    ? { opacity: 0, x: -500 }
    : { opacity: 0, y: -500, x: '-50%' };

  const handleClose = () => {
    resetAutoYesProgress();
    dispatch(ActionsCreator.setCurrentOverviewData(null));
  };

  const targetLinkLabel =
    currentOverviewData?.point?.location?.label ||
    currentOverviewData?.location?.label ||
    '';

  return (
    <>
      <AnimatePresence>
        {currentOverviewData && (
          <motion.div
            transition={{
              duration: 0.25,
              bounce: false,
            }}
            onClick={handleClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="overview-backdrop"
            style={isStoriesOpened ? { zIndex: -1 } : { zIndex: 999 }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {currentOverviewData && (
          <motion.div
            initial={initiaStyles}
            animate={animateStyles}
            exit={exitStyles}
            className={`overview-container-wrapper ${
              isMobile ? '' : 'desktop'
            }`}
            style={{
              scrollbarWidth: 'none',
              paddingBottom: '30px',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflowY: 'auto',
              zIndex: isStoriesOpened ? -1 : 999,
            }}
          >
            <CloseIcon
              className="close-btn"
              style={{
                cursor: 'pointer',
              }}
              fontSize="medium"
              onClick={handleClose}
            />

            <motion.div
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.15 }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 0,
                marginBottom: 10,
                maxWidth: '90%',
                justifyContent: 'center',
              }}
            >
              <img
                src={getIconByCategory(
                  currentOverviewData.category ||
                    currentOverviewData.point.category,
                )}
                height={48}
                loading="lazy"
                alt=""
              />
              <Typography
                fontSize={24}
                fontFamily={'Poppins'}
                fontWeight={'700'}
                style={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  textAlign: 'center',
                }}
              >
                {currentOverviewData?.location?.label ||
                  currentOverviewData?.point?.location?.label ||
                  ''}
              </Typography>
            </motion.div>
            <div
              className="overview-content-wrapper"
              style={{
                height: window.innerHeight * 0.8,
              }}
            >
              {currentOverviewData.description && (
                <div>
                  <Typography
                    fontSize={16}
                    fontFamily={'Poppins'}
                    fontWeight={'400'}
                    lineHeight={'18.05px'}
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      color: '#BDBDBD',
                      padding: '0 10px',
                      textAlign: 'center',
                      marginBottom: '16px',
                    }}
                  >
                    {currentOverviewData.description}
                  </Typography>
                </div>
              )}
              {(!!currentOverviewData.review || !!currentOverviewData.cost) && (
                <motion.div
                  initial={{ opacity: 0, x: -200 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.25 }}
                >
                  <div className="cost-review-section">
                    {!!currentOverviewData.review && (
                      <div className="custom-badge content-fit">
                        {currentOverviewData.review}
                        <span>⭐</span>
                      </div>
                    )}
                    {!!currentOverviewData.cost && (
                      <div className="custom-badge content-fit">
                        ~US${currentOverviewData.cost}
                      </div>
                    )}
                  </div>
                </motion.div>
              )}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  marginBottom: 20,
                }}
              >
                <motion.div
                  initial={{ opacity: 0, x: -200 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.25 }}
                >
                  <div
                    style={{
                      width: SLIDE_SIZE,
                      height: SLIDE_SIZE,
                      borderRadius: 32,
                      overflow: 'hidden',
                      justifyContent: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'lightgray',
                      boxShadow:
                        '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933,0px 1.5px 1.5px 0px #FFFFFF40 inset',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (currentOverviewData.images.length > 0) {
                        showStoriesSignal.value = currentOverviewData.images;
                        ActionsCreator.setIsStoriesOpened(true);
                      } else {
                        showStoriesSignal.value = undefined;
                      }
                    }}
                  >
                    {/* {currentOverviewData.images.length > 0 && !imageError ? (
                      <>{getThumbContent(currentOverviewData.images)}</>
                    ) : (
                      <img
                        src={getIconByCategory(
                          currentOverviewData.category ||
                            currentOverviewData.point.category,
                        )}
                        height={SLIDE_SIZE * 0.5}
                        loading="lazy"
                        alt="icon"
                      />
                    )} */}
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: -200 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.35 }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '95%',
                    paddingTop: 4,
                    paddingBottom: 24,
                  }}
                >
                  {currentOverviewData.dayIndex && (
                    <div
                      className="custom-badge mb-3"
                      style={{ display: 'flex' }}
                    >
                      <span>Day {currentOverviewData.dayIndex}</span>
                    </div>
                  )}
                  {(currentOverviewData.dateTime ||
                    currentOverviewData.point.dateTime) &&
                    (currentOverviewData.timezone ||
                      currentOverviewData.point.timezone) && (
                      <div
                        className="custom-badge mb-3"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <span>
                          {dayjs(
                            String(
                              currentOverviewData.dateTime ||
                                currentOverviewData.point.dateTime ||
                                '',
                            ),
                          )
                            .tz(
                              currentOverviewData.timezone ||
                                currentOverviewData.point.timezone ||
                                '',
                            )
                            .format('hh:mm A')}
                          {currentOverviewData.prevPoint &&
                            currentOverviewData.nextPoint &&
                            currentOverviewData.departureDateTime &&
                            currentOverviewData.departureTimeZone && (
                              <span>-</span>
                            )}
                        </span>
                        {currentOverviewData.prevPoint &&
                          currentOverviewData.nextPoint &&
                          currentOverviewData.departureDateTime &&
                          currentOverviewData.departureTimeZone && (
                            <span>
                              {dayjs(
                                String(
                                  currentOverviewData.departureDateTime || '',
                                ),
                              )
                                .tz(currentOverviewData.departureTimeZone || '')
                                .format('hh:mm A')}
                            </span>
                          )}
                      </div>
                    )}
                </motion.div>
              </div>
              {currentOverviewData.longDescription && (
                <motion.div
                  initial={{ opacity: 0, x: -200 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.35 }}
                >
                  <div className="long-desc">
                    {currentOverviewData.longDescription}
                  </div>
                </motion.div>
              )}
              <motion.div
                initial={{ opacity: 0, x: -200 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.35 }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '95%',
                  paddingTop: 24,
                  paddingBottom: 24,
                }}
              >
                <a
                  className="custom-badge"
                  target="_blank"
                  href={`https://google.com/maps/search/${targetLinkLabel
                    .split(' ')
                    .join('+')}`}
                  style={{ textDecoration: 'none' }}
                  rel="noreferrer"
                >
                  Search on Google
                </a>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
