import { Dispatch, FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import {
  EncodedPathDataObject,
  TravelOptions,
  TravelPointsByDayIndex,
} from '~/utility/models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import { P2PTimeDisplay } from './P2PTimeDisplay';
import { TimeProps } from '../P2PTravelPoint';
import ActionsCreator from '~/redux/actions';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  generateEncodedPathAndDuration,
  getDifferenceInMinutes,
  getTravelMode,
  updateNextDays,
  updateNextPointByDay,
} from '~/utility/utils';
import { CarModels } from '~/animationEngine/utility/enums/CarModels';
import { filteredPoints } from '~/redux/selectors/P2PManualSelectors';
import CloseIcon from '@mui/icons-material/Close';

dayjs.extend(utc);
dayjs.extend(timezone);

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 361,
    padding: '10px 10px',
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

interface P2PSelectTimeModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  selectedLocation: TravelOptions;
  selectedIndex: number;
  selectedTime: TimeProps;
  setSelectedTime: Dispatch<TimeProps>;
}

export const P2PSelectTimeModal: FC<P2PSelectTimeModalProps> = ({
  open,
  onClose,
  onSave,
  selectedLocation,
  selectedIndex,
  selectedTime,
  setSelectedTime,
}) => {
  const dispatch = useDispatch();

  const travelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );

  const filteredTravelPoints = useSelector(filteredPoints);

  const [showFullEnd, setShowFullEnd] = useState<boolean>(false);

  const [timeRanges, setTimeRanges] = useState({
    min: dayjs().startOf('day'),
    max: dayjs().endOf('day'),
  });

  const [editingStart, setEditingStart] = useState(false); // Flag to track if startTime is being edited
  const [editingEnd, setEditingEnd] = useState(false); // Flag to track if endTime is being edited

  const handleClick = (type: 'start' | 'end') => {
    setEditingStart(type === 'start');
    setEditingEnd(type === 'end');
  };

  useEffect(() => {
    const getTimeRangeForIndex = (index: number) => {
      const currentPoint = filteredTravelPoints[index];
      const firstPoint = filteredTravelPoints[0];

      if (editingStart) {
        if (currentPoint === firstPoint) {
          return {
            min: dayjs().tz(currentPoint.timezone).startOf('day'),
            max: dayjs().tz(currentPoint.timezone).endOf('day'),
          };
        }
        return {
          min: dayjs(firstPoint.startDate).tz(currentPoint.timezone),
          max: dayjs().tz(currentPoint.timezone).endOf('day'),
        };
      }

      if (editingEnd) {
        return {
          min: dayjs(currentPoint.startDate).tz(currentPoint.timezone),
          max: dayjs().tz(currentPoint.timezone).endOf('day'),
        };
      }

      return {
        min: dayjs(firstPoint.startDate).tz(currentPoint.timezone),
        max: dayjs().tz(currentPoint.timezone).endOf('day'),
      };
    };
    const { min, max } = getTimeRangeForIndex(selectedIndex);
    setTimeRanges({
      min,
      max,
    });
  }, [
    selectedIndex,
    filteredTravelPoints,
    selectedLocation.timezone,
    selectedTime.endTime,
    selectedLocation.id,
    selectedLocation.endDate,
    selectedLocation.startDate,
    editingStart,
    editingEnd,
    selectedLocation.duration,
  ]);

  const handleSaveTime = async (time: Dayjs, type: 'start' | 'end') => {
    const previousIndex = selectedIndex - 1;
    const nextIndex = selectedIndex + 1;

    // Clone objects to avoid direct mutation
    const updatedLocation = { ...selectedLocation };
    const nextPoint = { ...filteredTravelPoints[nextIndex] };
    const previousPoint = { ...filteredTravelPoints[previousIndex] };

    const isEditingStart = type === 'start';
    const isEditingEnd = type === 'end';

    const updateTime = (dateType: 'startDate' | 'endDate') => {
      const newDate = time.tz(updatedLocation.timezone).utc();

      if (dateType === 'startDate') {
        const durationInMinutes = getDifferenceInMinutes(
          updatedLocation.endDate,
          updatedLocation.startDate,
        );

        updatedLocation[dateType] = newDate.format();
        updatedLocation.endDate = newDate
          .add(durationInMinutes, 'minute')
          .format();
      }

      if (dateType === 'endDate') {
        updatedLocation[dateType] = newDate.format();
      }

      return newDate;
    };

    if (isEditingStart) {
      setEditingStart(true);
      setEditingEnd(false);
      const newStartDate = updateTime('startDate');
      setSelectedTime({ ...selectedTime, startTime: newStartDate });
    } else if (isEditingEnd) {
      setEditingEnd(true);
      setEditingStart(false);
      const newEndDate = updateTime('endDate');
      setSelectedTime({ ...selectedTime, endTime: newEndDate });
    }

    // Update travel points for the day
    const currentPointsByDay = [
      ...travelPointsByDayIndex[selectedLocation.dayIndex],
    ];
    currentPointsByDay[selectedIndex] = updatedLocation;

    if (previousPoint?.id) currentPointsByDay[previousIndex] = previousPoint;
    if (nextPoint?.id) currentPointsByDay[nextIndex] = nextPoint;

    // sort by startTime
    let currentPointsByDaySortedByStartTime = currentPointsByDay.sort(
      (a, b) => {
        const first = dayjs(a.startDate).utc();
        const second = dayjs(b.startDate).utc();
        return first.valueOf() - second.valueOf();
      },
    );

    // current point index has changed

    // const newIndexOccupant = currentPointsByDaySortedByStartTime[selectedIndex];
    const currentIndex = currentPointsByDaySortedByStartTime.findIndex(
      (point) => point.id === updatedLocation.id,
    );

    if (selectedIndex !== currentIndex) {
      let duration: number = 0;
      let encodedPath: EncodedPathDataObject | undefined = {
        data: [],
        path: '',
      };

      let currentPointAfterSorting =
        currentPointsByDaySortedByStartTime[currentIndex];

      const pointAfterCurrentIndex =
        currentPointsByDaySortedByStartTime[currentIndex + 1];

      if (pointAfterCurrentIndex) {
        const result = await generateEncodedPathAndDuration(
          {
            ...currentPointAfterSorting,
            selectedTransport: getTravelMode('Drive'),
          },
          pointAfterCurrentIndex,
        );

        duration = result.duration;
        encodedPath = result.encodedPath;
      }

      currentPointsByDaySortedByStartTime[currentIndex] = {
        ...currentPointAfterSorting,
        selectedTransport: getTravelMode('Drive'),
        encodedPath,
        duration,
        travelSegmentConfig: {
          modelScale: 1,
          modelEnum: [CarModels.Car],
          animationSpeed: 1,
        },
      };

      if (previousPoint) {
        const oldPreviousPointCurrentIndex =
          currentPointsByDaySortedByStartTime.findIndex(
            (point) => point.id === previousPoint.id,
          );

        if (oldPreviousPointCurrentIndex !== -1) {
          const isLastPointOfDay =
            oldPreviousPointCurrentIndex ===
            currentPointsByDaySortedByStartTime.length - 1;

          if (isLastPointOfDay) {
            const { encodedPath, ...rest } = previousPoint;
            currentPointsByDaySortedByStartTime[oldPreviousPointCurrentIndex] =
            {
              ...rest,
              duration: 0,
              selectedTransport: getTravelMode('Drive'),
            };
          } else {
            const nextPointAfterOldPrevious =
              currentPointsByDaySortedByStartTime[
              oldPreviousPointCurrentIndex + 1
              ];

            if (nextPointAfterOldPrevious) {
              const result = await generateEncodedPathAndDuration(
                { ...previousPoint, selectedTransport: getTravelMode('Drive') },
                nextPointAfterOldPrevious,
              );

              duration = result.duration;
              encodedPath = result.encodedPath;
            }
          }

          currentPointsByDaySortedByStartTime[oldPreviousPointCurrentIndex] = {
            ...currentPointsByDaySortedByStartTime[
            oldPreviousPointCurrentIndex
            ],
            selectedTransport: getTravelMode('Drive'),
            encodedPath,
            duration,
            travelSegmentConfig: {
              modelScale: 1,
              modelEnum: [CarModels.Car],
              animationSpeed: 1,
            },
          };
        }
      }

      if (currentIndex > 0) {
        const newPrevious =
          currentPointsByDaySortedByStartTime[currentIndex - 1];

        const result = await generateEncodedPathAndDuration(
          { ...newPrevious, selectedTransport: getTravelMode('Drive') },
          currentPointAfterSorting,
        );

        duration = result.duration;
        encodedPath = result.encodedPath;

        currentPointsByDaySortedByStartTime[currentIndex - 1] = {
          ...currentPointsByDaySortedByStartTime[currentIndex - 1],
          selectedTransport: getTravelMode('Drive'),
          encodedPath,
          duration,
          travelSegmentConfig: {
            modelScale: 1,
            modelEnum: [CarModels.Car],
            animationSpeed: 1,
          },
        };
      }

      handleShowFullEndTime();
    }

    // Update Redux state
    let updatedTravelPointsByDayIndex: TravelPointsByDayIndex = {
      ...travelPointsByDayIndex,
      [selectedLocation.dayIndex]: currentPointsByDaySortedByStartTime,
    };

    // check if the last place matches the first of the next point
    updatedTravelPointsByDayIndex = updateNextDays(
      updatedTravelPointsByDayIndex,
      selectedLocation.dayIndex,
      updateNextPointByDay,
    );

    dispatch(
      ActionsCreator.setTravelPointsByDayIndex(updatedTravelPointsByDayIndex),
    );

    // setIsModalOpen(false); // Close the modal after saving

    onSave();
    if (showFullEnd) {
      handleShowFullEndTime();
    }
  };

  const handleShowFullEndTime = () => {
    setShowFullEnd((prev) => !prev);
  };

  const close = () => {
    onClose();
    if (showFullEnd) {
      handleShowFullEndTime();
    }
  };

  useEffect(() => {
    if (showFullEnd) {
      handleShowFullEndTime();
    }
  }, [open]);

  return (
    <StyledDialog open={open} onClose={close}>
      {/* Close Button */}
      <IconButton
        onClick={close}
        style={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: '#000',
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle>
        <div
          style={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '36px',
            textAlign: 'center',
            position: 'relative', // To position the X icon
          }}
        >
          {/* Title Content */}
          <span
            style={{
              color: '#ff8447',
              textTransform: 'capitalize',
            }}
          >
            {selectedLocation?.label}
          </span>
          <br />
          <span
            style={{
              color: '#000',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '27px',
            }}
          >
            Minimum Start Time: {timeRanges.min.format('hh:mm A')}
          </span>
        </div>
      </DialogTitle>

      <DialogContent>
        {selectedIndex === 0 ? (
          <P2PTimeDisplay
            title="Set Start Time"
            selectedTime={selectedTime?.startTime!}
            type="start"
            minTime={timeRanges.min}
            maxTime={timeRanges.max}
            handleSaveTime={handleSaveTime}
            handleClick={() => handleClick('start')}
          />
        ) : selectedIndex === filteredTravelPoints.length - 1 ? (
          <>
            {!showFullEnd ? (
              <P2PTimeDisplay
                title="End of Day Time"
                selectedTime={selectedTime?.startTime!}
                type="start"
                minTime={timeRanges.min}
                maxTime={timeRanges.max}
                handleSaveTime={handleSaveTime}
                handleClick={() => handleClick('start')}
                text="Add a Start Time & End Time"
                handleShowFullEndTime={handleShowFullEndTime}
              />
            ) : (
              <>
                <P2PTimeDisplay
                  title="Set Start Time"
                  selectedTime={selectedTime?.startTime!}
                  type="start"
                  minTime={timeRanges.min}
                  maxTime={timeRanges.max}
                  handleSaveTime={handleSaveTime}
                  handleClick={() => handleClick('start')}
                />
                <P2PTimeDisplay
                  title="Set End Time"
                  selectedTime={selectedTime?.endTime!}
                  type="end"
                  minTime={timeRanges.min}
                  maxTime={timeRanges.max}
                  handleSaveTime={handleSaveTime}
                  handleClick={() => handleClick('end')}
                />
              </>
            )}
          </>
        ) : (
          <>
            <P2PTimeDisplay
              title="Set Start Time"
              selectedTime={selectedTime?.startTime!}
              type="start"
              minTime={timeRanges.min}
              maxTime={timeRanges.max}
              handleSaveTime={handleSaveTime}
              handleClick={() => handleClick('start')}
            />
            <P2PTimeDisplay
              title="Set End Time"
              selectedTime={selectedTime?.endTime!}
              type="end"
              minTime={timeRanges.min}
              maxTime={timeRanges.max}
              handleSaveTime={handleSaveTime}
              handleClick={() => handleClick('end')}
            />
          </>
        )}
      </DialogContent>
    </StyledDialog>
  );
};
