import { ConfigurationProps } from '~/utility/models';

export const planeZoomConfiguration: ConfigurationProps[] = [
  {
    type: 'Plane',
    distanceRange: {
      minDist: 7000,
      maxDist: 100000,
    },
    mapCurveHeight: 400,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 1.6,
    modelSize: 8,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 4000,
      maxDist: 7000,
    },
    mapCurveHeight: 300,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 2.6,
    modelSize: 4,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 3000,
      maxDist: 4000,
    },
    mapCurveHeight: 250,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 3.65,
    modelSize: 3,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 1750,
      maxDist: 3000,
    },
    mapCurveHeight: 250,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 4.8,
    modelSize: 2,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 1250,
      maxDist: 1750,
    },
    mapCurveHeight: 200,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 6.2,
    modelSize: 1.25,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 750,
      maxDist: 1250,
    },
    mapCurveHeight: 200,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 6.2,
    modelSize: 0.75,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 500,
      maxDist: 750,
    },
    mapCurveHeight: 200,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 7,
    modelSize: 0.6,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 250,
      maxDist: 500,
    },
    mapCurveHeight: 150,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 7,
    modelSize: 0.5,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 150,
      maxDist: 250,
    },
    mapCurveHeight: 100,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 8,
    modelSize: 0.3,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 75,
      maxDist: 150,
    },
    mapCurveHeight: 100,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 10,
    modelSize: 0.2,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 40,
      maxDist: 75,
    },
    mapCurveHeight: 100,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 11,
    modelSize: 0.1,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 20,
      maxDist: 40,
    },
    mapCurveHeight: 100,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 11,
    modelSize: 0.05,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 10,
      maxDist: 20,
    },
    mapCurveHeight: 100,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 11,
    modelSize: 0.04,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
  {
    type: 'Plane',
    distanceRange: {
      minDist: 0,
      maxDist: 10,
    },
    mapCurveHeight: 100,
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 11,
    modelSize: 0.03,
    modelGrowthPercentage: 10,
    curveSpeed: 0.015,
  },
];

export const carZoomConfiguration: ConfigurationProps[] = [
  {
    type: 'Car',
    distanceRange: {
      minDist: 7000,
      maxDist: 100000,
    },
    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 1,
    modelSize: 8,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 4000,
      maxDist: 7000,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 2,
    modelSize: 4,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 3000,
      maxDist: 4000,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 3,
    modelSize: 3,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 1750,
      maxDist: 3000,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 4,
    modelSize: 2,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 1250,
      maxDist: 1750,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 5,
    modelSize: 1,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 750,
      maxDist: 1250,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 6,
    modelSize: 1,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 500,
      maxDist: 750,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 7,
    modelSize: 0.9,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 250,
      maxDist: 500,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 8,
    modelSize: 0.85,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 150,
      maxDist: 250,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 9,
    modelSize: 0.8,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 75,
      maxDist: 150,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 10,
    modelSize: 0.7,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 40,
      maxDist: 75,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 11,
    modelSize: 0.6,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 20,
      maxDist: 40,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 11,
    modelSize: 0.6,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 10,
      maxDist: 20,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 12,
    modelSize: 0.5,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 5,
      maxDist: 10,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 13,
    modelSize: 0.5,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 2,
      maxDist: 5,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 13,
    modelSize: 0.4,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 1,
      maxDist: 2,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 14,
    modelSize: 0.4,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
  {
    type: 'Car',
    distanceRange: {
      minDist: 0,
      maxDist: 1,
    },

    mapPitch: 20,
    mapBearing: 0,
    mapZoom: 15,
    modelSize: 0.3,
    modelGrowthPercentage: 10,
    curveSpeed: 0,
    mapCurveHeight: 0,
  },
];
