import './style.css'
import { useEffect, useState } from "react"
import { categoryViewSignal, destinationStateSignal, TextTicker } from '../../common'

const StayPointLabelMobile = () => {

    const [staypoint, setStaypoint] = useState<any>({

    })

    useEffect(() => {
        const unsub = destinationStateSignal.subscribe((value) => {
            setStaypoint(value)
        })
        return () => {
            unsub()
        }
    }, [])

    return (
        <div className='d-flex flex-column' style={{ width: '100%' }}>
            <div className='daydate-mobile'>
                {staypoint?.duration && (
                    <span>
                        <b>Stay</b>&nbsp;<p className='m-0 d-inline'>for</p>&nbsp;
                        <b>{`${staypoint?.duration}`}</b>&nbsp;<p className='m-0 d-inline'>at</p>&nbsp;
                    </span>
                )}
                <b>{categoryViewSignal.peek().label || ''}</b>
            </div>
        </div>
    );


}

export default StayPointLabelMobile;

