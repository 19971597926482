import React from 'react';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import playIcon from '~assets/playIcon.png';

import './GridItem.css';

interface MediaProps {
  thumbnail: string;
  type: string;
  url: string;
}

export interface GridItemProps {
  name: string;
  url_book_place: string;
  summary: string;
  review: number;
  price: number;
  notes: string;
  media: MediaProps[];
  storyIndex: number;
}

export const GridItem = ({
  item,
  showStory,
}: {
  item: GridItemProps;
  showStory: (index: number) => void;
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={`discover-menu-grid--item ${!isMobile && 'desktop'}`}
      onClick={() => showStory(item.storyIndex)}
    >
      <img src={item.media[0].thumbnail} alt="" />
      <div className="discover-menu-grid--item-content">
        <span className="discover-menu-grid--item-text">{item.name}</span>
        <div className="discover-menu-grid--bottom-content">
          {item.review && (
            <span className="discover-menu-grid--bottom-review ">
              {item.review}⭐
            </span>
          )}{' '}
          <span className="discover-menu-grid--item-text bottom">
            {item.price === 0 ? 'FREE' : `${item.price} USD`}
          </span>
        </div>
        <img
          src={playIcon}
          alt=""
          className="discover-menu-grid--play-button"
        />
      </div>
    </div>
  );
};
