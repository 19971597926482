import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { Fragment, useEffect, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DateCalendar,
  PickersCalendarHeaderProps,
} from '@mui/x-date-pickers/DateCalendar';
import { signal } from '@preact/signals-core';
import dayjs, { Dayjs } from 'dayjs';
import { commonButtonsSx } from './MapPreview/BottomSheetp2p';
import { useStyles } from '../TravelAssistant/DatePickerComponent/styles';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';
import { useNavigate } from 'react-router-dom';
import TimePickerSlider from './TimePickerSlider';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';

export const selectedCitySignal = signal<string>('');

export const textFieldSx = {
  //    width: '100%' and add shadow around the input place holder should be bolder
  marginTop: '10px',
  boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)',
  outline: 'none',
  borderRadius: '10px',
  backgroundColor: 'white',
  border: 'none',
  '& .MuiInputBase-input': {
    fontWeight: 600,
    textAlign: 'center',

    fontSize: 'min(20px, 6vw)',
    borderRadius: '10px',
    outline: 'none',
    border: 'none',
  },
  // placeholder
  '& .MuiInputBase-input::placeholder': {
    fontWeight: 600,
    textAlign: 'center',

    fontSize: 'min(20px, 6vw)',
  },
  '& fieldset': { border: 'none' },
};

const WhereAndWhen = ({
  setDateAndCity,
}: {
  setDateAndCity: ({
    city,
    date,
    endDate,
    point,
    time,
    contextdata,
  }: {
    city: string;
    date: string;
    endDate?: string;
    point: string;
    time: string;
    contextdata: { key: string; value: string }[];
  }) => void;
}) => {
  const [state, setState] = useState<'where' | 'when' | 'context'>('where');
  const [contextdata, setContextData] = useState<
    { key: string; value: string }[]
  >([]);
  const [tempCity, setTempCity] = useState<string>('');
  const [tempDate, setTempDate] = useState<Date>(new Date());
  const [tempEndDate, setTempEndDate] = useState<Date>();
  const [tempPoint, setTempPoint] = useState<string>('');
  const [beginYourTripModalOpen, setBeginYourTripModalOpen] =
    useState<boolean>(false);
  const [timeModalOpen, setTimeModalOpen] = useState<boolean>(false);
  const [timeStart, setTimeStart] = useState<string>('8:00 am');
  const isMobile = useIsMobile();

  const setTempCityAction = (city: string) => {
    setTempCity(city);
    setState('when');
  };

  const setTempDateAction = (date: Date, endDate?: Date) => {
    setTempDate(date);
    setState('context');
    setTempEndDate(endDate);
    // setBeginYourTripModalOpen(true)
  };

  const submit = (tempPoint: string) => {
    setDateAndCity({
      city: tempCity,
      date: tempDate.toISOString(),
      endDate: tempEndDate?.toISOString() || '',
      point: tempPoint,
      time: timeStart,
      contextdata,
    });
  };

  const cancel = () => {
    // setTempCity('')
    setTempDate(new Date());
    setBeginYourTripModalOpen(false);
  };

  return (
    <>
      <CommonHeader setState={setState} />
      <div style={isMobile ? {} : { width: '30%', margin: '0 auto' }}>
        {state === 'where' ? (
          <Where setCity={setTempCityAction} />
        ) : state === 'when' ? (
          <When setDate={setTempDateAction} />
        ) : state === 'context' ? (
          <ContextForm
            setContextData={setContextData}
            contextdata={contextdata}
            setBeginYourTripModalOpen={setBeginYourTripModalOpen}
          />
        ) : null}
        <Modal
          sx={{
            zIndex: 10000,
          }}
          open={timeModalOpen}
          onClose={() => setTimeModalOpen(false)}
        >
          {/* <TimePicker onChange={(time: any) => { setTimeStart(`${time.hour}:${time.minute} ${time.period}`) }} /> */}
          <TimePickerSlider
            onChange={(time: any) => {
              setTimeModalOpen(false);
              setTimeStart(time);
            }}
          />
        </Modal>
        <Modal
          open={beginYourTripModalOpen}
          onClose={() => {}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <BeginYourTripCard
            data={{
              city: tempCity,
              date: tempDate.toISOString(),
            }}
            setTempPoint={setTempPoint}
            submit={submit}
            cancel={cancel}
            setTimeModalOpen={setTimeModalOpen}
            timeStart={timeStart}
          />
        </Modal>
      </div>
    </>
  );
};

type Location = {
  lat: number;
  lng: number;
};

const BeginYourTripCard = ({
  data,
  setTempPoint,
  submit,
  cancel,
  timeStart,
  setTimeModalOpen,
}: {
  data: { city: string; date: any };
  setTempPoint: any;
  submit: (tempoint: string) => void;
  cancel: () => void;
  timeStart: any;
  setTimeModalOpen: (val: boolean) => void;
}) => {
  const [text, setText] = useState<any>(data.city);
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [userLocation, setUserLocation] = useState<Location | null>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isMobile = useIsMobile();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSearchResults([]);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEdit = () => {
    setText('');
    setEdit(true);
    inputRef.current?.focus();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '32px',
        left: '24px',
        right: '24px',
        padding: 'min(24px, 5vw)',
        background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%)',
        borderRadius: '20px',
        boxShadow:
          '0px 1.3px 2.21px 0px #07081705 , 0px 3.13px 5.32px 0px #07081707 , 0px 5.89px 10.02px 0px #07081709 , 0px 10.5px 17.87px 0px #0708170B , 0px 19.64px 33.42px 0px #0708170D , 0px 47px 80px 0px #07081712',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        ...(!isMobile && { width: '400px', margin: '140px auto 0' }),
      }}
    >
      <h4
        style={{
          fontSize: 'min(24px, 6vw)',
        }}
      >
        <b>Begin Your Trip in</b>
      </h4>
      <h5
        style={{
          fontSize: 'min(18px, 5vw)',
        }}
      >
        <b style={{ color: '#F9682E' }}>{data.city}</b>
        <b> on </b>
        <b style={{ color: '#F9682E' }}>
          {dayjs(data.date).format('MMM DD, YYYY')}
        </b>
      </h5>

      <h4
        style={{
          fontSize: 'min(22px, 6vw)',
          marginTop: 'min(20px, 2vh)',
          marginBottom: 'min(20px, 2vh)',
        }}
      >
        <b style={{ whiteSpace: 'nowrap' }}>Start from this Point :</b>
      </h4>

      <div className="d-flex">
        <TextField
          fullWidth
          ref={inputRef}
          inputProps={{
            style: {
              padding: '10px 0px 10px 10px',
              color: edit ? 'black' : 'gray',
            },
          }}
          sx={{
            ...textFieldSx,
            boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)',
          }}
          // disabled={!edit}
          value={text}
          {...(!edit
            ? { onClick: handleEdit }
            : {
                onChange: (e) => {
                  setText(e.target.value);
                },
              })}
          placeholder="Enter Place Name"
        />

        <Button
          style={{
            textTransform: 'lowercase',
            fontWeight: 600,
            borderRadius: '18px',
            marginLeft: 'min(15px, 3vw)',
            boxShadow:
              '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
            backgroundColor: edit ? '#F9682E' : '#FFFFFF',
            color: edit ? 'white' : '#F9682E',
          }}
          sx={textFieldSx}
          onClick={() => {
            setEdit(!edit);
            inputRef.current?.focus();
          }}
        >
          {edit ? 'done' : 'edit'}
        </Button>
      </div>

      <h3
        style={{
          fontSize: 'min(22px, 6vw)',
          marginTop: 'min(20px, 2vh)',
          marginBottom: 'min(20px, 2vh)',
        }}
      >
        <b>At this Time?</b>
      </h3>

      <div className="d-flex">
        <TextField
          fullWidth
          inputProps={{
            style: {
              padding: '10px 0px 10px 10px',
              color: edit ? 'black' : 'gray',
            },
          }}
          sx={{
            ...textFieldSx,
            boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)',
          }}
          disabled={edit}
          value={timeStart}
          onClick={() => {
            setTimeModalOpen(true);
          }}
          placeholder="8:00 am"
        />

        <Button
          disabled={edit}
          style={{
            textTransform: 'lowercase',
            fontWeight: 600,
            borderRadius: '18px',
            marginLeft: 'min(15px, 3vw)',
            boxShadow:
              '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
            backgroundColor: '#FFFFFF',
            color: '#F9682E',
          }}
          sx={textFieldSx}
          onClick={() => {
            setTimeModalOpen(true);
          }}
        >
          {'edit'}
        </Button>
      </div>

      {!edit ? (
        <div
          className="d-flex gap-2 justify-content-center align-items-center"
          style={{
            flexDirection: window.innerHeight < 420 ? 'row' : 'column',
          }}
        >
          <Button
            style={{
              background: '#F9682E',
              boxShadow:
                '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
              borderRadius: '18px',
              textTransform: 'none',
              fontWeight: 600,
              color: 'white',
              width: '40%',
              marginTop: 'min(20px, 5vh)',
              padding: 'min(16px, 4vw) min(8px, 2vw)',
            }}
            onClick={() => {
              submit(text);
            }}
            disabled={!text || text.length < 1}
          >
            Yes
          </Button>
          <Button
            style={{
              background: '#FFFFFF',
              boxShadow:
                '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
              borderRadius: '18px',
              textTransform: 'none',
              fontWeight: 600,
              color: '#F9682E',
              width: '40%',
              marginTop: 'min(20px, 5vh)',
              padding: 'min(16px, 4vw) min(8px, 2vw)',
            }}
            onClick={cancel}
          >
            No
          </Button>
        </div>
      ) : (
        <h6 className="mt-3 " style={{ fontSize: '12px' }}>
          <b>
            TIP: Use your home address, nearest airport, or any other location
            as your starting point.
          </b>
        </h6>
      )}
    </Box>
  );
};

const Where = ({ setCity }: { setCity: (city: string) => void }) => {
  const [city, setCityLocal] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const isMobile = useIsMobile();

  return (
    <div className="d-flex justify-content-between align-items-center p-3  flex-column">
      <h3
        style={{
          fontSize: 'min(24px, 8vw)',
        }}
      >
        <b>Where to?</b>
      </h3>
      <TextField
        fullWidth
        inputProps={{
          style: {
            padding: '10px 0px 10px 10px',
          },
        }}
        sx={textFieldSx}
        autoFocus
        placeholder="Enter City Name"
        value={city}
        onChange={(e) => setCityLocal(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setCity(city);
          }
        }}
      />

      {city.length > 1 && (
        <Button
          sx={commonButtonsSx}
          style={{ width: 'min(162px,50vw)' }}
          disabled={city.length < 1}
          onClick={() => {
            setCity(city);
          }}
        >
          Next
        </Button>
      )}
      {/* 
      <h3 className="mt-5"><b>Top Searched Cities today</b></h3>

      {['New York', 'Paris', 'Kuala Lampur'].map((city, idx) => {
        return <Button key={city + idx} sx={commonButtonsSx}
          style={{ boxShadow: 'none', color: 'silver', background: 'white' }}
          onClick={() => { setCity(city); setCityLocal(city) }}>{city}</Button>
      })} */}

      <h3
        className="mt-5"
        style={{ color: '#F9682E', fontSize: 'min(22px,7vw)' }}
      >
        <b>This is a new Feature -</b>
      </h3>
      <h4 style={{ fontSize: 'min(18px,6vw)' }}>
        <b>Some errors may occur. We are working daily to fix them.</b>
      </h4>
    </div>
  );
};

const CustomCalendarHeader = (props: PickersCalendarHeaderProps<Dayjs>) => {
  const classes = useStyles();
  const { currentMonth, onMonthChange, minDate } = props;

  const selectNextMonth = () =>
    onMonthChange(currentMonth.add(1, 'month').endOf('month'), 'left');

  const selectPreviousMonth = () => {
    if (currentMonth.isAfter(minDate, 'month')) {
      onMonthChange(currentMonth.subtract(1, 'month').endOf('month'), 'right');
    }
  };

  return (
    <div className={classes.calendarHeaderRoot}>
      <Stack spacing={1} direction="row">
        <IconButton
          onClick={selectPreviousMonth}
          title="Previous month"
          className={classes.selectionIcon}
        >
          <ChevronLeft />
        </IconButton>
      </Stack>
      <Typography variant="body2" className={classes.currentMonthText}>
        {currentMonth.format('MMMM YYYY')}
      </Typography>
      <Stack spacing={1} direction="row">
        <IconButton
          onClick={selectNextMonth}
          title="Next month"
          className={classes.selectionIcon}
        >
          <ChevronRight />
        </IconButton>
      </Stack>
    </div>
  );
};

interface CustomPickersDayProps extends PickersDayProps<Dayjs> {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

const CustomPickersDay: React.FC<CustomPickersDayProps> = (props) => {
  const { day, selected, startDate, endDate, ...other } = props;
  const classes = useStyles();

  const isInRange =
    startDate &&
    endDate &&
    day.isAfter(startDate.subtract(1, 'day')) &&
    day.isBefore(endDate.add(1, 'day'));
  const isFirstDay = startDate && day.isSame(startDate, 'day');
  const isLastDay = endDate && day.isSame(endDate, 'day');

  let dayClassName = '';
  if (isInRange) {
    if (isFirstDay) {
      dayClassName = `${classes.highlightedDay} ${classes.firstDay}`;
    } else if (isLastDay) {
      dayClassName = `${classes.highlightedDay} ${classes.lastDay}`;
    } else {
      dayClassName = `${classes.highlightedDay}`;
    }
  } else if (selected) {
    dayClassName = `${classes.highlightedDay} ${classes.singleDay}`;
  }

  return (
    <PickersDay
      {...other}
      day={day}
      selected={isFirstDay || isLastDay || selected}
      className={`${classes.day} ${dayClassName}`}
    />
  );
};

const When = ({
  setDate,
}: {
  setDate: (startdate: Date, endDate?: Date) => void;
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const handleDateChange = (date: Dayjs | null) => {
    if (!startDate) {
      setStartDate(date);
      setEndDate(null);
    } else if (!endDate && date && date.isAfter(startDate)) {
      setEndDate(date);
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  return (
    <div
      className="d-flex justify-content-between align-items-center p-3 flex-column"
      style={{ ...(!isMobile && { marginTop: '60px' }) }}
    >
      <h3 style={{ fontSize: 'min(24px, 8vw)' }}>
        <b>When?</b>
      </h3>
      <span
        style={{
          zoom:
            window.innerWidth < 300 ? 0.6 : window.innerWidth < 400 ? 0.8 : 1,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            slots={{
              calendarHeader: CustomCalendarHeader,
              day: (dayProps) => (
                <CustomPickersDay
                  {...dayProps}
                  startDate={startDate}
                  endDate={endDate}
                />
              ),
            }}
            dayOfWeekFormatter={(day, date: any) =>
              new Intl.DateTimeFormat('en', { weekday: 'short' }).format(date)
            }
            sx={{
              '.MuiPickersDay-today': {
                outline: 'none',
                border: 'none',
              },
            }}
            className={`${classes.calendar} ${classes.weekdayLabel}`}
            slotProps={{
              leftArrowIcon: {
                style: { fill: '#F9682E' },
              },
              rightArrowIcon: {
                style: { fill: '#F9682E' },
              },
            }}
            onChange={handleDateChange}
            showDaysOutsideCurrentMonth
            minDate={dayjs()}
            // maxDate={dayjs().add(7, 'day')}
          />
        </LocalizationProvider>
      </span>
      {/* <div className="text-center " style={{ height: 'min(100px,10vh)', marginTop: 'min(20px,2vh)' }}>

        {startDate && <h3
          style={{ fontSize: 'min(22px, 6vw)' }}
        >
          Start Date : <b
            style={{ color: '#F9682E' }}
          > {startDate?.format('MMM DD, YYYY')}  </b>
        </h3>}

        {
          endDate && <h3
            style={{ fontSize: 'min(22px, 6vw)' }}
          >
            End Date : <b
              style={{ color: '#F9682E' }}>
              {endDate?.format('MMM DD, YYYY')}</b>
          </h3>
        )}

      </div> */}

      {startDate && (
        <Button
          sx={commonButtonsSx}
          style={{ width: 'min(162px,50vw)' }}
          onClick={() => {
            setDate(startDate.toDate(), endDate?.toDate());
          }}
        >
          Next
        </Button>
      )}
    </div>
  );
};

const CommonHeader = ({ setState }: { setState: any }) => {
  const navigate = useNavigate();
  return (
    <div
      className="d-flex justify-content-between align-items-center p-3"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 'min(12px, 3.5vw) min(20px, 6vw)',
        height: 'min(80px, 12vh)',
        background: '#ffffff',
        maxWidth: 450,
        width: '100%',
        alignSelf: 'center',
        margin: '0 auto',
      }}
    >
      <div
        onClick={() => {
          setState((prev: string) => {
            if (prev === 'context') {
              return 'when';
            }
            if (prev === 'when') {
              return 'where';
            }
            try {
              navigate(-1);
            } catch (e) {
              navigate('/home');
            }
            return prev;
          });
        }}
        style={{
          pointerEvents: 'all',
          width: '33%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
          }}
        >
          <img
            src="./goBack.png"
            alt="Back Button"
            style={{
              width: 'min(50px, 15vw)',
              height: 'min(35px, 11vw)',
            }}
          />
        </div>
      </div>
      <div
        onClick={() => {
          // navigate('/home');
        }}
        style={{ cursor: 'pointer', width: '33%' }}
      >
        <img
          src="./orangeLogoVizualTravel.svg"
          alt="Logo"
          width={123.56}
          height={26}
        />
      </div>
      {/*  */}
      <div style={{ display: 'flex', width: '33%' }} />
      {/* @Todo comment for now. Maybe we need in the future */}
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          className="meter"
          style={{
            width: '40px',
            height: '10px',
            marginTop: '0px',
            background: '#000',
          }}
        >
          <span style={{ width: `${25 * currentStep.peek()}%` }}></span>
        </div>
        <span
          style={{
            color: '#9CA3AF',
            marginLeft: '6px',
            fontWeight: '600',
            fontFamily: 'Poppins, Arial, sans-serif',
          }}
        >
          {`${currentStep.peek()} of 4`}
        </span>
      </div> */}
    </div>
  );
};

export default WhereAndWhen;

const ContextForm = ({
  setContextData,
  contextdata,
  setBeginYourTripModalOpen,
}: {
  setContextData: any;
  contextdata: { key: string; value: string }[];
  setBeginYourTripModalOpen: (val: boolean) => void;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('');

  const contextOptions = [
    {
      group: 'Budget',
      keys: [
        { key: 'cheap', value: 'Budget : Cheap' },
        { key: 'affordable', value: 'Budget : Affordable' },
        { key: 'luxury', value: 'Budget : Luxury' },
      ],
    },
    {
      group: 'Travel Type',
      keys: [
        { key: 'solo', value: 'I am traveling solo' },
        { key: 'couple', value: 'I am traveling as a couple' },
        { key: 'with kids', value: 'I am traveling with kids' },
      ],
    },
    {
      group: 'Travel Experience',
      keys: [{ key: 'foodie', value: 'Food places are very important to me' }],
    },
    {
      group: 'Travel Style',
      keys: [
        { key: 'relax', value: 'I want to relax and unwind' },
        { key: 'explore', value: 'I want to explore the city' },
        { key: 'love walking', value: 'I love walking and exploring' },
      ],
    },
    {
      group: 'Travel Interests',
      keys: [
        { key: 'popular places', value: 'I want to visit popular places' },
        { key: 'hidden gems', value: 'I want to explore hidden gems' },
      ],
    },
  ];

  const handleDrawerOpen = (type: string) => {
    setDrawerType(type);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setDrawerType('');
  };

  const handleAppendorRemoveContextData = (option: {
    key: string;
    value: string;
  }) => {
    const isSelected = contextdata.some((item) => item.key === option.key);
    if (isSelected) {
      setContextData(contextdata.filter((item) => item.key !== option.key));
    } else {
      // Remove any selected option from the same group
      const updatedData = contextOptions.reduce((acc, group) => {
        if (group.keys.some((key) => key.key === option.key)) {
          return acc.filter(
            (item) => !group.keys.map((key) => key.key).includes(item.key),
          );
        }
        return acc;
      }, contextdata);

      setContextData([...updatedData, option]);
    }
  };

  const chipStyle = {
    padding: '8px 14px',
    background: 'white',
    margin: '5px',
    fontSize: 'min(16px, 6vw)',
    height: 'min(40px, 40px)',
  };

  const renderChipGroupByOption = (optionGroup: any) => {
    const groupSelected = optionGroup.keys.find((key: any) =>
      contextdata.some((item) => item.key === key.key),
    );

    return optionGroup.keys.map((key: any) => {
      const isSelected = contextdata.some((item) => item.key === key.key);
      return !groupSelected || isSelected ? (
        <Button
          key={key.key}
          sx={commonButtonsSx}
          style={{
            ...chipStyle,
            color: isSelected ? 'green' : '#FF8447',
          }}
          onClick={() => handleAppendorRemoveContextData(key)}
        >
          {key.key}
        </Button>
      ) : (
        <Button
          key={key.key}
          sx={commonButtonsSx}
          style={chipStyle}
          disabled
          onClick={() => handleAppendorRemoveContextData(key)}
        >
          {key.key}
        </Button>
      );
    });
  };

  return (
    <div className="d-flex flex-column text-center">
      <h3
        style={{
          fontSize: 'min(22px, 6vw)',
        }}
      >
        <b>Give us a bit more context</b>
      </h3>

      <div
        className="p-3 flex-row"
        style={{
          flexWrap: 'wrap',
          overflowY: 'auto',
          textAlign: 'justify',
          flex: 1,
          flexGrow: 1,
        }}
      >
        {contextOptions.map((optionGroup) =>
          renderChipGroupByOption(optionGroup),
        )}
        <Button
          sx={commonButtonsSx}
          style={{
            ...chipStyle,
            color: contextdata.some((item) => item.key === 'Travel Notes')
              ? 'green'
              : '#FF8447',
          }}
          onClick={() =>
            contextdata.some((item) => item.key === 'Travel Notes')
              ? handleAppendorRemoveContextData({
                  key: 'Travel Notes',
                  value: '',
                })
              : handleDrawerOpen('Travel Notes')
          }
        >
          Travel Notes
        </Button>
        <Button
          sx={commonButtonsSx}
          style={{
            ...chipStyle,
            color: contextdata.some((item) => item.key === 'Itinerary')
              ? 'green'
              : '#FF8447',
          }}
          onClick={() =>
            contextdata.some((item) => item.key === 'Itinerary')
              ? handleAppendorRemoveContextData({ key: 'Itinerary', value: '' })
              : handleDrawerOpen('Itinerary')
          }
        >
          Copy Paste your Itinerary so far
        </Button>
      </div>

      {/* Skip and Next Buttons */}
      <div className="d-flex justify-content-center" style={{ margin: 'auto' }}>
        {contextdata.length > 0 ? (
          <Button
            onClick={() => setBeginYourTripModalOpen(true)}
            sx={{ ...commonButtonsSx, width: 'min(162px,50vw)' }}
          >
            Next
          </Button>
        ) : (
          <Button
            sx={{ textTransform: 'none', fontSize: '16px', fontWeight: 'bold' }}
            onClick={() => setBeginYourTripModalOpen(true)}
          >
            Skip
          </Button>
        )}
      </div>

      {/* Drawer for Paste Options */}
      <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerClose}>
        <div className="p-3 d-flex flex-column justify-content-center align-items-center">
          <TextField
            id="outlined-multiline-static-in-drawer-for-context"
            sx={textFieldSx}
            autoFocus
            fullWidth
            multiline
            rows={4}
            placeholder={`Paste your ${drawerType}`}
            variant="outlined"
          />
          <Button
            sx={{ ...commonButtonsSx, width: 'min(162px,50vw)' }}
            className="mt-3 mb-4"
            onClick={() => {
              const inp = document.getElementById(
                'outlined-multiline-static-in-drawer-for-context',
              ) as any;
              handleAppendorRemoveContextData({
                key: drawerType,
                value: inp?.value || '',
              });
              handleDrawerClose();
            }}
          >
            Done
          </Button>
        </div>
      </Drawer>
    </div>
  );
};
