import React from 'react';
import { Typography } from '@mui/material';
import useStyles from '../styles';

export interface IAudioFile {
  id: string;
  name: string;
  url: string;
}

interface LogoLibraryProps {
  file: IAudioFile;
  chooseFromLibrary: ({ url }: { url: string }) => void;
}

const LogoLibrary: React.FC<LogoLibraryProps> = ({
  file,
  chooseFromLibrary,
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        listStyle: 'none',
      }}
      className={classes.libraryList}
    >
      <img
        className={classes.logo}
        onClick={() => chooseFromLibrary({ url: file.url })}
        alt={file.name}
        src={file.url}
        height={60}
        width={60}
        style={{
          position: 'relative',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)', // Added shadow
          borderRadius: '.3rem',
          objectFit: 'contain',
        }}
      />
    </div>
  );
};

export default LogoLibrary;
