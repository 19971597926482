import React from 'react';
import Button from '@mui/material/Button';

interface P2PGoBackProps {
  onBackClick: () => void;
}

const P2PGoBack: React.FC<P2PGoBackProps> = ({ onBackClick }) => {
  return (
    <Button
      sx={{
        minWidth: '80px',
        cursor: 'pointer',
        padding: '6px 15px',
        gap: '6px',
        borderRadius: '16px',
        background:
          'radial-gradient(100% 100% at 0% 0%, #ff9345 0.01%, #fe7138 50%, #f25d21 100%)',
        boxShadow:
          '0px 2px 6px 0px #b8c5d033, 0px 6px 22px 0px #b8c5d04d, 0px 9px 30px 0px #c6d8e933, 0px 1.5px 1.5px 0px #ffffff40 inset',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '31.5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: '#ffffff',
        textTransform: 'capitalize',
        width: '144px',
        height: '64px',
        position: 'relative',
        zIndex: 2,
      }}
      onClick={onBackClick}
    >
      Go Back
    </Button>
  );
};

export default P2PGoBack;
