import { currentTravelTypeSignal, handleAnimationState } from "~/components/ViewTravel/common";
import { LandTransportController } from './LandTransportController';

/**
 * This class controls the animation of a car along a travel path on a map.
 * It inherits from the `AnimationController` class and provides car-specific functionalities.
 */
class FerryAnimationController extends LandTransportController {
  /**
   * Starts the ferry animation along the travel path.
   * @remarks
   * This function overrides the base class's `startAnimation` method.
   * It performs ferry-specific setup tasks in addition to the common
   * animation setup.
   */
  startAnimation() {
    currentTravelTypeSignal.value = "ferry";
    this.initializeModelAnimation();
  }

  /**
   * This function is called when Ferry Transport move along the path and call update() function of its parent class
   *
   * @param delta The elapsed time since the last animation frame (in seconds)
   */
  update(delta: number) {
    super.update(delta);
  }

  /**
   * This function is called when animation ends and call another function setAnimationExpired()
   */
  onAnimationEnded() {
    this.setAnimationExpired();
  }

  /**
   * This function sets the isAnimationExpired flag to true and call another function onAnimationCompleteCallback()
   */
  setAnimationExpired = () => {
    this.isAnimationExpired = true;
    this.onAnimationCompleteCallback();
  };
}

export { FerryAnimationController };
