import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import dayjs from 'dayjs';
import { useNavigate, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import useTitleState from './useTitleState';
import { isAuthenticated } from '../MobileFooter/BottomSheet';
import { initialItineraryRender, restartDaySignal } from '~/map/ViewTravel';
import { isOverlayVisible, isShareModalVisible } from '../StatsOverlay';
import { vizualizeButtonSignal } from '../MobileFooter/DaysHeader/DaysHeader';
import { showDrawerMenu } from '~/components/DrawerMenu';
import { showOverviewMenu } from '~/components/OverviewMenu';
import HeaderText from './HeaderText';

import {
  animationIsPlaying,
  currentTravelTypeSignal,
  durationString,
  endStateSignal,
  getActionBySelectedTransport,
  isVizualizingSignal,
  PauseAnimation,
  showMainHeader,
  showPlayPauseButton,
} from '../common';
import {
  PublishableTravelData,
  TravelOptions,
  TravelPointsByDayIndex,
} from '~/utility/models';

import { useIsMobile } from '../counter/hooks/useMobile';
import { handleShare } from '~/utility/shareLink';
import ActionsCreator from '~/redux/actions';
import { store, useDispatch } from '~/redux/store';
import subscribeSignal from '~/hooks/subscribeSignal';
import { convertToDayHourMinute, getTravelPointsArray } from '~/utility/utils';
import { cityPlaces } from '~/components/DiscoverMenu/data/cityPlaces';
import { FilterType } from '~/components/DiscoverMenu/DiscoverMap/DiscoverMap';
import {
  convertContentDetailsToPointTodo,
  IContentDetails,
  ITodoTravelPoint,
} from '~/components/AddToTrip';
import { todosSignal } from '~/components/signals/thingsToDoSignal';
import ROUTES from '~/routes';

import {
  fireResetTrip,
  fireShowSignupDialog,
  selectBottomSheetOverviewData,
  selectResetTrip,
} from '~/redux/reducers/ui.slice';
import './style.css';
import AccentText from './AccentText';
import { selectUserID } from '~/redux/selectors/mapSelectors';


type City = keyof typeof cityPlaces;

const allowedCities: City[] = ['tokyo'];

const MobileHeader = React.memo(
  ({ travelPoints }: { travelPoints: PublishableTravelData[] }) => {
    const location = useLocation();
    const [isAnimationStarted, setIsAnimationStarted] = useState(false);
    const [isOverlay, setIsOverlay] = useState(isOverlayVisible.peek());
    const duration = useRef('');
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const [showMainHeaderValue, setShowMainHeaderValue] = useState(true);
    const [isVisualizing, setIsVisualizing] = useState(false);
    const [overviewMenuVisible, setOverviewMenuVisible] = useState(false);

    const travelRawData = useSelector(
      (state: RootState) => state.TripInfoReducers.travelRawData,
    );
    const userId = useSelector(selectUserID);
    const resetTrip = useSelector(selectResetTrip);
    const bottomSheetOverviewData = useSelector(selectBottomSheetOverviewData);
    const pointCity = useSelector(
      (state: RootState) => state.TripInfoReducers.travelRawData?.city,
    );
    const [filter, setFilter] = useState<FilterType[]>([]);

    subscribeSignal(isVizualizingSignal, setIsVisualizing);
    subscribeSignal(showMainHeader, setShowMainHeaderValue);
    subscribeSignal(showOverviewMenu, setOverviewMenuVisible);

    subscribeSignal(showPlayPauseButton, setIsAnimationStarted);
    subscribeSignal(isOverlayVisible, setIsOverlay);

    const { step, headerState, day } = useTitleState(travelPoints);

    const navigate = useNavigate();
    const currentPoint = travelPoints[step];
    const nextPoint = travelPoints[step + 1];

    const handleFilter = (filterValue: FilterType) => {
      const currentFilters = filter;

      if (!pointCity) return;

      const city: City = pointCity.city?.toLowerCase() as City;
      const places = cityPlaces[city];

      if (!places) return;

      const mapItems = (
        items: {
          name: string;
          price: number;
          review: number;
          category: string;
          url_book_place: string;
          summary: string;
          notes: string;
          media: {
            url: string;
            thumbnail: string;
            type: string;
          }[];
          coords: number[];
        }[],
      ) =>
        items
          .map((item) => {
            const todo = convertContentDetailsToPointTodo({
              ...item,
              country: pointCity.country,
            } as IContentDetails);
            return todo ? todo[0] : undefined; // Safely return the first item or undefined
          })
          .filter((todo): todo is ITodoTravelPoint => todo !== undefined); // Filter out undefined values

      const items =
        filterValue === 'activity'
          ? mapItems(places.activities)
          : mapItems(places.restaurants);

      const currentTodos = todosSignal.peek();

      let newTodos: ITodoTravelPoint[] = [];

      if (currentFilters?.includes(filterValue)) {
        newTodos = currentTodos.filter(
          (todo) => todo.todoCategory !== filterValue,
        );
      } else {
        newTodos = [...currentTodos, ...items];
      }

      todosSignal.value = newTodos;
      setFilter((prevFilter) => {
        const updatedFilter = prevFilter ?? []; // Default to an empty array if null
        const valueIndex = updatedFilter.indexOf(filterValue);

        if (valueIndex > -1) {
          // If the value exists, remove it
          return updatedFilter.filter((item) => item !== filterValue);
        } else {
          // If the value doesn't exist, add it
          return [...updatedFilter, filterValue];
        }
      });
    };

    // Helper function to reset travel data
    const resetTravelData = () => {
      dispatch(ActionsCreator.setPublishedTravelLink(''));
      dispatch(ActionsCreator.setCurrentHistoryId(-1));
      dispatch(ActionsCreator.setCurrentHistoryIdentifier(''));
      dispatch(ActionsCreator.setPulishedTravelId(0));
    };

    // Helper: Handle new travel data creation
    const handleNewTravelData = async (
      travelRawData: any,
      travelPoints: TravelOptions[],
      travelPointsByDayIndex: TravelPointsByDayIndex,
    ) => {
      resetTravelData();

      dispatch(
        ActionsCreator.setTravelRawData(
          JSON.stringify({ ...travelRawData, publishedId: 0 }),
        ),
      );

      await dispatch(ActionsCreator.saveP2PTravelPoint(travelPoints));
      await dispatch(
        ActionsCreator.createP2PTravelPointVisualization(
          travelPointsByDayIndex,
        ),
      );
    };
    const saveOrEditTrip = async (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      travelPointsByDayIndex: TravelPointsByDayIndex,
    ) => {
      e.stopPropagation();

      const travelPointsArrays: TravelOptions[] = getTravelPointsArray(
        travelPointsByDayIndex,
      );

      // Set common states
      dispatch(ActionsCreator.setTravelTitle(travelRawData?.title));
      dispatch(
        ActionsCreator.setCurrentDayIndex(
          travelPointsArrays[travelPointsArrays.length - 1]?.dayIndex || 0,
        ),
      );
      dispatch(
        ActionsCreator.setLastVisitedDayIndex(
          travelPointsArrays[0]?.dayIndex || 0,
        ),
      );

      const historyId = store.getState().P2PManualReducers.currentHistoryId;

      // Update states based on authentication
      if (isAuthenticated()) {
        dispatch(ActionsCreator.setHeaderState(true));
        dispatch(ActionsCreator.setMapButtonState(true));
        dispatch(ActionsCreator.setIsTravelPointSelectedState(true));
        dispatch(ActionsCreator.setCurrentHistoryId(historyId));
        initialItineraryRender.value = false;
        if (travelRawData?.userId !== userId) {
          resetTravelData();
          // dispatch(ActionsCreator.setCurrentHistoryId(-1));
          await handleNewTravelData(
            travelRawData,
            travelPointsArrays,
            travelPointsByDayIndex,
          );
          // add delay to allow the trip to be added in db
          setTimeout(() => {
            navigate(ROUTES.HOME.path);
          }, 1000);
        } else {
          isOverlayVisible.value = true;
          showOverviewMenu.value = true;
        }
      } else {
        resetTravelData();
        dispatch(ActionsCreator.setCurrentHistoryId(historyId));
        dispatch(fireShowSignupDialog(true));
      }

      PauseAnimation();
    };

    const staypointLabel = useRef<string>(
      travelPoints?.[0]?.departure.location?.label || '',
    );
    const isLastDayPoint = useRef(false);

    subscribeSignal(vizualizeButtonSignal, (v) => {
      if (v) {
        staypointLabel.current =
          travelPoints?.[v.startIndex || 0]?.departure?.location?.label || '';
      }
    });

    useEffect(() => {
      if (resetTrip || endStateSignal.peek() || restartDaySignal.peek()) {
        duration.current = '';
        staypointLabel.current =
          travelPoints?.[0]?.departure?.location?.label || '';
        isLastDayPoint.current = false;
        dispatch(fireResetTrip(false));
      }
    }, [resetTrip]);

    useEffect(() => {
      if (headerState === 'depart') {
        staypointLabel.current = currentPoint?.arrival?.location?.label || '';
        isLastDayPoint.current = !!nextPoint
          ? !(
              dayjs(String(currentPoint.departure.dateTime))
                .tz(currentPoint.departure.timezone)
                .format('DD-MM-YYYY') ===
              dayjs(String(nextPoint?.departure?.dateTime))
                .tz(nextPoint?.departure?.timezone)
                .format('DD-MM-YYYY')
            )
          : false;
        duration.current = durationString(
          dayjs(String(nextPoint?.departure?.dateTime)),
          dayjs(String(currentPoint?.arrival?.dateTime)),
        );
      }
    }, [headerState, currentPoint, nextPoint]);

    return (
      <AnimatePresence>
        {!bottomSheetOverviewData && (
          <motion.div
            exit={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -200 }}
            style={{
              position: 'fixed',
              top: 0,
              width: '100%',
              pointerEvents: 'none',
            }}
          >
            {isAnimationStarted && (
              <motion.div
                exit={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -100 }}
                transition={{ type: 'tween' }}
              >
                <div
                  className={`mobileHeader-container ${
                    headerState === 'showDay'
                      ? 'mobileHeader-dark-bg'
                      : 'mobileHeader-light-bg'
                  }`}
                >
                  {headerState === 'showDay' && (
                    <HeaderText color="white">
                      Your trip on <AccentText>{`Day ${day}`}</AccentText>
                    </HeaderText>
                  )}
                  {(headerState === 'firstPoint' ||
                    (headerState === 'stayPoint' && !duration.current)) && (
                    <HeaderText>{staypointLabel.current}</HeaderText>
                  )}
                  {headerState === 'depart' && (
                    <HeaderText>
                      Depart at{' '}
                      <AccentText>
                        {dayjs(currentPoint?.departure?.dateTime as string)
                          .tz(currentPoint?.departure?.timezone)
                          ?.format('hh:mma')}
                      </AccentText>
                    </HeaderText>
                  )}
                  {headerState === 'flyfor' ? (
                    <HeaderText>
                      {getActionBySelectedTransport(
                        currentTravelTypeSignal.peek(),
                      )}
                      {currentPoint?.selectedTransport === 'Transit' ? '' : ' '}

                      {durationString(
                        dayjs(String(currentPoint?.arrival?.dateTime)),
                        dayjs(String(currentPoint?.departure?.dateTime)),
                      ) && <Fragment></Fragment>}

                      {currentPoint?.selectedTransport !== 'Transit' && (
                        <AccentText>
                          {currentPoint?.duration
                            ? convertToDayHourMinute(currentPoint.duration)
                            : durationString(
                                dayjs(String(currentPoint?.arrival?.dateTime)),
                                dayjs(
                                  String(currentPoint?.departure?.dateTime),
                                ),
                              )}
                        </AccentText>
                      )}
                    </HeaderText>
                  ) : null}
                  {headerState === 'arrival' && (
                    <HeaderText>
                      Arrive at{' '}
                      <AccentText>
                        {dayjs(currentPoint?.arrival?.dateTime as string)
                          .tz(currentPoint?.arrival?.timezone)
                          ?.format('hh:mma')}
                      </AccentText>
                    </HeaderText>
                  )}

                  {!isLastDayPoint.current &&
                    headerState === 'stayPoint' &&
                    !!duration.current && (
                      <HeaderText>
                        Spend <AccentText>{duration.current}</AccentText>
                        {` at ${staypointLabel.current}`}
                      </HeaderText>
                    )}
                  {headerState === 'stayPoint' && isLastDayPoint.current && (
                    <HeaderText>{staypointLabel.current}</HeaderText>
                  )}
                </div>
              </motion.div>
            )}
            <AnimatePresence>
              <motion.div
                animate={{
                  opacity: isOverlay ? 1 : 0,
                  y: 0,
                  zIndex: 0,
                }}
                style={{
                  position: 'relative',
                  paddingLeft: window.screen.width < 768 ? '22px' : '30%',
                  paddingRight: window.screen.width < 768 ? '22px' : '30%',
                  top: 24,
                  display: showMainHeaderValue ? 'flex' : 'none',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  pointerEvents: 'none',
                }}
              >
                {isAnimationStarted ? (
                  <div
                    className="mobile-header-go-back-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(fireResetTrip(true));
                      restartDaySignal.value = true;
                      isOverlayVisible.value = true;
                      isVizualizingSignal.value = false;
                    }}
                  >
                    <img
                      src="./goBackNew.png"
                      width={40}
                      height={33}
                      alt="go-back"
                    />
                  </div>
                ) : (
                  <div
                    className={'mobile-header-burger-menu'}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (location.pathname === ROUTES.VIEWTRAVEL.path) {
                        navigate(ROUTES.HOME.path);
                        showOverviewMenu.value = false;
                        return;
                      }
                      isOverlayVisible.value = true;
                      showDrawerMenu.value = true;
                    }}
                    style={{ pointerEvents: 'all' }}
                  >
                    {location.pathname === ROUTES.VIEWTRAVEL.path ? (
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.25 7.5V18.75H6.5V13.75C6.5 12.3693 7.61929 11.25 9 11.25C10.3807 11.25 11.5 12.3693 11.5 13.75V18.75H17.75V7.5L9 0L0.25 7.5Z"
                          fill="black"
                        />
                      </svg>
                    ) : (
                      <img
                        src="./icons/burger.png"
                        width={20}
                        height={14}
                        alt="menu"
                      />
                    )}
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  {!isVisualizing && travelRawData?.identifier && (
                    <Fragment>
                      {!overviewMenuVisible && (
                        <div
                          className="custom-badge pointer overview-btn mb-2"
                          onClick={async (e) =>
                            saveOrEditTrip(
                              e,
                              store.getState().P2PManualReducers
                                .travelPointsByDayIndex,
                            )
                          }
                          style={{ pointerEvents: 'all' }}
                        >
                          <svg
                            width="21"
                            height="21"
                            style={{
                              marginRight: '3px',
                            }}
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.64844 5.98828C7.08844 5.98828 6.64844 6.43828 6.64844 6.98828C6.64844 7.53828 7.09844 7.98828 7.64844 7.98828C8.19844 7.98828 8.6484 7.53828 8.6484 6.98828C8.6484 6.43828 8.19844 5.98828 7.64844 5.98828Z"
                              fill="#FF8447"
                            />
                            <path
                              d="M19.96 3.54C19.12 1.59 17.27 0.5 14.69 0.5H6.31C3.1 0.5 0.5 3.1 0.5 6.31V14.69C0.5 17.27 1.59 19.12 3.54 19.96C3.73 20.04 3.95 19.99 4.09 19.85L19.85 4.09C20 3.94 20.05 3.72 19.96 3.54ZM9.03 10.74C8.64 11.12 8.13 11.3 7.62 11.3C7.11 11.3 6.6 11.11 6.21 10.74C5.19 9.78 4.07 8.25 4.5 6.43C4.88 4.78 6.34 4.04 7.62 4.04C8.9 4.04 10.36 4.78 10.74 6.44C11.16 8.25 10.04 9.78 9.03 10.74Z"
                              fill="#FF8447"
                            />
                            <path
                              d="M17.9689 19.0295C18.1889 19.2495 18.1589 19.6095 17.8889 19.7595C17.0089 20.2495 15.9389 20.4995 14.6889 20.4995H6.30892C6.01892 20.4995 5.89892 20.1595 6.09892 19.9595L12.1389 13.9195C12.3389 13.7195 12.6489 13.7195 12.8489 13.9195L17.9689 19.0295Z"
                              fill="#FF8447"
                            />
                            <path
                              d="M20.5017 6.30892V14.6889C20.5017 15.9389 20.2517 17.0189 19.7617 17.8889C19.6117 18.1589 19.2517 18.1789 19.0317 17.9689L13.9117 12.8489C13.7117 12.6489 13.7117 12.3389 13.9117 12.1389L19.9517 6.09892C20.1617 5.89892 20.5017 6.01892 20.5017 6.30892Z"
                              fill="#FF8447"
                            />
                          </svg>

                          <span>Itinerary</span>
                        </div>
                      )}
                    </Fragment>
                  )}
                  <div
                    className={`custom-badge ${isMobile && 'mobile'} ${
                      isAnimationStarted && 'active'
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      isOverlayVisible.value = true;
                      animationIsPlaying.value && PauseAnimation();
                      // @ts-ignore - mozilla doesn't have this prop
                      if (isMobile && navigator.canShare) {
                        handleShare();
                      } else {
                        isOverlayVisible.value = true;
                        isShareModalVisible.value = true;
                      }
                    }}
                    style={{ pointerEvents: 'all' }}
                  >
                    <svg
                      style={{
                        marginRight: '3px',
                      }}
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.7587 0.5H8C8.55229 0.5 9 0.947717 9 1.5C9 2.05229 8.55229 2.5 8 2.5H5.8C4.94342 2.5 4.36113 2.50078 3.91104 2.53755C3.47262 2.57337 3.24842 2.6383 3.09202 2.71799C2.7157 2.90973 2.40973 3.2157 2.21799 3.59202C2.1383 3.74842 2.07337 3.97262 2.03755 4.41104C2.00078 4.86113 2 5.44342 2 6.3V14.7C2 15.5566 2.00078 16.1389 2.03755 16.589C2.07337 17.0274 2.1383 17.2516 2.21799 17.408C2.40973 17.7843 2.7157 18.0903 3.09202 18.282C3.24842 18.3617 3.47262 18.4266 3.91104 18.4624C4.36113 18.4992 4.94342 18.5 5.8 18.5H14.2C15.0566 18.5 15.6389 18.4992 16.089 18.4624C16.5274 18.4266 16.7516 18.3617 16.908 18.282C17.2843 18.0903 17.5903 17.7843 17.782 17.408C17.8617 17.2516 17.9266 17.0274 17.9624 16.589C17.9992 16.1389 18 15.5566 18 14.7V12.5C18 11.9477 18.4477 11.5 19 11.5C19.5523 11.5 20 11.9477 20 12.5V14.7413C20 15.5463 20 16.2106 19.9558 16.7518C19.9099 17.3139 19.8113 17.8306 19.564 18.316C19.1805 19.0686 18.5686 19.6805 17.816 20.064C17.3306 20.3113 16.8139 20.4099 16.2518 20.4558C15.7106 20.5 15.0463 20.5 14.2413 20.5H5.75868C4.95372 20.5 4.28936 20.5 3.74818 20.4558C3.18608 20.4099 2.66937 20.3113 2.18404 20.064C1.43139 19.6805 0.819469 19.0686 0.435975 18.316C0.188684 17.8306 0.0901201 17.3139 0.0441949 16.7518C-2.21655e-05 16.2106 -1.19578e-05 15.5463 4.11943e-07 14.7413V6.2587C-1.19578e-05 5.45374 -2.21655e-05 4.78937 0.0441949 4.24818C0.0901201 3.68608 0.188684 3.16937 0.435975 2.68404C0.819469 1.93139 1.43139 1.31947 2.18404 0.935975C2.66937 0.688684 3.18608 0.59012 3.74818 0.544195C4.28937 0.499978 4.95374 0.499988 5.7587 0.5Z"
                        fill="currentColor"
                      />
                      <path
                        d="M12 1.5C12 0.947716 12.4477 0.5 13 0.5H19C19.5523 0.5 20 0.947715 20 1.5L20 7.5C20 8.05228 19.5523 8.5 19 8.5C18.4477 8.5 18 8.05229 18 7.5L18 3.91422L10.7071 11.2071C10.3166 11.5976 9.68342 11.5976 9.29289 11.2071C8.90237 10.8166 8.90237 10.1834 9.29289 9.79289L16.5858 2.5H13C12.4477 2.5 12 2.05229 12 1.5Z"
                        fill="currentColor"
                      />
                    </svg>

                    {isAnimationStarted && isMobile ? (
                      <></>
                    ) : (
                      <span>Share</span>
                    )}
                  </div>

                  {pointCity &&
                    Object.keys(pointCity).length > 0 &&
                    pointCity?.city &&
                    allowedCities.includes(pointCity?.city?.toLowerCase()) && (
                      <>
                        {location.pathname === ROUTES.VIEWTRAVEL.path && (
                          <>
                            <div
                              className="activities-btns"
                              style={{
                                marginTop: '.5rem',
                                pointerEvents: 'all',
                              }}
                            >
                              <div
                                className={`custom-badge btn ${
                                  filter?.includes('activity')
                                    ? ''
                                    : 'not-active'
                                }`}
                                onClick={() => handleFilter('activity')}
                              >
                                <img
                                  className="discovery-map-img"
                                  src="/poa.svg"
                                  alt=""
                                />
                                <span style={{ color: '#4169e1' }}>Activity</span>
                              </div>
                              <div
                                style={{ color: '#ff901f' }}
                                className={`custom-badge btn ${
                                  filter?.includes('food') ? '' : 'not-active'
                                }`}
                                onClick={() => handleFilter('food')}
                              >
                                <img
                                  className="discovery-map-img"
                                  src="/restaurant.svg"
                                  alt=""
                                />
                                <span>Restaurant</span>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                </div>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    );
  },
  (prev, next) => prev.travelPoints === next.travelPoints,
);

export default MobileHeader;
