import { FC, useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import { TypeOfPointInterface, typesOfPoints } from '../helpers';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightBold,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 261,
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

interface P2PTypeOfPointModalProps {
  open: boolean;
  selectedPlaceType: TypeOfPointInterface | null;
  onClose: () => void;
  onSave: (type: TypeOfPointInterface) => void;
}

export const P2PTypeOfPointModal: FC<P2PTypeOfPointModalProps> = ({
  open,
  selectedPlaceType,
  onClose,
  onSave,
}) => {
  const handleSelect = (type: TypeOfPointInterface) => {
    onSave(type);
    onClose(); // Optionally close the dialog after selecting
  };

  const renderListItems = useMemo(() => {
    return Object.keys(typesOfPoints).map((k) => {
      const pointType = typesOfPoints[k];
      return (
        <ListItemButton
          key={k}
          sx={{ py: 0.2, px: 0 }}
          onClick={() => handleSelect(pointType)}
          aria-label={`Select ${pointType.label}`}
        >
          <ListItemIcon>
            <img
              src={pointType.icon}
              style={{ width: 40, height: 40 }}
              alt={pointType.label}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" sx={{ fontSize: '14px' }}>
                {pointType.label}
              </Typography>
            }
          />
        </ListItemButton>
      );
    });
  }, [typesOfPoints]);

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <StyledTypography
            variant="h5"
            sx={{
              color: '#ff8447',
              textAlign: 'center',
              lineHeight: '36px',
            }}
          >
            Select type of point :
          </StyledTypography>
          {selectedPlaceType && (
            <img
              src={selectedPlaceType.icon}
              style={{ width: 40, height: 40 }}
              alt={selectedPlaceType.label}
            />
          )}
        </Box>
      </DialogTitle>

      <DialogContent>
        <List>{renderListItems}</List>
      </DialogContent>
    </StyledDialog>
  );
};
