import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';
import { ITravelFormHistoryData } from '~/utility/models';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import HistoryItem from './HistoryItem';
import { Skeleton, Typography } from '@mui/material';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import { getP2PHistory } from '~/supabase/p2pHistory';
import { useLocation } from 'react-router-dom';

const aspectRatio = 269 / 214;

const P2PHistory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [travelHistory, setTravelHistory] = useState<ITravelFormHistoryData[]>(
    [],
  );

  const userID: string = useSelector(
    (state: RootState) => state.MapReducers.userID,
  );

  const isMobile = useIsMobile();

  /**
   * This useEffect is called when a component is rendered to fetch Travel History from DB
   *
   * @param {string} userID - The ID of the user for whom to fetch travel history.
   * @param {function} dispatch - The Redux dispatch function to update global state.
   */
  useEffect(() => {
    // Fetch Travel History Data from DB

    getP2PHistory(userID)
      .then((result) => {
        if (result.error) {
          // Handle the error if needed
          console.error('Error adding to travel history:', result.error);
        } else {
          // Store Travel History in Local State in this component
          setTravelHistory(result.data as ITravelFormHistoryData[]);

          // Disable Loader on after Data fetched from DB
          setLoading(false);

          // Store Travel History in Redux as Global State
          dispatch(
            ActionsCreator.saveTravelToHistory(
              result.data as ITravelFormHistoryData[],
            ),
          );
        }
      })
      .finally(() => setLoading(false));
  }, [dispatch, userID, location]);

  return (
    <div style={{ width: '90%', maxWidth: '400px', paddingBottom: 22 }}>
      {!(travelHistory.length > 0) && (
        <Swiper
          slidesPerView="auto"
          style={{
            height: travelHistory?.length > 0 ? '100vh' : 'auto',
          }}
          direction="vertical"
          spaceBetween={isMobile ? 10 : 20} // Reduced space between slides
        >
          {loading &&
            new Array(6).fill(1).map((_, idx) => (
              <SwiperSlide
                key={idx.toString()}
                style={{
                  width: window.innerHeight * 0.2 * aspectRatio,
                  cursor: 'pointer',
                }}
              >
                <div>
                  <Skeleton
                    variant="rounded"
                    style={{ aspectRatio }}
                    height={'20vh'}
                  />
                  <Skeleton
                    variant="rounded"
                    width={window.innerHeight * 0.2 * aspectRatio}
                    className="home-history-item-title"
                  />
                  <Skeleton
                    variant="text"
                    width={window.innerHeight * 0.2 * aspectRatio}
                    className="home-history-item-description"
                  />
                  <Skeleton
                    variant="text"
                    width={window.innerHeight * 0.2 * aspectRatio}
                    className="home-history-item-description"
                  />
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      )}
      {travelHistory?.length > 0 ? (
        travelHistory.map((item, idx) => {
          return (
            <div
              style={{
                width: '100%',
                height: 'auto',
                padding: '10px',
              }}
              key={idx + '231c'}
            >
              <HistoryItem item={item} />
            </div>
          );
        })
      ) : (
        <Typography
          variant="body1"
          style={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            textAlign: isMobile ? 'left' : 'center',
            padding: '0 0 0 25px',
          }}
        >
          You don't have any trips yet.
          <br />
          Generate one now.
        </Typography>
      )}
    </div>
  );
};

export default P2PHistory;
