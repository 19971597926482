import React from 'react';
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useStyles from './styles';

/**
 * Represents the props for the CategorySelect component.
 * @interface CategorySelectProps
 * @property {string[]} categories - The list of categories to display in the select dropdown.
 * @property {string} value - The current value of the select dropdown.
 * @property {(event: SelectChangeEvent<string>) => void} handleChange - The function to handle category change.
 * @property {boolean} disabled - Boolean indicating whether the select dropdown is disabled.
 */

interface CategorySelectProps {
  categories: string[];
  value: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  disabled?: boolean;
}

/**
 * Component for rendering a select dropdown for selecting the type of point.
 * @param {CategorySelectProps} props - The props object.
 * @returns {JSX.Element} CategorySelect component.
 */
const CategorySelect: React.FC<CategorySelectProps> = ({
  categories,
  value,
  handleChange,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.subHeading}>Type of Point</Typography>
      <Select
        sx={{
          border: '1.5px solid #ECECED',
          borderRadius: '5px',
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
          '& .MuiSelect-select': {
            '&:hover': {},
          },
          '& .MuiSelect-icon': { top: 'calc(50% - 12px)' },
          // '& .MuiSelect-icon': { display: 'none' }, // Hides the select icon
        }}
        value={value}
        onChange={handleChange}
        className={`${classes.subHeading} ${classes.customOutlineInput} ${classes.categoryHeight}`}
        displayEmpty
        disabled={disabled}
      >
        <MenuItem value="" disabled>
          Select
        </MenuItem>
        {categories.map((category, index) => (
          <MenuItem key={index} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default CategorySelect;
