// React Imports
import React, { useState } from 'react';

// MUI Imports
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

// Utility Imports
import { isEditingAllowed } from '~/utility/utils';

// Style Imports
import useStyles from './styles';

/**
 * Represents the props for the ScheduleSection
 * @interface EditMenuProps
 * @property {number} index - index of travel to be edited
 * @property {Function} handleEditClick - Edit Button click handler in EditMenu
 * @property {Function} handleEditClick - Delete button click handler in EditMenu
 * @property {number} travelPointsLength - travelPoints array length
 */
interface EditMenuProps {
  index: number;
  handleEditClick: (index: number) => void;
  handleDeleteClick: (index: number) => void;
  travelPointsLength: number;
}

/**
 * This component provides an edit menu dropdown for managing travel point actions.
 * @param {number} index - index of travel to be edited
 * @param {Function} handleEditClick - Edit Button click handler in EditMenu
 * @param {Function} handleEditClick - Delete button click handler in EditMenu
 * @param {number} travelPointsLength - travelPoints array length
 * @returns {JSX.Element} Returns ScheduleSection component.
 */
const EditMenu: React.FC<EditMenuProps> = ({
  index,
  handleEditClick,
  handleDeleteClick,
  travelPointsLength,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  /**
   * Handle open Edit Menu Dropdown
   * @param event - Mouse Event Object
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handle click on Edit Menu in Dropdown and close the Edit Menu Dropdown
   * @param index - Index of Travel Point Card to edit
   */
  const handleOnEditClick = (index: number) => {
    setAnchorEl(null);
    handleEditClick(index);
  };

  /**
   * Handle click on Delete Menu in Dropdown and close the Edit Menu Dropdown
   * @param index - Index of Travel Point Card to edit
   */
  const handleOnDeleteClick = (index: number) => {
    handleDeleteClick(index);
    setAnchorEl(null);
  };

  return (
    <div className={classes.editbutton}>
      <Button
        id={`edit-button-${index}`}
        aria-controls={open ? `edit-menu-${index}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img src="/icons/dotted.svg" alt="Add a Travel" />
      </Button>
      <Menu
        id={`edit-menu-${index}`}
        aria-labelledby={`edit-button-${index}`}
        anchorEl={anchorEl}
        open={open}
        key={index}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          key={index}
          disabled={!isEditingAllowed(index, travelPointsLength)}
          sx={{
            width: '118px',
            height: '25px',
            fontFamily: 'Futura Bk BT',
          }}
          onClick={() => handleOnEditClick(index)}
        >
          Edit
        </MenuItem>
        <Divider variant="middle" sx={{ bgcolor: 'gray' }} />
        <MenuItem
          disabled={!isEditingAllowed(index, travelPointsLength)}
          sx={{
            width: '118px',
            height: '25px',
            fontFamily: 'Futura Bk BT',
          }}
          onClick={() => handleOnDeleteClick(index)}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default EditMenu;
