import React from 'react';
import LoginForm from './LoginForm';

/**
 * Component for user login.
 * @component
 * @returns {JSX.Element} Rendered Login component.
 */
function Login() {
  return <LoginForm open={true} onClose={() => {}} returnHome={true} />;
}

export default Login;
