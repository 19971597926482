import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  IconButton,
  Box,
  Snackbar,
  Slide,
  Alert,
} from '@mui/material';
import {
  EncodedPathDataObject,
  ITiktokData,
  TravelOptions,
  TravelPointsByDayIndex,
} from '~/utility/models';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import { AddSummaryDialog } from './dialogs/AddSummaryDialog';
import { AddPriceDialog } from './dialogs/AddPriceDialog';
import { LeaveReviewDialog } from './dialogs/LeaveReviewDialog';
import { AddNoteDialog } from './dialogs/AddNoteDialog';

import { RootState } from '~/redux/reducers';
import ActionsCreator from '~/redux/actions';

import {
  deleteFileByUrlFromStorage,
  generateEncodedPathAndDuration,
  getTravelMode,
  updateNextDays,
  updateNextPointByDay,
} from '~/utility/utils';
import {
  addNewEditablePoint,
  updateEditablePlaces,
} from '~/supabase/php2EditablePlaces';
import {
  GetCurrentCategoryType,
  IPointInfo,
  IPointInfoResponse,
  TypeOfPointInterface,
} from '~/components/P2PManual/P2PSearchAutocomplete/helpers';
import { P2PTypeOfPointModal } from '~/components/P2PManual/P2PSearchAutocomplete/P2PTypeOfPointModal';
import { requestInstance } from '~/config';
import { TransitionProps } from '@mui/material/transitions';
import { AddTiktokDialog } from './dialogs/AddTiktokDialog';
import { TiktokThumbnail } from './components/TiktokThumbnail';
import { updateOrCreateEditablePoint } from '~/components/P2PManual/helpers';
import { fetchPlaceInfoSignal } from '~/components/signals/placeInfoSignal';
import subscribeSignal from '~/hooks/subscribeSignal';
import { store } from '~/redux/store';
import { getTravelItinerary } from '~/supabase/publishTravel';
import { AddImageOrVideoDialog } from './dialogs/AddImageOrVideoDialog';
import { MediaWrapper } from './components/MediaWrapper';

interface P2PTravelPointEditDialogProps {
  open: boolean;
  onClose: () => void;
  point: TravelOptions;
}

export type EditablePlacesKnownKeys =
  | 'review'
  | 'longDescription'
  | 'description'
  | 'cost';

export const P2PTravelPointEditDialog: React.FC<
  P2PTravelPointEditDialogProps
> = ({ open, onClose, point }) => {
  const dispatch = useDispatch();

  const typeOfPoint: TypeOfPointInterface = useMemo(() => {
    return GetCurrentCategoryType(point?.typeOfPoint);
  }, [point]);

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [isFetchingInfo, setIsFetchingInfo] = useState<boolean>(
    fetchPlaceInfoSignal.peek() || false,
  );

  subscribeSignal(fetchPlaceInfoSignal, setIsFetchingInfo);

  const [price, setPrice] = useState<number | null>(point.cost ?? null);
  const [summary, setSummary] = useState<string>(point.description ?? '');
  const [rating, setRating] = useState<number | null>(
    !isNaN(Number(point.review)) ? Number(point.review) : null,
  ); // Initial state for rating
  const [note, setNote] = useState<string>(point.longDescription ?? '');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const snackbarOpen: boolean = useSelector(
    (state: RootState) => state.MapReducers.snackbarOpen,
  );
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [dialogStates, setDialogStates] = useState({
    addSummaryOpen: false,
    addPriceOpen: false,
    leaveReviewOpen: false,
    addNoteOpen: false,
    addVideoOpen: false,
    addImageOpen: false,
    addImageAndVideo: false,
    addTiktokOpen: false,
  });

  const toggleDialog = (dialog: string) => {
    setDialogStates((prev) => ({
      ...prev,
      [dialog]: !prev[dialog as keyof typeof prev],
    }));
  };

  const fetchPointInfo = async (point: TravelOptions) => {
    setIsFetchingInfo(true);
    const { value, label, city, street, country } = point;

    const payload: IPointInfo = {
      name: value ?? label,
      ...(city && { city }),
      ...(street && { street }),
      ...(country && { country }),
    };

    // Validate required fields
    const requiredFields: (keyof IPointInfo)[] = ['name'];

    const missingFields = requiredFields.filter((field) => {
      const value = payload[field];
      return value === undefined || value === null || value === '';
    });
    if (missingFields.length > 0) {
      setErrorMessage(
        `Missing required field(s): ${missingFields.join(', ')}.`,
      );
      dispatch(ActionsCreator.setSnackbarOpen(true));

      return;
    }

    requestInstance
      .post('place-info', payload)
      .then(async (response) => {
        // console.log('Response data:', response.data);
        const result = response?.data?.resource as IPointInfoResponse;

        const { id: selectedPointId, dayIndex, editableplaceId } = point;

        const updatedProperties = {
          review: result?.review || '',
          longDescription: result?.longDescription || '',
          description: result?.description || '',
          cost: result?.cost || 0,
        };

        let newProperties: TravelOptions = {
          ...point,
          ...updatedProperties,
        };

        newProperties = await updateOrCreateEditablePoint(
          editableplaceId,
          updatedProperties,
          travelPointsByDayIndex,
          dayIndex,
          selectedPointId,
        );

        let updatedDayPoints = [...(travelPointsByDayIndex[dayIndex] || [])];

        updatedDayPoints = updatedDayPoints.map((p) =>
          p.id === point.id ? newProperties : p,
        );

        let updatedTravelPointsByDayIndex = { ...travelPointsByDayIndex };

        updatedTravelPointsByDayIndex = {
          ...updatedTravelPointsByDayIndex,
          [dayIndex]: updatedDayPoints,
        };

        dispatch(
          ActionsCreator.setTravelPointsByDayIndex(
            updatedTravelPointsByDayIndex,
          ),
        );
        setSummary(result?.description);
        setNote(result?.longDescription);
        setPrice(result?.cost || 0);
        setRating(Number(result?.review) || 0);
      })
      .catch((error) => {
        console.log('Error from filling info automatically', error);
        const response = error?.response?.data?.message;
        setErrorMessage(
          response ?? 'We failed to fetch the info, please try again.',
        );
        dispatch(ActionsCreator.setSnackbarOpen(true));
      })
      .finally(() => {
        setIsFetchingInfo(false);
      });
  };

  const removePoint = async () => {
    setIsRemoving(true);
    const selectedPointId = point.id;
    const dayIndex = point.dayIndex;

    const currentPointIndex = travelPointsByDayIndex[dayIndex]?.findIndex(
      (point) => point.id === selectedPointId,
    );

    if (point?.tiktokData?.[0]?.thumbnail) {
      deleteFileByUrlFromStorage({
        bucketName: 'travel-thumbnails',
        userId: store.getState().MapReducers.userID,
        fileUrl: point?.tiktokData[0]?.thumbnail,
      });
    }

    const previousIndex = currentPointIndex - 1;
    const nextIndex = currentPointIndex + 1;

    const pointsByDay = travelPointsByDayIndex[dayIndex];

    const previousPoint = pointsByDay[previousIndex];
    const nextPoint = pointsByDay[nextIndex];

    let updatedPointsByDay = [...pointsByDay];

    if (previousPoint) {
      let duration: number = 0;
      let encodedPath: EncodedPathDataObject | undefined = {
        data: [],
        path: '',
      };
      if (nextPoint) {
        const result = await generateEncodedPathAndDuration(
          { ...previousPoint, selectedTransport: getTravelMode('Drive') },
          nextPoint,
        );

        duration = result.duration;
        encodedPath = result.encodedPath;

        updatedPointsByDay[previousIndex] = {
          ...previousPoint,
          selectedTransport: getTravelMode('Drive'),
          encodedPath,
          duration,
        };
      } else {
        const { encodedPath, ...rest } = previousPoint;
        updatedPointsByDay[previousIndex] = {
          ...rest,
          selectedTransport: getTravelMode('Drive'),
          duration: 0,
        };
      }
    }

    const newTravelPointsByDayIndex = {
      ...travelPointsByDayIndex,
      [dayIndex]: updatedPointsByDay,
    };

    // Update travelPointsByDayIndex for the specific dayIndex
    let updatedDayPoints = newTravelPointsByDayIndex[dayIndex]?.filter(
      (point) => point.id !== selectedPointId,
    );

    let updatedTravelPointsByDayIndex: TravelPointsByDayIndex =
      updatedDayPoints?.length
        ? {
            ...travelPointsByDayIndex,
            [dayIndex]: updatedDayPoints,
          }
        : {};

    // check if the last place matches the first of the next point
    updatedTravelPointsByDayIndex = updateNextDays(
      updatedTravelPointsByDayIndex,
      dayIndex,
      updateNextPointByDay,
    );

    if (updatedDayPoints?.length === 0) {
      const { P2PManualReducers, MapReducers } = store.getState();

      const historyIdentifier = P2PManualReducers.currentHistoryIdentifier;

      getTravelItinerary(historyIdentifier)
        .then((res: any) => {
          const { publishable_data } = res;
          const publishId = MapReducers.publishID;
          const userId = MapReducers.userID;
          ActionsCreator.publishManualTravelPoints(
            {
              ...publishable_data,
              travelPoints: [],
            },
            userId,
            historyIdentifier,
            publishId,
          ).then((res) => {});
        })
        .catch((error) => {
          if (error) {
            console.log(
              'ERROR OCCURED WHILE GETTING MANUAL HISTORY edit travel point',
              {
                error,
              },
            );
          }
        });
    }

    // Dispatch updates
    dispatch(
      ActionsCreator.setTravelPointsByDayIndex(updatedTravelPointsByDayIndex),
    );
    setIsRemoving(false);
    onClose();
  };

  const handleUpdatePoint = async <K extends keyof TravelOptions>(
    key: K,
    value: TravelOptions[K],
  ) => {
    const EDITABLE_PLACE_ID_KEY = 'editableplaceId';
    const { id: selectedPointId, dayIndex, editableplaceId } = point;

    const updatePoints = (
      points: TravelOptions[],
      newProperties: Partial<TravelOptions>,
    ) =>
      points.map((p) =>
        p.id === selectedPointId ? { ...p, ...newProperties } : p,
      );

    let updatedDayPoints = [...(travelPointsByDayIndex[dayIndex] || [])];

    if (editableplaceId) {
      const hasAdded = await updateEditablePlaces(editableplaceId, key, value);

      // THIS IS FOR SAFETY, MAYBE THIS WILL NEVER BE USED, BUT IF SOMETHING HAPPENED THAT ROW WAS DELETED FROM EDITABLEPLACES DB, BUT POINT STILL HAVE ID OF THAT ROW, WHICH DOESN'T EXIST ENYMORE
      if (hasAdded && hasAdded.length === 0) {
        const newPoint = {
          ...(travelPointsByDayIndex[dayIndex]?.find(
            (p) => p.id === selectedPointId,
          ) || {}),
          [key]: value,
        } as TravelOptions;

        delete newPoint.editableplaceId;

        await addNewEditablePoint(newPoint);
      }
    } else {
      const newPoint = {
        ...(travelPointsByDayIndex[dayIndex]?.find(
          (p) => p.id === selectedPointId,
        ) || {}),
        [key]: value,
      } as TravelOptions;

      const newEditablePoint = await addNewEditablePoint(newPoint);

      if (newEditablePoint && newEditablePoint?.id) {
        const newProperties = {
          [key]: value,
          [EDITABLE_PLACE_ID_KEY]: newEditablePoint.id,
        };
        updatedDayPoints = updatePoints(updatedDayPoints, newProperties);
      }
    }

    updatedDayPoints = updatePoints(updatedDayPoints, { [key]: value });

    dispatch(
      ActionsCreator.setTravelPointsByDayIndex({
        ...travelPointsByDayIndex,
        [dayIndex]: updatedDayPoints,
      }),
    );
  };

  const dialogHandlers = {
    summary: (summary?: string) => {
      if (summary) handleUpdatePoint('description', summary?.trim());
      toggleDialog('addSummaryOpen');
    },
    price: (price?: number) => {
      if (price !== undefined && price !== point.cost)
        handleUpdatePoint('cost', price);
      toggleDialog('addPriceOpen');
    },
    review: (review?: string) => {
      if (review && review !== point.review) {
        // Ensure the review has changed
        handleUpdatePoint('review', review?.trim());
      }
      toggleDialog('leaveReviewOpen');
    },
    note: (note?: string) => {
      if (note) handleUpdatePoint('longDescription', note?.trim());
      toggleDialog('addNoteOpen');
    },
    tiktok: (tiktokData?: ITiktokData[]) => {
      if (tiktokData) handleUpdatePoint('tiktokData', tiktokData);
      toggleDialog('addTiktokOpen');
    },

    image: (image?: any) => {
      if (image) handleUpdatePoint('media', image);
      toggleDialog('addImageOpen');
    },

    video: (video?: any) => {
      if (video) handleUpdatePoint('media', video);
      toggleDialog('addVideoOpen');
    },

    imageAndVideo: (media?: any) => {
      if (media) handleUpdatePoint('media', media);
      toggleDialog('addImageAndVideo');
    },
  };

  const dialogControls = {
    price: {
      state: price,
      setState: setPrice,
    },
    summary: {
      state: summary,
      setState: setSummary,
    },
    note: {
      state: note,
      setState: setNote,
    },
    review: {
      state: rating,
      setState: setRating,
    },
  };

  const initialEditButtons = [
    { title: 'Add Summary', onClick: () => dialogHandlers.summary() },
    { title: 'Add Price', onClick: () => dialogHandlers.price() },
    { title: 'Leave Review', onClick: () => dialogHandlers.review() },
  ];

  const initialNotesButton = [
    { title: 'Add Additional Notes', onClick: () => dialogHandlers.note() },
  ];

  const initialTiktokButton = [
    { title: 'Add Tiktok Video', onClick: () => dialogHandlers.tiktok() },
  ];

  const initialInfoButtons = [
    {
      name: 'review',
      title: `${point.review} ⭐`,
      onClick: () => dialogHandlers.review(),
    },
    {
      name: 'price',
      title: `~US$${
        point.cost !== null && point.cost !== undefined && point.cost
      }`,
      onClick: () => dialogHandlers.price(),
    },
  ];

  const renderButtons = (buttons: any) => {
    return buttons.map((item: any) => (
      <Button
        key={item.title}
        sx={{
          fontSize: '18px',
          fontWeight: 700,
          textTransform: 'capitalize',
          background: '#FFFFFF',
          boxShadow: `
            0px 2px 6px 0px #B8C5D033,
            0px 6px 22px 0px #B8C5D04D,
            0px 9px 30px 0px #C6D8E933,
            inset 0px 1.5px 1.5px 0px #FFFFFF40
          `,
          borderRadius: '18px',
          color:
            (item.name === 'price' && point.cost) ||
            (item.name === 'review' && point.review)
              ? '#000'
              : '',
          paddingLeft: 2,
        }}
        onClick={item.onClick}
        disabled={isRemoving || isFetchingInfo}
      >
        {item.title}
      </Button>
    ));
  };

  const filteredEditButtons = initialEditButtons.filter((el) => {
    if (el.title === 'Add Summary' && point.description) {
      return false;
    }

    if (
      el.title === 'Add Price' &&
      point.cost !== undefined &&
      point.cost !== null
    ) {
      return false;
    }

    if (el.title === 'Leave Review' && point.review) {
      return false;
    }

    return true;
  });

  const filteredInfoButtons = initialInfoButtons.filter((el) => {
    if (el.name === 'review' && point.review) {
      return true;
    }
    if (el.name === 'price' && point.cost !== undefined) {
      return true;
    }
    return false;
  });

  const filteredNotesButton = initialNotesButton.filter((el) => {
    if (el.title === 'Add Additional Notes' && point.longDescription) {
      return false;
    }
    return true;
  });

  const filteredTiktokButton = initialTiktokButton.filter((el) => {
    if (
      el.title === 'Add Tiktok Video' &&
      (point.tiktokData?.length! > 0 || (point.media && point.media.length > 0))
    ) {
      return false;
    }
    return true;
  });

  const [openSelectTypeModal, setOpenSelectTypeModal] =
    useState<boolean>(false);

  return (
    <Dialog
      open={open}
      onClose={!isRemoving ? onClose : undefined}
      disableEscapeKeyDown={isRemoving}
      fullScreen
    >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={() => dispatch(ActionsCreator.setSnackbarOpen(false))} // Close the Snackbar on action
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }} // Position the Snackbar at bottom left
        TransitionComponent={Slide} // Use the Slide component for the transition
        TransitionProps={{ direction: 'right' } as TransitionProps} // Slide from right to left
      >
        <Alert
          variant="filled"
          severity="error"
          onClose={() => dispatch(ActionsCreator.setSnackbarOpen(false))}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <P2PTypeOfPointModal
        open={openSelectTypeModal}
        onClose={() => {
          setOpenSelectTypeModal(false);
        }}
        onSave={(type) => {
          handleUpdatePoint('typeOfPoint', type.placeType);
        }}
        selectedPlaceType={typeOfPoint}
      />
      <DialogTitle>
        <IconButton
          onClick={onClose}
          edge="start"
          aria-label="close"
          sx={{ position: 'absolute', left: 10, top: 6 }}
          disabled={isRemoving}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ textAlign: 'center', fontFamily: 'Poppins, sans-serif' }}>
          <Typography
            variant="h6"
            component="span"
            sx={{ fontWeight: 'bold', ml: 4, fontSize: '24px' }}
          >
            EDIT MODE
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontWeight: 'bold', fontSize: '18px' }}
          >
            (tap on what you want to change)
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            scrollbarWidth: 'none',
            paddingBottom: '30px',
            paddingTop: '20px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.15 }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 0,
              marginBottom: 10,
              maxWidth: '90%',
              justifyContent: 'center',
            }}
          >
            <Box onClick={() => setOpenSelectTypeModal(true)}>
              <img
                src={typeOfPoint?.icon}
                height={48}
                style={{ marginRight: 10 }}
                loading="lazy"
                alt={typeOfPoint?.label}
              />
            </Box>
            <Typography
              fontSize={24}
              fontFamily={'Poppins'}
              fontWeight={'700'}
              style={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                textAlign: 'center',
              }}
            >
              {point?.text}
            </Typography>
          </motion.div>

          {(!point.description ||
            !point.longDescription ||
            !point.review ||
            point.cost === undefined ||
            point.cost === null) && (
            <Button
              sx={{
                fontFamily: 'Poppins, sans-serif',
                textTransform: 'capitalize',
                fontSize: '21px',
                fontWeight: 700,
                background: '#003B95',
                borderRadius: '18px',
                marginBottom: '8px',
                color: '#ffffff',
                padding: '4px 12px',
                textAlign: 'center',
                '&:hover': {
                  background: '#003B95',
                  boxShadow: '0px 4px 10px rgba(184, 197, 208, 0.2)',
                },
              }}
              onClick={async () => await fetchPointInfo(point)}
              disabled={isRemoving || isFetchingInfo}
            >
              {isFetchingInfo ? 'Fetching info...' : 'Fill Info Automatically'}
            </Button>
          )}

          <Box className="mb-4 mt-2 px-2 w-[90%]">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {point?.description && (
                <Box
                  className="mt-2"
                  sx={{
                    width: '100%',
                    cursor:
                      isRemoving || isFetchingInfo ? 'not-allowed' : 'pointer',
                    opacity: isRemoving || isFetchingInfo ? 0.5 : 1,
                    pointerEvents:
                      isRemoving || isFetchingInfo ? 'none' : 'auto',
                  }}
                  onClick={() => dialogHandlers.summary()}
                >
                  <Typography
                    sx={{
                      color: '#bdbdbd',
                      fontSize: '18px',
                      lineHeight: '100%',
                      fontFamily:
                        'Poppins, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif',
                      width: '350px',
                      textAlign: 'center',
                    }}
                  >
                    {point?.description}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  my: '1.2rem',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: 'auto auto',
                  rowGap: 2,
                  justifyItems: 'center',
                  '& > :first-of-type': {
                    gridColumn: 'span 2',
                  },
                  '& > :not(:first-of-type)': {
                    gridColumn: 'span 1',
                  },
                  '& > :nth-last-of-type(-n+2)': {
                    gridColumn: 'span 1',
                    gridRow: 2,
                  },
                }}
              >
                {renderButtons(filteredEditButtons)}
                {renderButtons(filteredInfoButtons)}
              </Box>

              <Button
                sx={{
                  textTransform: 'capitalize',
                  fontSize: '18px',
                  fontWeight: 700,
                  background: '#FF0000',
                  borderRadius: '18px',
                  marginBottom: '8px',
                  color: '#ffffff',
                  width: '200px',
                  '&:hover': {
                    background: '#CC0000',
                    boxShadow: '0px 4px 10px rgba(255, 0, 0, 0.5)',
                  },
                }}
                onClick={removePoint}
                disabled={isFetchingInfo || isRemoving}
              >
                {isRemoving ? 'Removing point...' : ' Remove point'}
              </Button>
            </Box>
          </Box>
          {point.tiktokData && point.tiktokData.length > 0 ? (
            <TiktokThumbnail
              imageSrc={point?.tiktokData[0]?.thumbnail}
              removeThumbnail={() => {
                handleUpdatePoint('tiktokData', []);
                if (point?.tiktokData?.[0]?.thumbnail) {
                  deleteFileByUrlFromStorage({
                    bucketName: 'travel-thumbnails',
                    userId: store.getState().MapReducers.userID,
                    fileUrl: point.tiktokData[0].thumbnail,
                  });
                }
              }}
              dialogHandlers={dialogHandlers}
            />
          ) : (
            <MediaWrapper
              dialogHandlers={dialogHandlers}
              point={point}
              images={images}
              videos={videos}
              isRemoving={isRemoving}
              typeOfPoint={typeOfPoint}
            />
          )}

          <div
            style={{
              width: '95%',
              display: 'flex',
              marginBottom: 20,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></div>
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.35 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: '95%',
              paddingTop: 24,
              paddingBottom: 24,
            }}
          >
            <Box
              sx={{
                mb: 2,
              }}
            >
              {renderButtons(filteredTiktokButton)}
              {point.tiktokData?.length! > 0 && (
                <Box
                  onClick={
                    isFetchingInfo || isRemoving
                      ? () => {}
                      : () => dialogHandlers.tiktok()
                  }
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '20px',
                      textAlign: 'center',
                      textUnderlinePosition: 'from-font',
                      textDecorationSkipInk: 'none',
                    }}
                  >
                    {point.tiktokData![0].url}
                  </Typography>
                </Box>
              )}
            </Box>

            {renderButtons(filteredNotesButton)}
            {point.longDescription && (
              <Box
                onClick={
                  isRemoving || isFetchingInfo
                    ? () => {}
                    : () => dialogHandlers.note()
                }
                sx={{
                  cursor:
                    isRemoving || isFetchingInfo ? 'not-allowed' : 'pointer',
                  opacity: isRemoving || isFetchingInfo ? 0.5 : 1,
                  pointerEvents: isRemoving || isFetchingInfo ? 'none' : 'auto',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    textAlign: 'center',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                    color:
                      isRemoving || isFetchingInfo
                        ? 'text.disabled'
                        : 'inherit',
                  }}
                >
                  {point.longDescription}
                </Typography>
              </Box>
            )}
            {/* <a
              target="_blank"
              href={`https://google.com/maps/search/${label
                .split(' ')
                .join('+')}`}
              style={{ textDecoration: 'none', fontSize: '18px' }}
              rel="noreferrer"
            >
              Search on Google
            </a> */}
          </motion.div>
        </div>

        {/* Dialogs */}
        <AddSummaryDialog
          open={dialogStates.addSummaryOpen}
          onClose={dialogHandlers.summary}
          setState={dialogControls.summary.setState}
          state={dialogControls.summary.state}
        />
        <AddPriceDialog
          open={dialogStates.addPriceOpen}
          onClose={dialogHandlers.price}
          setState={dialogControls.price.setState}
          state={dialogControls.price.state}
        />
        <LeaveReviewDialog
          open={dialogStates.leaveReviewOpen}
          onClose={dialogHandlers.review}
          setState={dialogControls.review.setState}
          state={dialogControls.review.state}
        />
        <AddNoteDialog
          open={dialogStates.addNoteOpen}
          onClose={dialogHandlers.note}
          setState={dialogControls.note.setState}
          state={dialogControls.note.state}
        />
        <AddTiktokDialog
          open={dialogStates.addTiktokOpen}
          onSave={dialogHandlers.tiktok}
          point={point}
        />

        <AddImageOrVideoDialog
          open={dialogStates.addImageAndVideo}
          onClose={dialogHandlers.imageAndVideo}
          point={point}
        />
      </DialogContent>
    </Dialog>
  );
};
