import React, { Fragment, useState } from 'react';
import { showDrawerMenu } from '../DrawerMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { showOverviewMenu } from '../OverviewMenu';

import './style.css';
import subscribeSignal from '~/hooks/subscribeSignal';
import { isVizualizingSignal } from '../ViewTravel/common';
import ROUTES from '~/routes';

const Navbar = ({
  onBackButtonClick,
  style,
  onlyBurger = false,
  shouldShowMap = false,
  shouldShowDiscovery = false,
}: {
  onBackButtonClick?: () => void;
  style?: React.CSSProperties;
  onlyBurger?: boolean;
  shouldShowMap?: boolean;
  shouldShowDiscovery?: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showDrawerMenuValue, setShowDrawerMenuValue] = useState(
    showDrawerMenu.peek(),
  );
  subscribeSignal(showDrawerMenu, setShowDrawerMenuValue);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: onlyBurger ? 'auto' : '12px 20px',
        height: onlyBurger ? 'auto' : 80,
        background: onlyBurger ? 'transparent' : '#ffffff',
        maxWidth: 450,
        width: onlyBurger ? 'auto' : '100%',
        alignSelf: 'center',
        ...style,
      }}
    >
      {onBackButtonClick ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onBackButtonClick?.();
            isVizualizingSignal.value = false;
          }}
          style={{
            pointerEvents: 'all',
            width: onlyBurger ? 'auto' : '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: shouldShowDiscovery ? 'center' : 'flex-start',
          }}
        >
          <div
            className={`mobile-header-go-back-btn ${
              shouldShowDiscovery && 'home'
            }`}
          >
            <img
              src={
                shouldShowDiscovery ? './icons/homeLogo.png' : './goBackNew.png'
              }
              alt="Back Button"
              // width={40}
              // height={33}
            />
          </div>
        </div>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
            isVizualizingSignal.value = false;
            if (window.location.pathname === '/viewtravel') {
              navigate('/home');
              showOverviewMenu.value = false;
              return;
            }

            showDrawerMenu.value = true;
          }}
          style={{
            pointerEvents: 'all',
            width: '80px',
          }}
        >
          <div
            style={{
              boxShadow: onlyBurger ? 'none' : 'rgba(0, 0, 0, 0.2) 0px 4px 8px',
              borderRadius: '12px', // Make it more rounded for a smoother edge
              padding: '14px', // Adjust to give some spacing inside the cube
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: onlyBurger ? 'auto' : '64px', // Ensures it's a square
              height: '64px', // Same height as width for a cube-like shape

              backgroundColor: '#fff', // White background to match
              cursor: 'pointer',
            }}
          >
            {location.pathname === ROUTES.VIEWTRAVEL.path ? (
              <div className="p-3 shadow-sm" style={{ borderRadius: '15px' }}>
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.25 7.5V18.75H6.5V13.75C6.5 12.3693 7.61929 11.25 9 11.25C10.3807 11.25 11.5 12.3693 11.5 13.75V18.75H17.75V7.5L9 0L0.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            ) : (
              <img
                src="./icons/burger.png"
                alt="Menu BUtton"
                width={20}
                height={14}
              />
            )}
          </div>
        </div>
      )}
      {!onlyBurger && (
        <Fragment>
          <div
            onClick={() => {
              if (showDrawerMenuValue) showDrawerMenu.value = false;
              navigate('/home');
              showOverviewMenu.value = false;
              onBackButtonClick?.();
            }}
            style={{ cursor: 'pointer' }}
          >
            <img
              src="./orangeLogoVizualTravel.svg"
              alt="Logo"
              width={123.56}
              height={26}
            />
          </div>
          {showDrawerMenuValue && shouldShowMap ? (
            <div
              className="custom-badge pointer"
              onClick={() => {
                showOverviewMenu.value = false;
              }}
            >
              <img
                src="./icons/mapIcon.png"
                width={24}
                height={24}
                style={{ marginRight: '4px' }}
                alt="Map Icon"
              />
              <span>Map</span>
            </div>
          ) : (
            <div style={{ width: '25%' }} />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Navbar;
