import { useDispatch, useSelector } from 'react-redux';
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { DateFormatType, formatDate } from '../ViewTravel/StatsOverlay';

import { signal } from '@preact/signals-core';
import { IStreamAction, useWebSocket } from '~/hooks/use_websocket';
import { getOrdinal } from '~/utility/utils';
import { addPauseSignal } from '../signals/itinerary/pauseSignal';
import {
  ICompletedPlace,
  IProcessedPlace,
  WebSocketMessage,
} from '~/types/websocket';
import ActionsCreator from '~/redux/actions';
import { useSessionId } from '~/hooks/use_session_id';
import WhereAndWhen from './WhereAndWhen';
import MapPreview, {
  fadeAllpreviousLines,
  linesSignal,
  previewMapSignal,
} from './MapPreview';
import { dataReadySignal } from '../signals/itinerary/dataReadySignal';
import {
  bottomSheetp2pStateSignal,
  commonButtonsSx,
  getMapMarker,
  markersSignal,
} from './MapPreview/BottomSheetp2p';
import { LngLatLike } from 'maplibre-gl';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';
import { useSignals } from '@preact/signals-react/runtime';
import { PREVIOUS_ITINERARIES_KEY } from '../Home/ActionConfirmationDialog';
import { ItineraryWrapper } from './MapPreview/ItineraryOverview/ItineraryWrapper';
import TripStories from './MapPreview/TripStories';
import { selectIsStoriesOpened } from '~/redux/selectors/tripInfoSelectors';
import ROUTES from '~/routes';
import { useLocation } from 'react-router-dom';
import { store } from '~/redux/store';

interface State {
  startArea: string;
  startPoint: string;
  startDate: string;
  currentStep: number;
  startTime: string;
  endDate: string;
  contextdata: { key: string; value: string }[];
  errorMessage?: string; // Added errorMessage to handle errors
}

const initialState: State = {
  startArea: '',
  startPoint: '',
  startDate: '',
  startTime: '8:00 AM',
  endDate: '',
  currentStep: 1,
  contextdata: [],
};

export const generationStateSignal =
  signal<Omit<State, 'currentStep'>>(initialState);
export const dayCounterSignal = signal<number>(0);
export const lastGeneratePlaceSignal = signal<IProcessedPlace | null>(null);
const wsErrorProvokeSignal = signal({
  type: 'none' as 'none' | 'error' | 'timeout',
  value: 0,
});

export const showWSError = (str: 'none' | 'error' | 'timeout') => {
  wsErrorProvokeSignal.value = {
    type: str,
    value: wsErrorProvokeSignal.peek().value + 1,
  };
};
const websocketUrlPrefix =
  process.env.NODE_ENV === 'production' ? 'wss://' : 'ws://';

const Itinerary: React.FC = () => {
  useSignals();
  const dispatch = useDispatch();
  const sessionId = useSessionId();
  const isStoriesOpened = useSelector(selectIsStoriesOpened);
  const location = useLocation();

  const {
    messages,
    pauseStream,
    resumeStream,
    sendMessage,
    ws,
    requestNextData,
    regenerateStream,
    wsLoading,
    cancelStream,
    cleanUpForNextPlace,
    error: wsError,
    extra,
    getTitleAndDescription,
    refreshStream,
  } = useWebSocket(
    `${websocketUrlPrefix}${process.env.REACT_APP_SMARTAI_TRIP_SERVER_BASE_URL?.split('//')[1]
    }`,
    sessionId,
  );

  const [state, setState] = useState<State>(initialState);
  const [hardLoading, setHardLoading] = useState(false);
  const [showWSOffError, setShowWSOffError] = useState({
    type: 'none' as 'none' | 'error' | 'timeout' | 'refresh',
    visible: false,
  });
  // const [historySplice, setHistorySplice] = useState(0);
  const isMobile = useIsMobile();

  // const nextStep = useCallback(() => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     currentStep: prevState.currentStep + 1,
  //   }));
  // }, []);

  const restartForm = useCallback(() => {
    setState(initialState);
  }, []);

  const moveStepToPoint = (step: number) => {
    if (step === 0) return; // Ensure step cannot be set to 0
    setState((prevState) => ({
      ...prevState,
      currentStep: step,
    }));
  };

  const cleanUp = () => {
    addPauseSignal.value = false;
    dataReadySignal.value = false;

    bottomSheetp2pStateSignal.value = 'loading';

    setState((prevState) => ({
      ...prevState,
      errorMessage: '',
    }));
  };

  // const deleteDay = useCallback(
  //   (dayIndex: number, reason: string) => {
  //     if (ws) {
  //       dayCounterSignal.value =
  //         dayCounterSignal.peek() > 0 ? dayCounterSignal.peek() - 1 : 0;
  //       setState((prev) => ({ ...prev, loading: true })); // Set loading to true

  //       sendMessage({
  //         action: IStreamAction.deleteDay,
  //         payload: { id: sessionId, dayIndex, reason },
  //       });
  //     } else {
  //       setState((prev) => ({ ...prev, error: 'WebSocket is not connected.' }));
  //     }
  //   },
  //   [sessionId, ws],
  // );

  const handleCancel = () => {
    dayCounterSignal.value =
      dayCounterSignal.peek() > 0 ? dayCounterSignal.peek() - 1 : 0;
    cancelStream();
    cleanUp();
    markersSignal.value = [];
    linesSignal.value = [];
    setState((prevState) => ({
      ...prevState,
      currentStep: 1,
    }));
    // resumeStream();
  };

  const updateState = (
    errorMessage = '',
    showPause = false,
    showCancel = false,
  ) => {
    setState((prevState) => ({
      ...prevState,
      errorMessage,
      showPause,
      showCancel,
    }));
  };

  const sendWebSocketPayload = (
    prompt: string,
    action = IStreamAction.start,
  ) => {
    if (ws) {
      const payload: WebSocketMessage = {
        action,
        payload: {
          prompt,
          id: sessionId,
        },
      };

      dispatch(ActionsCreator.setLoading(true));
      updateState('', true, true);
      sendMessage(payload);
    } else {
      updateState('WebSocket connection failed. Please refresh the browser.');
      dispatch(ActionsCreator.setSnackbarOpen(true));
    }
  };

  const generateItineraryPrompt = () => {
    return `Build the perfect full ${getOrdinal(
      dayCounterSignal.peek(),
    )} Day itinerary for ${generationStateSignal.peek().startArea
      } on ${formatDate(
        new Date(generationStateSignal.peek().startDate),
        DateFormatType.MonthDateCommaYear,
      )}
      ${generationStateSignal.peek().endDate !== ''
        ? `till ${formatDate(
          new Date(generationStateSignal.peek().endDate),
          DateFormatType.MonthDateCommaYear,
        )}`
        : ''
      }
      starting from ${generationStateSignal.peek().startPoint} at exactly ${generationStateSignal.peek().startTime || '8:00 AM'
      }.
       Make sure it is a full day itinerary. No backtracking. Least amount of transportation possible. And allow ample time at each place, don't make me rush.
      The day should include:

      Starting Point: If no specific starting point is provided, begin in the city center.
      Morning: 2 points of attraction with short travel times.
      Lunch: A restaurant near the second attraction.
      Afternoon: 2 more points of attraction, prioritizing proximity.
      Dinner: A restaurant near the fourth attraction.
      Evening: Optionally, 1 evening attraction based on the user's preferences.
      Ending: Finish at a hotel or the specified end point.
      Proximity Check:

      Prioritize points that are geographically close but ensure variety.
      Use geocode verification to measure the distance between stops and confirm realistic travel times.
      Transportation Constraints:

      Assign walking for distances under 1 km or ~10 minutes.
      Use transit for distances between 1-5 km with available routes.
      Opt for driving or taxi for longer distances or when transit options are inefficient.
      Include realistic travel times for each transportation mode.
      Prioritization:

      Ensure the least amount of transportation overall without compromising variety or flow.
      Avoid backtracking or crossing large parts of the city unnecessarily.
      Pacing:

      Allocate 1-2 hours per point of attraction or restaurant.
      Ensure ample time to enjoy each stop without rushing.
      Contextual Inspiration:
      Use itineraries that have been reviewed and loved by real travelers as inspiration to craft a cohesive, practical plan.

        ${generationStateSignal.peek()?.contextdata.length > 0
        ? `
        5. Some Hard Constraints by the user:
        ${generationStateSignal
          .peek()
          ?.contextdata.map((item) => {
            return `  - ${item.key}: ${item.value}`;
          })
          .join('\n')}
      `
        : ''
      }
      Ensure the last arrival point of the day is a **hotel**; no other type is acceptable. Focus on keeping all locations within one primary area to minimize travel time and maintain a cohesive experience.`;
  };

  const generateNextDayPrompt = (lastPlaceGenerated: IProcessedPlace) => {
    return `
      I need you to generate the transportation index (${+lastPlaceGenerated.transportationIndex + 1
      } for Day ${dayCounterSignal.peek()
      }. 
      The last point you generated for me was ${lastPlaceGenerated.generatedPlace?.arrivalPointFullName
      }  on transportation index (${lastPlaceGenerated.transportationIndex
      }), I want you to keep into account the days before and the overall structure to generate my next Day in a new area and new places I haven't visited yet in the previous days, Day ${dayCounterSignal.peek()
      } starting from this transportation index (${+lastPlaceGenerated.transportationIndex + 1
      }). Make sure it is a full day itinerary, the day ${dayCounterSignal.peek()
      } should most probably end at the same accommodation as the previous day. No backtracking. Least amount of transportation possible. And allow ample time at each place, don't make me rush.
      The day should include:

      Starting Point: If no specific starting point is provided, begin in the city center.
      Morning: 2 points of attraction with short travel times.
      Lunch: A restaurant near the second attraction.
      Afternoon: 2 more points of attraction, prioritizing proximity.
      Dinner: A restaurant near the fourth attraction.
      Evening: Optionally, 1 evening attraction based on the user's preferences.
      Ending: Finish at a hotel or the specified end point.
      Proximity Check:

      Prioritize points that are geographically close but ensure variety.
      Use geocode verification to measure the distance between stops and confirm realistic travel times.
      Transportation Constraints:

      Assign walking for distances under 1 km or ~10 minutes.
      Use transit for distances between 1-5 km with available routes.
      Opt for driving or taxi for longer distances or when transit options are inefficient.
      Include realistic travel times for each transportation mode.
      Prioritization:

      Ensure the least amount of transportation overall without compromising variety or flow.
      Avoid backtracking or crossing large parts of the city unnecessarily.
      Pacing:

      Allocate 1-2 hours per point of attraction or restaurant.
      Ensure ample time to enjoy each stop without rushing.
      Contextual Inspiration:
      Use itineraries that have been reviewed and loved by real travelers as inspiration to craft a cohesive, practical plan.
      
      Don't ever recommend an activity or point of attraction or restaurant that we did in the previous days.
      Don't go back to an activity or point of attraction or restaurant that we did in the days before Day ${getOrdinal(
        dayCounterSignal.peek(),
      )},
      Explore an area you have not explored in previous days. 
      Ensure the last arrival point of the day is a **hotel**; no other type is acceptable. Focus on keeping all locations within one primary area to minimize travel time and maintain a cohesive experience.
    `;
  };

  // Main Handlers
  const handleGeneration = (state: {
    startArea: string;
    startDate: string;
    startPoint: string;
    startTime?: string;
    endDate?: string;
    contextdata: { key: string; value: string }[];
  }) => {
    cleanUp();

    if (!state.startArea || !state.startPoint || !state.startDate) {
      console.log('Please complete all fields before proceeding.');
      updateState('Please complete all fields before proceeding.');
      return;
    }

    generationStateSignal.value = {
      startArea: state.startArea,
      startDate: state.startDate,
      startPoint: state.startPoint,
      startTime: state.startTime || '8:00 AM',
      endDate: state.endDate || '',
      contextdata: state.contextdata,
    };

    dayCounterSignal.value = dayCounterSignal.peek() + 1;
    const prompt = generateItineraryPrompt();

    updateState();
    sendWebSocketPayload(prompt);
  };

  const generateNextDay = (optionalPrompt: string | null = null) => {
    dayCounterSignal.value = dayCounterSignal.peek() + 1;

    const lastPlaceGenerated = messages[messages.length - 1];
    if (lastPlaceGenerated) {
      const prompt =
        optionalPrompt || generateNextDayPrompt(lastPlaceGenerated);
      lastGeneratePlaceSignal.value = lastPlaceGenerated;
      cleanUp();

      markersSignal.peek().forEach((marker) => {
        if (
          marker.id ===
          lastPlaceGenerated.place.location?.value +
          '-' +
          lastPlaceGenerated.transportationIndex
        ) {
          marker.marker.remove();
          const newmarker = getMapMarker(
            0,
            lastPlaceGenerated.place?.location?.value || '',
            lastPlaceGenerated.place.category,
            true,
            false,
            lastPlaceGenerated,
          );
          newmarker.setLngLat(
            lastPlaceGenerated.place.location?.coordinates as LngLatLike,
          );
          previewMapSignal.peek() &&
            newmarker.addTo(previewMapSignal.peek() as any);

          return;
        }
        marker.marker.setOpacity('0.2');
      });
      fadeAllpreviousLines();

      addPauseSignal.value = false;

      cleanUpForNextPlace();
      bottomSheetp2pStateSignal.value = 'provoke-loading';

      updateState('', true);
      sendWebSocketPayload(prompt, IStreamAction.nextDay);
    }
  };

  const restartFromScratch = () => {
    const prompt = generateItineraryPrompt();

    updateState();
    sendWebSocketPayload(prompt);
    return;
  };

  const startFromPreviousPoint = () => {
    const lastPlaceGenerated = messages[messages.length - 1];
    if (lastPlaceGenerated && !!lastPlaceGenerated?.place) {
      regenerateStream({
        placeIndex: lastPlaceGenerated.placeIndex,
        transportationIndex: lastPlaceGenerated.transportationIndex,
        reason: '',
      });
    } else {
      regenerateStream({
        placeIndex: 1,
        transportationIndex: 1,
        reason: '',
      });
    }

    return;
  };

  const tryagain = () => {
    window.location.reload();
    // setHardLoading(true);
    // if (wsError !== '' && wsError !== null) {
    //   restartFromScratch();
    // }
  };

  useEffect(() => {
    console.log({ wsError });
    if (!(wsError !== '' && wsError !== null) && hardLoading) {
      setHardLoading(false);
    }
    let timeOuts = null as any;
    const unsub = wsErrorProvokeSignal.subscribe((val) => {
      if (val.value) {
        if (val.type === 'timeout') {
          timeOuts = setTimeout(async () => {
            if (wsError === 'timeout') {
              if (wsErrorProvokeSignal.peek().value === val.value) {
                setShowWSOffError({
                  type: 'timeout',
                  visible: true,
                });
              }
            } else if (wsError !== '' && wsError !== null) {
              setShowWSOffError({
                type: 'refresh',
                visible: true,
              });
            }
          }, 1500);
        }
      }
    });
    // if (!ws) {
    //   timeOuts = setTimeout(() => {
    //     setShowWSOffError({
    //       type: 'error',
    //       visible: true
    //     });
    //   }, 20000)
    // } else {
    //   setShowWSOffError({
    //     type: 'none',
    //     visible: false
    //   });
    // }
    return () => {
      unsub();
      if (timeOuts) {
        setShowWSOffError({
          type: 'none',
          visible: false,
        });
        clearTimeout(timeOuts);
      }
    };
  }, [ws, wsError, messages]);

  useLayoutEffect(() => {
    // set manual back to default

    store.dispatch(ActionsCreator.setDefaultP2pManualState);
    const previousItineraries = sessionStorage.getItem(
      PREVIOUS_ITINERARIES_KEY,
    );
    if (previousItineraries) {
      console.log('previousItineraries', previousItineraries);
      const previousItinerariesJSON: {
        currentDay: number;
        messages: IProcessedPlace[];
        itineraries: ICompletedPlace[];
        startArea: string;
        startPoint: string;
        startDate: string;
        endDate: string;
        startTime: string;
        contextdata: { key: string; value: string }[];
      } = JSON.parse(previousItineraries);
      const {
        startArea,
        startDate,
        startPoint,
        endDate,
        contextdata,
        startTime,
      } = previousItinerariesJSON;
      generationStateSignal.value = {
        startArea,
        startDate,
        startPoint,
        endDate,
        contextdata,
        startTime,
      };
      setState((prev) => ({
        ...prev,
        startArea,
        startDate,
        startPoint,
        currentStep: 3,
      }));

      sessionStorage.removeItem(PREVIOUS_ITINERARIES_KEY);
    }

    // before windows reload
    window.onbeforeunload = () => {
      sessionStorage.removeItem(PREVIOUS_ITINERARIES_KEY);
    };
  }, []);

  return (
    <>
      <ItineraryWrapper />
      <TripStories
        isOpened={isStoriesOpened && location.pathname === ROUTES.P2PAI.path}
        hanldeClose={() => ActionsCreator.setIsStoriesOpened(false)}
      />

      {
        showWSOffError.type !== 'none' && (
          <Modal
            open={showWSOffError.visible}
            onClose={() => { }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                margin: 'auto',
                padding: '24px',
                background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%)',
                borderRadius: '20px',
                boxShadow:
                  '0px 1.3px 2.21px 0px #07081705 , 0px 3.13px 5.32px 0px #07081707 , 0px 5.89px 10.02px 0px #07081709 , 0px 10.5px 17.87px 0px #0708170B , 0px 19.64px 33.42px 0px #0708170D , 0px 47px 80px 0px #07081712',
                justifyContent: 'center',
                textAlign: 'center',
                display: 'flex',
                width: '80%',
                flexDirection: 'column',
                ...(!isMobile && { width: '40%', margin: '0 auto' }),
              }}
            >
              <h3>
                {showWSOffError.type === 'refresh' ? (
                  <b>We are sorry, Please Refresh so we can continue</b>
                ) : (
                  <b>An Error Occured during Generation</b>
                )}
              </h3>
              {showWSOffError.type === 'refresh' ? (
                <Button
                  onClick={refreshStream}
                  className="my-3"
                  sx={commonButtonsSx}
                >
                  <Typography>Refresh</Typography>
                </Button>
              ) : (
                <Button
                  onClick={tryagain}
                  className="my-3"
                  sx={commonButtonsSx}
                >
                  <Typography>Retry</Typography>
                </Button>
              )}
              <h5 className="my-2">
                {showWSOffError.type === 'timeout' ? (
                  <b>
                    Sorry about that, we’ll make sure this happens less and
                    less.
                  </b>
                ) : (
                  <b>
                    Sorry about that, we’ll make sure this happens less and
                    less.
                  </b>
                )}
              </h5>
            </Box>
          </Modal>
        )
        // : <Modal
        //   open={(wsError !== '' && wsError !== null) || hardLoading}
        //   onClose={() => { }}
        //   style={{
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //   }}
        // >
        //   <Box
        //     sx={{
        //       position: 'absolute',
        //       margin: 'auto',
        //       padding: '24px',
        //       background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%)',
        //       borderRadius: '20px',
        //       boxShadow:
        //         '0px 1.3px 2.21px 0px #07081705 , 0px 3.13px 5.32px 0px #07081707 , 0px 5.89px 10.02px 0px #07081709 , 0px 10.5px 17.87px 0px #0708170B , 0px 19.64px 33.42px 0px #0708170D , 0px 47px 80px 0px #07081712',
        //       justifyContent: 'center',
        //       textAlign: 'center',
        //       display: 'flex',
        //       flexDirection: 'column',
        //       ...(!isMobile && { width: '40%', margin: '0 auto' }),
        //     }}
        //   >
        //     <h3>
        //       <b>

        //         An Error Occurred
        //         during Generation.
        //       </b>
        //     </h3>

        //     <Button
        //       onClick={tryagain}

        //       className='my-3' sx={commonButtonsSx}>
        //       {hardLoading ? <CircularProgress color='inherit' /> : <Typography>Try Again</Typography>}
        //     </Button>

        //     <h5 className="my-2">
        //       <b>
        //         Sorry about that, we’ll make
        //         sure this happens less and less.
        //       </b>
        //     </h5>
        //   </Box>
        // </Modal>
      }

      <>
        {state.currentStep === 1 && (
          <WhereAndWhen
            setDateAndCity={({
              date,
              endDate,
              city,
              point,
              time,
              contextdata,
            }: {
              date: string;
              endDate?: string;
              city: string;
              point: string;
              time: string;
              contextdata: { key: string; value: string }[];
            }) => {
              setState((prevState) => ({
                ...prevState,
                startArea: city,
                startDate: date,
                endDate: endDate || '',
                startPoint: point,
                startTime: time,
                contextdata,
                currentStep: 3,
              }));
              handleGeneration({
                startArea: city,
                startDate: date,
                endDate: endDate || '',
                startPoint: point,
                startTime: time,
                contextdata,
              });
            }}
          />
        )}

        {state.currentStep === 3 && (
          <MapPreview
            moveStepToPoint={moveStepToPoint}
            restartForm={restartForm}
            pauseStream={pauseStream}
            resumeStream={resumeStream}
            handleCancel={handleCancel}
            messages={messages}
            requestNextData={requestNextData}
            regenerateStream={regenerateStream}
            loading={wsLoading}
            generateNextDay={generateNextDay}
            wsError={wsError}
            restartFromScratch={restartFromScratch}
            startFromPreviousPoint={startFromPreviousPoint}
            extra={extra}
            getTitleAndDescription={getTitleAndDescription}
          />
        )}

        {/* {state.errorMessage && (
          <Typography color="error">{state.errorMessage}</Typography>
        )} */}
      </>
    </>
  );
};

export default Itinerary;
