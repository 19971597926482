// export const categories = [
//   '✈️ Airport',
//   '🏠 Home',
//   '🏨 Hotel',
//   '🍽️ Restaurant',
//   '🎡 Point of Attraction',
//   '📌 Other',
// ];

export const categories = [
  'Airport',
  'Home',
  'Hotel',
  'Restaurant',
  'Point of Attraction',
  'Other',
];
export const maxDebounceDelay = 5000;
export const debounceDelayInitial = 0;

export const outlinedInputStylesSX = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    backgroundColor: '#FAFAFB',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#f0f0f0', // Change background color on hover
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none', // Remove the border
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none', // Ensure the border is removed on hover
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none', // Ensure the border is removed when focused
  },
  '&.Mui-focused .MuiOutlinedInput-root': {
    backgroundColor: '#f0f0f0', // Brighten the background on focus
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    backgroundColor: '#f0f0f0', // Brighten the background on focus
  },
};
