import Tracker from '@openreplay/tracker'; // Import the Tracker if not already done

export function initializeOpenReplayTracker(): Tracker | null {
  if (process.env.REACT_APP_OPENREPLAY_PROJECT_KEY) {
    /**
     * OpenReplay Tracker
     */
    const tracker = new Tracker({
      projectKey: process.env.REACT_APP_OPENREPLAY_PROJECT_KEY ?? '',
      __DISABLE_SECURE_MODE:
        process.env.NODE_ENV === 'production' ? false : true,
      obscureInputDates: false,
      obscureInputEmails: false,
      obscureInputNumbers: false,
      obscureTextEmails: false,
      obscureTextNumbers: false,
      defaultInputMode: 0,
      capturePerformance: true,
    });
    tracker.start();
    return tracker;
  }
  return null; // Return null if the project key is not set
}
