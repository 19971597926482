import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers/PickersCalendarHeader';

import { TravelOptions, TravelPointsByDayIndex } from '~/utility/models';
import { RootState } from '~/redux/reducers';

import { useStyles } from './styles'; // Import the styles
import { selectTravelPoints } from '~/redux/selectors/P2PManualSelectors';

// Custom Calendar Header
const CustomCalendarHeader = (props: PickersCalendarHeaderProps<Dayjs>) => {
  const classes = useStyles();
  const { currentMonth, onMonthChange, minDate } = props;

  const selectNextMonth = () =>
    onMonthChange(currentMonth.add(1, 'month').endOf('month'), 'left');

  const selectPreviousMonth = () => {
    if (currentMonth.isAfter(minDate, 'month')) {
      onMonthChange(currentMonth.subtract(1, 'month').endOf('month'), 'right');
    }
  };

  return (
    <div className={classes.calendarHeaderRoot}>
      <Stack spacing={1} direction="row">
        <IconButton
          onClick={selectPreviousMonth}
          title="Previous month"
          className={classes.selectionIcon}
        >
          <ChevronLeft />
        </IconButton>
      </Stack>
      <Typography variant="body2" className={classes.currentMonthText}>
        {currentMonth.format('MMMM YYYY')}
      </Typography>
      <Stack spacing={1} direction="row">
        <IconButton
          onClick={selectNextMonth}
          title="Next month"
          className={classes.selectionIcon}
        >
          <ChevronRight />
        </IconButton>
      </Stack>
    </div>
  );
};

// Custom PickersDay component to handle day rendering logic
interface CustomPickersDayProps extends PickersDayProps<Dayjs> {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

const CustomPickersDay: React.FC<CustomPickersDayProps> = (props) => {
  const { day, selected, startDate, endDate, ...other } = props;
  const classes = useStyles();

  const isInRange = startDate && endDate && day &&
    dayjs.isDayjs(day) &&
    (day.isSame(startDate, 'day') || day.isAfter(startDate)) &&
    (day.isSame(endDate, 'day') || day.isBefore(endDate));

  const isFirstDay = startDate && day.isSame(startDate, 'day');
  const isLastDay = endDate && day.isSame(endDate, 'day');
  const isSingleDay = startDate && endDate && startDate.isSame(endDate, 'day') && day.isSame(startDate, 'day');

  let dayClassName = '';
  if (isSingleDay || (!isInRange && selected)) {
    dayClassName = `${classes.highlightedDay} ${classes.singleDay}`;
  } else if (isInRange) {
    if (isFirstDay) {
      dayClassName = `${classes.highlightedDay} ${classes.firstDay}`;
    } else if (isLastDay) {
      dayClassName = `${classes.highlightedDay} ${classes.lastDay}`;
    } else {
      dayClassName = `${classes.highlightedDay}`;
    }
  }

  return (
    <PickersDay
      {...other}
      day={day}
      selected={isFirstDay || isLastDay || selected}
      className={`${classes.day} ${dayClassName}`}
    />
  );
};

// Main Calendar Component
export const CalendarHeaderComponent: React.FC<{
  onChange: (startDate: Dayjs | null, endDate: Dayjs | null) => void;
}> = ({ onChange }) => {
  const classes = useStyles();

  const travelPoints: TravelOptions[] = useSelector(selectTravelPoints);

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );

  const firstPoint = travelPoints[0];
  const lastPoint = travelPoints[travelPoints.length - 1];

  const initialStartDate = dayjs(firstPoint.startDate).tz(firstPoint.timezone);
  const initialEndDate = dayjs(lastPoint.startDate).tz(lastPoint.timezone);

  const lengthofDays = Object.keys(travelPointsByDayIndex).length - 1;

  const [startDate, setStartDate] = useState<Dayjs | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Dayjs | null>(initialEndDate);


  const handleDateChange = (
    date: Dayjs | null,
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) => {
    if (date) {

      const rangeDay = Math.max(0, lengthofDays);
      const endDate: Dayjs = date.add(rangeDay, 'day');

      setStartDate(date);
      setEndDate(endDate);
      onChange(date, endDate);
    }
  };

  // Disable dates beyond 14 days from the start date
  const isDateDisabled = (date: Dayjs) => {
    if (startDate) {
      return date.isAfter(startDate.add(6, 'day'));
    }
    return false;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        slots={{
          calendarHeader: CustomCalendarHeader,
          day: (dayProps) => (
            <CustomPickersDay
              {...dayProps}
              startDate={startDate}
              endDate={endDate}
            />
          ),
        }}
        // minDate={today}
        // disablePast={false}
        // shouldDisableDate={isDateDisabled} // Disable dates beyond 14 days
        value={startDate}
        className={`${classes.calendar} ${classes.weekdayLabel}`}
        onChange={(date) => handleDateChange(date, travelPointsByDayIndex)}
      />
    </LocalizationProvider>
  );
};