import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import './restartAnimatedButton.css';
import { useIsMobile } from '../counter/hooks/useMobile';

interface RestartAnimationProps {
  open: boolean;
  onClose: () => void;
}

export const RestartAnimationModal: React.FC<RestartAnimationProps> = ({
  open,
  onClose,
}) => {
  const isMobile = useIsMobile();

  return (
    <Modal
      open={open}
      onClose={onClose}
      // Use `BackdropComponent` to create a semi-transparent backdrop
      components={{
        Backdrop: () => (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent', // Semi-transparent background
              pointerEvents: 'none', // Allow clicks through the backdrop
            }}
          />
        ),
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute', // Position the modal as absolute
        zIndex: 1300, // Ensure it is above other content
        pointerEvents: 'none', // Allow clicks through the modal itself
      }}
    >
      <Box
        sx={{
          width: 'auto',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'transparent', // Transparent background for the modal content
          pointerEvents: 'auto', // Allow clicks on the modal content
        }}
      >
        <IconButton
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: '10px',
            '&:hover': {
              backgroundColor: 'lightgray',
            },
          }}
          onClick={onClose}
          className="restart-btn"
        >
          <img
            src={
              isMobile
                ? '/icons/restart-mobile-btn.svg'
                : '/icons/restart-desktop-btn.svg'
            }
            alt="Restart"
          />
        </IconButton>
      </Box>
    </Modal>
  );
};
