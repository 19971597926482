import { store } from '~/redux/store';
import { supabase } from '~/supabase/supabaseClient';
import { TravelOptions } from '~/utility/models';

export const EDITABLE_PLACES_TABLE_NAME = 'editableplaces';

/**
 * This function adds a travel to Travel History of a User in DB based on email provided
 * @param {TravelFormData[]} travelArray - Array of TravelFormData objects
 * @param {string} email - Email of the user adding Travel (Logged In User)
 * @returns Returns response from Database query
 */

interface TravelPointInterfaceForEditablePlaces
  extends Omit<
    TravelOptions,
    | 'id'
    | 'duration'
    | 'startDate'
    | 'endDate'
    | 'selectedTransport'
    | 'encodedPath'
    | 'dayIndex'
    | 'leaveAtText'
    | 'durationText'
  > {
  // uuid: string;
  userUUID: string;
}

export async function updateMultipleEditablePlaces(
  id: string | number,
  updates: Record<string, any>, // Object containing column names as keys and their corresponding values
): Promise<any | null> {
  try {
    const { data, error } = await supabase
      .from(EDITABLE_PLACES_TABLE_NAME)
      .update(updates) // Use the entire updates object
      .eq('id', id) // Match the row by ID
      .select(); // Retrieve the updated data

    if (error) {
      console.error('Error updating columns:', error.message);
      return null; // Return null on error
    }

    return data; // Return the updated data on success
  } catch (err) {
    console.error('Unexpected error:', err);
    return null; // Handle unexpected errors
  }
}

export async function updateEditablePlaces(
  id: string | number,
  columnName: string,
  columnValue: any,
): Promise<any | null> {
  try {
    let valueToUpdate;

    if (typeof columnValue === 'object' && columnValue !== null) {
      const { urlBookPlace, ...rest } = columnValue;
      valueToUpdate = rest;
    } else {
      valueToUpdate = columnValue;
    }

    const { data, error } = await supabase
      .from(EDITABLE_PLACES_TABLE_NAME)
      .update({ [columnName]: valueToUpdate })
      .eq('id', id)
      .select();

    if (error) {
      console.error('Error updating column:', error.message);
      return null;
    }

    return data;
  } catch (err) {
    console.error('Unexpected error:', err);
    return null;
  }
}

// Action creator to update P2P to History and dispatch a proper action
export async function addNewEditablePoint(travelPoint: TravelOptions) {
  const {
    id,
    duration,
    startDate,
    endDate,
    selectedTransport,
    encodedPath,
    dayIndex,
    uuid,
    urlBookPlace,
    ...travelPointObj
  } = travelPoint;

  const userUUID = store.getState().MapReducers.userID;

  const travelPointData: TravelPointInterfaceForEditablePlaces = {
    ...travelPointObj,
    placeId: travelPointObj.placeId,
    userUUID,
  };

  try {
    // save to publish travel
    const { data, error } = await supabase
      .from(EDITABLE_PLACES_TABLE_NAME)
      .insert(travelPointData)
      .select()
      .single();

    if (error) {
      console.error('Error inserting travel point:', error.message);
      return null;
    }

    return data;
  } catch (err) {
    console.error('Unexpected error:', err); // Log unexpected errors
    return null;
  }
}

export async function findRowByValue(text: string) {
  const userUUID = store.getState().MapReducers.userID;

  try {
    const { data, error } = await supabase.rpc('combined_search', {
      search_term: text,
      user_uuid: userUUID,
    });

    if (error) {
      return [];
    } else {
      return data; // Process the data
    }
  } catch (error) {
    return null;
  }
}

export interface CityInterface {
  city: string;
  country: string;
  id: number;
  lat: number;
  lng: number;
  population: number;
}

export async function findCities(text: string): Promise<CityInterface[]> {
  try {
    const { data, error } = await supabase.rpc('city_autocomplete', {
      search_term: text,
    });

    if (error) {
      console.error('Supabase RPC Error:', error.message); // Log error details for debugging
      return [];
    }

    // Add a safeguard in case `data` is not an array
    if (!Array.isArray(data)) {
      console.warn('Unexpected response format:', data);
      return [];
    }

    return data; // Successfully return the data
  } catch (error) {
    console.error('Unexpected Error:', error);
    return []; // Return a consistent fallback value
  }
}

export async function findPlaceById(id: number) {
  try {
    const { data, error } = await supabase
      .from(EDITABLE_PLACES_TABLE_NAME)
      .select()
      .eq('id', id)
      .single();

    return error ? null : data;
  } catch (error) {
    return null;
  }
}
