import React, { useEffect, useRef, useState } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import { IFrameItem } from './IframeItem/IframeItem';
import { InfoFooter } from './InfoFooter/InfoFooter';
import { DetailsItem } from './DetailsItem/DetailsItem';
import playIconNoOpacity from '~assets/playIcon-no-opacity.png';

import './TiktokItem.css';
import { ITodoTravelPoint } from '~/components/AddToTrip';
import { Box, Drawer } from '@mui/material';

export const TiktokItem = ({
  content,
  handleClose,
  goNext,
  goPrev,
  currentStoryIndex,
  isViewTravel,
  childTitle,
  point,
}: {
  content?: any;
  handleClose?: () => void;
  goNext?: (currentStoryIndex: number) => void;
  goPrev?: (currentStoryIndex: number) => void;
  currentStoryIndex: number;
  isViewTravel?: boolean;
  childTitle?: string;
  point?: ITodoTravelPoint;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSwipeUp, setIsSwipeUp] = useState(false);
  const [isHideVisible, setIsIsHideVisible] = useState(false);
  const [hideplay, setHideplay] = useState(false);

  const tiktokUrlArr = content?.media[0].url.split('/') || [];
  const videoId = tiktokUrlArr[tiktokUrlArr.length - 1];

  const isMobile = useIsMobile();

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const animatedY = useMotionValue(0);
  const opacity = useTransform(animatedY, [-100, 0, 100], [0, 1, 0]);
  const rotate = useTransform(animatedY, [-100, 100], [4, -4]);

  const handleDragEnd = () => {
    if (animatedY.get() > 30) {
      goPrev?.(currentStoryIndex);
    } else if (animatedY.get() < -30) {
      goNext?.(currentStoryIndex);
    }
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.type === 'onPlayerReady') {
        setIsIsHideVisible(true);
      }

      if (event.data.type === 'onStateChange') {
        if (event.data.value === 0) {
          setIsSwipeUp(true);
          setTimeout(() => {
            setIsSwipeUp(false);
          }, 7000);
        }
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const handleShowDetails = () => {
    if (iframeRef?.current?.contentWindow) {
      iframeRef.current!.contentWindow?.postMessage(
        { type: 'pause', 'x-tiktok-player': true },
        '*',
      );
    }

    setIsDetailsVisible(true);
    setIsPlaying(false);
  };

  const handleHideDetails = () => {
    if (iframeRef?.current?.contentWindow) {
      iframeRef.current!.contentWindow?.postMessage(
        { type: 'play', 'x-tiktok-player': true },
        '*',
      );
    }
    setIsDetailsVisible(false);
    setIsPlaying(true);
  };

  const topLinkSrc = content.media[0].url.split('/').slice(0, 4).join('/');

  const togglePlay = () => {
    setIsPlaying((prev) => !prev);
    if (!isPlaying) {
      setIsInfoFooterVisible(false);
    }

    if ((window as any)?.reactNativeWebView) {
      (window as any).reactNativeWebView?.postMessage(
        { type: isPlaying ? 'pause' : 'play', 'x-tiktok-player': true },
        '*',
      );
    }

    if (iframeRef?.current?.contentWindow) {
      iframeRef.current!.contentWindow?.postMessage(
        { type: isPlaying ? 'pause' : 'play', 'x-tiktok-player': true },
        '*',
      );
    }
  };

  const [isInfoFooterVisible, setIsInfoFooterVisible] = useState(true);

  if (!content) {
    return null;
  }

  return (
    <>
      {isViewTravel && !isMobile ? (
        <>
          <Drawer
            anchor="right"
            variant="persistent"
            open={true}
            onClose={() => { }}
            sx={{
              '& .MuiDrawer-paper': {
                backgroundColor: 'transparent',
                width: '420px',
                boxShadow: 'none',
                marginTop: '30px',
                marginBottom: '20px',
                marginLeft: '20px',
                overflow: 'hidden', // Prevents scrollbars due to background content
              },
            }}
          >
            <Box
              sx={{
                position: 'relative',
                height: '100%',
                width: '100%',
              }}
            >
              {content && handleClose && (
                <div
                  style={{
                    position: 'absolute',
                    left: '3%',
                    top: '120px',
                    color: '#fff',
                    zIndex: 9999999,
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                  }}
                >
                  {' '}
                  <ArrowBackIosNewIcon
                    fontSize="large"
                    onClick={() => handleClose?.()}
                  />
                </div>
              )}

              {isHideVisible && !isPlaying && !hideplay && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    zIndex: 999,
                    color: '#fff',
                    transform: 'translate(-40%, -50%)',
                    pointerEvents: 'auto',
                  }}
                  onClick={() => {
                    setIsPlaying(true);
                    iframeRef.current!.contentWindow?.postMessage(
                      { type: 'play', 'x-tiktok-player': true },
                      '*',
                    );
                    setHideplay(true);
                  }}
                >
                  <img
                    src={playIconNoOpacity}
                    alt="Play"
                    className="tiktok-play-button"
                  />
                </div>
              )}

              {/* Background TikTok video */}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  pointerEvents: 'auto',
                  cursor: 'pointer',
                  zIndex: 2,
                }}
                onClick={togglePlay}
              >
                <IFrameItem
                  videoId={videoId}
                  ref={iframeRef}
                  shouldCover={true}
                />
              </div>

              {/* Foreground content */}
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 2,
                  padding: 2,
                  overflowY: 'auto',
                  height: 'auto',
                  width: '100%',
                }}
              >
                <InfoFooter
                  isInfoFooterVisible={isInfoFooterVisible}
                  setIsInfoFooterVisible={setIsInfoFooterVisible}
                  isHideVisible={isHideVisible}
                  content={content}
                  showDetails={handleShowDetails}
                  playVideo={() => {
                    iframeRef.current!.contentWindow?.postMessage(
                      { type: 'play', 'x-tiktok-player': true },
                      '*',
                    );
                    setIsPlaying(true);
                    setHideplay(true);
                  }}
                  togglePlay={togglePlay}
                  isViewTravel={isViewTravel}
                  point={point}
                  childTitle={childTitle}
                />
              </Box>
            </Box>
          </Drawer>

          {isDetailsVisible && (
            <DetailsItem
              content={content}
              close={handleHideDetails}
              isViewTravel={isViewTravel}
              childTitle={childTitle}
              point={point}
            />
          )}
        </>
      ) : (
        <motion.div
          drag="y"
          dragConstraints={{
            top: 0,
            bottom: 0,
          }}
          style={{
            y: animatedY,
            opacity,
            rotate,
          }}
          className="tiktok_iframe_container"
          onDragEnd={handleDragEnd}
          onClick={togglePlay}
        >
          {!isMobile && (
            <a
              style={{
                width: '100px',
                height: '100px',
                background: 'white',
                position: 'absolute',
                zIndex: 99999999,
                top: 0,
                right: 0,
                opacity: 0,
              }}
              target="_blank"
              rel="noreferrer"
              href={topLinkSrc}
              onClick={(e) => e.stopPropagation()}
              aria-label="Open link"
            >
              {/* Provide accessible content */}
              <span style={{ visibility: 'hidden' }}>Open link</span>
            </a>
          )}
          <a
            style={{
              width: isMobile ? '100%' : '300px',
              height: isMobile ? '50px' : '100px',
              background: 'white',
              position: 'absolute',
              zIndex: 99999999,
              top: 0,
              left: 0,
              opacity: 0,
            }}
            target="_blank"
            rel="noreferrer"
            href={topLinkSrc}
            aria-label="Open link"
          >
            {/* Provide accessible content */}
            <span style={{ visibility: 'hidden' }}>Open link</span>
          </a>

          {content && handleClose && (
            <div
              style={{
                position: 'absolute',
                left: '3%',
                top: '120px',
                color: '#fff',
                zIndex: 9999999,
                cursor: 'pointer',
              }}
            >
              {' '}
              <ArrowBackIosNewIcon
                fontSize="large"
                onClick={() => handleClose?.()}
              />
            </div>
          )}

          {isHideVisible && !isPlaying && !hideplay && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 999,
                color: '#fff',
                transform: 'translate(-40%, -50%)',
              }}
              onClick={() => {
                setIsPlaying(true);
                iframeRef.current!.contentWindow?.postMessage(
                  { type: 'play', 'x-tiktok-player': true },
                  '*',
                );
                setHideplay(true);
              }}
            >
              <img
                src={playIconNoOpacity}
                alt=""
                className="tiktok-play-button"
              />
            </div>
          )}

          {isSwipeUp && (
            <div className="tiktok-item-swipeup">
              <KeyboardDoubleArrowUpIcon fontSize="large" />
              <span>Swipe Up For Next</span>
              <span>Activity</span>
            </div>
          )}

          <IFrameItem videoId={videoId} ref={iframeRef} />

          <InfoFooter
            isInfoFooterVisible={isInfoFooterVisible}
            setIsInfoFooterVisible={setIsInfoFooterVisible}
            isHideVisible={isHideVisible}
            content={content}
            showDetails={handleShowDetails}
            playVideo={() => {
              iframeRef.current!.contentWindow?.postMessage(
                { type: 'play', 'x-tiktok-player': true },
                '*',
              );
              setIsPlaying(true);
              setHideplay(true);
            }}
            togglePlay={togglePlay}
            isViewTravel={isViewTravel}
            point={point}
            childTitle={childTitle}
          />
          {isDetailsVisible && (
            <DetailsItem
              content={content}
              close={handleHideDetails}
              isViewTravel={isViewTravel}
              childTitle={childTitle}
              point={point}
            />
          )}
        </motion.div>
      )}
    </>
  );
};
