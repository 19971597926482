import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { signal } from '@preact/signals-core';
import { useSignalEffect } from '@preact/signals-react/runtime';
import './style.css';
import { useAuth } from '~/managers/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';
import Navbar from '~/components/NavbarNew';
import { showMakeYourOwn } from '../ViewTravel/MakeYourOwnModal';
import useIsAuth from '../ViewTravel/useIsAuth';
import { useNavigate } from 'react-router-dom';
import { shouldShowMakeOwnPopup } from '~/map/ViewTravel/MakeYourOwnPopup';
import subscribeSignal from '~/hooks/subscribeSignal';

export const showDrawerMenu = signal(false);

const DrawerMenu = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const { signOut } = useAuth();
  const [showDrawerMenuValue, setShowDrawerMenuValue] = useState(showDrawerMenu.peek());
  subscribeSignal(showDrawerMenu, setShowDrawerMenuValue);

  useEffect(() => {
    setIsVisible(showDrawerMenuValue);
  }, [showDrawerMenuValue]);

  const userName: string = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  const profilePictureURL: string = useSelector(
    (state: RootState) => state.MapReducers.profileImgURL,
  );
  const selectedProfilePicture = '/icons/croppedLogoVizualTravel.svg';

  const dispatch = useDispatch();

  const isAuthenticated = useIsAuth();

  const actionDelay = (action: () => void) => {
    setTimeout(() => {
      showDrawerMenu.value = false;
      action?.();
    }, 250);
  };

  const menuItems = isAuthenticated
    ? [
      {
        label: 'Home',
        action: () => {
          actionDelay(() => navigate('/home'));
        },
      },
      {
        label: 'Settings',
        action: () => {
          actionDelay(() => navigate('/settings'));
        },
      },
      {
        label: 'Give us Feedback',
        action: () => {
          actionDelay(() =>
            dispatch(ActionsCreator.setFeedbackPopupState(true)),
          );
        },
      },
      {
        label: 'Log out',
        action: () => {
          console.log(window.location.pathname);
          signOut()
            .then(() => {
              const tokenKey = process.env
                .REACT_APP_SUPABASE_TOKEN_KEY as string;
              localStorage.removeItem(tokenKey);
              dispatch(ActionsCreator.resetStateToInitial());
            })
            .catch((error) => {
              console.error(error);
            });
          if (window.location.pathname !== '/viewtravel') {
            actionDelay(() => window.location.replace('/login'));
          }
        },
      },
    ]
    : [
      {
        label: 'Generate a new Trip',
        action: () => {
          //(showMakeYourOwn.value = 'generate'));
          actionDelay(() => window.location.href = '/p2p-ai');
        },
      },
      {
        label: 'Give us Feedback',
        action: () => {
          actionDelay(() =>
            dispatch(ActionsCreator.setFeedbackPopupState(true)),
          );
        },
      },
    ];

  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            transition={{
              duration: 0.25,
              bounce: false,
            }}
            onClick={() => {
              showDrawerMenu.value = false;
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="drawer-menu-backdrop"
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            transition={{
              ease: 'easeInOut',
              duration: 0.4,
              type: 'spring',
              bounce: false,
            }}
            initial={{ x: -window.innerWidth }}
            animate={{ x: 0 }}
            exit={{ x: -window.innerWidth }}
            className="drawer-menu-container"
          >
            <Navbar
              onBackButtonClick={() => {
                showDrawerMenu.value = false;
              }}
            />

            <div
              style={{
                paddingTop: 44,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {isAuthenticated ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ borderRadius: 56 }}
                    height={56}
                    width={56}
                    src={
                      !profilePictureURL
                        ? selectedProfilePicture
                        : profilePictureURL
                    }
                    alt="profilePicture"
                  />
                  <div style={{ paddingLeft: 16 }}>
                    <span className="drawer-menu-user-name">
                      Logged in as{' '}
                      <span
                        style={{
                          fontWeight: '700',
                          textOverflow: 'ellipsis',
                          fontSize: '20px',
                        }}
                      >
                        {userName}
                      </span>
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className="drawer-menu-button"
                    onClick={() => {
                      navigate('/signup');
                      showDrawerMenu.value = false;
                    }}
                  >
                    Sign Up
                  </div>
                  <div style={{ paddingLeft: 16 }}>
                    <span className="drawer-menu-login-text">{`Already member?`}</span>
                    <a className="drawer-menu-login-text" href="/login">
                      {' '}
                      Log in
                    </a>
                  </div>
                </div>
              )}
              <div className={'drawer-menu-items'}>
                {menuItems.map((i, idx) => {
                  return (
                    <button
                      key={i.label + idx}
                      className={`${idx === 0 ? 'generate-trip-btn' : 'drawer-menu-item'
                        }`}
                      onClick={() => i.action?.()}
                    >
                      {i.label}
                    </button>
                  );
                })}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default DrawerMenu;
