import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { TravelPointsByDayIndex } from '~/utility/models';
import { store } from '~/redux/store';
import { Signal } from '@preact/signals-core';

export const useDebouncedSave = (dependencies: {
  tripTitle: string;
  isSavingToServerSignal: Signal;
  previousTravelPoints: Signal;
  previousTitle: Signal;
  saveTravelPoints: (
    travelPointsByDayIndex: TravelPointsByDayIndex,
  ) => Promise<void>;
}) => {
  const {
    tripTitle,
    isSavingToServerSignal,
    previousTravelPoints,
    previousTitle,
    saveTravelPoints,
  } = dependencies;

  // Create a ref to hold the debounced function
  const debouncedSaveRef = useRef(
    debounce(async (travelPointsByDayIndex: TravelPointsByDayIndex) => {
      const { userEmail, userID } = store.getState().MapReducers;
      console.log('here');

      if (
        !tripTitle ||
        !userEmail ||
        !userID ||
        isSavingToServerSignal.peek()
      ) {
        return;
      }

      const currentPointsString = JSON.stringify(travelPointsByDayIndex);
      const previousPointsString = JSON.stringify(previousTravelPoints.peek());

      if (
        currentPointsString !== previousPointsString ||
        tripTitle !== previousTitle.peek()
      ) {
        isSavingToServerSignal.value = true;
        previousTravelPoints.value = travelPointsByDayIndex;
        previousTitle.value = tripTitle;
        await saveTravelPoints(travelPointsByDayIndex);
        isSavingToServerSignal.value = false;
      }
    }, 100),
  );

  useEffect(() => {
    // Update the debounced function when dependencies change
    debouncedSaveRef.current = debounce(
      async (travelPointsByDayIndex: TravelPointsByDayIndex) => {
        const { userEmail, userID } = store.getState().MapReducers;

        if (
          !tripTitle ||
          !userEmail ||
          !userID ||
          isSavingToServerSignal.peek()
        ) {
          return;
        }

        const currentPointsString = JSON.stringify(travelPointsByDayIndex);
        const previousPointsString = JSON.stringify(
          previousTravelPoints.peek(),
        );

        if (
          currentPointsString !== previousPointsString ||
          tripTitle !== previousTitle.peek()
        ) {
          isSavingToServerSignal.value = true;
          previousTravelPoints.value = travelPointsByDayIndex;
          previousTitle.value = tripTitle;
          await saveTravelPoints(travelPointsByDayIndex);
          isSavingToServerSignal.value = false;
        }
      },
      100,
    );

    return () => {
      debouncedSaveRef.current.cancel();
    };
  }, [
    tripTitle,
    isSavingToServerSignal,
    previousTravelPoints,
    previousTitle,
    saveTravelPoints,
  ]);

  // Return a function that uses the ref's current value
  return (travelPointsByDayIndex: TravelPointsByDayIndex) => {
    if (travelPointsByDayIndex) {
      debouncedSaveRef.current(travelPointsByDayIndex);
    }
  };
};
