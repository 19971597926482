import { makeStyles } from '@mui/styles';
import type MuiTheme from '~/styles/theme/MuiTheme';

const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  button: {
    position: 'fixed',
    zIndex: 100,

    borderRadius: '18px',
    padding: '4px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background:
      'radial-gradient(circle at top left, #FF9345 0%, #FE7138 50%, #F25D21 100%)', // Corrected gradient
    boxShadow: `
          0 2px 6px rgba(184, 197, 208, 0.2),
          0 6px 22px rgba(184, 197, 208, 0.3)
        `,
    border: 'none',
    cursor: 'pointer',
  },

  desktopButton: {
    height: '75px',
    width: '75px',
  },

  mobileButton: {
    height: '52px',
    width: '52px',
  },

  mobileIcon: {
    width: '11px',
    height: '14px',
    display: 'block',
  },

  desktopIcon: {
    width: '28px',
    height: '36px',
    display: 'block',
  },
}));

export default useStyles;
