import { styled } from '@mui/styles';
import { Box, Button, Dialog, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarHeaderComponent } from '~/components/TravelAssistant/DatePickerComponent';
import { useEffect, useState } from 'react';

interface TitleProps {
  title: string;
}

export interface State {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  numberOfDays: number;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 361,
    padding: '20px',
    borderRadius: 30,
    // background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                  0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                  0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

const DoneButton = styled(Button)({
  width: 160,
  height: 64,
  borderRadius: 18,
  fontFamily: 'Poppins',
  fontSize: '16px',
  backgroundColor: '#fe7138',
  boxShadow: `0px 2px 6px #b8c5d033, 0px 6px 22px #b8c5d04d, 
                0px 9px 30px #c6d8e933, inset 0px 1.5px 1.5px #ffffff40`,
  '&:hover': { backgroundColor: '#e6602b' },
});

export const CalendarPopup = ({
  open,
  onClose,
  onSave,
}: {
  open: boolean;
  onClose: () => void;
  onSave: (state: State) => void;
}) => {
  const [state, setState] = useState<State | null>(null);

  const [isDateSelected, setIsDateSelected] = useState(false);

  const handleDateChange = (startDate: Dayjs | null, endDate: Dayjs | null) => {
    // Calculate duration here
    if (startDate && endDate) {
      const duration = dayjs(endDate).diff(dayjs(startDate), 'day');
      console.log(`Duration: ${duration} days`);
      setState(() => ({
        startDate,
        endDate,
        numberOfDays: duration,
      }));
      setIsDateSelected(true);
    } else if (startDate) {
      setIsDateSelected(false);
      setState(() => ({
        startDate,
        endDate: null,
        numberOfDays: 1,
      }));
      console.log('End date is not selected yet');
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <Title title="When ?" />

      <CalendarHeaderComponent onChange={handleDateChange} />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2rem',
        }}
      >
        <DoneButton
          variant="contained"
          disabled={!isDateSelected}
          onClick={() => {
            onSave(state as State);
            onClose();
          }}
        >
          Done
        </DoneButton>
      </Box>
    </StyledDialog>
  );
};

const Title: React.FC<TitleProps> = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: 'Poppins',
        fontSize: 20,
        textAlign: 'center',
        fontWeight: '700',
        mb: '1rem',
      }}
    >
      {title}
    </Typography>
  );
};

//   <DialogTitle>
//     <div
//       style={{
//         fontFamily: 'Poppins, sans-serif',
//         fontSize: '24px',
//         fontWeight: 700,
//         lineHeight: '36px',
//         textAlign: 'center',
//       }}
//     >
//       <span
//         style={{
//           color: ' #000',
//         }}
//       >
//         Change Time of{' '}
//       </span>
//       <span
//         style={{
//           color: '#ff8447',
//           textTransform: 'capitalize',
//         }}
//       >
//         {selectedLocation?.label}
//       </span>
//       <span
//         style={{
//           color: ' #000',
//         }}
//       >
//         {' '}
//         Point
//       </span>
//     </div>
//   </DialogTitle>

//   <DialogContent>
//     {selectedIndex === 0 ? (
//       <P2PTimeDisplay
//         title="Start Time"
//         selectedTime={selectedTime?.startTime}
//         handleTimeClick={() => handleTimeClick('start')}
//       />
//     ) : selectedIndex === travelPoints.length - 1 ? (
//       <P2PTimeDisplay
//         title="End Time"
//         selectedTime={selectedTime?.endTime}
//         handleTimeClick={() => handleTimeClick('end')}
//       />
//     ) : (
//       <>
//         <P2PTimeDisplay
//           title="Start Time"
//           selectedTime={selectedTime?.startTime}
//           handleTimeClick={() => handleTimeClick('start')}
//         />

//         <P2PTimeDisplay
//           title="End Time"
//           selectedTime={selectedTime?.endTime}
//           handleTimeClick={() => handleTimeClick('end')}
//         />
//       </>
//     )}
//   </DialogContent>

//   <DialogActions
//     sx={{
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'center',
//       alignItems: 'center',
//       gap: 2,
//     }}
//   >
//     <AddButton onClick={onSave} variant="contained">
//       <Typography
//         variant="body1"
//         fontWeight="bold"
//         sx={{ color: '#fff', fontFamily: 'Poppins, sans-serif' }}
//       >
//         Yes
//       </Typography>
//     </AddButton>

//     <BackButton onClick={handleClose}>
//       <Typography
//         variant="body1"
//         fontWeight="bold"
//         sx={{ color: '#FE7138', fontFamily: 'Poppins, sans-serif' }}
//       >
//         No
//       </Typography>
//     </BackButton>
//   </DialogActions>
