import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';

export const planeTransport = {
  label: 'Plane',
  icon: '/icons/blackplane.svg',
  selectIcon: '/icons/plane.svg',
};

export const carTransport = {
  label: 'Car',
  icon: '/icons/blackcar.svg',
  selectIcon: '/icons/car.svg',
};

export const transitTransport = {
  label: 'Transit',
  icon: '/icons/blacktransit.svg',
  selectIcon: '/icons/transit.svg',
};

export const ferryTransport = {
  label: 'Ferry',
  icon: '/icons/blackferry.png',
  selectIcon: '/icons/whiteferry.svg',
};

export const walkTransport = {
  label: 'Walk',
  icon: '/icons/blackwalk.svg',
  selectIcon: '/icons/walk.svg',
};

export const transportSelectorsArray = [
  {
    label: TravelMode.Plane,
    icon: '/icons/goldplane.svg',
  },
  {
    label: TravelMode.Car,
    icon: '/icons/goldcar.svg',
  },
  {
    label: TravelMode.Walk,
    icon: '/icons/goldwalk.svg',
  },

  {
    label: TravelMode.Transit,
    icon: '/icons/goldbesttransit.svg',
  },
  {
    label: TravelMode.Ferry,
    icon: '/icons/goldferry.svg',
  },
];
