import React from 'react';
import { MobileTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { Box } from '@mui/material';

interface P2PModalTimePickerProps {
  onSave: (time: Dayjs) => void;
  time: Dayjs;
  minTime?: Dayjs;
  maxTime?: Dayjs;
  handleClick: () => void;
}

export default function P2PModalTimePicker({
  onSave,
  time,
  minTime,
  maxTime,
  handleClick,
}: P2PModalTimePickerProps) {
  const handleSave = (time: Dayjs | null) => {
    if (time) onSave(time);
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileTimePicker
          value={time}
          onAccept={handleSave}
          onOpen={handleClick}
          minTime={minTime}
          maxTime={maxTime}
          ampm
        />
      </LocalizationProvider>
    </Box>
  );
}
