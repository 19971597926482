import React, { useEffect, useRef } from 'react';
import HomeSection from './Home/HomeSection';
import AboutSection from './About/AboutSection';
import Footer from './Footer';
import HowWorksSection from './HowWorksSteps/HowWorksSection';
import Navbar from './Navbar/Navbar';
import TravelPlanSection from './TravelPlan/TravelPlanSection';
import useStyles from './styles';
import { LiveAgentChat } from '../LiveAgentChat';

/**
 * LandingPage component - renders the landing page including
 * Navbar, HomeSection, AboutSection, HowWorksSection, TravelPlanSection, and Footer.
 *
 * @returns {JSX.Element} - Rendered component.
 */
const LandingPage = () => {
  const mainContainerRef = useRef<HTMLDivElement | null>();
  const homeRef = useRef<HTMLElement | null>(null);
  const aboutRef = useRef<HTMLElement | null>(null);
  const featuresRef = useRef<HTMLElement | null>(null);
  const testimonialsRef = useRef<HTMLElement | null>(null);
  const pricingRef = useRef<HTMLElement | null>(null);
  const classes = useStyles();

  useEffect(() => {
    return () => {
      mainContainerRef.current = null;
      homeRef.current = null;
      aboutRef.current = null;
      featuresRef.current = null;
      testimonialsRef.current = null;
      pricingRef.current = null;
    };
  }, []);


  const getLiveChatAgent=()=>{
    if(process.env.REACT_APP_OPENREPLAY_PROJECT_KEY){
      return <LiveAgentChat/>
    }
  }

  return (
    <>
      {
        getLiveChatAgent()
      }
      <div
        ref={mainContainerRef as React.MutableRefObject<HTMLDivElement>}
        className={classes.pageWrapper}
      >
        <Navbar
          homeRef={homeRef}
          aboutRef={aboutRef}
          featuresRef={featuresRef}
          testimonialsRef={testimonialsRef}
          pricingRef={pricingRef}
          mainContainerRef={
            mainContainerRef as React.MutableRefObject<HTMLDivElement>
          }
        />
        <HomeSection
          mainContainerRef={
            mainContainerRef as React.MutableRefObject<HTMLDivElement>
          }
          homeRef={homeRef}
        />
        <AboutSection aboutRef={aboutRef} />
        <HowWorksSection featuresRef={featuresRef} />
        <TravelPlanSection />
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
