import { CatmullRomCurve3, Mesh } from 'three';
import MapLibreGL, {
  GeoJSONSource,
  LngLatLike,
  Map,
  Marker,
} from 'maplibre-gl';
import {
  PublishableTravelDataWithDecodedPath,
  TravelFormData,
} from '~/utility/models';
import { generate3DLine } from '../utility/utils';
import { unprojectFromWorld } from '~/CustomThreeJsWrapper/utility/utils';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import {
  along,
  distance,
  length,
  lineString,
  point,
  Position,
} from '@turf/turf';
import { TravelMode } from '../utility/enums/TravelMode';
import { MultiStaticVisualizer } from './MultiStaticVisualizer';
import { MonoStaticVisualizer } from './MonoStaticVisualizer';
import { showPlayPauseButton } from '~/components/ViewTravel/common';
import { getMarkerIcon } from './getCustomIcon';
import dayjs, { Dayjs } from 'dayjs';
import {
  getIconColor,
  determineMarkerType,
  getDefaultMarkerSvgString,
  getTravelIdArray,
} from '~/utility/utils';

class StaticTravelVisualizer {
  markers: Marker[] = [];
  pathMeshes: Mesh[] = [];
  tb!: CustomThreeJSWrapper;
  map!: Map;
  visualizePath: boolean = false;
  shouldAnimatePath: boolean = false;
  selectedTransport: string = ''; // Add the actual type for selectedTransport
  pathCurve!: CatmullRomCurve3;
  cameraMovingToOrigin: boolean | undefined;
  addedTravelIDs: string[] = [];
  devMode = true;
  showOriginStraightLine: boolean = false;
  showDestinationStraightLine: boolean = false;
  staticVisualizer!: (MonoStaticVisualizer | MultiStaticVisualizer)[];
  firstOriginPoint: number[] = [];

  constructor(map: Map, tb: CustomThreeJSWrapper) {
    if (!map) return;
    this.map = map;
    this.tb = tb;
    this.cameraMovingToOrigin = false;
    this.addedTravelIDs = [];
    this.staticVisualizer = [];
  }

  calculateBearing(
    originLngLat: Position,
    destinationLngLat: Position,
  ): number {
    const originLat = originLngLat[1];
    const originLng = originLngLat[0];
    const destLat = destinationLngLat[1];
    const destLng = destinationLngLat[0];

    // Convert latitude and longitude from degrees to radians
    const lat1 = (originLat * Math.PI) / 180;
    const lon1 = (originLng * Math.PI) / 180;
    const lat2 = (destLat * Math.PI) / 180;
    const lon2 = (destLng * Math.PI) / 180;

    // Calculate the difference in longitude
    const deltaLon = lon2 - lon1;

    // Calculate the bearing using the atan2 function
    const y = Math.sin(deltaLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltaLon);
    let initialBearing = Math.atan2(y, x);

    // Convert the bearing from radians to degrees
    initialBearing = (initialBearing * 180) / Math.PI;

    // Normalize the bearing to be in the range [0, 360)
    if (initialBearing < 0) {
      initialBearing += 360;
    }

    return initialBearing;
  }

  createCustomMarker(
    coordinates: [number, number],
    index: number,
    category: string,
    type: 'origin' | 'destination',
    travelData: PublishableTravelDataWithDecodedPath,
    travelArray: PublishableTravelDataWithDecodedPath[],
    handleMarkerClick: {
      (
        travelArray: TravelFormData[],
        index: number,
        currentMarker: string | null,
        wholeTravelArr: PublishableTravelDataWithDecodedPath[],
      ): void;
    },
    isMarkerNameVisible: boolean = true,
    indexOfMarker: number,
  ) {
    
    const arrayIds = getTravelIdArray(travelArray);
    const getImageInfo = () => {
      let image: string | undefined, dataType: 'tiktok' | 'media' | undefined;
      switch (type) {
        case 'origin':
          if (travelData.departure.tiktokData?.length) {
            dataType = 'tiktok';
            image = travelData.departure.tiktokData[0].thumbnail;
          } else if (travelData.departure.media?.length) {
            dataType = 'media';
            image = travelData.departure.media[0].thumbnail;
          } else {
            dataType = undefined;
            image = undefined;
          }
          break;

        case 'destination':
          if (travelData.arrival.tiktokData?.length) {
            dataType = 'tiktok';
            image = travelData.arrival.tiktokData[0].thumbnail;
          } else if (travelData.arrival.media?.length) {
            dataType = 'media';
            image = travelData.arrival.media[0].thumbnail;
          } else {
            dataType = undefined;
            image = undefined;
          }
          break;
        default:
          break;
      }
      return { image, dataType };
    };
    const imageInfo = getImageInfo();

    const customIcon = `
    <div style="display: flex; flex-direction: column; align-items: center; position: relative">
    ${getMarkerIcon({
      category,
      indexOfMarker,
      travelArrayIds: arrayIds,
      imageLink: imageInfo.image,
    })}
    
      ${
        isMarkerNameVisible
          ? `<span style="
          background-color: ${getIconColor(category)};
          color: white;  
          font-size: 12px; 
          font-weight: 600; 
          height: 19px;
          padding: 2px 4px; 
          border-radius: 12px; 
          line-height: 15px;
          max-width: 150px; 
          text-align: center;   
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          position: absolute;
          bottom: -22px;
          font-family: 'poppins';
          ">
            ${
              travelData[type === 'origin' ? 'departure' : 'arrival'].location
                ?.label
            }
        </span>`
          : ''
      }
      </div>`;
    const customMarker = document.createElement('div');
    customMarker.innerHTML = customIcon;
    const anchor = 'bottom'; // Replace with actual values
    const marker = new MapLibreGL.Marker({
      element: customMarker,
      anchor: anchor,
    })
      .setLngLat(coordinates)
      .addTo(this.map);
    let originDataArray: any = [];
    let markerIndex: any;
    if (type === 'origin') {
      if (index > 0) {
        originDataArray = [travelArray[index - 1], travelData];
        markerIndex = index;
      } else {
        // For i == 0, store travelData as-is
        originDataArray = [travelData];
        markerIndex = index;
      }
    } else {
      originDataArray = [travelData];
      markerIndex = index;
    }

    marker.getElement().addEventListener('click', () => {
      handleMarkerClick?.(
        originDataArray,
        markerIndex,
        type,
        travelArray,
      );
    });
    // sync error handler, if image not available - we use default icons
    document.addEventListener(
      'error',
      function (event) {
        const target = event.target as HTMLLinkElement;

        if (target && target.id.startsWith('imageThumbnail')) {
          const category = target.getAttribute('data-category');
          const indexOfMarker = target.id.replace('imageThumbnail', '');
          const svgParent = this.getElementById(`svgThumbnail${indexOfMarker}`);

          if (category && svgParent) {
            const markerType = determineMarkerType(category);
            const defaultIcon = getDefaultMarkerSvgString(markerType).replace(
              `viewBox="27 21.75 45 45"`,
              `viewBox="0 -1 50 60"`,
            );
            svgParent.innerHTML = defaultIcon;
          }
        }
      },
      true,
    );

    return marker;
  }

  generateMiddleIcon = (
    selectedTransport: string,
    departure?: PublishableTravelDataWithDecodedPath['departure']['dateTime'],
    arrival?: PublishableTravelDataWithDecodedPath['arrival']['dateTime'],
    travelDuration?: number,
  ) => {
    let middleImgSrc = '';
    switch (selectedTransport) {
      case TravelMode.Plane:
        middleImgSrc = './icons/planeTransport-new.png';
        break;

      case TravelMode.Car:
        middleImgSrc = './icons/carTransport-new.png';
        break;

      case TravelMode.Transit:
        // Assuming there's an icon for Transit transport
        middleImgSrc = './icons/bestTransitTrain-new.png';
        break;

      case TravelMode.Walk:
        // Assuming there's an icon for walk transport
        middleImgSrc = './icons/walkTransport-new.png';
        break;

      case TravelMode.Ferry:
        middleImgSrc = './icons/ferryTransport-new.png';
        break;
    }

    let duration: plugin.Duration;

    if (travelDuration) {
      duration = dayjs.duration(travelDuration, 'minutes');
    } else {
      duration = dayjs.duration(
        dayjs(String(arrival)).diff(dayjs(String(departure))),
      );
    }

    const durationString = `${
      duration.asDays() > 1 ? Math.floor(duration.asDays()) + 'd' : ''
    } ${duration.hours() > 0 ? duration.hours() + 'h' : ''} ${
      duration.minutes() > 0 ? duration.minutes() + 'm' : '0m'
    }`;

    return `
    <div style="position: relative; display: flex; flex-direction: column; align-items: center; width: 100%; top:-15px" >
      <div style="
      display: flex; 
      flex-direction: row; 
      align-items: center; 
      justify-content:space-between; 
      background-color: white; 
      padding: 1px; 
      border-radius: 20px; 
      height: 22px;
      font-family: 'poppins';
      ">
        <img style="height: 20px; width: 20px" src=${middleImgSrc}></img>
        ${
          durationString
            ? `<p style="position:relative; font-size:12px; font-weight: 600; margin: 5px; width: 100%;">${durationString}</p>`
            : ''
        }
      </div>
        <div style="
          width: 0; 
          height: 0; 
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8px solid #fff;
      "/>
      </div>
      `;
  };

  createMiddleMarker(
    middlePointlngLat: Position,
    travelData: PublishableTravelDataWithDecodedPath,
    bearing: number,
  ): void {
    let middleIcon = this.generateMiddleIcon(
      travelData.selectedTransport,
      travelData.departure.dateTime,
      travelData.arrival.dateTime,
      travelData.duration,
    );

    const middleMarker = document.createElement('div');
    middleMarker.innerHTML = middleIcon as string;
    middleMarker.classList.add('custom-popup');
    middleMarker.classList.add('middleMarker');

    const marker = new MapLibreGL.Marker({ element: middleMarker })
      .setLngLat(middlePointlngLat as LngLatLike)
      .addTo(this.map);

    //Add the marker in this array so that we can delete them on the next click.
    this.markers.push(marker);
  }

  clearTravel(): void {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].remove();
    }
    this.markers.length = 0;

    for (let i = 0; i < this.staticVisualizer.length; i++) {
      this.staticVisualizer[i].clearTravels();
    }
  }

  removeTravelLayerSource(id: string) {
    if (this.map.getLayer(id)) {
      this.map.removeLayer(id);
    }

    if (this.map.getSource(id)) {
      this.map.removeSource(id);
    }
  }

  addTravelLayer(id: string, obj: Position[]) {
    if (!this.map.getSource(id)) {
      this.map.addSource(id, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: obj,
          },
        },
      });
    }

    if (!this.map.getLayer(id)) {
      // Check to add layer for dottedLine for straightline
      if (id.includes('straightline')) {
        this.map.addLayer({
          id: id,
          type: 'line',
          source: id,
          paint: {
            'line-color': '#FE7138',
            'line-width': 3,
            'line-dasharray': [0, 0.5, 1],
          },
        });
      } else {
        // Layer for solid line other than dotted line
        this.map.addLayer({
          id: id,
          type: 'line',
          source: id,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#FE7138',
            'line-width': 3,
          },
        });
      }
    }

    if (!this.addedTravelIDs.includes(id)) {
      this.addedTravelIDs.push(id);
    }
  }

  removeTravelLayer(i: number) {
    let id = 'static-route' + i;

    if (this.map.getLayer(id)) {
      this.map.removeLayer(id);
    }
    if (this.map.getSource(id)) {
      this.map.removeSource(id);
    }

    const index = this.addedTravelIDs.indexOf(id);
    if (index !== -1) {
      this.addedTravelIDs.splice(index, 1);
    }
  }

  addLayers(travelData: Position[], id: string) {
    // console.log(id, travelData);
    // this.addTravelLayer(id, travelData);
  }

  addTravelLineLayer(
    travelArray: PublishableTravelDataWithDecodedPath,
    i: number,
  ) {
    if (travelArray.selectedTransport === TravelMode.Ferry) {
      if (travelArray.decodedPath.data.length === 0) {
        // When StraightLine Ferry Path between Origin & Destination, use decodedPath.path
        let id = `static-route-${i}`;
        this.addTravelLayer(id, travelArray.decodedPath.path);
      } else {
        // When Single or MultiFerry Path
        travelArray.decodedPath.data.forEach((data, index) => {
          // RF: Condition not requried here
          let id = 'static-route-' + i;
          if (data.maneuver === 'ferry' || data.maneuver === 'straightline') {
            // Modify id for ferry paths
            id = id + `-${data.maneuver}-${index}`;
            this.addTravelLayer(id, data.path);
          }
        });
      }
    } else {
      let id = `static-route-${i}`;
      this.addTravelLayer(id, travelArray.decodedPath.path);
    }
  }

  async redrawTravelLineLayer() {
    for (let i = 0; i < this.staticVisualizer.length; i += 1) {
      this.staticVisualizer[i].visualize();
    }
  }

  addLineLayerSource(id: string, path: Position[]) {
    const existingSource = this.map.getSource(id);

    if (existingSource) {
      // Update the source data if it existss
      (existingSource as MapLibreGL.GeoJSONSource).setData({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: path,
        },
      });
    } else {
      this.map.addSource(id, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: path,
          },
        },
      });

      this.map.addLayer({
        id: id,
        type: 'line',
        source: id,
        paint: {
          'line-color': '#FE7138',
          'line-width': 3,
          'line-dasharray': [2, 2],
        },
      });
    }
  }

  showNoTransportationPart(locationPoint: Position, pathPoint: Position) {
    let showNoTransportationParts = true;

    const point1 = point(locationPoint);
    const point2 = point(pathPoint);

    const distanceBetweenPoints = distance(point1, point2);

    if (distanceBetweenPoints === 0) {
      showNoTransportationParts = true;
    } else {
      showNoTransportationParts = false;
    }

    return showNoTransportationParts;
  }

  /**
   * Add or updates the PreFerry & PostFerry line source and layers
   * @param travelData publishable traveldata object with decoded path
   * @param routeIndex route index of travel
   */
  addPrePostFerryPathLayers(
    travelData: PublishableTravelDataWithDecodedPath,
    routeIndex: number,
  ) {
    let preFerryPathId = 'preFerryPath-' + routeIndex;
    let postFerryPathId = 'postFerryPath-' + routeIndex;

    const preFerryPathStart = travelData.departure.location?.coordinates;
    const preFerryPathEnd = travelData.decodedPath.path[0];

    const postFerryPathStart =
      travelData.decodedPath.path[travelData.decodedPath.path.length - 1];
    const postFerryPathEnd = travelData.arrival.location?.coordinates;

    const preFerryRouteSource = this.map.getSource(
      preFerryPathId,
    ) as GeoJSONSource;
    const postFerryRouteSource = this.map.getSource(
      postFerryPathId,
    ) as GeoJSONSource;

    // Add Source and Layer for Pre-Ferry Path
    if (!preFerryRouteSource) {
      this.map.addSource(preFerryPathId, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [
              preFerryPathStart as Position,
              preFerryPathEnd as Position,
            ],
          },
        },
      });
    } else {
      // Update Source
      preFerryRouteSource?.setData({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [
            preFerryPathStart as Position,
            preFerryPathEnd as Position,
          ],
        },
      });
    }

    // Add Source and Layer for Pre-Ferry Path
    if (!postFerryRouteSource) {
      this.map.addSource(postFerryPathId, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [
              postFerryPathStart as Position,
              postFerryPathEnd as Position,
            ],
          },
        },
      });
    } else {
      postFerryRouteSource?.setData(
        lineString([
          postFerryPathStart as Position,
          postFerryPathEnd as Position,
        ]),
        // {
        // type: 'Feature',
        // properties: {},
        // geometry: {
        //   type: 'LineString',
        //   coordinates: [
        //     postFerryPathStart as Position,
        //     postFerryPathEnd as Position,
        //   ],
        // },
        // }
      );
    }

    if (!this.map.getLayer(preFerryPathId)) {
      this.map.addLayer({
        id: preFerryPathId,
        type: 'line',
        source: 'preFerryPath-' + routeIndex,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FE7138',
          'line-width': 3,
          'line-dasharray': [2, 2],
        },
      });

      if (!this.addedTravelIDs.includes(preFerryPathId)) {
        this.addedTravelIDs.push(preFerryPathId);
      }
    }

    if (!this.map.getLayer(postFerryPathId)) {
      this.map.addLayer({
        id: postFerryPathId,
        type: 'line',
        source: 'postFerryPath-' + routeIndex,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FE7138',
          'line-width': 3,
          'line-dasharray': [2, 2],
        },
      });

      if (!this.addedTravelIDs.includes(postFerryPathId)) {
        this.addedTravelIDs.push(postFerryPathId);
      }
    }
    this.showOriginStraightLine = this.showNoTransportationPart(
      travelData.departure.location?.coordinates as Position,
      travelData.decodedPath.path[0],
    );

    this.showDestinationStraightLine = this.showNoTransportationPart(
      travelData.arrival.location?.coordinates as Position,
      travelData.decodedPath.path[travelData.decodedPath.path.length - 1],
    );

    if (!this.showOriginStraightLine) {
      this.removeTravelLayerSource('originNoTransportationStatic');
    }

    if (!this.showOriginStraightLine) {
      this.removeTravelLayerSource('destinationNoTransportationStatic');
    }

    this.map.repaint = true;
  }

  findMiddlePoint(
    travelData: PublishableTravelDataWithDecodedPath,
  ): Position | null {
    let allPoints: Position[] = [];

    // console.log(travelData);

    // console.log(allPoints);

    const line = lineString(allPoints);
    const dist = length(line);

    // console.log('dist', dist);

    const midPoint = along(line, dist / 2).geometry.coordinates;

    // console.log('midPoint', midPoint);

    return midPoint;
  }

  getMiddleAndLastPoints(travelData: PublishableTravelDataWithDecodedPath): {
    lastPointLngLat: Position;
    middlePointLngLat: Position;
  } {
    try {
      let allPoints: Position[] = [];

      if (travelData?.decodedPath?.data?.length > 0) {
        const paths = travelData?.decodedPath?.data;
        for (const path of paths) {
          allPoints.push(...path.path);
        }
      } else if (travelData?.decodedPath?.path.length > 0) {
        allPoints = travelData?.decodedPath?.path;
      }

      const { pathCurve } = generate3DLine(
        allPoints,
        this.selectedTransport,
        this.tb,
        this.shouldAnimatePath,
      );

      const lastPointLngLat = unprojectFromWorld(pathCurve.getPointAt(0.98));

      const middlePointLngLat = unprojectFromWorld(pathCurve.getPointAt(0.5));

      return { lastPointLngLat, middlePointLngLat } as {
        lastPointLngLat: Position;
        middlePointLngLat: Position;
      };
    } catch (e) {
      // console.error('Error in getMiddleAndLastPoints', e);
      return { lastPointLngLat: [0, 0], middlePointLngLat: [0, 0] };
    }
  }

  async visualizeTravel(
    travelArray: PublishableTravelDataWithDecodedPath[],
    handleMarkerClick?: (
      travelArray: TravelFormData[],
      index: number,
      currentMarker: string | null,
    ) => void,
    date?: Dayjs,
  ) {
    // const isMobile = window.innerWidth <= 1025;
    this.clearTravel();
    let markersPoints: GeoJSON.Feature[] = [];
    // console.log('travelArray in STATIC TRAVE VISUALIZER', travelArray);
    const dayTravel = date
      ? travelArray.filter((i) => {
          //@ts-ignore
          return (
            dayjs(String(i.departure.dateTime))
              .tz(i.departure.timezone)
              .format('DD-MM-YYYY') === date.format('DD-MM-YYYY')
          );
        })
      : travelArray;

    const isFullTravel = !date && dayTravel.length === travelArray.length;

    const isArrIncludesValue = (mainArr: any, arrToFind: any) => {
      return mainArr.some((a: any) =>
        (arrToFind as [number, number])!.every((v, i) => v === a[i]),
      );
    };

    for (let i = 0; i < dayTravel.length; i++) {
      const travelData = dayTravel[i];
      const repeatedOriginCoordinates = [];
      const repteatedDestinationCoordinates = [];
      const origins = [];
      const destinations = [];

      let coordsCount = 0;

      for (let j = 0; j < dayTravel.length; j++) {
        const travelDatax = dayTravel[j];
        const origin = travelDatax?.departure?.location?.coordinates;
        const destination = travelDatax?.arrival.location?.coordinates;

        if (coordsCount < dayTravel.length) {
          if (!isArrIncludesValue(repeatedOriginCoordinates, origin)) {
            repeatedOriginCoordinates.push(origin);
          } else {
            origins.push(origin as [number, number]);
          }

          if (
            !isArrIncludesValue(repteatedDestinationCoordinates, destination)
          ) {
            repteatedDestinationCoordinates.push(destination);
          } else {
            destinations.push(destination as [number, number]);
          }
        }
        coordsCount++;
      }

      this.firstOriginPoint = dayTravel[0]?.departure?.location
        ?.coordinates as [number, number];

      const coordinates = {
        origin: travelData?.departure?.location?.coordinates,
        destination: travelData?.arrival?.location?.coordinates,
      };

      const { lastPointLngLat, middlePointLngLat } =
        this.getMiddleAndLastPoints(travelData);

      if (travelData?.decodedPath?.data.length > 0) {
        this.staticVisualizer[i] = new MultiStaticVisualizer(
          this.map,
          i,
          this.tb,
          travelData,
        );
      } else if (travelData?.decodedPath?.path.length > 0) {
        this.staticVisualizer[i] = new MonoStaticVisualizer(
          this.map,
          i,
          this.tb,
          travelData,
        );
      }

      markersPoints.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coordinates.origin as [number, number],
        },
        properties: {
          type: travelData.departure.category,
          index: i,
          mark: 'origin',
        },
      });

      if (i + 1 === dayTravel.length) {
        markersPoints.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: coordinates.destination as [number, number],
          },
          properties: {
            type: travelData.arrival.category,
            index: i,
            mark: 'destination',
          },
        });
      }
      if (!isFullTravel && !showPlayPauseButton.peek())
        this.staticVisualizer[i].visualize();

      const bearing = this.calculateBearing(
        lastPointLngLat as Position,
        coordinates.destination as Position,
      );

      // if departure and arrival are same, then show only one marker
      if (
        (coordinates.origin as [number, number]).toString() !==
        (coordinates.destination as [number, number]).toString()
      ) {
        if (!isFullTravel && !showPlayPauseButton.peek())
          this.createMiddleMarker(
            middlePointLngLat as Position,
            travelData,
            bearing,
          );
      }

      const originMarker = this.createCustomMarker(
        coordinates.origin as [number, number],
        i,
        travelData.departure.category,
        'origin',
        travelData,
        dayTravel,
        handleMarkerClick!,
        true,
        i,
      );

      this.markers.push(originMarker);
      if (i + 1 === dayTravel.length) {
        const destinationMarker = this.createCustomMarker(
          coordinates.destination as [number, number],
          i,
          travelData.arrival.category,
          'destination',
          travelData,
          dayTravel,
          handleMarkerClick!,
          true,
          dayTravel.length,
        );
        this.markers.push(destinationMarker);
      }
      this.shouldAnimatePath = false;
      this.visualizePath = true;
    }
  }
}

export { StaticTravelVisualizer };
