import { TravelAnimation } from '../TravelAnimation';
import { EasingFunctions } from '../../utility/easing';
import { State } from '../models';
import { LngLatLike, Map } from 'maplibre-gl';
import { Clock } from 'three';
import { Marker } from '~/animationEngine/Marker';
import { MarkerInstances } from '~/utility/models';
import { distance, point, Position } from '@turf/turf';
import { toggleMapInteractivity } from '~/map/helpers';
import { mapOffset } from '~/components/ViewTravel/MobileFooter/BottomSheet';

/**
 * This class represents the state where the camera is animated to the origin of the travel animation.
 */
export class AnimateCameraToOrigin implements State {
  private stateMachine: TravelAnimation;
  map: Map;
  private clock = new Clock();

  animationStartTime = 0;
  animationDuration = 1000; // Duration of the flyTo animation in milliseconds
  remainingTime = this.animationDuration;

  private markers: MarkerInstances | undefined;
  isPaused = false;
  timeElapsed: number = 0;

  /**
   * @constructor
   * @param stateMachine - A reference to the TravelAnimation state machine.
   * @param map - A reference to the Maplibre GL map object.
   */
  constructor(stateMachine: TravelAnimation, map: Map) {
    this.stateMachine = stateMachine;
    this.map = map;
    // Binding `this.setState` to `this` ensures that the same function instance is used
    // when adding and removing event listeners. This is important because without binding,
    // each reference to `this.setState` would create a new function instance, making it
    // impossible to correctly add and remove event listeners for the same function.
    this.setState = this.setState.bind(this);
  }

  flyToOrigin() {
    const animationStartCenter =
      this.stateMachine.getCurrentSegment()?.departure.location?.coordinates;

    const animationStartZoom = this.stateMachine
      .getCurrentController()
      ?.getAnimationStartZoom();

    this.animationStartTime = performance.now();

    this.map.flyTo({
      center: animationStartCenter as LngLatLike,
      bearing: this.stateMachine.mapBearing,
      pitch: this.stateMachine.mapPitch,
      zoom: animationStartZoom,
      duration: this.remainingTime,
      easing: EasingFunctions.linear,
      essential: true,
      offset: mapOffset.value,
    });
    toggleMapInteractivity(this.map, true);
  }

  /**
   * Called when the state is entered.
   *
   * Animates the map camera to the origin of the current travel segment.
   */
  onEnter() {
    if (this.stateMachine.devMode)
      console.log('Entering AnimateCameraToOrigin state');

    this.flyToOrigin();
    // this.map.once('moveend', this.setState);

    this.markers = this.stateMachine.getCurrentController()?.markers;
  }

  setShowOriginMarker() {
    let showMarker = true;

    if (
      this.stateMachine.currentSegmentIndex > 0 &&
      this.stateMachine.currentSegmentIndex <
        this.stateMachine.animationTravelData.length
    ) {
      let currentPoint = this.stateMachine.getCurrentSegment();
      let prevPointPath =
        this.stateMachine.animationTravelData[
          this.stateMachine.currentSegmentIndex - 1
        ];

      const point1 = point(
        currentPoint.departure.location?.coordinates as Position,
      );
      const point2 = point(
        prevPointPath.arrival.location?.coordinates as Position,
      );
      const distanceBetweenPoints = distance(point1, point2);

      if (distanceBetweenPoints > 0) {
        showMarker = true;
      } else {
        showMarker = false;
      }
    } else if (this.stateMachine.currentSegmentIndex === 0) {
      showMarker = true;
    }

    return showMarker;
  }

  /**
   * Callback function called when the map finishes moving.
   *
   * Transitions the state machine to the 'animateOriginMarker' state.
   */
  setState() {
    this.stateMachine.showMarker = this.setShowOriginMarker();
    this.stateMachine.setState(this.stateMachine.states.animateOriginMarker);
  }

  onUpdate() {
    const delta = this.clock.getDelta();
    for (const controller of this.stateMachine.animationControllers) {
      this.markers = controller?.markers;
      for (const markerInstance of Object.values(
        this.markers as MarkerInstances,
      )) {
        (markerInstance as Marker).update(delta);
      }
    }

    const elapsedTime = performance.now() - this.animationStartTime;
    // Check if the animation duration has passed
    if (elapsedTime >= this.remainingTime) {
      this.setState();
    }
  }

  /**
   * Called when the state is exited.
   *
   * Removes the event listener for the 'moveend' map event.
   */
  onExit() {
    if (this.stateMachine.devMode)
      console.log('Exiting AnimateCameraToOrigin state');
    this.map.off('moveend', this.setState);
  }

  onPause() {
    this.isPaused = true;

    //The time at which the pause is clicked
    this.timeElapsed = performance.now() - this.animationStartTime;

    this.stateMachine.map.stop();
  }

  onPlay() {
    this.isPaused = false;
    this.stateMachine.map.repaint = true;

    this.remainingTime = this.animationDuration - this.timeElapsed;

    this.flyToOrigin();
  }
}
