import React, { useState } from 'react';
import './style.css';
import { PublishableTravelData } from '~/utility/models';

import { uniqBy } from 'lodash';
import { motion, AnimatePresence } from 'framer-motion';
import { isShareModalVisible } from '../StatsOverlay';
import { Alert, Snackbar } from '@mui/material';
import ModalComponent from '~/components/ModalComponent';
import { shouldShowMakeOwnPopup } from '~/map/ViewTravel/MakeYourOwnPopup';
import dayjs from 'dayjs';

const ShareModal = ({
  travelPoints,
}: {
  travelPoints: PublishableTravelData[];
}) => {
  const days = uniqBy(
    travelPoints.map((i) =>
      dayjs(String(i.departure.dateTime)).tz(i.departure.timezone),
    ),
    (i) => i.format('DD-MM-YYYY'),
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    setSnackbarOpen(true);
  };

  return (
    <ModalComponent
      onBackdropClick={() => {
        isShareModalVisible.value = false;
      }}
    >
      <>
        <span className="share-modal-header-text">
          Share this{' '}
          {`${days.length} Day${days.length > 1 ? 's' : ''} in ${travelPoints[5]?.arrival?.location?.city ||
            travelPoints[0]?.arrival?.location?.city ||
            travelPoints[0]?.arrival?.location?.country
            }`}{' '}
          Trip Plan
        </span>
        <div
          style={{
            width: '90%',
            display: 'flex',
            flexDirection: 'row',
            height: 48,
            border: '1px solid #34434B26',
            borderRadius: 4,
            padding: 14,
            overflow: 'hidden',
            alignItems: 'center',
          }}
          onClick={handleCopy}
        >
          <span className="share-modal-link">{window.location.href}</span>
          <img alt='' src={'./icons/clipboard.png'} width={16} height={16} />
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={() => setSnackbarOpen?.(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{
            zIndex: '1500 !important',
          }}
        >
          <Alert variant="filled" severity="success">
            Copied Successfully
          </Alert>
        </Snackbar>

        <motion.div
          className="share-modal-button"
          onClick={handleCopy}
          animate={{
            scale: 1.05,
          }}
          exit={{ scale: 1 }}
          initial={{ scale: 1 }}
          transition={{
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 1,
            ease: 'easeInOut',
          }}
        >
          Copy Link
        </motion.div>
        <span
          className="share-modal-goback"
          onClick={() => {
            isShareModalVisible.value = false;
          }}
        >
          Go back
        </span>
      </>
    </ModalComponent>
  );
};

export default ShareModal;
