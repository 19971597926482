import { makeStyles } from '@mui/styles';
import type MuiTheme from '~/styles/theme/MuiTheme';

const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  dialog: {
    maxWidth: '400px',
    borderRadius: '30px',
    padding: '20px',
    background: 'linear-gradient(to bottom, #EEF3F7, #FFFFFF)',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  fieldBox: {
    width: '100%',
    borderRadius: '10px',
    fontFamily: 'Poppins',
    fontSize: '15px',
    color: '#B1B1B1',
    backgroundColor: '000000',
  },
  dialogContent: {
    width: '300px',
    height: '100%',
    display: 'flex',
    padding: '0px',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '17px',
    color: '#454953',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '22px',
    padding: '8px',
  },
  dialogButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '10px',
  },
  dialogButton: {
    textTransform: 'none',
    borderRadius: '14px',
    width: '55%',
    height: '65px',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: 700,
    boxShadow: 'none',
  },
  dialogConfirmButton: {
    backgroundColor: '#FE7138',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#E46532 ',
    },
  },
  dialogCancelButton: {
    backgroundColor: '#ECECED',
    color: '#000',
    marginBottom: '10px',
  },
  '@media (max-width: 768px)': {
    dialog: {
      minWidth: '310px',
    },
  },
  [theme.breakpoints.between(150, 450)]: {
    dialogCancelButton: {
      width: '100%',
    },
    dialogConfirmButton: {
      width: '100%',
    },
  },
}));
export default useStyles;
