import { currentTravelTypeSignal, handleAnimationState } from '~/components/ViewTravel/common';
import { LandTransportAnimationConfig } from '../AnimationController';
import { CarAnimationController } from '../CarAnimationController';
import {
  Config,
  MultiTransportStates,
  MultiTransportAnimationController,
} from '../MultiTransportAnimationController';

export class SubwayState implements MultiTransportStates {
  private controller: any;

  constructor(
    private map: any,
    private index: number,
    private tb: any,
    private stateMachine: MultiTransportAnimationController,
    private config: Config,
  ) {
    this.controller = new CarAnimationController(this.map, this.index, this.tb);
    this.controller.setup(this.config, this.index, false);
  }

  onEnter(): void {
    this.startAnimation();
  }

  getTotalTime(): number {
    return this.controller.totalTime * 1000;
  }

  onReset() {
    if (this.controller) {
      this.controller.cleanup();
    }
  }

  onCleanup() {
    if (this.controller) {
      this.controller.cleanup();
    }
  }

  setLineLayerAndSources() {
    this.controller.setLineLayerAndSources();
  }

  onExit(): void {
    // Cleanup or transition logic here
    // if (this.controller) {
    //   this.controller.cleanup();
    // }
  }

  onPause() {
    if (this.controller) {
      this.controller.onPause();
    }
  }

  onPlay() {
    if (this.controller) {
      this.controller.onPlay();
    }
  }

  onUpdate(delta: number): void {
    this.controller.update(delta);
  }

  startAnimation(): void {
    currentTravelTypeSignal.value = 'subway';
    const animationConfig: LandTransportAnimationConfig = {
      duration: this.stateMachine.durationForEachStep, // Replace with actual duration
      onCompleteCallback: () => {
        this.stateMachine.setState(this.stateMachine.transitionState); // Transition to the next state
      },
    };
    this.controller.setupAnimation(animationConfig, () => {
      this.controller.initializeModelAnimation();
    });
  }
}
