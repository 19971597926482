import React, { FC } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { TravelOptions } from '~/utility/models';
import { TransportationDataInterface } from '..';
import { transportMapInterface } from '~/components/P2PManual/helpers';
import { P2PTimeDurationModal } from '../P2PTimeDurationModal';
import { getTravelMode } from '~/utility/utils';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 261,
    borderRadius: 15,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: '#fff',
  color: '#FE7138',
  borderRadius: 20,
  padding: theme.spacing(1, 3),
  fontWeight: 600,
  fontSize: '16px',
  textTransform: 'none',
  boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
              0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
              0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  '&:hover': {
    background: '#FE7138',
    color: '#fff',
  },
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  gap: '2rem',
  flexDirection: 'column',
}));

// Props interface
interface DurationWithEncodingProps {
  open: boolean;
  onClose: () => void;
  onSave: (transportationData: transportMapInterface, duration: number) => void;
  selectedLocation: TravelOptions;
  handleFetchFromGoogle: (
    selectedLocation: TravelOptions,
    nextLocation: TravelOptions,
    selectedIndex: number,
    transport: transportMapInterface,
  ) => Promise<void>;
  selectedIndex: number;
  transportationData: TransportationDataInterface;
  nextLocation: TravelOptions;
  openTimeDialog: boolean;
  setOpenTimeDialog: React.Dispatch<React.SetStateAction<boolean>>;
  isFetchingFromGoogle: boolean;
}

// Main component
export const DurationWithEncoding: FC<DurationWithEncodingProps> = ({
  open,
  onClose,
  onSave,
  selectedLocation,
  selectedIndex,
  nextLocation,
  handleFetchFromGoogle,
  transportationData,
  openTimeDialog,
  setOpenTimeDialog,
  isFetchingFromGoogle,
}) => {
  const onConfirm = (duration: number) => {
    onSave(transportationData, duration);
    onClose();
  };

  return (
    <StyledDialog
      open={open}
      onClose={!isFetchingFromGoogle ? onClose : undefined}
      disableEscapeKeyDown={isFetchingFromGoogle}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h5"
            sx={{
              color: '#000',
              fontFamily: 'Poppins, sans-serif',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '25.2px',
              margin: '10px',
            }}
          >
            How much time does it take?
          </Typography>

          {transportationData.icon && (
            <img
              src={transportationData.icon}
              alt=""
              style={{ height: '40px', width: '40px', marginRight: '5px' }}
            />
          )}
        </Box>
      </DialogTitle>

      <DialogContent>
        <StyledBox>
          {getTravelMode(transportationData.label) !== TravelMode.Plane && (
            <StyledButton
              onClick={async () => {
                await handleFetchFromGoogle(
                  selectedLocation,
                  nextLocation,
                  selectedIndex,
                  transportationData,
                );
                onClose();
              }}
              disabled={isFetchingFromGoogle}
            >
              {isFetchingFromGoogle ? 'Fetching data...' : 'Fetch from Google'}
            </StyledButton>
          )}
          <StyledButton
            onClick={() => setOpenTimeDialog(true)}
            disabled={isFetchingFromGoogle}
          >
            Enter Manually
          </StyledButton>
        </StyledBox>
        <P2PTimeDurationModal
          open={openTimeDialog}
          onClose={() => setOpenTimeDialog(false)}
          onSave={onConfirm}
          selectedLocation={selectedLocation}
          selectedIndex={selectedIndex}
          transportationData={transportationData}
        />
      </DialogContent>
    </StyledDialog>
  );
};
