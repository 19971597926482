import { Box } from '@mui/material';



export default function Logo() {
  
  return (
    <Box>
          <img src="/logoVizualTravel.svg" alt="Logo" />
    </Box>
  );
}
