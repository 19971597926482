import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Snackbar, Slide, Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import ActionsCreator from '~redux/actions';
import { store, useDispatch } from '~redux/store';
import useStyles from './styles';
import { TransitionProps } from '@mui/material/transitions';
import { FormData, Options, TravelFormData } from '~/utility/models';
import FormContainer from '~/containers/FormContainer';
import TransportSelector from '../TransportSelector';
import { Dayjs } from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { TravelMode } from '../../animationEngine/utility/enums/TravelMode';
import { RootState } from '~/redux/reducers';
import { initialFormData } from './constants';
import { modifyTravelPointsArray, updateTravelPointsArray } from './helpers';
import {
  deleteFileByUrlFromStorage,
  uploadFileToStorage,
  validateFileMimetype,
} from '~/utility/utils';
import { validImageTypes } from '~/containers/ImageContainer/constants';

/**
 * Represents the props for the ModifyTravelForm component.
 * @interface ModifyTravelFormProps
 * @property {string} departureAlertText - Text for the departure alert.
 * @property {string} arrivalAlertText - Text for the arrival alert.
 * @property {string} formName - Name of the form.
 * @property {React.MouseEventHandler<HTMLImageElement> | undefined} [handleClose] - Function to handle closing the form.
 * @property {boolean} state - State indicating whether the form is open or closed.
 */
interface ModifyTravelFormProps {
  departureAlertText: string;
  arrivalAlertText: string;
  formName: string;
  handleClose?: React.MouseEventHandler<HTMLImageElement>;
  state: boolean;
}

/**
 * Represents the ModifyTravelForm component.
 * @param {ModifyTravelFormProps} props - The props for the ModifyTravelForm component.
 * @param {string} props.departureAlertText - Text for the departure alert.
 * @param {string} props.arrivalAlertText - Text for the arrival alert.
 * @param {string} props.formName - Name of the form.
 * @param {React.MouseEventHandler<HTMLImageElement> | undefined} [props.handleClose] - Function to handle closing the form.
 * @param {boolean} props.state - State indicating whether the form is open or closed.
 * @returns {JSX.Element} JSX element representing the ModifyTravelForm component.
 */

const ModifyTravelForm: React.FC<ModifyTravelFormProps> = (
  props: ModifyTravelFormProps,
): JSX.Element => {
  const classes = useStyles(); // Use useStyles to access the CSS classes
  const dispatch = useDispatch();
  const isMobile = window.innerWidth <= 600; // Detect initial screen width
  const isArrivalDisabled = useRef<boolean | null>(false);
  const isDepartureDisabled = useRef<boolean | null>(false);
  const selectedTransport = useRef<TravelMode | null>(null); // New state for selected transport
  const [errorMessage, setErrorMessage] = useState(''); // State to manage the error message
  const [isCarSelected, setIsCarSelected] = useState(false);
  const [isFerrySelected, setIsFerrySelected] = useState(false);
  const [isPlaneSelected, setIsPlaneSelected] = useState(false);
  const [isWalkSelected, setIsWalkSelected] = useState(false);
  const [isTransitSelected, setIsTransitSelected] = useState(false);
  const [isCarHovered, setIsCarHovered] = useState(false);
  const [isPlaneHovered, setIsPlaneHovered] = useState(false);
  const [isFerryHovered, setIsFerryHovered] = useState(false);
  const [isWalkHovered, setIsWalkHovered] = useState(false);
  const [isTransitHovered, setIsTransitHovered] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<Dayjs>(); //THIS STATE IS USED IN DISABLED TRAVEL FORM.
  const [selectedTransportImages, setSelectedTransportImages] = useState<
    string[]
  >([]); // This holds the selected transport images

  const [transportFileuploadLoader, setTransportFileuploadLoader] =
    useState<boolean>(false); //Holds the loading state for file upload

  /**-----------------------------------REDUX STATES-----------------------------------------*/

  /**
   * Represents the state of the Snackbar in the application.
   * @type {boolean}
   */
  const snackbarOpen: boolean = useSelector(
    (state: RootState) => state.MapReducers.snackbarOpen,
  );

  /**
   * Represents the success message displayed in the application.
   * @type {string}
   */
  const successMessage: string = useSelector(
    (state: RootState) => state.MapReducers.successMessage,
  );

  /**
   * Represents the state of the success Snackbar in the application.
   * @type {boolean}
   */
  const successSnackbarOpen: boolean = useSelector(
    (state: RootState) => state.MapReducers.successSnackbarOpen,
  );

  /**
   * Represents the loading state of the application.
   * @type {boolean}
   */
  const loading: boolean = useSelector(
    (state: RootState) => state.MapReducers.loading,
  );

  /**
   * Represents the array of TravelFormData objects stored in the application state.
   * @type {TravelFormData[]}
   */
  const state: TravelFormData[] = useSelector(
    (state: RootState) => state.MapReducers.pointsArray,
  );

  /**
   * Represents the disabled state of the application.
   * @type {boolean}
   */
  const isDisabled: boolean = useSelector(
    (state: RootState) => state.TravelReducers.isDisabled,
  );

  /**
   * Represents the index on which we are currently in the itinerary state.
   * @type {number}
   */
  const index: number = useSelector(
    (state: RootState) => state.MapReducers.index,
  );

  /**
   * Represents the editing state of the application.
   * @type {boolean}
   */
  const isEditing: boolean = useSelector(
    (state: RootState) => state.TravelReducers.isEditing,
  );

  const [departureFormData, setDepartureFormData] =
    useState<FormData>(initialFormData);
  const [arrivalFormData, setArrivalFormData] =
    useState<FormData>(initialFormData);

  const travelPoints = useRef<TravelFormData[] | null>(state); //Reference to store the state (The whole travel array of different departures and arrivals)

  /**-----------------------------------USE EFFECT-----------------------------------------*/

  /**
   * Manages side effects related to travel form state changes and updates component state accordingly.
   * This effect is triggered whenever the index or state array changes.
   * @param {number} index - Index of the selected item in the itinerary state.
   * @param {TravelFormData[]} state - Array of travel form data representing different points in the itinerary.
   */

  useEffect(() => {
    if (state[index]) {
      if (state.length === 1) {
        // If there is only one item in the state, disable neither Arrival nor Departure
        setArrivalFormData(state[index].arrival);
        setDepartureFormData(state[index].departure);
        selectedTransport.current = state[index].selectedTransport;
        isArrivalDisabled.current = false;
        isDepartureDisabled.current = false;
        setSelectedTransportImages(state[index].selectedTransportImages);
      } else if (state.length > 1) {
        if (index === 0) {
          // If it's the first index, disable Arrival
          setArrivalFormData(state[index].arrival);
          setDepartureFormData(state[index].departure);
          selectedTransport.current = state[index].selectedTransport;
          isArrivalDisabled.current =
            (travelPoints.current as TravelFormData[]).length > 0
              ? true
              : false;
          isDepartureDisabled.current = false; // Make sure Departure is enabled
          setSelectedTransportImages(state[index].selectedTransportImages);
        } else if (index === state.length - 1) {
          // If it's the last index, disable Departure
          setArrivalFormData(state[index].arrival);
          setDepartureFormData(state[index].departure);
          selectedTransport.current = state[index].selectedTransport;
          isArrivalDisabled.current = false; // Make sure Arrival is enabled
          isDepartureDisabled.current = true;
          setSelectedTransportImages(state[index].selectedTransportImages);
        }
      }
    } else {
      if (index > state.length - 1) {
        isArrivalDisabled.current = false;
        isDepartureDisabled.current = true;
        selectedTransport.current = null;
        setDepartureFormData(state[state.length - 1].arrival);
        setArrivalFormData(initialFormData);
        setSelectedTransportImages([]);
      } else if (index < 0) {
        setArrivalFormData(state[0].departure);
        setDepartureFormData(initialFormData);
        selectedTransport.current = null;
        isArrivalDisabled.current = true;
        isDepartureDisabled.current = false; // Make sure Departure is enabled
        setSelectedTransportImages([]);
      }
    }
    setTravelModeSelected(selectedTransport.current);
  }, [index, state]);

  //This reference was important to be cleaned up so I did that but the rest are just boolean values that wont affect
  //And they can just either be true or false and not null.
  useEffect(() => {
    return () => {
      travelPoints.current = [];
    };
  }, []);

  /**-----------------------------------FUNCTIONS-----------------------------------------*/

  function setTravelModeSelected(selectedTransport: TravelMode | null) {
    switch (selectedTransport) {
      case TravelMode.Plane:
        setIsPlaneSelected(true);
        setIsCarSelected(false);
        setIsTransitSelected(false);
        setIsFerrySelected(false);
        setIsWalkSelected(false);
        break;

      case TravelMode.Car:
        setIsCarSelected(true);
        setIsPlaneSelected(false);
        setIsTransitSelected(false);
        setIsFerrySelected(false);
        setIsWalkSelected(false);
        break;

      case TravelMode.Transit:
        setIsTransitSelected(true);
        setIsPlaneSelected(false);
        setIsCarSelected(false);
        setIsFerrySelected(false);
        setIsWalkSelected(false);
        break;

      case TravelMode.Walk:
        setIsWalkSelected(true);
        setIsCarSelected(false);
        setIsPlaneSelected(false);
        setIsTransitSelected(false);
        setIsFerrySelected(false);
        break;

      case TravelMode.Ferry:
        setIsWalkSelected(false);
        setIsCarSelected(false);
        setIsPlaneSelected(false);
        setIsTransitSelected(false);
        setIsFerrySelected(true);
        break;

      default:
        setIsPlaneSelected(false);
        setIsCarSelected(false);
        setIsTransitSelected(false);
        setIsFerrySelected(false);
        setIsWalkSelected(false);
        break;
    }
  }

  /**
   * @function handleTransportFileChange
   * Handles changes in the transport file form fields.
   * @param {keyof FormData} field - The field in the form data to update.
   * @param {string | null | number | Date | Options | Dayjs | File[] } value - The new value for the field.
   * @returns {void}
   */
  const handleTransportFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = Array.from(event.target.files || []);

    if (files.length > 0) {
      const filteredFilesByMimeType = files.filter((file) =>
        validateFileMimetype({ file, validFileTypes: validImageTypes }),
      );

      if (filteredFilesByMimeType.length > 0) {
        try {
          const maxFilesToAdd = 4 - selectedTransportImages.length; // Calculate max files to add
          const filesToAdd = filteredFilesByMimeType.slice(0, maxFilesToAdd); // Limit to maxFilesToAdd

          const uploadPromises = filesToAdd.map((file) =>
            uploadFileToStorage({
              bucketName: 'travel-images',
              userId: store.getState().MapReducers.userID,
              file: file,
            }),
          );

          setTransportFileuploadLoader(true);

          // Wait for all uploads to complete
          const uploadResults = await Promise.all(uploadPromises);

          // Process each upload result
          uploadResults.forEach((result) => {
            if (result.success && result.fileUrl) {
              setSelectedTransportImages((prevData) =>
                [...prevData, result.fileUrl.data.publicUrl].slice(0, 4),
              );
            } else {
              console.error('Failed to upload file:', result.error);
              // Handle error if needed
            }
          });
        } catch (error) {
          console.error('Error uploading files:', error);
          // Handle general upload error if needed
        } finally {
          setTransportFileuploadLoader(false);
        }
      }
    }
  };

  /**
   * Handles the removal of a selected transport image by its index.
   * Removes the image from the selectedTransportImages state.
   * Remove from supabase storage
   * @param {number} index - The index of the image to be removed.
   */
  const handleTransportFileRemoval = (index: number) => {
    const imagesLeft = selectedTransportImages.filter((_, i) => i !== index);
    setSelectedTransportImages(imagesLeft);

    deleteFileByUrlFromStorage({
      bucketName: 'travel-images',
      userId: store.getState().MapReducers.userID, // Retrieve user ID from Redux store
      fileUrl: selectedTransportImages[index],
    });
  };

  /**
   * @function handleArrivalFormChange
   * Handles changes in the arrival form fields and updates the arrival form data accordingly.
   * @param {keyof FormData} field - The field in the form data to be updated.
   * @param {string | null | number | Date | Options | Dayjs | File | string[]} value - The new value for the specified field.
   */
  const handleArrivalFormChange = (
    field: keyof FormData,
    value:
      | string
      | null
      | number
      | Date
      | Options
      | Dayjs
      | boolean
      | File[]
      | string[],
  ) => {
    setArrivalFormData((prevData) => {
      // Create a copy of previous form data
      let updatedFormData = { ...prevData };

      // Update the specified field with the new value
      updatedFormData = { ...prevData, [field]: value };

      return updatedFormData;
    });
  };

  /**
   * Handles changes in the departure form fields and updates the departure form data accordingly.
   * @param {keyof FormData} field - The field in the form data to be updated.
   * @param {string | null | number | Date | Options | Dayjs} value - The new value for the specified field.
   */
  const handleDepartureFormChange = (
    field: keyof FormData,
    value:
      | string
      | null
      | number
      | Date
      | Options
      | Dayjs
      | boolean
      | File[]
      | string[],
  ) => {
    setDepartureFormData((prevData) => {
      // Create a copy of previous form data
      let updatedFormData = { ...prevData };

      // Update the specified field with the new value
      updatedFormData = { ...prevData, [field]: value };

      return updatedFormData;
    });
  };

  /**
   * @function handleTransportChange
   * Handles the change in transportation type and updates the state accordingly.
   * @param {TravelMode} transportType - The selected transportation mode.
   */
  const handleTransportChange = (transportType: TravelMode) => {
    // Update state based on the selected transportation mode
    setIsPlaneSelected(transportType === TravelMode.Plane);
    setIsCarSelected(transportType === TravelMode.Car);
    setIsTransitSelected(transportType === TravelMode.Transit);
    setIsFerrySelected(transportType === TravelMode.Ferry);
    setIsWalkSelected(transportType === TravelMode.Walk);

    // Update the selected transport reference
    selectedTransport.current = transportType;
  };

  /**
   * @function handleMouseEnter
   * Handles mouse enter event for a specific transportation mode and updates state accordingly.
   * @param {TravelMode} transportType - The transportation mode for which the mouse enter event is triggered.
   */
  const handleMouseEnter = (transportType: TravelMode) => {
    // Update state based on the transportation mode when mouse enters
    switch (transportType) {
      case TravelMode.Plane:
        setIsPlaneHovered(true);
        setIsCarHovered(false);
        setIsTransitHovered(false);
        setIsWalkHovered(false);
        setIsFerryHovered(false);
        break;

      case TravelMode.Car:
        setIsPlaneHovered(false);
        setIsCarHovered(true);
        setIsTransitHovered(false);
        setIsWalkHovered(false);
        setIsFerryHovered(false);
        break;

      case TravelMode.Transit:
        setIsPlaneHovered(false);
        setIsCarHovered(false);
        setIsTransitHovered(true);
        setIsWalkHovered(false);
        setIsFerryHovered(false);
        break;

      case TravelMode.Walk:
        setIsPlaneHovered(false);
        setIsCarHovered(false);
        setIsTransitHovered(false);
        setIsWalkHovered(true);
        setIsFerryHovered(false);
        break;

      case TravelMode.Ferry:
        setIsCarHovered(false);
        setIsPlaneHovered(false);
        setIsFerryHovered(true);
        setIsWalkHovered(false);
        setIsTransitHovered(false);
        break;

      default:
        setIsPlaneHovered(false);
        setIsCarHovered(false);
        setIsTransitHovered(false);
        setIsWalkHovered(false);
        setIsFerryHovered(false);
        break;
    }
  };

  /**
   * @function handleMouseLeave
   * Handles mouse leave event for a specific transportation mode and updates state accordingly.
   * @param {TravelMode} transportType - The transportation mode for which the mouse leave event is triggered.
   */
  const handleMouseLeave = (transportType: TravelMode) => {
    // Update state based on the transportation mode when mouse leaves
    switch (transportType) {
      case TravelMode.Plane:
        setIsPlaneHovered(false);
        break;
      case TravelMode.Car:
        setIsCarHovered(false);
        break;

      case TravelMode.Transit:
        setIsTransitHovered(false);
        break;

      case TravelMode.Walk:
        setIsWalkHovered(false);
        break;

      case TravelMode.Ferry:
        setIsFerryHovered(false);
        break;
    }
  };

  /**
   * @function handleEditingDoneButtonClick
   * @returns {Promise<boolean>} A promise indicating the success of the modification. Resolves to true if successful, false otherwise.
   */
  const handleEditingDoneButtonClick = async (): Promise<boolean> => {
    return await modifyTravelPointsArray(
      departureFormData,
      arrivalFormData,
      selectedTransport,
      travelPoints as React.MutableRefObject<TravelFormData[]>,
      index,
      dispatch,
      setErrorMessage,
      selectedTransportImages,
    );
  };

  /**
   * @function handleAddAnotherPointClick
   * Handles the event when adding is done and updates the travel points array.
   * @returns {Promise<boolean>} A promise indicating the success of the operation. Resolves to true if successful, false otherwise.
   */
  const handleAddingDoneButtonClick = async (): Promise<boolean> => {
    return await updateTravelPointsArray(
      departureFormData,
      arrivalFormData,
      selectedTransport,
      travelPoints as React.MutableRefObject<TravelFormData[]>,
      dispatch,
      setErrorMessage,
      true, // Indicating that the new travel point should be saved
      selectedTransportImages,
    );
  };

  /**
   * @function handleAddAnotherPointClick
   * Handles the event when adding another point is clicked and updates the travel points array accordingly.
   */
  const handleAddAnotherPointClick = async () => {
    // Update the travel points array with a new point
    let formValidState = await updateTravelPointsArray(
      departureFormData,
      arrivalFormData,
      selectedTransport,
      travelPoints as React.MutableRefObject<TravelFormData[]>,
      dispatch,
      setErrorMessage,
      false, // Indicates that the new point should not be saved
      selectedTransportImages,
    );

    // If the form is valid and travel points array exists
    if (formValidState && travelPoints.current) {
      // Get the index of the last point
      const lastPointIndex = travelPoints.current.length - 1;

      // Reset selected transport and arrival form data
      setTravelModeSelected(null);
      selectedTransport.current = null;
      setArrivalFormData(initialFormData);
      setSelectedTransportImages([]);

      // Set departure form data to the arrival of the last point
      setDepartureFormData(travelPoints.current[lastPointIndex].arrival);

      // Dispatch actions to update index and disabled state
      dispatch(ActionsCreator.setIndexForModifyTravelForm(index + 1));
      dispatch(ActionsCreator.setDisabledState(true));

      // Reset disabled states
      isArrivalDisabled.current = false;
      isDepartureDisabled.current = true;
      //Set initial state of disbaled date everytime add another point is clicked
      setSelectedDateTime(undefined);
    } else return; // Exit function if form is not valid
  };

  /**
   * @function handleOnGoBackMethod
   * Handles the event when going back is clicked and updates the travel points array accordingly.
   */
  const handleOnGoBackMethod = () => {
    // If travel points array exists
    if (travelPoints.current) {
      // Get the index of the last point
      const lastPointIndex = travelPoints.current.length - 1;

      // Set departure and arrival form data to the last point
      setDepartureFormData(travelPoints.current[lastPointIndex].departure);
      setArrivalFormData(travelPoints.current[lastPointIndex].arrival);

      // Set selected transport based on the last point
      selectedTransport.current =
        travelPoints.current[lastPointIndex].selectedTransport;

      // Set selected transport images based on the last point
      setSelectedTransportImages(
        travelPoints.current[lastPointIndex].selectedTransportImages,
      );

      // Set selected transport states
      setTravelModeSelected(
        travelPoints.current[lastPointIndex].selectedTransport,
      );

      // Remove the last point from the travel points array
      const updatedTravelPoints = [...travelPoints.current.slice(0, -1)];
      travelPoints.current = updatedTravelPoints;

      // Dispatch actions to update index and disabled state
      dispatch(ActionsCreator.setIndexForModifyTravelForm(index - 1));
      if (Array.isArray(travelPoints.current)) {
        if (travelPoints.current.length === 0) {
          isDepartureDisabled.current = false;
          dispatch(ActionsCreator.setDisabledState(false));
        }
      }
      //Set initial state of disbaled date everytime add another point is clicked
      setSelectedDateTime(undefined);
    }
  };

  //THESE NEED TO STAY HERE BECAUSE THEY INCLUDE FUNCTIONS THAT ARE DEFINED ABOVE.
  const buttonConfigurations = [
    {
      label: 'Add another Point',
      class: classes.addPointButton,
      loading: false,
      isDisabled: isEditing ? true : false,
      onClick: handleAddAnotherPointClick,
    },
    {
      label: 'Save',
      class: classes.submitButton,
      isDisabled: false,
      loading: loading,
      onClick: isEditing
        ? handleEditingDoneButtonClick
        : handleAddingDoneButtonClick,
    },
  ];

  useEffect(() => {
    return () => {
      isArrivalDisabled.current = null;
      isDepartureDisabled.current = null;
      selectedTransport.current = null;
      travelPoints.current = null;
    };
  }, []);

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={() => dispatch(ActionsCreator.setSnackbarOpen(false))} // Close the Snackbar on action
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // Position the Snackbar at bottom left
        TransitionComponent={Slide} // Use the Slide component for the transition
        TransitionProps={{ direction: 'right' } as TransitionProps} // Slide from right to left
      >
        <Alert
          variant="filled"
          severity="error"
          onClose={() => dispatch(ActionsCreator.setSnackbarOpen(false))}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => dispatch(ActionsCreator.setSuccessSnackbarOpen(false))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'right' } as TransitionProps}
      >
        <Alert
          variant="filled"
          severity="success"
          onClose={() => dispatch(ActionsCreator.setSuccessSnackbarOpen(false))}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Typography variant="h5" textAlign={'center'} className={classes.title}>
        {props.formName}
        <img
          src="/icons/cross.svg"
          alt="cancel"
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            padding: '22px',
            cursor: 'pointer',
          }}
          onClick={props.handleClose}
        />
      </Typography>

      <Grid
        item
        container
        spacing={1}
        marginTop={2}
        className={classes.formContainer}
      >
        <Grid item xs={12} md={6}>
          <FormContainer
            type={'departure'}
            formData={departureFormData}
            oppositeformData={arrivalFormData}
            onFormChange={handleDepartureFormChange}
            isDisabled={isDepartureDisabled.current as boolean} // Pass the isDisabled prop to indicate it's for departure and should not be disabled
            alertText={props.departureAlertText}
            setSelectedDateTime={setSelectedDateTime}
            selectedDateTime={selectedDateTime}
          />
        </Grid>

        {/* This is to show mode of transport selector in middle for mobile init state case */}
        {/* {isMobile && (
         
        )} */}

        <Grid item xs={12} md={6}>
          <FormContainer
            type={'arrival'}
            formData={arrivalFormData}
            oppositeformData={departureFormData}
            onFormChange={handleArrivalFormChange}
            isDisabled={isArrivalDisabled.current as boolean}
            alertText={props.arrivalAlertText}
            setSelectedDateTime={setSelectedDateTime}
            selectedDateTime={selectedDateTime}
          />
        </Grid>
      </Grid>

      {isMobile ? (
        <TransportSelector
          onTransportChange={(transportType) =>
            handleTransportChange(transportType)
          }
          isLoading={transportFileuploadLoader}
          images={selectedTransportImages}
          onFileChange={handleTransportFileChange}
          handleRemoveImage={handleTransportFileRemoval}
          selectedTransport={selectedTransport.current}
          isPlaneSelected={isPlaneSelected}
          isCarSelected={isCarSelected}
          isWalkSelected={isWalkSelected}
          isTransitSelected={isTransitSelected}
          isFerrySelected={isFerrySelected}
          isPlaneHovered={isPlaneHovered}
          isCarHovered={isCarHovered}
          isTransitHovered={isTransitHovered}
          isWalkHovered={isWalkHovered}
          isFerryHovered={isFerryHovered}
          onMouseEnter={(transportType) => handleMouseEnter(transportType)}
          onMouseLeave={(transportType) => handleMouseLeave(transportType)}
        />
      ) : (
        // Desktop mode of transport selector
        <TransportSelector
          onTransportChange={(transportType) =>
            handleTransportChange(transportType)
          }
          isLoading={transportFileuploadLoader}
          images={selectedTransportImages}
          onFileChange={handleTransportFileChange}
          handleRemoveImage={handleTransportFileRemoval}
          selectedTransport={selectedTransport.current}
          isPlaneSelected={isPlaneSelected}
          isCarSelected={isCarSelected}
          isWalkSelected={isWalkSelected}
          isTransitSelected={isTransitSelected}
          isFerrySelected={isFerrySelected}
          isPlaneHovered={isPlaneHovered}
          isCarHovered={isCarHovered}
          isTransitHovered={isTransitHovered}
          isWalkHovered={isWalkHovered}
          isFerryHovered={isFerryHovered}
          onMouseEnter={(transportType) => handleMouseEnter(transportType)}
          onMouseLeave={(transportType) => handleMouseLeave(transportType)}
        />
      )}

      <Grid
        container
        spacing={2}
        textAlign={'center'}
        marginTop={'1px'}
        className={classes.footerButtons}
      >
        <Grid item xs={12} md={4} className={classes.goBackContainer}>
          {isDisabled && (
            <Button
              disabled={isEditing ? true : false}
              size="large"
              variant="contained"
              className={classes.backButton}
              sx={{
                fontFamily: 'Futura Bold Italic',
              }}
              onClick={handleOnGoBackMethod}
            >
              Go Back
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={8} className={classes.submitContainer}>
          {buttonConfigurations.map((config, index) => (
            <LoadingButton
              key={index}
              loading={config.loading}
              disabled={config.isDisabled}
              size="large"
              variant="contained"
              className={config.class}
              sx={{
                fontFamily: 'Futura Bold Italic',
              }}
              onClick={config.onClick}
            >
              {config.label}
            </LoadingButton>
          ))}
        </Grid>
      </Grid>
      <br></br>
    </div>
  );
};

export default ModifyTravelForm;
