import {
  Dialog,
  DialogContent,
  Snackbar,
  Alert,
  Slide,
  styled,
  Box,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { validImageTypes } from '~/containers/NewImageContainer/constants';
import { NewImageContainer } from '~/containers/NewImageContainer';
import VideoContainer from '~/containers/VideoContainer';
import { validVideoTypes } from '~/containers/VideoContainer/constants';
import { RootState } from '~/redux/reducers';
import { store } from '~/redux/store';

import { IMedia, TravelOptions } from '~/utility/models';
import {
  deleteFileByUrlFromStorage,
  generateThumbnailBlob,
  isFileGreaterThan100MB,
  saveUserUploadedImageAndPlaceDetails,
  uploadFileToStorage,
  validateFileMimetype,
} from '~/utility/utils';
import heic2any from 'heic2any';

interface AddImageOrVideoDialogProps {
  open: boolean;
  onClose: (media: any) => void;
  point: TravelOptions;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 361,
    padding: '10px 10px',
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

export const AddImageOrVideoDialog: React.FC<AddImageOrVideoDialogProps> = ({
  open,
  onClose,
  point,
}) => {
  const [images, setImages] = useState<IMedia[]>(
    point?.media?.filter((item) => item.type === 'image') || [],
  );

  const [videos, setVideos] = useState<IMedia[]>(
    point?.media?.filter((item) => item.type === 'video') || [],
  );

  useEffect(() => {
    setImages(point?.media?.filter((item) => item.type === 'image') || []);
    setVideos(point?.media?.filter((item) => item.type === 'video') || []);
  }, [point]);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const stateMedia = useSelector((state: RootState) =>
    state.P2PManualReducers.travelPointsByDayIndex[point.dayIndex].find(
      (x) => x.id === point.id,
    ),
  )?.media;

  /**
   * Handles the change of image(s) uploaded for the trip.
   * @param event - The event object representing the change event.
   * @returns void
   */

  const onImageFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const files = Array.from(event.target.files || []);

    if (files.length > 0) {
      // Filter files by allowed image types
      const filteredFiles = files.filter((file) => {
        const isHeicOrHeif =
          !file.type &&
          (file.name.endsWith('.heic') || file.name.endsWith('.heif'));

        // Check for invalid file type
        if (
          !validateFileMimetype({ file, validFileTypes: validImageTypes }) &&
          !isHeicOrHeif
        ) {
          setErrorMessage(
            'Invalid file type, allowed file types are ' +
              validImageTypes.map((type) => type.split('/')[1]).join(', '),
          );
          setSnackbarOpen(true);
          return false;
        }

        // Check for file size (max is 2MB)
        if (isFileGreaterThan100MB(file, 2)) {
          setErrorMessage('File size is too large, the max is 5MB');
          setSnackbarOpen(true);
          return false;
        }

        return true;
      });

      if (filteredFiles.length > 0) {
        try {
          setLoading(true);

          const maxFilesToAdd = 3 - images.length; // Calculate max files to add
          const filesToAdd = filteredFiles.slice(0, maxFilesToAdd); // Limit to maxFilesToAdd

          if (filesToAdd.length > 0) {
            const uploadPromises = filesToAdd.map(async (file) => {
              const isHeicOrHeif =
                !file.type &&
                (file.name.endsWith('.heic') || file.name.endsWith('.heif'));

              let imageFile = file;

              // Convert HEIC/HEIF to JPEG if necessary
              if (isHeicOrHeif) {
                const convertedBlob = await heic2any({
                  blob: file,
                  toType: 'image/jpeg',
                });
                imageFile = new File(
                  [convertedBlob as Blob],
                  `${file.name}.jpeg`,
                  {
                    type: 'image/jpeg',
                  },
                );
              }

              // Generate a thumbnail
              const thumbnailBlob = await generateThumbnailBlob(imageFile);
              const thumbnailFile = new File(
                [thumbnailBlob],
                `thumbnail_${file.name}`,
                {
                  type: 'image/webp',
                },
              );

              // Upload the file and its thumbnail to the appropriate bucket
              const [fileUploadResult, thumbnailUploadResult] =
                await Promise.all([
                  uploadFileToStorage({
                    bucketName: 'travel-images',
                    userId: store.getState().MapReducers.userID,
                    file: imageFile,
                  }),
                  uploadFileToStorage({
                    bucketName: 'travel-thumbnails',
                    userId: store.getState().MapReducers.userID,
                    file: thumbnailFile,
                    resize: true,
                  }),
                ]);

              return {
                originalFileUrl: fileUploadResult.fileUrl?.data.publicUrl,
                thumbnailUrl: thumbnailUploadResult.fileUrl?.data.publicUrl,
                success:
                  fileUploadResult.success && thumbnailUploadResult.success,
              };
            });

            const uploadResults = await Promise.all(uploadPromises);
            const newImages: IMedia[] = [];

            // Process each upload result
            uploadResults.forEach((result) => {
              if (
                result.success &&
                result.originalFileUrl &&
                result.thumbnailUrl
              ) {
                const newMedia: IMedia = {
                  url: result.originalFileUrl,
                  thumbnail: result.thumbnailUrl,
                  type: 'image',
                  credits: '',
                };
                newImages.push(newMedia);
              } else {
                console.error('Failed to upload file:', result);
                setErrorMessage(
                  'File upload to storage failed, please try again.',
                );
                setSnackbarOpen(true);
                return;
              }
            });

            // Ensure the new array doesn't exceed the maximum length
            // Update states
            setImages(newImages.slice(0, 3));

            let newStateMedia: IMedia[] = [];

            if (stateMedia && stateMedia.length > 0) {
              newStateMedia = [...stateMedia, ...newImages.slice(0, 3)];
            } else {
              newStateMedia = newImages.slice(0, 3);
            }

            // Update the redux store
            onClose(newStateMedia);

            saveUserUploadedImageAndPlaceDetails({
              placeId: point.placeId!,
              city: point.city!,
              coordinates: point.coordinates!,
              timezone: point.timezone,
              createdBy: 'created_by_user',
              files: filesToAdd,
            });
          }
        } catch (error) {
          console.error('Error uploading files:', error);
          setErrorMessage(
            'An error occurred while uploading your file, please try again.',
          );
          setSnackbarOpen(true);
        } finally {
          setLoading(false);
        }
      }

      // Reset the file input value so the same file can be selected again
      event.target.value = '';
    }
  };

  /**
   * Handles the change of image(s) uploaded for the trip.
   * @param event - The event object representing the change event.
   * @returns void
   */

  const onVideoFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const files = Array.from(event.target.files || []);

    if (files.length > 0) {
      // Filter files by allowed image types
      const filteredFiles = files.filter((file) => {
        // Check for invalid file type
        if (!validateFileMimetype({ file, validFileTypes: validVideoTypes })) {
          setErrorMessage(
            'Invalid file type, allowed file types are mp4 and webm.',
          );
          setSnackbarOpen(true);
          return false;
        }

        // Check for file size (max is 5MB)
        if (isFileGreaterThan100MB(file, 5)) {
          setErrorMessage('File size is too large, the max is 5MB');
          setSnackbarOpen(true);
          return false;
        }

        return true;
      });

      if (filteredFiles.length > 0) {
        try {
          setLoading(true);

          const maxFilesToAdd = 3 - videos.length; // Calculate max files to add
          const filesToAdd = filteredFiles.slice(0, maxFilesToAdd); // Limit to maxFilesToAdd

          if (filesToAdd.length > 0) {
            const uploadPromises = filesToAdd.map(async (file) => {
              // Generate a thumbnail
              const thumbnailBlob = await generateThumbnailBlob(file);
              const thumbnailFile = new File(
                [thumbnailBlob],
                `thumbnail_${file.name}`,
                {
                  type: 'image/webp',
                },
              );

              // Upload the file and its thumbnail to the appropriate bucket
              const [fileUploadResult, thumbnailUploadResult] =
                await Promise.all([
                  uploadFileToStorage({
                    bucketName: 'travel-videos',
                    userId: store.getState().MapReducers.userID,
                    file: file,
                  }),
                  uploadFileToStorage({
                    bucketName: 'travel-thumbnails',
                    userId: store.getState().MapReducers.userID,
                    file: thumbnailFile,
                    resize: true,
                  }),
                ]);

              return {
                originalFileUrl: fileUploadResult.fileUrl?.data.publicUrl,
                thumbnailUrl: thumbnailUploadResult.fileUrl?.data.publicUrl,
                success:
                  fileUploadResult.success && thumbnailUploadResult.success,
              };
            });

            const uploadResults = await Promise.all(uploadPromises);

            const newVideosUrl: IMedia[] = [];

            // Process each upload result
            uploadResults.forEach((result) => {
              if (
                result.success &&
                result.originalFileUrl &&
                result.thumbnailUrl
              ) {
                const newMedia: IMedia = {
                  url: result.originalFileUrl,
                  thumbnail: result.thumbnailUrl,
                  type: 'video',
                  credits: '',
                };
                newVideosUrl.push(newMedia);
              } else {
                console.error('Failed to upload file:', result);
                setErrorMessage(
                  'File upload to storage failed, please try again.',
                );
                setSnackbarOpen(true);
                return;
              }
            });

            // Ensure the new array doesn't exceed the maximum length
            // Update states
            setVideos(newVideosUrl.slice(0, 3));

            let newStateMedia: IMedia[] = [];

            if (stateMedia && stateMedia.length > 0) {
              newStateMedia = [...stateMedia, ...newVideosUrl.slice(0, 3)];
            } else {
              newStateMedia = newVideosUrl.slice(0, 3);
            }

            // Update the redux store
            onClose(newStateMedia);

            saveUserUploadedImageAndPlaceDetails({
              placeId: point.placeId!,
              city: point.city!,
              coordinates: point.coordinates!,
              timezone: point.timezone,
              createdBy: 'created_by_user',
              files: filesToAdd,
            });
          }
        } catch (error) {
          console.error('Error uploading files:', error);
          setErrorMessage(
            'An error occurred while uploading your file, please try again.',
          );
          setSnackbarOpen(true);
        } finally {
          setLoading(false);
        }
      }

      // Reset the file input value so the same file can be selected again
      event.target.value = '';
    }
  };

  /**
   * @function handleRemoveImage
   * Handles the removal of image that was added to the image array.
   * Updates the setImages array which contains all existing images by filtering out the image with the index
   * Removes the image from supabase storage
   * @param index - The index to remove
   * @returns {void}
   */
  const handleRemoveImage = async (index: number) => {
    // Ensure images and stateMedia are valid
    if (
      !stateMedia ||
      stateMedia.length === 0 ||
      !images ||
      images.length === 0
    ) {
      return;
    }

    // Safely access the image and its thumbnail
    const imageToRemove = images[index];

    const imageThumbnail = imageToRemove?.thumbnail;

    if (!imageThumbnail) {
      console.error('Thumbnail not found for the image at the given index.');
      return;
    }

    // Find the index in stateMedia
    const stateMediaIndex = stateMedia.findIndex(
      (file) => file?.thumbnail === imageThumbnail,
    );

    if (stateMediaIndex === -1) {
      console.error('Image not found in stateMedia.');
      return;
    }

    // Filter out the removed image
    const imagesLeft = images.filter((_, i) => i !== index);
    const mediaLeft = stateMedia.filter((_, i) => i !== stateMediaIndex);

    setImages(imagesLeft);

    // Update redux store
    onClose(mediaLeft);

    // Retrieve media URL and delete files
    const mediaUrl = stateMedia[stateMediaIndex]?.url;

    if (mediaUrl) {
      deleteFileByUrlFromStorage({
        bucketName: 'travel-images',
        userId: store.getState().MapReducers.userID,
        fileUrl: mediaUrl,
      });
    }

    deleteFileByUrlFromStorage({
      bucketName: 'travel-thumbnails',
      userId: store.getState().MapReducers.userID,
      fileUrl: imageThumbnail,
    });
  };

  /**
   * @function handleRemoveImage
   * Handles the removal of image that was added to the image array.
   * Updates the setImages array which contains all existing images by filtering out the image with the index
   * Removes the image from supabase storage
   * @param index - The index to remove
   * @returns {void}
   */
  const handleRemoveVideo = async (index: number) => {
    // Ensure stateMedia and videos are valid
    if (
      !stateMedia ||
      stateMedia.length === 0 ||
      !videos ||
      videos.length === 0
    ) {
      return;
    }

    // Safely access the video and its thumbnail
    const videoToRemove = videos[index];
    const videoThumbnail = videoToRemove?.thumbnail;

    if (!videoThumbnail) {
      console.error('Thumbnail not found for the video at the given index.');
      return;
    }

    // Find the index in stateMedia
    const stateMediaIndex = stateMedia.findIndex(
      (file) => file?.thumbnail === videoThumbnail,
    );

    if (stateMediaIndex === -1) {
      console.error('Video not found in stateMedia.');
      return;
    }

    // Filter out the removed video
    const videosLeft = videos.filter((_, i) => i !== index);
    const mediaLeft = stateMedia.filter((_, i) => i !== stateMediaIndex);

    setVideos(videosLeft);

    // Update redux store
    onClose(mediaLeft);

    // Retrieve media URL and delete files
    const mediaUrl = stateMedia[stateMediaIndex]?.url;

    if (mediaUrl) {
      deleteFileByUrlFromStorage({
        bucketName: 'travel-videos',
        userId: store.getState().MapReducers.userID,
        fileUrl: mediaUrl,
      });
    }

    deleteFileByUrlFromStorage({
      bucketName: 'travel-thumbnails',
      userId: store.getState().MapReducers.userID,
      fileUrl: videoThumbnail,
    });
  };

  return (
    <StyledDialog open={open} onClose={() => onClose('')}>
      <DialogContent>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={() => setSnackbarOpen(false)} // Close the Snackbar on action
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // Position the Snackbar at bottom left
          TransitionComponent={Slide} // Use the Slide component for the transition
          TransitionProps={{ direction: 'right' } as TransitionProps} // Slide from right to left
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={() => setSnackbarOpen(false)}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Box>
            <NewImageContainer
              onFileChange={onImageFileChange}
              isLoading={loading}
              images={images}
              handleRemoveImage={handleRemoveImage}
              isDisabled={loading}
            />
          </Box>
          <Box>
            <VideoContainer
              videos={videos}
              isDisabled={loading}
              isLoading={loading}
              onFileChange={onVideoFileChange}
              handleRemoveVideo={handleRemoveVideo}
            />
          </Box>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};
