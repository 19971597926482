import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';
import { TravelPointsByDayIndex } from '~/utility/models';
import { getInitialDateTime } from '~/utility/utils';
import dayjs from 'dayjs';
import { store } from '~/redux/store';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import MuiTheme from '~/styles/theme/MuiTheme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import { DEFAULT_DURATION_HOUR } from '~/components/P2PManual/P2PSearchAutocomplete/constant';
import subscribeSignal from '~/hooks/subscribeSignal';
import { selectedTravelDayIndexSignal } from '~/components/ViewTravel/MobileFooter/DaysHeader/DaysHeader';
import { isSyncingToServerSignal } from '~/components/P2PManual';

// Initialize dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    backgroundColor: '#FF4000',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    border: '3px solid #fff',
    cursor: 'pointer',
  },
}));

interface DayNumberProps {
  disabled: boolean;
  onDeleteDay: (event: React.MouseEvent<HTMLElement>, index: number) => void;
}

const DayNumber = ({ disabled = false, onDeleteDay }: DayNumberProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const sliderRef = React.useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperType>();
  const [isSyncingToServer, setIsSyncingToServer] = useState(false);
  subscribeSignal(isSyncingToServerSignal, setIsSyncingToServer);
  const currentDayIndex: number = useSelector(
    (state: RootState) =>
      state.P2PManualReducers.currentDayIndex ||
      selectedTravelDayIndexSignal.peek() ||
      1,
  );

  const travelPointsByDayIndex: TravelPointsByDayIndex = useSelector(
    (state: RootState) => state.P2PManualReducers.travelPointsByDayIndex,
  );

  // State to keep track of each number button and the active button
  const [numbers, setNumbers] = useState<number[]>(
    Array.from({ length: currentDayIndex }, (_, i) => i + 1) || [1],
  );

  const setActiveNumber = (activeNumber: number) => {
    swiperRef.current?.slideTo(activeNumber - 1);
    // if (selectedTravelDaySignal.peek()) {
    const startDate = travelPointsByDayIndex?.[activeNumber]?.[0]?.startDate;
    const endDate = travelPointsByDayIndex?.[activeNumber]?.[0]?.endDate;
    if (startDate) {
      selectedTravelDayIndexSignal.value = activeNumber;
    }
    // }
    dispatch(ActionsCreator.setCurrentDayIndex(activeNumber));
    dispatch(ActionsCreator.setLastVisitedDayIndex(activeNumber));
  };

  useEffect(() => {
    setTimeout(() => {
      setActiveNumber(selectedTravelDayIndexSignal.peek() || 1);
    }, 500);
  }, []);

  subscribeSignal(selectedTravelDayIndexSignal, (dayIndex) => {
    if (!dayIndex) return;
    console.log('selectedTravelDayIndexSignal', dayIndex);
    setActiveNumber(dayIndex);
  });

  // Function to add a new number button and set it as active
  const addNumber = () => {
    if (!travelPointsByDayIndex[currentDayIndex]?.length) {
      console.log(
        'No points to add to the next day',
        travelPointsByDayIndex,
        currentDayIndex,
      );
      return;
    }
    isSyncingToServerSignal.value = true;
    // Generate new number for the next day
    const newNumber = numbers.length + 1;

    const value = { ...travelPointsByDayIndex };

    // Get the keys of the object
    const keys = Object.keys(value);

    // Access the last key
    const lastKey = keys[keys.length - 1];

    // Convert lastKey to a number
    const lastDay = [...value[Number(lastKey)]];

    const firstPointOfCurrentDay = lastDay[0]; // Access the first point of the current day

    let lastPointOfCurrentDay = { ...lastDay[lastDay.length - 1] };

    // Extract the start date from firstPointOfCurrentDay
    const firstPointDate = dayjs(firstPointOfCurrentDay.startDate).tz(firstPointOfCurrentDay.timezone);
    const year = firstPointDate.year();
    const month = firstPointDate.month() + 1; // Note: month is 0-indexed (0 = January)
    const day = firstPointDate.date();
    const hour = firstPointDate.hour();
    const minute = firstPointDate.minute();

    // Create a new date (with day + 1) using the extracted values but with the timezone of lastPointOfCurrentDay
    let newDate = dayjs.tz(`${year}-${month}-${day + 1} ${hour}:${minute}`, lastPointOfCurrentDay.timezone);

    const startDate = getInitialDateTime(newDate);

    // Add the new point or update the travelPoints array as needed
    const newPoint = {
      ...lastPointOfCurrentDay,
      dayIndex: newNumber,
      startDate: startDate,
      endDate: dayjs(startDate)
        .add(DEFAULT_DURATION_HOUR, 'hour')
        .utc()
        .format(),
    };

    // Set new day number
    setNumbers((prevNumbers) => [...prevNumbers, newNumber]);
    setActiveNumber(newNumber); // Automatically set the new button as active

    // Create a new updated object for travelPointsByDayIndex
    const updatedTravelPointsByDayIndex: TravelPointsByDayIndex = {
      ...travelPointsByDayIndex,
      [newNumber]: [newPoint], // Use last point as the first point of the new day
    };

    // Dispatch the updated state
    dispatch(
      ActionsCreator.setTravelPointsByDayIndex(updatedTravelPointsByDayIndex),
    );
    dispatch(ActionsCreator.setCurrentDayIndex(newNumber));

    sliderRef.current?.scrollTo({
      left: sliderRef.current.scrollWidth,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (swiperRef.current && numbers.length > 0) {
      swiperRef.current.slideTo(currentDayIndex - 1);
    }
  }, [numbers, currentDayIndex]);

  useEffect(() => {
    // travelPointsByDayIndex length is day numbers
    const value = {
      ...store.getState().P2PManualReducers.travelPointsByDayIndex,
    };
    const daysLength = Object.keys(value).length;

    setNumbers(Array.from({ length: daysLength }, (_, i) => i + 1));
    // dispatch(ActionsCreator.setCurrentDayIndex(daysLength));
  }, [dispatch, travelPointsByDayIndex]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: '10px',
      }}
    >
      <div style={{ flex: 1 }} />
      <Swiper
        //@ts-ignore
        onSwiper={(ref) => (swiperRef.current = ref)}
        slidesPerView={'auto'}
        spaceBetween={10}
        style={{
          padding: 10,
        }}
        longSwipesRatio={1}
        centeredSlidesBounds
        centerInsufficientSlides
        centeredSlides
      >
        {/* Placeholder without points */}
        {!numbers?.length && (
          <Button
            variant="contained"
            sx={{
              width: 10,
              height: '60px',
              padding: 3,
              boxShadow: 'none',
              minWidth: 0,
              fontSize: '9px',
              borderRadius: '10px',
              textTransform: 'none',
              backgroundColor: '#fe7138',
              color: '#fff',
            }}
          >
            <span className="d-flex flex-column" style={{ lineHeight: '0.3' }}>
              <h6 style={{ fontWeight: 400, fontSize: '14px' }}>Day </h6>
              <b style={{ fontSize: '18px', fontWeight: 700 }}>1</b>
            </span>
          </Button>
        )}
        {/* Render each number button based on numbers array */}
        {numbers.map((num, i) => (
          <SwiperSlide style={{ width: 51 }} key={i.toString()}>
            <Button
              key={num}
              variant="contained"
              onClick={() => setActiveNumber(num)}
              sx={{
                width: 10,
                height: '60px',
                padding: 3,
                boxShadow: 'none',
                minWidth: 0,
                fontSize: '9px',
                borderRadius: '10px',
                textTransform: 'none',
                backgroundColor:
                  currentDayIndex === num
                    ? '#fe7138'
                    : 'var(--alpha-orange-alpha-10, #FF91471A)',
                color: currentDayIndex === num ? '#fff' : '#fe7138',
              }}
            >
              {/* For last day, not allow for only one day */}
              {num === numbers[numbers?.length - 1] &&
                numbers?.length !== 1 && (
                  <div
                    role="presentation"
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      onDeleteDay(event, num)
                    }
                    className={classes.deleteButton}
                  >
                    <DeleteIcon
                      style={{
                        fontSize: '14px',
                        fontWeight: 900,
                        color: '#fff',
                      }}
                    />
                  </div>
                )}

              <span
                className="d-flex flex-column"
                style={{ lineHeight: '0.3' }}
              >
                <h6 style={{ fontWeight: 400, fontSize: '14px' }}>Day </h6>
                <b style={{ fontSize: '18px', fontWeight: 700 }}>{num}</b>
              </span>
            </Button>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        style={{
          display: 'flex',
          color: '#fe7138',
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '48px',
          height: '60px',
          maxWidth: '55px',
          marginLeft: '2px',
          boxShadow: 'none',
          borderRadius: '10px',
          outline: 'none',
          background: '#FF91471A',
        }}
        {...(isSyncingToServer || disabled
          ? { onClick: () => { } }
          : { onClick: addNumber })}
      >
        {isSyncingToServer ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <AddIcon
            style={{
              fontSize: '17px',
              fontWeight: 900,
            }}
          />
        )}
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
};

export default DayNumber;
