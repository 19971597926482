import React, { useEffect, useRef, useState } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import { IFrameItem } from './IframeItem/IframeItem';
import { InfoFooter } from './InfoFooter/InfoFooter';
import { DetailsItem } from './DetailsItem/DetailsItem';
import playIconNoOpacity from '~assets/playIcon-no-opacity.png';

import './TiktokItem.css';

export const TiktokItem = ({
  content,
  handleClose,
  goNext,
  goPrev,
  currentStoryIndex,
  tiktokUrl = [],
}: {
  handleClose: () => void;
  content?: any;
  goNext?: (currentStoryIndex: number) => void;
  goPrev?: (currentStoryIndex: number) => void;
  currentStoryIndex?: number;
  tiktokUrl?: string[];
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSwipeUp, setIsSwipeUp] = useState(false);
  const [isHideVisible, setIsIsHideVisible] = useState(false);
  const [hideplay, setHideplay] = useState(false);

  const tiktokUrlArr = tiktokUrl[0].split('/') || [];
  const videoId = tiktokUrlArr[tiktokUrlArr.length - 1];
  const isMobile = useIsMobile();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  // const handleDragEnd = () => {
  //   if (animatedY.get() > 30) {
  //     goPrev?.(currentStoryIndex);
  //   } else if (animatedY.get() < -30) {
  //     goNext?.(currentStoryIndex);
  //   }
  // };

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.type === 'onPlayerReady') {
        setIsIsHideVisible(true);
      }

      if (event.data.type === 'onStateChange') {
        if (event.data.value === 0) {
          setIsSwipeUp(true);
          setTimeout(() => {
            setIsSwipeUp(false);
          }, 7000);
        }
      }

      if (event.data.type === 'onCurrentTime') {
        if (event.data.value.currentTime === event.data.value.duration) {
          handleClose();
        }
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const handleShowDetails = () => {
    iframeRef.current!.contentWindow?.postMessage(
      { type: 'pause', 'x-tiktok-player': true },
      '*',
    );
    setIsDetailsVisible(true);
    setIsPlaying(false);
  };

  const handleHideDetails = () => {
    iframeRef.current!.contentWindow?.postMessage(
      { type: 'play', 'x-tiktok-player': true },
      '*',
    );
    setIsDetailsVisible(false);
    setIsPlaying(true);
  };

  const topLinkSrc = tiktokUrl[0].split('/').slice(0, 4).join('/');

  const togglePlay = () => {
    setIsPlaying((prev) => !prev);
    // if (!isPlaying) {
    //   setIsInfoFooterVisible(false);
    // }
    iframeRef.current!.contentWindow?.postMessage(
      { type: isPlaying ? 'pause' : 'play', 'x-tiktok-player': true },
      '*',
    );
  };

  // const [isInfoFooterVisible, setIsInfoFooterVisible] = useState(true);

  // if (!content) {
  //   return null;
  // }

  return (
    <motion.div
      className="tiktok_iframe_container"
      // onDragEnd={handleDragEnd}
      onClick={togglePlay}
    >
      {!isMobile && (
        <a
          style={{
            width: '100px',
            height: '100px',
            background: 'white',
            position: 'absolute',
            zIndex: 99999999,
            top: 0,
            right: 0,
            opacity: 0,
          }}
          target="_blank"
          rel="noreferrer"
          href={topLinkSrc}
          onClick={(e) => e.stopPropagation()}
          aria-label="Open link"
        >
          {/* Provide accessible content */}
          <span style={{ visibility: 'hidden' }}>Open link</span>
        </a>
      )}
      <a
        style={{
          width: isMobile ? '100%' : '300px',
          height: isMobile ? '50px' : '100px',
          background: 'white',
          position: 'absolute',
          zIndex: 99999999,
          top: 0,
          left: 0,
          opacity: 0,
        }}
        target="_blank"
        rel="noreferrer"
        href={topLinkSrc}
        aria-label="Open link"
      >
        {/* Provide accessible content */}
        <span style={{ visibility: 'hidden' }}>Open link</span>
      </a>
      <a
        style={{
          width: isMobile ? '50px' : '100px',
          background: 'white',
          height: isMobile ? '210px' : '240px',
          position: 'fixed',
          zIndex: 7,
          bottom: 0,
          right: 0,
          opacity: 0,
        }}
        target="_blank"
        rel="noreferrer"
        href={tiktokUrl[0]}
        aria-label="Open link"
      >
        {/* Provide accessible content */}
        <span style={{ visibility: 'hidden' }}>Open link</span>
      </a>

      <div
        style={{
          position: 'absolute',
          left: '3%',
          top: '120px',
          color: '#fff',
          zIndex: 9999999,
          cursor: 'pointer',
        }}
      >
        {' '}
        <ArrowBackIosNewIcon fontSize="large" onClick={handleClose} />
      </div>

      <div
        className="custom-badge inverse"
        onClick={handleClose}
        style={{
          position: 'absolute',
          left: '50%',
          bottom: '100px',
          transform: 'translateX(-50%)',
          zIndex: 9999999,
          width: '158px',
        }}
      >
        Skip
      </div>

      {isHideVisible && !isPlaying && !hideplay && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 999,
            color: '#fff',
            transform: 'translate(-40%, -50%)',
          }}
          onClick={() => {
            setIsPlaying(true);
            iframeRef.current!.contentWindow?.postMessage(
              { type: 'play', 'x-tiktok-player': true },
              '*',
            );
            setHideplay(true);
          }}
        >
          <img src={playIconNoOpacity} alt="" className="tiktok-play-button" />
        </div>
      )}

      {/* {isSwipeUp && (
        <div className="tiktok-item-swipeup">
          <KeyboardDoubleArrowUpIcon fontSize="large" />
          <span>Swipe Up For Next</span>
          <span>Activity</span>
        </div>
      )} */}
      <IFrameItem videoId={videoId} ref={iframeRef} />

      {/* <InfoFooter
        isInfoFooterVisible={isInfoFooterVisible}
        setIsInfoFooterVisible={setIsInfoFooterVisible}
        isHideVisible={isHideVisible}
        content={content}
        showDetails={handleShowDetails}
        playVideo={() => {
          iframeRef.current!.contentWindow?.postMessage(
            { type: 'play', 'x-tiktok-player': true },
            '*',
          );
          setIsPlaying(true);
          setHideplay(true);
        }}
        togglePlay={togglePlay}
      /> */}
      {isDetailsVisible && (
        <DetailsItem content={content} close={handleHideDetails} />
      )}
    </motion.div>
  );
};
