import React, { useEffect, useRef } from 'react';
import './style.css';
import { motion } from 'framer-motion';

import { uniqBy } from 'lodash';
import { TravelPoint } from '~/components/ViewTravel/StatsOverlay';
import { RootState, useSelector } from '~/redux/reducers';
import dayjs from 'dayjs';

const P2PHeaderDescription = ({
  travelData,
  selectedDateRange,
}: {
  travelData: TravelPoint[];
  selectedDateRange: string;
}) => {
  const currentTravelDay: number = useSelector(
    (state: RootState) => state.TripInfoReducers.currentTravelDay as number,
  );
  const travelDays = useRef<any[]>([]);

  useEffect(() => {
    travelDays.current = uniqBy(
      travelData.map((i, idx) => ({
        date: dayjs(String(i.departure.dateTime)).tz(i.departure.timezone),
        index: idx,
        timezone: i.departure.timezone,
      })),
      (u) => u.date.format('DD-MM-YYYY'),
    ).map((i) => ({
      date: i.date,
      index: i.index,
    }));
  }, [travelData]);

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ delay: 0.15, duration: 0.25 }}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 18,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <span className="bottom-tabs-play-header">{selectedDateRange}</span>
      <span className="bottom-tabs-play-header" style={{ color: '#FE7138' }}>
        {currentTravelDay > -1
          ? `Day ${currentTravelDay}/${travelDays.current.length}`
          : 'Select a Day'}
      </span>
    </motion.div>
  );
};

export default P2PHeaderDescription;
