import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import { combineReducers } from 'redux';

import MapReducers, { MapProps } from './MapReducers';
import TravelReducers, { TravelProps } from './TravelReducers';
import AnimationReducers, { AnimationProps } from './AnimationReducers';
import TripInfoReducers, { TripInfoProps } from './TripInfoReducers';

import { SET_INITIAL_STATE } from '../actionTypes';
import { CombinedState } from '@reduxjs/toolkit';
import P2PManualReducers, { P2PManualProps } from './P2PManualReducers';
import { IUiState, UiReducer } from './ui.slice';

// Create a type for the action
type ActionProps = MapProps &
  TravelProps &
  AnimationProps &
  P2PManualProps & {
    type: string;
  } & TripInfoProps;

// Combine your existing reducers
export const rootReducer = combineReducers({
  MapReducers,
  TravelReducers,
  AnimationReducers,
  TripInfoReducers,
  P2PManualReducers,
  UiReducer,
});

// Update your rootReducer to handle the RESET_ALL_STATES action
const rootReducerWithReset = (
  state:
    | CombinedState<{
        MapReducers: MapProps;
        TravelReducers: TravelProps;
        AnimationReducers: AnimationProps;
        TripInfoReducers: TripInfoProps;
        P2PManualReducers: P2PManualProps;
        UiReducer: IUiState;
      }>
    | undefined,
  action: ActionProps,
) => {
  if (action.type === SET_INITIAL_STATE) {
    // Reset the state of all reducers to their initial values
    state = {
      MapReducers: MapReducers(undefined, action),
      TravelReducers: TravelReducers(undefined, action),
      AnimationReducers: AnimationReducers(undefined, action),
      TripInfoReducers: TripInfoReducers(undefined, action),
      P2PManualReducers: P2PManualReducers(undefined, action),
      UiReducer: UiReducer(undefined, action),
    };
  }

  return rootReducer(state, action);
};

export default rootReducerWithReset;

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
