import styled from '@emotion/styled';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
} from '@mui/material';

interface AddNoteDialogProps {
  open: boolean;
  onClose: (note: string) => void;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 361,
    padding: '10px 10px',
    borderRadius: 30,
    background: 'linear-gradient(180deg, #eef3f7ff 0%, #ffff 100%)',
    boxShadow: `0px 1.3px 2.2px #07081705, 0px 3.1px 5.3px #07081707,
                0px 5.9px 10px #07081709, 0px 10.5px 17.9px #0708170b, 
                0px 19.6px 33.4px #0708170d, 0px 47px 80px #07081712`,
  },
}));

const DoneButton = styled(Button)({
  width: 120,
  height: 64,
  borderRadius: 18,
  fontFamily: 'Poppins',
  fontSize: '16px',
  background:
    ' radial-gradient(100% 100% at 0% 0%, #FF9345 0.01%, #FE7138 50%, #F25D21 100%) ',
  boxShadow: `0px 2px 6px #b8c5d033, 0px 6px 22px #b8c5d04d, 
              0px 9px 30px #c6d8e933, inset 0px 1.5px 1.5px #ffffff40`,
  '&:hover': { backgroundColor: '#e6602b' },
});

export const AddNoteDialog: React.FC<AddNoteDialogProps> = ({
  open,
  onClose,
  state,
  setState,
}) => {
  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(event.target.value);
  };

  const handleSave = () => {
    onClose(state);
  };

  return (
    <StyledDialog open={open} onClose={() => onClose('')}>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Notes
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          multiline
          rows={4}
          value={state}
          onChange={handleNoteChange}
          variant="outlined"
          label="Enter your note here"
          sx={{ marginBottom: 2 }}
        />
        <DoneButton onClick={handleSave}>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: '#fff', fontFamily: 'Poppins, sans-serif' }}
          >
            Done
          </Typography>
        </DoneButton>
      </DialogContent>
    </StyledDialog>
  );
};
